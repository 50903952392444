import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RentalListSearch from "./RentalListSearch";
import RentalUnit from "./RentalUnit";
import "./RentalUnits.css";
import {
  getRentalLists,
  getRentalListsImages,
  url,
} from "../../../Services/ApiServices";
import Loader from "../../Loader/Loader";
import RentalUnitModal from "./RentalUnitModal";
import { setRentalList, appendTempListingData } from "../../../actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

function RentalUnits(props) {
  // const [map, setMap] = useState(false);
  const [tab, setTab] = useState(0);
  const [mainTab, setMainTab] = useState("resident");
  const [tabOffice, setTabOffice] = useState(0);
  const [tabArrayRental, setTabArrayRental] = useState([]);
  const [tabArrayOffice, setTabArrayOffice] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [images, setImage] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [spinner2, setSpinner2] = useState(true);
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [sampleLists, setSampleLists] = useState([]);
  const dark = useSelector((state) => state.isDark);
  const rentalListData = useSelector((state) => state.rentalList);
  const [rentalListDataHomes, setRentalListDataHomes] = useState(
    rentalListData === null
      ? null
      : rentalListData.filter(
          (todo) => todo.rentalEntity.property.type !== "OFFICE"
        )
  );
  const [rentalListDataOffice, setRentalListDataOffice] = useState(
    rentalListData === null
      ? null
      : rentalListData.filter(
          (todo) => todo.rentalEntity.property.type === "OFFICE"
        )
  );
  const tempListing = useSelector((state) => state.tempListing);
  const dispatch = useDispatch();
  const setTabFunction = (val) => {
    window.scrollTo(0, 0);
    setTab(val);
  };
  const setTabOfficeFunction = (val) => {
    window.scrollTo(0, 0);
    setTabOffice(val);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    tabSet(rentalListData);
    getRentalLists()
      .then((data) => {
        if (data.status === 200) {
          appendTempArray(data.data.reverse());
          setSampleLists(data.data);
          setSpinner2(false);
          if (props.match.params.id) {
            var listing = data.data.filter(
              (todo) =>
                parseInt(todo.rentalEntity.id) ===
                parseInt(props.match.params.id)
            );
            if (listing.length !== 0) {
              handelDetail(listing[0].rentalEntity);
              window.$("#exampleModal").modal("show");
            }
            data.data
              .filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
              .forEach((todo, index) => {
                if (
                  parseInt(todo.rentalEntity.id) ===
                  parseInt(props.match.params.id)
                ) {
                  setTabFunction(parseInt(index / 12));
                  setMainTab("resident");
                }
              });
            data.data
              .filter((todo) => todo.rentalEntity.property.type === "OFFICE")
              .forEach((todo, index) => {
                if (
                  parseInt(todo.rentalEntity.id) ===
                  parseInt(props.match.params.id)
                ) {
                  setTabOfficeFunction(parseInt(index / 12));
                  setMainTab("office");
                }
              });
          }
          tabSet(data.data);
        } else {
          appendTempArray([]);
          setSampleLists([]);
          setSpinner2(false);
        }
      })
      .catch();

    // eslint-disable-next-line
  }, []);
  const tabSet = (array) => {
    if (array !== null) {
      var tabsArrayRental = [];
      for (
        let index = 0;
        index <
        array.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
          .length /
          12;
        index++
      ) {
        tabsArrayRental.push(index + 1);
      }
      setTabArrayRental(tabsArrayRental);
      var tabsArrayOffice = [];
      for (
        let index = 0;
        index <
        array.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
          .length /
          12;
        index++
      ) {
        tabsArrayOffice.push(index + 1);
      }
      setTabArrayOffice(tabsArrayOffice);
    }
  };
  const appendTempArray = (array) => {
    dispatch(setRentalList(array));
    setRentalListDataHomes(
      array.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
    );
    setRentalListDataOffice(
      array.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
    );
    array.forEach((element) => {
      var listing = tempListing.filter(
        (todo) => todo.rentalEntity.id === element.rentalEntity.id
      );
      if (listing.length === 0) {
        dispatch(appendTempListingData(element));
      }
    });
  };
  function handelDetail(rentalEntity) {
    setImage([]);
    setModalData(rentalEntity);
    setSpinner(true);
    if (parseInt(rentalEntity.id) !== parseInt(props.match.params.id)) {
      props.history.push({ pathname: "/rental-units/" + rentalEntity.id });
    }

    try {
      getRentalListsImages(rentalEntity.id).then((response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            let imgList = [];
            response.data.forEach((img) => {
              imgList.push(
                url +
                  `api/property/${rentalEntity.id}/${img}/image/downloadByName`
              );
            });
            setImage(imgList);
            setSpinner(false);
          } else {
            console.log("No data!");
            setSpinner(false);
          }
        } else {
          props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  function search(type, value) {
    if (type === "Bedrooms") {
      var data = rentalListData.filter(
        (data) => data.rentalEntity.bedRooms === value
      );
      dispatch(setRentalList(data));
      setRentalListDataHomes(
        data.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
      );
      setRentalListDataOffice(
        data.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
      );
      tabSet(data);
      setTabFunction(0);
      setTabOfficeFunction(0);
    }
    if (type === "City") {
      var data0 = rentalListData.filter(
        (data) =>
          data.address.city
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      dispatch(setRentalList(data0));
      setRentalListDataHomes(
        data0.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
      );
      setRentalListDataOffice(
        data0.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
      );
      tabSet(data0);
      setTabFunction(0);
      setTabOfficeFunction(0);
    }
    if (type === "State") {
      var data1 = rentalListData.filter(
        (data) =>
          data.address.state
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      dispatch(setRentalList(data1));
      setRentalListDataHomes(
        data1.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
      );
      setRentalListDataOffice(
        data1.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
      );
      tabSet(data1);
      setTabFunction(0);
      setTabOfficeFunction(0);
    }
    if (type === "Rent (less than)") {
      var data2 = rentalListData.filter(
        (data) => data.rentalEntity.rent < parseInt(value)
      );
      dispatch(setRentalList(data2));
      setRentalListDataHomes(
        data2.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
      );
      setRentalListDataOffice(
        data2.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
      );
      tabSet(data2);
      setTabFunction(0);
      setTabOfficeFunction(0);
    }
  }
  async function rentalRefresh() {
    await dispatch(setRentalList(sampleLists));
    setRentalListDataHomes(
      sampleLists.filter((todo) => todo.rentalEntity.property.type !== "OFFICE")
    );
    setRentalListDataOffice(
      sampleLists.filter((todo) => todo.rentalEntity.property.type === "OFFICE")
    );
    await setRefreshSearch(false);
    setRefreshSearch(true);
    tabSet(sampleLists);
    setTabFunction(0);
    setTabOfficeFunction(0);
  }
  // function handleMap() {
  //   let classes = "";
  //   classes += !map
  //     ? " RentalUnitsMapOn p-0 pt-3"
  //     : " RentalUnitsMapOff p-0 pt-3";
  //   return classes;
  // }
  // function handleMapMobile() {
  //   let classes = "";
  //   classes += !map
  //     ? " RentalUnitsMapOnMobile p-0 pt-1"
  //     : " RentalUnitsMapOffMobile p-0 pt-1";
  //   return classes;
  // }
  return (
    <div style={{}}>
      {refreshSearch ? (
        <RentalListSearch search={search} rentalRefresh={rentalRefresh} />
      ) : (
        <div style={{ marginTop: "80px" }}></div>
      )}
      <div className="container-fluid ">
        <div className="row pt-4  pb-2">
          <div className="col ml-2 pl-3">
            <button
              className={
                mainTab === "office"
                  ? "mr-2 pri textCapitalizeCase"
                  : "mr-2  textCapitalizeCase"
              }
              onClick={() => setMainTab("resident")}
            >
              Residence
            </button>
            <button
              className={
                mainTab === "resident"
                  ? "mr-2 pri textCapitalizeCase"
                  : "mr-2  textCapitalizeCase"
              }
              onClick={() => setMainTab("office")}
            >
              Office
            </button>
          </div>
          <div className="col-2 text-right">
            <i
              className="fa fa-search  searchListHeadButton1"
              aria-hidden="true"
              onClick={() => setRefreshSearch(!refreshSearch)}
            ></i>
          </div>
        </div>
      </div>
      {/* <ul className="nav nav-tabs pb-0 mb-0 " id="myTab" role="tablist">
        <li className={dark ? "nav-item makeWhite" : "nav-item  "}>
          <a
            className={
              dark
                ? mainTab === "resident"
                  ? "nav-link navTabLink makeWhite active"
                  : "nav-link navTabLink makeWhite"
                : mainTab === "resident"
                ? "nav-link navTabLink  active"
                : "nav-link navTabLink"
            }
            id="resident-tab"
            data-toggle="tab"
            href="#resident"
            role="tab"
            aria-controls="resident"
            aria-selected="true"
          >
            Resident Units
          </a>
        </li>

        <li className={dark ? "nav-item makeWhite" : "nav-item  "}>
          <a
            className={
              dark
                ? mainTab === "office"
                  ? "nav-link navTabLink makeWhite active"
                  : "nav-link navTabLink makeWhite"
                : mainTab === "office"
                ? "nav-link navTabLink active"
                : "nav-link navTabLink"
            }
            id="office-tab"
            data-toggle="tab"
            href="#office"
            role="tab"
            aria-controls="office"
            aria-selected="false"
          >
            Office Units
          </a>
        </li>
        <i
          className="fa fa-search  searchListHeadButton1"
          aria-hidden="true"
          onClick={() => setRefreshSearch(!refreshSearch)}
        ></i>
      </ul> */}
      <div
        className={dark ? "tab-content  pt-0 mt-0 " : "tab-content  pt-0 mt-0 "}
        style={{ minHeight: "700px" }}
        id="myTabContent"
      >
        <div
          className={
            mainTab === "resident"
              ? "tab-pane fade show active "
              : "tab-pane fade "
          }
          id="resident"
          role="tabpanel"
          aria-labelledby="resident-tab"
        >
          {/* <div className={handleMapMobile()}>
            {map && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d172139.41616514194!2d-122.48214724825026!3d47.61294318356923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1644402332828!5m2!1sen!2slk"
                title="map"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            )}
          </div> */}
          <div className="row  p-0 m-0" style={{}}>
            {/* {rentalListDataHomes === null ? (
              <Loader maxHeight="500px" />
            ) : (
              <>
                <div className=" mapNavigatorRootMobile p-0">
                  <div
                    className={
                      map
                        ? "mapNavigatorMobile  text-center"
                        : " padNavMapMobile text-center"
                    }
                    onClick={() => setMap(!map)}
                  >
                    <i
                      className={
                        dark
                          ? "fa fa-map-marker vert-move text-light"
                          : "fa fa-map-marker vert-move"
                      }
                      style={{ fontSize: "30px" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </>
            )} */}

            {/* <div className={handleMap()}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d172139.41616514194!2d-122.48214724825026!3d47.61294318356923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1644402332828!5m2!1sen!2slk"
                title="map"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div> */}
            {/* <div className="mapNavigatorRoot p-0">
              <div
                className={
                  map ? "mapNavigator  text-right" : " padNavMap text-right"
                }
                onClick={() => setMap(!map)}
              >
                <i
                  className={
                    dark
                      ? "fa fa-map-marker vert-move text-light"
                      : "fa fa-map-marker vert-move"
                  }
                  style={{ fontSize: "30px" }}
                  aria-hidden="true"
                ></i>
              </div>
            </div> */}

            <div className="col RentalUnitsModal">
              <div className="row ">
                {rentalListDataHomes === null ? (
                  <Loader maxHeight="500px" />
                ) : (
                  rentalListDataHomes.length !== 0 &&
                  rentalListDataHomes.map(
                    (unit, index) =>
                      Math.round((index - 6) / 12) === tab && (
                        <div
                          className="col   d-flex justify-content-center"
                          key={"rentalUnit"+index}
                          onClick={() => handelDetail(unit.rentalEntity)}
                          data-target="#exampleModal"
                          data-toggle="modal"
                        >
                          <RentalUnit rentalEntity={unit.rentalEntity} />
                        </div>
                      )
                  )
                )}
                {rentalListDataHomes === null ? (
                  <Loader maxHeight="500px" />
                ) : (
                  rentalListDataHomes.length === 0 &&
                  !spinner2 && (
                    <div className="col text-center pt-5">
                      <span className="span2">No rental units available</span>
                      <br></br>
                      <br></br>
                      <button onClick={() => rentalRefresh()}>Refresh</button>
                    </div>
                  )
                )}
              </div>

              <br></br>
              {rentalListDataHomes === null ? (
                <></>
              ) : (
                rentalListDataHomes.length !== 0 && (
                  <div className="row">
                    <div className="col-md text-center">
                      <button
                        className={
                          tab !== 0
                            ? "roundButton mr-2"
                            : "roundButtonDisable mr-2"
                        }
                        data-placement="top"
                        title="Preview"
                        disabled={tab === 0}
                        onClick={() => setTabFunction(tab - 1)}
                      >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                      </button>
                      {tabArrayRental.length > 2 && tab > 1 && (
                        <>
                          <i className="textPri" style={{ fontSize: "50px" }}>
                            .
                          </i>
                          <i className=" textPri" style={{ fontSize: "50px" }}>
                            .
                          </i>
                          <i className=" textPri" style={{ fontSize: "50px" }}>
                            .
                          </i>
                        </>
                      )}
                      {tabArrayRental.map(
                        (element, index) =>
                          !(index > tab + 1 || index < tab - 1) && (
                            <button
                              className={
                                index === tab
                                  ? "roundButtonActive mr-2"
                                  : "roundButton mr-2"
                              }
                              data-placement="top"
                              title="Preview"
                              disabled={index === tab}
                              onClick={() => setTabFunction(index)}
                              key={"rentalUnitPreview"+index}
                            >
                              {index + 1}
                            </button>
                          )
                      )}
                      {tabArrayRental.length > 2 &&
                        tab < tabArrayRental.length - 2 && (
                          <>
                            <i className="textPri" style={{ fontSize: "50px" }}>
                              .
                            </i>
                            <i
                              className=" textPri"
                              style={{ fontSize: "50px" }}
                            >
                              .
                            </i>
                            <i
                              className=" textPri"
                              style={{ fontSize: "50px" }}
                            >
                              .
                            </i>
                          </>
                        )}
                      <button
                        className={
                          Math.round(rentalListDataHomes.length / 12) > tab + 1
                            ? "roundButton "
                            : "roundButtonDisable"
                        }
                        data-placement="top"
                        title="Next"
                        disabled={
                          Math.round(rentalListDataHomes.length / 12) <= tab + 1
                        }
                        onClick={() => setTabFunction(tab + 1)}
                      >
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div
          className={
            mainTab === "office" ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="office"
          role="tabpanel"
          aria-labelledby="office-tab"
        >
          {/* <div className={handleMapMobile()}>
            {map && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d172139.41616514194!2d-122.48214724825026!3d47.61294318356923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1644402332828!5m2!1sen!2slk"
                title="map"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            )}
          </div> */}
          <div className="row  p-0 m-0" style={{}}>
            {/* {!rentalListDataOffice ? (
              <Loader maxHeight="500px" />
            ) : (
              <>
                <div className=" mapNavigatorRootMobile p-0">
                  <div
                    className={
                      map
                        ? "mapNavigatorMobile  text-center"
                        : " padNavMapMobile text-center"
                    }
                    onClick={() => setMap(!map)}
                  >
                    <i
                      className={
                        dark
                          ? "fa fa-map-marker vert-move text-light"
                          : "fa fa-map-marker vert-move"
                      }
                      style={{ fontSize: "30px" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </>
            )} */}

            {/* <div className={handleMap()}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d172139.41616514194!2d-122.48214724825026!3d47.61294318356923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1644402332828!5m2!1sen!2slk"
                title="map"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div> */}
            {/* <div className="mapNavigatorRoot p-0">
              <div
                className={
                  map ? "mapNavigator  text-right" : " padNavMap text-right"
                }
                onClick={() => setMap(!map)}
              >

                <i
                  className={
                    dark
                      ? "fa fa-map-marker vert-move text-light"
                      : "fa fa-map-marker vert-move"
                  }
                  style={{ fontSize: "30px" }}
                  aria-hidden="true"
                ></i>

              </div>
            </div> */}
            <div className="col RentalUnitsModal">
              <div className="row ">
                {rentalListDataOffice === null ? (
                  <Loader maxHeight="500px" />
                ) : (
                  rentalListDataOffice.length !== 0 &&
                  rentalListDataOffice.map(
                    (unit, index) =>
                      Math.round((index - 6) / 12) === tabOffice && (
                        <div
                          className="col  d-flex justify-content-center"
                          key={"rentalUnitTwo"+index}
                          onClick={() => handelDetail(unit.rentalEntity)}
                          data-target="#exampleModal"
                          data-toggle="modal"
                        >
                          <RentalUnit rentalEntity={unit.rentalEntity} />
                        </div>
                      )
                  )
                )}
                {rentalListDataOffice !== null &&
                  rentalListDataOffice.length === 0 &&
                  !spinner2 && (
                    <div className="col text-center pt-5">
                      <span className="span2">No rental units available</span>
                      <br></br>
                      <br></br>
                      <button onClick={() => rentalRefresh()}>Refresh</button>
                    </div>
                  )}
              </div>
              <br></br>
              {rentalListDataOffice !== null &&
                rentalListDataOffice.length !== 0 && (
                  <div className="row">
                    <div className="col-md text-center">
                      <button
                        className={
                          tabOffice !== 0
                            ? "roundButton mr-2"
                            : "roundButtonDisable mr-2"
                        }
                        data-placement="top"
                        title="Preview"
                        disabled={tabOffice === 0}
                        onClick={() => setTabOfficeFunction(tabOffice - 1)}
                      >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                      </button>
                      {tabArrayOffice.length > 2 && tabOffice > 1 && (
                        <>
                          <i className="textPri" style={{ fontSize: "50px" }}>
                            .
                          </i>
                          <i className=" textPri" style={{ fontSize: "50px" }}>
                            .
                          </i>
                          <i className=" textPri" style={{ fontSize: "50px" }}>
                            .
                          </i>
                        </>
                      )}
                      {tabArrayOffice.map(
                        (element, index) =>
                          !(index > tabOffice + 1 || index < tabOffice - 1) && (
                            <button
                              className={
                                index === tabOffice
                                  ? "roundButtonActive mr-2"
                                  : "roundButton mr-2"
                              }
                              data-placement="top"
                              title="Preview"
                              disabled={index === tabOffice}
                              onClick={() => setTabOfficeFunction(index)}
                              key={"rentalUnitPreviewTwo"+index}
                            >
                              {index + 1}
                            </button>
                          )
                      )}
                      {tabArrayOffice.length > 2 &&
                        tabOffice < tabArrayOffice.length - 2 && (
                          <>
                            <i className="textPri" style={{ fontSize: "50px" }}>
                              .
                            </i>
                            <i
                              className=" textPri"
                              style={{ fontSize: "50px" }}
                            >
                              .
                            </i>
                            <i
                              className=" textPri"
                              style={{ fontSize: "50px" }}
                            >
                              .
                            </i>
                          </>
                        )}
                      <button
                        className={
                          Math.round(rentalListDataOffice.length / 12) >
                          tabOffice + 1
                            ? "roundButton "
                            : "roundButtonDisable"
                        }
                        data-placement="top"
                        title="Next"
                        disabled={
                          Math.round(rentalListDataOffice.length / 12) <=
                          tabOffice + 1
                        }
                        onClick={() => setTabOfficeFunction(tabOffice + 1)}
                      >
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      {modalData && (
        <RentalUnitModal
          modalData={modalData}
          spinner={spinner}
          images={images}
          type="apply"
        />
      )}
    </div>
  );
}

export default withRouter(RentalUnits);
