import React, { Component } from "react";
import image from "../../../../Images/bankIcon.png";
import "../payment.css";
import { withRouter } from "react-router-dom";
import { timestampToDate } from "../../../../Defined/Function";
import { connect } from "react-redux";
import {
  appendAlert,
  appendTempUsers,
  appendTempProfileImage,
  getTempProfileImage,
} from "../../../../actions";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";


class LandlordRecentPaymentListStripe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentImage: image,
      ImageLoader: true,
      tenantIdDetails: [],
    };
  }
  
  render() {
    return (
      this.props.data && (
        <>
          <div className="recentPaymentTableWeb">
            <div className={"row   contractDivider2"}>
              <div className="col-md-1 m-0 p-0  align-self-center d-flex justify-content-center ">
                <img
                  alt={""}
                  src={this.state.paymentImage}
                  className="payeeImage"
                />
              </div>
              <div className="col-md m-0 p-0  pt-2">
                <p className="maintenanceHeadText ">
                  Payout from Axallant {this.props.data.type}
                </p>
              </div>

              <div className="col-md-1 m-0 p-0 text-center align-self-center d-flex justify-content-center">
                <p className={"maintenanceHeadText4 pt-2"}>
                  {timestampToDate(this.props.data.created)}
                </p>
              </div>
              <div className="col-md-2 m-0 p-0 text-right">
                <p
                  className={"maintenanceHeadText3 pt-2 "}
                  style={{
                    color: "#ee745e",
                    fontWeight: 800,
                  }}
                >
                  <CurrencyFormat value={this.props.data.amount} />
                </p>
              </div>
            </div>
          </div>
          <div className="recentPaymentTableMobile">
            <div className={"row   contractDivider3"}>
              <div className="col-3 pb-5  borderTopGray borderLeftGray ">
                <img
                  alt={""}
                  src={this.state.paymentImage}
                  className="mt-4 round payeeImage "
                />
              </div>
              <div className=" col-9    text-left  borderLeftGray borderBottomGray borderTopGray borderRightGray">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Type
                </p>
                <p className="maintenanceHeadText1 pb-3">
                  Payout from Axallant
                </p>
              </div>
              <div className=" col-3   text-left  borderLeftGray borderBottomGray borderTopGray">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Date
                </p>
                <p className={"maintenanceHeadText4 pt-2"}>
                  {timestampToDate(this.props.data.created)}
                </p>
              </div>
              <div className=" col-9   text-left  borderLeftGray borderBottomGray borderRightGray">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Amount
                </p>
                <p className={"maintenanceHeadText4 pt-2"}>
                <CurrencyFormat value={this.props.data.amount} />
                </p>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
    alert: state.alertReducer,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    tempProfileImages: state.tempProfileImagesReducer,
    tempProfileImagesArray: state.tempProfileImages,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempUsers,
    appendTempProfileImage,
    getTempProfileImage,
  })(LandlordRecentPaymentListStripe)
);
