import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Login.css";
import JwtAuthService from "../../Services/JwtAuthService";
import CookieService from "../../Services/CookieService";
import {
  getToken,
  getUser,
  getRole,
  signUp,
  isAvailableUser,
  // url,
} from "../../Services/ApiServices";
import Loader from "../Loader/Loader";
import {
  appendAlert,
  authorize,
  // changePercentageProfileAlert,
  changeRole,
  changeRoleGuest,
  // setOpenProfileAlert,
  setUser,
  unAuthorize,
} from "../../actions";

function Login(props) {
  const [state, setState] = React.useState({
    email: props.location.state ? props.location.state.email : "",
    password: "",
    signUpEmail: props.location.state ? props.location.state.email : "",
    signUpPass: "",
    role: false,
  });
  const [passShow, changePassShow] = useState(false);
  const [passShowSignUp, changePassSignUpShow] = useState(false);
  const [passConfirmShowSignUp, changePassConfirmSignUpShow] = useState(false);
  // const [rememberPass, changeRememberPass] = useState(false); removed remember password in UI
  const [staySignIn, changeStaySignIn] = useState(false);
  const [strength, setStrength] = useState(1);
  const [loader, changeLoader] = useState(false);
  const [alertEmailLogin, changeAlertEmailLogin] = useState(false);
  const [alertPassLogin, changeAlertPassLogin] = useState(false);
  const [loginEmailNull, changeLoginEmailNull] = useState(false);
  const [loginPassNull, changeLoginPassNull] = useState(false);
  const [passLength, changePassLength] = useState(false);
  const [passUpper, changePassUpper] = useState(false);
  const [passNum, changePassNum] = useState(false);
  const [passSpecial, changePassSpecial] = useState(false);
  const [passBox, changePassBox] = useState(false);
  const dispatch = useDispatch();

  const AuthCheck = () => {
    let UserData = CookieService.getArrayCookie("axUserDetails");
    let roleNo = CookieService.getArrayCookie("axUserRole");
    dispatch(setUser(UserData));
    if (UserData !== "" && roleNo !== "" && roleNo.length > 0) {
      dispatch(authorize());
      dispatch(changeRole(roleNo[0].id));
    } else {
      if (roleNo.length === 0) {
        appendAlert("Role data is missing", "danger");
      }
      dispatch(unAuthorize());
      dispatch(changeRoleGuest());
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";

    window.addEventListener("click", function (event) {
      if (
        event.target.className !== "tag signUpPassword" &&
        event.target.className !== "tag signUpPasswordConfirm" &&
        event.target.className !== "signUpPassword ApplicationInput" &&
        event.target.className !== "signUpPasswordConfirm ApplicationInput"
      ) {
        changePassBox(false);
      }
    });
    if (
      props.location.pathname.split("/")[1] === "application" ||
      props.location.pathname.split("/")[1] === "profile" ||
      props.location.pathname.split("/")[1] === "accept-contract"
    ) {
      if (props.location.state) {
        if (props.location.state.email !== undefined) {
          isAvailableUser(props.location.state.email).then((response) => {
            if (!response.data) {
              props.changeSign(false);
            }
          });
        }
      }
    }

    if (CookieService.checkCookie("rememberPassword")) {
      if (CookieService.getCookie("rememberPassword") === "true") {
        setState({
          ...state,
          email: CookieService.getCookie("axUser"),
          password: CookieService.getCookie("axPass"),
        });
      }
    }
    // eslint-disable-next-line
  }, []);
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      signInSubmit();
    }
  };
  const onKeyUp1 = (event) => {
    if (event.key === "Enter") {
      signInSubmit();
    }
  };

  function signIn() {
    props.changeSign(true);
    if (
      props.location.pathname.split("/")[1] !== "application" &&
      props.location.pathname.split("/")[1] !== "profile" &&
      props.location.pathname.split("/")[1] !== "accept-contract"
    ) {
      props.history.push({ pathname: "/login" });
    }
  }
  function signUpFun() {
    props.changeSign(false);
    if (
      props.location.pathname.split("/")[1] !== "application" &&
      props.location.pathname.split("/")[1] !== "profile" &&
      props.location.pathname.split("/")[1] !== "accept-contract"
    ) {
      props.history.push({ pathname: "/signup" });
    }
  }
  function openPassBox() {
    if (strength < 5) {
      changePassBox(true);
    }
  }

  //submit signUp
  function signUpSubmit() {
    isAvailableUser(state.signUpEmail).then((response) => {
      if (!response.data) {
        if (state.signUpPass === "" || state.signUpEmail === "") {
          dispatch(appendAlert("Please fill out all fields !", "warning"));
        } else {
          if (strength > 4) {
            if (state.signUpPass === state.signUpConfirmPass) {
              changeLoader(true);
              let body = {
                username: state.signUpEmail,
                password: state.signUpPass,
                enabled: props.location.state
                  ? props.location.state.type === "directEmailTenantLogin" &&
                    state.signUpEmail === props.location.state.email
                    ? true
                    : false
                  : false,
                accountNonExpired: true,
                credentialsNonExpired: true,
                accountNonLocked: true,
                firstName: "",
                lastName: "",
                middleName: "",
                provider: "local",
                mobileNumber: "",
                address: {
                  address: null,
                  city: null,
                  state: null,
                  zip: null,
                  country: null,
                  lat: 0.0,
                  lng: 0.0,
                },
              };
              let roleEx = 0;
              if (state.role) roleEx = 2;
              else roleEx = 1;
              signUp(roleEx, body)
                .then((response1) => {
                  changeLoader(false);
                  if (response1.status === 200) {
                    if (
                      props.location.pathname.split("/")[1] === "application" ||
                      props.location.pathname.split("/")[1] === "profile" ||
                      props.location.pathname.split("/")[1] ===
                        "accept-contract"
                    ) {
                      props.changeSign(true);
                    } else {
                      props.changeSign(true);
                      props.history.push({
                        pathname: "/login",
                      });
                    }

                    dispatch(
                      appendAlert("Account created successfully", "success")
                    );
                    if (props.location.state) {
                      if (
                        props.location.state.type !== "directEmailTenantLogin"
                      ) {
                        dispatch(
                          appendAlert(
                            "The verification link has been sent to your email",
                            "info"
                          )
                        );
                      }
                    } else {
                      dispatch(
                        appendAlert(
                          "The verification link has been sent to your email",
                          "info"
                        )
                      );
                    }
                    setState({
                      ...state,
                      email: state.signUpEmail,
                      password: state.signUpPass,
                    });
                  } else {
                    dispatch(appendAlert(response1.data.message, "danger"));
                  }
                })
                .catch((error) => {
                  dispatch(appendAlert(error.message, "danger"));
                });
            } else {
              dispatch(
                appendAlert(
                  "Those passwords didn't match. Try again. ",
                  "warning"
                )
              );
              setState({
                ...state,
                setSignUpPass: "",
                setSignUpConfirmPass: "",
              });
            }
          } else {
            dispatch(
              appendAlert(
                <div className="">
                  <span style={{ fontSize: "14px" }}>
                    &nbsp;&nbsp;Please make sure your password contain:
                  </span>
                  <ul
                    class="pt-2"
                    style={{ listStyleType: "none", marginLeft: "-20px" }}
                  >
                    <li>
                      {!passLength && (
                        <>
                          <i
                            className="fa fa-dot-circle-o "
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;<span>At least 8 characters</span>
                        </>
                      )}
                    </li>
                    <li>
                      {!passUpper && (
                        <>
                          <i
                            className="fa fa-dot-circle-o "
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;<span>At least 1 upper case letter</span>
                        </>
                      )}
                    </li>
                    <li>
                      {!passNum && (
                        <>
                          <i
                            className="fa fa-dot-circle-o "
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;<span>At least 1 number</span>
                        </>
                      )}
                    </li>
                    <li>
                      {!passSpecial && (
                        <>
                          <i
                            className="fa fa-dot-circle-o "
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;<span>At least 1 special character</span>
                        </>
                      )}
                    </li>
                  </ul>
                </div>,
                "warning"
              )
            );
            // if (strength === 4) {
            //   dispatch(
            //     appendAlert(
            //       "Your password strength is Fair,please give strong password!",
            //       "warning"
            //     )
            //   );
            // } else if (strength === 3) {
            //   dispatch(
            //     appendAlert(
            //       "Your password strength is bad ,please give strong password!",
            //       "warning"
            //     )
            //   );
            // } else if (strength === 2 || strength === 1) {
            //   dispatch(
            //     appendAlert(
            //       "Your password strength is week ,please give strong password!",
            //       "warning"
            //     )
            //   );
            // }
          }
        }
      } else {
        props.changeSign(true);
        dispatch(appendAlert("This user id already in used", "warning"));
      }
    });
  }
  function signInSubmit() {
    loginWithEmailAndPassword(state.email, state.password);
  }
  //submit signIn
  const loginWithEmailAndPassword = (email, password) => {
    if (email === "" || password === "") {
      dispatch(appendAlert("Please fill out all fields !", "warning"));
    } else {
      if (window.navigator.onLine) {
        changeLoader(true);
        getToken(email, password).then(async (data) => {
          if (!data.error) {
            await JwtAuthService.setSession(
              data.access_token,
              data.refresh_token
            );
            getUser()
              .then((data) => {
                getRole().then((role) => {
                  JwtAuthService.setRole(role.data.roles);
                  AuthCheck();
                  changeLoader(false);
                  let count = 0;
                  if (
                    data.data.username !== "" &&
                    data.data.username !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.firstName !== "" &&
                    data.data.firstName !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.lastName !== "" &&
                    data.data.lastName !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.mobileNumber !== "" &&
                    data.data.mobileNumber !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.address.address !== "" &&
                    data.data.address.address !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.address.state !== "" &&
                    data.data.address.state !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.address.city !== "" &&
                    data.data.address.city !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.address.zip !== "" &&
                    data.data.address.zip !== null
                  ) {
                    count++;
                  }
                  if (
                    data.data.address.country !== "" &&
                    data.data.address.country !== null
                  ) {
                    count++;
                  }

                  let path = "";
                  if (staySignIn) {
                    CookieService.setCookie("rememberPassword", "true", 7);
                    CookieService.setCookie("staySignIn", "true", 7);
                    CookieService.setCookie("axUser", email, 7);
                    CookieService.setCookie("axPass", password, 7);
                  } else {
                    CookieService.setCookie("staySignIn", "false", 7);
                    CookieService.setCookie("rememberPassword", "true", 7);
                    CookieService.setCookie("axUser", email, 7);
                    CookieService.setCookie("axPass", password, 7);
                  }
                  if (role.data.roles.length > 0) {
                    if (role.data.roles[0].id === 3) {
                      props.history.push({ pathname: "/dashboard" });
                    } else {
                      if (count !== 9) {
                        if (props.location.state) {
                          if (props.location.state.rentalEntityId) {
                            if (props.location.state.rentalEntityId !== null) {
                              props.history.push({
                                pathname: "/profile",
                                state: {
                                  rentalEntityId:
                                    props.location.state.rentalEntityId,
                                },
                              });
                            } else {
                              props.history.push({
                                pathname: "/profile",
                                state: {
                                  rentalEntityId: 0,
                                },
                              });
                            }
                          } else {
                            props.history.push({
                              pathname: "/profile",
                              state: {
                                rentalEntityId: 0,
                              },
                            });
                          }
                        } else {
                          props.history.push({
                            pathname: "/profile",
                            state: {
                              rentalEntityId: 0,
                            },
                          });
                        }
                      } else if (props.hitPoint === "/rental-units") {
                        if (role.data.roles[0].id === 2) {
                          props.history.push({ pathname: "/properties" });
                        } else {
                          if (props.match.params.id) {
                            props.history.push({
                              pathname:
                                "/rental-units/" + props.match.params.id,
                            });
                          } else {
                            props.history.push({ pathname: "/rental-units" });
                          }
                        }
                      } else if (
                        props.hitPoint === "/application" &&
                        role.data.roles[0].id === 2
                      ) {
                        props.history.push({ pathname: "/rental-units" });
                        dispatch(
                          appendAlert(
                            "Sorry!!! Landlord can't Apply Units",
                            "warning"
                          )
                        );
                      } else if (props.hitPoint === "/application") {
                        props.history.push({
                          pathname:
                            "/application" +
                            (props.match.params.id
                              ? "/" + props.match.params.id
                              : ""),
                          state: props.history.location.state,
                        });
                      } else if (props.hitPoint === "/application/secondary") {
                        props.history.push({
                          pathname:
                            "/application/secondary/" +
                            props.match.params.id +
                            "/" +
                            props.match.params.appId +
                            "/" +
                            props.match.params.applicant +
                            "/" +
                            props.match.params.order,
                          state: props.history.location.state,
                        });
                      } else if (props.hitPoint === "/create-contract/british_columbia") {
                        props.history.push("/applications");
                      } else {
                        path = props.hitPoint;
                        props.history.push({
                          pathname: path,
                          state: {
                            access_token: data.access_token,
                            user: CookieService.getArrayCookie("axUserDetails"),
                          },
                        });
                      }
                    }
                  } else {
                    appendAlert("Role data is missing", "danger");
                  }
                });
                JwtAuthService.setUser(data.data);
                return data.data;
              })
              .catch();
          } else {
            if (data.error === "unauthorized") {
              dispatch(
                appendAlert(
                  "Please verify your account. Check your email !",
                  "warning"
                )
              );
              changeLoader(false);
            } else {
              changeAlertEmailLogin(true);
              changeAlertPassLogin(true);
              if (email === "") {
                changeLoginEmailNull(true);
              }
              if (password === "") {
                changeLoginPassNull(true);
              }
              dispatch(
                appendAlert(
                  "The credentials you provided were incorrect. Please try again!",
                  "danger"
                )
              );
              changeLoader(false);
            }
          }
        });
      } else {
        if (window.confirm("You are now offline, please Reconnect ... ")) {
          window.location.reload();
        }
      }
    }
  };
  //password strength check
  const handleChangePassword = (event) => {
    changePassUpper(false);
    changePassNum(false);
    changePassLength(false);
    changePassSpecial(false);
    let pass = event.target.value;
    const {
      target: { name, value },
    } = event;
    setState({ ...state, [name]: value });
    let x = 1;
    if (checkPasswordLength(pass)) {
      x = x + 1;
    }
    if (checkSpecialCharacters(pass)) {
      x = x + 1;
    }
    if (checkUppercase(pass)) {
      x = x + 1;
    }
    if (checkNumber(pass)) {
      x = x + 1;
    }
    if (pass === "") {
      x = 0;
    }
    if (x > 4) {
      changePassBox(false);
    } else {
      openPassBox();
    }
    setStrength(x);
  };
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      changePassLength(true);
      return true;
    }
    return false;
  };
  const check = () => {
    let checkbox = document.getElementById("unitCheck");
    let checkbox1 = document.getElementById("unitCheck1");
    if (staySignIn === false) {
      checkbox.checked = true;
      checkbox1.checked = true;
      changeStaySignIn(true);
    } else {
      checkbox.checked = false;
      checkbox1.checked = false;
      changeStaySignIn(false);
    }
  };
  const checkLandlord1 = () => {
    let checkbox1 = document.getElementById("unitCheckLandlord1");
    if (state.role === false) {
      checkbox1.checked = true;
      setState({ ...state, role: true });
    } else {
      checkbox1.checked = false;
      setState({ ...state, role: false });
    }
  };
  //check landlord
  const checkLandlord = () => {
    let checkbox = document.getElementById("unitCheckLandlord");

    if (state.role === false) {
      checkbox.checked = true;

      setState({ ...state, role: true });
    } else {
      checkbox.checked = false;

      setState({ ...state, role: false });
    }
  };
  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g; //eslint-disable-line
    if (pattern.test(password)) {
      changePassSpecial(true);
      return true;
    }
    return false;
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      changePassUpper(true);
      return true;
    }
    return false;
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      changePassNum(true);
      return true;
    }
    return false;
  };
  async function onchange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });

    if (name === "email") {
      changeLoginEmailNull(false);
      changeAlertEmailLogin(false);
      changeAlertPassLogin(false);
    }
    if (name === "password") {
      changeLoginPassNull(false);
      changeAlertPassLogin(false);
      changeAlertEmailLogin(false);
    }
  }

  function handleContainer() {
    let classes = "";
    classes +=
      props.location.pathname === "/login"
        ? "container"
        : props.location.pathname === "/signup"
        ? "container right-panel-active"
        : props.location.pathname.split("/")[1] === "application" ||
          props.location.pathname.split("/")[1] === "profile" ||
          props.location.pathname.split("/")[1] === "accept-contract"
        ? props.sign
          ? "container"
          : "container right-panel-active"
        : "container";
    return classes;
  }
  return loader ? (
    <div>
      <Loader />
    </div>
  ) : (
    <>
      <div className="loginModal">
        <div className={handleContainer()} id="container">
          <div className="form-container sign-up-container">
            <div
              action="/login"
              className="dropup formlogin"
              style={{ display: "block" }}
            >
              <br></br>
              <h1 className="loginHead">Create Account</h1>
              <br></br>

              <div className="social-container ">
                {/* <a
                  href={url + "oauth2/authorization/facebook"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i
                    className="fa fa-facebook text-dark"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                <a
                  href={url + "oauth2/authorization/google"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-google text-dark" aria-hidden="true"></i>{" "}
                </a> */}
              </div>
              {/* <span>or use your email for registration</span> */}

              <input
                className="tag"
                type="email"
                placeholder="Email"
                name="signUpEmail"
                value={state.signUpEmail}
                onChange={onchange}
                onKeyPress={onKeyUp1}
              />
              <b>{props.match.params.tenantEmail}</b>

              <input
                className="tag signUpPassword"
                type={!passShowSignUp ? "password" : "text"}
                placeholder="Password"
                name="signUpPass"
                value={state.signUpPass}
                onChange={handleChangePassword}
                onKeyPress={onKeyUp1}
                onClick={() => openPassBox()}
                onFocus={() => openPassBox()}
              />
              {!passShowSignUp ? (
                <i
                  className={
                    " fa fa-eye-slash  fa-10x absolute pointer iconPassShowSignUp"
                  }
                  aria-hidden="true"
                  onClick={() => changePassSignUpShow(true)}
                ></i>
              ) : (
                <i
                  className={
                    " fa fa-eye  fa-10x absolute pointer iconPassShowSignUp"
                  }
                  aria-hidden="true"
                  onClick={() => changePassSignUpShow(false)}
                ></i>
              )}
              <div
                className={
                  passBox
                    ? "passwordRuleBox p-0 show float-left"
                    : "passwordRuleBox p-0 hide"
                }
              >
                <div className=" passDropHead p-1 ">
                  {" "}
                  <span className="pl-2 ">
                    <b>Strong Password Requirements</b>
                  </span>
                </div>
                <div className=" passDropContent p-2 pr-3  ">
                  <span>Your password should contain:</span>
                  <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                    <li class="d-flex align-items-start">
                      {passLength ? (
                        <i
                          className="fa fa-check-circle-o text-success "
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 8 characters</span>
                    </li>
                    <li class="d-flex align-items-start">
                      {passUpper ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 1 upper case letter</span>
                    </li>
                    <li class="d-flex align-items-start">
                      {passNum ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 1 number</span>
                    </li>
                    <li class="d-flex align-items-start">
                      {passSpecial ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 1 special character</span>
                    </li>
                  </ul>
                </div>
                <span className="pl-3 d-flex align-items-start bg-white border p-1">
                  <strong>strength:</strong>&nbsp;&nbsp;
                  {strength === 1
                    ? "Very Bad"
                    : strength === 2
                    ? "Bad"
                    : strength === 3
                    ? "OK"
                    : strength === 4
                    ? "Good"
                    : strength === 5
                    ? "Strong"
                    : ""}
                </span>
              </div>
              {strength === 1 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 2 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "40%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 3 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: "60%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 4 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: "80%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 5 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-light"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              )}
              <input
                className="tag signUpPasswordConfirm"
                type={!passConfirmShowSignUp ? "password" : "text"}
                placeholder="Confirm Password"
                name="signUpConfirmPass"
                value={state.signUpConfirmPass}
                onChange={onchange}
                onKeyPress={onKeyUp1}
                onClick={() => openPassBox()}
                onFocus={() => openPassBox()}
              />
              {!passConfirmShowSignUp ? (
                <i
                  className={
                    " fa fa-eye-slash  fa-10x absolute pointer iconPassShowSignUp2"
                  }
                  aria-hidden="true"
                  onClick={() => changePassConfirmSignUpShow(true)}
                ></i>
              ) : (
                <i
                  className={
                    " fa fa-eye  fa-10x absolute pointer iconPassShowSignUp2"
                  }
                  aria-hidden="true"
                  onClick={() => changePassConfirmSignUpShow(false)}
                ></i>
              )}
              <div className="container-fluid pt-2 ">
                {/* <div className="row"> */}
                {/* <div className="col d-flex justify-items-start"> */}
                {/* <input
                      className="toggle mr-2 mt-2"
                      type="checkbox"
                      id="exampleCheck1"
                    />
                    <span className="pt-2" htmlFor="exampleCheck1">
                      I agree to the{" "}
                      <span className="term">terms of service</span>
                    </span> */}
                {/* </div> */}
                {/* </div> */}
                <div
                  className="custom-control custom-checkbox item-left pointer pb-4"
                  onClick={() => checkLandlord()}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input listCheck pointer"
                    id="unitCheckLandlord"
                    style={{ background: "#000" }}
                  />
                  <label
                    className="custom-control-label span2 pt-1 pointer"
                    htmlFor="customCheck1"
                  >
                    <b className="landlordLogin">I am a Landlord</b>
                  </label>
                </div>
                {/* <div className="row text-left ">
                  <div className="col  ">
                    <label className="switch ">
                      <input
                        type="checkbox"
                        id="togBtn "
                        name="role"
                        checked={state.role ? "checked" : ""}
                        onChange={onchangeCheck}
                      />
                      <div className="slider round">
                        <span className="on">
                          <b>LandLoad</b>
                        </span>
                        <span className="off">
                          <b>Tenant</b>
                        </span>
                      </div>
                    </label>
                  </div>
                </div> */}
              </div>
              <button
                onClick={() => signUpSubmit()}
                name="signUpSubmit"
                id="signUpSubmit"
                className="mt-2"
                // onClick={() =>
                //   dispatch(
                //     appendAlert("Currently you can't register the new user.","warning")
                //   )
                // }
              >
                Sign Up
              </button>
            </div>
          </div>
          <div className="form-container sign-in-container">
            <div action="/login" className="formlogin">
              <h1 className="loginHead">Sign in</h1>
              <div className="social-container">
                {/* <a
                  href={url + "oauth2/authorization/facebook"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i
                    className="fa fa-facebook text-dark"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                <a
                  href={url + "oauth2/authorization/google"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-google text-dark" aria-hidden="true"></i>{" "}
                </a> */}
              </div>
              {/* <span>or use your account</span> */}
              <input
                className={alertEmailLogin ? "tag alert" : "tag"}
                type="email"
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={onchange}
                onKeyPress={onKeyUp}
              />
              {loginEmailNull && (
                <span className="spanvalidate textPri">Email is Required</span>
              )}

              <input
                className={alertPassLogin ? "tag alert" : "tag"}
                type={!passShow ? "password" : "text"}
                name="password"
                placeholder="Password"
                value={state.password}
                style={{ paddingRight: "40px" }}
                onChange={onchange}
                onKeyPress={onKeyUp}
              />

              {!passShow ? (
                <i
                  className={
                    " fa fa-eye-slash  fa-10x absolute pointer iconPassShow"
                  }
                  aria-hidden="true"
                  onClick={() => changePassShow(true)}
                ></i>
              ) : (
                <i
                  className={" fa fa-eye  fa-10x absolute pointer iconPassShow"}
                  aria-hidden="true"
                  onClick={() => changePassShow(false)}
                ></i>
              )}
              {loginPassNull && (
                <span className="spanvalidate textPri">
                  Password is required
                </span>
              )}
              <div
                className="custom-control custom-checkbox item-left pointer  "
                onClick={() => check()}
              >
                <input
                  type="checkbox"
                  className="custom-control-input listCheck pointer "
                  id="unitCheck"
                  style={{ background: "#000" }}
                />
                <label
                  className="custom-control-label span2 pt-1 pointer"
                  htmlFor="customCheck1"
                >
                  Keep me signed in
                </label>
              </div>
              {/* <div
                className="custom-control custom-checkbox item-left pointer "
                onClick={() => check()}
              >
                <input
                  type="checkbox"
                  className="custom-control-input listCheck pointer"
                  id="unitCheck"
                  style={{ background: "#000" }}
                />
                <label
                  className="custom-control-label span2 pt-1 pointer"
                  htmlFor="customCheck1"
                >
                  Remember password
                </label>
              </div> */}
              <span
                style={{ textDecoration: "none" }}
                className=" pt-4 pb-3 loginForgot"
                onClick={() => {
                  props.history.push(
                    "/forgot-password/" +
                      (state.email === "" ? "user" : state.email)
                  );
                }}
              >
                Forgot your password?
              </span>
              <button
                name="signInSubmit"
                id="signInSubmit"
                onClick={() => signInSubmit()}
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="overlay-container">
            <div className="overlay bgPriRad">
              <div className="overlay-panel overlay-left">
                <a className=" logo" href="/">
                  Axallant
                </a>
                <br></br>
                <br></br>
                <br></br>
                <h1 className="loginHead">Welcome Back!</h1>
                <p>Please login</p>
                <button className="ghost" id="signIn" onClick={() => signIn()}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <a className="logo" href="/">
                  Axallant
                </a>
                <br></br>
                <br></br>
                <br></br>
                <h1 className="loginHead">Hello, there!</h1>

                <p>
                  Sign up here if you’re a landlord, a prosepective renter, or
                  if you rent or manage a property managed by Axallant.
                </p>
                <button
                  className="ghost"
                  id="signUp"
                  onClick={() => signUpFun()}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="loginModal-mobile ">
        <div className="container-mobile " id="container">
          {props.sign ? (
            <div className="form-container sign-in-container-mobile ">
              <div
                action="/login"
                className="dropup formlogin"
                style={{ display: "block" }}
              >
                <br></br>
                <br></br>
                <br></br>

                <h1 className="loginHead">Sign in</h1>
                <br></br>
                <div className="social-container">
                  {/* <a
                    href={url + "oauth2/authorization/facebook"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-facebook text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                  <a
                    href={url + "oauth2/authorization/google"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-google text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a> */}
                </div>
                {/* <span className="span2">or use your account</span> */}
                <input
                  className={
                    alertEmailLogin
                      ? "ApplicationInput alert"
                      : "ApplicationInput"
                  }
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={state.email}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />
                {loginEmailNull && (
                  <span className="spanvalidate textPri">
                    Email is Required
                  </span>
                )}
                <input
                  className={
                    alertPassLogin
                      ? "ApplicationInput alert"
                      : "ApplicationInput"
                  }
                  type={!passShow ? "password" : "text"}
                  name="password"
                  placeholder="Password"
                  onChange={onchange}
                  style={{ paddingRight: "40px" }}
                  onKeyPress={onKeyUp}
                  value={state.password}
                />
                {!passShow ? (
                  <i
                    className={
                      " fa fa-eye-slash  fa-10x absolute pointer iconPassShow "
                    }
                    aria-hidden="true"
                    onClick={() => changePassShow(true)}
                  ></i>
                ) : (
                  <i
                    className={
                      " fa fa-eye  fa-10x absolute pointer iconPassShow "
                    }
                    aria-hidden="true"
                    onClick={() => changePassShow(false)}
                  ></i>
                )}
                {loginPassNull && (
                  <span className="spanvalidate textPri mt-3">
                    Keep me signed in
                  </span>
                )}
                <br></br>
                <div
                  className="custom-control custom-checkbox item-left pointer pt-3 pb-3"
                  onClick={() => check()}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input listCheck pointer"
                    id="unitCheck1"
                    style={{ background: "#000" }}
                  />
                  <label
                    className="custom-control-label span3 pt-1"
                    htmlFor="customCheck1"
                  >
                    Keep me signed in
                  </label>
                </div>
                {/* <div
                  className="custom-control custom-checkbox item-left pointer"
                  onClick={() => check()}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input listCheck pointer"
                    id="unitCheck1"
                    style={{ background: "#000" }}
                  />
                  <label
                    className="custom-control-label span3 pt-1"
                    htmlFor="customCheck1"
                  >
                    Remember Password
                  </label>
                </div> */}
                <br></br>
                <br></br>

                <span
                  style={{ textDecoration: "none" }}
                  className=" mt-5 pb-3  span2 loginForgot"
                  onClick={() => {
                    props.history.push(
                      "/forgot-password/" +
                        (state.email === "" ? "user" : state.email)
                    );
                  }}
                >
                  Forgot your password?
                </span>
                <br></br>
                <br></br>

                <button
                  name="signInSubmit"
                  id="signInSubmit"
                  onClick={() => signInSubmit()}
                >
                  Sign In
                </button>
                <br></br>
                <br></br>
                <span
                  style={{ textDecoration: "none" }}
                  className="text-dark pt-2 pb-3 pointer span2"
                >
                  You don't have account?
                </span>
                <br></br>
                <br></br>
                <button className="pri" id="signUp" onClick={() => signUpFun()}>
                  Sign Up
                </button>
              </div>
            </div>
          ) : (
            <div className="form-container sign-up-container-mobile pt-5">
              <div
                action="/login"
                className="dropup formlogin"
                style={{ display: "block" }}
              >
                <h1 className="loginHead">Create Account</h1>
                <br></br>

                <div className="social-container ">
                  {/* <a
                    href={url + "oauth2/authorization/facebook"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-facebook text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                  <a
                    href={url + "oauth2/authorization/google"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-google text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a> */}
                </div>
                {/* <span className="span3">
                  or use your email for registration
                </span> */}

                <input
                  className=" ApplicationInput"
                  type="email"
                  placeholder="Email"
                  name="signUpEmail"
                  value={state.signUpEmail}
                  onChange={onchange}
                  onKeyPress={onKeyUp1}
                />
                <input
                  className="signUpPassword ApplicationInput"
                  type={!passShowSignUp ? "password" : "text"}
                  placeholder="Password"
                  name="signUpPass"
                  value={state.signUpPass}
                  onChange={handleChangePassword}
                  onKeyPress={onKeyUp1}
                  onClick={() => openPassBox()}
                  onFocus={() => openPassBox()}
                />
                {!passShowSignUp ? (
                  <i
                    className={
                      " fa fa-eye-slash  fa-10x absolute pointer iconPassShowSignUp"
                    }
                    aria-hidden="true"
                    onClick={() => changePassSignUpShow(true)}
                  ></i>
                ) : (
                  <i
                    className={
                      " fa fa-eye  fa-10x absolute pointer iconPassShowSignUp"
                    }
                    aria-hidden="true"
                    onClick={() => changePassSignUpShow(false)}
                  ></i>
                )}
                <div
                  className={
                    passBox
                      ? " p-0 passwordRuleBox show float-left"
                      : "passwordRuleBox p-0 hide"
                  }
                >
                  <div className=" passDropHead p-1 ">
                    {" "}
                    <span className="pl-2 ">
                      <b>Strong Password Requirements</b>
                    </span>
                  </div>
                  <div className=" passDropContent p-2 pr-3">
                    <span className="span3">Your password should contain:</span>
                    <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                      <li class="d-flex align-items-start">
                        {passLength ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 8 characters</span>
                      </li>
                      <li class="d-flex align-items-start">
                        {passUpper ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 1 upper case letter</span>
                      </li>
                      <li class="d-flex align-items-start">
                        {passNum ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 1 number</span>
                      </li>
                      <li class="d-flex align-items-start">
                        {passSpecial ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 1 special character</span>
                      </li>
                    </ul>
                  </div>
                  <span className="pl-3 d-flex align-items-start bg-white border p-1">
                    <strong>strength:</strong>&nbsp;&nbsp;
                    {strength === 1
                      ? "Very Bad"
                      : strength === 2
                      ? "Bad"
                      : strength === 3
                      ? "OK"
                      : strength === 4
                      ? "Good"
                      : strength === 5
                      ? "Strong"
                      : ""}
                  </span>
                </div>
                {strength === 1 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "20%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 2 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "40%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 3 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 4 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 5 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-light"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                <input
                  className="signUpPasswordConfirm ApplicationInput"
                  type={!passConfirmShowSignUp ? "password" : "text"}
                  placeholder="Confirm Password"
                  name="signUpConfirmPass"
                  value={state.signUpConfirmPass}
                  onChange={onchange}
                  onKeyPress={onKeyUp1}
                  onClick={() => openPassBox()}
                  onFocus={() => openPassBox()}
                />
                {!passConfirmShowSignUp ? (
                  <i
                    className={
                      " fa fa-eye-slash  fa-10x absolute pointer iconPassShowSignUp2"
                    }
                    aria-hidden="true"
                    onClick={() => changePassConfirmSignUpShow(true)}
                  ></i>
                ) : (
                  <i
                    className={
                      " fa fa-eye  fa-10x absolute pointer iconPassShowSignUp2"
                    }
                    aria-hidden="true"
                    onClick={() => changePassConfirmSignUpShow(false)}
                  ></i>
                )}
                <div className="container-fluid pt-2 ">
                  {/* <div className="row"> */}
                  {/* <div className="col d-flex justify-items-start"> */}
                  {/* <input
                        className="toggle mr-2 mt-2"
                        type="checkbox"
                        id="exampleCheck1"
                      />
                      <span className="pt-2" htmlFor="exampleCheck1">
                        I agree to the{" "}
                        <span className="term">terms of service</span>
                      </span> */}
                  {/* </div> */}
                  {/* </div> */}
                  <div
                    className="custom-control custom-checkbox item-left pointer pb-4"
                    onClick={() => checkLandlord1()}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input listCheck pointer"
                      id="unitCheckLandlord1"
                      style={{ background: "#000" }}
                    />
                    <label
                      className="custom-control-label span2 pt-1 pointer"
                      htmlFor="customCheck1"
                    >
                      <b className="landlordLogin">I am a Landlord</b>
                    </label>
                  </div>
                  {/* <div className="row text-left pt-2 ">
                    <div className="col  ">
                      <label className="switch ">
                        <input
                          type="checkbox"
                          id="togBtn "
                          name="role"
                          checked={state.role ? "checked" : ""}
                          onChange={onchangeCheck}
                        />
                        <div className="slider round">
                          <span className="on">
                            <b>LandLoad</b>
                          </span>
                          <span className="off">
                            <b>Tenant</b>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div> */}
                </div>
                <br></br>

                <button
                  onClick={() => signUpSubmit()}
                  name="signUpSubmit"
                  id="signUpSubmit"
                  // className="mt-2"
                >
                  Sign Up
                </button>
                <br></br>
                <br></br>
                <span
                  style={{ textDecoration: "none" }}
                  className="text-dark pt-2 pb-3 pointer span3"
                >
                  Do you already have account?
                </span>
                <br></br>

                <button
                  className="pri mt-3 mb-5"
                  id="signIn"
                  onClick={() => signIn()}
                >
                  Sign In
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(Login);
