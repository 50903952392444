import React, { Component } from "react";
import "./property.css";
import Dropzone from "react-dropzone";
import ApplicationInput from "../../UIServices/ApplicationInput";
import { withRouter } from "react-router-dom";
import Geocode from "react-geocode";
import { CountryJson } from "./CountryJson";

import {
  addProperty,
  uploadPropertyImage,
  geoKey,
  updateProperty,
  url,
  getPropertyById,
  createPaymentAccount,
  checkPaymentAccountDetail,
  addPropertyByAxallant,
} from "../../../Services/ApiServices";

import SearchInput from "../../Pages/Application/SearchInput";
import SmallModalContainer from "../Documents/SmallModalContainer";
import Loader3 from "../../Loader/loader3";
import { connect } from "react-redux";
import { setAccountPaymentStatus, appendAlert, getProperty ,setProperty} from "../../../actions";
import Loader from "../../Loader/Loader";

class AddProp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeAccountId: null,
      isLoading: true,
      coverImage: "",
      country: "United States",

      address: "",
      state1: "",
      countryList: [],
      stateList: [],
      cityList: [],
      city: "",

      zip: "",
      propertyType: "",
      unitType: "",
      numUnit: "",
      unitArray: [],
      checkNumUnit: false,
      floorNum: "",
      checkFloorNum: false,
      canSubmit: false,
      redirect: false,
      countryJson: [],
      stateJson: [],
      cityJson: [],
      type: this.props.location.pathname.split("/")[3],
      next: false,
      nextEnable: false,
      errorMessageFloor: "number of floors",
      leave: true,
      isButtonDisable: false,
      x: [
        "my",
        "name",
        "is",
        "bavaram",
        "my",
        "name",
        "is",
        "bavaram",
        "my",
        "name",
        "is",
        "bavaram",
      ],
      search: "",
      apartmentName: "",
      managerName: "",
      managerPhone: "",
    };
    this.onchange = this.onchange.bind(this);
    this.checkFloorNum = this.checkFloorNum.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  checkValidate(value) {
    return (
      (value === "" && this.state.next) ||
      (value === undefined && this.state.next)
    );
  }
  checkValidateMobile(value) {
    return value === "" && this.state.next;
  }
  makeSearchable() {}
  async changeState(name, value) {
    if (name === "floorNum") {
      value = value.replace(/[^0-9-,,]/g, "");
      let x = value.split(",");
      if (value.substr(-1) === ",") {
        for (let i = 0; i < x.length; i++) {
          let y = x[i].split("-");
          if (y.length > 1) {
            if (parseInt(y[0]) > parseInt(y[1])) {
              this.setState({
                errorMessageFloor: "correct format number of floors",
              });
              x[i] = y[0] + "-";
            }
          }
        }
        value = x.toString();
      } else {
        for (const element of x) {
          let y = element.split("-");
          if (y.length > 1) {
            if (parseInt(y[0]) > parseInt(y[1])) {
              this.setState({
                errorMessageFloor: "correct format number of floors",
              });
            }
          }
        }
      }

      if (value === ",") {
        value = "";
      }
      value = value.replace("-,", "-");
      value = value.replace(",-", ",");
      await this.setState({ [name]: value });
    } else {
      await this.setState({ [name]: value });
    }

    if (name === "unitType" && value === "Single") {
      if (this.state.propertyType === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: false });
        this.setState({ numUnit: "", floorNum: "", unitArray: [] });
      } else if (
        this.state.propertyType === "House" ||
        this.state.propertyType === "Office"
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: false });
        this.setState({ floorNum: "", numUnit: "", unitArray: [] });
      }
    } else if (name === "unitType" && value === "Multiple") {
      if (this.state.propertyType === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: true });
      } else if (
        this.state.propertyType === "House" ||
        this.state.propertyType === "Office"
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: true });
        this.setState({ floorNum: "", numUnit: "" });
      }
    } else if (this.state.unitType === "Single") {
      if (name === "propertyType" && value === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: false });
        this.setState({ numUnit: "" });
      } else if (
        name === "propertyType" &&
        (value === "House" || value === "Office")
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: false });
        this.setState({ floorNum: "", numUnit: "", unitArray: [] });
      }
    } else if (this.state.unitType === "Multiple") {
      if (name === "propertyType" && value === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: true });
      } else if (
        name === "propertyType" &&
        (value === "House" || value === "Office")
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: true });
        this.setState({ floorNum: "", unitArray: [], numUnit: "" });
      }
    }
    if (name === "state1") {
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data, index) =>
          stateList.push(data.name)
        );
        this.setState({ stateList: stateList });
        this.setState({ state1: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state1: "" });
        this.setState({ city: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
      }
    }
    if (name.substring(0, 8) === "unitName") {
      let y = name.substring(8, name.length);
      let item = this.state.unitArray;
      item[y] = value;
      this.setState({ unitArray: item });
    }
    if (name === "numUnit") {
      let x = [];
      for (let k = 0; k < value; k++) {
        x.push("");
        this.setState({ ["unitName" + k]: "" });
      }
      this.setState({ unitArray: x });
    }
    if (name === "state1") {
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data, index) =>
          stateList.push(data.name)
        );
        this.setState({ stateList: stateList });
        this.setState({ state1: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state1: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
        this.setState({ city: "" });
      }
    }
    this.nextCheck();
  }
  checkFloorNumNext(value) {
    if (value === "") {
      return true;
    } else {
      let x = value.split(",");
      if (x[x.length - 1] === "") {
        return true;
      }
      if (x.length === 1) {
        let y = x[0].split("-");
        if (y.length > 1) {
          if (parseInt(y[0]) > parseInt(y[1]) || y[1] === "") {
            return true;
          }
        }
        return false;
      }
      for (const element of x) {
        let y = element.split("-");
        if (y.length > 1) {
          if (parseInt(y[0]) > parseInt(y[1]) || y[1] === "") {
            return true;
          }
        }
      }
    }
    return false;
  }
  checkFloorNum(value) {
    if (value === "" && this.state.next) {
      return true;
    } else if (this.state.next) {
      let x = value.split(",");
      if (x[x.length - 1] === "") {
        return true;
      }
      if (x.length === 1) {
        let y = x[0].split("-");
        if (y.length > 1) {
          if (parseInt(y[0]) > parseInt(y[1]) || y[1] === "") {
            return true;
          }
        }
        return false;
      }
      for (const element of x) {
        let y = element.split("-");
        if (y.length > 1) {
          if (parseInt(y[0]) > parseInt(y[1]) || y[1] === "") {
            return true;
          }
        }
      }
    }
    return false;
  }
  async onchange(e) {
    let { name, value } = e.target;
    if (name === "floorNum") {
      value = value.replace(/[^0-9-,]+/g, "");
      let x = value.split(",");
      if (value.substr(-1) === ",") {
        for (let i = 0; i < x.length; i++) {
          let y = x[i].split("-");
          if (y.length > 1) {
            if (parseInt(y[0]) > parseInt(y[1])) {
              this.setState({
                errorMessageFloor: "correct format number of floors",
              });
              x[i] = y[0] + "-";
            }
          }
        }
        value = x.toString();
      } else {
        for (const element of x) {
          let y = element.split("-");
          if (y.length > 1) {
            if (parseInt(y[0]) > parseInt(y[1])) {
              this.setState({
                errorMessageFloor: "correct format number of floors",
              });
            }
          }
        }
      }

      if (value === ",") {
        value = "";
      }
      value = value.replace("-,", "-");
      value = value.replace(",-", ",");
      await this.setState({ [name]: value });
    } else if (name === "managerPhone") {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (name === "numUnit") {
      let num = value.replace(/[^\d]/g, "");
      value = num;
      await this.setState({ [name]: value });
    } else {
      await this.setState({ [name]: value });
    }

    if (name === "unitType" && value === "Single") {
      if (this.state.propertyType === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: false });
        this.setState({ numUnit: "", floorNum: "", unitArray: [] });
      } else if (
        this.state.propertyType === "House" ||
        this.state.propertyType === "Office"
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: false });
        this.setState({ floorNum: "", numUnit: "", unitArray: [] });
      }
    } else if (name === "unitType" && value === "Multiple") {
      if (this.state.propertyType === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: true });
      } else if (
        this.state.propertyType === "House" ||
        this.state.propertyType === "Office"
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: true });
        this.setState({ floorNum: "", numUnit: "" });
      }
    } else if (this.state.unitType === "Single") {
      if (name === "propertyType" && value === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: false });
        this.setState({ numUnit: "" });
      } else if (
        name === "propertyType" &&
        (value === "House" || value === "Office")
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: false });
        this.setState({ floorNum: "", numUnit: "", unitArray: [] });
      }
    } else if (this.state.unitType === "Multiple") {
      if (name === "propertyType" && value === "Condo / Apartment") {
        this.setState({ checkFloorNum: true });
        this.setState({ checkNumUnit: true });
      } else if (
        name === "propertyType" &&
        (value === "House" || value === "Office")
      ) {
        this.setState({ checkFloorNum: false });
        this.setState({ checkNumUnit: true });
        this.setState({ floorNum: "", unitArray: [], numUnit: "" });
      }
    }
    if (name === "state1") {
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data, index) =>
          stateList.push(data.name)
        );
        this.setState({ stateList: stateList });
        this.setState({ state1: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state1: "" });
        this.setState({ city: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
      }
    }
    if (name.substring(0, 8) === "unitName") {
      let y = name.substring(8, name.length);
      let item = this.state.unitArray;
      item[y] = value;
      this.setState({ unitArray: item });
    }
    if (name === "numUnit") {
      let x = [];
      for (let k = 0; k < value; k++) {
        x.push("");
        this.setState({ ["unitName" + k]: "" });
      }
      this.setState({ unitArray: x });
    }
    this.nextCheck();
  }
  checkMobileNext(value) {
    return value.length === 14;
  }
  checkMobile(value) {
    return (value.length !== 14 || value === "") && this.state.next;
  }
  AddProperty(e) {
    if (
      this.props.location.pathname.split("/")[2] === "add-property" ||
      this.props.location.pathname.split("/")[2] === "add-property-by-axallant"
    ) {
      if (this.props.manage === "axallant") {
        if (
          this.state.address !== "" &&
          this.state.country !== "" &&
          this.state.state1 !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.state.propertyType !== ""
        ) {
          if (this.state.propertyType === "Condo / Apartment") {
            if (this.state.apartmentName !== "") {
              this.submitAxallant(e);
            } else {
              this.setState({ next: true });
            }
          } else {
            this.submitAxallant(e);
          }
        } else {
          this.setState({ next: true });
        }
      } else {
        if (
          this.state.address !== "" &&
          this.state.country !== "" &&
          this.state.state1 !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.state.propertyType !== "" &&
          this.state.unitType !== ""
        ) {
          let x = true;
          if (this.state.unitType === "Multiple") {
            for (const element of this.state.unitArray) {
              if (element === "") {
                x = false;
              }
            }
          }
          if (x) {
            if (
              this.state.propertyType === "Condo / Apartment" &&
              this.state.unitType === "Single"
            ) {
              if (
                this.state.floorNum === "" ||
                this.state.apartmentName === "" ||
                this.state.managerName === "" ||
                this.state.managerPhone === "" ||
                !this.checkMobileNext(this.state.managerPhone) ||
                this.checkFloorNumNext(this.state.floorNum)
              ) {
                this.setState({ next: true });
              } else {
                if (
                  this.props.location.pathname.split("/")[2] === "add-property"
                ) {
                  this.submit(e);
                } else {
                  this.submitUpdate(e);
                }
              }
            } else if (
              this.state.propertyType === "Condo / Apartment" &&
              this.state.unitType === "Multiple"
            ) {
              if (
                this.state.floorNum === "" ||
                this.state.numUnit === "" ||
                this.state.apartmentName === "" ||
                this.state.managerName === "" ||
                this.state.managerPhone === "" ||
                !this.checkMobileNext(this.state.managerPhone) ||
                this.checkFloorNumNext(this.state.floorNum)
              ) {
                this.setState({ next: true });
              } else {
                if (
                  this.props.location.pathname.split("/")[2] === "add-property"
                ) {
                  this.submit(e);
                } else {
                  this.submitUpdate(e);
                }
              }
            } else if (
              this.state.propertyType === "House" &&
              this.state.unitType === "Multiple" &&
              this.state.numUnit === ""
            ) {
              this.setState({ next: true });
            } else if (
              this.state.propertyType === "Office" &&
              this.state.unitType === "Multiple" &&
              this.state.numUnit === ""
            ) {
              this.setState({ next: true });
            } else {
              if (
                this.props.location.pathname.split("/")[2] === "add-property"
              ) {
                this.submit(e);
              } else {
                this.submitUpdate(e);
              }
            }
          } else {
            this.setState({ next: true });
          }
        } else {
          this.setState({ next: true });
        }
      }
    } else {
      if (this.props.manage === "axallant") {
        if (
          this.state.address !== "" &&
          this.state.country !== "" &&
          this.state.state1 !== "" &&
          this.state.city !== "" &&
          this.state.zip !== ""
        ) {
          if (this.state.propertyType === "Condo / Apartment") {
            if (this.state.apartmentName !== "") {
              this.submitUpdate(e);
            } else {
              this.setState({ next: true });
            }
          } else {
            this.submitUpdate(e);
          }
        } else {
          this.setState({ next: true });
        }
      } else {
        if (
          this.state.address !== "" &&
          this.state.country !== "" &&
          this.state.state1 !== "" &&
          this.state.city !== "" &&
          this.state.zip !== ""
        ) {
          if (this.state.propertyType === "Condo / Apartment") {
            if (
              this.state.apartmentName !== "" &&
              this.state.managerName !== "" &&
              this.state.managerPhone !== "" &&
              this.checkMobileNext(this.state.managerPhone)
            ) {
              this.submitUpdate(e);
            } else {
              this.setState({ next: true });
            }
          } else {
            this.submitUpdate(e);
          }
        } else {
          this.setState({ next: true });
        }
      }
    }
  }
  nextCheck() {
    this.setState({ nextEnable: false });

    if (
      this.props.location.pathname.split("/")[2] === "add-property" ||
      this.props.location.pathname.split("/")[2] === "add-property-by-axallant"
    ) {
      if (this.props.manage === "axallant") {
        if (
          this.state.address !== "" &&
          this.state.country !== "" &&
          this.state.state1 !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.state.propertyType !== ""
        ) {
          if (this.state.propertyType === "Condo / Apartment") {
            if (this.state.apartmentName !== "") {
              this.setState({ nextEnable: true });
              return true;
            } else {
              this.setState({ nextEnable: false });
              return false;
            }
          } else {
            this.setState({ nextEnable: true });
            return true;
          }
        } else {
          this.setState({ nextEnable: false });
          return false;
        }
      } else {
        if (
          this.state.address !== "" &&
          this.state.country !== "" &&
          this.state.state1 !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.state.propertyType !== "" &&
          this.state.unitType !== ""
        ) {
          let x = true;
          if (this.state.unitType === "Multiple") {
            for (const element of this.state.unitArray) {
              if (element === "") {
                x = false;
              }
            }
          }
          if (x) {
            if (
              this.state.propertyType === "Condo / Apartment" &&
              this.state.unitType === "Single"
            ) {
              if (
                this.state.floorNum === "" ||
                this.state.apartmentName === "" ||
                this.state.managerName === "" ||
                this.state.managerPhone === "" ||
                !this.checkMobileNext(this.state.managerPhone) ||
                this.checkFloorNumNext(this.state.floorNum)
              ) {
                this.setState({ nextEnable: false });
                return false;
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            } else if (
              this.state.propertyType === "Condo / Apartment" &&
              this.state.unitType === "Multiple"
            ) {
              if (
                this.state.floorNum === "" ||
                this.state.numUnit === "" ||
                this.state.apartmentName === "" ||
                this.state.managerName === "" ||
                this.state.managerPhone === "" ||
                !this.checkMobileNext(this.state.managerPhone) ||
                this.checkFloorNumNext(this.state.floorNum)
              ) {
                this.setState({ nextEnable: false });
                return false;
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            } else if (
              this.state.propertyType === "House" &&
              this.state.unitType === "Multiple" &&
              this.state.numUnit === "" &&
              this.state.propertyType === "Office" &&
              this.state.unitType === "Multiple" &&
              this.state.numUnit === ""
            ) {
              this.setState({ nextEnable: false });
              return false;
            } else {
              this.setState({ nextEnable: true });
              return true;
            }
          } else {
            this.setState({ nextEnable: false });
            return false;
          }
        } else {
          this.setState({ nextEnable: false });
          return false;
        }
      }
    } else {
      if (
        this.state.address !== "" &&
        this.state.country !== "" &&
        this.state.state1 !== "" &&
        this.state.city !== "" &&
        this.state.zip !== ""
      ) {
        if (this.state.propertyType === "Condo / Apartment") {
          if (this.props.manage === "axallant") {
            if (this.state.apartmentName !== "") {
              this.setState({ nextEnable: true });
              return true;
            } else {
              this.setState({ nextEnable: false });
              return false;
            }
          } else {
            if (
              this.state.apartmentName !== "" &&
              this.state.managerName !== "" &&
              this.state.managerPhone !== "" &&
              this.checkMobileNext(this.state.managerPhone)
            ) {
              this.setState({ nextEnable: true });
              return true;
            } else {
              this.setState({ nextEnable: false });
              return false;
            }
          }
        } else {
          this.setState({ nextEnable: true });
          return true;
        }
      } else {
        this.setState({ nextEnable: false });
        return false;
      }
    }
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = this.beforeUnloadListener;
    const json = await CountryJson();
    this.setState({ countryJson: json });

    try {
      if (
        this.props.UserData.stripeAccountId !== null &&
        this.props.UserData.stripeAccountId !== ""
      ) {
        checkPaymentAccountDetail().then((response) => {
          if (response.status === 200) {
            if (response.data.capabilities.card_payments !== "active") {
              createPaymentAccount("EXPRESS").then(async (response) => {
                if (response.status === 200) {
                  window.location.href = response.data.data;
                } else {
                  this.props.appendAlert(
                    "Something went wrong!, Please try again later.",
                    "danger"
                  );
                }
              });
            } else {
              this.props.setAccountPaymentStatus(
                response.data.capabilities.card_payments
              );
            }
            this.setState({ isLoading: false });
          }
        });
      } else {
        createPaymentAccount("EXPRESS").then(async (response) => {
          if (response.status === 200) {
            window.location.href = response.data.data;
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    let countryList = [];
    this.state.countryJson.map((data) => countryList.push(data.name));
    this.setState({ countryList: countryList });
    if (this.props.location.state !== undefined) {
      try {
        if (this.props.location.state.data) {
          this.setState({
            country: this.props.location.state.data.address.country
              ? this.props.location.state.data.address.country
              : "",
            state1: this.props.location.state.data.address.state
              ? this.props.location.state.data.address.state
              : "",
            city: this.props.location.state.data.address.city
              ? this.props.location.state.data.address.city
              : "",
            zip: this.props.location.state.data.address.zip
              ? this.props.location.state.data.address.zip
              : "",
            address: this.props.location.state.data.address.address
              ? this.props.location.state.data.address.address
              : "",
            apartmentName: this.props.location.state.data.name
              ? this.props.location.state.data.name
              : "",
            managerName: this.props.location.state.data.managerName
              ? this.props.location.state.data.managerName
              : "",
            managerPhone: this.props.location.state.data.managerPhone
              ? this.props.location.state.data.managerPhone
              : "",
            propertyType:
              this.props.location.state.data.type !== ""
                ? this.props.location.state.data.type === "APPARTMENT_BUILDING"
                  ? "Condo / Apartment"
                  : this.props.location.state.data.type === "SINGLE_HOME"
                  ? "House"
                  : "Office"
                : "",
            type: this.props.location.pathname.split("/")[3],
            coverImage: this.props.location.state.data.coverImage,
          });

          if (this.props.location.state.data.coverImage !== "") {
            fetch(
              url +
                "api/property/" +
                this.props.match.params.propsId +
                "/image/download"
            ).then((res) => {
              this.setState({ coverImage: res.url, ImageLoader: false });
            });
          }
          let selectedCountry = this.state.countryJson.filter(
            (country) =>
              country.name.replace(/\s/g, "").toLowerCase() ===
              this.props.location.state.data.address.country
                .replace(/\s/g, "")
                .toLowerCase()
          );

          let stateList = [];
          if (selectedCountry.length !== 0) {
            this.setState({ stateJson: selectedCountry[0].states });
            selectedCountry[0].states.map((data, index) =>
              stateList.push(data.name)
            );
            this.setState({ stateList: stateList });
          } else {
            this.setState({ stateList: [] });
            this.setState({ cityList: [] });
          }

          let selectedCities = this.state.stateJson.filter(
            (state) =>
              state.name.replace(/\s/g, "").toLowerCase() ===
              this.props.location.state.data.address.state
                .replace(/\s/g, "")
                .toLowerCase()
          );
          let cityList = [];
          if (selectedCities.length !== 0) {
            this.setState({ cityJson: selectedCities[0].cities });
            selectedCities[0].cities.map((data, index) =>
              cityList.push(data.name)
            );
            this.setState({ cityList: cityList });
          } else {
            this.setState({ cityList: [] });
          }
        }
      } catch (err) {}
    } else {
      if (this.props.match.params.propsId !== undefined) {
        try {
          getPropertyById(this.props.match.params.propsId).then(
            async (response) => {
              if (response.status === 200) {
                this.setState({
                  country: response.data.address.country
                    ? response.data.address.country
                    : "",
                  state1: response.data.address.state
                    ? response.data.address.state
                    : "",
                  city: response.data.address.city
                    ? response.data.address.city
                    : "",
                  zip: response.data.address.zip
                    ? response.data.address.zip
                    : "",
                  address: response.data.address.address
                    ? response.data.address.address
                    : "",
                  propertyType:
                    response.data.type !== ""
                      ? response.data.type === "APPARTMENT_BUILDING"
                        ? "Condo / Apartment"
                        : response.data.type === "SINGLE_HOME"
                        ? "House"
                        : "Office"
                      : "",
                  apartmentName: response.data.name ? response.data.name : "",
                  type: this.props.location.pathname.split("/")[3],
                  coverImage: response.data.coverImage,
                });
                if (this.props.location.state.data.coverImage !== "") {
                  fetch(
                    url +
                      "api/property/" +
                      this.props.match.params.propsId +
                      "/image/download"
                  ).then((res) => {
                    this.setState({ coverImage: res.url, ImageLoader: false });
                  });
                }
                let selectedCountry = this.state.countryJson.filter(
                  (country) =>
                    country.name.replace(/\s/g, "").toLowerCase() ===
                    response.data.address.country
                      .replace(/\s/g, "")
                      .toLowerCase()
                );
                let stateList = [];
                if (selectedCountry.length !== 0) {
                  this.setState({ stateJson: selectedCountry[0].states });
                  selectedCountry[0].states.map((data, index) =>
                    stateList.push(data.name)
                  );
                  this.setState({ stateList: stateList });
                } else {
                  this.setState({ stateList: [] });
                  this.setState({ cityList: [] });
                }

                let selectedCities = this.state.stateJson.filter(
                  (state) =>
                    state.name.replace(/\s/g, "").toLowerCase() ===
                    response.data.address.state.replace(/\s/g, "").toLowerCase()
                );
                let cityList = [];
                if (selectedCities.length !== 0) {
                  this.setState({ cityJson: selectedCities[0].cities });
                  selectedCities[0].cities.map((data, index) =>
                    cityList.push(data.name)
                  );
                  this.setState({ cityList: cityList });
                } else {
                  this.setState({ cityList: [] });
                }
              } else {
                this.props.appendAlert(
                  "Something went wrong!, Please try again later.",
                  "danger"
                );
              }
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        let selectedCountry = this.state.countryJson.filter(
          (country) =>
            country.name.replace(/\s/g, "").toLowerCase() ===
            this.state.country.replace(/\s/g, "").toLowerCase()
        );
        let stateList = [];
        if (selectedCountry.length !== 0) {
          this.setState({ stateJson: selectedCountry[0].states });
          let countryData=[];
          let countryData2=[];
          selectedCountry[0].states.map((data) => {
            stateList.push(data.name);
            countryData = countryData.concat(data.cities);
            return data;
          });
          countryData.map((data) => {
            countryData2.push(data.name);
            return data;
          });
          
          this.setState({ stateList: stateList });
          this.setState({ cityList: countryData2 });
          this.setState({ state1: "" });
          this.setState({ city: "" });
        } else {
          this.setState({ state1: "" });
          this.setState({ city: "" });
          this.setState({ stateList: [] });
          this.setState({ cityList: [] });
        }
      }
    }
  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };

  async submitUpdate() {
    Geocode.setApiKey(geoKey);
    Geocode.enableDebug();
    this.setState({ isButtonDisable: true });
    let lati, longi;
    await Geocode.fromAddress(
      this.state.address +
        "," +
        this.state.city +
        "," +
        this.state.state1 +
        "," +
        this.state.country
    ).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        lati = lat;
        longi = lng;
      },
      (error) => {
        console.error(error);
      }
    );
    let address = {
      address: this.state.address !== "" ? this.state.address : "",
      city: this.state.city !== "" ? this.state.city : "",
      state: this.state.state1 !== "" ? this.state.state1 : "",
      zip: this.state.zip !== "" ? this.state.zip : "",
      lat: lati,
      lng: longi,
      country: this.state.country !== "" ? this.state.country : "",
    };

    let body = {
      address: address,
      type:
        this.props.location.state.data.type !== ""
          ? this.props.location.state.data.type
          : "",
      name: this.state.apartmentName,
      managerName: this.state.managerName,
      managerPhone: this.state.managerPhone,
    };
    try {
      updateProperty(body, this.props.match.params.propsId).then((response) => {
        if (response.status === 200) {
          this.setPropertyDetails();
          if (typeof this.state.coverImage !== "string") {
            const formData = new FormData();
            formData.append("file", this.state.coverImage);
            uploadPropertyImage(formData, this.props.match.params.propsId)
              .then((response) => {
                if (response.status === 200) {
                  // this.props.appendAlert("Image uploaded successfully" "success" );
                } else {
                  this.props.appendAlert(
                    "Property Image Upload Fail",
                    "danger"
                  );
                }
                this.props.appendAlert(
                  "Property updated successfully",
                  "success"
                );
                this.setState({ isButtonDisable: false });
                
                this.props.history.replace({ pathname: "/properties" });
              })
              .catch((err) => {
                this.props.appendAlert("Property Image Upload Fail", "danger");
                this.props.appendAlert(
                  "Property updated successfully",
                  "success"
                );
                this.setState({ isButtonDisable: false });
                this.props.history.replace({ pathname: "/properties" });
              });
          } else {
            this.setState({ isButtonDisable: false });
            this.props.history.replace({ pathname: "/properties" });
            this.props.appendAlert("Property updated successfully", "success");
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {}
  }
  setPropertyDetails() {
    try {
      getProperty().then((response) => {
        if (response.status === 200) {
          this.setState({ isLoading: false });
          if (response.data.length !== 0) {
            this.props.setProperty(response.data);
          } else {
            this.props.setProperty([]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  cancel() {
    this.props.history.goBack();
  }
  async submit() {
    Geocode.setApiKey(geoKey);
    Geocode.enableDebug();
    this.setState({ isButtonDisable: true });
    let lati, longi;
    await Geocode.fromAddress(
      this.state.address +
        "," +
        this.state.city +
        "," +
        this.state.state1 +
        "," +
        this.state.country
    ).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        lati = lat;
        longi = lng;
      },
      (error) => {
        console.error(error);
      }
    );
    let address = {
      address: this.state.address !== "" ? this.state.address : "",
      city: this.state.city !== "" ? this.state.city : "",
      state: this.state.state1 !== "" ? this.state.state1 : "",
      zip: this.state.zip !== "" ? this.state.zip : "",
      lat: lati,
      lng: longi,
      country: this.state.country !== "" ? this.state.country : "",
    };

    let property = {
      address: address,
      apt_unit: this.state.numUnit !== "" ? parseInt(this.state.numUnit) : "",
      cover_image: null,
      type:
        this.state.propertyType !== ""
          ? this.state.propertyType === "Condo / Apartment"
            ? "APPARTMENT_BUILDING"
            : this.state.propertyType === "House"
            ? "SINGLE_HOME"
            : "OFFICE"
          : "",
      name: this.state.apartmentName,
      managerName: this.state.managerName,
      managerPhone: this.state.managerPhone,
    };

    let body = {
      property: property,
      unitType:
        this.state.unitType !== ""
          ? this.state.unitType === "Single"
            ? "single"
            : "multiple"
          : "",
      numberOfUnits:
        this.state.numUnit !== "" ? parseInt(this.state.numUnit) : "",
      unitName: this.state.unitArray,
      floorTypeString: this.state.floorNum !== "" ? this.state.floorNum : "",
    };
    try {
      addProperty(body).then((response) => {
        if (response.status === 200) {
          if (typeof this.state.coverImage !== "string") {
            const formData = new FormData();
            formData.append("file", this.state.coverImage);
            uploadPropertyImage(formData, response.data.data.id)
              .then((response) => {
                if (response.status === 200) {
                  // this.props.appendAlert(  "Image uploaded successfully",  "success" );
                } else {
                  this.props.appendAlert(
                    "Property Image Upload Fail",
                    "danger"
                  );
                }
                this.setState({ isButtonDisable: false });
                this.props.history.goBack();
                this.props.appendAlert(
                  "Property created successfully",
                  "success"
                );
              })
              .catch((err) => {
                this.props.appendAlert("Image upload failed", "danger");
                this.props.appendAlert(
                  "Property created successfully",
                  "success"
                );
                this.setState({ isButtonDisable: false });
                this.props.history.goBack();
              });
          } else {
            this.props.appendAlert("Property created successfully", "success");
            this.setState({ isButtonDisable: false });
            this.props.history.goBack();
          }
        } else {
          this.setState({ isButtonDisable: false });
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {}
  }
  async submitAxallant() {
    // Geocode.setApiKey(geoKey);
    // Geocode.enableDebug();
    this.setState({ isButtonDisable: true });
    // let lati, longi;
    // await Geocode.fromAddress(
    //   this.state.address +
    //     "," +
    //     this.state.city +
    //     "," +
    //     this.state.state1 +
    //     "," +
    //     this.state.country
    // ).then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     lati = lat;
    //     longi = lng;
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
    let address = {
      address: this.state.address !== "" ? this.state.address : "",
      city: this.state.city !== "" ? this.state.city : "",
      state: this.state.state1 !== "" ? this.state.state1 : "",
      zip: this.state.zip !== "" ? this.state.zip : "",
      lat: 0.0,
      lng: 0.0,
      country: this.state.country !== "" ? this.state.country : "",
    };

    let property = {
      address: address,
      cover_image: null,
      type:
        this.state.propertyType !== ""
          ? this.state.propertyType === "Condo / Apartment"
            ? "APPARTMENT_BUILDING"
            : this.state.propertyType === "House"
            ? "SINGLE_HOME"
            : "OFFICE"
          : "",
      name: this.state.apartmentName,
    };

    let body = {
      property: property,
      manageByAxallant: true,
    };
    try {
      addPropertyByAxallant(body).then((response) => {
        if (response.status === 200) {
          if (typeof this.state.coverImage !== "string") {
            const formData = new FormData();
            formData.append("file", this.state.coverImage);
            uploadPropertyImage(formData, response.data.data.id)
              .then((response) => {
                if (response.status === 200) {
                  // this.props.appendAlert(  "Image uploaded successfully",  "success" );
                } else {
                  this.props.appendAlert(
                    "Property Image Upload Fail",
                    "danger"
                  );
                }
                this.setState({ isButtonDisable: false });
                this.props.history.goBack();
                this.props.appendAlert(
                  "Property created successfully",
                  "success"
                );
              })
              .catch((err) => {
                this.props.appendAlert("Image upload failed", "danger");
                this.props.appendAlert(
                  "Property created successfully",
                  "success"
                );
                this.setState({ isButtonDisable: false });
                this.props.history.goBack();
              });
          } else {
            this.props.appendAlert("Property created successfully", "success");
            this.setState({ isButtonDisable: false });
            this.props.history.goBack();
          }
        } else {
          this.setState({ isButtonDisable: false });
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {}
  }
  render() {
    return (
      <>
        {this.props.accountPaymentStatus === null ? (
          <Loader />
        ) : (
          <div className="container-fluid addprops ">
            {/* <Message topic="payment" head1="" head2="Payment method" content="You haven't registered any payment method,Click here to setup payment methods" button="add Payment Method" link="/payments" history={this.props.history}/> */}
            <h1 className="axText pl-4">
              {this.props.location.pathname.split("/")[3] === "edit-property" ||
              this.props.location.pathname.split("/")[3] ===
                "edit-property-by-axallant"
                ? "Edit Property"
                : "Add Property"}
            </h1>
            <div className="row  cardApplication pr-4 pl-4 pt-4 pb-4">
              <div className="col-md-4 pt-4 pb-4">
                <Dropzone
                  accept=".jpeg,.png,.jpg"
                  maxSize={10485760}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles[0] === undefined) {
                      this.props.appendAlert(
                        "Image size is too Long (max size = 10mb) ",
                        "danger"
                      );
                    } else {
                      this.setState({ coverImage: acceptedFiles[0] });
                    }
                  }}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {typeof this.state.coverImage === "string" &&
                        this.props.location.pathname.split("/")[3] !==
                          "edit-property" &&
                        this.props.location.pathname.split("/")[3] !==
                          "edit-property-by-axallant" ? (
                          <div className="text-left">
                            <div
                              className="dragDrop text-center  pointer"
                              style={{
                                fontSize: "60px",
                                minHeight: "350px",
                                paddingTop: "100px",
                              }}
                            >
                              <div className="row">
                                <div className="col">
                                  <i
                                    className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <p className="licenseHead">
                                    Drag Or Click to Upload
                                  </p>
                                </div>
                              </div>
                            </div>
                            <span className="span5 textPri ">
                              Max file size is 10MB per image. JPG, PNG, or GIF
                              formats only.
                            </span>
                          </div>
                        ) : (
                          <div className=" text-center ">
                            {this.props.location.pathname.split("/")[3] ===
                              "edit-property" ||
                            this.props.location.pathname.split("/")[3] ===
                              "edit-property-by-axallant" ? (
                              this.state.coverImage === "" ? (
                                <div className="text-left">
                                  <div
                                    className="dragDrop text-center pointer"
                                    style={{
                                      fontSize: "60px",
                                      minHeight: "350px",
                                      paddingTop: "100px",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <i
                                          className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="licenseHead">
                                          Drag Or Click to Upload
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="span5 textPri ">
                                    Max file size is 10MB per image. JPG, PNG,
                                    or GIF formats only.
                                  </span>
                                </div>
                              ) : typeof this.state.coverImage === "string" ? (
                                <img
                                  src={this.state.coverImage}
                                  className="licenseImage dragDrop pointer"
                                  alt="propsImage"
                                  style={{ height: "100%", width: "100%" }}
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    this.state.coverImage
                                  )}
                                  className="licenseImage dragDrop pointer"
                                  alt="propsImage"
                                  style={{ height: "100%", width: "100%" }}
                                />
                              )
                            ) : (
                              <img
                                src={URL.createObjectURL(this.state.coverImage)}
                                className="licenseImage dragDrop pointer"
                                alt="propsImage"
                                style={{ height: "100%", width: "100%" }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Dropzone>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-md">
                    <SearchInput
                      name="country"
                      id="Country"
                      type="2"
                      target="country"
                      onChange={this.onchange}
                      list={this.state.countryList}
                      changeState={this.changeState}
                      important={"true"}
                      errorMessage="property Country"
                      validate={this.checkValidate(this.state.country)}
                      value={this.state.country}
                    />
                  </div>
                  <div className="col-md">
                    <SearchInput
                      name="state1"
                      id="State"
                      target="state1"
                      // inputDisable={true}
                      // searchDisable={true}
                      type="2"
                      onChange={this.onchange}
                      list={this.state.stateList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="property State"
                      validate={this.checkValidate(this.state.state1)}
                      value={this.state.state1}
                    />
                  </div>
                  <div className="col-md">
                    <SearchInput
                      name="city"
                      id="City"
                      target="city"
                      type="2"
                      onChange={this.onchange}
                      list={this.state.cityList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="property City"
                      validate={this.checkValidate(this.state.city)}
                      value={this.state.city}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <ApplicationInput
                      name="Address"
                      type="text"
                      designType="2"
                      target="address"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="property Address"
                      validate={this.checkValidate(this.state.address)}
                      value={this.state.address}
                    />
                  </div>
                  <div className="col-md">
                    <ApplicationInput
                      name="Zip"
                      type="text"
                      target="zip"
                      designType="2"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="property Zip Code"
                      validate={this.checkValidate(this.state.zip)}
                      value={this.state.zip}
                    />
                  </div>
                  {(this.props.location.pathname.split("/")[2] ===
                    "add-property" ||
                    this.props.location.pathname.split("/")[2] ===
                      "add-property-by-axallant") && (
                    <div className="col-md-4">
                      <SearchInput
                        name="Property type"
                        id="Property type"
                        type="2"
                        target="propertyType"
                        onChange={this.onchange}
                        list={["Condo / Apartment", "House", "Office"]}
                        important="true"
                        errorMessage="type of property"
                        validate={this.checkValidate(this.state.propertyType)}
                        inputDisable={true}
                        changeState={this.changeState}
                        value={this.state.propertyType}
                        searchDisable={true}
                      />
                    </div>
                  )}

                  {this.props.location.pathname.split("/")[2] ===
                    "add-property" && (
                    <div className="col-md-4">
                      <SearchInput
                        name="Unit type"
                        id="Unit type"
                        type="2"
                        target="unitType"
                        onChange={this.onchange}
                        list={["Single", "Multiple"]}
                        important="true"
                        errorMessage="type of unit type"
                        validate={this.checkValidate(this.state.unitType)}
                        inputDisable={true}
                        changeState={this.changeState}
                        value={this.state.unitType}
                        searchDisable={true}
                      />
                    </div>
                  )}
                  {this.state.checkNumUnit && (
                    <div className="col-md-4">
                      <ApplicationInput
                        name="Number of units per floor"
                        type="text"
                        target="numUnit"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="number of units."
                        validate={this.checkValidate(this.state.numUnit)}
                        value={this.state.numUnit}
                      />
                    </div>
                  )}
                  {this.state.checkFloorNum && (
                    <div className="col-md-4">
                      <div
                        className=""
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <ApplicationInput
                          name="Number of Floors"
                          target="floorNum"
                          type="textArea"
                          row="1"
                          onChange={this.onchange}
                          important="true"
                          errorMessage={
                            this.checkFloorNum(this.state.floorNum)
                              ? "number of floors"
                              : " number of floors correct format"
                          }
                          validate={this.checkFloorNum(this.state.floorNum)}
                          value={this.state.floorNum}
                        />
                      </div>

                      <div
                        className="dropdown-menu pt-0 mt-0"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="bg-secondary p-2">
                          <span className="dropHead">Instructions</span>
                        </div>
                        <div className="pr-3 pl-3">
                          <p className="dropContent1">
                            {" "}
                            Enter number of floors separated by ","
                          </p>
                          <p className="dropContent2"> eg: 1, 5, 17</p>
                          <p className="dropContent3 text-center"> Or</p>
                          <p className="dropContent1">
                            {" "}
                            Continuous number by "-"
                          </p>
                          <p className="dropContent2"> eg: 1-6, 9-13</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.propertyType === "Condo / Apartment" && (
                    <>
                      <div className="col-md-4">
                        <ApplicationInput
                          name="Apartment Name"
                          type="text"
                          designType="2"
                          target="apartmentName"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="apartment name"
                          validate={this.checkValidate(
                            this.state.apartmentName
                          )}
                          value={this.state.apartmentName}
                        />
                      </div>
                      {this.props.manage !== "axallant" && (
                        <>
                          <div className="col-md-4">
                            <ApplicationInput
                              name="Managers Name"
                              type="text"
                              designType="2"
                              target="managerName"
                              onChange={this.onchange}
                              important="true"
                              errorMessage="manager's name"
                              validate={this.checkValidate(
                                this.state.managerName
                              )}
                              value={this.state.managerName}
                            />
                          </div>
                          <div className="col-md-4">
                            <ApplicationInput
                              name="Managers Mobile"
                              type="text"
                              designType="2"
                              target="managerPhone"
                              onChange={this.onchange}
                              important="true"
                              errorMessage={
                                this.checkValidateMobile(
                                  this.state.managerPhone
                                )
                                  ? "manager's mobile no"
                                  : "the 10 digit number"
                              }
                              validate={this.checkMobile(
                                this.state.managerPhone
                              )}
                              value={this.state.managerPhone}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {this.state.unitArray.map((unit, index) => (
                    <div className="col-md-4 alignInput">
                      <ApplicationInput
                        name={"Unit " + (index + 1) + " Name"}
                        type="text"
                        target={"unitName" + index}
                        onChange={this.onchange}
                        important="true"
                        errorMessage="unit name"
                        validate={this.checkValidate(
                          this.state["unitName" + index]
                        )}
                        value={this.state["unitName" + index]}
                        min="1"
                      />
                    </div>
                  ))}
                </div>

                <div className="row pb-4 pt-4">
                  <div className="col-12 text-right">
                    <button
                      className="pri mr-3"
                      data-target="#cancel"
                      data-toggle="modal"
                    >
                      {this.props.location.pathname.split("/")[3] !==
                        "edit-property" &&
                      this.props.location.pathname.split("/")[3] !==
                        "edit-property-by-axallant"
                        ? "Cancel"
                        : "Discard"}
                    </button>
                    {this.state.nextEnable ? (
                      <button
                        disabled={this.state.isButtonDisable}
                        className={
                          this.state.isButtonDisable
                            ? "secDisable notAllow"
                            : " buttonPri "
                        }
                        onClick={() => this.AddProperty()}
                      >
                        {this.props.location.pathname.split("/")[3] !==
                          "edit-property" &&
                        this.props.location.pathname.split("/")[3] !==
                          "edit-property-by-axallant"
                          ? "Create"
                          : "Update"}
                        {this.state.isButtonDisable && (
                          <Loader3 transY="50px" transX="0px" />
                        )}
                      </button>
                    ) : (
                      <button
                        className="secDisable "
                        onClick={() => this.AddProperty()}
                      >
                        {this.props.location.pathname.split("/")[3] !==
                          "edit-property" &&
                        this.props.location.pathname.split("/")[3] !==
                          "edit-property-by-axallant"
                          ? "Create"
                          : "Update"}
                        {this.state.isButtonDisable && (
                          <Loader3 transY="50px" transX="0px" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <SmallModalContainer
              name="cancel"
              dark={this.props.dark}
              title=""
              header="false"
              confirmCommand="Leave"
              confirm={() => this.cancel()}
              reject={() => this.setState({ leave: false })}
              rejectCommand="Cancel"
              size="lg"
              rejectCheck="true"
            >
              <div className="p-2 pull-left text-left">
              <h1
                  className="modal-title axText1 textPri mb-3"
                  id="exampleModalLabel"
                  style={{ marginTop: "-10px", fontWeight: "900" }}
                >
                 Do You Want to Leave the Property Edit Page?
                </h1>
                <span className="span7" style={{ fontSize: "16px" }}>
                Any changes you made will not be saved.
                </span>
              </div>
            </SmallModalContainer>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountPayment: state.accountPaymentReducer,
    accountPaymentStatus: state.accountPayment,
    UserData: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { setAccountPaymentStatus, appendAlert,setProperty })(AddProp)
);
