// @author Karunaaharan Bavaram

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  appendAlert,
  authorize,
  // changePercentageProfileAlert,
  changeRole,
  changeRoleGuest,
  // setOpenProfileAlert,
  setUser,
  unAuthorize,
} from "./actions";
import "./App.css";
import "./Components/Dashboard/App.css";
import "./Components/Dashboard/components/Lead/table.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";
import Page404 from "./Components/AnonymousPage/404";
import NavBar from "./Components/NavBar/NavBar";
import RentalUnits from "./Components/Pages/RentalUnit/RentalUnits";
import Alert from "./Components/Alert/Alert";
import ListingApplication from "./Components/Pages/Application/ApplicationForms/ListingApplication";
import ListingApplicationCanada from "./Components/Pages/Application/ApplicationForms/ListingApplicationCanada";
import Applications from "./Components/Pages/Application/Applications";
import Contracts from "./Components/Pages/Contracts/Contracts";
import CreateContractWashington from "./Components/Pages/Contracts/CreateContract/Washington/CreateContractWashington";
import Dashboard from "./Components/Dashboard/Dashboard";
import ViewProp from "./Components/Pages/Property/ViewProp";
import Payments from "./Components/Pages/Payment/Payments";
import AddTenantPayment from "./Components/Pages/Payment/AddTenantPayment";
import JwtAuthService from "../src/Services/JwtAuthService";
import AddProp from "./Components/Pages/Property/AddProp";
import ViewListing from "./Components/Listings/ViewListing";
import GetStepContent from "./Components/Listings/Listing";
import AddTenant from "./Components/Listings/AddTenant";
import OfficeApplication from "./Components/Pages/Application/ApplicationForms/OfficeApplication";
import CreateContractOntario from "./Components/Pages/Contracts/CreateContract/Ontario/CreateContractOntario";
import AcceptContract from "./Components/Pages/Contracts/AcceptContract";
import Loader from "./Components/Loader/Loader";
import CookieService from "./Services/CookieService";
import RegistrationConfirm from "./Components/Login/RegistrationConfirm";
import StripeLanding from "./Components/Login/StripeLanding";
import AcceptDirectContract from "./Components/Login/AcceptDirectContract";
import {
  checkToken,
  getTokenIntoRefresh,
  offline,
} from "./Services/ApiServices";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ResetPassword from "./Components/Login/ResetPassword";
import ChangePassword from "./Components/Login/ChangePassword";
import Footer from "./Components/Home/Footer";
import ContactUs from "./Components/Home/ContactUs";
import AboutUs from "./Components/Home/AboutUs";
import PreLoaderHandler from "./Services/PreLoaderHandler";
import ListingApplicationSecondary from "./Components/Pages/Application/ApplicationForms/ListingApplicationSecondary";
import Maintenance from "./Components/Pages/Maintenance/Maintenance";
import MaintenanceRequestDetail from "./Components/Pages/Maintenance/MaintenanceRequestDetail/MaintenanceRequestDetail";
//It is helps secure route
import Permission from "./Components/Pages/Permission/Permission";
import Chat from "./Components/Pages/Chat/Chat";
import MaintenanceRequestProviderDetail from "./Components/Pages/Maintenance/MaintenanceRequestDetail/MaintenanceRequestProviderDetail";
import CreateContractBritishColumbia from "./Components/Pages/Contracts/CreateContract/BritishColumbia/CreateContractBritishColumbia";
import ListingApplicationBritishColumbia from "./Components/Pages/Application/ApplicationForms/ListingApplicationBritishColumbia";
import LandlordProfile from "./Components/Pages/Profile/LandlordProfile";
import TenantProfile from "./Components/Pages/Profile/TenantProfile";

function App() {
  //state management using redux
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const auth = useSelector((state) => state.isAuth);
  const dark = useSelector((state) => state.isDark);
  const alert = useSelector((state) => state.alert);
  // const profileAlert = useSelector((state) => state.profileAlert);
  const customerPaymentStatus = useSelector((state) => state.customerPayment);
  const dispatch = useDispatch();
  const [preLoader, setPreLoader] = useState(true);
  // const [profile, setProfile] = useState(true);
  let [sign, changeSign] = useState(true);

  useEffect(() => {
    const AuthCheck = async () => {
      //if signed already get user Data with stored cookie
      //All cookies are encrypted with aes
      let UserData = CookieService.getArrayCookie("axUserDetails");
      let roleNo = CookieService.getArrayCookie("axUserRole");
      let token = CookieService.getCookie("axTok");
      let reReToken = CookieService.getCookie("axReTok");
      let staySignIn = CookieService.getCookie("staySignIn");

      dispatch(setUser(UserData));
      if (UserData !== "" && roleNo !== "" && token !== "") {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (UserData !== "" && roleNo !== "" && roleNo.length > 0) {
          dispatch(authorize());
          dispatch(changeRole(roleNo[0].id));

          //It's helps to profile fill check after login
          // let count = 0;
          // if (UserData.username !== "" && UserData.username !== null) {
          //   count++;
          // }
          // if (UserData.firstName !== "" && UserData.firstName !== null) {
          //   count++;
          // }
          // if (UserData.lastName !== "" && UserData.lastName !== null) {
          //   count++;
          // }
          // if (UserData.mobileNumber !== "" && UserData.mobileNumber !== null) {
          //   count++;
          // }
          // if (
          //   UserData.address.address !== "" &&
          //   UserData.address.address !== null
          // ) {
          //   count++;
          // }
          // if (
          //   UserData.address.state !== "" &&
          //   UserData.address.state !== null
          // ) {
          //   count++;
          // }
          // if (UserData.address.city !== "" && UserData.address.city !== null) {
          //   count++;
          // }
          // if (UserData.address.zip !== "" && UserData.address.zip !== null) {
          //   count++;
          // }
          // if (
          //   UserData.address.country !== "" &&
          //   UserData.address.country !== null
          // ) {
          //   count++;
          // }

          // if (count !== 9) {
          //   if (
          //     window.location.href.substring(
          //       window.location.href.lastIndexOf("/") + 1
          //     ) !== "profile"
          //   ) {
          //     dispatch(setOpenProfileAlert(true));

          //   }
          //   dispatch(changePercentageProfileAlert((count / 9) * 360));
          // }
        }
        try {
          //check token is expired or not
          checkToken(token).then((response) => {
            if (response.status === 200) {
              setPreLoader(false);
              dispatch(authorize());
              dispatch(changeRole(roleNo[0].id));
            } else {
              if (staySignIn) {
                if (staySignIn === "true") {
                  //if token is expired get token with refresh token
                  getTokenIntoRefresh(reReToken).then((data) => {
                    JwtAuthService.setSession(
                      data.access_token,
                      data.refresh_token
                    );
                    dispatch(authorize());
                    dispatch(changeRole(roleNo[0].id));
                    setTimeout(() => {
                      setPreLoader(false);
                    }, 2000);
                  });
                } else {
                  dispatch(unAuthorize());
                  dispatch(changeRoleGuest());
                  setPreLoader(false);
                  JwtAuthService.logout("refresh");
                }
              } else {
                dispatch(unAuthorize());
                dispatch(changeRoleGuest());
                setPreLoader(false);
                JwtAuthService.logout("refresh");
              }
            }
          });
        } catch (error) {
          dispatch(unAuthorize());
          dispatch(changeRoleGuest());
          setPreLoader(false);
          JwtAuthService.logout("refresh");
        }
      } else {
        dispatch(unAuthorize());
        dispatch(changeRoleGuest());
        setPreLoader(false);
      }
    };
    AuthCheck();
    offline();
  }, [dispatch]);

  const pushAlert = (element, message) => {
    dispatch(appendAlert(element, message));
  };
  // async function pushProfile() {
  //   // await dispatch(setOpenProfileAlert(false));
  //   if (
  //     window.location.href.substring(
  //       window.location.href.lastIndexOf("/") + 1
  //     ) !== "profile"
  //   ) {
  //     window.location.href = "/profile";
  //   }
  //   // await setProfile(false);
  // }
  // async function close() {
  //   await dispatch(setOpenProfileAlert(false));
  //   // setProfile(false);
  // }

  //Fill Profile Details
  //It's working after login
  // const FillProfileUI = () => {
  //   return (
  //     <div className="">
  //       <style>
  //         {`
  //         .progressProfile .progressProfile-right .progressProfile-bar {
  //           animation: loading-1 1.8s linear forwards;
  //         }
  //         .progressProfile.blue .progressProfile-left .progressProfile-bar {
  //           animation: loading-2 1.5s linear forwards 1.8s;
  //         }
  //         .progressProfile.red .progressProfile-left .progressProfile-bar {
  //           animation: loading-2 1.5s linear forwards 1.8s;
  //         }
  //         @keyframes loading-1 {
  //           0% {
  //             -webkit-transform: rotate(0deg);
  //             transform: rotate(0deg);
  //           }

  //           100% {
  //             -webkit-transform: rotate(` +
  //           (profileAlert.percentage > 180 ? 180 : profileAlert.percentage) +
  //           `deg);
  //             transform: rotate(` +
  //           (profileAlert.percentage > 180 ? 180 : profileAlert.percentage) +
  //           `deg);
  //           }
  //         }

  //         @keyframes loading-2 {
  //           0% {
  //             -webkit-transform: rotate(0deg);
  //             transform: rotate(0deg);
  //           }

  //           100% {
  //             -webkit-transform: rotate(` +
  //           (profileAlert.percentage > 180
  //             ? profileAlert.percentage - 180
  //             : 0) +
  //           `deg);
  //             transform: rotate(` +
  //           (profileAlert.percentage > 180
  //             ? profileAlert.percentage - 180
  //             : 0) +
  //           `deg);
  //           }
  //         }

  //         `}
  //       </style>
  //       <div
  //         className="modal fade show profileFill"
  //         id={"profileModal"}
  //         tabIndex="-1"
  //         role="dialog"
  //         aria-labelledby="exampleModalLabel"
  //         aria-hidden="true"
  //         style={{
  //           minHeight: window.screen.height + 500,
  //         }}
  //       >
  //         <div
  //           className={"modal-dialog   modal-lg profileFill2"}
  //           role="document"
  //         >
  //           <div className="modal-content">
  //             <div
  //               className=" text-center pt-4 "
  //               style={{ height: "60px", borderBottom: "none" }}
  //             >
  //               <h1 className=" profileFillText2" style={{ marginTop: "0px" }}>
  //                 Complete your profile
  //               </h1>
  //             </div>
  //             <div className="modal-body bodyAvailable ">
  //               <div className="row  ">
  //                 <div className="col d-flex justify-content-center">
  //                   <div className="progressProfile blue">
  //                     {" "}
  //                     <span className="progressProfile-left">
  //                       {" "}
  //                       <span className="progressProfile-bar"></span>{" "}
  //                     </span>{" "}
  //                     <span className="progressProfile-right">
  //                       {" "}
  //                       <span className="progressProfile-bar"></span>{" "}
  //                     </span>
  //                     <div className="progressProfile-value">
  //                       {Math.round((profileAlert.percentage / 360) * 100)}%
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="row p-3">
  //                 <div className="col text-center">
  //                   <h1 className="one ">Please complete your profile.</h1>
  //                   <p className="profileFillText">
  //                     You're just a few steps away from making your profile
  //                     great!. A complete profile helps others to recognize you
  //                     more easily.
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className={" pt-3 shadow "} style={{ borderTop: "none" }}>
  //               <div className="row pr-4 pl-4 pb-3">
  //                 <div className="col text-right">
  //                   <button
  //                     className="pri text-left mr-2 textNoneCase"
  //                     data-dismiss="modal"
  //                     aria-label="Close"
  //                     onClick={() => close()}
  //                   >
  //                     I'll do it later
  //                   </button>
  //                   &nbsp; &nbsp;
  //                   <button
  //                     data-placement="top"
  //                     title={""}
  //                     className={"buttonPri textNoneCase"}
  //                     data-dismiss="modal"
  //                     onClick={() => pushProfile()}
  //                   >
  //                     Let's do it!
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  return preLoader ? (
    <Loader />
  ) : (
    <>
      <div name="App" className={dark ? "AppDark" : "AppMain"}>
        <BrowserRouter>
          {/* Alert message component */}
          <div className="text-right alertApp">
            {alert.map((todo) => {
              return (
                todo.status && (
                  <Alert
                    content={todo.text}
                    index={todo.id}
                    messageType={todo.messageType}
                    key={todo.id}
                  />
                )
              );
            })}
          </div>
          {/* It's load all the data before load the component */}
          {auth && role !== "Guest" && <PreLoaderHandler />}
          <NavBar
            role={role}
            UserData={user}
            pushAlert={pushAlert}
            changeSign={changeSign}
          />
          {/* {profileAlert.open && profile && <FillProfileUI />} */}
          <div className="appResponsive">
            <Switch>
              <Route exact path="/">
                <RentalUnits />
              </Route>
              <Route exact path="/contact-us">
                <ContactUs />
              </Route>
              <Route exact path="/about-us">
                <AboutUs />
              </Route>
              <Route path="/login">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/rental-units"
                >
                  <Redirect push to="/rental-units" />
                </Permission>
              </Route>
              <Route path="/signup">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/rental-units"
                >
                  <Redirect push to="/rental-units" />
                </Permission>
              </Route>
              <Route exact path="/rental-units/:id/:appId/:applicant/:order">
                <RentalUnits />
              </Route>
              <Route exact path="/rental-units/:id">
                <RentalUnits />
              </Route>
              <Route exact path="/rental-units">
                <RentalUnits />
              </Route>

              <Route exact path="/profile">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/"
                >
                  {role === "Landlord" ? (
                    <LandlordProfile UserData={user} pushAlert={pushAlert} />
                  ) : (
                    <TenantProfile UserData={user} pushAlert={pushAlert} />
                  )}
                </Permission>
              </Route>
              <Route exact path="/application/sora/:id">
                {auth && customerPaymentStatus !== "verified" ? (
                  <Permission
                    sign={sign}
                    changeSign={changeSign}
                    routes="secure"
                    defaultRoute="/payments"
                    guest="404"
                    withUserDetails={true}
                  >
                    <Payments role={role} />
                  </Permission>
                ) : (
                  <Permission
                    sign={sign}
                    changeSign={changeSign}
                    routes="secure"
                    defaultRoute="/properties"
                    withoutLandlordRoute="/properties"
                    landlord={false}
                    tenant={true}
                  >
                    <ListingApplication
                      pushAlert={pushAlert}
                      role={role}
                      dark={dark}
                      UserData={user}
                    />
                  </Permission>
                )}
              </Route>
              <Route exact path="/application/ontario/:id">
                {auth && customerPaymentStatus !== "verified" ? (
                  <Permission
                    sign={sign}
                    changeSign={changeSign}
                    routes="secure"
                    defaultRoute="/payments"
                    guest="404"
                    withUserDetails={true}
                  >
                    <Payments role={role} hitPoint={true} />{" "}
                  </Permission>
                ) : (
                  <Permission
                    sign={sign}
                    changeSign={changeSign}
                    routes="secure"
                    defaultRoute="/properties"
                    withoutLandlordRoute="/properties"
                    landlord={false}
                    tenant={true}
                  >
                    <ListingApplicationCanada
                      pushAlert={pushAlert}
                      role={role}
                      dark={dark}
                      UserData={user}
                    />
                  </Permission>
                )}
              </Route>
              <Route exact path="/application/british_columbia/:id">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/application"
                  withoutLandlordRoute="/properties"
                  landlord={false}
                  tenant={true}
                >
                  <ListingApplicationBritishColumbia
                    pushAlert={pushAlert}
                    role={role}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/application/office/:id">
                <OfficeApplication
                  pushAlert={pushAlert}
                  role={role}
                  dark={dark}
                  UserData={user}
                />
              </Route>

              <Route exact path="/application/:id">
                {/* {auth && customerPaymentStatus !== "verified" ? (
                  <Permission
                    sign={sign}
                    changeSign={changeSign}
                    routes="secure"
                    defaultRoute="/payments"
                    guest="404"
                    withUserDetails={true}
                    landlord={false}
                    tenant={true}
                  >
                    <Payments role={role} hitPoint={true} />{" "}
                  </Permission>
                ) : ( */}
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/application"
                  withoutLandlordRoute="/properties"
                  landlord={false}
                  tenant={true}
                >
                  <ListingApplication
                    pushAlert={pushAlert}
                    role={role}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
                {/* )} */}
              </Route>
              <Route
                exact
                path="/application/secondary/:id/:appId/:applicant/:order"
              >
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/application/secondary"
                  withoutLandlordRoute="/properties"
                  landlord={false}
                  tenant={true}
                >
                  <ListingApplicationSecondary
                    pushAlert={pushAlert}
                    role={role}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/applications">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/applications"
                  guest="404"
                >
                  <Applications dark={dark} role={role} UserData={user} />
                </Permission>
              </Route>
              <Route exact path="/contracts">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/contracts"
                  guest="404"
                >
                  <Contracts
                    pushAlert={pushAlert}
                    dark={dark}
                    role={role}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route path="/contracts/:id">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/contracts"
                  guest="404"
                >
                  <Contracts
                    pushAlert={pushAlert}
                    dark={dark}
                    role={role}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/create-contract/washington">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/create-contract/washington"
                  guest="404"
                  withoutTenantRoute="/properties"
                  tenant={false}
                  landlord={true}
                >
                  <CreateContractWashington
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/create-contract/british_columbia">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/create-contract/british_columbia"
                  guest="404"
                  withoutTenantRoute="/properties"
                  tenant={false}
                  landlord={true}
                >
                  <CreateContractBritishColumbia
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/create-contract/ontario">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/create-contract/ontario"
                  guest="404"
                  withoutTenantRoute="/create-contract/ontario"
                  tenant={false}
                  landlord={true}
                >
                  <CreateContractOntario
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route
                exact
                path="/maintenance/request_view/:token/:requestId/:providerId"
              >
                <MaintenanceRequestProviderDetail />
              </Route>
              <Route exact path="/maintenance/:requestId">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/maintenance"
                  guest="404"
                >
                  <MaintenanceRequestDetail />
                </Permission>
              </Route>
              <Route exact path="/maintenance">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/maintenance"
                  guest="404"
                >
                  <Maintenance />
                </Permission>
              </Route>

              <Route path="/dashboard">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/dashboard"
                  guest="404"
                  landlord={true}
                  admin={true}
                  tenant={false}
                >
                  <Dashboard
                    pushAlert={pushAlert}
                    role={role}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route
                exact
                path="/properties/:propsId/listings/:rentalEntityId/add-listing"
              >
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                  landlord={true}
                  tenant={false}
                >
                  <GetStepContent
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route
                exact
                path="/properties/:propsId/listings/:rentalEntityId/edit-listing"
              >
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                  landlord={true}
                  tenant={false}
                >
                  <GetStepContent
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route
                exact
                path="/properties/:propsId/listings/:rentalEntityId/add-tenant"
              >
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                  landlord={true}
                  tenant={false}
                >
                  <AddTenant
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/properties/:propsId/listings">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                  landlord={true}
                  tenant={false}
                >
                  <ViewListing />{" "}
                </Permission>
              </Route>
              <Route exact path="/properties/add-property">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties/add-property"
                  guest="404"
                  tenant={false}
                  landlord={true}
                >
                  <AddProp
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                    manage={"landlord"}
                  />
                </Permission>
              </Route>
              <Route exact path="/properties/add-property-by-axallant">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties/add-property-by-axallant"
                  guest="404"
                  tenant={false}
                  landlord={true}
                >
                  <AddProp
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                    manage={"axallant"}
                  />
                </Permission>
              </Route>
              <Route exact path="/properties/:propsId/edit-property">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                >
                  <AddProp pushAlert={pushAlert} dark={dark} UserData={user} />
                </Permission>
              </Route>
              <Route
                exact
                path="/properties/:propsId/edit-property-by-axallant"
              >
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                >
                  <AddProp
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                    manage={"axallant"}
                  />
                </Permission>
              </Route>
              <Route exact path="/properties">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                  tenant={false}
                  landlord={true}
                >
                  <ViewProp />
                </Permission>
              </Route>
              <Route exact path="/payments/add">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/payments/add"
                  guest="404"
                >
                  <AddTenantPayment
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/payments">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/payments"
                  guest="404"
                  withUserDetails={true}
                >
                  <Payments role={role} />
                </Permission>
              </Route>
              <Route
                exact
                path="/accept-contract/:contractId/:tenantEmail/:token"
              >
                <AcceptContract
                  pushAlert={pushAlert}
                  dark={dark}
                  UserData={user}
                />
              </Route>
              <Route
                exact
                path="/accept-primary-contract/:contractId/:tenantEmail/:token"
              >
                <AcceptContract
                  pushAlert={pushAlert}
                  dark={dark}
                  tenant="primary"
                  UserData={user}
                />
              </Route>
              <Route exact path="/registration-confirm/:Id">
                <RegistrationConfirm
                  pushAlert={pushAlert}
                  dark={dark}
                  UserData={user}
                />
              </Route>
              <Route exact path="/forgot-password/:Id">
                <ForgotPassword
                  pushAlert={pushAlert}
                  dark={dark}
                  UserData={user}
                />
              </Route>
              <Route exact path="/reset-password/:Id/:token">
                <ResetPassword
                  pushAlert={pushAlert}
                  dark={dark}
                  UserData={user}
                />
              </Route>
              <Route exact path="/change-password">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/profile"
                  guest="404"
                >
                  <ChangePassword
                    pushAlert={pushAlert}
                    dark={dark}
                    UserData={user}
                  />
                </Permission>
              </Route>
              <Route exact path="/auth/stripe">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/properties"
                  guest="404"
                >
                  <StripeLanding UserData={user} />{" "}
                </Permission>
              </Route>
              <Route
                exact
                path="/accept-direct-contract/:contractId/:tenantEmail"
              >
                <AcceptDirectContract
                  pushAlert={pushAlert}
                  dark={dark}
                  UserData={user}
                />
              </Route>
              <Route exact path="/accept-contract">
                <Permission
                  sign={sign}
                  changeSign={changeSign}
                  routes="secure"
                  defaultRoute="/contracts"
                  guest="404"
                >
                  <Contracts
                    pushAlert={pushAlert}
                    dark={dark}
                    role={role}
                    UserData={user}
                  />
                </Permission>
              </Route>

              <Route component={Page404} UserData={user} />
            </Switch>
          </div>
          <Chat />
          <div className="container-fluid m-0 p-0">
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
