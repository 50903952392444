import React, { Component } from "react";
import ApplicationInput from "../../../../UIServices/ApplicationInput";
import "../../contracts.css";
import "../../../Application/application.css";
import { withRouter } from "react-router-dom";
import {
  createContract,
  getLandlordContracts,
  uploadContractFile,
  uploadContractPdf,
} from "../../../../../Services/ApiServices";
import Loader from "../../../../Loader/Loader";
import Dropzone from "react-dropzone";
import Loader3 from "../../../../Loader/loader3";
import { connect } from "react-redux";
import { appendAlert, setContract } from "../../../../../actions";
import ContractReview from "../../../Application/Review/ContractReview";
import { today } from "../../../../../Defined/Function";
class CreateContractWashington extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      nowTab: 1,
      next: false,
      nextEnable: false,
      footer: true,
      pushFinished: false,
      applicationData: this.props.location.state
        ? this.props.location.state.applicationData
        : null,
      type: "",
      isLoading: true,
      activeStep: 0,
      moveInCheckList: "",
      moveOutCheckList: "",

      startDate: "",
      endDate: "",
      parkingAccess: false,
      indoorSmoking: false,
      indoorSmoking2: true,
      indoorVaping: false,
      moveEarly: false,
      rent: this.props.location.state
        ? this.props.location.state.applicationData
          ? this.props.location.state.applicationData.rent
          : ""
        : "",
      rentCollectionDate: "",
      isLateFee: false,
      gracePeriod: "",
      lateFeeCharging: "",
      lateFeeAmount: "",
      lateFeePercentage: "",
      isSecurityDeposit: true,
      securityDeposit: "",
      bankName: "",
      bankAddress: "",
      bankBranch: "",
      improvement: false,
      subLease: false,
      utilityResposible: false,
      isInspection: false,
      keyLosePayment: false,
      isTenantMaintenance: false,
      isTenantInsurance: false,
      isPetCharge: false,
      agreementPDF: true,
      pdfDoc: [],

      isWater: false,
      tenantCarpetCleaning: false,
      isSewer: false,
      isGarbage: false,
      isOther: false,
      otherUtility: "",
      rentalInsurance: true,
      smokeDetectionDeviceBattery: false,
      smokeDetectionDeviceHardWired: false,
      smokeDetectionFireSprinkler: false,
      smokeDetectionFireSprinkler2: true,
      smokeDetectionFireAlarm: false,
      smokeDetectionFireAlarm2: true,
      smokeDetectionEmeNotifi: false,
      smokeDetectionEmeNotifi2: true,
      smokeDetectionEmeNotifiFile: "",
      smokeDetectionEmeRelocation: false,
      smokeDetectionEmeRelocation2: true,
      smokeDetectionEmeRelocationFile: "",
      smokeDetectionEmeEvacuation: false,
      smokeDetectionEmeEvacuation2: true,
      smokeDetectionEmeEvacuationFile: "",

      petChargeMethod: "",
      petAmount: "",
      isButtonDisableFinish: true,
      rentalInfo:
        "In Washington, a Landlord may increase the rent at that end of a fixed term rental agreement by providing at least 30days written notice. With rentals without an end date, the Landlord may increase the rent upon at least 30 days notice. There are no state rent control laws in Washington, but the Landlord may want to confirm with the city or the county to ensure there is no rent control ordinance.",
      lateInfo:
        " In Washington State, the landlord may charge a late fee of upto 20% or $20, whichever is greater, as long as the late fee is mentioned in the rental agreement.",
      depositLimitInfo:
        "In Washington, there's no statutory limit on security deposits at the state level, but check your city and county laws to see if your municipality has set a cap on security deposits for residential rentals.",
      returnSecurityInfo:
        "In Washington, a landlord must return the tenant's security deposit within 21 days after the tenant has moved out.",
      isProRent: false,
      proRentFrom: "",
      proRentTo: "",
      shortSign: false,
      sign: false,
      signObject: null,
      shortSignObject: null,
    };
    this.onchange = this.onchange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.showButton = this.showButton.bind(this);
    this.uploadQuestionImage = this.uploadQuestionImage.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
  }
  setDemoData() {
    let date = new Date();
    date.setDate(date.getDate() + 90);
    let formattedEndDate = date.toISOString().slice(0, 10);
    this.setState({
      // applicationData: this.props.location.state
      // ? this.props.location.state.applicationData
      // : null,
      // type: "",
      // moveInCheckList: "",
      // moveOutCheckList: "",
      nextEnable: true,
      startDate: today(),
      endDate: formattedEndDate,
      rent: this.props.location.state
        ? this.props.location.state.applicationData
          ? this.props.location.state.applicationData.rent
          : ""
        : "",
      rentCollectionDate: "5",
      // gracePeriod: "",
      // lateFeeCharging: "",
      // lateFeeAmount: "",
      // lateFeePercentage: "",
      // isSecurityDeposit: true,
      securityDeposit: "23500",
      bankName: "JPMorgan Chase",
      bankAddress: "Northtown",
      bankBranch: "Spokane",
      // improvement: false,
      // subLease: false,
      // utilityResposible: false,
      // isInspection: false,
      // keyLosePayment: false,
      // isTenantMaintenance: false,
      // isTenantInsurance: false,
      // isPetCharge: false,
      // agreementPDF: true,
      // isWater: false,
      // tenantCarpetCleaning: false,
      // isSewer: false,
      // isGarbage: false,
      // isOther: false,
      // otherUtility: "",
      // rentalInsurance: true,
      // smokeDetectionDeviceBattery: false,
      // smokeDetectionDeviceHardWired: false,
      // smokeDetectionFireSprinkler: false,
      // smokeDetectionFireSprinkler2: true,
      // smokeDetectionFireAlarm: false,
      // smokeDetectionFireAlarm2: true,
      // smokeDetectionEmeNotifi: false,
      // smokeDetectionEmeNotifi2: true,
      // smokeDetectionEmeNotifiFile: "",
      // smokeDetectionEmeRelocation: false,
      // smokeDetectionEmeRelocation2: true,
      // smokeDetectionEmeRelocationFile: "",
      // smokeDetectionEmeEvacuation: false,
      // smokeDetectionEmeEvacuation2: true,
      // smokeDetectionEmeEvacuationFile: "",
      // petChargeMethod: "",
      // petAmount: "",
      // isButtonDisableFinish: true,
    });
  }
  showButton() {
    this.setState({ footer: true });
  }
  handleValidate() {
    if (this.state.pdfDoc.length !== 0) {
      this.handleFinish();
    } else {
      this.setState({ openModal: true });
    }
  }
  async handleFinish() {
    try {
      this.setState({ isButtonDisableFinish: false });

      let tenant = [];
      if (this.props.location.state.applicationData.apply[0]) {
        tenant.push({
          username: this.props.location.state
            ? this.props.location.state.applicationData.apply[0].applicant
                .username
            : null,
          tenantOrder: 1,
        });
      }
      if (this.props.location.state.applicationData.apply[1]) {
        tenant.push({
          username: this.props.location.state
            ? this.props.location.state.applicationData.apply[1].applicant
                .username
            : null,
          tenantOrder: 2,
        });
      }
      if (this.props.location.state.applicationData.apply[2]) {
        tenant.push({
          username: this.props.location.state
            ? this.props.location.state.applicationData.apply[2].applicant
                .username
            : null,
          tenantOrder: 3,
        });
      }
      let contractBody = {
        tenants: tenant,
        rentalEntityId: this.props.location.state
          ? this.props.location.state.applicationData.rentalEntityId
          : null,
        applicationId: this.props.location.state
          ? this.props.location.state.applicationData.id
          : null,
        agreementType: "monthly",
        otherAgreementType: null,
        moveInCheckList: null,
        moveOutCheckList: null,
        status: "PENDING",
        startDate: this.state.startDate !== "" ? this.state.startDate : null,
        endDate: this.state.endDate !== "" ? this.state.endDate : null,
        rent: this.state.rent !== "" ? this.state.rent : null,
        rentCollectionDay:
          this.state.rentCollectionDate !== ""
            ? parseInt(this.state.rentCollectionDate)
            : null,
        rentPaidOnPeriod: null,
        isSecurityDeposit: this.state.isSecurityDeposit,
        securityDeposit:
          this.state.isSecurityDeposit && this.state.securityDeposit !== ""
            ? this.state.securityDeposit
            : null,
        parkingAcce: this.state.parkingAccess,
        parkingDescription: "",
        condominium: false,
        condominiumLink: null,
        indoorSmoking: this.state.indoorSmoking,
        smokingDescription: this.state.smokingDescription,
        smokeDetectionEmeNotifi: this.state.smokeDetectionEmeNotifi,
        smokeDetectionEmeNotifiFile: "",
        smokeDetectionEmeRelocation: this.state.smokeDetectionEmeRelocation,
        smokeDetectionEmeRelocationFile: "",
        smokeDetectionEmeEvacuation: this.state.smokeDetectionEmeEvacuation,
        smokeDetectionEmeEvacuationFile: "",
        moveEarly: this.state.moveEarly,
        indoorVaping: this.state.indoorVaping,
        emailOption: false,
        documentEmails: "",
        landlordEmergencyContactOption: false,
        parkingFee: 0.0,
        otherRentFee1Name: "",
        otherRentFee1: 0.0,
        otherRentFee2Name: "",
        otherRentFee2: 0.0,
        otherRentFee3Name: "",
        otherRentFee3: 0.0,
        isRentDiscount: false,
        rentDiscount: 0.0,
        rentDiscountType: "",
        rentDiscountDescription: "",
        rentPayableTo: "",
        rentPayableMethod: "",
        electricityResponsible: "",
        heatResponsible: "",
        waterResponsible: this.state.isWater ? "tenant" : "",
        sewerResponsible: this.state.isSewer ? "tenant" : "",
        tenantCarpetCleaning: this.state.tenantCarpetCleaning,
        garbageResponsible: this.state.isGarbage ? "tenant" : "",
        otherResponsible: this.state.otherUtility,
        isTenantInsurance: this.state.rentalInsurance,
        smokeDetectionDevice:
          this.state.smokeDetectionDeviceHardWired &&
          this.state.smokeDetectionDeviceBattery
            ? "both"
            : this.state.smokeDetectionDeviceHardWired
            ? "hard_wired"
            : this.state.smokeDetectionDeviceBattery
            ? "battery"
            : "",
        smokeDetectionFireSprinkler: this.state.smokeDetectionFireSprinkler,
        smokeDetectionFireAlarm: this.state.smokeDetectionFireAlarm,
        airConditioner: "",
        gas: false,
        storage: false,
        laundry: false,
        laundryChargeType: "",
        guestParking: false,
        guestParkingType: "",
        serviceType1: "",
        serviceType2: "",
        serviceType3: "",
        serviceDescription: "",
        subscription: false,
        isPartialRent: false,
        partialRent: 0.0,
        partialRentDate: null,
        partialRentFrom: null,
        partialRentTo: null,

        isAdditionalTerms: false,
        additionalTermsDoc: "",
        landLordId: "",
        bankName:
          this.state.isSecurityDeposit && this.state.bankName !== ""
            ? this.state.bankName
            : null,
        bankBranch:
          this.state.isSecurityDeposit && this.state.bankBranch !== ""
            ? this.state.bankBranch
            : null,
        bankAddress:
          this.state.isSecurityDeposit && this.state.bankAddress !== ""
            ? this.state.bankAddress
            : null,
        improvement: this.state.improvement === "true" ? true : false,
        subLease: this.state.subLease === "true" ? true : false,
        isKeyDeposit: this.state.keyLosePayment,
        keyDepositDescription: null,
        keyDeposit: null,
        petChargeMethod: this.state.isPetCharge
          ? this.state.petChargeMethod
            ? "REFUNDABLE"
            : "NONREFUNDABLE"
          : null,
        petAmount:
          this.state.isPetCharge && this.state.petAmount !== ""
            ? this.state.petAmount
            : null,
        utilityResposible:
          this.state.utilityResposible === "true" ? true : false,
        islateFee: this.state.isLateFee,
        lateFeeCharging:
          this.state.isLateFee && this.state.lateFeeCharging !== ""
            ? this.state.lateFeeCharging
            : null,
        gracePeriod:
          this.state.isLateFee && this.state.gracePeriod !== ""
            ? this.state.gracePeriod
            : null,
        lateFeeAmount:
          this.state.isLateFee && this.state.lateFeeAmount !== ""
            ? this.state.lateFeeCharging === "PERCENTAGE"
              ? (this.state.rent * parseInt(this.state.lateFeePercentage)) / 100
              : this.state.lateFeeAmount
            : null,
        inspection: this.state.isInspection,
        petCharge: this.state.isPetCharge,
        tenantMaintenance: this.state.isTenantMaintenance,
        tenantInsurance: this.state.isTenantInsurance,
        agreementPDF: this.state.agreementPDF
          ? "AXALLANT_AGREEMENT"
          : "OWN_AGREEMENT_PENDING",
      };
      let body = {
        contract: contractBody,
        shortSignature: this.state.shortSignObject,
        signatureImage: this.state.signObject,
        signature: true,
      };
      createContract(body)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status) {
              if (this.state.smokeDetectionEmeNotifi) {
                this.fileUpload(
                  response.data.data,
                  0,
                  "smokingEmergencyNotification",
                  this.state.smokeDetectionEmeNotifiFile
                );
              }
              if (this.state.smokeDetectionEmeRelocation) {
                this.fileUpload(
                  response.data.data,
                  0,
                  "smokingEmergencyRelocation",
                  this.state.smokeDetectionEmeRelocationFile
                );
              }
              if (this.state.smokeDetectionEmeEvacuation) {
                this.fileUpload(
                  response.data.data,
                  0,
                  "smokingEmergencyEvacuation",
                  this.state.smokeDetectionEmeEvacuationFile
                );
              }
              if (this.state.pdfDoc.length !== 0) {
                const formData = new FormData();
                formData.append("file", this.state.pdfDoc);
                formData.append("version", 1);
                formData.append("tenantAccess", true);
                formData.append("landlordAccess", true);
                uploadContractPdf(formData, parseInt(response.data.data)).then(
                  (response1) => {
                    if (response1.status === 200) {
                      this.props.history.push({
                        pathname: "/contracts",
                        state: {
                          refresh: true,
                        },
                      });
                      this.setState({ isButtonDisableFinish: true });
                      this.setState({ footer: true });
                      this.props.appendAlert(
                        "Lease created successfully!",
                        "success"
                      );
                    } else {
                      this.props.history.push({
                        pathname: "/contracts",
                        state: {
                          refresh: true,
                        },
                      });
                      this.setState({ isButtonDisableFinish: true });
                      this.setState({ footer: true });
                      this.props.appendAlert(
                        "Lease created successfully!",
                        "success"
                      );
                      this.props.appendAlert("Pdf upload failed", "danger");
                    }
                  }
                );
              } else {
                this.props.history.push({
                  pathname: "/contracts",
                  state: {
                    refresh: true,
                  },
                });
                this.setState({ isButtonDisableFinish: true });
                this.setState({ footer: true });
                this.props.appendAlert(
                  "Lease created successfully!",
                  "success"
                );
              }

              try {
                getLandlordContracts().then((response2) => {
                  if (response2.status === 200) {
                    if (response2.data.length !== 0) {
                      this.props.setContract(
                        response2.data.sort((a, b) =>
                          b.updatedAt.localeCompare(a.updatedAt)
                        )
                      );
                    } else {
                      this.props.setContract([]);
                    }
                  }
                });
              } catch (error) {
                console.error(error);
              }
            } else {
              this.props.appendAlert("Something went wrong!", "danger");
            }
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );

            this.setState({ isButtonDisableFinish: true });
            this.setState({ footer: true });
          }
        })
        .catch(() => {
          this.props.appendAlert("Something went wrong!", "danger");
          this.setState({ isButtonDisableFinish: true });
          this.setState({ footer: true });
        });
    } catch (error) {
      this.setState({ isButtonDisableFinish: true });
      this.setState({ footer: true });
    }
  }
  fileUpload(contractId, version, cat, file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("version", version);
    formData.append("tenantAccess", true);
    formData.append("landlordAccess", true);
    uploadContractFile(formData, contractId, cat)
      .then((response) => {
        if (response.status === 200) {
          // this.props.appendAlert("File Uploaded successfully", "success");
        }
      })
      .catch(() => {
        this.props.appendAlert("File details couldn't be gathered", "warning");
      });
  }
  next() {
    this.setState({ next: true });
    if (this.state.nowTab < 5) {
      if (this.state.nowTab === 1) {
        window.scrollTo(0, 0);
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          this.setState({ next: false });
          if (!this.nextCheck(this.state.nowTab + 1)) {
            this.setState({ nextEnable: false });
          }
          this.setState({ nowTab: this.state.nowTab + 1 });
        }
      } else if (this.state.nowTab === 2) {
        window.scrollTo(0, 0);
        if (this.state.rent !== "" && this.state.rentCollectionDate !== "") {
          if (this.state.isProRent) {
            if (this.state.proRentFrom !== "" && this.state.proRentTo !== "") {
              if (this.state.isLateFee) {
                if (
                  (this.state.lateFeeAmount !== "" ||
                    this.state.lateFeePercentage !== "") &&
                  this.state.gracePeriod !== "" &&
                  this.state.lateFeeCharging !== ""
                ) {
                  this.setState({ next: false });
                  if (!this.nextCheck(this.state.nowTab + 1)) {
                    this.setState({ nextEnable: false });
                  }
                  this.setState({ nowTab: this.state.nowTab + 1 });
                }
              } else {
                this.setState({ next: false });
                if (!this.nextCheck(this.state.nowTab + 1)) {
                  this.setState({ nextEnable: false });
                }
                this.setState({ nowTab: this.state.nowTab + 1 });
              }
            }
          } else {
            if (this.state.isLateFee) {
              if (
                (this.state.lateFeeAmount !== "" ||
                  this.state.lateFeePercentage !== "") &&
                this.state.gracePeriod !== "" &&
                this.state.lateFeeCharging !== ""
              ) {
                this.setState({ next: false });
                if (!this.nextCheck(this.state.nowTab + 1)) {
                  this.setState({ nextEnable: false });
                }
                this.setState({ nowTab: this.state.nowTab + 1 });
              }
            } else {
              this.setState({ next: false });
              if (!this.nextCheck(this.state.nowTab + 1)) {
                this.setState({ nextEnable: false });
              }
              this.setState({ nowTab: this.state.nowTab + 1 });
            }
          }
        }
      } else if (this.state.nowTab === 3) {
        window.scrollTo(0, 0);
        if (this.state.isSecurityDeposit) {
          if (
            this.state.bankBranch !== "" &&
            this.state.securityDeposit !== "" &&
            this.state.bankName !== "" &&
            this.state.bankAddress !== ""
          ) {
            this.setState({ next: false });
            if (!this.nextCheck(this.state.nowTab + 1)) {
              this.setState({ nextEnable: false });
            }
            this.setState({ nowTab: this.state.nowTab + 1 });
          }
        } else {
          this.setState({ next: false });
          if (!this.nextCheck(this.state.nowTab + 1)) {
            this.setState({ nextEnable: false });
          }
          this.setState({ nowTab: this.state.nowTab + 1 });
        }
      } else if (this.state.nowTab === 4) {
        window.scrollTo(0, 0);
        if (this.state.isOther) {
          if (this.state.otherUtility !== "") {
            if (this.state.isPetCharge) {
              if (this.state.petAmount !== "") {
                if (!this.state.agreementPDF) {
                  if (this.state.pdfDoc.length !== 0) {
                    if (this.state.indoorSmoking) {
                      if (this.state.smokingDescription !== "") {
                        if (this.state.smokeDetectionEmeNotifi) {
                          if (this.state.smokeDetectionEmeNotifiFile !== "") {
                            if (this.state.smokeDetectionEmeRelocation) {
                              if (
                                this.state.smokeDetectionEmeRelocationFile !==
                                ""
                              ) {
                                if (this.state.smokeDetectionEmeEvacuation) {
                                  if (
                                    this.state
                                      .smokeDetectionEmeEvacuationFile !== ""
                                  ) {
                                    this.setState({ next: false });
                                    this.setState({
                                      nowTab: this.state.nowTab + 1,
                                    });
                                    this.setState({ footer: false });
                                  }
                                } else {
                                  this.setState({ next: false });
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              }
                            } else {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ next: false });
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              } else {
                                this.setState({ next: false });
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ next: false });
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              } else {
                                this.setState({ next: false });
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ next: false });
                                  if (!this.nextCheck(this.state.nowTab + 1)) {
                                    this.setState({ nextEnable: false });
                                  }
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              } else {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    }
                  } else {
                    this.props.appendAlert(
                      "Please add your agreement form",
                      "danger"
                    );
                  }
                } else {
                  if (this.state.indoorSmoking) {
                    if (this.state.smokingDescription !== "") {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ next: false });
                                  if (!this.nextCheck(this.state.nowTab + 1)) {
                                    this.setState({ nextEnable: false });
                                  }
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              } else {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (!this.state.agreementPDF) {
                if (this.state.pdfDoc.length !== 0) {
                  if (this.state.indoorSmoking) {
                    if (this.state.smokingDescription !== "") {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ next: false });
                                  if (!this.nextCheck(this.state.nowTab + 1)) {
                                    this.setState({ nextEnable: false });
                                  }
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              } else {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  }
                } else {
                  this.props.appendAlert(
                    "Please add your agreement form",
                    "danger"
                  );
                }
              } else {
                if (this.state.indoorSmoking) {
                  if (this.state.smokingDescription !== "") {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      } else {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          if (this.state.isPetCharge) {
            if (this.state.petAmount !== "") {
              if (!this.state.agreementPDF) {
                if (this.state.pdfDoc.length !== 0) {
                  if (this.state.indoorSmoking) {
                    if (this.state.smokingDescription !== "") {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ next: false });
                                  if (!this.nextCheck(this.state.nowTab + 1)) {
                                    this.setState({ nextEnable: false });
                                  }
                                  this.setState({
                                    nowTab: this.state.nowTab + 1,
                                  });
                                  this.setState({ footer: false });
                                }
                              } else {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  }
                } else {
                  this.props.appendAlert(
                    "Please add your agreement form",
                    "danger"
                  );
                }
              } else {
                if (this.state.indoorSmoking) {
                  if (this.state.smokingDescription !== "") {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      } else {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (!this.state.agreementPDF) {
              if (this.state.pdfDoc.length !== 0) {
                if (this.state.indoorSmoking) {
                  if (this.state.smokingDescription !== "") {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ next: false });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                                this.setState({ footer: false });
                              }
                            } else {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      } else {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    }
                  }
                }
              } else {
                this.props.appendAlert(
                  "Please add your agreement form",
                  "danger"
                );
              }
            } else {
              if (this.state.indoorSmoking) {
                if (this.state.smokingDescription !== "") {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ next: false });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                              this.setState({ footer: false });
                            }
                          } else {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      } else {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    }
                  }
                }
              } else {
                if (this.state.smokeDetectionEmeNotifi) {
                  if (this.state.smokeDetectionEmeNotifiFile !== "") {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ next: false });
                            if (!this.nextCheck(this.state.nowTab + 1)) {
                              this.setState({ nextEnable: false });
                            }
                            this.setState({ nowTab: this.state.nowTab + 1 });
                            this.setState({ footer: false });
                          }
                        } else {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      } else {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeRelocation) {
                    if (this.state.smokeDetectionEmeRelocationFile !== "") {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ next: false });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                          this.setState({ footer: false });
                        }
                      } else {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeEvacuation) {
                      if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                        this.setState({ next: false });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        this.setState({ footer: false });
                      }
                    } else {
                      this.setState({ next: false });
                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({ nowTab: this.state.nowTab + 1 });
                      this.setState({ footer: false });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  nextCheck(tab) {
    this.setState({ nextEnable: false });
    if (tab < 5) {
      if (tab === 1) {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          this.setState({ nextEnable: true });
          return true;
        }
      } else if (tab === 2) {
        if (this.state.rent !== "" && this.state.rentCollectionDate !== "") {
          if (this.state.isProRent) {
            if (this.state.proRentFrom !== "" && this.state.proRentTo !== "") {
              if (this.state.isLateFee) {
                if (
                  (this.state.lateFeeAmount !== "" ||
                    this.state.lateFeePercentage !== "") &&
                  this.state.gracePeriod !== "" &&
                  this.state.lateFeeCharging !== ""
                ) {
                  this.setState({ nextEnable: true });
                  return true;
                }
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            }
          } else {
            if (this.state.isLateFee) {
              if (
                (this.state.lateFeeAmount !== "" ||
                  this.state.lateFeePercentage !== "") &&
                this.state.gracePeriod !== "" &&
                this.state.lateFeeCharging !== ""
              ) {
                this.setState({ nextEnable: true });
                return true;
              }
            } else {
              this.setState({ nextEnable: true });
              return true;
            }
          }
        }
      } else if (tab === 3) {
        if (this.state.isSecurityDeposit) {
          if (
            this.state.bankBranch !== "" &&
            this.state.securityDeposit !== "" &&
            this.state.bankName !== "" &&
            this.state.bankAddress !== ""
          ) {
            this.setState({ nextEnable: true });
            return true;
          }
        } else {
          this.setState({ nextEnable: true });
          return true;
        }
      } else if (tab === 4) {
        if (this.state.isOther) {
          if (this.state.otherUtility !== "") {
            if (this.state.isPetCharge) {
              if (this.state.petAmount !== "") {
                if (!this.state.agreementPDF) {
                  if (this.state.pdfDoc.length !== 0) {
                    if (this.state.indoorSmoking) {
                      if (this.state.smokingDescription !== "") {
                        if (this.state.smokeDetectionEmeNotifi) {
                          if (this.state.smokeDetectionEmeNotifiFile !== "") {
                            if (this.state.smokeDetectionEmeRelocation) {
                              if (
                                this.state.smokeDetectionEmeRelocationFile !==
                                ""
                              ) {
                                if (this.state.smokeDetectionEmeEvacuation) {
                                  if (
                                    this.state
                                      .smokeDetectionEmeEvacuationFile !== ""
                                  ) {
                                    this.setState({ nextEnable: true });
                                    return true;
                                  }
                                } else {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              }
                            } else {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              } else {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              } else {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              } else {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    }
                  } else {
                    this.props.appendAlert(
                      "Please add your agreement form",
                      "danger"
                    );
                  }
                } else {
                  if (this.state.indoorSmoking) {
                    if (this.state.smokingDescription !== "") {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              } else {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (!this.state.agreementPDF) {
                if (this.state.pdfDoc.length !== 0) {
                  if (this.state.indoorSmoking) {
                    if (this.state.smokingDescription !== "") {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              } else {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  }
                } else {
                  this.props.appendAlert(
                    "Please add your agreement form",
                    "danger"
                  );
                }
              } else {
                if (this.state.indoorSmoking) {
                  if (this.state.smokingDescription !== "") {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          if (this.state.isPetCharge) {
            if (this.state.petAmount !== "") {
              if (!this.state.agreementPDF) {
                if (this.state.pdfDoc.length !== 0) {
                  if (this.state.indoorSmoking) {
                    if (this.state.smokingDescription !== "") {
                      if (this.state.smokeDetectionEmeNotifi) {
                        if (this.state.smokeDetectionEmeNotifiFile !== "") {
                          if (this.state.smokeDetectionEmeRelocation) {
                            if (
                              this.state.smokeDetectionEmeRelocationFile !== ""
                            ) {
                              if (this.state.smokeDetectionEmeEvacuation) {
                                if (
                                  this.state.smokeDetectionEmeEvacuationFile !==
                                  ""
                                ) {
                                  this.setState({ nextEnable: true });
                                  return true;
                                }
                              } else {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            }
                          } else {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  }
                } else {
                  this.props.appendAlert(
                    "Please add your agreement form",
                    "danger"
                  );
                }
              } else {
                if (this.state.indoorSmoking) {
                  if (this.state.smokingDescription !== "") {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (!this.state.agreementPDF) {
              if (this.state.pdfDoc.length !== 0) {
                if (this.state.indoorSmoking) {
                  if (this.state.smokingDescription !== "") {
                    if (this.state.smokeDetectionEmeNotifi) {
                      if (this.state.smokeDetectionEmeNotifiFile !== "") {
                        if (this.state.smokeDetectionEmeRelocation) {
                          if (
                            this.state.smokeDetectionEmeRelocationFile !== ""
                          ) {
                            if (this.state.smokeDetectionEmeEvacuation) {
                              if (
                                this.state.smokeDetectionEmeEvacuationFile !==
                                ""
                              ) {
                                this.setState({ nextEnable: true });
                                return true;
                              }
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        } else {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    }
                  }
                }
              } else {
                this.props.appendAlert(
                  "Please add your agreement form",
                  "danger"
                );
              }
            } else {
              if (this.state.indoorSmoking) {
                if (this.state.smokingDescription !== "") {
                  if (this.state.smokeDetectionEmeNotifi) {
                    if (this.state.smokeDetectionEmeNotifiFile !== "") {
                      if (this.state.smokeDetectionEmeRelocation) {
                        if (this.state.smokeDetectionEmeRelocationFile !== "") {
                          if (this.state.smokeDetectionEmeEvacuation) {
                            if (
                              this.state.smokeDetectionEmeEvacuationFile !== ""
                            ) {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      } else {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    }
                  }
                }
              } else {
                if (this.state.smokeDetectionEmeNotifi) {
                  if (this.state.smokeDetectionEmeNotifiFile !== "") {
                    if (this.state.smokeDetectionEmeRelocation) {
                      if (this.state.smokeDetectionEmeRelocationFile !== "") {
                        if (this.state.smokeDetectionEmeEvacuation) {
                          if (
                            this.state.smokeDetectionEmeEvacuationFile !== ""
                          ) {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        } else {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      }
                    } else {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    }
                  }
                } else {
                  if (this.state.smokeDetectionEmeRelocation) {
                    if (this.state.smokeDetectionEmeRelocationFile !== "") {
                      if (this.state.smokeDetectionEmeEvacuation) {
                        if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                          this.setState({ nextEnable: true });
                          return true;
                        }
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    }
                  } else {
                    if (this.state.smokeDetectionEmeEvacuation) {
                      if (this.state.smokeDetectionEmeEvacuationFile !== "") {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    } else {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return false;
  }
  back() {
    if (this.state.nowTab > 1) {
      window.scrollTo(0, 0);
      if (!this.nextCheck(this.state.nowTab - 1)) {
        this.setState({ nextEnable: false });
      }
      this.setState({ nowTab: this.state.nowTab - 1 });
    }
  }
  convertPdf(pdf) {
    const url = window.URL.createObjectURL(new Blob([pdf], { type: pdf.type }));
    const link = document.createElement("a");
    link.href = url;
    return link;
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  async onChangeDate(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  async onChangeState(name, value) {
    await this.setState({ [name]: value });
  }
  async onchange(e) {
    let { name, value } = e.target;
    if (name === "rentCollectionDate" || name === "gracePeriod") {
      let sin = value.replace(/[^\d]/g, "");
      value = sin;
      if (value > 31 || value < 1) {
        value = "";
      }
      await this.setState({ [name]: value });
    }
    if (
      name === "lateFeePercentage" ||
      name === "lateFeeAmount" ||
      name === "securityDeposit" ||
      name === "petAmount" ||
      name === "rent"
    ) {
      let sin = value.replace(/[^\d^.]/g, "");
      value = sin;
      await this.setState({ [name]: value });
    } else {
      await this.setState({ [name]: value });
    }
    this.nextCheck(this.state.nowTab);
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    if (name === "smokeDetectionFireSprinkler") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionFireSprinkler2: !checked });
    } else if (name === "smokeDetectionFireSprinkler2") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionFireSprinkler: !checked });
    } else if (name === "smokeDetectionFireAlarm") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionFireAlarm2: !checked });
    } else if (name === "smokeDetectionFireAlarm2") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionFireAlarm: !checked });
    } else if (name === "indoorSmoking") {
      await this.setState({ [name]: checked });
      await this.setState({ indoorSmoking2: !checked });
    } else if (name === "indoorSmoking2") {
      await this.setState({ [name]: checked });
      await this.setState({ indoorSmoking: !checked });
    } else if (name === "smokeDetectionEmeNotifi") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionEmeNotifi2: !checked });
    } else if (name === "smokeDetectionEmeNotifi2") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionEmeNotifi: !checked });
    } else if (name === "smokeDetectionEmeRelocation") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionEmeRelocation2: !checked });
    } else if (name === "smokeDetectionEmeRelocation2") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionEmeRelocation: !checked });
    } else if (name === "smokeDetectionEmeEvacuation") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionEmeEvacuation2: !checked });
    } else if (name === "smokeDetectionEmeEvacuation2") {
      await this.setState({ [name]: checked });
      await this.setState({ smokeDetectionEmeEvacuation: !checked });
    } else if (name === "smokeDetectionDeviceHardWired") {
      await this.setState({ [name]: checked });
      if (checked) {
        this.setState({ smokeDetectionDeviceBattery: !checked });
      }
    } else if (name === "smokeDetectionDeviceBattery") {
      await this.setState({ [name]: checked });
      if (checked) {
        this.setState({ smokeDetectionDeviceHardWired: !checked });
      }
    } else if (name === "rentalInsurance") {
      this.props.appendAlert("This is required to move next step!", "warning");
    } else {
      await this.setState({ [name]: checked });
    }
    this.nextCheck(this.state.nowTab);
  }
  base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  async uploadQuestionImage(e) {
    let { name } = e.target;
    if (e.target.files[0]) {
      if (e.target.files[0].type === "application/pdf") {
        let reader = new FileReader();
        reader.onloadend = async () => {
          let blob = new Blob([reader.result], {
            type: e.target.files[0].type,
          });
          await this.setState({ [name]: blob });
          this.nextCheck(this.state.nowTab);
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.props.appendAlert("pdf file only acceptable", "danger");
      }
    }
  }
  checkNull(value) {
    return (value === "" || value === null) && this.state.next;
  }
  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push({
        pathname: "/applications",
      });
    }
  }

  render() {
    return (
      <>
        <div
          className="container-fluid"
          style={{ minHeight: window.screen.height }}
        >
          <div className="row stepFormNav">
            <div className="col text-center">
              <div className="row">
                <div className="col SideLineHide"></div>
                <div className="col">
                  <div className=" social-container-step activeStep">
                    <div className="social ">
                      <i
                        className="fa fa-address-card-o "
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 1</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 1
                      ? "col  SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 1
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col">
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-home " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 2</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 2
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>

            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 2
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col ">
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-shield" aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 3</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 3
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 3
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col">
                  <div
                    className={
                      this.state.nowTab > 3
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social">
                      <i className="fa fa-puzzle-piece " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 4</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 4
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 4
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col">
                  <div
                    className={
                      this.state.nowTab > 4
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social">
                      <i className="fa fa-retweet " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 5</span>
                  <br></br>
                </div>
                <div className="col SideLineHide"></div>
              </div>
            </div>
          </div>

          <div className="row stepFormNavTitle ">
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Basic</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle ">Rental</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Security</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Additional</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Review</p>
            </div>
          </div>
          {this.state.nowTab === 1 && (
            <div className="applicationAgreement">
              <div className="cardContract">
                <div className="row inputContainer pt-2 ">
                  <div className="col-xl-4 alignInput">
                    <ApplicationInput
                      name="Lease Start Date"
                      type="date"
                      target="startDate"
                      max={this.state.endDate}
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage="lease start date"
                      validate={this.checkValidate(this.state.startDate)}
                      value={this.state.startDate}
                    />
                  </div>
                  <div className="col-xl-4 alignInput ">
                    <ApplicationInput
                      name="Lease End Date"
                      type="date"
                      target="endDate"
                      onChange={this.onChangeDate}
                      important="true"
                      min={this.state.startDate}
                      errorMessage="lease end date"
                      validate={this.checkValidate(this.state.endDate)}
                      value={this.state.endDate}
                    />
                  </div>
                  <div className="col-lg alignInput ">
                    <ApplicationInput
                      name="Can tenant move before the lease start?"
                      type="optionYN"
                      target="moveEarly"
                      onChange={this.onchangeCheck}
                      validate={this.checkValidate(this.state.moveEarly)}
                      value={this.state.moveEarly}
                    />
                  </div>
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Smoking Allowed"
                      type="optionYN"
                      target="indoorSmoking"
                      onChange={this.onchangeCheck}
                      validate={this.checkValidate(this.state.indoorSmoking)}
                      value={this.state.indoorSmoking}
                    />
                  </div>
                  <div className="col-lg alignInput ">
                    <ApplicationInput
                      name="Vaping Allowed"
                      type="optionYN"
                      target="indoorVaping"
                      onChange={this.onchangeCheck}
                      validate={this.checkValidate(this.state.indoorVaping)}
                      value={this.state.indoorVaping}
                    />
                  </div>
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Parking Access"
                      type="optionYN"
                      target="parkingAccess"
                      onChange={this.onchangeCheck}
                      validate={this.checkValidate(this.state.parkingAccess)}
                      value={this.state.parkingAccess}
                    />
                  </div>
                </div>
                {process.env.REACT_APP_STATUS !== "Production" && (
                  <div className="row inputContainer pt-0 pb-0 mt-0 mb-0">
                    <div className="col  d-flex flex-row-reverse">
                      <span
                        class="badge p-2 text-white pointer"
                        onClick={() => this.setDemoData()}
                        style={{ backgroundColor: "#42dc13" }}
                      >
                        Test Data
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.nowTab === 2 && (
            <div className="applicationAgreement">
              <div className="cardContract  ">
                <div className="row  inputContainer pt-2 pb-4">
                  <div className="col-12 ">
                    <ApplicationInput
                      name="How many days notice will you give before increasing rent?"
                      type="info"
                      value={""}
                      target="rentalInfo"
                      titleBreck="true"
                      // onChange={this.onchange}
                    />
                    <div className="informationListAppNormal mt-1">
                      <span className="searchListContentApp">
                        {this.state.rentalInfo}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-3">
                    <ApplicationInput
                      name="Monthly Rent"
                      type="text"
                      place="1200.00"
                      target="rent"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="monthly rent"
                      validate={this.checkValidate(this.state.rent)}
                      iconL={"fa-usd"}
                      value={this.state.rent}
                    />
                  </div>

                  <div className="col mt-3">
                    <ApplicationInput
                      name="What day of the month will payment be made?"
                      type="text"
                      place="1"
                      target="rentCollectionDate"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="valid date"
                      validate={this.checkValidate(
                        this.state.rentCollectionDate
                      )}
                      value={this.state.rentCollectionDate}
                    />
                  </div>
                  <div className="col-12 pt-1">
                    <ApplicationInput
                      name="Do you want to charge the tenant for late rent payments?"
                      type="optionYN"
                      target="isLateFee"
                      onChange={this.onchangeCheck}
                      // validate={this.checkValidate(this.state.isLateFee)}
                      value={this.state.isLateFee}
                    />
                  </div>

                  {this.state.isLateFee && (
                    <>
                      <div className="col mt-1 mb-2">
                        <div className="row">
                          <div className="col-6 ">
                            <ApplicationInput
                              name="Late Payments in Washington"
                              type="info"
                              value={""}
                              target="lateInfo"
                              // onChange={this.onchange}
                            />
                            <div className="informationListAppNormal mt-1">
                              <span className="searchListContentApp">
                                {this.state.lateInfo}
                              </span>
                            </div>
                          </div>
                          <div className="col ">
                            <div className="row">
                              <div className="col-lg-6 ">
                                <ApplicationInput
                                  name="Grace Period"
                                  type="text"
                                  designType="2"
                                  target="gracePeriod"
                                  onChange={this.onchange}
                                  value={this.state.gracePeriod}
                                  important="true"
                                  errorMessage="the grace period"
                                  validate={this.checkValidate(
                                    this.state.gracePeriod
                                  )}
                                />
                              </div>
                              <div className="col-lg-6 ">
                                <ApplicationInput
                                  name="Charging Method"
                                  target="lateFeeCharging"
                                  type="optional"
                                  designType="2"
                                  options={[
                                    "Fixed amount",
                                    "Charge per day",
                                    // "Percentage",
                                  ]}
                                  optionValue={[
                                    "FIXED",
                                    "PERDAY",
                                    // "PERCENTAGE",
                                  ]}
                                  onChange={this.onchange}
                                  important="true"
                                  errorMessage="unit type you are living"
                                  validate={this.checkValidate(
                                    this.state.lateFeeCharging
                                  )}
                                  value={this.state.lateFeeCharging}
                                />
                              </div>
                              {this.state.lateFeeCharging === "PERCENTAGE" ? (
                                <div className="col-6 ">
                                  <ApplicationInput
                                    name="Percentage"
                                    type="text"
                                    place={
                                      this.state.lateFeeCharging !== ""
                                        ? String(
                                            this.state.lateFeeCharging
                                          ).toLocaleLowerCase()
                                        : " 5%"
                                    }
                                    target="lateFeePercentage"
                                    onChange={this.onchange}
                                    important="true"
                                    errorMessage="the grace period Amount"
                                    validate={this.checkValidate(
                                      this.state.lateFeePercentage
                                    )}
                                    value={this.state.lateFeePercentage}
                                  />
                                </div>
                              ) : (
                                <div className="col-6 ">
                                  <ApplicationInput
                                    name="Amount"
                                    type="text"
                                    place={
                                      this.state.lateFeeCharging !== ""
                                        ? String(
                                            this.state.lateFeeCharging
                                          ).toLocaleLowerCase()
                                        : "20 or 5%"
                                    }
                                    target="lateFeeAmount"
                                    onChange={this.onchange}
                                    iconL={"fa-usd"}
                                    important="true"
                                    errorMessage="the grace period Amount"
                                    validate={this.checkValidate(
                                      this.state.lateFeeAmount
                                    )}
                                    value={this.state.lateFeeAmount}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-12 pt-1">
                    <ApplicationInput
                      name="Do you have pro-rated payment?"
                      type="optionYN"
                      target="isProRent"
                      onChange={this.onchangeCheck}
                      value={this.state.isProRent}
                    />
                  </div>
                  {this.state.isProRent && (
                    <>
                      <div className="col mt-1 mb-2">
                        <div className="row">
                          <div className="col-lg-4 ">
                            <ApplicationInput
                              name="Rent From"
                              type="date"
                              target="proRentFrom"
                              onChange={this.onChangeDate}
                              important="true"
                              errorMessage="pro-rated rent from date"
                              validate={this.checkValidate(
                                this.state.proRentFrom
                              )}
                              value={this.state.proRentFrom}
                            />
                          </div>
                          <div className="col-lg-4 ">
                            <ApplicationInput
                              name="Rent To"
                              type="date"
                              target="proRentTo"
                              onChange={this.onChangeDate}
                              important="true"
                              errorMessage="pro-rated rent to date"
                              validate={this.checkValidate(
                                this.state.proRentTo
                              )}
                              value={this.state.proRentTo}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 ">
                    <div className="informationListAppNormal mt-1 ">
                      <span className="searchListContentApp">
                        <b>RENTERS INSURANCE.</b> Renter’s insurance is
                        available to Tenant for coverage related to liability
                        for 81 bodily injury, property damage, and for the
                        theft, loss, or damage to Tenant’s personal property.
                      </span>
                      <br></br>
                      <br></br>
                      <ApplicationInput
                        name={
                          <>
                            {" "}
                            <span
                              className="searchListContentApp"
                              style={{
                                whiteSpace: "pre-line",
                                lineHeight: "25px",
                              }}
                            >
                              <b>Rental Insurance.</b> Tenant shall obtain
                              renter’s insurance providing coverage for
                              liability, bodily injury, property damage, and for
                              the theft, loss, or damage to Tenant’s personal
                              property stored on the Property. Tenant shall
                              provide Lessor a copy of the renter’s insurance
                              policy within five days of mutual acceptance of
                              this Agreement.
                            </span>
                          </>
                        }
                        type="checkedBox"
                        target="rentalInsurance"
                        onChange={this.onchangeCheck}
                        value={this.state.rentalInsurance}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.nowTab === 3 && (
            <div className="applicationAgreement">
              <div className="cardContract  ">
                <div className="row inputContainer pt-3  pb-4">
                  <div className="col-xl-4 ">
                    <ApplicationInput
                      name="Security Deposit"
                      type="optionYN"
                      target="isSecurityDeposit"
                      onChange={this.onchangeCheck}
                      // important="true"
                      // errorMessage="the security deposit amount"
                      // validate={this.checkValidate(this.state.isSecurityDeposit)}
                      value={this.state.isSecurityDeposit}
                    />
                    {this.state.isSecurityDeposit && (
                      <>
                        <p className=" stepTitle  textDark">
                          Bank Holding Deposits
                        </p>
                        <br></br>
                        <ApplicationInput
                          name="Bank Name"
                          type="text"
                          target="bankName"
                          onChange={this.onchange}
                          value={this.state.bankName}
                          important="true"
                          errorMessage="the bank name"
                          validate={this.checkValidate(this.state.bankName)}
                        />

                        <ApplicationInput
                          name="Branch"
                          type="text"
                          target="bankBranch"
                          onChange={this.onchange}
                          value={this.state.bankBranch}
                          important="true"
                          errorMessage="the bank branch"
                          validate={this.checkValidate(this.state.bankBranch)}
                        />

                        <ApplicationInput
                          name="City/Town"
                          type="text"
                          target="bankAddress"
                          onChange={this.onchange}
                          value={this.state.bankAddress}
                          important="true"
                          errorMessage="the bank city name"
                          validate={this.checkValidate(this.state.bankAddress)}
                        />
                      </>
                    )}
                  </div>
                  {this.state.isSecurityDeposit && (
                    <>
                      <div className="col ">
                        <ApplicationInput
                          name="Security Deposit Amount"
                          type="text"
                          place="2000.00"
                          target="securityDeposit"
                          onChange={this.onchange}
                          value={this.state.securityDeposit}
                          iconL={"fa-usd"}
                          important="true"
                          errorMessage="the security reposit amount"
                          validate={this.checkValidate(
                            this.state.securityDeposit
                          )}
                        />
                        <br></br>
                        <ApplicationInput
                          name="Security Deposit Limits in City"
                          type="info"
                          value={""}
                          target="depositLimitInfo"
                          // onChange={this.onchange}
                        />
                        <div className="informationListAppNormal mt-1">
                          <span className="searchListContentApp">
                            {this.state.depositLimitInfo}
                          </span>
                        </div>
                        <br></br>

                        <ApplicationInput
                          name="When Landlord Must Return Security Deposit"
                          type="info"
                          value={""}
                          target="returnSecurityInfo"
                          // onChange={this.onchange}
                        />
                        <div className="informationListAppNormal mt-1">
                          <span className="searchListContentApp">
                            {this.state.returnSecurityInfo}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.nowTab === 4 && (
            <div className="applicationAgreement">
              <div className="cardContract  ">
                <div className="row inputContainer  pt-2">
                  <div className="col-md-7 ">
                    <ApplicationInput
                      name="Will the tenant be allowed to make improvements to the property?"
                      type="YN"
                      options={["With landlord's consent", "No"]}
                      target="improvement"
                      onChange={this.onchange}
                      value={this.state.improvement}
                    />
                    <ApplicationInput
                      name="Who is the responsible for paying the utilities?"
                      type="YN"
                      options={["Tenant", "Included in Rent"]}
                      target="utilityResposible"
                      onChange={this.onchange}
                      value={this.state.utilityResposible}
                    />
                    <ApplicationInput
                      name="Can the tenant assign/sublease the property?"
                      type="YN"
                      options={["With landlord's consent", "No"]}
                      target="subLease"
                      onChange={this.onchange}
                      value={this.state.subLease}
                    />
                    <ApplicationInput
                      name="Is tenant responsible for any maintenance?"
                      type="optionYN"
                      target="isTenantMaintenance"
                      onChange={this.onchangeCheck}
                      value={this.state.isTenantMaintenance}
                    />
                  </div>
                  <div className="col-5 ">
                    <ApplicationInput
                      name="Pet Deposit"
                      type="optionYN"
                      target="isPetCharge"
                      onChange={this.onchangeCheck}
                      value={this.state.isPetCharge}
                    />

                    {this.state.isPetCharge && (
                      <>
                        <ApplicationInput
                          name="Pet Charge Type"
                          type="optionYN"
                          options={["Refundable", "Non Refundable"]}
                          target="petChargeMethod"
                          onChange={this.onchangeCheck}
                          value={this.state.petChargeMethod}
                          size="long"
                        />

                        <ApplicationInput
                          name="Pet Amount"
                          type="text"
                          place="200"
                          target="petAmount"
                          onChange={this.onchange}
                          validate={this.checkValidate(this.state.petAmount)}
                          important="true"
                          errorMessage="the pet amount"
                          value={this.state.petAmount}
                          iconL={"fa-usd"}
                        />
                      </>
                    )}
                    {/* <div className="row">
                      <div className="col   ">
                        <ApplicationInput
                          name="Insurance"
                          type="optionYN"
                          target="isTenantInsurance"
                          onChange={this.onchangeCheck}
                          value={this.state.isTenantInsurance}
                        />
                      </div>
                      <div className="col  ">
                        <ApplicationInput
                          name="Key Deposit"
                          type="optionYN"
                          target="keyLosePayment"
                          onChange={this.onchangeCheck}
                          value={this.state.keyLosePayment}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="col-12">
                    <p className=" stepTitle  textDark">Utilities</p>
                    <br></br>
                    <span className="searchListContentApp">
                      Tenant shall pay all utilities when due except:
                    </span>
                    <br></br>
                    <div className="row mt-3">
                      <div className="col">
                        <ApplicationInput
                          name="Carpet Cleaning"
                          type="optionYN"
                          target="tenantCarpetCleaning"
                          onChange={this.onchangeCheck}
                          value={this.state.tenantCarpetCleaning}
                        />
                      </div>
                      <div className="col">
                        <ApplicationInput
                          name="Water"
                          type="optionYN"
                          target="isWater"
                          onChange={this.onchangeCheck}
                          value={this.state.isWater}
                        />
                      </div>
                      <div className="col">
                        <ApplicationInput
                          name="Sewer"
                          type="optionYN"
                          target="isSewer"
                          onChange={this.onchangeCheck}
                          value={this.state.isSewer}
                        />
                      </div>

                      <div className="col">
                        <ApplicationInput
                          name="Garbage"
                          type="optionYN"
                          target="isGarbage"
                          onChange={this.onchangeCheck}
                          value={this.state.isGarbage}
                        />
                      </div>
                      <div className="col  ">
                        <ApplicationInput
                          name="Other"
                          type="optionYN"
                          target="isOther"
                          onChange={this.onchangeCheck}
                          value={this.state.isOther}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {this.state.isOther && (
                          <ApplicationInput
                            name="Other Utility"
                            type="text"
                            target="otherUtility"
                            onChange={this.onchange}
                            value={this.state.otherUtility}
                            place="gas,electricity"
                            important="true"
                            errorMessage="the other utilities"
                            validate={this.checkValidate(
                              this.state.otherUtility
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 ">
                      <br></br>
                      <div className="informationListAppNormal mt-1  ">
                        <div className="row">
                          <span
                            className="col searchListContentApp"
                            style={{
                              whiteSpace: "pre-line",
                              lineHeight: "25px",
                            }}
                          >
                            <b>SMOKE DETECTOR. </b>
                            Tenant acknowledges and Lessor certifies that the
                            Property is equipped with a smoke detector(s) as
                            required by RCW 43.44.110 and that the detector(s)
                            has/have been tested and is/are operable. It is
                            Tenant's responsibility to maintain the smoke
                            detector(s) as specified by the manufacturer,
                            including replacement of batteries, if required. In
                            addition, if the Property is a multi-family building
                            (more than one unit), Lessor makes the following
                            disclosures:
                          </span>
                        </div>
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp pr-3 pt-3">
                            (a) The smoke detection device is
                          </span>
                          <div className=" p-0 m-0 pt-3  pr-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  hard-wired;
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionDeviceHardWired"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionDeviceHardWired}
                            />
                          </div>
                          <div className=" p-0 m-0 pt-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  battery operated
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionDeviceBattery"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionDeviceBattery}
                            />
                          </div>
                        </div>
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp pr-3 pt-3">
                            (b) The building
                          </span>
                          <div className=" p-0 m-0 pt-3  pr-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  does;
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionFireSprinkler"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionFireSprinkler}
                            />
                          </div>
                          <div className=" p-0 m-0 pt-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  does not have a fire sprinkler system.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionFireSprinkler2"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionFireSprinkler2}
                            />
                          </div>
                        </div>
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp pr-3 pt-3">
                            (c) The building
                          </span>
                          <div className=" p-0 m-0 pt-3  pr-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  does;
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionFireAlarm"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionFireAlarm}
                            />
                          </div>
                          <div className=" p-0 m-0 pt-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  does not have a fire alarm system.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionFireAlarm2"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionFireAlarm2}
                            />
                          </div>
                        </div>
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp  pt-3">
                            (d) &nbsp;&nbsp;
                          </span>
                          <div className=" p-0 m-0 pt-3 ">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building has a smoking policy, as follows:
                                </span>
                              }
                              type="checkedBox"
                              target="indoorSmoking"
                              onChange={this.onchangeCheck}
                              value={this.state.indoorSmoking}
                            />
                          </div>
                        </div>
                        {this.state.indoorSmoking && (
                          <div className="row alignSmokeDetect ">
                            <div className="col ">
                              <ApplicationInput
                                name="null"
                                designType="2"
                                type="textArea"
                                target="smokingDescription"
                                onChange={this.onchange}
                                value={this.state.smokingDescription}
                                place="policies"
                                important="true"
                                errorMessage="the other utilities"
                                validate={this.checkValidate(
                                  this.state.smokingDescription
                                )}
                              />
                            </div>
                          </div>
                        )}
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp pt-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <div className=" p-0 m-0 pt-3  ">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building does not have a smoking policy
                                </span>
                              }
                              type="checkedBox"
                              target="indoorSmoking2"
                              onChange={this.onchangeCheck}
                              value={this.state.indoorSmoking2}
                            />
                          </div>
                        </div>

                        <div className="row  alignSmokeDetect" style={{}}>
                          <span className="searchListContentApp  pt-3">
                            (e) &nbsp;&nbsp;
                          </span>
                          <div
                            className="  pt-3  "
                            style={{
                              paddingLeft: "35px",
                            }}
                          >
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building has an emergency notification
                                  plan for occupants, a copy of which is
                                  attached to this Agreement.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionEmeNotifi"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionEmeNotifi}
                            />
                          </div>
                        </div>
                        {this.state.smokeDetectionEmeNotifi && (
                          <div className="row alignSmokeDetect">
                            <div className="col pt-1 alignSmokeDetect">
                              <label
                                htmlFor={"smokeDetectionEmeNotifiFile"}
                                className={
                                  this.checkNull(
                                    this.state.smokeDetectionEmeNotifiFile
                                  )
                                    ? "buttonUploadAlert"
                                    : "buttonUpload"
                                }
                              >
                                <i
                                  className="fa fa-file-text"
                                  aria-hidden="true"
                                ></i>{" "}
                                &nbsp;&nbsp;Upload
                              </label>
                              <input
                                id={"smokeDetectionEmeNotifiFile"}
                                style={{ display: "none" }}
                                type="file"
                                accept="application/pdf"
                                name="smokeDetectionEmeNotifiFile"
                                onChange={this.uploadQuestionImage}
                              />
                              {this.state.smokeDetectionEmeNotifiFile !==
                                "" && (
                                <i
                                  className="fa fa-check pt-2 pl-4 ax-success"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            {this.checkNull(
                              this.state.smokeDetectionEmeNotifiFile
                            ) && (
                              <div className="col-12 alignSmokeDetect">
                                <span className="textPri searchListContentApp">
                                  Please add file
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp pt-3 ">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <div className=" p-0 m-0 pt-3  pr-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building does not have an emergency
                                  notification plan for occupants.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionEmeNotifi2"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionEmeNotifi2}
                            />
                          </div>
                        </div>
                        <div className="row alignSmokeDetect ">
                          <span className="searchListContentApp  pt-3">
                            (f) &nbsp;&nbsp;
                          </span>
                          <div
                            className="  pt-3  "
                            style={{
                              paddingLeft: "2px",
                            }}
                          >
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building does not have an emergency
                                  relocation plan for occupants.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionEmeRelocation"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionEmeRelocation}
                            />
                          </div>
                        </div>
                        {this.state.smokeDetectionEmeRelocation && (
                          <div className="row alignSmokeDetect">
                            <div className="col pt-1 alignSmokeDetect">
                              <label
                                htmlFor={"smokeDetectionEmeRelocationFile"}
                                className={
                                  this.checkNull(
                                    this.state.smokeDetectionEmeRelocationFile
                                  )
                                    ? "buttonUploadAlert"
                                    : "buttonUpload"
                                }
                              >
                                <i
                                  className="fa fa-file-text"
                                  aria-hidden="true"
                                ></i>{" "}
                                &nbsp;&nbsp;Upload
                              </label>
                              <input
                                id={"smokeDetectionEmeRelocationFile"}
                                style={{ display: "none" }}
                                type="file"
                                accept="application/pdf"
                                name="smokeDetectionEmeRelocationFile"
                                onChange={this.uploadQuestionImage}
                              />
                              {this.state.smokeDetectionEmeRelocationFile !==
                                "" && (
                                <i
                                  className="fa fa-check pt-2 pl-4 ax-success"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            {this.checkNull(
                              this.state.smokeDetectionEmeRelocationFile
                            ) && (
                              <div className="col-12 alignSmokeDetect">
                                <span className="textPri searchListContentApp">
                                  Please add file
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="row alignSmokeDetect ">
                          <span className="searchListContentApp pt-3 ">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <div
                            className="  pt-3  pr-3"
                            style={{
                              paddingLeft: "2px",
                            }}
                          >
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building does not have an emergency
                                  relocation plan for occupants.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionEmeRelocation2"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionEmeRelocation2}
                            />
                          </div>
                        </div>
                        <div className="row alignSmokeDetect ">
                          <span className="searchListContentApp  pt-5">
                            (g) &nbsp;&nbsp;
                          </span>
                          <div
                            className="  pt-3 "
                            style={{
                              paddingLeft: "35px",
                            }}
                          >
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building has an emergency evacuation plan
                                  for occupants, a copy of which is attached to
                                  this Agreement.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionEmeEvacuation"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionEmeEvacuation}
                            />
                          </div>
                        </div>
                        {this.state.smokeDetectionEmeEvacuation && (
                          <div className="row alignSmokeDetect">
                            <div className="col pt-1 alignSmokeDetect">
                              <label
                                htmlFor={"smokeDetectionEmeEvacuationFile"}
                                className={
                                  this.checkNull(
                                    this.state.smokeDetectionEmeEvacuationFile
                                  )
                                    ? "buttonUploadAlert"
                                    : "buttonUpload"
                                }
                              >
                                <i
                                  className="fa fa-file-text"
                                  aria-hidden="true"
                                ></i>{" "}
                                &nbsp;&nbsp;Upload
                              </label>
                              <input
                                id={"smokeDetectionEmeEvacuationFile"}
                                style={{ display: "none" }}
                                type="file"
                                accept="application/pdf"
                                name="smokeDetectionEmeEvacuationFile"
                                onChange={this.uploadQuestionImage}
                              />
                              {this.state.smokeDetectionEmeEvacuationFile !==
                                "" && (
                                <i
                                  className="fa fa-check pt-2 pl-4 ax-success"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            {this.checkNull(
                              this.state.smokeDetectionEmeEvacuationFile
                            ) && (
                              <div className="col-12 alignSmokeDetect">
                                <span className="textPri searchListContentApp">
                                  Please add file
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="row alignSmokeDetect">
                          <span className="searchListContentApp pt-3 ">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <div className=" p-0 m-0 pt-3  pr-3">
                            <ApplicationInput
                              name={
                                <span
                                  className="searchListContentApp"
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "25px",
                                  }}
                                >
                                  The building does not have an emergency
                                  evacuation plan for occupants.
                                </span>
                              }
                              type="checkedBox"
                              target="smokeDetectionEmeEvacuation2"
                              onChange={this.onchangeCheck}
                              value={this.state.smokeDetectionEmeEvacuation2}
                            />
                          </div>
                        </div>
                        <span className="searchListContentApp pt-3">
                          Tenant hereby acknowledges receipt of a copy of the
                          building's emergency evacuation routes.
                        </span>
                      </div>
                      <br></br>
                    </div>
                  </div>

                  <div className="col-12">
                    <ApplicationInput
                      name="Are you want to use default agreement form?"
                      type="optionYN"
                      target="agreementPDF"
                      onChange={this.onchangeCheck}
                      value={this.state.agreementPDF}
                    />
                  </div>
                  {!this.state.agreementPDF && (
                    <>
                      <div className="col-md-4">
                        <p className="span2  text-center  ">
                          Please upload your agreement form
                        </p>
                        <Dropzone
                          accept=".pdf"
                          maxSize={10485760}
                          onDrop={(acceptedFiles) => {
                            if (acceptedFiles[0] === undefined) {
                              this.props.appendAlert(
                                "Pdf size is too Long (max size = 10mb) ",
                                "danger"
                              );
                            } else {
                              this.setState({ pdfDoc: acceptedFiles[0] });
                            }
                          }}
                          multiple={false}
                          submitButtonContent="Upload"
                        >
                          {({ getRootProps, getInputProps }) => (
                            <>
                              <div
                                className={
                                  this.state.next &&
                                  this.state.pdfDoc.length === 0
                                    ? "alert"
                                    : "mt-4"
                                }
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />

                                <div
                                  className="dragDrop text-center "
                                  style={{
                                    fontSize: "60px",
                                    minHeight: "350px",
                                    paddingTop: "100px",
                                  }}
                                >
                                  <div className="row">
                                    <div className="col">
                                      <i
                                        className={
                                          this.props.dark
                                            ? "fa fa-cloud-upload  fa-10x dragIcon text-light vert-move "
                                            : "fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                        }
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <p
                                        className={
                                          this.props.dark
                                            ? "licenseHeadDark"
                                            : "licenseHead"
                                        }
                                      >
                                        Drag Or Click to Upload Pdf
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </Dropzone>
                        <span className="span2 textPri text-center pull-right ">
                          Max file size is 10MB. Pdf format only.
                        </span>
                      </div>
                      <div className="col-md mb-4 ">
                        {/* <embed
                          src={this.convertPdf(this.state.pdfDoc)}
                          width="100%"
                          height="450px"
                        /> */}
                        <center>
                          <div className="PDFWeb">
                            <object
                              data={this.convertPdf(this.state.pdfDoc)}
                              type="application/pdf"
                              width="100%"
                              height="450px"
                            >
                              <p>
                                Your web browser doesn't have a PDF plugin.
                                Instead you can{" "}
                                <a href={this.convertPdf(this.state.pdfDoc)}>
                                  click here to download the PDF file.
                                </a>
                              </p>
                            </object>
                          </div>
                          <div className="PDFMobile">
                            <object
                              data={this.convertPdf(this.state.pdfDoc)}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                            >
                              <p>
                                Your web browser doesn't have a PDF plugin.
                                Instead you can{" "}
                                <a href={this.convertPdf(this.state.pdfDoc)}>
                                  click here to download the PDF file.
                                </a>
                              </p>
                            </object>
                          </div>
                          <a
                            className="pt-0 mt-0 PDFMobile "
                            href={this.convertPdf(this.state.pdfDoc)}
                          >
                            click here to view the full PDF file.
                          </a>
                        </center>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.nowTab === 5 && (
            <>
              {this.state.pushFinished ? (
                <Loader />
              ) : (
                <>
                  <ContractReview
                    data={this.state}
                    showButton={this.showButton}
                    onChangeState={this.onChangeState}
                    signObject={this.state.signObject}
                    shortSignObject={this.state.shortSignObject}
                    sign={this.state.sign}
                    shortSign={this.state.shortSign}
                  />
                </>
              )}
            </>
          )}
          {this.state.footer && (
            <div className="row appFootButton text-right">
              <div className="col ">
                <button
                  className="pri mr-3"
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </button>
                {this.state.nowTab !== 1 && (
                  <button className="mr-3 pri" onClick={() => this.back()}>
                    Back
                  </button>
                )}
                {this.state.nowTab !== 5 &&
                  (this.state.nextEnable ? (
                    <button className="buttonPri " onClick={() => this.next()}>
                      Next
                    </button>
                  ) : (
                    <button className="secDisable " onClick={() => this.next()}>
                      Next
                    </button>
                  ))}
                {this.state.nowTab === 5 && (
                  <button
                    className={
                      !this.state.isButtonDisableFinish
                        ? "secDisable notAllow "
                        : " buttonPri "
                    }
                    disabled={!this.state.isButtonDisableFinish}
                    onClick={() => this.handleFinish()}
                  >
                    Submit
                    {!this.state.isButtonDisableFinish && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setContract })(CreateContractWashington)
);
