import React from "react";
import "./Rating.css";

const Rating = (props) => {
  function ratingClass(rate, num) {
    var n1 = getMonth(num);
    if (n1 === "non") {
      return "boxx non";
    }
    if (rate === "5") {
      return "boxx excellent";
    } else if (rate === "4") {
      return "boxx good";
    } else if (rate === "3") {
      return "boxx ok";
    } else if (rate === "2") {
      return "boxx bad";
    } else if (rate === "1") {
      return "boxx veryBad";
    } else if (rate === "0") {
      return "boxx non1";
    }
  }
  const month = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  function getMonth(num) {
    var today = new Date();
    var n1 = (num + (today.getMonth() + 1)) % 13;

    if (n1 === 0) {
      return "non";
    }
    if (n1 === 1) {
      return "Jan";
    } else if (n1 === 2) {
      return "Feb";
    } else if (n1 === 3) {
      return "Mar";
    } else if (n1 === 4) {
      return "Apr";
    } else if (n1 === 5) {
      return "May";
    } else if (n1 === 6) {
      return "Jun";
    } else if (n1 === 7) {
      return "Jul";
    } else if (n1 === 8) {
      return "Aug";
    } else if (n1 === 9) {
      return "Sep";
    } else if (n1 === 10) {
      return "Oct";
    } else if (n1 === 11) {
      return "Nov";
    } else if (n1 === 12) {
      return "Dec";
    }
  }

  return (
    <div className="row">
      <div className="col">
        <div className="BackStatus dashCover ">
          <div className="row pb-2">
            <div className="col-sm-4 ">
              <h3 className="text-dark pt-3 pl-3 tableHead cardHead">Ratings</h3>
            </div>
            <div className="col-sm-8  pt-3 pr-4 text-right d-flex justify-content-end">
              <div className="pr-2 ">
                <span className="badge badge-primary excellent p-1">
                  Excellent
                </span>
              </div>
              <div className="pr-2 ">
                <span className="badge badge-primary good p-1">Good</span>
              </div>
              <div className="pr-2 ">
                <span
                  className="badge badge-primary ok p-1"
                  style={{ color: "#313131" }}
                >
                  Late
                </span>
              </div>
              <div className="pr-2 ">
                <span className="badge badge-primary bad p-1">Very Late</span>
              </div>
              {/* <div className="pr-2 "><span className="badge badge-primary veryBad p-1" style={{ color:"#313131" }}>Very Bad</span></div> */}
            </div>
          </div>

          <table className="table table-responsive tableRating">
            <thead>
              <tr className="rowTable">
                <th className="ratingName headName">Tenant Name</th>
                <th className="headName">{new Date().getFullYear() - 1}</th>
                {month.map((m) => (
                  <th
                    /*className={getMonth(m)==="non"?"":""}*/ className="headName"
                  >
                    {getMonth(m) === "non"
                      ? new Date().getFullYear().toString()
                      : getMonth(m)}
                  </th>
                ))}
                <th className="ratingFianl headName">Final</th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((ta) => (
                  <tr className="rowTable">
                    <td className="ratingName">{ta.name}</td>
                    <td>
                      <div className="boxx non"></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.jan, 0)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.feb, 1)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.mar, 2)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.apr, 3)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.may, 4)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.jun, 5)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.jul, 6)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.aug, 7)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.sep, 8)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.oct, 9)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.nov, 10)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.dec, 11)}></div>
                    </td>
                    <td>
                      <div className={ratingClass(ta.dec, 12)}></div>
                    </td>
                    <td className="ratingFianl">{ta.finalRating}%</td>{" "}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Rating;
