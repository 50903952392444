import React, { Component } from "react";
import "../contracts.css";
import coverImage from "../../../../Images/no-cover-img.jpg";
import image1 from "../../../../Images/profile.png";
import {
  getTenantContracts,
  acceptContract,
  userDetail,
  url,
  checkPaymentStatus,
  getPaymentData,
  payRent,
  viewContractDocument,
  changeVisibleContractDocument,
  deleteContractDocument,
  uploadContractFile,
} from "../../../../Services/ApiServices";
import Loader from "../../../Loader/Loader";
import NoData from "../../../../Messages/NoData";
import ModalContainer from "../../Documents/ModalContainer";
import ViewDocuments from "../../Documents/ViewDocuments";
import Dropzone from "react-dropzone";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import SearchInput from "../../Application/SearchInput";
import PaymentMethod from "../PaymentMethod";
import {
  sortText,
  sortText2,
  capitalizeFirstLetter,
  getFullMonth,
  setDateFormat,
  convertLocalDate,
  addressFormatter,
} from "../../../../Defined/Function";
import ContractListTenant from "./ContractListTenant";
import { connect } from "react-redux";
import {
  appendAlert,
  setContract,
  setCustomerPaymentStatus,
  appendTempUsers,
} from "../../../../actions";
import { withRouter } from "react-router-dom";
import Loader3 from "../../../Loader/loader3";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

export class ContractTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      contractData: [],
      noData: false,
      viewId: 0,
      viewFile: "contractAgreement",
      viewContract: false,
      viewTitle: "",
      ModalData: [],
      modalLandlordData: [],
      modalLandlordImage: image1,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      payedMonth: new Date().getMonth() + 1,
      modalImage: "",
      agreePayment: false,
      confirmLoader: false,
      isButtonDisableFinishPay: true,
      isButtonDisableFinish: false,
      paymentData: [],
      paymentDataSelect: [],
      additionalPayment: false,
      additionalPaymentAmount: "",
      additionalPaymentType: "",
      total: 0,
      next1: false,
      uploadAgreement: false,
      files: false,
      pdfDoc: [],
      fileDoc: [],
      pdfButtonDisable: false,
      fileButtonDisable: false,
      next: false,
      fileType: "",
      fileName: "",
      uploadWindow: false,
      viewFilesArray: [],
      monthlyTick: false,
      shortSign: false,
      sign: false,
      signObject: null,
      shortSignObject: null,
    };
    this.closeContract = this.closeContract.bind(this);
    this.closeContract1 = this.closeContract.bind(this);
    this.viewContract = this.viewContract.bind(this);
    this.payRent = this.payRent.bind(this);
    this.setModalData = this.setModalData.bind(this);
    this.setModalImage = this.setModalImage.bind(this);
    this.acceptContract = this.acceptContract.bind(this);
    this.onchange = this.onchange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckModal = this.onchangeCheckModal.bind(this);
    this.footerMessage = this.footerMessage.bind(this);
    this.changeState = this.changeState.bind(this);
    this.viewFiles = this.viewFiles.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
  }
  viewContract(viewFilesArray, id, file) {
    this.setState({
      viewId: id,
      viewFilesArray: viewFilesArray ? viewFilesArray : [],
    });
    this.setState({ viewFile: file });
    this.setState({ viewContract: true });
    if (file === "contractAgreement") {
      this.setState({ viewTitle: "Contract Agreement" });
    } else {
      this.setState({ viewTitle: "Move In List" });
    }
  }
  viewFiles(viewFilesArray, id) {
    this.setState({
      viewFilesArray: viewFilesArray ? viewFilesArray : [],
      viewId: id,
      files: true,
      uploadWindow: false,
    });
  }
  closeContract() {
    this.setState({ viewContract: false, files: false });
  }
  onchangeCheck(e) {
    const {
      target: { name, checked },
    } = e;
    if (name === "additionalPayment") {
      let array = this.state.paymentData;
      let total = 0;
      for (const element of array) {
        if (element.tick) {
          total = total + element.dueAmount;
        }
      }
      this.setState({
        ...this.state,
        [name]: checked,
        additionalPaymentAmount: "",
        additionalPaymentType: "",
        total: total,
      });
    } else {
      this.setState({ ...this.state, [name]: checked });
    }
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      await getTenantContracts().then(async (response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            await this.props.setContract(null);
            this.props.setContract(
              response.data.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
            );
          } else {
            this.props.setContract([]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await checkPaymentStatus().then((response) => {
        if (response.status === 200) {
          if (response.status) {
            this.props.setCustomerPaymentStatus(response.data.data);
          }
        }
      });
    } catch (err) {}
  }

  handleActiveKeyChange = (activeKey) => {
    if (this.state.activeKey === activeKey) {
      activeKey = -1;
    }
    this.setState({ activeKey });
  };
  setUser(data) {
    this.props.appendTempUsers(data);
    if (data.provider === "local") {
      fetch(url + `api/user/${data.landLordId}/image/download`)
        .then((res) => {
          if (res.status === 200) {
            if (data.coverImage === null) {
              this.setState({
                modalLandlordImage: image1,
                modalLandlordData: data,
              });
            } else {
              this.setState({
                modalLandlordImage: res.url,
                modalLandlordData: data,
              });
            }
          } else {
            this.setState({
              modalLandlordImage: image1,
              modalLandlordData: data,
            });
          }
        })
        .catch(() => {
          this.setState({
            modalLandlordImage: image1,
            modalLandlordData: data,
          });
        });
    } else {
      this.setState({
        modalLandlordImage: data.coverImage,
        modalLandlordData: data,
      });
    }
  }
  getUser(ModalData) {
    try {
      userDetail(ModalData.landLordId).then((response) => {
        if (response.status) {
          this.setUser(response.data);
        }
      });
    } catch (error) {
      this.setState({ modalLandlordImage: image1 });
    }
  }
  async setModalData(ModalData, image, mode) {
    await this.setState({
      paymentData: [],
      total: 0,
      additionalPaymentAmount: "",
      additionalPaymentType: "",
      additionalPayment: false,
      sign: false,
      shortSign: false,
      signObject: null,
      shortSignObject: null,
    });
    if (mode === "accept") {
      window.$("#shortSignatureModal" + ModalData.rentalEntityId).modal("show");
    } else {
      window.$("#exampleModal1").modal("show");
    }
    this.setState({ isButtonDisableFinish: true });
    try {
      getPaymentData(this.props.userDetail.username, ModalData.contractId).then(
        (response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({ paymentData: response.data });
              let array = response.data;
              for (const element of array) {
                element.tick = false;
              }
              this.setState({ isButtonDisableFinish: false });
            }
          }
        }
      );
    } catch (err) {}
    if (this.props.customerPaymentStatus !== "verified") {
      this.props.history.push({
        pathname: "/payments/add",
        state: {
          data: this.props.userDetail,
          status: this.props.customerPaymentStatus,
          type: "initial",
        },
      });
    } else {
      this.setState({
        ModalData: ModalData,
        modalImage: image,
        agreePayment: false,
      });
      if (this.state.modalLandlordData.username !== ModalData.landLordId) {
        this.setState({
          modalLandlordImage: image1,
          modalLandlordData: [],
        });
      }
      if (ModalData.landLordId) {
        let users = this.props.tempUsersArray.filter(
          (todo) => todo.username === ModalData.landLordId
        );
        if (users.length === 0) {
          this.getUser(ModalData);
        } else {
          this.setUser(users[0]);
          this.getUser(ModalData);
        }
      }
    }
  }
  setModalImage(image, rentalEntityId) {
    if (this.state.ModalData.rentalEntityId === rentalEntityId) {
      this.setState({ modalImage: image });
    }
  }
  pay() {
    this.setState({ isButtonDisableFinishPay: false });
    window.$("#exampleModal1").modal("hide");

    let data = this.state.paymentData.filter((data) => data.tick);
    let array = data;
    for (const element of array) {
      element.paidAmount = element.dueAmount;
    }
    data = array;
    let data1 = [
      {
        contractId: this.state.ModalData.contractId,
        tenantId: this.props.userDetail.username,
        landlordId: this.state.ModalData.landLordId,
        paidAmount: this.state.additionalPaymentAmount,
        paymentType: this.state.additionalPaymentType,
      },
    ];
    let body = {
      paymentRecords: data,
      aditionalPayments: this.state.additionalPayment ? data1 : [],
    };
    try {
      payRent(body).then(async (response) => {
        if (response.status === 200) {
          setTimeout(() => {
            this.setState({ isButtonDisableFinishPay: true });
            this.props.appendAlert("Payment successful", "success");
          }, 7000);
        } else {
          this.setState({ isButtonDisableFinishPay: true });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  async payRent() {
    this.setState({ next1: true });
    if (this.state.additionalPayment) {
      if (
        this.state.additionalPaymentType !== "" &&
        this.state.additionalPaymentAmount !== ""
      ) {
        this.setState({ next1: true });
        this.pay();
      }
    } else {
      this.setState({ next1: true });
      this.pay();
    }
  }

  async acceptContract(subscription, paymentDay) {
    let updated = this.state.ModalData;

    updated.status = "ACTIVE";
    updated.subscription = subscription;

    this.setState({ isOpen: false });
    let body = {
      subscription: subscription,
      contractId: this.state.ModalData.contractId,
      primaryLandlordId: this.state.ModalData.landLordId,
      primaryTenantId: this.state.ModalData.tenants[0].username,
      paymentDay: subscription ? parseInt(paymentDay) : null,
      rent: this.state.ModalData.rent,
      contract: this.state.ModalData,
      shortSignature: this.state.shortSignObject,
      signatureImage: this.state.signObject,
      signature: true,
    };
    try {
      await acceptContract(body).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.setState((prevState) => {
              let newData = prevState.ModalData;
              Object.assign(newData, updated);
              return { ModalData: newData };
            });
            this.props.appendAlert("You have accepted the contract", "success");
            try {
              getTenantContracts().then(async (response1) => {
                window.scrollTo(0, 0);
                if (response1.status === 200) {
                  if (response1.data.length !== 0) {
                    await this.props.setContract(null);
                    this.props.setContract(
                      response1.data.sort((a, b) =>
                        b.updatedAt.localeCompare(a.updatedAt)
                      )
                    );
                  } else {
                    this.props.setContract([]);
                  }
                }
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            updated.status = "PENDING";
            this.props.appendAlert("Error in acceptance", "danger");
            this.props.appendAlert(response.data, "danger");
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  async onchange(e) {
    let { name, value } = e.target;
    if (name === "additionalPaymentType") {
      if (value.length < 254) {
        await this.setState({ [name]: value });
      }
    } else if (name === "additionalPaymentAmount") {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      let array = this.state.paymentData;
      let total = 0;
      for (const element of array) {
        if (element.tick) {
          total = total + element.dueAmount;
        }
      }
      total = total + parseInt(value === "" ? 0 : value);
      this.setState({ total: total });
      await this.setState({ [name]: value });
    } else {
      await this.setState({ [name]: value });
    }
  }
  async changeState(name, value) {
    await this.setState({ [name]: value });
  }
  checkValidate(value) {
    return (
      (value === "" && this.state.next) ||
      (value === undefined && this.state.next)
    );
  }
  checkValidate1(value) {
    return value === "" && this.state.next1;
  }
  async onchangeCheckModal(e) {
    let { name, checked } = e.target;
    let array = this.state.paymentData;
    let total = 0;

    if (checked) {
      for (const element of array) {
        if (element.id === parseInt(name)) {
          element.tick = checked;
          total =
            element.dueAmount +
            parseInt(
              this.state.additionalPaymentAmount === ""
                ? 0
                : this.state.additionalPaymentAmount
            );
        } else {
          element.tick = !checked;
        }
      }
      this.setState({ paymentData: array, total: total });
    }
    // if (checked) {
    //   total =
    //     total +
    //     array[x].dueAmount +
    //     parseInt(
    //       this.state.additionalPaymentAmount === ""
    //         ? 0
    //         : this.state.additionalPaymentAmount
    //     );
    // } else {
    //   total =
    //     total -
    //     array[x].dueAmount +
    //     parseInt(
    //       this.state.additionalPaymentAmount === ""
    //         ? 0
    //         : this.state.additionalPaymentAmount
    //     );
    // }
  }
  footerMessage() {
    return (
      <div className="pull-left" style={{ fontFamily: "Montserrat" }}>
        <input
          className="pointer  mt-2 "
          type="checkbox"
          id="agreePayment"
          name="agreePayment"
          checked={this.state.agreePayment ? "checked" : ""}
          onChange={this.onchangeCheck}
        />
        <label className="agreePayment pl-2" htmlFor="exampleCheck1">
          I agree to the payment
        </label>
      </div>
    );
  }

  downloadFile(data) {
    viewContractDocument(data.id, data.category).then((response) => {
      if (response.status === 200) {
        const url1 = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url1;
        link.download = data.category + " - " + data.version + ".pdf";
        link.click();
        window.URL.revokeObjectURL(url1);
      } else {
        this.props.appendAlert("Download Failed", "danger");
      }
    });
  }
  saveUpload() {
    if (this.state.fileType !== "" && this.state.fileDoc.length !== 0) {
      let cat =
        this.state.fileType === "Final Agreement"
          ? "contractAgreement"
          : this.state.fileType === "Draft Agreement"
          ? "draftAgreement"
          : this.state.fileType === "Credit Check"
          ? "creditCheck"
          : this.state.fileType === "Background Check"
          ? "backgroundCheck"
          : this.state.fileType;
      let array = this.state.viewFilesArray
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((todo) => todo.category === cat);
      let length = 1;
      if (array.length !== 0) {
        length = parseInt(array[0].version) + 1;
      }
      this.fileUpload(length, cat);
    } else {
      this.setState({ next: true });
    }
  }

  updateFileAccess(data, access) {
    data.landlordAccess = access;
    changeVisibleContractDocument(data, this.state.viewId)
      .then((response) => {
        if (response.status === 200) {
          window.$("#filesView").modal("hide");
          this.props.appendAlert("File Access changed successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              for (let i = 0; i < element.contractFiles.length; i++) {
                if (element.contractFiles[i].id === data.id) {
                  element.contractFiles[i].landlordAccess = access;
                }
              }
            }
          }
          this.props.setContract([]);
          this.props.setContract(arrayData);
        } else {
          this.props.appendAlert("File Access changed Failed", "danger");
        }
      })
      .catch(() => {
        this.props.appendAlert("File Access changed Failed", "danger");
      });
  }
  deleteFile(id) {
    deleteContractDocument(id)
      .then((response) => {
        if (response.status === 200) {
          window.$("#filesView").modal("hide");
          this.props.appendAlert("File deleted successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              for (let i = 0; i < element.contractFiles.length; i++) {
                if (element.contractFiles[i].id === id) {
                  element.contractFiles[i].deleted = true;
                }
              }
            }
          }

          this.props.setContract([]);
          this.props.setContract(arrayData);
        } else {
          this.props.appendAlert("File deleted Failed", "danger");
        }
      })
      .catch(() => {
        this.props.appendAlert("File deleted Failed", "danger");
      });
  }

  fileUpload(version, cat) {
    const formData = new FormData();
    formData.append("file", this.state.fileDoc);
    formData.append("version", version);
    formData.append("tenantAccess", true);
    formData.append("landlordAccess", true);
    this.setState({ fileButtonDisable: true });
    uploadContractFile(formData, this.state.viewId, cat)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            fileButtonDisable: false,
            fileType: "",
            fileDoc: [],
          });
          window.$("#filesView").modal("hide");
          this.props.appendAlert("File Uploaded successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              element.contractFiles.push(response.data);
            }
          }
          this.props.setContract([]);
          this.props.setContract(arrayData);
        } else {
          this.setState({ fileButtonDisable: false });
          window.$("#filesView").modal("hide");
        }
      })
      .catch(() => {
        this.setState({ fileButtonDisable: false });
        window.$("#filesView").modal("hide");
        this.props.appendAlert("File details couldn't be gathered", "warning");
      });
  }
  async onChangeState(name, value) {
    await this.setState({ [name]: value });
  }
  render() {
    return this.props.contractArray === null ? (
      <Loader />
    ) : this.props.contractArray.length === 0 ? (
      <div style={{ paddingTop: "112px", minHeight: window.screen.height }}>
        {" "}
        <NoData
          message="pending contracts to process"
          sub=""
        ></NoData>
      </div>
    ) : (
      <div
        style={{
          paddingTop: "115px",
          paddingBottom: "25px",
          minHeight: window.screen.height,
        }}
      >
        {this.props.contractArray.map((data, index) => {
          return (
            <ContractListTenant
              dark={this.props.isDark}
              data={data}
              eventKey={index}
              handleActiveKeyChange={this.handleActiveKeyChange}
              appendAlert={this.props.appendAlert}
              viewContract={this.viewContract}
              setModalData={this.setModalData}
              setModalImage={this.setModalImage}
              isButtonDisableFinishPay={this.state.isButtonDisableFinishPay}
              viewFiles={this.viewFiles}
              onChangeState={this.onChangeState}
              signObject={this.state.signObject}
              shortSignObject={this.state.shortSignObject}
              sign={this.state.sign}
              shortSign={this.state.shortSign}
              key={"ContractListTenant" + index}
            />
          );
        })}
        {this.state.viewContract && (
          <ModalContainer
            dark={this.props.isDark}
            min="500px"
            name="exampleModal"
            title={this.state.viewTitle}
            footer="false"
            closeContract={this.closeContract}
          >
            <ViewDocuments
              viewFilesArray={this.state.viewFilesArray}
              id={this.state.viewId}
              file={this.state.viewFile}
              dark={this.props.isDark}
              pushAlert={this.props.appendAlert}
            />
          </ModalContainer>
        )}
        {this.state.files && (
          <SmallModalContainer
            name="filesView"
            title="Files"
            size="lg"
            footer="false"
            max="530px"
          >
            <div style={{ paddingRight: "2%", paddingLeft: "2%" }}>
              <span className="badge badge02 ">My Files</span>
            </div>
            {this.state.viewFilesArray.filter(
              (todo) => todo.uploadBy === this.props.userDetail.username
            ).length === 0 && (
              <span className="span2 d-flex justify-content-center textSec1">
                No file Found
              </span>
            )}
            <div className="row mb-0 pb-0">
              {this.state.viewFilesArray
                .filter(
                  (todo) => todo.uploadBy === this.props.userDetail.username
                )
                .map((data, index) => {
                  return (
                    !data.deleted && (
                      <div
                        className=" mt-4 mr-4 ml-4 text-center"
                        style={{ width: "150px" }}
                        key={"ContractListTenantTwo" + index}
                      >
                        <div className="fileType">
                          {data.landlordAccess ? (
                            <span
                              className="badge badgeFileType absolute"
                              title="Landlord can view"
                            >
                              open
                            </span>
                          ) : (
                            <span
                              className="badge badgeFileType1 absolute"
                              title="Landlord can't view"
                            >
                              private
                            </span>
                          )}
                          {data.fileType === "" ||
                          data.fileType === null ||
                          data.fileType === undefined
                            ? "file"
                            : sortText2(data.fileType.split("/")[1], 0, 4)}{" "}
                        </div>

                        <span className="span2  pt-2 ">
                          {capitalizeFirstLetter(
                            sortText2(data.category, 0, 17)
                          )}{" "}
                          - {data.version}{" "}
                        </span>
                        <div className="row">
                          <div
                            className=" pt-1 "
                            style={{ width: "30px" }}
                          ></div>
                          <div className=" pt-1 " style={{ width: "20px" }}>
                            <div className="btn-group pointer text-right ">
                              <div
                                className="fileTypeMenuPro  "
                                aria-hidden="true"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <svg
                                  version="1.0"
                                  className="fileTypeMenuPro1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10px"
                                  viewBox="0 0 512.000000 512.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#000000"
                                    stroke="none"
                                  >
                                    <path
                                      d="M1604 4869 c-201 -23 -407 -137 -539 -296 -62 -76 -135 -210 -160
                                          -295 l-16 -58 -372 0 c-357 0 -372 -1 -415 -21 -106 -52 -132 -188 -54 -281
                                          48 -57 56 -58 466 -58 l374 0 22 -72 c43 -142 115 -254 233 -364 151 -140 312
                                          -209 517 -221 346 -20 656 165 806 480 20 42 41 98 48 124 l12 48 1240 5 c913
                                          4 1244 8 1259 17 52 30 95 104 95 165 0 50 -37 116 -83 146 l-41 27 -1234 5
                                          -1234 5 -23 72 c-82 259 -301 471 -565 546 -56 16 -207 38 -245 36 -11 -1 -52
                                          -5 -91 -10z m219 -374 c87 -23 155 -61 218 -124 185 -185 186 -477 2 -664
                                          -141 -144 -353 -181 -539 -93 -165 78 -265 239 -265 426 0 99 21 170 74 252
                                          109 170 318 253 510 203z"
                                    />
                                    <path
                                      d="M3237 3410 c-108 -23 -267 -98 -352 -167 -123 -100 -223 -247 -271
                                        -398 l-22 -70 -1234 -5 c-1221 -5 -1233 -5 -1268 -26 -117 -68 -117 -240 0
                                        -308 35 -21 47 -21 1268 -26 l1233 -5 27 -80 c90 -265 292 -458 567 -541 77
                                        -23 109 -27 220 -27 152 -1 225 15 353 74 227 105 390 291 457 523 l16 56 377
                                        0 c372 0 377 1 417 23 125 71 125 243 0 314 -40 22 -45 23 -416 23 l-377 0
                                        -26 83 c-68 218 -253 419 -469 510 -155 65 -336 82 -500 47z m318 -370 c203
                                        -70 326 -240 326 -450 0 -133 -44 -240 -138 -333 -91 -91 -202 -137 -331 -137
                                        -365 0 -591 395 -406 710 57 97 157 177 264 211 78 25 210 25 285 -1z"
                                    />
                                    <path
                                      d="M1565 1909 c-157 -26 -308 -103 -434 -223 -108 -104 -180 -218 -221
                                        -354 l-22 -72 -374 0 c-410 0 -418 -1 -466 -58 -78 -93 -52 -229 54 -281 43
                                        -20 58 -21 415 -21 l372 0 16 -57 c46 -158 156 -319 287 -419 414 -319 1003
                                        -197 1253 259 21 38 48 101 60 140 l23 72 1234 5 1234 5 41 27 c46 30 83 96
                                        83 146 0 61 -43 135 -95 165 -15 9 -346 13 -1259 17 l-1240 5 -12 48 c-17 68
                                        -77 190 -128 262 -181 252 -509 386 -821 334z m291 -380 c333 -106 433 -531
                                        185 -780 -249 -248 -666 -148 -782 189 -14 39 -20 84 -20 142 0 187 100 348
                                        265 426 115 55 229 62 352 23z"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="dropdown-menu pr-2 pl-2 dropdown-menu-left text-left">
                                <span
                                  className="dropdown-item text-info click span2"
                                  onClick={() => this.downloadFile(data)}
                                  title="Download File"
                                >
                                  Download
                                </span>
                                <span
                                  className="dropdown-item textPri click span2"
                                  onClick={() => this.deleteFile(data.id)}
                                  title="Delete File"
                                >
                                  Delete
                                </span>
                                <span
                                  className="dropdown-item textSec1 click span2"
                                  onClick={() =>
                                    this.updateFileAccess(
                                      data,
                                      !data.landlordAccess
                                    )
                                  }
                                  title={
                                    !data.landlordAccess
                                      ? "Change open access for landlord"
                                      : "Change file as private"
                                  }
                                >
                                  {!data.landlordAccess
                                    ? "Change Open Access"
                                    : "Change Private Access"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <span className="span2 textSec1">
                              {convertLocalDate(data.createdAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            {this.state.viewFilesArray.filter(
              (todo) => todo.uploadBy !== this.props.userDetail.username
            ).length !== 0 && (
              <>
                <div style={{ paddingRight: "2%", paddingLeft: "2%" }}>
                  <hr className="line "></hr>
                  <span className="badge badge02 ">Landlord's Files</span>
                </div>

                <div className="row ">
                  {this.state.viewFilesArray
                    .filter(
                      (todo) => todo.uploadBy !== this.props.userDetail.username
                    )
                    .map((data, index) => {
                      return (
                        !data.deleted &&
                        data.tenantAccess && (
                          <div
                            className=" mt-4 mr-4 ml-4 text-center"
                            style={{ width: "150px" }}
                            key={"ContractListTenantThree" + index}
                          >
                            <div className="fileType">
                              {data.fileType === "" ||
                              data.fileType === null ||
                              data.fileType === undefined
                                ? "file"
                                : sortText2(
                                    data.fileType.split("/")[1],
                                    0,
                                    4
                                  )}{" "}
                            </div>

                            <span className="span2  pt-2 ">
                              {capitalizeFirstLetter(
                                sortText2(data.category, 0, 17)
                              )}{" "}
                              - {data.version}{" "}
                            </span>
                            <div className="row">
                              <div
                                className=" pt-1 "
                                style={{ width: "30px" }}
                              ></div>
                              <div className=" pt-1 " style={{ width: "20px" }}>
                                <span
                                  className=" text-info click span2 fileTypeMenuPro pt-2 "
                                  onClick={() => this.downloadFile(data)}
                                  title="Download File"
                                >
                                  <i
                                    className={
                                      this.props.dark
                                        ? "fa fa-download  fa-10x  text-light fileTypeMenuPro1 "
                                        : "fa fa-download  fa-10x  text-dark  fileTypeMenuPro1"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <div className="col">
                                <span className="span2 textSec1">
                                  {convertLocalDate(data.createdAt)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
              </>
            )}
            {!this.state.uploadWindow ? (
              <button
                className="priButton mb-5  pull-right"
                onClick={() => this.setState({ uploadWindow: true })}
              >
                Upload Files
              </button>
            ) : (
              <>
                <div style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                  <hr className="line "></hr>
                </div>
                <br></br>
                <div
                  style={{
                    paddingRight: "2%",
                    paddingLeft: "2%",
                    border: "1px solid #000",
                    borderRadius: "10px ",
                  }}
                >
                  <span
                    className="closeModal pull-right pointer textPri"
                    onClick={() => this.setState({ uploadWindow: false })}
                  >
                    &times;
                  </span>
                  <p
                    className={
                      this.props.isDark
                        ? "contractTextDark mb-2 mt-3"
                        : "contractText mb-2 mt-3"
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    Upload{" "}
                  </p>
                  <SearchInput
                    name="fileType"
                    id="File Type"
                    type="2"
                    target="fileType"
                    onChange={this.onchange}
                    list={[
                      "Draft Agreement",
                      "Credit Check",
                      "Background Check",
                    ]}
                    changeState={this.changeState}
                    important="true"
                    errorMessage=" the file type"
                    validate={this.checkValidate(this.state.fileType)}
                    value={this.state.fileType}
                  />

                  <br></br>
                  <div className="row">
                    {this.state.fileDoc.length !== 0 && (
                      <div className="col-4">
                        <div className=" m-4" style={{ width: "120px" }}>
                          <div className="fileType">
                            {sortText2(
                              this.state.fileDoc.type.split("/")[1],
                              0,
                              4
                            )}{" "}
                          </div>

                          <span className="span2  d-flex justify-content-center mt-3">
                            {capitalizeFirstLetter(
                              sortText(this.state.fileDoc.name, 0, 20)
                            )}{" "}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="col">
                      <Dropzone
                        // accept=".pdf,.jpeg,.png,.jpg,.doc,.docx"
                        maxSize={10485760}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles[0] === undefined) {
                            this.props.appendAlert(
                              "file size is too Long (max size = 10mb) ",
                              "danger"
                            );
                          } else {
                            this.setState({ fileDoc: acceptedFiles[0] });
                          }
                        }}
                        inputWithFilesContent="Upload more"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <>
                            <div
                              className={
                                this.state.next &&
                                this.state.fileDoc.length === 0
                                  ? "alert"
                                  : "mt-4"
                              }
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />

                              <div
                                className="dragDrop text-center pointer"
                                style={{
                                  fontSize: "60px",
                                  minHeight: "130px",
                                  paddingTop: "40px",
                                }}
                              >
                                <div className="row">
                                  <div className="col">
                                    <i
                                      className={
                                        this.state.fileDoc.length === 0
                                          ? this.props.dark
                                            ? this.state.next
                                              ? "fa fa-cloud-upload  fa-10x dragIcon textPri vert-move "
                                              : "fa fa-cloud-upload  fa-10x dragIcon text-light vert-move "
                                            : this.state.next
                                            ? "fa fa-cloud-upload  fa-10x dragIcon textPri vert-move "
                                            : "fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                          : this.props.dark
                                          ? "fa fa-check-circle  fa-10x dragIcon text-light vert-move "
                                          : "fa fa-check-circle  fa-10x dragIcon text-dark vert-move "
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <p
                                      className={
                                        this.props.dark
                                          ? "licenseHeadDark"
                                          : "licenseHead"
                                      }
                                    >
                                      {this.state.fileDoc.length === 0
                                        ? "Drag Or Click to upload file"
                                        : "Drag Or Click to change file"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Dropzone>
                      <span className="span2 textPri text-center pull-right ">
                        Max file size is 10MB.
                      </span>
                    </div>
                  </div>

                  <br></br>
                  <button
                    className="priButton mb-5  pull-right"
                    onClick={() => this.saveUpload()}
                  >
                    Save
                  </button>
                  <br></br>
                  {this.state.fileButtonDisable && (
                    <>
                      <div className="pull-right">
                        <Loader3 transY="60px" transX="60px" />
                      </div>
                      <br></br>
                    </>
                  )}
                  <br></br>
                </div>
              </>
            )}
          </SmallModalContainer>
        )}
        <SmallModalContainer
          dark={this.props.isDark}
          name="exampleModal1"
          title="Checkout"
          size="lg"
          confirm={this.payRent}
          confirmCommand="Pay"
          stopConfirmDismiss={true}
          confirmLoader={this.state.confirmLoader}
          confirmDisable={!this.state.agreePayment}
          confirmToggle="Add month and pay"
          footerMessage={this.footerMessage}
          beforeButton={"Total : $ " + this.state.total}
          max={window.screen.height - 400}
        >
          <div className="p-3 pt-0 pb-0">
            <div className="row">
              <div className="col-md ">
                <p
                  className={
                    this.props.isDark ? "contractTextDark" : "contractText "
                  }
                  style={{ fontWeight: "bold" }}
                >
                  PAYMENT FOR
                </p>
                <div
                  className="mt-3 "
                  style={{
                    backgroundImage:
                      "url(" +
                      (this.state.modalImage === ""
                        ? coverImage
                        : this.state.modalImage) +
                      ")",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: "240px",
                  }}
                ></div>
              </div>
              <div className="col-md ">
                <p
                  className={
                    this.props.isDark ? "contractTextDark" : "contractText "
                  }
                  style={{ fontWeight: "bold" }}
                >
                  LANDLORD
                </p>
                <div className="mt-3  paymentModalColum ">
                  <img
                    src={this.state.modalLandlordImage}
                    alt="profile"
                    style={{
                      height: "25px",
                      width: "25px",
                      borderRadius: "15px",
                      marginLeft: "0px",
                    }}
                  ></img>
                  <span className={"span7 pl-3"}>
                    {this.state.modalLandlordData.firstName
                      ? this.state.modalLandlordData.firstName +
                        " " +
                        this.state.modalLandlordData.lastName
                      : this.state.ModalData.landLordId}
                  </span>
                  <div className="mt-2">
                    {this.state.modalLandlordData.mobileNumber && (
                      <>
                        <span className={"span7 pl-3"}>
                          <i
                            className="fa fa-phone pr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          {this.state.modalLandlordData.mobileNumber &&
                            (this.state.modalLandlordData.mobileNumber ===
                              null ||
                            this.state.modalLandlordData.mobileNumber === ""
                              ? "-"
                              : this.state.modalLandlordData.mobileNumber)}
                        </span>
                        <br></br>
                      </>
                    )}
                    {this.state.modalLandlordData.address && (
                      <>
                        {!(
                          (this.state.modalLandlordData.address.address ===
                            null ||
                            this.state.modalLandlordData.address.address ===
                              "") &&
                          (this.state.modalLandlordData.address.state ===
                            null ||
                            this.state.modalLandlordData.address.state ===
                              "") &&
                          (this.state.modalLandlordData.address.city === null ||
                            this.state.modalLandlordData.address.city === "") &&
                          (this.state.modalLandlordData.address.country ===
                            null ||
                            this.state.modalLandlordData.address.country === "")
                        ) && (
                          <span className={"span7 pl-3"}>
                            <i
                              className="fa fa-address-card-o pr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            {addressFormatter(
                              this.state.modalLandlordData.address
                            )}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md  p-0">
                  <div className="row  m-0 ">
                    <div className="col-6 m-0 paymentModalColum text-left pt-0">
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        START
                      </p>
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                      >
                        {setDateFormat(this.state.ModalData.startDate)}
                      </p>
                    </div>
                    <div className="col-6 m-0 paymentModalColum text-left pt-0">
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        END
                      </p>
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                      >
                        {setDateFormat(this.state.ModalData.endDate)}
                      </p>
                    </div>

                    <div className="col paymentModalColum">
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        MONTHLY PAYMENT
                      </p>
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark pt-2"
                            : "contractText pt-2"
                        }
                      >
                        <CurrencyFormat value={this.state.ModalData.rent} />
                        {/* {(
                          this.state.ModalData.otherRentFee1 +
                          this.state.ModalData.otherRentFee2 +
                          this.state.ModalData.otherRentFee3 +
                          this.state.ModalData.parkingFee +
                          this.state.ModalData.petAmount +
                          (this.state.ModalData.rentDiscount !== 0
                            ? this.state.ModalData.rentDiscountType ===
                              "percentage"
                              ? (this.state.ModalData.rent *
                                  (100 - this.state.ModalData.rentDiscount)) /
                                100
                              : this.state.ModalData.rent -
                                this.state.ModalData.rentDiscount
                            : this.state.ModalData.rent)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })} */}
                        <span style={{ fontSize: "10px" }}>/Month</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  pt-4">
              <div className="col-md ">
                <p
                  className={
                    this.props.isDark
                      ? "contractTextDark pb-2 "
                      : "contractText pb-2 "
                  }
                  style={{ fontWeight: "bold" }}
                >
                  PAYABLE{" "}
                  {this.state.isButtonDisableFinish && (
                    <Loader3 transY="20px" transX="-40px" />
                  )}
                </p>
                {this.state.paymentData
                  .filter(
                    (data) =>
                      data.paymentType !== "rent" &&
                      data.paymentType !== "Rent" &&
                      data.status === "upcomming"
                  )
                  .map((data, index) => {
                    return (
                      <div
                        className="row m-0 paymentModalColum "
                        key={"ContractListTenantPayment" + index}
                      >
                        <div className="col text-left pr-0 pl-0">
                          <p
                            className={
                              this.props.isDark
                                ? "contractTextDark"
                                : "contractText "
                            }
                          >
                            <div className="outSpan ">
                              <label className="checkedContainer">
                                {capitalizeFirstLetter(data.paymentType)}
                                <input
                                  type="checkbox"
                                  onChange={this.onchangeCheckModal}
                                  name={data.id}
                                  checked={data.tick ? "checked" : ""}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </p>
                        </div>
                        <div className="col-5 text-right pr-0 pl-0">
                          <p
                            className={
                              this.props.isDark
                                ? "contractTextDark"
                                : "contractText "
                            }
                            style={{ fontWeight: "bold" }}
                          >
                            <CurrencyFormat value={data.dueAmount} />
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="col-md monthlyRentScroll">
                <p
                  className={
                    this.props.isDark
                      ? "contractTextDark pb-2 "
                      : "contractText pb-2 "
                  }
                  style={{ fontWeight: "bold" }}
                >
                  MonthlyRent{" "}
                  {this.state.isButtonDisableFinish && (
                    <Loader3 transY="20px" transX="-40px" />
                  )}
                </p>
                {this.state.paymentData
                  .filter(
                    (data) =>
                      (data.paymentType === "rent" ||
                        data.paymentType === "Rent") &&
                      data.status === "upcomming"
                  )
                  .map((data, index) => {
                    return (
                      <div
                        className="row m-0 paymentModalColum "
                        key={"ContractListTenantPaymentTwo" + index}
                      >
                        <div className="col text-left pr-0 pl-0">
                          <p
                            className={
                              this.props.isDark
                                ? "contractTextDark"
                                : "contractText "
                            }
                          >
                            <div className="outSpan ">
                              <label className="checkedContainer">
                                {data.yearAndMonth.slice(0, 5) +
                                  " " +
                                  getFullMonth(
                                    parseInt(data.yearAndMonth.slice(5, 7))
                                  )}
                                <input
                                  type="checkbox"
                                  onChange={this.onchangeCheckModal}
                                  name={data.id}
                                  checked={data.tick ? "checked" : ""}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </p>
                        </div>
                        <div className="col-5 text-right pr-0 pl-0">
                          <p
                            className={
                              this.props.isDark
                                ? "contractTextDark"
                                : "contractText "
                            }
                            style={{ fontWeight: "bold" }}
                          >
                            <CurrencyFormat value={data.dueAmount} />
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="row m-0 mt-4 mb-5 paymentModalColum">
              <div className="col-12 text-left pr-0 pl-0">
                <input
                  className="pointer  mt-2 "
                  type="checkbox"
                  id="additionalPayment"
                  name="additionalPayment"
                  checked={this.state.additionalPayment ? "checked" : ""}
                  onChange={this.onchangeCheck}
                />
                <label className="agreePayment pl-2" htmlFor="exampleCheck1">
                  I have additional payments
                </label>
              </div>
              {this.state.additionalPayment && (
                <>
                  <div className="col text-left pr-0 pl-0">
                    <ApplicationInput
                      name="Reason For Payment"
                      type="text"
                      target="additionalPaymentType"
                      onChange={this.onchange}
                      value={this.state.additionalPaymentType}
                      important="true"
                      errorMessage="the reason"
                      validate={this.checkValidate1(
                        this.state.additionalPaymentType
                      )}
                    />
                  </div>
                  <div className="col-5 text-right pr-0 pl-0">
                    <ApplicationInput
                      name="Amount"
                      type="text"
                      align="right"
                      target="additionalPaymentAmount"
                      onChange={this.onchange}
                      value={this.state.additionalPaymentAmount}
                      important="true"
                      errorMessage="the amount"
                      validate={this.checkValidate1(
                        this.state.additionalPaymentAmount
                      )}
                    />
                  </div>
                </>
              )}
              {this.state.paymentData.map(
                (data, index) =>
                  data.tick && (
                    <>
                      <div
                        className="col-12 text-left pr-3 pl-3 pb-2 pt-2 borderBottomBlack"
                        key={"ContractListTenantPaymentThree" + index}
                      >
                        <div className="row">
                          <div className="col">
                            <p
                              className={
                                this.props.isDark
                                  ? "contractTextDark"
                                  : "contractText "
                              }
                              style={{ fontWeight: "bold" }}
                            >
                              {data.paymentType === "Rent"
                                ? "Rent " +
                                  data.yearAndMonth.slice(0, 5) +
                                  " " +
                                  getFullMonth(
                                    parseInt(data.yearAndMonth.slice(5, 7))
                                  )
                                : capitalizeFirstLetter(data.paymentType)}
                            </p>
                          </div>
                          <div className="col-3 text-right">
                            <p
                              className={
                                this.props.isDark
                                  ? "contractTextDark"
                                  : "contractText "
                              }
                              style={{ fontWeight: "bold" }}
                            >
                              {data.dueAmount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )
              )}
              <div className="col-12 text-left pr-3 pl-3 pb-1 pt-1 borderBottomBlack ">
                <div className="row borderBottomBlack borderTopBlack pb-2 pt-2">
                  <div className="col">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      TOTAL
                    </p>
                  </div>
                  <div className="col-3 text-right">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      {this.state.total}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SmallModalContainer>
        <ModalContainer
          dark={this.props.isDark}
          name="exampleModal2"
          min="100px"
          max="600px"
          title="Choose Your Payment Plan"
          footer="false"
          width="auto"
          closeContract={this.closeContract1}
        >
          <PaymentMethod
            onAccept={this.acceptContract}
            dark={this.props.isDark}
          />
        </ModalContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    contract: state.contractReducer,
    contractArray: state.contract,
    customerPayment: state.customerPaymentReducer,
    customerPaymentStatus: state.customerPayment,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setContract,
    setCustomerPaymentStatus,
    appendTempUsers,
  })(ContractTenant)
);
