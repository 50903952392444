import React, { Component } from "react";
import {
  getLandlordPaymentTransactionsPayouts,
  getLandlordPaymentTransactionsPayoutsNext,
  // getLandlordPaymentTransactionsPayoutsPrevious,
  getLandlordPayments,
  getPaymentDashboardDatas,
} from "../../../../Services/ApiServices";
import "../payment.css";
import "./paymentlandlord.css";
import "../../Maintenance/maintenance.css";
import Loader2 from "../../../Loader/Loader2";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
  appendAlert,
  setRecentPayment,
  setPaymentDashboardData,
} from "../../../../actions";
import LandlordRecentPaymentList from "./LandlordRecentPaymentList";
import { addressFormatter } from "../../../../Defined/Function";
import PaymentLandlordCard from "./PaymentLandlordCard";
import LandlordRecentPaymentListStripe from "./LandlordRecentPaymentListStripe";

class PaymentLandlordV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId: 0,
      // contractId: this.props.contractArray!==null?this.props.contractArray[0].contractId:0,
      paymentsList: [],
      tab: "allActivity",
      checkPaymentAccountDetail: [],
      transactionsData: [],
      hasMore: false,
      hasPre: false,
    };
    this.getUnit = this.getUnit.bind(this);
    this.onchange = this.onchange.bind(this);
  }
  nextPayout() {
    getLandlordPaymentTransactionsPayoutsNext(
      this.props.userDetail.username,
      this.state.transactionsData[this.state.transactionsData.length - 1].id
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            transactionsData: this.state.transactionsData.concat(
              response.data.data
            ),
            hasMore: response.data.hasMore,
            // hasPre: true,
          });
        }
        this.setState({ mainLoader: false });
      })
      .catch((err) => {
        this.setState({ mainLoader: false });
      });
  }
  // prePayout() {
  //   getLandlordPaymentTransactionsPayoutsPrevious(
  //     this.props.userDetail.username,
  //     this.state.transactionsData[0].id
  //   )
  //     .then((response) => {
  //       if (response.status === 200) {
  //         this.setState({
  //           transactionsData: response.data.data,
  //           hasMore: response.data.hasMore,
  //           hasPre: true,
  //         });
  //       }
  //       this.setState({ mainLoader: false });
  //     })
  //     .catch((err) => {
  //       this.setState({ mainLoader: false });
  //     });
  // }
  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      getLandlordPaymentTransactionsPayouts(this.props.userDetail.username)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              transactionsData: response.data.data,
              hasMore: response.data.hasMore,
            });
          }
          this.setState({ mainLoader: false });
        })
        .catch((err) => {
          this.setState({ mainLoader: false });
        });
      await getLandlordPayments(this.props.userDetail.username).then(
        async (response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              await this.props.setRecentPayment(null);
              this.props.setRecentPayment(
                response.data.sort((a, b) =>
                  b.paidDate.localeCompare(a.paidDate)
                )
              );
            } else {
              this.props.setRecentPayment([]);
            }
          } else {
            // this.props.appendAlert("Something went wrong!, Please try again later.", "danger");
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
    try {
      await getPaymentDashboardDatas().then((response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.props.setPaymentDashboardData(response.data);
          } else {
            this.props.setPaymentDashboardData([]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  getUnit(contract, index) {
    var unit = this.props.tempListingArray.filter(
      (unit) => unit.rentalEntity.id === contract.rentalEntityId
    );
    if (index === 0) {
      // this.setState({contractId:contract.id});
    }
    if (unit !== null) {
      if (unit.length > 0) {
        return (
          <option value={contract.contractId}>
            {addressFormatter(unit[0].address)}
          </option>
        );
      }
    }
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          paddingTop: "105px",
          minHeight: window.screen.height,
        }}
      >
        <PaymentLandlordCard />

        {this.props.contractArray === null ||
        this.props.recentPaymentArray === null ||
        this.props.tempListingArray === null ? (
          <div className="col-12 mb-5">
            <Loader2 />
          </div>
        ) : (
          this.props.contractArray.length !== 0 && (
            <>
              <div className="row mr-1 ml-1 ">
                <div className="col-md-2 mr-2 squareDivPri maintenanceRequestHeaderAlign1 text-center">
                  Properties
                </div>
                <div className="col-12 maintenanceRequestHeaderAlign2 mb-2 p-0 pb-2">
                  <h1 className="axText text-dark">Properties</h1>
                </div>

                <div className="col-md pr-0  p-0">
                  <select
                    className={"maintenanceInputSelect custom-select"}
                    onChange={this.onchange}
                    name={"contractId"}
                    id={"contractId"}
                    disabled={this.state.tab === "payout" && "disabled"}
                  >
                    <option value={0} selected="selected">
                      All
                    </option>
                    {this.props.contractArray.map((contract, index) =>
                      this.getUnit(contract, index)
                    )}
                  </select>
                </div>
              </div>

              <div className="row ml-1 mr-1 mb-3 ">
                <div className="pt-5">
                  <h1
                    className={
                      this.props.isDark
                        ? "axText text-dark"
                        : "axText text-dark"
                    }
                  >
                    Transactions
                  </h1>
                </div>
                <div className="col mt-5 ">
                  <div class="dropdown pull-right ">
                    <div
                      class="btn dropDownStyle2 dropdown-toggle  "
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.tab === "allActivity"
                        ? "All activity"
                        : "Payout"}
                    </div>

                    <div
                      class="dropdown-menu absolute profileMenu navProfile"
                      aria-labelledby="dropdownMenuButton"
                      x-placement="bottom-start"
                    >
                      <span
                        class="dropdown-item profileList"
                        onClick={() => this.setState({ tab: "allActivity" })}
                      >
                        All Activity
                      </span>
                      <span
                        class="dropdown-item profileList "
                        onClick={() => this.setState({ tab: "payout" })}
                      >
                        Payout
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.tab === "allActivity" && (
                <div className={"row mt-4 mr-3 ml-3 mb-5  recentPayment"}>
                  <div className="col-12 mr-0 ml-0 ">
                    <div className="recentPaymentTableWeb">
                      <div
                        className={"row"}
                        style={{
                          marginTop: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          borderTopLeftRadius: "25px",
                          borderTopRightRadius: "25px",
                        }}
                      >
                        <div className="col-1 "></div>
                        <div className="col m-0 p-0 text-left ">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Name
                          </p>
                        </div>
                        <div className="col-2 m-0 p-0  text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Type
                          </p>
                        </div>

                        <div className="col m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Paid
                          </p>
                        </div>
                        <div className="col m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Due
                          </p>
                        </div>
                        <div className="col-1 m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText "}
                            style={{ fontWeight: "bold" }}
                          >
                            Status
                          </p>
                        </div>
                      </div>
                    </div>
                    {this.props.recentPaymentArray.length !== 0 ? (
                      this.props.recentPaymentArray
                        .filter(
                          (data) =>
                            parseInt(this.state.contractId) === 0 ||
                            parseInt(this.state.contractId) ===
                              parseInt(data.contractId)
                        )
                        .map((data, index) => {
                          return (
                            <LandlordRecentPaymentList
                              data={data}
                              eventKey={index}
                            />
                          );
                        })
                    ) : (
                      <div className="text-center mb-4 mt-4 ml-5">
                        <span className="spanBig ">
                          No transactions in recent payment history.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.state.tab === "payout" && (
                <div className={"row mt-4 mr-3 ml-3 mb-5  recentPayment"}>
                  <div className="col-12 mr-0 ml-0 ">
                    <div className="recentPaymentTableWeb">
                      <div
                        className={"row"}
                        style={{
                          marginTop: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="col-1 "></div>

                        <div className="col m-0 p-0  ">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Type
                          </p>
                        </div>

                        <div className="col-1 m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Date
                          </p>
                        </div>
                        <div className="col-2 m-0 p-0 pl-5 text-right">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Amount
                          </p>
                        </div>
                      </div>
                    </div>
                    {this.state.transactionsData.length !== 0 ? (
                      <>
                        {this.state.transactionsData
                          // .filter(
                          // (data) =>
                          //   data.type === "payout"  )
                          .map((data, index) => {
                            return (
                              <LandlordRecentPaymentListStripe
                                data={data}
                                eventKey={index}
                              />
                            );
                          })}
                        <div className="row">
                          <div className="col-lg mt-3 applicationListAlign2 d-flex justify-content-center">
                            {this.state.hasMore && (
                              <button
                                class={
                                  this.state.hasMore ? "pri" : "secDisable"
                                }
                                disabled={!this.state.hasMore && "disabled"}
                                onClick={() => this.nextPayout()}
                              >
                                Show More
                              </button>
                            )}
                            {/* <button
                              class={
                                this.state.hasPre
                                  ? "roundButton mr-2"
                                  : "roundButtonDisable mr-2"
                              }
                              data-placement="top"
                              disabled={!this.state.hasPre && "disabled"}
                              title="Preview"
                              onClick={() => this.prePayout()}
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>
                            </button>

                            <button
                              class={
                                this.state.hasMore
                                  ? "roundButton mr-2"
                                  : "roundButtonDisable mr-2"
                              }
                              data-placement="top"
                              title="Next"
                              disabled={!this.state.hasMore && "disabled"}
                              onClick={() => this.nextPayout()}
                            >
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </button> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-center mb-4 mt-4 ml-5">
                        <span className="spanBig ">
                          No transactions in recent payment history.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
    alert: state.alertReducer,
    recentPayment: state.recentPaymentReducer,
    recentPaymentArray: state.recentPayment,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    contract: state.contractReducer,
    contractArray: state.contract,
    paymentDashboardData: state.paymentDashboardDataReducer,
    paymentDashboardDataArray: state.paymentDashboardData,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setRecentPayment,
    setPaymentDashboardData,
  })(PaymentLandlordV2)
);
