import React, { Component } from "react";
import "./stepNav.css";
import Loader3 from "../../Loader/loader3";

class StepNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nowTab: 0,
    };
  }

  render() {
    return (
      <>
        <div className="row stepFormNav">
          {this.props.tabData.map((tab, index) => (
            <div className="col text-center">
              <div className="row">
                {index === 0 && <div className="col SideLineHide"></div>}
                {index !== 0 && (
                  <div
                    className={
                      this.props.activeTab > index
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                )}
                <div className="col">
                  <div
                    className={
                      this.props.activeTab - 1 >= index
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className={"fa " + tab.icon} aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step {index + 1}</span>
                  <br></br>
                </div>
                {index + 1 !== this.props.tabData.length && (
                  <div
                    className={
                      this.props.activeTab - 1 > index
                        ? "col  SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                )}
                {index === this.props.tabData.length - 1 && (
                  <div className="col SideLineHide"></div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="row stepFormNavTitle ">
          {this.props.tabData.map((tab, index) => (
            <div
              className={
                index !== this.props.activeTab - 1
                  ? "col d-flex justify-content-center stepperAdjust"
                  : "col d-flex justify-content-center stepperAdjust2"
              }
            >
              <p className={"stepTitle stepTitleBc"}>{tab.head}</p>
            </div>
          ))}
        </div>

        {this.props.children}

        {this.props.tabData[this.props.activeTab - 1].child}
        <div
          className="row appFootButton text-right inputContainer  "
          style={{ paddingBottom: "40px", paddingTop: "40px" }}
        >
          <div className="col ">
            {this.props.activeTab === 1 && (
              <button
                className="pri mr-3"
                onClick={() => this.props.history.goBack()}
              >
                Cancel
              </button>
            )}
            {this.props.activeTab !== 1 && (
              <button className="mr-3 pri" onClick={() => this.props.back()}>
                Back
              </button>
            )}
            {this.props.nextEnable ? (<>
              <button
                className={this.props.nextLoading?"secDisable":"pri "}
                onClick={this.props.nextLoading?()=>{}:() => this.props.next()}
                data-target={this.props.nextTarget}
                data-toggle="modal"
              >
                {this.props.nextText}
                {this.props.nextLoading &&<Loader3 transY="60px" transX="0px" />}
              </button>
              </>
            ) : (
              <button className="secDisable " onClick={() => this.props.next()}>
                Next
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default StepNavigator;
