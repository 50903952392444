import ApplicationInput from "../../../../../UIServices/ApplicationInput";
import React, { useEffect } from "react";


export default function Stepper02Elements(props) {
  var {
    values,
    onchangeCheck,
    onchange,
    checkValidate,
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="cardContract step2CardBCContract">
        <div className="row inputContainer pt-3 pb-0">
          <div class="rentalInfoSentenceBCContract0">
            <span className="span2 contractTextStyle ">
              The tenant will pay the rent of
            </span>
          </div>
          <div class="col-2 rentalInfoInputBCContract  rentContractInputBC">
            <ApplicationInput
              name=""
              type="text"
              target="rent"
              onChange={onchange}
              important="true"
              errorMessage="rent"
              iconL={"fa-usd"}
              validate={checkValidate("rent")}
              value={values.rent}
            />
          </div>
        </div>
        <div className="row inputContainer pt-2 pb-2">
          <div>
            <span className="span2 contractTextStyle ">each</span>
          </div>
          <div className="rentalInfoSentenceBCContract rentTypeBCContract">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Day</span>}
              type="checkedBox"
              target="rentDay"
              onChange={onchangeCheck}
              value={values.rentDay}
            />
          </div>
          <div className="rentalInfoSentenceBCContract rentTypeBCContract">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Week</span>}
              type="checkedBox"
              target="rentWeek"
              onChange={onchangeCheck}
              value={values.rentWeek}
            />
          </div>
          <div className="rentalInfoSentenceBCContract rentTypeBCContract">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">month</span>}
              type="checkedBox"
              target="rentMonth"
              onChange={onchangeCheck}
              value={values.rentMonth}
            />
          </div>
          <div class="  ">
            <span className="span2 contractTextStyle ">
              to the landlord on the first day of the rental period which falls
              on the (due date, e.g., 1st, 2nd, 3rd, .... 31st)
            </span>
          </div>
        </div>
        <div className="row inputContainer pt-2 pb-0">
          <div class="col  rentDayInputBC">
            <ApplicationInput
              name=""
              type="number"
              target="paymentDay"
              onChange={onchange}
              min="1"
              max="31"
              important="true"
              errorMessage="paymentDay"
              validate={checkValidate("paymentDay")}
              value={values.paymentDay}
            />
          </div>
          <div>
            <span className="span2 contractTextStyle ">day of each</span>
          </div>
          <div className="rentalInfoSentenceBCContract rentTypeBCContract">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Day</span>}
              type="checkedBox"
              target="rentPayDay"
              onChange={onchangeCheck}
              value={values.rentPayDay}
            />
          </div>
          <div className="rentalInfoSentenceBCContract rentTypeBCContract">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Week</span>}
              type="checkedBox"
              target="rentPayWeek"
              onChange={onchangeCheck}
              value={values.rentPayWeek}
            />
          </div>
          <div className="rentalInfoSentenceBCContract rentTypeBCContract">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">month</span>}
              type="checkedBox"
              target="rentPayMonth"
              onChange={onchangeCheck}
              value={values.rentPayMonth}
            />
          </div>
          <div class="rentalInfoSentenceBCContract2">
            <span className="span2 contractTextStyle ">
              subject to rent increases given in accordance with the RTA.
            </span>
          </div>
          <div className="col-12 mt-3">
          <div className="cardContract mb-3 mt-5 mr-0 ml-0">
            <div className="step2CardContainerBCContract ">
              <span className="span2 contractTextStyle ">
                The tenant must pay the rent on time. If the rent is late, the
                landlord may issue a Notice to End Tenancy for Unpaid Rent (form
                RTB-30) to the tenant, which may take effect not earlier than 10
                days after the date the notice is given.
              </span>
            </div>
          
          </div>
        </div>
        </div>
        
      </div>
    </div>
  );
}
