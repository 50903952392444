import ApplicationInput from "../../../../../UIServices/ApplicationInput";
import React, { useEffect } from "react";
import { today } from "../../../../../../Defined/Function";

export default function Stepper03Elements(props) {
  var { values, onchangeCheck, onchange, checkValidate, onChangeDate } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <div className="cardContract">
        <div className="row inputContainer pt-2 pb-0 ">
          <div className="col-lg-3 col-12 ">
            <ApplicationInput
              name="Security Deposit Amount"
              type="text"
              target="securityDeposit"
              onChange={onchange}
              important="true"
              errorMessage="security deposit amount"
              iconL={"fa-usd"}
              validate={checkValidate("securityDeposit")}
              value={values.securityDeposit}
            />
          </div>

          <div className="col alignInput">
            <ApplicationInput
              name="Pay by"
              type="date"
              min={today()}
              target="securityDepositPayBy"
              format="yearFirst"
              important="true"
              onChange={onChangeDate}
              errorMessage="security deposit pay date"
              validate={checkValidate("securityDepositPayBy")}
              value={values.securityDepositPayBy}
            />
          </div>
        </div>
        <div className="row inputContainer pt-2 pb-3 ">
          <div className="col-lg-3 col-12">
            <ApplicationInput
              name="Pet Damage Deposit"
              type="optionYN"
              target="isPetDeposit"
              onChange={onchangeCheck}
              value={values.isPetDeposit}
            />
          </div>
          {values.isPetDeposit && (
            <div className="col alignInput">
              <ApplicationInput
                name="Pet Damage Deposit Amount"
                type="text"
                target="petDeposit"
                onChange={onchange}
                important="true"
                errorMessage="pet damage deposit amount"
                iconL={"fa-usd"}
                validate={checkValidate("petDeposit")}
                value={values.petDeposit}
              />
            </div>
          )}
          {values.isPetDeposit && (
            <div className="col alignInput">
              <ApplicationInput
                name="Pay by"
                type="date"
                min={today()}
                target="petDepositPayBy"
                format="yearFirst"
                important="true"
                onChange={onChangeDate}
                errorMessage="pet damage deposit pay date"
                validate={checkValidate("petDepositPayBy")}
                value={values.petDepositPayBy}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
