import React, { Component } from "react";

import {
  checkPaymentAccountDetail,
  getLandlordPaymentBalanceData,
} from "../../../../Services/ApiServices";

import { connect } from "react-redux";
import { setAccountPaymentStatus, appendAlert } from "../../../../actions";
import { withRouter } from "react-router-dom";
import { dateFormat } from "../../../../Defined/Function";

class PaymentDashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceData: [],
      readyForPayout: 0.0,
      pending: 0.0,
      data: {
        totalEarning: 14699,
        readyForPayout: 2100,
        pending: 2254,
        due: 1355,
        payoutDate: "05/22/2023",
      },
      paymentMonth: new Date().getMonth(),
      paymentYear: new Date().getFullYear(),
      years: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      balance: 0,
      isLoadingStipe: false,
      mainLoader: true,
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  async componentDidMount() {
    var x = [];
    for (var i = parseInt(new Date().getFullYear()); i > 1900; i--) {
      x.push(i);
    }
    this.setState({ years: x });
    if (this.props.accountPaymentStatus !== null) {
      this.setState({ mainLoader: false });
    }
    await checkPaymentAccountDetail()
      .then((response) => {
        if (response.status === 200) {
          this.props.setAccountPaymentStatus(
            response.data.capabilities.card_payments
          );
          getLandlordPaymentBalanceData(this.props.UserData.username)
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  balanceData: response.data,
                  readyForPayout: this.sumAmount(response.data.available),
                  pending: this.sumAmount(response.data.pending),
                });
              }
              this.setState({ mainLoader: false });
            })
            .catch((err) => {
              this.setState({ mainLoader: false });
            });
        }
        this.setState({ mainLoader: false });
      })
      .catch((err) => {
        this.setState({ mainLoader: false });
      });
  }
  sumAmount(jsonArray) {
    let sum = 0;
    for (let i = 0; i < jsonArray.length; i++) {
      sum += jsonArray[i].amount;
    }
    return sum;
  }
  openLink(path) {
    window.location.href = path;
  }

  render() {
    return (
      <>
        <div className="row paymentLandlordCard ml-1 mr-1 mb-5 mt-2 p-3 ">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <span className="paymentLandlordCardHead">
              Total earning{" "}
              <i class="fa fa-info-circle textSec1 pl-2" aria-hidden="true"></i>
            </span>
            <h1 className={"paymentLandlordCardText pt-1"}>
              <i class="fa fa-usd" aria-hidden="true"></i>
              {this.state.data.totalEarning.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              USD
            </h1>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <span className="paymentLandlordCardHead">
              Ready for payout
              <i class="fa fa-info-circle textSec1 pl-2" aria-hidden="true"></i>
            </span>
            <h1 className={"paymentLandlordCardText pt-1"}>
              <i class="fa fa-usd" aria-hidden="true"></i>
              {this.state.readyForPayout.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              USD
            </h1>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <span className="paymentLandlordCardHead">
              Pending
              <i class="fa fa-info-circle textSec1 pl-2" aria-hidden="true"></i>
            </span>
            <h1 className={"paymentLandlordCardText pt-1"}>
              <i class="fa fa-usd" aria-hidden="true"></i>
              {this.state.pending.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              USD
            </h1>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <span className="paymentLandlordCardHead">
              Due/Overdue
              <i class="fa fa-info-circle textSec1 pl-2" aria-hidden="true"></i>
            </span>
            <h1 className={"paymentLandlordCardText pt-1"}>
              <i class="fa fa-usd" aria-hidden="true"></i>
              {this.state.data.due.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              USD
            </h1>
          </div>
          <div className="col-12 mt-3">
            <span className="paymentLandlordCardHead">
              Next payout expected on {dateFormat(this.state.data.payoutDate)}.
            </span>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountPayment: state.accountPaymentReducer,
    accountPaymentStatus: state.accountPayment,
    UserData: state.user,
    paymentDashboardData: state.paymentDashboardDataReducer,
    paymentDashboardDataArray: state.paymentDashboardData,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setAccountPaymentStatus })(
    PaymentDashboardCard
  )
);
