import React, { Component } from "react";
import "../application.css";
import { withRouter } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import { CountryJson } from "../../Property/CountryJson";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import LicenseModal from "../LicenseModal";
import {
  checkPaymentStatus,
  //setApplication,
  stripeKey,
  getApplicationsCount,
  addPaymentMethodGuest,
  getPropertyLists,
  url,
  getPropertyRent,
  isAvailableUser,
  getLandlordByRentalId,
  stripeToken,
  addPaymentMethod,
  getCustomerPayment,
  verifyPaymentMethod,
  userDetail,
  setBritishColumbiaApplicationV2,
} from "../../../../Services/ApiServices";

import { StripeProvider, Elements } from "react-stripe-elements";
import CardPayment from "../../../Pages/Payment/CardPayment";

import ApplicationReview1 from "../Review/ApplicationReview1";
import ModalContainer from "../../Documents/ModalContainer";
import SignaturePad from "../../../UIServices/SignaturePad/SignaturePad";
import { soraDetails } from "../../../../Services/JsonData";
import Loader3 from "../../../Loader/loader3";
import {
  addressFormatter,
  betweenExtraMonths,
  betweenYears,
  correctDateFormatYearFirst,           // added NEW FUNCTION from application canada
} from "../../../../Defined/Function";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import SearchInput from "../SearchInput";
import CookieService from "../../../../Services/CookieService";
import { connect } from "react-redux";
import {
  appendAlert,
  setCustomerPaymentStatus,
  setUser,
} from "../../../../actions";
import Dropzone from "react-dropzone";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import Loader from "../../../Loader/Loader";

//This Component is Duplication component of ListingApplication
//New Canada Application additional fields have been added in this Component
class ListingApplicationBritishColumbia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceList: [],
      confirmLoader: false,
      user: [],
      nowTab: 0,
      stripe_data: "",
      stripe_type: "",
      tabCheck: [false, false, false, false, false, false],
      next: false,
      nextEnable: false,
      licenseModal: false,
      footer: true,
      firstName: "",
      middleName: "",
      lastName: "",
      otherName: "",
      relativeName: "",
      email: this.props.UserData.username,
      dob: "",
      mobile: "",
      drivingNo: "",
      drivingNoProvince: "",       // added field from application canada
      drivingNoValid: true,       // added field from application canada
      securityNo: "",
      workingNo: "",
      pet: "",
      petCount: 1,
      petBreed: "",      // added field from application canada
      petHeight: "",      // added field from application canada
      petWeight: "",      // added field from application canada
      relativeNum: "",
      occupant: 1,
      occupantChildren: 0,      // added field from application canada
      occupantArray: [],
      occupantChildrenArray: [],        // added field from application canada
      occupantsArray: [],
      occupantChildrensArray: [],
      moveInDate: "",
      leaseTerm: 1,
      crimeCheck: false,
      bankruptcy: false,
      evicted: false,
      smoke: false,
      additionalIncome: "",
      vehicles: "",
      applicantVehicle: false,      // added field from application canada
      applicantVehicleMadeBy: "",      // added field from application canada
      applicantVehicleModel: "",      // added field from application canada
      applicantVehicleYear: "",      // added field from application canada
      applicantVehicleColor: "",      // added field from application canada
      applicantVehicleLicensePlateNumber: "",      // added field from application canada
      applicantVehicleLicensePlateProvince: "",      // added field from application canada
      dropYears: [],      // added field from application canada

      applicantFirstReferencesName: "",      // added field from application canada
      applicantFirstReferencesAddress: "",      // added field from application canada
      applicantFirstReferencesCity: "",      // added field from application canada
      applicantFirstReferencesProvince: "",      // added field from application canada
      applicantFirstReferencesCountry: "", //newly added  
      applicantFirstReferencesTelephone: "",      // added field from application canada
      applicantSecondReferencesName: "",      // added field from application canada
      applicantSecondReferencesAddress: "",      // added field from application canada
      applicantSecondReferencesCity: "",      // added field from application canada
      applicantSecondReferencesCountry: "",      // added field from application canada
      applicantSecondReferencesProvince: "",      // added field from application canada
      applicantSecondReferencesTelephone: "",      // added field from application canada

      address: "",
      state: "",
      city: "",
      country: "",
      zip: "",
      residentPhone: "",      // added field from application canada
      residentEmail: "",      // added field from application canada
      from: "",
      reasonForLeaving: "",      // added field from application canada
      to: this.today(),
      propOwn: "",
      presentLandlord: "",
      landLordEmail: "",
      rent: "",
      landLordMobile: "",
      landLordFax: "",
      unitType: "",

      preAddress: "",
      preState: "",
      preCity: "",
      preCountry: "",
      preZip: "",
      preResidentPhone: "",      // added field from application canada
      preResidentEmail: "",      // added field from application canada
      preFrom: "",
      preTo: "",
      preReasonForLeaving: "",      // added field from application canada
      prePropOwn: "",
      preLandLord: "",
      preLandLordEmail: "",
      preRent: "",
      preLandLordMobile: "",
      preLandLordFax: "",
      preUnitType: "",
      occupant0: "",
      occupant1: "",
      occupant2: "",
      occupant3: "",
      occupant4: "",
      occupant5: "",
      occupant6: "",
      occupant7: "",
      occupant8: "",
      occupant9: "",
      occupant10: "",
      occupant11: "",
      occupant12: "",
      occupant13: "",
      occupant14: "",
      occupant15: "",
      occupant16: "",

      occupantChildren0: "",      // added field from application canada
      occupantChildren1: "",      // added field from application canada
      occupantChildren2: "",      // added field from application canada
      occupantChildren3: "",      // added field from application canada
      occupantChildren4: "",      // added field from application canada
      occupantChildren5: "",      // added field from application canada
      occupantChildren6: "",      // added field from application canada
      occupantChildren7: "",      // added field from application canada
      occupantChildren8: "",      // added field from application canada
      occupantChildren9: "",      // added field from application canada
      occupantChildren10: "",      // added field from application canada
      occupantChildren11: "",      // added field from application canada
      occupantChildren12: "",      // added field from application canada
      occupantChildren13: "",      // added field from application canada
      occupantChildren14: "",      // added field from application canada
      occupantChildren15: "",      // added field from application canada
      occupantChildren16: "",      // added field from application canada

      emergencyContactName: "",      // added field from application canada
      emergencyContactStreet: "",      // added field from application canada
      emergencyContactCity: "",      // added field from application canada
      emergencyContactProvince: "",      // added field from application canada
      emergencyContactCountry: "",      // added field from application canada
      emergencyContactPostalCode: "",      // added field from application canada
      emergencyContactPhone: "",      // added field from application canada

      employmentCompany: "",
      employmentPhone: "",
      employmentName: "",      // added field from application canada
      employmentEmail: "",
      employmentZip: "",
      employmentCountry: "",
      employmentAddress: "",
      employmentCity: "",
      employmentState: "",
      employmentSalary: "",
      employmentPosition: "",
      employmentFrom: "",
      employmentUntil: this.today(),

      preEmploymentCompany: "",
      preEmploymentPhone: "",
      preEmploymentName: "",      // added field from application canada

      preEmploymentEmail: "",
      preEmploymentZip: "",
      preEmploymentAddress: "",
      preEmploymentCountry: "",
      preEmploymentCity: "",
      preEmploymentState: "",
      preEmploymentSalary: "",
      preEmploymentPosition: "",
      preEmploymentFrom: "",
      preEmploymentUntil: "",
      rentalUnit: this.props.match.params.id ? this.props.match.params.id : "",
      stripe_account_status: "",
      paymentToken: "",
      listingData: "",
      landlords: "",
      downloadModal: false,
      signatureObject: null,
      signatureObject2: null,
      signatureObject3: null,
      signatureTab: "pad",
      next_action: {
        type: "redirect_to_url",
        redirect_to_url: {
          url: "https://hooks.stripe.com/...",
          return_url: "https://http://localhost:3000/sora/application/",
        },
      },
      isButtonDisableFinish: true,
      pdfDocObject: "",
      applicationFee: 0,
      finishStatus: false,
      finishedModal: true,
      preRentalInfo: false,
      preEmploymentInfo: false,
      isJoinTenant: false,
      joinTenantCount: 0,
      joinTenant1: "",
      joinTenant2: "",

      countryList: [],
      countryListRef1: [],      // added field from application canada
      countryListRef2: [],      // added field from application canada
      countryListEmergency: [],      // added field from application canada
      stateList: [],
      stateListRef1: [],      // added field from application canada
      stateListRef2: [],      // added field from application canada
      stateListEmergency: [],      // added field from application canada
      cityList: [],
      cityListRef1: [],      // added field from application canada
      cityListRef2: [],      // added field from application canada
      cityListEmergency: [],      // added field from application canada

      preCountryList: [],
      preStateList: [],
      preCityList: [],

      employmentCountryList: [],
      employmentStateList: [],
      employmentCityList: [],

      preEmploymentCountryList: [],
      preEmploymentStateList: [],
      preEmploymentCityList: [],

      countryJson: [],
      countryRef1Json: [],      // added field from application canada
      countryRef2Json: [],      // added field from application canada
      countryEmergencyJson: [],      // added field from application canada
      stateJson: [],
      stateRef1Json: [],      // added field from application canada
      stateRef2Json: [],      // added field from application canada
      stateEmergencyJson: [],      // added field from application canada
      cityJson: [],
      cityRef1Json: [],      // added field from application canada
      cityRef2Json: [],      // added field from application canada
      cityEmergencyJson: [],      // added field from application canada

      preCountryJson: [],
      preStateJson: [],
      preCityJson: [],

      employmentCountryJson: [],
      employmentStateJson: [],
      employmentCityJson: [],

      preEmploymentCountryJson: [],
      preEmploymentStateJson: [],
      preEmploymentCityJson: [],
      changeBlob: true,
      mailCheck: false,
      primaryApply: true,
      nowJoinTenant: 1,
      pdfLink: null,
    };
    this.onchange = this.onchange.bind(this);
    this.getOccupant = this.getOccupant.bind(this);
    this.getOccupantChildren = this.getOccupantChildren.bind(this);      // added function from application canada
    this.next = this.next.bind(this);
    this.changeLicense = this.changeLicense.bind(this);
    this.changeLicenseData = this.changeLicenseData.bind(this);
    this.keepWait = this.keepWait.bind(this);
    this.removeKeepWait = this.removeKeepWait.bind(this);
    this.showButton = this.showButton.bind(this);
    this.setPaymentToken = this.setPaymentToken.bind(this);
    this.changeTokenNull = this.changeTokenNull.bind(this);
    this.onchangeState = this.onchangeState.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckOpp = this.onchangeCheckOpp.bind(this);
    this.onchangeCheckOption = this.onchangeCheckOption.bind(this);
    this.closeDownloadModal = this.closeDownloadModal.bind(this);
    this.changeSignatureObject = this.changeSignatureObject.bind(this);
    this.changeSignatureObject2 = this.changeSignatureObject2.bind(this);
    this.changeSignatureObject3 = this.changeSignatureObject3.bind(this);
    this.changeState = this.changeState.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.afterPayment = this.afterPayment.bind(this);
  }
  setDemoData() {
    let date = new Date();
    date.setDate(date.getDate() + 15);
    let formattedMoveInDate = date.toISOString().slice(0, 10);

    this.setState({
      nextEnable: true,
      firstName: this.props.userDetail ? this.props.userDetail.firstName : "",
      middleName: "",
      lastName: this.props.userDetail ? this.props.userDetail.lastName : "",
      otherName: "-",
      relativeName: "James Oven",
      email: this.props.userDetail ? this.props.userDetail.username : "",
      dob: "1978-02-02",
      mobile: this.props.userDetail ? this.props.userDetail.mobileNumber : "",
      drivingNo: "WDLJKMN580GF",
      drivingNoProvince: "British Columbia",  // added field from application canada
      drivingNoValid: "true",     // added field from application canada
      securityNo: "233 423 342",
      workingNo: "(484) 473-1851",
      pet: "true",
      petBreed: "Bulldog",     // added field from application canada
      petHeight: "16 inch",         // added field from application canada
      petWeight: "23 kg",        // added field from application canada
      relativeNum: "(585) 304-2531",
      moveInDate: formattedMoveInDate,
      leaseTerm: 1,
      additionalIncome: "1140",
      vehicles: "van,car",
      from: "2022-01-07",
      reasonForLeaving: "Need to change my work place",
      to: this.today(),
      propOwn: "Own",
      presentLandlord: "",
      landLordEmail: "",
      rent: "",
      landLordMobile: "",
      landLordFax: "",
      unitType: "",

      applicantVehicle: true,
      applicantVehicleMadeBy: "Toyota",        // added field from application canada
      applicantVehicleModel: "Camry",        // added field from application canada
      applicantVehicleYear: "2021",        // added field from application canada
      applicantVehicleColor: "Silver",        // added field from application canada
      applicantVehicleLicensePlateNumber: "XDM 031",        // added field from application canada
      applicantVehicleLicensePlateProvince: "Ontario",        // added field from application canada

      applicantFirstReferencesName: "Bruce Huel",        // added field from application canada
      applicantFirstReferencesAddress: "720 Bristol Rd",        // added field from application canada
      applicantFirstReferencesCity: "Vancouver",        // added field from application canada
      applicantFirstReferencesProvince: "British Columbia",        // added field from application canada
      applicantFirstReferencesCountry: "Canada",        // added field from application canada
      applicantFirstReferencesTelephone: "(905) 507-3335",        // added field from application canada
      applicantSecondReferencesName: "Floy Hoppe",        // added field from application canada
      applicantSecondReferencesAddress: "20150 Langley Bypass",        // added field from application canada
      applicantSecondReferencesCity: "Langley",        // added field from application canada
      applicantSecondReferencesCountry: "Canada",        // added field from application canada
      applicantSecondReferencesProvince: "British Columbia",        // added field from application canada
      applicantSecondReferencesTelephone: "(604) 530-2335",        // added field from application canada

      preAddress: "",
      preState: "",
      preCity: "",
      preCountry: "",
      preZip: "",
      preFrom: "",
      preTo: "",
      prePropOwn: "",
      preLandLord: "",
      preLandLordEmail: "",
      preRent: "",
      preLandLordMobile: "",
      preLandLordFax: "",
      preUnitType: "",
      occupant0: "",
      occupant1: "",
      occupant2: "",
      occupant3: "",
      occupant4: "",
      occupant5: "",
      occupant6: "",
      occupant7: "",
      occupant8: "",
      occupant9: "",
      occupant10: "",
      occupant11: "",
      occupant12: "",
      occupant13: "",
      occupant14: "",
      occupant15: "",
      occupant16: "",
      occupantChildren0: "",
      occupantChildren1: "",
      occupantChildren2: "",
      occupantChildren3: "",
      occupantChildren4: "",
      occupantChildren5: "",
      occupantChildren6: "",
      occupantChildren7: "",
      occupantChildren8: "",
      occupantChildren9: "",
      occupantChildren10: "",
      occupantChildren11: "",
      occupantChildren12: "",
      occupantChildren13: "",
      occupantChildren14: "",
      occupantChildren15: "",
      occupantChildren16: "",

      emergencyContactName: "John Kane",        // added field from application canada
      emergencyContactStreet: "12915 E Broadway Ave",        // added field from application canada
      emergencyContactCity: "Spokane Valley",        // added field from application canada
      emergencyContactProvince: "Washington",        // added field from application canada
      emergencyContactCountry: "United State",        // added field from application canada
      emergencyContactPostalCode: "99216",        // added field from application canada
      emergencyContactPhone: "(509) 343-1212",        // added field from application canada

      employmentCompany: "J B Engineering",
      employmentPhone: "(509) 642-7842",
      employmentName: "James karden",
      employmentEmail: "johnmc@jbeng.com",
      employmentZip: "99216",
      employmentCountry: "United State",
      employmentAddress: "12915 E Broadway Ave",
      employmentCity: "Spokane Valley",
      employmentState: "Washington",
      employmentSalary: "2570",
      employmentPosition: "Mechanical engineer - III",
      employmentFrom: "2020-01-15",
      preEmploymentCompany: "",
      preEmploymentPhone: "",
      preEmploymentName: "",
      preEmploymentEmail: "",
      preEmploymentZip: "",
      preEmploymentAddress: "",
      preEmploymentCountry: "",
      preEmploymentCity: "",
      preEmploymentState: "",
      preEmploymentSalary: "",
      preEmploymentPosition: "",
      preEmploymentFrom: "",
      preEmploymentUntil: "",
    });
  }
  async clickStep(step) {
    if (this.state.tabCheck[step]) {
      this.setState({ nowTab: step });
      if (step === 5) {
        this.getPdfBlob();
        await this.setState({ occupantsArray: [], occupantChildrensArray: [] });
        for (let i = 1; i < this.state.occupant; i++) {
          this.state.occupantsArray.push(this.state["occupant" + i]);
        }
        for (let i = 1; i < this.state.occupantChildren; i++) {         // added occupation  from application canada
          this.state.occupantChildrensArray.push(
            this.state["occupantChildren" + i]
          );
        }
      }
    } else {
      if (this.state.nowTab + 1 === step) {
        if (step === 5) {
          if (this.state.paymentToken !== "") {
            this.next();
            this.getPdfBlob();
            await this.setState({ occupantsArray: [] });
            await this.setState({ occupantChildrensArray: [] });
            for (let j = 1; j < this.state.occupant; j++) {
              this.state.occupantsArray.push(this.state["occupant" + j]);
            }
            for (let j = 1; j < this.state.occupantChildren; j++) {
              this.state.occupantChildrensArray.push(
                this.state["occupantChildren" + j]
              );
            }
          }
        } else {
          this.next();
        }
      } else {
        if (step === 5) {
          if (this.state.paymentToken !== "") {
            this.setState({ nowTab: step });
            this.getPdfBlob();
            await this.setState({ occupantsArray: [] });
            await this.setState({ occupantChildrensArray: [] });
            for (let k = 1; k < this.state.occupant; k++) {
              this.state.occupantsArray.push(this.state["occupant" + k]);
            }
            for (let k = 1; k < this.state.occupantChildren; k++) {
              this.state.occupantChildrensArray.push(
                this.state["occupantChildren" + k]
              );
            }
          }
        } else {
          this.props.appendAlert(
            "Please fill correct order, Your next step is " +
              (this.state.nowTab + 1),
            "warning"
          );
        }
      }
    }
  }
  checkTab() {
    if (this.state.tabCheck[this.state.nowTab - 1] || this.state.nowTab === 0) {
      return true;
    } else if (this.state.stripe_account_status.data === "verified") {
      if (
        this.state.tabCheck[0] &&
        this.state.tabCheck[1] &&
        this.state.tabCheck[2]
      ) {
        return true;
      }
    } else {
      for (let i = this.state.nowTab - 1; i > -1; i--) {
        if (this.state.tabCheck[i]) {
          this.props.appendAlert("Please fill correct order", "danger");
          this.setState({ nowTab: i + 1 });
          break;
        }

        if (i === 0) {
          this.props.appendAlert("Please fill correct order", "danger");
          this.setState({ nowTab: 0 });
          break;
        }
      }
    }
  }
  //get today date
  today() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }

  async changeSignatureObject(object) {
    await this.setState({ signatureObject: object });
    this.next();
  }
  changeSignatureObject2(object) {
    this.setState({ signatureObject2: object });
    window.$("#signatureModal").modal("hide");
    // if(this.state.stripe_account_status.data === "verified"){
    //   window.$("#finishedModal").modal("show");
    // }
  }
  changeSignatureObject3(object) {
    this.setState({ signatureObject3: object });
  }
  openDownloadModal() {
    this.setState({ downloadModal: true });
  }
  closeDownloadModal() {
    this.setState({ downloadModal: false });
  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };
  async onChangeDate(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    // window.onbeforeunload = "";
    const json = await CountryJson();
    this.setState({ countryJson: json });
    this.setState({ countryRef1Json: json });            // added field from application canada
    this.setState({ countryRef2Json: json });        // added field from application canada
    this.setState({ countryEmergencyJson: json });        // added field from application canada
    this.setState({ stateJson: json[1].states });
    this.setState({ stateRef1Json: json[1].states });        // added field from application canada
    this.setState({ stateRef2Json: json[1].states });        // added field from application canada
    this.setState({ stateEmergencyJson: json[1].states });        // added field from application canada
    this.setState({ preCountryJson: json });
    this.setState({ preStateJson: json[1].states });
    this.setState({ employmentCountryJson: json });
    this.setState({ employmentStateJson: json[1].states });
    this.setState({ preEmploymentCountryJson: json });
    this.setState({ preEmploymentStateJson: json[1].states });
    this.setState({
      accountStatus: this.props.status ? this.props.status : "",
    });
    if (this.props.role === "Guest" && this.props.match.params.id) {
      let apartmentData = soraDetails;
      let unit = this.props.match.params.id;
      apartmentData = apartmentData.filter(function (data) {
        return data.unit === unit;
      });
      this.setState({ apartmentData: apartmentData[0] });
      getPropertyRent("sora").then((response) => {
        if (response.status === 200) {
          this.setState({ applicationFee: response.data.fee / 100 });
        } else {
          if (response.data === undefined) {
            if (response.response.data !== undefined) {
              this.props.appendAlert(response.response.data.message, "danger");
            } else {
              this.props.appendAlert("Try again later", "warning");
            }
          }
          this.setState({ applicationFee: 0 });
        }
      });
    } else {
      this.setState({
        applicationFee: this.props.history.location.state
          ? this.props.history.location.state.applicationFee
          : 0,
      });
    }

    try {
      await getApplicationsCount(this.props.match.params.id).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status) {
              response.data.data !== "0" &&
                this.props.appendAlert(response.data.data, "warning");

              if (this.props.role === "Landlord") {
                this.props.history.push({ pathname: "/rental-units" });
                this.props.appendAlert(
                  "Sorry!!! Landlord can't Apply Units",
                  "warning"
                );
              }
            } else {
              this.props.history.push({ pathname: "/rental-units" });
              this.props.appendAlert(response.data.data, "danger");
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
          }
        }
      );
    } catch (error) {}

    this.setState({ user: this.props.userDetail });

    if (this.props.userDetail) {
      this.setState({ firstName: this.props.userDetail.firstName });
      this.setState({ lastName: this.props.userDetail.lastName });
      this.setState({ email: this.props.userDetail.username });
      this.setState({ address: this.props.userDetail.address.address });
      this.setState({ state: this.props.userDetail.address.state });
      this.setState({ city: this.props.userDetail.address.city });
      this.setState({ zip: this.props.userDetail.address.zip });
      this.setState({ mobile: this.props.userDetail.mobileNumber });
      try {
        await checkPaymentStatus().then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({
                stripe_account_status: response.data,
                stripe_data: response.data,
              });
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
          }
        });
      } catch (err) {}
      try {
        await getPropertyLists(this.props.match.params.id).then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({
                listingData: response.data,
              });
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
            this.props.history.push({ pathname: "/rental-units" });
          }
        });
      } catch (err) {}
      try {
        await getLandlordByRentalId(this.props.match.params.id).then(
          (response) => {
            if (response.status === 200) {
              if (response.status) {
                this.setState({
                  landlords: response.data,
                });
              }
            } else {
              if (response.data === undefined) {
                if (response.response.data !== undefined) {
                  this.props.appendAlert(
                    response.response.data.message,
                    "danger"
                  );
                } else {
                  this.props.appendAlert("Try again later", "warning");
                }
              }
              this.props.history.push({ pathname: "/rental-units" });
            }
          }
        );
      } catch (err) {}
    }
    let countryList = []; 
    let countryListRef1 = [];        // added field from application canada
    let countryListRef2 = [];        // added field from application canada
    let countryListEmergency = [];        // added field from application canada
    let preCountryList = [];
    let employmentCountryList = [];
    let preEmploymentCountryList = [];
    this.state.countryJson.map((data) => countryList.push(data.name));
    this.state.countryRef1Json.map((data) => countryListRef1.push(data.name));        // added field from application canada
    this.state.countryRef2Json.map((data) => countryListRef2.push(data.name));        // added field from application canada
    this.state.countryEmergencyJson.map((data) => countryListEmergency.push(data.name));        // added field from application canada
    this.state.preCountryJson.map((data) => preCountryList.push(data.name));
    this.state.employmentCountryJson.map((data) =>
      employmentCountryList.push(data.name)
    );
    this.state.preEmploymentCountryJson.map((data) =>
      preEmploymentCountryList.push(data.name)
    );

    this.setState({ countryList: countryList });
    this.setState({ countryListRef1: countryListRef1 });        // added field from application canada
    this.setState({ countryListRef2: countryListRef2 });        // added field from application canada
    this.setState({ countryListEmergency: countryListEmergency });        // added field from application canada
    this.setState({ preCountryList: preCountryList });
    this.setState({ employmentCountryList: employmentCountryList });
    this.setState({ preEmploymentCountryList: preEmploymentCountryList });

    let stateList = [];
    let stateListRef1 = [];        // added field from application canada
    let stateListRef2 = [];        // added field from application canada
    let stateListEmergency = [];        // added field from application canada
    let preStateList = [];
    let employmentStateList = [];
    let preEmploymentStateList = [];
    let provinceList = [];
    json.map((data) =>data.states.map((data1) =>provinceList.push(data1.name)));
    

    this.state.stateJson.map((data) => stateList.push(data.name));
    this.state.stateRef1Json.map((data) => stateListRef1.push(data.name));        // added field from application canada
    this.state.stateRef2Json.map((data) => stateListRef2.push(data.name));        // added field from application canada
    this.state.stateEmergencyJson.map((data) => stateListEmergency.push(data.name));        // added field from application canada
    this.state.preStateJson.map((data) => preStateList.push(data.name));
    this.state.employmentStateJson.map((data) =>
      employmentStateList.push(data.name)
    );
    this.state.preEmploymentStateJson.map((data) =>
      preEmploymentStateList.push(data.name)
    );

    this.setState({ stateList: stateList });
    this.setState({ stateListRef1: stateListRef1 });        // added field from application canada
    this.setState({ stateListRef2: stateListRef2 });        // added field from application canada
    this.setState({ stateListEmergency: stateListEmergency });        // added field from application canada
    this.setState({ preStateList: preStateList });
    this.setState({ employmentStateList: employmentStateList });
    this.setState({ preEmploymentStateList: preEmploymentStateList });
    this.setState({ provinceList: provinceList });

    let x = [];
    for (let i = parseInt(new Date().getFullYear()); i > 1900; i--) {
      x.push(i);
    }
    this.setState({ dropYears: x });
  }
  setPaymentToken(token) {
    this.setState({ paymentToken: token });
  }
  changeTokenNull() {
    this.setState({ paymentToken: "" });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    if (name === "preRentalInfo" && checked) {
      this.setState({
        preAddress: "",
        preState: "",
        preCity: "",
        preCountry: "",
        preZip: "",
        preFrom: "",
        preTo: "",
        prePropOwn: "",
        preLandLord: "",
        preLandLordEmail: "",
        preRent: "",
        preLandLordMobile: "",
        preLandLordFax: "",
        preUnitType: "",
      });
    } else if (name === "preEmploymentInfo" && checked) {
      this.setState({
        preEmploymentCompany: "",
        preEmploymentPhone: "",
        preEmploymentName: "",
        preEmploymentEmail: "",
        preEmploymentZip: "",
        preEmploymentAddress: "",
        preEmploymentCountry: "",
        preEmploymentCity: "",
        preEmploymentState: "",
        preEmploymentSalary: "",
        preEmploymentPosition: "",
        preEmploymentFrom: "",
        preEmploymentUntil: "",
      });
    } else if (name === "isJoinTenant") {
      if (checked) {
        this.setState({ joinTenantCount: 1 });
      } else {
        this.setState({ joinTenantCount: 0 });
      }
    }

    this.setState({ next: false });
    await this.setState({ [name]: checked });
    this.nextCheck(this.state.nowTab);
  }
  async onchangeCheckOpp(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: !checked });
    this.nextCheck(this.state.nowTab);
  }

  async onchangeCheckOption(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  changeJoinTenantCount() {
    if (this.state.joinTenantCount === 1) {
      this.setState({ joinTenantCount: 2 });
    } else {
      this.setState({ joinTenantCount: 1 });
    }
  }
  changeLicenseData(data) {
    this.setState({
      dob: this.setDOB(data.dob),
      drivingNo: data.licenseNo,
      city: data.city,
      state: data.state,
      zip: data.zip,
      address: data.addressLine1,
    });
    if (
      this.state.firstName === "" ||
      this.state.firstName === "please wait..."
    ) {
      this.setState({ firstName: data.firstName });
    }
    if (
      this.state.lastName === "" ||
      this.state.lastName === "please wait..."
    ) {
      this.setState({ lastName: data.lastName });
    }
  }
  keepWait() {
    this.setState({
      dob: "please wait...",
      drivingNo: "please wait...",
      city: "please wait...",
      state: "please wait...",
      zip: "please wait...",
      address: "please wait...",
    });
    if (this.state.firstName === "") {
      this.setState({ firstName: "please wait..." });
    }
    if (this.state.lastName === "") {
      this.setState({ lastName: "please wait..." });
    }
  }
  removeKeepWait() {
    if (this.state.firstName === "please wait...") {
      this.setState({ firstName: "" });
    }
    if (this.state.lastName === "please wait...") {
      this.setState({ lastName: "" });
    }
    if (this.state.dob === "please wait...") {
      this.setState({ dob: "" });
    }
    if (this.state.drivingNo === "please wait...") {
      this.setState({ drivingNo: "" });
    }
    if (this.state.city === "please wait...") {
      this.setState({ city: "" });
    }
    if (this.state.state === "please wait...") {
      this.setState({ state: "" });
    }
    if (this.state.zip === "please wait...") {
      this.setState({ zip: "" });
    }
    if (this.state.address === "please wait...") {
      this.setState({ address: "" });
    }
  }
  setDOB(_date) {         // change this component return format yyyy/mm/dd
    if (_date) {
      let formatLowerCase = "mm/dd/yyyy";
      let formatItems = formatLowerCase.split("/");
      let dateItems = _date.split("/");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      return (
        dateItems[yearIndex] +
        "-" +
        dateItems[monthIndex] +
        "-" +
        dateItems[dayIndex]
      );
    } else {
      return "";
    }
  }
  changeLicense() {
    this.setState({ licenseModal: !this.state.licenseModal });
  }
  showButton() {
    this.setState({ footer: true });
  }
  ModifyString(value) {
    if (value) {
      return value.toString();
    }
    return "";
  }
  async getPdfBlob() {
    this.setState({ changeBlob: false });
    const formUrl = url + "api/application/britishColumbia/download";     //changed this working as britishColumbia
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();

    try {
      //Address
      const premisesAddress = form.getTextField("Premises Address");
      premisesAddress.setText(
        this.ModifyString(addressFormatter(this.state.listingData.address))
      );
      premisesAddress.enableReadOnly();
      //Rent
      const rentAmount = form.getTextField("Rent Rate");
      rentAmount.setText(
        this.ModifyString(
          this.state.apartmentData
            ? "$ " + this.state.apartmentData.price
            : "$ " +
                (this.state.listingData.rentalEntity.rent
                  ? this.state.listingData.rentalEntity.rent
                  : "")
        )
      );
      rentAmount.enableReadOnly();
      //Security Deposit
      const SecurityDeposit = form.getTextField("Security Deposit");
      SecurityDeposit.setText(
        this.ModifyString(
          "$ " + String(this.state.listingData.rentalEntity.securityDeposit)
        )
      );
      SecurityDeposit.enableReadOnly();
      //Move In Date
      const formDateRequire = form.getTextField("Date Required");
      formDateRequire.setText(
        correctDateFormatYearFirst(this.state.moveInDate)
      );
      formDateRequire.enableReadOnly();

      //last Name
      const applicantsLastName = form.getTextField("Last Name");
      applicantsLastName.setText(
        this.state.lastName ? this.state.lastName : " "
      );
      applicantsLastName.enableReadOnly();

      //First Name
      const applicantsFirstName = form.getTextField("First Name");
      applicantsFirstName.setText(
        this.state.firstName ? this.state.firstName + " " : ""
      );
      applicantsFirstName.enableReadOnly();

      //Middle Name
      const applicantsMiddleName = form.getTextField("Middle Name");
      applicantsMiddleName.setText(
        this.state.middleName ? this.state.middleName + " " : ""
      );
      applicantsMiddleName.enableReadOnly();

      // DOB
      const birthDateYear = form.getTextField("Date of Birth Y");
      birthDateYear.setText(
        this.ModifyString(this.ModifyDateYear(this.state.dob))
      );
      birthDateYear.enableReadOnly();
      const birthDateMonth = form.getTextField("Date of Birth M");
      birthDateMonth.setText(
        this.ModifyString(this.ModifyDateMonth(this.state.dob))
      );
      birthDateMonth.enableReadOnly();
      const birthDateDay = form.getTextField("Date of Birth D");
      birthDateDay.setText(
        this.ModifyString(this.ModifyDateDay(this.state.dob))
      );
      birthDateDay.enableReadOnly();

      // ssn
      const socialSecurityNumber1 = form.getTextField("SIN1");
      socialSecurityNumber1.setText(
        this.ModifyString(String(this.state.securityNo[0]))
      );
      socialSecurityNumber1.enableReadOnly();
      const socialSecurityNumber2 = form.getTextField("SIN2");
      socialSecurityNumber2.setText(
        this.ModifyString(String(this.state.securityNo[1]))
      );
      socialSecurityNumber2.enableReadOnly();
      const socialSecurityNumber3 = form.getTextField("SIN3");
      socialSecurityNumber3.setText(
        this.ModifyString(String(this.state.securityNo[2]))
      );
      socialSecurityNumber3.enableReadOnly();

      const socialSecurityNumber4 = form.getTextField("SIN4");
      socialSecurityNumber4.setText(
        this.ModifyString(String(this.state.securityNo[4]))
      );
      socialSecurityNumber4.enableReadOnly();
      const socialSecurityNumber5 = form.getTextField("SIN5");
      socialSecurityNumber5.setText(
        this.ModifyString(String(this.state.securityNo[5]))
      );
      socialSecurityNumber5.enableReadOnly();
      const socialSecurityNumber6 = form.getTextField("SIN6");
      socialSecurityNumber6.setText(
        this.ModifyString(String(this.state.securityNo[6]))
      );
      socialSecurityNumber6.enableReadOnly();

      const socialSecurityNumber7 = form.getTextField("SIN7");
      socialSecurityNumber7.setText(
        this.ModifyString(String(this.state.securityNo[8]))
      );
      socialSecurityNumber7.enableReadOnly();
      const socialSecurityNumber8 = form.getTextField("SIN8");
      socialSecurityNumber8.setText(
        this.ModifyString(String(this.state.securityNo[9]))
      );
      socialSecurityNumber8.enableReadOnly();
      const socialSecurityNumber9 = form.getTextField("SIN9");
      socialSecurityNumber9.setText(
        this.ModifyString(String(this.state.securityNo[10]))
      );
      socialSecurityNumber9.enableReadOnly();

      //Driving License
      const driversLicense = form.getTextField("Drivers License");
      driversLicense.setText(this.ModifyString(this.state.drivingNo));
      driversLicense.enableReadOnly();
      const driversLicenseProvince = form.getTextField("Province");
      driversLicenseProvince.setText(
        this.ModifyString(this.state.drivingNoProvince)
      );
      driversLicenseProvince.enableReadOnly();
      // visual proof of driver’s license/or State ID
      let driversLicenseValidYes = form.getCheckBox("valid yes chk");
      let driversLicenseValidNo = form.getCheckBox("valid no chk");
      if (this.state.drivingNoValid === "true") {
        driversLicenseValidYes.check();
      } else {
        driversLicenseValidNo.check();
      }
      driversLicenseValidNo.enableReadOnly();
      driversLicenseValidYes.enableReadOnly();

      //Vehicle Data
      const vehicleYear = form.getTextField("Vehicle Information Year");
      const vehicleMake = form.getTextField("Make");
      const vehicleModel = form.getTextField("Model");
      const vehicleColor = form.getTextField("Color");
      const vehiclePlateNo = form.getTextField("Vehicle License Plate Number");
      const vehicleIssueProvince = form.getTextField("License issued Province");

      if (this.state.applicantVehicle) {
        vehicleYear.setText(this.ModifyString(this.state.applicantVehicleYear));
        vehicleMake.setText(
          this.ModifyString(this.state.applicantVehicleMadeBy)
        );
        vehicleModel.setText(
          this.ModifyString(this.state.applicantVehicleModel)
        );
        vehicleColor.setText(
          this.ModifyString(this.state.applicantVehicleColor)
        );
        vehiclePlateNo.setText(
          this.ModifyString(this.state.applicantVehicleLicensePlateNumber)
        );
        vehicleIssueProvince.setText(
          this.ModifyString(this.state.applicantVehicleLicensePlateProvince)
        );
      }
      vehicleYear.enableReadOnly();
      vehicleMake.enableReadOnly();
      vehicleColor.enableReadOnly();
      vehicleModel.enableReadOnly();
      vehiclePlateNo.enableReadOnly();
      vehicleIssueProvince.enableReadOnly();
      
      // Present Address City State Zip
      const presentResidentAddress = form.getTextField(
        "Current Address Unit NumberStreet"
      );
      presentResidentAddress.setText(
        this.ModifyString(this.state.address ? this.state.address : "")
      );
      presentResidentAddress.enableReadOnly();
      const presentResidentCity = form.getTextField("Current Address City");
      presentResidentCity.setText(
        this.ModifyString(this.state.city ? this.state.city : "")
      );
      presentResidentCity.enableReadOnly();
      const presentResidentProvince = form.getTextField(
        "Current Address Province"
      );
      presentResidentProvince.setText(
        this.ModifyString(this.state.state ? this.state.state : "")
      );
      presentResidentProvince.enableReadOnly();
      const presentResidentPostalCode = form.getTextField(
        "Current Address PostalCode"
      );
      presentResidentPostalCode.setText(
        this.ModifyString(this.state.zip ? this.state.zip : "")
      );
      presentResidentPostalCode.enableReadOnly();
      const presentResidentPhone = form.getTextField("Current Address Phone");
      presentResidentPhone.setText(
        this.ModifyString(
          this.state.residentPhone ? this.state.residentPhone : ""
        )
      );
      presentResidentPhone.enableReadOnly();
      const presentResidentEmail = form.getTextField("Current Address Email");
      presentResidentEmail.setText(
        this.ModifyString(
          this.state.residentEmail ? this.state.residentEmail : ""
        )
      );
      presentResidentEmail.enableReadOnly();
      const presentResidentDurationYears = form.getTextField(
        "Duration Current Address Years"
      );
      presentResidentDurationYears.setText(
        this.ModifyString(betweenYears(this.state.from, this.state.to))
      );
      presentResidentDurationYears.enableReadOnly();
      const presentResidentDurationMonths = form.getTextField(
        "Duration Current Address Months"
      );
      presentResidentDurationMonths.setText(
        this.ModifyString(betweenExtraMonths(this.state.from, this.state.to))
      );
      presentResidentDurationMonths.enableReadOnly();
      const presentResidentRent = form.getTextField("Current Address Rent");
      presentResidentRent.setText("$ " + this.ModifyString(this.state.rent));
      presentResidentRent.enableReadOnly();
      const presentResidentReasonLeaving = form.getTextField(
        "Current Address Reason for leaving"
      );
      presentResidentReasonLeaving.setText(
        this.ModifyString(this.state.reasonForLeaving)
      );
      presentResidentReasonLeaving.enableReadOnly();

      const presentResidentLandlordName = form.getTextField(
        "Current Landlords Name"
      );
      const presentResidentLandlordPhone = form.getTextField(
        "Current Landlords phone"
      );
      if (this.state.propOwn === "Rent") {
        presentResidentLandlordName.setText(
          this.ModifyString(this.state.presentLandlord)
        );
        presentResidentLandlordPhone.setText(
          this.ModifyString(this.state.landLordMobile)
        );
      }
      presentResidentLandlordName.enableReadOnly();
      presentResidentLandlordPhone.enableReadOnly();

      // Previous Address City State Zip
      const previousResidentAddress = form.getTextField(
        "Previous Address Unit NumberStreet"
      );
      previousResidentAddress.setText(
        this.ModifyString(this.state.preAddress ? this.state.preAddress : "")
      );
      previousResidentAddress.enableReadOnly();
      const previousResidentCity = form.getTextField("Previous Address City");
      previousResidentCity.setText(
        this.ModifyString(this.state.preCity ? this.state.preCity : "")
      );
      previousResidentCity.enableReadOnly();
      const previousResidentProvince = form.getTextField(
        "Previous Address Province"
      );
      previousResidentProvince.setText(
        this.ModifyString(this.state.preState ? this.state.preState : "")
      );
      previousResidentProvince.enableReadOnly();
      const previousResidentPostalCode = form.getTextField(
        "Previous Address PostalCode"
      );
      previousResidentPostalCode.setText(
        this.ModifyString(this.state.preZip ? this.state.preZip : "")
      );
      previousResidentPostalCode.enableReadOnly();
      const previousResidentPhone = form.getTextField("Previous Address Phone");
      previousResidentPhone.setText(
        this.ModifyString(
          this.state.preResidentPhone ? this.state.preResidentPhone : ""
        )
      );
      previousResidentPhone.enableReadOnly();
      const previousResidentEmail = form.getTextField("Previous Address Email");
      previousResidentEmail.setText(
        this.ModifyString(
          this.state.preResidentEmail ? this.state.preResidentEmail : ""
        )
      );
      previousResidentEmail.enableReadOnly();
      const previousResidentDurationYears = form.getTextField(
        "Duration Previous Address Years"
      );
      previousResidentDurationYears.setText(
        this.ModifyString(betweenYears(this.state.preFrom, this.state.preTo))
      );
      previousResidentDurationYears.enableReadOnly();
      const previousResidentDurationMonths = form.getTextField(
        "Duration Previous Address Months"
      );
      previousResidentDurationMonths.setText(
        this.ModifyString(
          betweenExtraMonths(this.state.preFrom, this.state.preTo)
        )
      );
      previousResidentDurationMonths.enableReadOnly();
      const previousResidentRent = form.getTextField("Previous Address Rent");
      previousResidentRent.setText(this.ModifyString(this.state.preRent));
      previousResidentRent.enableReadOnly();
      const previousResidentReasonLeaving = form.getTextField(
        "Previous Address Reason for leaving"
      );
      previousResidentReasonLeaving.setText(
        this.ModifyString(this.state.preReasonForLeaving)
      );
      previousResidentReasonLeaving.enableReadOnly();

      const previousResidentLandlordName = form.getTextField(
        "Previous Landlords Name"
      );
      const previousResidentLandlordPhone = form.getTextField(
        "Previous Landlords phone"
      );
      if (this.state.propOwn === "Rent") {
        previousResidentLandlordName.setText(
          this.ModifyString(this.state.preLandLord)
        );
        previousResidentLandlordPhone.setText(
          this.ModifyString(this.state.preLandLordMobile)
        );
      }
      previousResidentLandlordName.enableReadOnly();
      previousResidentLandlordPhone.enableReadOnly();

      //EMPLOYMENT DATA
      //Current working company
      const presentEmploy = form.getTextField("Current EmployerCompany Name");
      presentEmploy.setText(this.ModifyString(this.state.employmentCompany));
      presentEmploy.enableReadOnly();
      const presentEmployPosition = form.getTextField("Occupation");
      presentEmployPosition.setText(
        this.ModifyString(this.state.employmentPosition)
      );
      presentEmployPosition.enableReadOnly();
      const presentEmploymentYears = form.getTextField("How Long Years");
      presentEmploymentYears.setText(
        this.ModifyString(
          betweenYears(this.state.employmentFrom, this.state.employmentUntil)
        )
      );
      presentEmploymentYears.enableReadOnly();
      const presentEmploymentMonths = form.getTextField("How Long Months");
      presentEmploymentMonths.setText(
        this.ModifyString(
          betweenExtraMonths(
            this.state.employmentFrom,
            this.state.employmentUntil
          )
        )
      );
      presentEmploymentMonths.enableReadOnly();
      const presentEmploymentIncome = form.getTextField("Income");
      presentEmploymentIncome.setText(
        "$" + this.ModifyString(this.state.employmentSalary)
      );
      presentEmploymentIncome.enableReadOnly();
      const presentEmploymentName = form.getTextField("Employers Contact Name");
      presentEmploymentName.setText(
        this.ModifyString(this.state.employmentName)
      );
      presentEmploymentName.enableReadOnly();
      const presentEmploymentPhone = form.getTextField(
        "Employers Contact Number"
      );
      presentEmploymentPhone.setText(
        this.ModifyString(this.state.employmentPhone)
      );
      presentEmploymentPhone.enableReadOnly();

      //Previous working company
      const previousEmploy = form.getTextField(
        "Previous Employer Company Name"
      );
      previousEmploy.setText(
        this.ModifyString(this.state.preEmploymentCompany)
      );
      previousEmploy.enableReadOnly();
      const previousEmployPosition = form.getTextField(
        "Previous Employer Occupation"
      );
      previousEmployPosition.setText(
        this.ModifyString(this.state.preEmploymentPosition)
      );
      previousEmployPosition.enableReadOnly();
      const previousEmploymentYears = form.getTextField(
        "Previous Employer Years"
      );
      previousEmploymentYears.setText(
        this.ModifyString(
          betweenYears(
            this.state.preEmploymentFrom,
            this.state.preEmploymentUntil
          )
        )
      );
      previousEmploymentYears.enableReadOnly();
      const previousEmploymentMonths = form.getTextField(
        "Previous Employer Months"
      );
      previousEmploymentMonths.setText(
        this.ModifyString(
          betweenExtraMonths(
            this.state.preEmploymentFrom,
            this.state.preEmploymentUntil
          )
        )
      );
      previousEmploymentMonths.enableReadOnly();
      const previousEmploymentIncome = form.getTextField(
        "Previous Employer Income"
      );
      previousEmploymentIncome.setText(
        this.ModifyString(this.state.preEmploymentSalary)
      );
      previousEmploymentIncome.enableReadOnly();
      const previousEmploymentName = form.getTextField(
        "Previous Employers Contact Name"
      );
      previousEmploymentName.setText(
        this.ModifyString(this.state.preEmploymentName)
      );
      previousEmploymentName.enableReadOnly();
      const previousEmploymentPhone = form.getTextField(
        "Previous Employers Contact Number"
      );
      previousEmploymentPhone.setText(
        this.ModifyString(this.state.preEmploymentPhone)
      );
      previousEmploymentPhone.enableReadOnly();

      //First Reference

      const referenceName = form.getTextField("Reference1 Name");
      referenceName.setText(
        this.ModifyString(this.state.applicantFirstReferencesName)
      );
      referenceName.enableReadOnly();
      const referencePhone = form.getTextField("Reference1 Phone");
      referencePhone.setText(
        this.ModifyString(this.state.applicantFirstReferencesTelephone)
      );
      referencePhone.enableReadOnly();
      const referenceStreet = form.getTextField("Reference1 Street");
      referenceStreet.setText(
        this.ModifyString(this.state.applicantFirstReferencesAddress)
      );
      referenceStreet.enableReadOnly();
      const referenceCity = form.getTextField("Reference1 City");
      referenceCity.setText(
        this.ModifyString(this.state.applicantFirstReferencesCity)
      );
      referenceCity.enableReadOnly();
      const referenceProvince = form.getTextField("Reference1 Province");
      referenceProvince.setText(
        this.ModifyString(this.state.applicantFirstReferencesProvince)
      );
      referenceProvince.enableReadOnly();

      //Secound Refrence

      const reference2Name = form.getTextField("Reference2 Name");
      reference2Name.setText(
        this.ModifyString(this.state.applicantSecondReferencesName)
      );
      reference2Name.enableReadOnly();
      const reference2Phone = form.getTextField("Reference2 Phone");
      reference2Phone.setText(
        this.ModifyString(this.state.applicantSecondReferencesTelephone)
      );
      reference2Phone.enableReadOnly();
      const reference2Street = form.getTextField("Reference2 Street");
      reference2Street.setText(
        this.ModifyString(this.state.applicantSecondReferencesAddress)
      );
      reference2Street.enableReadOnly();
      const reference2City = form.getTextField("Reference2 City");
      reference2City.setText(
        this.ModifyString(this.state.applicantSecondReferencesCity)
      );
      reference2City.enableReadOnly();
      const reference2Province = form.getTextField("Reference2 Province");
      reference2Province.setText(
        this.ModifyString(this.state.applicantSecondReferencesProvince)
      );
      reference2Province.enableReadOnly();

      //Evicted as tenant
      let evictedYes = form.getCheckBox("Evicted as a tenant Yes");
      let evictedNo = form.getCheckBox("Evicted as a tenant No");
      if (this.state.evicted) {
        evictedYes.check();
      } else {
        evictedNo.check();
      }
      evictedYes.enableReadOnly();
      evictedNo.enableReadOnly();

      //convicted of a criminal offence
      let criminalCheckYes = form.getCheckBox("Criminal offence Yes");
      let criminalCheckNo = form.getCheckBox("Criminal offence No");
      if (this.state.crimeCheck) {
        criminalCheckYes.check();
      } else {
        criminalCheckNo.check();
      }
      criminalCheckYes.enableReadOnly();
      criminalCheckNo.enableReadOnly();

      // Adult occupant
      const adultsOccupant = form.getTextField(
        "Number of adults to occupy rental until"
      );
      adultsOccupant.setText(this.ModifyString(this.state.occupant));
      adultsOccupant.enableReadOnly();
      const childrenOccupant = form.getTextField(
        "Number of children under 18 to occupy rental unit"
      );
      childrenOccupant.setText(this.ModifyString(this.state.occupantChildren));
      childrenOccupant.enableReadOnly();

      var adults = this.state.firstName + " " + this.state.lastName + ", ";
      this.state.occupantArray.forEach((element) => {
        adults += this.state[element] + ", ";
      });
      var childrens = "";
      this.state.occupantChildrenArray.forEach((occupant) => {
        childrens += this.state[occupant] + ", ";
      });

      const nameOccupants = form.getTextField("Name of All Occupants");
      nameOccupants.setText(this.ModifyString(adults + childrens));
      nameOccupants.enableReadOnly();

      //pet Details
      const petCount = form.getTextField("Number of pets");
      petCount.setText(this.ModifyString(this.state.petCount));
      petCount.enableReadOnly();
      const petBreed = form.getTextField("Breed");
      petBreed.setText(this.ModifyString(this.state.petBreed));
      petBreed.enableReadOnly();
      const petHeight = form.getTextField("Height");
      petHeight.setText(this.ModifyString(this.state.petHeight));
      petHeight.enableReadOnly();
      const petWeight = form.getTextField("Weight");
      petWeight.setText(this.ModifyString(this.state.petWeight));
      petWeight.enableReadOnly();

      //Emergency Contact

      const emergencyContactName = form.getTextField("Emergency notify Name");
      emergencyContactName.setText(
        this.ModifyString(this.state.emergencyContactName)
      );
      emergencyContactName.enableReadOnly();
      const emergencyContactPhone = form.getTextField("Emergency notify Phone");
      emergencyContactPhone.setText(
        this.ModifyString(this.state.emergencyContactPhone)
      );
      emergencyContactPhone.enableReadOnly();
      const emergencyContactStreet = form.getTextField(
        "Emergency notify Address Street"
      );
      emergencyContactStreet.setText(
        this.ModifyString(this.state.emergencyContactStreet)
      );
      emergencyContactStreet.enableReadOnly();
      const emergencyContactCity = form.getTextField(
        "Emergency notify Address City"
      );
      emergencyContactCity.setText(
        this.ModifyString(this.state.emergencyContactCity)
      );
      emergencyContactCity.enableReadOnly();
      const emergencyContactProvince = form.getTextField(
        "Emergency notify Address Province"
      );
      emergencyContactProvince.setText(
        this.ModifyString(this.state.emergencyContactProvince)
      );
      emergencyContactProvince.enableReadOnly();
      const emergencyContactPostalCode = form.getTextField(
        "Emergency notify Address PostalCode"
      );
      emergencyContactPostalCode.setText(
        this.ModifyString(this.state.emergencyContactPostalCode)
      );
      emergencyContactPostalCode.enableReadOnly();

      const date = form.getTextField("Date");
      date.setText(this.ModifyString(this.today()));
      date.enableReadOnly();

      const pdfBytes = await pdfDoc.save();
      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      const urlView = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlView;

      this.setState({ pdfLink: link });

      this.setState({ pdfDocObject: blob, changeBlob: true });
    } catch (error) {
      this.setState({ pdfDocObject: "" });
      console.log("PDF Fetching Error");
    }
  }
  async downloadPDF() {
    if (this.state.pdfDocObject === "") {
      this.props.appendAlert("Downloading Error Found!!!", "danger");
    } else {
      const urlView = window.URL.createObjectURL(this.state.pdfDocObject);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = urlView;
      a.download = "file.pdf";
      a.click();
      window.URL.revokeObjectURL(urlView);
    }
  }
  ModifyDate(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      let month = parseInt(dateItems[monthIndex]);
      let date = parseInt(dateItems[dayIndex]);
      return month + "/" + date + "/" + year;
    } else {
      return "";
    }
  }
  ModifyDateYear(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      return year;
    } else {
      return "";
    }
  }
  ModifyDateMonth(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let month = parseInt(dateItems[monthIndex]);
      return month;
    } else {
      return "";
    }
  }
  ModifyDateDay(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let dayIndex = formatItems.indexOf("dd");
      let date = parseInt(dateItems[dayIndex]);
      return date;
    } else {
      return "";
    }
  }
  emailReject() {
    window.$("#mailCheck").modal("hide");
    this.setState({ email: "" });
  }
  async emailAccept() {
    await this.setState({ mailCheck: true });
    this.next();
  }
  async afterPayment(data, type) {
    if (data !== null) {
      await this.setState({
        stripe_data: data,
        stripe_type: type,
      });
    } else {
      this.setState({
        stripe_account_status: "",
      });
    }
  }

  async next() {
    this.setState({ next: true });
    if (this.state.nowTab < 4) {
      if (this.state.nowTab === 0) {
        this.getPdfBlob();
        window.scrollTo(0, 0);
        this.setState({ next: false });
        if (this.checkTab()) {
          let newArray = this.state.tabCheck;
          newArray[this.state.nowTab] = true;
          this.setState({ tabCheck: newArray });
          if (!this.nextCheck(this.state.nowTab + 1)) {
            this.setState({ nextEnable: false });
          }
          this.setState({ nowTab: this.state.nowTab + 1 });
          window.onbeforeunload = this.beforeUnloadListener;
        }
      } else if (this.state.nowTab === 1) {
        window.scrollTo(0, 0);

        if (
          this.state.firstName !== "" &&
          this.state.lastName !== "" &&
          this.state.email !== "" &&
          this.checkEmailNext(this.state.email) &&
          // this.state.relativeName !== "" &&      // removed feild from application canada
          this.state.dob !== "" &&
          this.state.drivingNo !== "" &&
          this.state.drivingNoProvince !== "" &&
          this.state.drivingNoValid !== "" &&
          this.state.securityNo !== "" &&
          this.checkSINNext(this.state.securityNo) &&
          this.state.mobile !== "" &&
          this.checkMobileNext(this.state.mobile) &&
          this.state.pet !== "" &&
          this.state.emergencyContactName !== "" && // added field from application canada
          this.state.emergencyContactStreet !== "" && // added field from application canada
          this.state.emergencyContactCity !== "" && // added field from application canada
          this.state.emergencyContactProvince !== "" && // added field from application canada
          this.state.emergencyContactCountry !== "" && // added field from application canada
          this.state.emergencyContactPostalCode !== "" && // added field from application canada
          this.state.emergencyContactPhone !== "" && // added field from application canada
          this.state.applicatFirstReferencesName!== "" && // added field from application canada
          this.state.applicatFirstReferencesAddress!== "" && // added field from application canada
          this.state.applicatFirstReferencesCity!== "" && // added field from application canada
          this.state.applicatFirstReferencesProvince!== "" && // added field from application canada
          this.state.applicatFirstReferencesCountry!== "" && // added field from application canada
          this.state.applicatFirstReferencesTelephone!== "" && // added field from application canada
          this.state.applicatSecondReferencesName!== "" && // added field from application canada
          this.state.applicatSecondReferencesAddress!== "" && // added field from application canada
          this.state.applicatSecondReferencesCity!== "" && // added field from application canada
          this.state.applicatSecondReferencesCountry!== "" && // added field from application canada
          this.state.applicatSecondReferencesProvince!== "" && // added field from application canada
          this.state.applicatSecondReferencesTelephone!== "" && // added field from application canada
          this.checkMobileNext(this.state.emergencyContactPhone) && // added field from application canada
          // this.state.relativeNum !== "" &&                  // removed field from application canada
          // this.checkMobileNext(this.state.relativeNum) &&       // removed field from application canada
          this.state.occupant !== "" &&
          this.state.occupantChildren !== "" &&
          this.state.moveInDate !== "" &&
          this.state.leaseTerm !== ""
        ) {
          if (this.state.isJoinTenant) {
            if (this.state.joinTenantCount === 1) {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1)
              ) {
                await this.setState({ occupantsArray: [] });

                let x = true;
                for (let i = 1; i < this.state.occupant; i++) {
                  if (this.state["occupant" + i] === "") {
                    x = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i]);
                }
                await this.setState({ occupantChildrensArray: [] });
                let y = true;
                for (let i = 1; i < this.state.occupantChildren; i++) {
                  if (this.state["occupantChildren" + i] === "") {
                    y = false;
                  }
                  this.state.occupantChildrensArray.push(
                    this.state["occupantChildren" + i]
                  );
                }
                if (x) {
                  if (y) {
                    if (!this.state.mailCheck) {
                      if (this.props.UserData.username !== this.state.email) {
                        await isAvailableUser(this.state.email).then(
                          (response) => {
                            if (response.data) {
                              window.$("#mailCheck").modal("show");
                            } else {
                              this.setState({ next: false });
                              if (this.checkTab()) {
                                let newArray = this.state.tabCheck;
                                newArray[this.state.nowTab] = true;
                                this.setState({ tabCheck: newArray });

                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                              }
                            }
                          }
                        );
                      } else {
                        this.setState({ next: false });
                        if (this.checkTab()) {
                          let newArray = this.state.tabCheck;
                          newArray[this.state.nowTab] = true;
                          this.setState({ tabCheck: newArray });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                        }
                      }
                    } else {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                      }
                    }
                  }
                }
              }
            } else {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1) &&
                this.state.joinTenant2 !== "" &&
                this.checkEmailNext(this.state.joinTenant2)
              ) {
                await this.setState({ occupantsArray: [] });
                let x1 = true;
                for (let i1 = 1; i1 < this.state.occupant; i1++) {
                  if (this.state["occupant" + i1] === "") {
                    x1 = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i1]);
                }
                await this.setState({ occupantChildrensArray: [] });
                let y1 = true;
                for (let i1 = 1; i1 < this.state.occupantChildren; i1++) {
                  if (this.state["occupantChildren" + i1] === "") {
                    y1 = false;
                  }
                  this.state.occupantChildrensArray.push(
                    this.state["occupantChildren" + i1]
                  );
                }
                if (x1) {
                  if (y1) {
                    if (!this.state.mailCheck) {
                      if (this.props.UserData.username !== this.state.email) {
                        await isAvailableUser(this.state.email).then(
                          (response) => {
                            if (response.data) {
                              window.$("#mailCheck").modal("show");
                            } else {
                              this.setState({ next: false });
                              if (this.checkTab()) {
                                let newArray = this.state.tabCheck;
                                newArray[this.state.nowTab] = true;
                                this.setState({ tabCheck: newArray });
                                if (!this.nextCheck(this.state.nowTab + 1)) {
                                  this.setState({ nextEnable: false });
                                }
                                this.setState({
                                  nowTab: this.state.nowTab + 1,
                                });
                              }
                            }
                          }
                        );
                      } else {
                        this.setState({ next: false });
                        if (this.checkTab()) {
                          let newArray = this.state.tabCheck;
                          newArray[this.state.nowTab] = true;
                          this.setState({ tabCheck: newArray });

                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                        }
                      }
                    } else {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });

                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                      }
                    }
                  }
                }
              }
            }
          } else {
            await this.setState({ occupantsArray: [] });
            let x2 = true;
            for (let i2 = 1; i2 < this.state.occupant; i2++) {
              if (this.state["occupant" + i2] === "") {
                x2 = false;
              }
              this.state.occupantsArray.push(this.state["occupant" + i2]);
            }
            await this.setState({ occupantChildrensArray: [] });
            let y2 = true;
            for (let i2 = 1; i2 < this.state.occupantChildren; i2++) {
              if (this.state["occupantChildren" + i2] === "") {
                y2 = false;
              }
              this.state.occupantChildrensArray.push(
                this.state["occupantChildren" + i2]
              );
            }
            if (x2) {
              if (y2) {
                if (!this.state.mailCheck) {
                  if (this.props.UserData.username !== this.state.email) {
                    await isAvailableUser(this.state.email).then((response) => {
                      if (response.data) {
                        window.$("#mailCheck").modal("show");
                      } else {
                        this.setState({ next: false });
                        if (this.checkTab()) {
                          let newArray = this.state.tabCheck;
                          newArray[this.state.nowTab] = true;
                          this.setState({ tabCheck: newArray });
                          if (!this.nextCheck(this.state.nowTab + 1)) {
                            this.setState({ nextEnable: false });
                          }
                          this.setState({ nowTab: this.state.nowTab + 1 });
                        }
                      }
                    });
                  } else {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      this.setState({ tabCheck: newArray });
                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({ nowTab: this.state.nowTab + 1 });
                    }
                  }
                } else {
                  this.setState({ next: false });
                  if (this.checkTab()) {
                    let newArray = this.state.tabCheck;
                    newArray[this.state.nowTab] = true;
                    this.setState({ tabCheck: newArray });
                    if (!this.nextCheck(this.state.nowTab + 1)) {
                      this.setState({ nextEnable: false });
                    }
                    this.setState({ nowTab: this.state.nowTab + 1 });
                  }
                }
              }
            }
          }
        }
      } else if (this.state.nowTab === 2) {
        window.scrollTo(0, 0);
        if (
          this.state.address !== "" &&
          this.state.state !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.checkPeriodNext(this.state.from, this.state.to) &&
          this.state.from !== "" &&
          this.state.reasonForLeaving !== "" &&
          this.state.to !== "" &&
          this.state.propOwn !== ""
        ) {
          if (this.state.propOwn === "Rent") {
            if (
              this.state.presentLandlord !== "" &&
              this.state.rent !== "" &&
              // this.state.landLordEmail !== "" &&
              this.state.landLordMobile !== ""
              // this.state.unitType !== ""
            ) {
              if (this.state.preRentalInfo) {
                if (
                  this.state.preAddress !== "" &&
                  this.state.preState !== "" &&
                  this.state.preCity !== "" &&
                  this.state.preZip !== "" &&
                  this.state.preFrom !== "" &&
                  this.state.preReasonForLeaving !== "" &&
                  this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                  this.state.preTo !== "" &&
                  this.state.prePropOwn !== ""
                ) {
                  if (this.state.prePropOwn === "Rent") {
                    if (
                      this.state.preLandLord !== "" &&
                      this.state.preRent !== "" &&
                      // this.state.preLandLordEmail !== "" &&
                      this.state.preLandLordMobile !== ""
                      // this.state.preUnitType !== ""
                    ) {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });

                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        if (this.state.prePropOwn === "Own") {
                          this.setState({
                            preLandLord: "",
                            preLandLordEmail: "",
                            preRent: "",
                            preLandLordMobile: "",
                            preLandLordFax: "",
                            preUnitType: "",
                          });
                        }
                        if (this.state.propOwn === "Own") {
                          this.setState({
                            presentLandlord: "",
                            landLordEmail: "",
                            rent: "",
                            landLordMobile: "",
                            landLordFax: "",
                            unitType: "",
                          });
                        }
                      }
                    }
                  } else {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      this.setState({ tabCheck: newArray });

                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({ nowTab: this.state.nowTab + 1 });
                      if (this.state.prePropOwn === "Own") {
                        this.setState({
                          preLandLord: "",
                          preLandLordEmail: "",
                          preRent: "",
                          preLandLordMobile: "",
                          preLandLordFax: "",
                          preUnitType: "",
                        });
                      }
                      if (this.state.propOwn === "Own") {
                        this.setState({
                          presentLandlord: "",
                          landLordEmail: "",
                          rent: "",
                          landLordMobile: "",
                          landLordFax: "",
                          unitType: "",
                        });
                      }
                    }
                  }
                }
              } else {
                this.setState({ next: false });
                if (this.checkTab()) {
                  let newArray = this.state.tabCheck;
                  newArray[this.state.nowTab] = true;
                  this.setState({ tabCheck: newArray });

                  if (!this.nextCheck(this.state.nowTab + 1)) {
                    this.setState({ nextEnable: false });
                  }
                  this.setState({ nowTab: this.state.nowTab + 1 });
                  if (this.state.prePropOwn === "Own") {
                    this.setState({
                      preLandLord: "",
                      preLandLordEmail: "",
                      preRent: "",
                      preLandLordMobile: "",
                      preLandLordFax: "",
                      preUnitType: "",
                    });
                  }
                  if (this.state.propOwn === "Own") {
                    this.setState({
                      presentLandlord: "",
                      landLordEmail: "",
                      rent: "",
                      landLordMobile: "",
                      landLordFax: "",
                      unitType: "",
                    });
                  }
                }
              }
            }
          } else {
            if (this.state.preRentalInfo) {
              if (
                this.state.preAddress !== "" &&
                this.state.preState !== "" &&
                this.state.preCity !== "" &&
                this.state.preZip !== "" &&
                this.state.preFrom !== "" &&
                this.state.preReasonForLeaving !== "" &&
                this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                this.state.preTo !== "" &&
                this.state.prePropOwn !== ""
              ) {
                if (this.state.prePropOwn === "Rent") {
                  if (
                    this.state.preLandLord !== "" &&
                    this.state.preRent !== "" &&
                    // this.state.preLandLordEmail !== "" &&
                    this.state.preLandLordMobile !== ""
                    // this.state.preUnitType !== ""
                  ) {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({
                        tabCheck: newArray,
                        nowTab: this.state.nowTab + 1,
                        presentLandlord: "",
                        landLordEmail: "",
                        rent: "",
                        landLordMobile: "",
                        landLordFax: "",
                        unitType: "",
                      });
                      if (this.state.prePropOwn === "Own") {
                        this.setState({
                          preLandLord: "",
                          preLandLordEmail: "",
                          preRent: "",
                          preLandLordMobile: "",
                          preLandLordFax: "",
                          preUnitType: "",
                        });
                      }
                      if (this.state.propOwn === "Own") {
                        this.setState({
                          presentLandlord: "",
                          landLordEmail: "",
                          rent: "",
                          landLordMobile: "",
                          landLordFax: "",
                          unitType: "",
                        });
                      }
                    }
                  }
                } else {
                  this.setState({ next: false });
                  if (this.checkTab()) {
                    let newArray = this.state.tabCheck;
                    newArray[this.state.nowTab] = true;
                    if (!this.nextCheck(this.state.nowTab + 1)) {
                      this.setState({ nextEnable: false });
                    }
                    this.setState({
                      tabCheck: newArray,
                      nowTab: this.state.nowTab + 1,
                      presentLandlord: "",
                      landLordEmail: "",
                      rent: "",
                      landLordMobile: "",
                      landLordFax: "",
                      unitType: "",
                    });
                    if (this.state.prePropOwn === "Own") {
                      this.setState({
                        preLandLord: "",
                        preLandLordEmail: "",
                        preRent: "",
                        preLandLordMobile: "",
                        preLandLordFax: "",
                        preUnitType: "",
                      });
                    }
                    if (this.state.propOwn === "Own") {
                      this.setState({
                        presentLandlord: "",
                        landLordEmail: "",
                        rent: "",
                        landLordMobile: "",
                        landLordFax: "",
                        unitType: "",
                      });
                    }
                  }
                }
              }
            } else {
              this.setState({ next: false });
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                if (!this.nextCheck(this.state.nowTab + 1)) {
                  this.setState({ nextEnable: false });
                }
                this.setState({
                  tabCheck: newArray,
                  nowTab: this.state.nowTab + 1,
                  presentLandlord: "",
                  landLordEmail: "",
                  rent: "",
                  landLordMobile: "",
                  landLordFax: "",
                  unitType: "",
                });
                if (this.state.prePropOwn === "Own") {
                  this.setState({
                    preLandLord: "",
                    preLandLordEmail: "",
                    preRent: "",
                    preLandLordMobile: "",
                    preLandLordFax: "",
                    preUnitType: "",
                  });
                }
                if (this.state.propOwn === "Own") {
                  this.setState({
                    presentLandlord: "",
                    landLordEmail: "",
                    rent: "",
                    landLordMobile: "",
                    landLordFax: "",
                    unitType: "",
                  });
                }
              }
            }
          }
        }
      } else if (this.state.nowTab === 3) {
        window.scrollTo(0, 0);
        if (this.state.preEmploymentInfo) {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.state.employmentName !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            // this.state.employmentEmail !== "" &&
            // this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== "" &&
            this.state.preEmploymentCompany !== "" &&
            this.state.preEmploymentPhone !== "" &&
            this.state.preEmploymentName !== "" &&
            this.checkMobileNext(this.state.preEmploymentPhone) &&
            this.checkPeriodNext(
              this.state.preEmploymentFrom,
              this.state.preEmploymentUntil
            ) &&
            // this.state.preEmploymentEmail !== "" &&
            // this.checkEmailNext(this.state.preEmploymentEmail) &&
            this.state.preEmploymentZip !== "" &&
            this.state.preEmploymentCity !== "" &&
            this.state.preEmploymentState !== "" &&
            this.state.preEmploymentSalary !== "" &&
            this.state.preEmploymentPosition !== "" &&
            this.state.preEmploymentFrom !== "" &&
            this.state.preEmploymentUntil !== ""
          ) {
            this.setState({ next: false });

            if (this.checkTab()) {
              let newArray = this.state.tabCheck;
              newArray[this.state.nowTab] = true;
              this.setState({ tabCheck: newArray });
              if (!this.nextCheck(this.state.nowTab + 1)) {
                this.setState({ nextEnable: false });
              }
              this.setState({ nowTab: this.state.nowTab + 1 });
              this.getPdfBlob();
            }
          }
        } else {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            // this.state.employmentEmail !== "" &&
            // this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== ""
          ) {
            this.setState({ next: false });

            if (this.checkTab()) {
              let newArray = this.state.tabCheck;
              newArray[this.state.nowTab] = true;
              this.setState({ tabCheck: newArray });

              if (!this.nextCheck(this.state.nowTab + 1)) {
                this.setState({ nextEnable: false });
              }
              this.setState({ nowTab: this.state.nowTab + 1 });
              this.getPdfBlob();
            }
          }
        }
      }
    } else if (this.state.nowTab === 4) {
      window.scrollTo(0, 0);

      this.setState({ next: false });
      if (this.checkTab()) {
        let newArray = this.state.tabCheck;
        newArray[this.state.nowTab] = true;
        this.setState({ tabCheck: newArray });

        if (!this.nextCheck(this.state.nowTab + 1)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ nowTab: this.state.nowTab + 1 });
      }
    } else if (this.state.nowTab === 5) {
      window.scrollTo(0, 0);
      this.setState({ next: false });
      if (this.checkTab()) {
        let newArray = this.state.tabCheck;
        newArray[this.state.nowTab] = true;
        this.setState({ tabCheck: newArray });

        if (!this.nextCheck(this.state.nowTab + 1)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ nowTab: this.state.nowTab + 1 });
      }

      // this.setState({ stripe_account_status: "verified" });
    } else {
      window.scrollTo(0, 0);
      this.setState({ next: false });
      this.setState({ nowTab: 1 });
    }
  }
  async nextCheck(tab) {
    this.setState({ nextEnable: false });
    if (tab < 5) {
      if (tab === 0) {
        this.setState({ nextEnable: true });
        return true;
      } else if (tab === 1) {
        if (
          this.state.firstName !== "" &&
          this.state.lastName !== "" &&
          this.state.email !== "" &&
          this.checkEmailNext(this.state.email) &&
          // this.state.relativeName !== "" &&  // removed field from application canada
          this.state.dob !== "" &&
          this.state.drivingNo !== "" &&
          this.state.drivingNoProvince !== "" &&
          this.state.drivingNoValid !== "" &&
          this.state.securityNo !== "" &&
          this.checkSINNext(this.state.securityNo) &&
          this.state.mobile !== "" &&
          this.checkMobileNext(this.state.mobile) &&
          this.state.pet !== "" &&
          // this.state.relativeNum !== "" &&                     // removed field from application canada
          // this.checkMobileNext(this.state.relativeNum) &&      // removed field from application canada
          this.state.occupant !== "" &&
          this.state.occupantChildren !== "" &&
          this.state.moveInDate !== ""
          // this.state.leaseTerm !== ""   // removed field from application canada
        ) {
          if (this.state.isJoinTenant) {
            if (this.state.joinTenantCount === 1) {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1)
              ) {
                await this.setState({ occupantsArray: [] });
                let x = true;
                for (let i = 1; i < this.state.occupant; i++) {
                  if (this.state["occupant" + i] === "") {
                    x = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i]);
                }
                await this.setState({ occupantChildrensArray: [] });
                let y = true;
                for (let i = 1; i < this.state.occupantChildren; i++) {
                  if (this.state["occupantChildren" + i] === "") {
                    y = false;
                  }
                  this.state.occupantChildrensArray.push(
                    this.state["occupantChildren" + i]
                  );
                }

                if (x) {
                  if (y) {
                    if (!this.state.mailCheck) {
                      if (this.props.UserData.username !== this.state.email) {
                        await isAvailableUser(this.state.email).then(
                          (response) => {
                            if (response.data) {
                              window.$("#mailCheck").modal("show");
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        );
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    } else {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  }
                }
              }
            } else {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1) &&
                this.state.joinTenant2 !== "" &&
                this.checkEmailNext(this.state.joinTenant2)
              ) {
                await this.setState({ occupantsArray: [] });
                await this.setState({ occupantChildrensArray: [] });
                let x1 = true;
                for (let i1 = 1; i1 < this.state.occupant; i1++) {
                  if (this.state["occupant" + i1] === "") {
                    x1 = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i1]);
                }
                let y1 = true;
                for (let i1 = 1; i1 < this.state.occupantChildren; i1++) {
                  if (this.state["occupantChildren" + i1] === "") {
                    y1 = false;
                  }
                  this.state.occupantChildrensArray.push(
                    this.state["occupantChildren" + i1]
                  );
                }
                if (x1) {
                  if (y1) {
                    if (!this.state.mailCheck) {
                      if (this.props.UserData.username !== this.state.email) {
                        await isAvailableUser(this.state.email).then(
                          (response) => {
                            if (response.data) {
                              window.$("#mailCheck").modal("show");
                            } else {
                              this.setState({ nextEnable: true });
                              return true;
                            }
                          }
                        );
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    } else {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  }
                }
              }
            }
          } else {
            await this.setState({ occupantsArray: [] });
            let x2 = true;
            for (let i2 = 1; i2 < this.state.occupant; i2++) {
              if (this.state["occupant" + i2] === "") {
                x2 = false;
              }
              this.state.occupantsArray.push(this.state["occupant" + i2]);
            }
            let y2 = true;
            for (let i2 = 1; i2 < this.state.occupantChildren; i2++) {
              if (this.state["occupantChildren" + i2] === "") {
                y2 = false;
              }
              this.state.occupantChildrensArray.push(
                this.state["occupantChildren" + i2]
              );
            }
            if (x2) {
              if (y2) {
                if (!this.state.mailCheck) {
                  if (this.props.UserData.username !== this.state.email) {
                    await isAvailableUser(this.state.email).then((response) => {
                      if (response.data) {
                        window.$("#mailCheck").modal("show");
                      } else {
                        this.setState({ nextEnable: true });
                        return true;
                      }
                    });
                  } else {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                } else {
                  this.setState({ nextEnable: true });
                  return true;
                }
              }
            }
          }
        }
      } else if (tab === 2) {
        if (
          this.state.address !== "" &&
          this.state.state !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.checkPeriodNext(this.state.from, this.state.to) &&
          this.state.from !== "" &&
          this.state.reasonForLeaving !== "" &&
          this.state.to !== "" &&
          this.state.propOwn !== ""
        ) {
          if (this.state.propOwn === "Rent") {
            if (
              this.state.presentLandlord !== "" &&
              this.state.rent !== "" &&
              // this.state.landLordEmail !== "" &&
              this.state.landLordMobile !== ""
              // this.state.unitType !== ""
            ) {
              if (this.state.preRentalInfo) {
                if (
                  this.state.preAddress !== "" &&
                  this.state.preState !== "" &&
                  this.state.preCity !== "" &&
                  this.state.preZip !== "" &&
                  this.state.preFrom !== "" &&
                  this.state.preReasonForLeaving !== "" &&
                  this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                  this.state.preTo !== "" &&
                  this.state.prePropOwn !== ""
                ) {
                  if (this.state.prePropOwn === "Rent") {
                    if (
                      this.state.preLandLord !== "" &&
                      this.state.preRent !== "" &&
                      // this.state.preLandLordEmail !== "" &&
                      this.state.preLandLordMobile !== ""
                      // this.state.preUnitType !== ""
                    ) {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  } else {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                }
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            }
          } else {
            if (this.state.preRentalInfo) {
              if (
                this.state.preAddress !== "" &&
                this.state.preState !== "" &&
                this.state.preCity !== "" &&
                this.state.preZip !== "" &&
                this.state.preFrom !== "" &&
                this.state.preReasonForLeaving !== "" &&
                this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                this.state.preTo !== "" &&
                this.state.prePropOwn !== ""
              ) {
                if (this.state.prePropOwn === "Rent") {
                  if (
                    this.state.preLandLord !== "" &&
                    this.state.preRent !== "" &&
                    // this.state.preLandLordEmail !== "" &&
                    this.state.preLandLordMobile !== ""
                    // this.state.preUnitType !== ""
                  ) {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                } else {
                  this.setState({ nextEnable: true });
                  return true;
                }
              }
            } else {
              this.setState({ nextEnable: true });
              return true;
            }
          }
        }
      } else if (tab === 3) {
        if (this.state.preEmploymentInfo) {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.state.employmentName !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            // this.state.employmentEmail !== "" &&
            // this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== "" &&
            this.state.preEmploymentCompany !== "" &&
            this.state.preEmploymentPhone !== "" &&
            this.state.preEmploymentName !== "" &&
            this.checkMobileNext(this.state.preEmploymentPhone) &&
            this.checkPeriodNext(
              this.state.preEmploymentFrom,
              this.state.preEmploymentUntil
            ) &&
            // this.state.preEmploymentEmail !== "" &&
            // this.checkEmailNext(this.state.preEmploymentEmail) &&
            this.state.preEmploymentZip !== "" &&
            this.state.preEmploymentCity !== "" &&
            this.state.preEmploymentState !== "" &&
            this.state.preEmploymentSalary !== "" &&
            this.state.preEmploymentPosition !== "" &&
            this.state.preEmploymentFrom !== "" &&
            this.state.preEmploymentUntil !== ""
          ) {
            this.setState({ nextEnable: true });
            return true;
          }
        } else {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            // this.state.employmentEmail !== "" &&
            // this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== ""
          ) {
            this.setState({ nextEnable: true });
            return true;
          }
        }
      } else if (tab === 4 || tab === 5) {
        this.setState({ nextEnable: true });
        return true;
      }
    } else {
      this.setState({ nextEnable: true });
      return true;
    }
    return false;
  }
  finished(data) {
    this.setState({ isButtonDisableFinish: false, confirmLoader: true });

    if (this.checkTab()) {
      if (data === "verified" || this.state.paymentToken !== "") {
        try {
          let userSample = [
            {
              user: {
                username: this.state.email,
                password: "",
                enabled: false,
                accountNonExpired: true,
                credentialsNonExpired: false,
                accountNonLocked: true,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                middleName: this.state.middleName,
                address: {
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  lat: 0,
                  lng: 0,
                },
                mobileNumber: this.state.mobile,
                stripe_id: "",
                provider: "local",
                coverImage: "",
              },
              order: 1,
            },
          ];
          let applicantSample = [
            {
              applicant: {
                username: this.state.email,
                drivingLicNo: this.state.drivingNo,
                drivingLicIssuedProvince: this.state.drivingNoProvince,
                drivingLicValid: this.state.drivingNoValid,
                emergencyNo: this.state.relativeNum,
                ssn: this.state.securityNo,
                dob: this.state.dob,
                otherName: this.state.otherName,
                gender: this.state.applicantGender,
                resPresentAddress: this.state.address,
                resPresentCity: this.state.city,
                resPresentState: this.state.state,
                resPresentZip: this.state.zip,
                resPresentEmail: this.state.residentEmail,

                resPresentRent: this.state.rent
                  ? parseInt(this.state.rent).toFixed(2)
                  : 0.0,
                resPresentFrom: this.state.from,
                resPresentTo: this.state.to,

                resPresentPhone: this.state.residentPhone,
                resPresentFax: this.state.presentHomeFax,

                resPresentOwnRent: this.state.propOwn,
                resPresentLandlord:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.presentLandlord,
                resPresentLandlordEmail:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.landLordEmail,
                resPresentLandlordTp:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.landLordMobile,
                resPresentLandlordFax:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.landLordFax,
                resPresentApartment:
                  this.state.resPresentOwnRent === "Rent" &&
                  this.state.unitType === "Apartment"
                    ? true
                    : false,
                resPresentUnitType: this.state.unitType,
                resPresentMortgage:
                  this.state.resPresentOwnRent === "Rent" &&
                  this.state.unitType === "Mortgage"
                    ? true
                    : false,
                resPresentReasonLeaving: this.state.reasonForLeaving,
                resPresentLandlordIDProof: this.state.presentLandlordIDProof,

                resPreviousCity: this.state.preCity,
                resPreviousAddress: this.state.preAddress,
                resPreviousState: this.state.preState,
                resPreviousZip: this.state.preZip,
                resPreviousPhoneNumber: this.state.preResidentPhone,
                resPreviousEmail: this.state.preResidentEmail,
                resPreviousRent: this.state.preRent
                  ? parseInt(this.state.preRent).toFixed(2)
                  : 0.0,
                resPreviousFrom: this.state.preFrom,
                resPreviousTo: this.state.preTo,
                resPreviousOwnRent: this.state.prePropOwn,
                resPreviousLandlord: this.state.preLandLord,
                resPreviousLandlordEmail: this.state.preLandLordEmail,
                resPreviousLandlordTp: this.state.preLandLordMobile,
                resPreviousLandlordFax: this.state.preLandLordFax,
                resPreviousApartment:
                  this.state.resPreviousOwnRent === "Rent" &&
                  this.state.preUnitType === "Apartment"
                    ? true
                    : false,

                resPreviousUnitType: this.state.preUnitType,
                resPreviousMortgage:
                  this.state.resPreviousOwnRent === "Rent" &&
                  this.state.preUnitType === "Mortgage"
                    ? true
                    : false,
                resPreviousReasonLeaving: this.state.preReasonForLeaving,
                empPresentEmployed: this.state.employmentCompany,
                empPresentName:this.state.employmentName,
                empPresentEmail: this.state.employmentEmail,
                empPresentAddress: this.state.employmentAddress,
                empPresentCity: this.state.employmentCity,
                empPresentState: this.state.employmentState,
                empPresentZip: this.state.employmentZip,
                empPresentPhone: this.state.employmentPhone,
                empPresentSalary: this.state.employmentSalary
                  ? parseInt(this.state.employmentSalary).toFixed(2)
                  : 0.0,
                empPresentFrom: this.state.employmentFrom,
                empPresentTo: this.state.employmentUntil,
                empPresentPosition: this.state.employmentPosition,

                empPresentAnnualIncome: 0.0,

                empPreviousEmployed:
                  this.state.preEmploymentCompany === ""
                    ? null
                    : this.state.preEmploymentCompany,
                empPreviousName: this.state.preEmploymentName,
                empPreviousEmail: this.state.preEmploymentEmail,
                empPreviousAddress: this.state.preEmploymentAddress,
                empPreviousCity: this.state.preEmploymentCity,
                empPreviousState: this.state.preEmploymentState,
                empPreviousZip: this.state.preEmploymentZip,
                empPreviousPhone: this.state.preEmploymentPhone,
                empPreviousSalary: this.state.preEmploymentSalary
                  ? parseInt(this.state.preEmploymentSalary).toFixed(2)
                  : 0.0,
                empPreviousFrom: this.state.preEmploymentFrom,
                empPreviousTo: this.state.preEmploymentUntil,
                empPreviousPosition: this.state.preEmploymentPosition,
                empPreviousAnnualIncome: 0.0,
                emp2PreviousEmployed: null,
                emp2PreviousEmail: null,
                emp2PreviousAddress: this.state.preEmployment2Address,
                emp2PreviousCity: this.state.preEmployment2City,
                emp2PreviousState: this.state.preEmployment2State,
                emp2PreviousZip: this.state.preEmployment2Zip,
                emp2PreviousPhone: null,
                emp2PreviousSalary: 0.0,
                emp2PreviousFrom: this.state.preEmployment2From,
                emp2PreviousTo: this.state.preEmployment2Until,
                emp2PreviousPosition: this.state.preEmployment2Position,
                emp2PreviousAnnualIncome: 0.0,
                spousesEmployer: this.state.spousesEmployer,
                spousesEmployerAddress: this.state.spousesEmployerAddress,
                spousesIncome: this.state.spousesIncome
                  ? parseInt(this.state.spousesIncome).toFixed(2)
                  : 0.0,
                referenceName: this.state.applicantFirstReferencesName,
                referenceAddress: this.state.applicantFirstReferencesAddress,
                referenceTelephone: this.state.applicantFirstReferencesTelephone,
                referenceCity: this.state.applicantFirstReferencesCity,
                referenceProvince: this.state.applicantFirstReferencesProvince,
                referenceName2: this.state.applicantSecondReferencesName,
                referenceAddress2: this.state.applicantSecondReferencesAddress,
                referenceCity2: this.state.applicantSecondReferencesCity,
                referenceProvince2: this.state.applicantSecondReferencesProvince,
                referenceTelephone2: this.state.applicantSecondReferencesTelephone,
                emergencyContactName: this.state.emergencyContactName,
                emergencyContactPhone: this.state.emergencyContactPhone,
                emergencyContactStreet: this.state.emergencyContactStreet,
                emergencyContactCity: this.state.emergencyContactCity,
                emergencyContactProvince: this.state.emergencyContactProvince,
                emergencyContactPostalCode: this.state.emergencyContactPostalCode,
                emergencyContactFax: this.state.emergencyContactFax,
                emergencyContactRelation: this.state.emergencyContactRelation,
                primaryBankName: "",
                primaryBankBranch: "",
                primaryBankAccType: "",
                primaryBankAccNo: "",
                secondaryBankName: "",
                secondaryBankBranch: "",
                secondaryBankAccType: "",
                secondaryBankAccNo: "",
                relativeNum: this.state.relativeNum,
                relativeName: this.state.relativeName,
                additionalIncome: this.state.additionalIncome,
                bankruptcy: this.state.bankruptcy,
                crimeCheck: this.state.crimeCheck,
                evicted: this.state.evicted,
                smoke: this.state.smoke,
                homePhone: this.state.workingNo,
                resPresentYears: betweenYears(this.state.from, this.state.to),
                resPresentMonths: betweenExtraMonths(
                  this.state.to,
                  this.state.from
                ),
                resPreviousYear: betweenYears(
                  this.state.preFrom,
                  this.state.preTo
                ),
                resPreviousMonth: betweenExtraMonths(
                  this.state.preFrom,
                  this.state.preTo
                ),

                empPresentYears: betweenYears(
                  this.state.employmentFrom,
                  this.state.employmentUntil
                ),
                empPresentMonths: betweenExtraMonths(
                  this.state.employmentFrom,
                  this.state.employmentUntil
                ),
                empPreviousYear: betweenYears(
                  this.state.preEmploymentFrom,
                  this.state.preEmploymentUntil
                ),
                empPreviousMonth: betweenExtraMonths(
                  this.state.preEmploymentFrom,
                  this.state.preEmploymentUntil
                ),
                vehicle: this.state.vehicles === "" ? false : true,
                vehicleType: this.state.vehicles,
                vehicleMadeBy: this.state.applicantVehicleMadeBy,
                vehicleModel: this.state.applicantVehicleModel,
                vehicleColour: this.state.applicantVehicleColor,
                vehicleYear: this.state.applicantVehicleYear,
                vehicleLicenseNumber: this.state.applicantVehicleLicensePlateNumber,
                vehicleLicenseNumberplateIssuedProvince: this.state.applicantVehicleLicensePlateProvince,
                // createdAt: "2021-09-17T17:45:05.546+0000",
                // updatedAt: "2021-09-17T17:45:05.546+0000",
              },
              applicantOrder: 1,
            },
          ];
          if (this.state.joinTenantCount === 1) {
            userSample.push({
              user: {
                username: this.state.joinTenant1,
                password: "",
                enabled: false,
                accountNonExpired: true,
                credentialsNonExpired: false,
                accountNonLocked: true,
                firstName: "",
                lastName: "",
                middleName: "",
                address: {
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  lat: 0,
                  lng: 0,
                },
                mobileNumber: "",
                stripe_id: "",
                provider: "local",
                coverImage: "",
              },
              order: 2,
            });
            applicantSample.push({
              applicant: {
                username: this.state.joinTenant1,
                apply: null,
                drivingLicNo: 0.0,
                emergencyNo: null,
                ssn: 0.0,
                dob: null,
                otherName: null,
                gender: "",
                resPresentAddress: "",
                resPresentCity: "",
                resPresentState: "",
                resPresentZip: "",
                resPresentRent: 0.0,
                resPresentFrom: "",
                resPresentTo: "",
                resPresentPhone: "",
                resPresentFax: "",
                resPresentOwnRent: 0.0,
                resPresentLandlord: "",
                resPresentLandlordEmail: "",
                resPresentLandlordTp: "",
                resPresentLandlordFax: null,
                resPresentApartment: null,
                resPresentUnitType: "",
                resPresentMortgage: false,
                resPresentReasonLeaving: "",
                resPreviousAddress: "",
                resPreviousCity: "",
                resPreviousState: "",
                resPreviousZip: "",
                resPreviousRent: 0.0,
                resPreviousFrom: "",
                resPreviousTo: "",
                resPreviousOwnRent: 0.0,
                resPreviousLandlordEmail: null,
                resPreviousLandlordTp: "",
                resPreviousLandlordFax: null,
                resPreviousApartment: null,
                resPreviousUnitType: "",
                resPreviousMortgage: false,
                resPreviousReasonLeaving: "",
                empPresentEmployed: null,
                empPresentEmail: null,
                empPresentAddress: "",
                empPresentCity: "",
                empPresentState: "",
                empPresentZip: "",
                empPresentPhone: "",
                empPresentSalary: 0.0,
                empPresentFrom: "",
                empPresentTo: "",
                empPresentPosition: "",
                empPresentAnnualIncome: 0.0,

                empPreviousEmployed: null,
                empPreviousEmail: null,
                empPreviousAddress: "",
                empPreviousCity: "",
                empPreviousState: "",
                empPreviousZip: "",
                empPreviousPhone: null,
                empPreviousSalary: 0.0,
                empPreviousFrom: "",
                empPreviousTo: "",
                empPreviousPosition: "",
                empPreviousAnnualIncome: 0.0,
                emp2PreviousEmployed: null,
                emp2PreviousEmail: null,
                emp2PreviousAddress: "",
                emp2PreviousCity: "",
                emp2PreviousState: "",
                emp2PreviousZip: "",
                emp2PreviousPhone: null,
                emp2PreviousSalary: 0.0,
                emp2PreviousFrom: "",
                emp2PreviousTo: "",
                emp2PreviousPosition: "",
                emp2PreviousAnnualIncome: 0.0,
                spousesEmployer: "",
                spousesEmployerAddress: "",
                spousesIncome: 0.0,
                referenceName: "",
                referenceAddress: "",
                referenceTelephone: "",
                referenceName2: "",
                referenceAddress2: "",
                referenceTelephone2: "",
                emergencyContactName: "",
                emergencyContactPhone: "",
                emergencyContactFax: "",
                emergencyContactRelation: "",
                primaryBankName: "",
                primaryBankBranch: "",
                primaryBankAccType: "",
                primaryBankAccNo: "",
                secondaryBankName: "",
                secondaryBankBranch: "",
                secondaryBankAccType: "",
                secondaryBankAccNo: "",
                relativeNum: null,
                relativeName: null,
                additionalIncome: null,
                bankruptcy: false,
                crimeCheck: false,
                evicted: false,
                smoke: false,
                homePhone: null,
                resPresentYears: null,
                resPresentMonths: null,
                resPreviousYear: null,
                resPreviousMonth: null,
                empPresentYears: null,
                empPresentMonths: null,
                empPreviousYear: null,
                empPreviousMonth: null,
                vehicle: "",
                vehicleType: null,
                vehicleMadeBy: "",
                vehicleModel: "",
                vehicleYear: "",
                vehicleLicenseNumber: "",
              },
              applicantOrder: 2,
            });
          }
          if (this.state.joinTenantCount === 2) {
            userSample.push({
              user: {
                username: this.state.joinTenant2,
                password: "",
                enabled: false,
                accountNonExpired: true,
                credentialsNonExpired: false,
                accountNonLocked: true,
                firstName: "",
                lastName: "",
                middleName: "",
                address: {
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  lat: 0,
                  lng: 0,
                },
                mobileNumber: "",
                stripe_id: "",
                provider: "local",
                coverImage: "",
              },
              order: 3,
            });
            applicantSample.push({
              applicant: {
                username: this.state.joinTenant1,
                apply: null,
                drivingLicNo: 0.0,
                emergencyNo: null,
                ssn: 0.0,
                dob: null,
                otherName: null,
                gender: "",
                resPresentAddress: "",
                resPresentCity: "",
                resPresentState: "",
                resPresentZip: "",
                resPresentRent: 0.0,
                resPresentFrom: "",
                resPresentTo: "",
                resPresentPhone: "",
                resPresentFax: "",
                resPresentOwnRent: 0.0,
                resPresentLandlord: "",
                resPresentLandlordEmail: "",
                resPresentLandlordTp: "",
                resPresentLandlordFax: null,
                resPresentApartment: null,
                resPresentUnitType: "",
                resPresentMortgage: false,
                resPresentReasonLeaving: "",
                resPreviousAddress: "",
                resPreviousCity: "",
                resPreviousState: "",
                resPreviousZip: "",
                resPreviousRent: 0.0,
                resPreviousFrom: "",
                resPreviousTo: "",
                resPreviousOwnRent: 0.0,
                resPreviousLandlordEmail: null,
                resPreviousLandlordTp: "",
                resPreviousLandlordFax: null,
                resPreviousApartment: null,
                resPreviousUnitType: "",
                resPreviousMortgage: false,
                resPreviousReasonLeaving: "",
                empPresentEmployed: null,
                empPresentEmail: null,
                empPresentAddress: "",
                empPresentCity: "",
                empPresentState: "",
                empPresentZip: "",
                empPresentPhone: "",
                empPresentSalary: 0.0,
                empPresentFrom: "",
                empPresentTo: "",
                empPresentPosition: "",
                empPresentAnnualIncome: 0.0,

                empPreviousEmployed: null,
                empPreviousEmail: null,
                empPreviousAddress: "",
                empPreviousCity: "",
                empPreviousState: "",
                empPreviousZip: "",
                empPreviousPhone: null,
                empPreviousSalary: 0.0,
                empPreviousFrom: "",
                empPreviousTo: "",
                empPreviousPosition: "",
                empPreviousAnnualIncome: 0.0,
                emp2PreviousEmployed: null,
                emp2PreviousEmail: null,
                emp2PreviousAddress: "",
                emp2PreviousCity: "",
                emp2PreviousState: "",
                emp2PreviousZip: "",
                emp2PreviousPhone: null,
                emp2PreviousSalary: 0.0,
                emp2PreviousFrom: "",
                emp2PreviousTo: "",
                emp2PreviousPosition: "",
                emp2PreviousAnnualIncome: 0.0,
                spousesEmployer: "",
                spousesEmployerAddress: "",
                spousesIncome: 0.0,
                referenceName: "",
                referenceAddress: "",
                referenceTelephone: "",
                referenceName2: "",
                referenceAddress2: "",
                referenceTelephone2: "",
                emergencyContactName: "",
                emergencyContactPhone: "",
                emergencyContactFax: "",
                emergencyContactRelation: "",
                primaryBankName: "",
                primaryBankBranch: "",
                primaryBankAccType: "",
                primaryBankAccNo: "",
                secondaryBankName: "",
                secondaryBankBranch: "",
                secondaryBankAccType: "",
                secondaryBankAccNo: "",
                relativeNum: null,
                relativeName: null,
                additionalIncome: null,
                bankruptcy: false,
                crimeCheck: false,
                evicted: false,
                smoke: false,
                homePhone: null,
                resPresentYears: null,
                resPresentMonths: null,
                resPreviousYear: null,
                resPreviousMonth: null,
                empPresentYears: null,
                empPresentMonths: null,
                empPreviousYear: null,
                empPreviousMonth: null,
                vehicle: "",
                vehicleType: null,
                vehicleMadeBy: "",
                vehicleModel: "",
                vehicleYear: "",
                vehicleLicenseNumber: "",
              },
              applicantOrder: 3,
            });
          }
          let body = {
            users: userSample,
            application: {
              apply: applicantSample,
              occupants: [],
              occupantChildrens: [],
              rentalEntityId: this.state.rentalUnit,
              rent: this.props.history.location.state
                ? this.props.history.location.state.rent
                : 0.0,
              pets: this.state.pet === "true" ? true : false,
              petCount: this.state.pet === "true" ? this.state.petCount : 0,
              petBreed: this.state.pet === "true" ? this.state.petBreed : null, //newly added in canada application
              petHeight:
                this.state.pet === "true" ? this.state.petHeight : null, //newly added in canada application
              petWeight:
                this.state.pet === "true" ? this.state.petWeight : null, //newly added in canada application
              creditCheck: null,
              securityCheck: null,
              applicationForm: null,
              occupant: this.state.occupantsArray
                ? this.state.occupantsArray.toString()
                : "",
              occupantChildren: this.state.occupantChildrensArray
                ? this.state.occupantChildrensArray.toString()
                : "",
              leaseTerm: parseInt(this.state.leaseTerm),
              moveInDate: this.state.moveInDate,
              hydro: false,
              gas: false,
              heat: false,
              hotWaterHeater: false,
              hotWaterTank: false,
              internet: false,
              cableTv: false,
              other: false,
              water: false,
              status: null,
              totalOccupants: this.state.occupant
                ? parseInt(this.state.occupant)
                : "",
              totalOccupantsChildren: this.state.occupantChildren
                ? parseInt(this.state.occupantChildren)
                : "",
            },
            applicationFeeModel: {},
            token: "tok_visa",
            signature: true,
            file: this.state.signatureObject2,
          };
          if (this.props.role === "Guest") {
            let body_guest = {
              apartmentData: this.state.apartmentData,

              application: {
                apply: applicantSample,
                occupants: null,
                occupantChildrens: null,
                rentalEntityId: this.state.rentalUnit,
                rent: this.state.apartmentData
                  ? this.state.apartmentData.price
                  : "",
                pets: this.state.pet === "true" ? true : false,
                petCount: this.state.pet === "true" ? this.state.petCount : 0,
                petBreed: this.state.pet === "true" ? this.state.petBreed : "", //newly added in canada application
                petHeight:
                  this.state.pet === "true" ? this.state.petHeight : "", //newly added in canada application
                petWeight:
                  this.state.pet === "true" ? this.state.petWeight : "", //newly added in canada application
                creditCheck: null,
                securityCheck: null,
                applicationForm: null,
                occupant: this.state.occupantsArray
                  ? this.state.occupantsArray.toString()
                  : "",
                occupantChildren: this.state.occupantChildrensArray
                  ? this.state.occupantChildrensArray.toString()
                  : "",
                leaseTerm: parseInt(this.state.leaseTerm),
                moveInDate: this.state.moveInDate,
                hydro: false,
                gas: false,
                heat: false,
                hotWaterHeater: false,
                hotWaterTank: false,
                internet: false,
                cableTv: false,
                other: false,
                water: false,
                status: null,
                totalOccupants: this.state.occupant
                  ? parseInt(this.state.occupant)
                  : "",
                totalOccupantChildrens: this.state.occupantChildren
                  ? parseInt(this.state.occupantChildren)
                  : "",
              },
              user: userSample,
              applicationFeeModel: {
                // amount: 4000,
                // userName: this.state.email,
                // customerId: "cus_JFJjwfszSGfEQn",
                // contractId: 0,
                // applicationId: null,
              },
              token: this.state.paymentToken.id,
              signature: true,
              file: this.state.signatureObject2,
            };

            addPaymentMethodGuest(body_guest)
              .then((response) => {
                this.setState({ isButtonDisableFinish: true });
                this.setState({ confirmLoader: false });

                if (response.status === 200) {
                  window.onbeforeunload = "";
                  if (response.status) {
                    let message = response.data.data;
                    this.props.appendAlert(
                      "Application Sent Successfully",
                      "success"
                    );

                    if (message === "Already Applied") {
                      this.props.appendAlert(
                        String("This application is" + message),
                        "warning"
                      );
                    } else {
                      if (parseInt(this.state.applicationFee) > 0) {
                        this.props.appendAlert("Paid Successfully", "success");
                      }
                    }

                    this.props.history.push({ pathname: "/applications" });
                  }
                } else if (response.response.status === 500) {
                  if (
                    response.response.data.message === "No such token: 'null'"
                  ) {
                    this.props.appendAlert("please try again", "warning");
                  }
                  this.props.appendAlert(
                    "Application creation failed",
                    "danger"
                  );
                } else if (response.response.status === 402) {
                  this.changeTokenNull();
                  this.setState({ nowTab: 4 });
                  this.props.appendAlert(response.response.data.message);
                } else {
                  this.props.appendAlert(
                    "Application creation failed ",
                    "danger"
                  );
                }
              })
              .catch((error) => {
                this.setState({ confirmLoader: false });

                this.setState({ isButtonDisableFinish: true });
                console.log("Error" + error);
              });
          } else {
            setBritishColumbiaApplicationV2(body).then(async (response) => {
              await window.$("#finishedModal").modal("hide");
              this.setState({ isButtonDisableFinish: true });

              if (response.status === 200) {
                window.onbeforeunload = "";
                if (response.status) {
                  let message = response.data.data;
                  this.props.appendAlert(
                    "Application Sent Successfully",
                    "success"
                  );
                  if (message === "Already Applied") {
                    this.props.appendAlert(
                      String("This application is" + message),
                      "warning"
                    );
                  } else {
                    if (parseInt(this.state.applicationFee) > 0) {
                      this.props.appendAlert("Paid Successfully", "success");
                    }
                  }

                  this.props.history.push({ pathname: "/applications" });
                }
              } else if (response.response.status === 500) {
                if (
                  response.response.data.message === "No such token: 'null'"
                ) {
                  this.props.appendAlert("please try again", "warning");
                }
                this.props.appendAlert("Application creation failed", "danger");
              } else if (response.response.status === 402) {
                this.setPaymentToken();
                this.setState({ nowTab: 4 });
                this.props.appendAlert(
                  response.response.data.message,
                  "warning"
                );
              } else {
                this.props.appendAlert("Application creation failed", "danger");
              }
            });
          }
        } catch (error) {
          this.setState({ confirmLoader: false });
          window.$("#finishedModal").modal("hide");
        }
      } else {
        this.props.appendAlert("Please add card", "success");
        // this.setState({ nowTab: this.state.nowTab - 1 });
        this.setState({ confirmLoader: false });
        this.setState({ isButtonDisableFinish: true, confirmLoader: false });
        window.$("#finishedModal").modal("hide");
      }
    }
  }

  back() {
    if (this.state.nowTab > 0) {
      window.scrollTo(0, 0);
      if (!this.nextCheck(this.state.nowTab - 1)) {
        this.setState({ nextEnable: false });
      }
      this.setState({ nowTab: this.state.nowTab - 1 });
    }
    if (this.state.nowTab === 2) {
      this.setState({ mailCheck: false });
    }
    if (this.state.nowTab === 5) {
      window.scrollTo(0, 0);
      if (
        this.state.stripe_account_status.data === "verified"
        // this.state.paymentToken !== ""
      ) {
        if (!this.nextCheck(this.state.nowTab - 2)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ nowTab: this.state.nowTab - 2 });
      } else {
        if (!this.nextCheck(this.state.nowTab - 1)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ nowTab: this.state.nowTab - 1 });
      }
    }
  }

  async onchange(e) {
    let { name, value } = e.target;

    if (name === "occupant") {
      this.getOccupant(this.state.occupant, value);
      await this.setState({ [name]: value });
    } else if (name === "occupantChildren") {
      this.getOccupantChildren(this.state.occupantChildren, value);
      await this.setState({ [name]: value });
    } else if (
      name === "mobile" ||
      name === "workingNo" ||
      name === "relativeNum" ||
      name === "employmentPhone" ||
      name === "preEmploymentPhone" ||
      name === "landLordMobile" ||
      name === "emergencyContactPhone" ||
      name === "applicantFirstReferencesTelephone" ||
      name === "applicantSecondReferencesTelephone" ||
      name === "residentPhone" ||
      name === "preResidentPhone" ||
      name === "preLandLordMobile"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (
      name === "firstName" ||
      name === "middleName" ||
      name === "lastName" ||
      name === "otherName" ||
      name === "relativeName" ||
      name === "presentLandlord" ||
      name === "preLandLord" ||
      name === "occupant1" ||
      name === "occupant2" ||
      name === "occupant3" ||
      name === "occupant3" ||
      name === "occupant4" ||
      name === "occupant5" ||
      name === "occupant6" ||
      name === "occupant7" ||
      name === "occupant8" ||
      name === "occupant9" ||
      name === "occupant10" ||
      name === "occupant11" ||
      name === "occupant12" ||
      name === "occupant12" ||
      name === "occupant13" ||
      name === "occupant14" ||
      name === "occupantChildren1" ||
      name === "occupantChildren2" ||
      name === "occupantChildren3" ||
      name === "occupantChildren3" ||
      name === "occupantChildren4" ||
      name === "occupantChildren5" ||
      name === "occupantChildren6" ||
      name === "occupantChildren7" ||
      name === "occupantChildren8" ||
      name === "occupantChildren9" ||
      name === "occupantChildren10" ||
      name === "occupantChildren11" ||
      name === "occupantChildren12" ||
      name === "occupantChildren12" ||
      name === "occupantChildren13" ||
      name === "occupantChildren14" ||
      name === "city" ||
      name === "country" ||
      name === "state" ||
      name === "preCity" ||
      name === "preState" ||
      name === "preCountry" ||
      name === "employmentCity" ||
      name === "employmentState" ||
      name === "employmentCountry" ||
      name === "preEmploymentCity" ||
      name === "preEmploymentState" ||
      name === "preEmploymentCountry"
    ) {
      await this.setState({
        [name]: value.replace(/[^a-zA-Z/\s/g\s,\s.\s-]+/g, ""),
      });
    } else if (name === "state") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    }else if (name === "applicantFirstReferencesProvince") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateRef1Json.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityRef1Json: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityListRef1: cityList });
        this.setState({ applicantFirstReferencesCity: "" });
      } else {
        this.setState({ applicantFirstReferencesCity: "" });
        this.setState({ cityListRef1: [] });
      }
    }else if (name === "applicantSecondReferencesProvince") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateRef2Json.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityRef2Json: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityListRef2: cityList });
        this.setState({ applicantSecondReferencesCity: "" });
      } else {
        this.setState({ applicantSecondReferencesCity: "" });
        this.setState({ cityListRef2: [] });
      }
    }else if (name === "emergencyContactProvince") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateEmergencyJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityEmergencyJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityListEmergency: cityList });
        this.setState({ emergencyContactCity: "" });
      } else {
        this.setState({ emergencyContactCity: "" });
        this.setState({ cityListEmergency: [] });
      }
    }else if (name === "country") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateList: stateList });
        this.setState({ state: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state: "" });
        this.setState({ city: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
      }
    }else if (name === "applicantFirstReferencesCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryRef1Json.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateRef1Json: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateListRef1: stateList });
        this.setState({ applicantFirstReferencesState: "" });
        this.setState({ applicantFirstReferencesCity: "" });
      } else {
        this.setState({ applicantFirstReferencesState: "" });
        this.setState({ applicantFirstReferencesCity: "" });
        this.setState({ stateListRef1: [] });
        this.setState({ cityListRef1: [] });
      }
    }else if (name === "applicantSecondReferencesCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryRef2Json.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateRef2Json: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateListRef2: stateList });
        this.setState({ applicantSecondReferencesState: "" });
        this.setState({ applicantSecondReferencesCity: "" });
      } else {
        this.setState({ applicantSecondReferencesState: "" });
        this.setState({ applicantSecondReferencesCity: "" });
        this.setState({ stateListRef2: [] });
        this.setState({ cityListRef2: [] });
      }
    }else if (name === "emergencyContactCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryEmergencyJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateEmergencyJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateListEmergency: stateList });
        this.setState({ emergencyContactProvince: "" });
        this.setState({ emergencyContactCity: "" });
      } else {
        this.setState({ emergencyContactProvince: "" });
        this.setState({ emergencyContactCity: "" });
        this.setState({ stateListEmergency: [] });
        this.setState({ cityListEmergency: [] });
      }
    }   else if (name === "preState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.preStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preCityList: cityList });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preCity: "" });
        this.setState({ preCityList: [] });
      }
    } else if (name === "preCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.preCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preStateList: stateList });
        this.setState({ preState: "" });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preState: "" });
        this.setState({ preCity: "" });
        this.setState({ preStateList: [] });
        this.setState({ preCityList: [] });
      }
    } else if (name === "employmentState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.employmentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ employmentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ employmentCityList: cityList });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentCity: "" });
        this.setState({ employmentCityList: [] });
      }
    } else if (name === "employmentCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.employmentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ employmentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ employmentStateList: stateList });
        this.setState({ employmentState: "" });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentState: "" });
        this.setState({ employmentCity: "" });
        this.setState({ employmentStateList: [] });
        this.setState({ employmentCityList: [] });
      }
    } else if (name === "preEmploymentState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.preEmploymentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preEmploymentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preEmploymentCityList: cityList });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentCity: "" });
        this.setState({ preEmploymentCityList: [] });
      }
    } else if (name === "preEmploymentCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.preEmploymentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preEmploymentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preEmploymentStateList: stateList });
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentCity: "" });
        this.setState({ preEmploymentStateList: [] });
        this.setState({ preEmploymentCityList: [] });
      }
    } else if (name === "propOwn") {
      this.setState({
        presentLandlord: "",
        landLordEmail: "",
        rent: "",
        landLordMobile: "",
        landLordFax: "",
        unitType: "",
        next: false,
      });
      await this.setState({ [name]: value });
    } else if (name === "prePropOwn") {
      this.setState({
        preLandLord: "",
        preLandLordEmail: "",
        preRent: "",
        preLandLordMobile: "",
        preLandLordFax: "",
        preUnitType: "",
        next: false,
      });
      await this.setState({ [name]: value });
    } else if (name === "securityNo") {
      let sin = value.replace(/[^\d]/g, "");
      value = sin;
      if (sin.length > 6) {
        value = sin.replace(/(\d{3})(\d{3})/, "$1 $2 ");
      } else if (sin.length > 4) {
        value = sin.replace(/(\d{3})/, "$1 ");
      }
      if (sin.length > 9) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (name === "reasonForLeaving" || name === "preReasonForLeaving") {
      if (value.length > 90) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
    this.nextCheck(this.state.nowTab);
  }
  async changeState(name, value) {
    await this.setState({ [name]: value });
    if (name === "state") {
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    }else if (name === "applicantFirstReferencesProvince") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateRef1Json.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityRef1Json: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityListRef1: cityList });
        this.setState({ applicantFirstReferencesCity: "" });
      } else {
        this.setState({ applicantFirstReferencesCity: "" });
        this.setState({ cityListRef1: [] });
      }
    }else if (name === "applicantSecondReferencesProvince") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateRef2Json.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityRef2Json: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityListRef2: cityList });
        this.setState({ applicantSecondReferencesCity: "" });
      } else {
        this.setState({ applicantSecondReferencesCity: "" });
        this.setState({ cityListRef2: [] });
      }
    }else if (name === "emergencyContactProvince") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateEmergencyJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityEmergencyJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityListEmergency: cityList });
        this.setState({ emergencyContactCity: "" });
      } else {
        this.setState({ emergencyContactCity: "" });
        this.setState({ cityListEmergency: [] });
      }
    } else if (name === "preState") {
      let selectedCities = this.state.preStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preCityList: cityList });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preCity: "" });
        this.setState({ preCityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateList: stateList });
        this.setState({ state: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
        this.setState({ city: "" });
      }
    }else if (name === "applicantFirstReferencesCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryRef1Json.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateRef1Json: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateListRef1: stateList });
        this.setState({ applicantFirstReferencesState: "" });
        this.setState({ applicantFirstReferencesCity: "" });
      } else {
        this.setState({ applicantFirstReferencesState: "" });
        this.setState({ applicantFirstReferencesCity: "" });
        this.setState({ stateListRef1: [] });
        this.setState({ cityListRef1: [] });
      }
    }else if (name === "applicantSecondReferencesCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryRef2Json.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateRef2Json: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateListRef2: stateList });
        this.setState({ applicantSecondReferencesState: "" });
        this.setState({ applicantSecondReferencesCity: "" });
      } else {
        this.setState({ applicantSecondReferencesState: "" });
        this.setState({ applicantSecondReferencesCity: "" });
        this.setState({ stateListRef2: [] });
        this.setState({ cityListRef2: [] });
      }
    }else if (name === "emergencyContactCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryEmergencyJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateEmergencyJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateListEmergency: stateList });
        this.setState({ emergencyContactProvince: "" });
        this.setState({ emergencyContactCity: "" });
      } else {
        this.setState({ emergencyContactProvince: "" });
        this.setState({ emergencyContactCity: "" });
        this.setState({ stateListEmergency: [] });
        this.setState({ cityListEmergency: [] });
      }
    }  else if (name === "preCountry") {
      let selectedCountry = this.state.preCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preStateList: stateList });
        this.setState({ preState: "" });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preState: "" });
        this.setState({ preStateList: [] });
        this.setState({ preCityList: [] });
        this.setState({ preCity: "" });
      }
    } else if (name === "employmentState") {
      let selectedCities = this.state.employmentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ employmentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ employmentCityList: cityList });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentCity: "" });
        this.setState({ employmentCityList: [] });
      }
    } else if (name === "preEmploymentState") {
      let selectedCities = this.state.preEmploymentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preEmploymentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preEmploymentCityList: cityList });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentCity: "" });
        this.setState({ preEmploymentCityList: [] });
      }
    } else if (name === "employmentCountry") {
      let selectedCountry = this.state.employmentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ employmentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ employmentStateList: stateList });
        this.setState({ employmentState: "" });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentState: "" });
        this.setState({ employmentStateList: [] });
        this.setState({ employmentCityList: [] });
        this.setState({ employmentCity: "" });
      }
    } else if (name === "preEmploymentCountry") {
      let selectedCountry = this.state.preEmploymentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preEmploymentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preEmploymentStateList: stateList });
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentStateList: [] });
        this.setState({ preEmploymentCityList: [] });
        this.setState({ preEmploymentCity: "" });
      }
    }
    this.nextCheck(this.state.nowTab);
  }
  async onchangeState(e) {
    let { name, value } = e.target;
    if (
      name === "mobile" ||
      name === "workingNo" ||
      name === "relativeNum" ||
      name === "employmentPhone" ||
      name === "preEmploymentPhone" ||
      name === "landLordMobile" ||
      name === "preLandLordMobile"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
    this.nextCheck(this.state.nowTab);
  }
  checkEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      (!re.test(String(value).toLowerCase()) || value === "") && this.state.next
    );
  }

  checkEmailNext(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return !(!re.test(String(value).toLowerCase()) || value === "");
  }

  checkPeriod(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    return (
      (date2.getTime() - date1.getTime() <= 0 || d1 === "" || d2 === "") &&
      this.state.next
    );
  }
  checkPeriodNext(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    if (d1 === "" || d2 === "") {
      return true;
    }
    return date2.getTime() - date1.getTime() > 0;
  }
  checkMobile(value) {
    return (value.length !== 14 || value === "") && this.state.next;
  }
  checkMobileNext(value) {
    return value.length === 14;
  }
  getOccupant(occ, val) {
    let x = [];
    for (let i = 1; i < parseInt(val); i++) {
      x.push("occupant" + i);
    }
    for (let j = 1; j < parseInt(occ); j++) {
      this.setState({ ["occupant" + j]: "" });
    }
    this.setState({ occupantArray: x });
  }
  getOccupantChildren(occ, val) {
    let x = [];
    for (let i = 0; i < parseInt(val); i++) {
      x.push("occupantChildren" + i);
    }
    for (let j = 0; j < parseInt(occ); j++) {
      this.setState({ ["occupantChildren" + j]: "" });
    }
    this.setState({ occupantChildrenArray: x });
  }
  checkSIN(value) {
    return (value.length !== 11 || value === "") && this.state.next;
  }
  checkSINNext(value) {
    return value.length === 11;
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  async onChangeParent(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{ minHeight: window.screen.height }}
      >
        {this.state.nowTab !== 0 ? (
          <>
            <div className="row stepFormNav">
              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(1)}
              >
                <div className="row">
                  <div className="col SideLineHide"></div>
                  <div className="col">
                    <div
                      className={
                        this.state.nowTab !== 0
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Basic"
                    >
                      <div className="social ">
                        <i
                          className="fa fa-address-card-o "
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Basic"
                    >
                      step 1
                    </span>
                    <br></br>
                  </div>
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "col  SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                </div>
              </div>
              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(2)}
              >
                <div className="row">
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                  <div className="col">
                    <div
                      className={
                        this.state.nowTab > 1
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Residence History"
                    >
                      <div className="social ">
                        <i
                          className="fa fa-address-book "
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Residence History"
                    >
                      step 2
                    </span>
                    <br></br>
                  </div>
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                </div>
              </div>

              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(3)}
              >
                <div className="row">
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                  <div className="col ">
                    <div
                      className={
                        this.state.nowTab > 2
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Employment Data"
                    >
                      <div className="social ">
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Employment Data"
                    >
                      step 3
                    </span>
                    <br></br>
                  </div>
                  <div
                    className={
                      this.state.nowTab > 3
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                </div>
              </div>

              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(4)}
              >
                <div className="row">
                  <div
                    className={
                      this.state.nowTab > 3
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>

                  <div className="col">
                    <div
                      className={
                        this.state.nowTab > 3
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Review"
                    >
                      <div className="social">
                        <i className="fa fa-retweet " aria-hidden="true"></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Review"
                    >
                      step 4
                    </span>
                    <br></br>
                  </div>
                  {this.state.stripe_account_status.data !== "verified" ? (
                    <div
                      className={
                        this.state.nowTab > 4
                          ? "col SideLine activeLine"
                          : "col SideLine"
                      }
                    ></div>
                  ) : (
                    <div className="col SideLineHide"></div>
                  )}
                </div>
              </div>
              {this.state.stripe_account_status.data !== "verified" && (
                <div
                  className="col text-center pointer"
                  onClick={() => this.clickStep(5)}
                >
                  <div className="row">
                    <div
                      className={
                        this.state.nowTab > 4
                          ? "col SideLine activeLine"
                          : "col SideLine"
                      }
                    ></div>
                    <div className="col ">
                      <div
                        className={
                          this.state.nowTab > 4
                            ? "social-container-step activeStep"
                            : "social-container-step"
                        }
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Payment Method"
                      >
                        <div className="social ">
                          <i
                            className="fa fa-credit-card-alt"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <br></br>
                      <span
                        className="stepSpan"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Payment Method"
                      >
                        step 5
                      </span>
                      <br></br>
                    </div>
                    <div className="col SideLineHide"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="row stepFormNavTitle titleStep">
              <div
                className="col d-flex justify-content-center text-center pointer"
                onClick={() => this.clickStep(1)}
              >
                {" "}
                <p className="stepTitle titleStep"> Basic Info</p>
              </div>
              <div
                className="col d-flex justify-content-center text-center pointer"
                onClick={() => this.clickStep(2)}
              >
                {" "}
                <p className="stepTitle titleStep">Residence History</p>
              </div>
              <div
                className="col d-flex justify-content-center text-center pointer"
                onClick={() => this.clickStep(3)}
              >
                {" "}
                <p className="stepTitle titleStep">Employment Data</p>
              </div>
              <div
                className="col d-flex justify-content-center text-center  pointer"
                onClick={() => this.clickStep(4)}
              >
                {" "}
                <p className="stepTitle titleStep">Review</p>
              </div>
              {this.state.stripe_account_status.data !== "verified" && (
                <div
                  className="col d-flex justify-content-center text-center  pointer"
                  onClick={() => this.clickStep(5)}
                >
                  {" "}
                  <p className="stepTitle titleStep">Payment Method</p>
                </div>
              )}
            </div>
            <div className="row stepFormNavTitle pt-5 titleStepOpp">
              <div className="col d-flex justify-content-center text-center">
                <p className="stepTitle ">
                  {this.state.nowTab === 1
                    ? "Basic"
                    : this.state.nowTab === 2
                    ? "Residence History"
                    : this.state.nowTab === 3
                    ? "Employment Data"
                    : this.state.nowTab === 4
                    ? "Review"
                    : this.state.nowTab === 5 &&
                      this.state.stripe_account_status.data !== "verified"
                    ? "Payment Method"
                    : ""}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <br></br>
            <br></br>
            <br></br>
          </>
        )}
        {this.state.nowTab === 0 && (
          <>
            <div className="">
              <div
                className="cardApplication pb-5 pt-3 "
                style={{ paddingRight: "5%", paddingLeft: "5%" }}
              >
                <div className="row mt-3">
                  <div className="col">
                    <p className="axTextBold pb-0 mt-0">
                      Requirements to Be Approved Through Axallant Corporation
                    </p>
                  </div>
                </div>

                <div className="row pt-2 pb-2">
                  <div className="col">
                    <span className="span8">
                      We do not accept comprehensive reusable tenant screening
                      reports as defined by and pursuant to RCW 59.18.
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <span className="span8">
                      Axallant Corporation is prohibited from requiring
                      disclosure, asking about, rejecting an applicant, or
                      taking adverse action based on any arrest record,
                      conviction record,or criminal history, except for registry
                      information as described in subsections 14.09.025A.3,
                      14.09.025.A.4, and 14.09.025.A.5, and subject to the
                      exclusions and legal requirements in Section 14.09.115:We
                      are prohibited from taking an adverse action against a
                      tenant based on eviction history during or within six
                      months after the end of the civil emergency proclaimed by
                      Mayor Durkan on March 3, 2021. The Seattle Office for
                      Civil Rights is the department that will enforce any
                      violations of this ordinance.
                    </span>
                  </div>
                </div>
                <a
                  className="pb-0 mb-0 axText"
                  href="http://www.seattle.gov/Documents/Departments/RentingInSeattle/languages/English/RentersHandbook_English.pdf"
                >
                  Please click here to access your copy of the Seattle Renter's
                  Handbook.
                </a>
                <p className="axTextBold pb-0 mb-0">
                  <i>
                    The following types of information will be accessed to
                    conduct the tenant screening: credit history, credit score,
                    employment history, eviction history, rental history, and
                    sex offender status.
                  </i>
                </p>
                <p className="axTextBold pt-0">
                  When evaluating your report for tenant screening purposes, the
                  following minimum requirements for tenancy, along with items
                  which may result in the denial of your tenancy, are outlined
                  below.
                </p>
                <p className="span8 pt-0 mt-0 mb-0 pb-3">
                  <b className="axTextBold">Income:</b> &nbsp; &nbsp;Minimum
                  requirement
                </p>
                <ul className="span8">
                  <li>
                    History of acceptable level of income must cover the
                    previous 24 months.
                  </li>
                  <li>Rent to income ratio: 1 to 2.5.</li>
                  <li>Debt to income ratio: .20 to 1.</li>
                  <li>
                    Verification of income such as check stubs for the past two
                    pay periods, SSI/disability papers, child support papers,
                    etc.
                  </li>
                  <li>
                    Self-employed must provide last two years tax returns and 3
                    months of current bank statements.
                  </li>
                  <li>
                    Full-time students must provide written verification of
                    financial aid, parental support, scholarships, and/or
                    supplemental savings.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Income:</b> &nbsp; &nbsp;Criteria
                  that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>
                    Lack of acceptable level of income over the previous 24
                    months.
                  </li>
                  <li>Rent to income ratio below: 1 to 2.5.</li>
                  <li>Debt to income ratio above: .20 to 1.</li>
                  <li>
                    Lack of documentation to verify income such as check stubs
                    for the past two pay periods, SSI/disability papers, child
                    support papers, etc.
                  </li>
                  <li>
                    Self-employed unable to provide last two years tax returns
                    and 3 months of current bank statements.
                  </li>
                  <li>
                    Full-time students unable to provide written verification of
                    financial aid, parental support, scholarships, and/or
                    supplemental savings. Co-signer may be required.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Employment History:</b> &nbsp;
                  &nbsp;Minimum requirement
                </p>
                <ul className="span8">
                  <li>
                    For employment verification, you must provide a copy of your
                    2 most recent pay stubs and/or copies of government, pension
                    or valid program funding.
                  </li>
                  <li>
                    If self-employed, you must provide signed copies of the last
                    two years tax returns and the last 3 months bank statements.
                  </li>
                  <li>
                    If you do no have enough income to rent the property, you
                    must specify how you intend to pay and provide evidence of
                    the funds (i.e. bank statements.)
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Employment History:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>
                    Unable to provide a copy of your 2 most recent pay stubs
                    and/or copies of government, pension, or valid program
                    funding.
                  </li>
                  <li>
                    If self-employed, inability to provide signed copies of the
                    last two years tax returns and the last 3 months bank
                    statements.
                  </li>
                  <li>
                    Unable to provide documentation to support how you intend to
                    pay and provide evidence of the funds (i.e. bank
                    statements.)
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Credit History:</b> &nbsp;
                  &nbsp;Minimum requirement
                </p>
                <ul className="span8">
                  <li>
                    No more than 2 delinquent payments over the last 24 months.
                  </li>
                  <li>No accounts in collection.</li>
                  <li>No open bankruptcies.</li>
                  <li>No money owing to a previous landlord.</li>
                  <li>Credit score above 650.</li>
                  <li>
                    Must have two active accounts being rated to establish
                    history.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Credit History:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>
                    More than 2 delinquent payments over the last 24 months.
                  </li>
                  <li>Accounts in collection.</li>
                  <li>Non-discharged bankruptcies.</li>
                  <li>Money owing to a previous landlord.</li>
                  <li>Credit score less than 650.</li>
                  <li>
                    Less than two active accounts being rated to establish
                    history.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Landlord References:</b> &nbsp;
                  &nbsp;Minimum requirements.
                </p>
                <ul className="span8">
                  <li>
                    Each rental applicant must be able to demonstrate a pattern
                    of meeting their rental obligations, leaving prior rental
                    properties in good condition and not having a pattern of
                    complaints from neighbors.
                  </li>
                  <li>
                    Verifiable rental references for positive rental or mortgage
                    history over the last 24 months from unbiased sources; no
                    roommate or family references. Please note: you must provide
                    contact information for the previous landlord and the
                    previous landlord must take or return our screening calls
                    within 72 hours. If 72 hours is not enough time, please
                    notify us.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Landlord References:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my
                  application.
                </p>
                <ul className="span8">
                  <li>
                    Each rental applicant unable to demonstrate a pattern of
                    meeting their rental obligations, leaving prior rental
                    properties in poor condition or having a pattern of
                    complaints from neighbors.
                  </li>
                  <li>
                    No verifiable references for positive rental or mortgage
                    history over the last 24 months from unbiased sources; no
                    roommate or family references.
                  </li>
                  <li>
                    No contact information for previous landlord or no returned
                    phone calls from the landlord within 72 hours of application
                    submission. If 72 hours is not enough time, please notify us
                    if additional time is needed.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Other Factors:</b> &nbsp;
                  &nbsp;Minimum requirements
                </p>
                <ul className="span8">
                  <li>
                    Applications must be filled out completely and truthfully.
                  </li>
                  <li>
                    Applicant should be on time for showing appointment and act
                    respectfully toward residents and leasing staff.
                  </li>
                  <li>
                    Applicant should be able to pay complete reservation deposit
                    at time or application and any rent due at time of lease
                    signing.
                  </li>
                  <li>
                    Each person 18 and older who will be residing in the home
                    must be screened.
                  </li>
                  <li>Copy of current state or government identification.</li>
                  <li>
                    Out of state applicants with no local employer must provide
                    current bank statement with balance of 5x the rent.
                  </li>
                  <li>
                    International students must provide passport, proof of
                    current college or university full-time enrollment or
                    current student visa along with proof of funding (such as an
                    I-20) and/or supplemental savings.
                  </li>
                  <li>
                    Other applicants who are new to the country must provide
                    passport and proof of income (such as a letter of
                    employment), and/or documentation of supplemental savings.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Other Factors:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>Application not filled out completed and truthfully.</li>
                  <li>
                    Incomplete or inaccurate applications cannot be processed.
                  </li>
                  <li>
                    Unfavorable information for any individual applicant may
                    result in denial of all applications for that group.
                  </li>
                  <li>
                    Applicants who do not show up on time for appointment and
                    act disrespectfully toward residents and leasing staff.
                  </li>
                  <li>
                    Unable to pay complete reservation deposit at time of
                    application and any rent due at time of lease signing.
                  </li>
                  <li>
                    Applicant over 18 does not want to apply for residency.
                  </li>
                  <li>
                    Unable to provide a copy of current state or government
                    identification.
                  </li>
                  <li>
                    Out of state applicants with no local employer who are
                    unable to provide a current bank statement with balance of
                    5x the rent.
                  </li>
                  <li>
                    International students unable to provide the following
                    documents: passport, proof of current college or university
                    full-time enrollment or current student visa along with
                    proof of funding (such as I-20) and/or supplemental savings.
                  </li>
                  <li>
                    Other applicants who are new to the country and unable to
                    provide: passport and proof of income (such as letter of
                    employment), and/or documentation of supplemental savings.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">
                    All applicants are judged on the same standards
                  </b>
                </p>
                <span className="span8">
                  All applicants are judged on the same standards; one person,
                  group, or family at a time on a first-come, first0served
                  basis. All vacancies will be filled by the first qualified
                  applicant who can move in closest to the availability date.
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-3">
                  <b className="axTextBold">Sex offender registry notice</b>
                </p>
                <span className="span8">
                  Do not list sex offender registry information if you are a
                  juvenile. Seattle Municipal Code (SMC) 14.09.025.A.4 prohibits
                  taking an adverse action based on registry information of a
                  juvenile prospective occupant, tenant, or household member.
                </span>
                <span className="span8">
                  Do not sex offender registry information if you were a
                  juvenile when the convictions occurred. SMC 14.09.025.A.5
                  prohibits adverse actions base don registry information
                  against adults if the convictions occurred when the individual
                  was a juvenile.
                </span>
                <span className="span8">
                  SMC 14.09.025.A.3 requires a legitimate business reason
                  analysis before a landlord or any person takes an adverse
                  action based on registry information of an adult prospective
                  occupant, tenant, or household member.
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-3">
                  <i className="axTextBold">
                    When considering sex offender registry information, the
                    following factors relating to the conviction(s) that
                    requires registry on a local, state, or national sex
                    offender registry:
                  </i>
                </p>
                <ul className="span8">
                  <li>The nature and severity of the conviction</li>
                  <li>The number and types of convictions</li>
                  <li>
                    The time that has elapsed since the date of conviction
                  </li>
                  <li>Age of the individual at the time of convictions</li>
                  <li>
                    Evidence of good tenant history before and/or after the
                    conviction occurred
                  </li>
                  <li>
                    Any supplemental information related to the individual's
                    rehabilitation, good conduct, and additional facts or
                    explanations provided by the individual, if the individual
                    chooses to do so.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Additional Time needed</b>
                </p>
                <span className="span8">
                  If you require additional time to complete the application due
                  to the need to procure interpretation/translation services or
                  accommodations for a disability, please submit your request in
                  writing to info@axallant.com
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-3">
                  <b className="axTextBold">Co-Signers</b>
                </p>
                <span className="span8">
                  If you do not meet one or more of the above criteria, you may
                  be able to qualify for a rental unit if you have a third party
                  located within the state that will guarantee your lease. The
                  guarantor must fill out an application, pass the screening
                  process, and have income and debt to income ratio enough to
                  cover their expense and yours in event of default.
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-4">
                  <b className="axTextBold">Notice:</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-1">
                  <b className="axTextBold">
                    Axallant 2021 is the consumer reporting agency who will be
                    compiling your consumer report. You have the right to obtain
                    a free copy of your consumer report in the event of a denial
                    or adverse action and to dispute the accuracy of the
                    information appearing in the consumer report. All inquiries
                    may be directed to:
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Axallant 2021, Inc.</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">11420 NE 20th St</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Bellevue WA 98004</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Phone:</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Fax: </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    The screening fee is non-refundable.
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    If you plan to apply for more than one of our properties,
                    please only submit one application and let your agent know
                    which other property you would like to apply for.
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    By signing below, I certify and acknowledge I have applied
                    for a rental property withing the Seattle city limits.
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    ***If the rental property is outside of the Seattle city
                    limits please choose the other application, do not
                    proceed***
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    ***If the rental property is outside of the Seattle city
                    limits please choose the other application, do not
                    proceed***
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    Sign below to confirm that you have read and understand the
                    above, before continuing with the application
                  </b>
                </p>
                <span className="span8 pt-5 ">Signature :</span>
                <div className=" mt-4 ml-3">
                  <SignaturePad
                    name="applicationFront"
                    submitAlign="pull-right"
                    submitName="Continue"
                    type="base64"
                    value={this.state.signatureObject}
                    submit={this.changeSignatureObject}
                    submit2={this.changeSignatureObject3}
                    type2={true}
                    submitDisable={true}
                    maxWidth="550"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.nowTab === 1 && (
          <>
            <div className="cardApplication pt-3 pb-5">
              <p className=" stepTitle subTitle pt-3 textDark ">
                Applicant Info
              </p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="First Name"
                    type="text"
                    target="firstName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your First Name"
                    validate={this.checkValidate(this.state.firstName)}
                    value={this.state.firstName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Middle Name"
                    type="text"
                    target="middleName"
                    onChange={this.onchange}
                    errorMessage="your Middle Name"
                    validate={this.checkValidate(this.state.middleName)}
                    value={this.state.middleName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Last Name"
                    type="text"
                    target="lastName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Last Name"
                    validate={this.checkValidate(this.state.lastName)}
                    value={this.state.lastName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Email"
                    type="email"
                    target="email"
                    important="true"
                    // onChange={this.onchange}
                    disabled
                    errorMessage={
                      this.checkValidate(this.state.email)
                        ? "your Email"
                        : "your email correct format"
                    }
                    validate={this.checkEmail(this.state.email)}
                    value={this.state.email}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    target="dob"
                    type="date"
                    name="Date of Birth"
                    format="yearFirst"
                    min={"1970-01-01"}
                    max={this.today()}
                    onChange={this.onChangeDate}
                    value={this.state.dob}
                    place="mm-dd-yyyy"
                    important="true"
                    errorMessage="your Date of Birth"
                    validate={this.checkValidate(this.state.dob)}
                  />
                </div>

                <div className="col alignInput ">
                  <ApplicationInput
                    name="Driver's License Number"
                    type="text"
                    target="drivingNo"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Driving License Number"
                    validate={this.checkValidate(this.state.drivingNo)}
                    value={this.state.drivingNo}
                    icon={this.props.role !== "Guest" ? "fa-camera-retro" : ""}
                    clickIcon={this.changeLicense}
                  />
                </div>
                <div className="col alignInput">
                  <SearchInput
                    name="drivingNoProvince"
                    id="Province of issue"
                    target="drivingNoProvince"
                    onChange={this.onchange}
                    list={this.state.provinceList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="your licence issue province"
                    validate={this.checkValidate(this.state.drivingNoProvince)}
                    value={this.state.drivingNoProvince}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Valid"
                    type="YN"
                    options={["Yes", "No"]}
                    target="drivingNoValid"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the licence valid  or not"
                    validate={this.checkValidate(this.state.drivingNoValid)}
                    value={this.state.drivingNoValid}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Social Security Number"
                    type="text"
                    target="securityNo"
                    min="1"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.securityNo)
                        ? "your social security number"
                        : "a valid social security number"
                    }
                    validate={this.checkSIN(this.state.securityNo)}
                    value={this.state.securityNo}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Mobile Number"
                    type="text"
                    target="mobile"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.mobile)
                        ? "your cell number"
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.mobile)}
                    value={this.state.mobile}
                  />
                </div>
                {/* <div className="col alignInput">     // removed field from application canada
                  <ApplicationInput
                    name="Home number"
                    type="text"
                    target="workingNo"
                    onChange={this.onchange}
                    errorMessage="your home number"
                    validate={this.checkMobile(this.state.workingNo)}
                    value={this.state.workingNo}
                  />
                </div>
                <div className="col alignInput">         // removed field from application canada
                  <ApplicationInput
                    name="Other Names"
                    type="text"
                    target="otherName"
                    onChange={this.onchange}
                    errorMessage="your Other Name Used"
                    validate={this.checkValidate(this.state.otherName)}
                    value={this.state.otherName}
                  />
                </div> */}

                {/* <div className="col alignInput"> // removed field from application canada
                  <ApplicationInput
                    name="Nearest Relative Name"
                    type="text"
                    // place="Relative Name"
                    target="relativeName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Relative Name"
                    validate={this.checkValidate(this.state.relativeName)}
                    value={this.state.relativeName}
                  />
                </div>
                <div className="col alignInput"> // removed field from application canada
                  <ApplicationInput 
                    name="Nearest Relative Mobile Number"
                    type="text"
                    // place="Contact No"
                    target="relativeNum"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.relativeNum)
                        ? "your Relative Number"
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.relativeNum)}
                    value={this.state.relativeNum}
                  />
                </div> */}
              </div>

              <p className="subTitle stepTitle pt-3 textDark">
                References Details
              </p>
              <br />
              <p className="subTitle stepTitle pt-4 textSec2">
                First References
              </p>
              <div className="row  pt-5 pb-0 mt-3 inputContainer">
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Name"
                    type="text"
                    target="applicantFirstReferencesName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of first reference"
                    validate={this.checkValidate(
                      this.state.applicantFirstReferencesName
                    )}
                    value={this.state.applicantFirstReferencesName}
                  />
                </div>

                
                <div className="col-3 alignInput">
                  <SearchInput
                    name="applicantFirstReferencesCountry"
                    id="Country"
                    target="applicantFirstReferencesCountry"
                    onChange={this.onchange}
                    list={this.state.countryListRef1}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the country of first reference"
                    validate={this.checkValidate(this.state.applicantFirstReferencesCountry)}
                    value={this.state.applicantFirstReferencesCountry}
                  />
                
                </div>
                <div className="col-3 alignInput">
                  <SearchInput
                    name="applicantFirstReferencesProvince"
                    id="Province"
                    target="applicantFirstReferencesProvince"
                    onChange={this.onchange}
                    list={this.state.stateListRef1}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the province of first reference"
                    validate={this.checkValidate(this.state.applicantFirstReferencesProvince)}
                    value={this.state.applicantFirstReferencesProvince}
                  />
                
                </div>
                <div className="col-3 alignInput">
                
                  <SearchInput
                    name="applicantFirstReferencesCity"
                    id="City"
                    target="applicantFirstReferencesCity"
                    onChange={this.onchange}
                    list={this.state.cityListRef1}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the city of first reference"
                    validate={this.checkValidate(this.state.applicantFirstReferencesCity)}
                    value={this.state.applicantFirstReferencesCity}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Street"
                    type="text"
                    target="applicantFirstReferencesAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the street of first reference"
                    validate={this.checkValidate(
                      this.state.applicantFirstReferencesAddress
                    )}
                    value={this.state.applicantFirstReferencesAddress}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Phone Number"
                    type="text"
                    target="applicantFirstReferencesTelephone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(
                        this.state.applicantFirstReferencesTelephone
                      )
                        ? "the phone number of first reference."
                        : "the valid phone number of first reference."
                    }
                    validate={this.checkMobile(
                      this.state.applicantFirstReferencesTelephone
                    )}
                    value={this.state.applicantFirstReferencesTelephone}
                  />
                </div>
              </div>
              <br />
              <p className="subTitle stepTitle  textSec2">Second References</p>
              <div className="row  pt-5 pb-3 inputContainer">
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Name"
                    type="text"
                    target="applicantSecondReferencesName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of second reference"
                    validate={this.checkValidate(
                      this.state.applicantSecondReferencesName
                    )}
                    value={this.state.applicantSecondReferencesName}
                  />
                </div>

               
                <div className="col-3 alignInput">
            
                  <SearchInput
                    name="applicantSecondReferencesCountry"
                    id="Country"
                    target="applicantSecondReferencesCountry"
                    onChange={this.onchange}
                    list={this.state.countryListRef2}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the country of second reference"
                    validate={this.checkValidate(this.state.applicantSecondReferencesCountry)}
                    value={this.state.applicantSecondReferencesCountry}
                  />
                </div>
                <div className="col-3 alignInput">
            
                  <SearchInput
                    name="applicantSecondReferencesProvince"
                    id="Province"
                    target="applicantSecondReferencesProvince"
                    onChange={this.onchange}
                    list={this.state.stateListRef2}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the province of second reference"
                    validate={this.checkValidate(this.state.applicantSecondReferencesProvince)}
                    value={this.state.applicantSecondReferencesProvince}
                  />
                </div>
                <div className="col-3 alignInput">
                  
                   <SearchInput
                    name="applicantSecondReferencesCity"
                    id="City"
                    target="applicantSecondReferencesCity"
                    onChange={this.onchange}
                    list={this.state.cityListRef2}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the city of second reference"
                    validate={this.checkValidate(this.state.applicantSecondReferencesCity)}
                    value={this.state.applicantSecondReferencesCity}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Street"
                    type="text"
                    target="applicantSecondReferencesAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the street of second reference"
                    validate={this.checkValidate(
                      this.state.applicantSecondReferencesAddress
                    )}
                    value={this.state.applicantSecondReferencesAddress}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Phone Number"
                    type="text"
                    target="applicantSecondReferencesTelephone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(
                        this.state.applicantSecondReferencesTelephone
                      )
                        ? "the phone number of second reference."
                        : "the valid phone number of second reference."
                    }
                    validate={this.checkMobile(
                      this.state.applicantSecondReferencesTelephone
                    )}
                    value={this.state.applicantSecondReferencesTelephone}
                  />
                </div>
              </div>

              <br />
              <p className="subTitle stepTitle textDark">Occupants Details</p>
              <div className="row inputContainer pt-5 pb-0 mt-0 mb-0">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Number of Occupants(Adults)"
                    type="number"
                    place=""
                    target="occupant"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    max="10"
                    errorMessage="Number of Occupants"
                    validate={this.checkValidate(this.state.occupant)}
                    value={this.state.occupant}
                  />
                </div>
                <div className="col ">
                  <ApplicationInput
                    name="Number of Occupants(Children under 18)"
                    type="number"
                    place=""
                    target="occupantChildren"
                    onChange={this.onchange}
                    important="true"
                    min="0"
                    max="10"
                    errorMessage="Number of Occupants children"
                    validate={this.checkValidate(this.state.occupantChildren)}
                    value={this.state.occupantChildren}
                  />
                </div>
              </div>
              {this.state.occupant > 1 && (
                <div
                  className="inputContainer pt-0 pb-0 mt-0 mb-0"
                  style={{ marginTop: "-45px", marginBottom: "-0px" }}
                >
                  <span className="searchListHeadApp">
                    Occupants Names(Adults)
                  </span>
                </div>
              )}
              <div className="row inputContainer pt-0 pb-0 mt-0 mb-0">
                {this.state.occupantArray.map((occupant) => (
                  <div className="col-3 alignInput">
                    <ApplicationInput
                      name="null"
                      type="text"
                      target={occupant}
                      onChange={this.onchange}
                      important="true"
                      errorMessage="occupant name"
                      validate={this.checkValidate(this.state[occupant])}
                      value={this.state[occupant]}
                      min="1"
                    />
                  </div>
                ))}
              </div>

              {this.state.occupantChildren > 0 && (
                <div
                  className="inputContainer pt-0 pb-0 mt-0 mb-0"
                  style={{ marginTop: "-45px", marginBottom: "-0px" }}
                >
                  <span className="searchListHeadApp">
                    Occupants Names(Children under 18)
                  </span>
                </div>
              )}
              <div className="row inputContainer pt-0 pb-0 mt-0 mb-0">
                {this.state.occupantChildrenArray.map((occupant) => (
                  <div className="col-3 alignInput">
                    <ApplicationInput
                      name="null"
                      type="text"
                      target={occupant}
                      onChange={this.onchange}
                      important="true"
                      errorMessage="occupant name"
                      validate={this.checkValidate(this.state[occupant])}
                      value={this.state[occupant]}
                      min="1"
                    />
                  </div>
                ))}
              </div>
              {process.env.REACT_APP_STATUS !== "Production" && (
                <div className="row inputContainer pt-0 pb-0 mt-0 mb-0">
                  <div className="col  d-flex flex-row-reverse">
                    <span
                      class="badge p-2 text-white pointer"
                      onClick={() => this.setDemoData()}
                      style={{ backgroundColor: "#42dc13" }}
                    >
                      Test Data
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="cardApplication pt-4 ">
              <p className="subTitle stepTitle pt-3 textDark ">Additional</p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="Move In Date"
                    type="date"
                    format="yearFirst"
                    // max={"2021-12-01"}
                    min={this.today()}
                    target="moveInDate"
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage="your Move In Date"
                    validate={this.checkValidate(this.state.moveInDate)}
                    value={this.state.moveInDate}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Do you have pets?"
                    type="YN"
                    options={["Yes", "No"]}
                    target="pet"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="you have the pet or not"
                    validate={this.checkValidate(this.state.pet)}
                    value={this.state.pet}
                  />
                </div>
                {this.state.pet === "true" && (
                  <>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Number of pets"
                        type="number"
                        target="petCount"
                        min="1"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage=" pets count do you have?"
                        // validate={this.checkValidate(this.state.petCount)}
                        value={this.state.petCount}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Pet Breed"
                        type="text"
                        // place="Relative Name"
                        target="petBreed"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your pet breed"
                        validate={this.checkValidate(this.state.petBreed)}
                        value={this.state.petBreed}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Pet Height"
                        type="text"
                        // place="Relative Name"
                        target="petHeight"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your pet height"
                        validate={this.checkValidate(this.state.petHeight)}
                        value={this.state.petHeight}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Pet Weight"
                        type="text"
                        // place="Relative Name"
                        target="petWeight"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your pet weight"
                        validate={this.checkValidate(this.state.petWeight)}
                        value={this.state.petWeight}
                      />
                    </div>
                  </>
                )}
                {/* <div className="col alignInput ">        // removed field from application canada
                  <ApplicationInput
                    name="Lease Term (Month)"
                    type="number"
                    target="leaseTerm"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    errorMessage="your Lease Term"
                    validate={this.checkValidate(this.state.leaseTerm)}
                    value={this.state.leaseTerm}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Additional Income (Monthly)"
                    type="number"
                    target="additionalIncome"
                    onChange={this.onchange}
                    min="1"
                    // important="true"
                    // errorMessage="your additional income."
                    // validate={this.checkValidate(this.state.additionalIncome)}
                    value={this.state.additionalIncome}
                    iconL={"fa-usd"}
                  />
                </div>
                */}
                {/* <div className="col-12 ">
                  <ApplicationInput
                    name="Do you have a vehicle"
                    type="text"
                    target="vehicles"
                    onChange={this.onchange}
                    // important="true"
                    errorMessage="your vehicles"
                    validate={this.checkValidate(this.state.vehicles)}
                    value={this.state.vehicles}
                    titleBreck="true"
                  />
                </div>  */}
              </div>
              <p className="subTitle stepTitle pt-3 textDark ">
                Vehicle Details
              </p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="Do you have vehicle"
                    type="optionYN"
                    target="applicantVehicle"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.applicantVehicle)}
                    value={this.state.applicantVehicle}
                  />
                </div>

                {this.state.applicantVehicle && (
                  <>
                    <div className="col alignInput ">
                      <ApplicationInput
                        name="Make of Vehicle"
                        type="text"
                        target="applicantVehicleMadeBy"
                        onChange={this.onchange}
                        important="true"
                        errorMessage=" make of vehicle"
                        validate={this.checkValidate(
                          this.state.applicantVehicleMadeBy
                        )}
                        value={this.state.applicantVehicleMadeBy}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <ApplicationInput
                        name="Model of Vehicle"
                        type="text"
                        target="applicantVehicleModel"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="model of vehicle"
                        validate={this.checkValidate(
                          this.state.applicantVehicleModel
                        )}
                        value={this.state.applicantVehicleModel}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <ApplicationInput
                        name="Year of Vehicle"
                        type="optional"
                        options={this.state.dropYears}
                        optionValue={this.state.dropYears}
                        target="applicantVehicleYear"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the year of vehicle"
                        validate={this.checkValidate(
                          this.state.applicantVehicleYear
                        )}
                        value={this.state.applicantVehicleYear}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <ApplicationInput
                        name="Color of Vehicle"
                        type="text"
                        target="applicantVehicleColor"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="color of vehicle "
                        validate={this.checkValidate(
                          this.state.applicantVehicleColor
                        )}
                        value={this.state.applicantVehicleColor}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <ApplicationInput
                        name="Vehicle License Plate No."
                        type="text"
                        min="1"
                        target="applicantVehicleLicensePlateNumber"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="vehicle license plate no"
                        validate={this.checkValidate(
                          this.state.applicantVehicleLicensePlateNumber
                        )}
                        value={this.state.applicantVehicleLicensePlateNumber}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <SearchInput
                        name="applicantVehicleLicensePlateProvince"
                        id="Province of Issue"
                        target="applicantVehicleLicensePlateProvince"
                        onChange={this.onchange}
                        list={this.state.provinceList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="vehicle license plate province"
                        validate={this.checkValidate(
                          this.state.applicantVehicleLicensePlateProvince
                        )}
                        value={this.state.applicantVehicleLicensePlateProvince}
                      />
                    </div>
                  </>
                )}
              </div>
              <p className="subTitle stepTitle pt-3  textDark">
                Emergency Contact
              </p>
              <div className="row  pt-5 pb-3 inputContainer">
                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="Name"
                    type="text"
                    target="emergencyContactName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of emergency Contact"
                    validate={this.checkValidate(
                      this.state.emergencyContactName
                    )}
                    value={this.state.emergencyContactName}
                  />
                </div>

               
                <div className="col-4 alignInput">
                  <SearchInput
                    name="emergencyContactCountry"
                    id="Province"
                    target="emergencyContactCountry"
                    onChange={this.onchange}
                    list={this.state.countryListEmergency}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the country of emergency Contact"
                    validate={this.checkValidate(this.state.emergencyContactCountry)}
                    value={this.state.emergencyContactCountry}
                  />
                </div>
                <div className="col-4 alignInput">
                  <SearchInput
                    name="emergencyContactProvince"
                    id="Province"
                    target="emergencyContactProvince"
                    onChange={this.onchange}
                    list={this.state.stateListEmergency}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the province of emergency Contact"
                    validate={this.checkValidate(this.state.emergencyContactProvince)}
                    value={this.state.emergencyContactProvince}
                  />
                </div>
                <div className="col-4 alignInput">
                 
                  <SearchInput
                    name="emergencyContactCity"
                    id="City"
                    target="emergencyContactCity"
                    onChange={this.onchange}
                    list={this.state.cityListEmergency}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the city of emergency Contact"
                    validate={this.checkValidate(this.state.emergencyContactCity)}
                    value={this.state.emergencyContactCity}
                  />
                </div>
                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="Street"
                    type="text"
                    target="emergencyContactStreet"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the street of emergency Contact"
                    validate={this.checkValidate(
                      this.state.emergencyContactStreet
                    )}
                    value={this.state.emergencyContactStreet}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Postal Code"
                    type="text"
                    target="emergencyContactPostalCode"
                    min="1"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your postal code of emergency contact"
                    validate={this.checkValidate(
                      this.state.emergencyContactPostalCode
                    )}
                    value={this.state.emergencyContactPostalCode}
                  />
                </div>
                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="Phone Number"
                    type="text"
                    target="emergencyContactPhone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.emergencyContactPhone)
                        ? "the phone number of emergency Contact"
                        : "the valid phone number of emergency Contact"
                    }
                    validate={this.checkMobile(
                      this.state.emergencyContactPhone
                    )}
                    value={this.state.emergencyContactPhone}
                  />
                </div>
              </div>

              <div className="row inputContainer pt-2  mt-0 pb-5">
                <div className="col-md-6  ">
                  <ApplicationInput
                    name="Have you ever been convicted of a criminal offence?"
                    type="optionYN"
                    target="crimeCheck"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.crimeCheck)}
                    value={this.state.crimeCheck}
                  />
                </div>
                {/* <div className="col-6  alignInput ">  // removed field from application canada
                  <ApplicationInput
                    name="Ever filed bankruptcy?"
                    type="optionYN"
                    target="bankruptcy"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.bankruptcy)}
                    value={this.state.bankruptcy}
                  />
                </div> */}

                <div className="col-md-6  ">
                  <ApplicationInput
                    name="Have you ever been evicted as a tenant?"
                    type="optionYN"
                    target="evicted"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.evicted)}
                    value={this.state.evicted}
                  />
                </div>
                {/* <div className="col-6  alignInput ">   // removed field from application canada
                  <ApplicationInput
                    name="Anyone in the Household Smoke?"
                    type="optionYN"
                    target="smoke"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.smoke)}
                    value={this.state.smoke}
                  />
                </div> */}
                <div className="col  alignInput ">
                  <ApplicationInput
                    name="Do you have join tenant?"
                    type="optionYN"
                    target="isJoinTenant"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.isJoinTenant)}
                    value={this.state.isJoinTenant}
                  />
                </div>
                {this.state.isJoinTenant && (
                  <>
                    <div className="col  alignInput ">
                      <ApplicationInput
                        name="Email (Tenant-2)"
                        type="email"
                        target="joinTenant1"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.joinTenant1)
                            ? "the join tenant email address"
                            : "the join tenant email correct format"
                        }
                        validate={this.checkEmail(this.state.joinTenant1)}
                        value={this.state.joinTenant1}
                      />
                    </div>
                    {this.state.joinTenantCount === 2 && (
                      <div className="col  alignInput ">
                        <ApplicationInput
                          name="Email (Tenant-3)"
                          type="email"
                          target="joinTenant2"
                          onChange={this.onchange}
                          important="true"
                          errorMessage={
                            this.checkValidate(this.state.joinTenant2)
                              ? "the join tenant email address"
                              : "the join tenant email correct format"
                          }
                          validate={this.checkEmail(this.state.joinTenant2)}
                          value={this.state.joinTenant2}
                        />
                      </div>
                    )}
                    <div className="col pt-2">
                      <button
                        className="buttonPri mt-4"
                        onClick={() => this.changeJoinTenantCount()}
                      >
                        {this.state.joinTenantCount === 1 ? (
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {this.state.nowTab === 2 && (
          <>
            <div className="cardApplication pt-3 pb-3">
              <p className="subTitle stepTitle pt-3 textDark ">
                Present Address
              </p>
              <div className="row inputContainer  pt-5 pb-4">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Unit # / Number / Street"
                    type="text"
                    target="address"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Present Address"
                    validate={this.checkValidate(this.state.address)}
                    value={this.state.address}
                  />
                </div>
                {/* <div className="col alignInput">
                <SearchInput
                  name="country"
                  id="Country"
                  target="country"
                  onChange={this.onchange}
                  list={this.state.countryList}
                  changeState={this.changeState}
                  important="true"
                  errorMessage="property Country"
                  validate={this.checkValidate(this.state.country)}
                  value={this.state.country}
                />
              </div> */}
                <div className="col alignInput">
                  <SearchInput
                    name="state"
                    id="Province"
                    target="state"
                    onChange={this.onchange}
                    list={this.state.stateList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="property State"
                    validate={this.checkValidate(this.state.state)}
                    value={this.state.state}
                  />
                </div>

                <div className="col alignInput">
                  <SearchInput
                    name="city"
                    id="City"
                    target="city"
                    onChange={this.onchange}
                    list={this.state.cityList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="property City"
                    validate={this.checkValidate(this.state.city)}
                    value={this.state.city}
                  />
                </div>
                {/* <div className="col alignInput">
                <ApplicationInput
                  name="City"
                  type="text"
                  target="city"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="your Present City"
                  validate={this.checkValidate(this.state.city)}
                  value={this.state.city}
                />
              </div>
              
              <div className="col alignInput">
                <ApplicationInput
                  name="State"
                  type="text"
                  target="state"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="your Present State"
                  validate={this.checkValidate(this.state.state)}
                  value={this.state.state}
                />
              </div> */}
                <div className="col alignInput">
                  <ApplicationInput
                    name="Postal Code"
                    type="number"
                    target="zip"
                    min="1"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Present Zip Code"
                    validate={this.checkValidate(this.state.zip)}
                    value={this.state.zip}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Phone Number"
                    type="text"
                    target="residentPhone"
                    onChange={this.onchange}
                    // important="true"
                    errorMessage={
                      this.checkValidate(this.state.residentPhone)
                        ? "the phone number of your residents."
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.residentPhone)}
                    value={this.state.residentPhone}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Email"
                    type="email"
                    target="residentEmail"
                    // important="true"
                    onChange={this.onchange}
                    errorMessage={
                      this.checkValidate(this.state.residentEmail)
                        ? "the email address of your residents."
                        : "email address correct format "
                    }
                    validate={this.checkEmail(this.state.residentEmail)}
                    value={this.state.residentEmail}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="From"
                    type="date"
                    format="yearFirst"
                    min={"1910-01-01"}
                    max={this.state.to === "" ? this.today() : this.state.to}
                    target="from"
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.from)
                        ? "from when you were living?"
                        : "date before " + this.state.to
                    }
                    validate={this.checkPeriod(this.state.from, this.state.to)}
                    value={this.state.from}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="To"
                    type="date"
                    format="yearFirst"
                    target="to"
                    onChange={this.onChangeDate}
                    min={this.state.from}
                    max={this.today()}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.to)
                        ? "to when you were living?"
                        : "date after " + this.state.from
                    }
                    validate={this.checkPeriod(this.state.from, this.state.to)}
                    value={this.state.to}
                  />
                </div>
                <div className="col-6  ">
                  <ApplicationInput
                    name="Reason for Leaving"
                    target="reasonForLeaving"
                    type="textArea"
                    row="2"
                    onChange={this.onchange}
                    designType="2"
                    errorMessage="the reason for leaving"
                    validate={this.checkValidate(this.state.reasonForLeaving)}
                    value={this.state.reasonForLeaving}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Ownership"
                    type="optional"
                    options={["Own", "Rent"]}
                    optionValue={["Own", "Rent"]}
                    target="propOwn"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="you were living own or rent"
                    validate={this.checkValidate(this.state.propOwn)}
                    value={this.state.propOwn}
                  />
                </div>
              </div>

              {this.state.propOwn === "Rent" && (
                <>
                  <div className="row  pt-2 inputContainer pb-4">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Landlord’s Name"
                        type="text"
                        target="presentLandlord"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="from the name of present Landlord you are living."
                        validate={this.checkValidate(
                          this.state.presentLandlord
                        )}
                        value={this.state.presentLandlord}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Rent"
                        type="number"
                        target="rent"
                        onChange={this.onchange}
                        important="true"
                        min="1"
                        errorMessage="your monthly rental"
                        validate={this.checkValidate(this.state.rent)}
                        iconL={"fa-usd"}
                        value={this.state.rent}
                      />
                    </div>
                    {/* <div className="col alignInput">   //removed for canada application
                      <ApplicationInput
                        name="Email"
                        type="email"
                        target="landLordEmail"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.landLordEmail)
                            ? "from the email address of present landlord you are living."
                            : "present landlord email correct format"
                        }
                        validate={this.checkEmail(this.state.landLordEmail)}
                        value={this.state.landLordEmail}
                      />
                    </div> */}

                    <div className="col alignInput">
                      <ApplicationInput
                        name="Landlord’s Phone"
                        type="text"
                        target="landLordMobile"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the phone number of present Landlord you are living."
                        validate={this.checkMobile(this.state.landLordMobile)}
                        value={this.state.landLordMobile}
                      />
                    </div>
                    {/* <div className="col alignInput">       //removed for canada application
                      <ApplicationInput
                        name="Fax Number"
                        type="number"
                        min="1"
                        target="landLordFax"
                        onChange={this.onchange}
                        errorMessage="the Cell number of present Landlord you are living."
                        validate={this.checkValidate(this.state.landLordFax)}
                        value={this.state.landLordFax}
                      />
                    </div> */}
                    {/* <div className="col alignInput">     //removed for canada application
                      <ApplicationInput
                        name="Property Type"
                        target="unitType"
                        type="optional"
                        options={["Apartment", "Mortgage", "Other"]}
                        optionValue={["Apartment", "Mortgage", "Other"]}
                        onChange={this.onchange}
                        important="true"
                        errorMessage="unit type you are living"
                        validate={this.checkValidate(this.state.unitType)}
                        value={this.state.unitType}
                      />
                    </div> */}
                  </div>
                </>
              )}
            </div>
            {/* <div style={{ paddingRight: "10%", paddingLeft: "10%" }}>
              <hr className="line "></hr>
            </div> */}

            <p
              className="subTitle stepTitle pt-4 textDark "
              style={{ zIndex: "10" }}
            >
              Previous Resident Information &nbsp;&nbsp;&nbsp;&nbsp;
              <div
                className="pull-right preEmployeeHide1"
                style={{ zIndex: "900" }}
              >
                <ApplicationInput
                  name=""
                  type="checkedBox"
                  target="preRentalInfo"
                  onChange={this.onchangeCheck}
                  value={this.state.preRentalInfo}
                />
              </div>
            </p>

            <div
              className="subTitle stepTitle pt-5 pull-left preEmployeeHide2"
              style={{ zIndex: "900" }}
            >
              <ApplicationInput
                name=""
                type="checkedBox"
                target="preRentalInfo"
                onChange={this.onchangeCheck}
                value={this.state.preRentalInfo}
              />
            </div>
            <br></br>
            <br></br>
            {this.state.preRentalInfo && (
              <>
                <div className="preEmployeeHide2">
                  <br></br>
                  <br></br>
                </div>
                <div className="cardApplication pt-3 pb-4">
                  <div className="row  pt-3 pb-2  inputContainer">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Unit # / Number / Street"
                        type="text"
                        target="preAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your previous Address"
                        validate={this.checkValidate(this.state.preAddress)}
                        value={this.state.preAddress}
                      />
                    </div>
                    {/* <div className="col alignInput">
                    <SearchInput
                      name="preCountry"
                      id="Country"
                      target="preCountry"
                      onChange={this.onchange}
                      list={this.state.preCountryList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="your previous Country"
                      validate={this.checkValidate(this.state.preCountry)}
                      value={this.state.preCountry}
                    />
                  </div> */}
                    <div className="col alignInput">
                      <SearchInput
                        name="preState"
                        id="Province"
                        target="preState"
                        onChange={this.onchange}
                        list={this.state.preStateList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="your previous State"
                        validate={this.checkValidate(this.state.preState)}
                        value={this.state.preState}
                      />
                    </div>

                    <div className="col alignInput">
                      <SearchInput
                        name="preCity"
                        id="City"
                        target="preCity"
                        onChange={this.onchange}
                        list={this.state.preCityList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="your previous City"
                        validate={this.checkValidate(this.state.preCity)}
                        value={this.state.preCity}
                      />
                    </div>
                    {/* <div className="col alignInput">
                <ApplicationInput
                  name="City"
                  type="text"
                  target="preCity"
                  onChange={this.onchange}
                  errorMessage="your previous City"
                  validate={this.checkValidate(this.state.preCity)}
                  value={this.state.preCity}
                />
              </div>
              <div className="col alignInput">
                <ApplicationInput
                  name="State"
                  type="text"
                  target="preState"
                  onChange={this.onchange}
                  errorMessage="your previous State"
                  validate={this.checkValidate(this.state.preState)}
                  value={this.state.preState}
                />
              </div> */}
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Postal Code"
                        type="number"
                        target="preZip"
                        min="1"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your previous Zip Code"
                        validate={this.checkValidate(this.state.preZip)}
                        value={this.state.preZip}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Phone Number"
                        type="text"
                        target="preResidentPhone"
                        onChange={this.onchange}
                        // important="true"
                        errorMessage={
                          this.checkValidate(this.state.preResidentPhone)
                            ? "the phone number of your residents."
                            : "the 10 digit number "
                        }
                        validate={this.checkMobile(this.state.preResidentPhone)}
                        value={this.state.preResidentPhone}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Email"
                        type="email"
                        target="preResidentEmail"
                        // important="true"
                        onChange={this.onchange}
                        errorMessage={
                          this.checkValidate(this.state.preResidentEmail)
                            ? "the email address of your residents."
                            : "email address correct format "
                        }
                        validate={this.checkEmail(this.state.preResidentEmail)}
                        value={this.state.preResidentEmail}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="From"
                        type="date"
                        format="yearFirst"
                        target="preFrom"
                        onChange={this.onChangeDate}
                        max={
                          this.state.preTo === ""
                            ? this.today()
                            : this.state.preTo
                        }
                        min={"1910-01-01"}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.preFrom)
                            ? "from when you were previous living?"
                            : "date before " + this.state.preTo
                        }
                        validate={this.checkValidate(this.state.preFrom)}
                        value={this.state.preFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="To"
                        type="date"
                        format="yearFirst"
                        target="preTo"
                        onChange={this.onChangeDate}
                        max={
                          this.state.from === ""
                            ? this.today()
                            : this.state.from
                        }
                        min={this.state.preFrom}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.preTo)
                            ? "to when you were previous living?"
                            : "date after " + this.state.preFrom
                        }
                        validate={this.checkValidate(this.state.preTo)}
                        value={this.state.preTo}
                      />
                    </div>
                    <div className="col-6  ">
                      <ApplicationInput
                        name="Reason for Leaving"
                        target="preReasonForLeaving"
                        type="textArea"
                        row="5"
                        onChange={this.onchange}
                        designType="2"
                        errorMessage="the reason for leaving"
                        validate={this.checkValidate(
                          this.state.preReasonForLeaving
                        )}
                        value={this.state.preReasonForLeaving}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Ownership"
                        type="optional"
                        options={["Own", "Rent"]}
                        optionValue={["Own", "Rent"]}
                        target="prePropOwn"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="you were previous living own or rent"
                        validate={this.checkValidate(this.state.prePropOwn)}
                        value={this.state.prePropOwn}
                      />
                    </div>
                  </div>

                  {this.state.prePropOwn === "Rent" && (
                    <>
                      <div className="row  pt-2 inputContainer">
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord’s Name"
                            type="text"
                            target="preLandLord"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="from the name of previous Landlord you are living."
                            validate={this.checkValidate(
                              this.state.preLandLord
                            )}
                            value={this.state.preLandLord}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Rent"
                            type="number"
                            target="preRent"
                            min="1"
                            onChange={this.onchange}
                            iconL={"fa-usd"}
                            important="true"
                            errorMessage="your monthly rental"
                            validate={this.checkValidate(this.state.preRent)}
                            value={this.state.preRent}
                          />
                        </div>
                        {/* <div className="col alignInput">
                          <ApplicationInput
                            name="Email"
                            type="email"
                            target="preLandLordEmail"
                            onChange={this.onchange}
                            errorMessage={
                              this.checkValidate(this.state.preLandLordEmail)
                                ? "from the email address of present landlord you are living."
                                : "present landlord email correct format"
                            }
                            important="true"
                            validate={this.checkEmail(
                              this.state.preLandLordEmail
                            )}
                            value={this.state.preLandLordEmail}
                          />
                        </div> */}
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord's Number"
                            type="text"
                            target="preLandLordMobile"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the Cell number of previous Landlord you are living."
                            validate={this.checkMobile(
                              this.state.preLandLordMobile
                            )}
                            value={this.state.preLandLordMobile}
                          />
                        </div>
                        {/* <div className="col alignInput">
                          <ApplicationInput
                            name="Fax Number"
                            type="text"
                            min="1"
                            target="preLandLordFax"
                            onChange={this.onchange}
                            errorMessage="the fax number of previous Landlord you are living."
                            validate={this.checkValidate(
                              this.state.preLandLordFax
                            )}
                            value={this.state.preLandLordFax}
                          />
                        </div> */}
                        {/* <div className="col alignInput">
                          <ApplicationInput
                            name="Property Type"
                            target="preUnitType"
                            type="optional"
                            options={["Apartment", "Mortgage", "Other"]}
                            optionValue={["Apartment", "Mortgage", "Other"]}
                            onChange={this.onchange}
                            important="true"
                            errorMessage="unit type you are previous living"
                            validate={this.checkValidate(
                              this.state.preUnitType
                            )}
                            value={this.state.preUnitType}
                          />
                        </div> */}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {this.state.nowTab === 3 && (
          <>
            <div className="cardApplication pt-3 pb-3">
              <p className="subTitle stepTitle pt-3 textDark">
                Employment Details
              </p>
              <div className="row  pt-5 inputContainer pb-4">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed by"
                    type="text"
                    target="employmentCompany"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of your current company."
                    validate={this.checkValidate(this.state.employmentCompany)}
                    value={this.state.employmentCompany}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employer's Contact Name"
                    type="text"
                    target="employmentName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of your employer current company."
                    validate={this.checkMobile(this.state.employmentName)}
                    value={this.state.employmentName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employer’s Contact Number"
                    type="text"
                    target="employmentPhone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.employmentPhone)
                        ? "the phone number of your employer's current company."
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.employmentPhone)}
                    value={this.state.employmentPhone}
                  />
                </div>
                {/* <div className="col alignInput">
                  <ApplicationInput
                    name="Email"
                    type="email"
                    target="employmentEmail"
                    important="true"
                    onChange={this.onchange}
                    errorMessage={
                      this.checkValidate(this.state.employmentEmail)
                        ? "the email address of your current company."
                        : "current company email correct format"
                    }
                    validate={this.checkEmail(this.state.employmentEmail)}
                    value={this.state.employmentEmail}
                  />
                </div> */}
                <div className="col alignInput">
                  <ApplicationInput
                    name="Address"
                    type="text"
                    target="employmentAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the address of your current company."
                    validate={this.checkValidate(this.state.employmentAddress)}
                    value={this.state.employmentAddress}
                  />
                </div>
                <div className="col alignInput">
                  <SearchInput
                    name="employmentState"
                    id="Province"
                    target="employmentState"
                    onChange={this.onchange}
                    list={this.state.employmentStateList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the State of your current company "
                    validate={this.checkValidate(this.state.employmentState)}
                    value={this.state.employmentState}
                  />
                </div>

                <div className="col alignInput">
                  <SearchInput
                    name="employmentCity"
                    id="City"
                    target="employmentCity"
                    onChange={this.onchange}
                    list={this.state.employmentCityList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the city of your current company"
                    validate={this.checkValidate(this.state.employmentCity)}
                    value={this.state.employmentCity}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Zip"
                    type="number"
                    target="employmentZip"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    errorMessage="the Zip Code of your current company."
                    validate={this.checkValidate(this.state.employmentZip)}
                    value={this.state.employmentZip}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Salary(Monthly)"
                    type="number"
                    target="employmentSalary"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    errorMessage="your salary at the current company."
                    validate={this.checkValidate(this.state.employmentSalary)}
                    value={this.state.employmentSalary}
                    iconL={"fa-usd"}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Position"
                    type="text"
                    target="employmentPosition"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your position in your current company."
                    validate={this.checkValidate(this.state.employmentPosition)}
                    value={this.state.employmentPosition}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed From"
                    type="date"
                    format="yearFirst"
                    min={"1910-01-01"}
                    target="employmentFrom"
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.employmentFrom)
                        ? "your start date of your work."
                        : "date before " + this.state.employmentUntil
                    }
                    validate={this.checkPeriod(
                      this.state.employmentFrom,
                      this.state.employmentUntil
                    )}
                    max={
                      this.state.employmentUntil === ""
                        ? this.today()
                        : this.state.employmentUntil
                    }
                    value={this.state.employmentFrom}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed Until"
                    type="text"
                    target="employmentUntil"
                    value={"Present"}
                  />
                </div>
              </div>
            </div>
            <br></br>

            <p
              className="subTitle stepTitle  textDark pb-2"
              style={{ zIndex: "10" }}
            >
              Previous Employment Information&nbsp;&nbsp;&nbsp;&nbsp;
              <div
                className="pull-right preEmployeeHide1"
                style={{ zIndex: "900" }}
              >
                <ApplicationInput
                  name=""
                  type="checkedBox"
                  target="preEmploymentInfo"
                  onChange={this.onchangeCheck}
                  // important="true"
                  // errorMessage="sharedYard"
                  // validate={this.checkValidate(this.state.preEmploymentInfo)}
                  value={this.state.preEmploymentInfo}
                />
              </div>
            </p>

            <div
              className="subTitle stepTitle pt-5 pb-4 pull-left   preEmployeeHide2"
              style={{ zIndex: "900" }}
            >
              <ApplicationInput
                name=""
                type="checkedBox"
                target="preEmploymentInfo"
                onChange={this.onchangeCheck}
                // important="true"
                // errorMessage="sharedYard"
                // validate={this.checkValidate(this.state.preEmploymentInfo)}
                value={this.state.preEmploymentInfo}
              />
            </div>
            <br></br>

            {this.state.preEmploymentInfo && (
              <div className="cardApplication pt-3 pb-3">
                <div className="row inputContainer pt-3 pb-2">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employed by"
                      type="text"
                      target="preEmploymentCompany"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of your current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentCompany
                      )}
                      value={this.state.preEmploymentCompany}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employer's Contact Name"
                      type="text"
                      target="preEmploymentName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of your employer current company."
                      validate={this.checkMobile(this.state.preEmploymentName)}
                      value={this.state.preEmploymentName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employer's Contact Number"
                      type="text"
                      target="preEmploymentPhone"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the phone number of your employer current company."
                      validate={this.checkMobile(this.state.preEmploymentPhone)}
                      value={this.state.preEmploymentPhone}
                    />
                  </div>
                  {/* <div className="col alignInput">
                    <ApplicationInput
                      name="Email"
                      type="email"
                      target="preEmploymentEmail"
                      important="true"
                      onChange={this.onchange}
                      errorMessage={
                        this.checkValidate(this.state.preEmploymentEmail)
                          ? "the email address of your current company."
                          : "current company email correct format"
                      }
                      validate={this.checkEmail(this.state.preEmploymentEmail)}
                      value={this.state.preEmploymentEmail}
                    />
                  </div> */}
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Address"
                      type="text"
                      target="preEmploymentAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the address of your current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentAddress
                      )}
                      value={this.state.preEmploymentAddress}
                    />
                  </div>

                  <div className="col alignInput">
                    <SearchInput
                      name="preEmploymentState"
                      id="Province"
                      target="preEmploymentState"
                      onChange={this.onchange}
                      list={this.state.preEmploymentStateList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="your previous State"
                      validate={this.checkValidate(
                        this.state.preEmploymentState
                      )}
                      value={this.state.preEmploymentState}
                    />
                  </div>

                  <div className="col alignInput">
                    <SearchInput
                      name="preEmploymentCity"
                      id="City"
                      target="preEmploymentCity"
                      onChange={this.onchange}
                      list={this.state.preEmploymentCityList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="your previous City"
                      validate={this.checkValidate(
                        this.state.preEmploymentCity
                      )}
                      value={this.state.preEmploymentCity}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Zip"
                      type="number"
                      min="1"
                      target="preEmploymentZip"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the Zip Code of your current company."
                      validate={this.checkValidate(this.state.preEmploymentZip)}
                      value={this.state.preEmploymentZip}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Salary(Monthly)"
                      min="1"
                      type="number"
                      target="preEmploymentSalary"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="your salary at the current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentSalary
                      )}
                      value={this.state.preEmploymentSalary}
                      iconL={"fa-usd"}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Position"
                      type="text"
                      target="preEmploymentPosition"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="your position in your current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentPosition
                      )}
                      value={this.state.preEmploymentPosition}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employed From"
                      type="date"
                      format="yearFirst"
                      target="preEmploymentFrom"
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.preEmploymentFrom)
                          ? "your start date of your previous work."
                          : "date before " + this.state.preEmploymentUntil
                      }
                      min={"1910-01-01"}
                      validate={this.checkPeriod(
                        this.state.preEmploymentFrom,
                        this.state.preEmploymentUntil
                      )}
                      max={
                        this.state.preEmploymentUntil === ""
                          ? this.today()
                          : this.state.preEmploymentUntil
                      }
                      value={this.state.preEmploymentFrom}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employed Until"
                      type="date"
                      format="yearFirst"
                      target="preEmploymentUntil"
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.preEmploymentUntil)
                          ? "your end date of your previous work."
                          : "date after " + this.state.preEmploymentFrom
                      }
                      validate={this.checkPeriod(
                        this.state.preEmploymentFrom,
                        this.state.preEmploymentUntil
                      )}
                      max={
                        this.state.employmentFrom === ""
                          ? this.today()
                          : this.state.employmentFrom
                      }
                      min={this.state.preEmploymentFrom}
                      value={this.state.preEmploymentUntil}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {this.state.nowTab === 4 && (
          <div className="text-center mt-4">
            {this.state.pdfLink ? (
              // <embed src={this.state.pdfLink} width="800px" height="2100px" />
              <center>
                <div className="PDFWeb">
                  <object
                    data={this.state.pdfLink}
                    type="application/pdf"
                    width="800px"
                    height="2100px"
                  >
                    <p>
                      Your web browser doesn't have a PDF plugin. Instead you
                      can{" "}
                      <a href={this.state.pdfLink}>
                        click here to download the PDF file.
                      </a>
                    </p>
                  </object>
                </div>
                <div className="PDFMobile">
                  <object
                    data={this.state.pdfLink}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  >
                    <p>
                      Your web browser doesn't have a PDF plugin. Instead you
                      can{" "}
                      <a href={this.state.pdfLink}>
                        click here to download the PDF file.
                      </a>
                    </p>
                  </object>
                </div>
                <a className="pt-0 mt-0 PDFMobile " href={this.state.pdfLink}>
                  click here to view the full PDF file.
                </a>
              </center>
            ) : (
              <Loader />
            )}
          </div>
        )}
        {this.state.nowTab === 5 && (
          <AddTenantPayment
            data={this.props.UserData}
            status={this.state.stripe_account_status.data}
            status2={this.state.stripe_data.data}
            type="initial"
            next={this.afterPayment}
            role={this.props.role}
            appendAlert={this.props.appendAlert}
            paymentToken={this.state.paymentToken}
            setPaymentToken={this.setPaymentToken}
            changeTokenNull={this.changeTokenNull}
            propertyRent={this.state.applicationFee}
            setCustomerPaymentStatus={this.props.setCustomerPaymentStatus}
            setUser={this.props.setUser}
            stripe_type={this.state.stripe_type}
          />
        )}

        {this.state.footer && (
          <div
            className="row appFootButton text-right inputContainer  "
            style={{ paddingBottom: "40px", paddingTop: "40px" }}
          >
            <div className="col ">
              {this.state.nowTab > 0 && (
                <>
                  {" "}
                  <button
                    className="pri mr-3"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </button>
                  <button className="mr-3 pri" onClick={() => this.back()}>
                    Back
                  </button>
                </>
              )}
              {this.state.nowTab !== 5 &&
                this.state.nowTab !== 4 &&
                this.state.nowTab !== 0 &&
                (this.state.nextEnable ? (
                  <button className="buttonPri " onClick={() => this.next()}>
                    Next
                  </button>
                ) : (
                  <button className="secDisable " onClick={() => this.next()}>
                    Next
                  </button>
                ))}
              {this.state.nowTab === 5 &&
                (this.state.stripe_data.data !== "verified" ? (
                  <button
                    className={"secDisable notAllow"}
                    onclick={() =>
                      this.props.appendAlert(
                        "Please add payment method",
                        "warning"
                      )
                    }
                    disabled={true}
                  >
                    Proceed & Submit
                  </button>
                ) : (
                  <button
                    className={
                      !this.state.isButtonDisableFinish
                        ? "secDisable notAllow"
                        : " buttonPri"
                    }
                    // data-target="#finishedModal"
                    // data-toggle="modal"
                    // onClick={() => this.finished()}
                    onClick={
                      this.state.stripe_account_status.data !== "verified"
                        ? () => this.finished(this.state.stripe_data.data)
                        : () =>
                            this.finished(this.state.stripe_account_status.data)
                    }
                    disabled={!this.state.isButtonDisableFinish}
                  >
                    Proceed & Submit
                    {!this.state.isButtonDisableFinish && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                ))}
              {this.state.nowTab === 4 && (
                <>
                  <button
                    className={
                      !this.state.changeBlob
                        ? "secDisable notAllow"
                        : " buttonPri"
                    }
                    onClick={() => this.downloadPDF()}
                    data-target="#exampleModal1"
                    data-toggle="modal"
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>
                    {!this.state.changeBlob && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>{" "}
                  &nbsp;&nbsp;
                  {this.state.signatureObject2 !== null ? (
                    this.state.stripe_account_status.data !== "verified" ? (
                      <button
                        className="buttonPri "
                        onClick={() => this.next()}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className={
                          !this.state.isButtonDisableFinish
                            ? "secDisable notAllow"
                            : " buttonPri"
                        }
                        // data-target="#finishedModal"
                        // data-toggle="modal"
                        onClick={
                          this.state.stripe_account_status.data !== "verified"
                            ? () => this.finished(this.state.stripe_data.data)
                            : () =>
                                this.finished(
                                  this.state.stripe_account_status.data
                                )
                        }
                        disabled={!this.state.isButtonDisableFinish}
                      >
                        Proceed
                        {!this.state.isButtonDisableFinish && (
                          <Loader3 transY="50px" transX="0px" />
                        )}
                      </button>
                    )
                  ) : (
                    <button
                      className={" buttonPri"}
                      data-target="#signatureModal"
                      data-toggle="modal"
                    >
                      Sign here
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <LicenseModal
          pushAlert={this.props.appendAlert}
          changeLicense={this.changeLicense}
          licenseModal={this.state.licenseModal}
          changeLicenseData={this.changeLicenseData}
          keepWait={this.keepWait}
          removeKeepWait={this.removeKeepWait}
        />
        {this.state.downloadModal && (
          <ModalContainer
            // min="300px"
            max={window.innerHeight - 200}
            maxWidth={window.innerWidth}
            name="exampleModal1"
            title={"Download"}
            footer="false"
            heightAgreement="920"
            widthAgreement="290"
            footerDownload="true"
            minWidth="1000px"
            closeContract={this.closeDownloadModal}
          >
            <ApplicationReview1
              listingData={this.state.listingData}
              onchangeState={this.onchangeState}
              data={this.state}
              onchangeCheck={this.onchangeCheck}
              onchangeCheckOpp={this.onchangeCheckOpp}
              onChangeParent={this.onChangeParent}
              onchangeCheckOption={this.onchangeCheckOption}
              mobile="true"
            />
          </ModalContainer>
        )}
        {this.state.finishedModal && (
          <SmallModalContainer
            name="finishedModal"
            dark={this.props.dark}
            title="Confirmation"
            // footer="false"
            confirmCommand={
              this.state.applicationFee === 0
                ? "Proceed"
                : "Proceed to checkout"
            }
            confirm={
              this.state.stripe_account_status.data !== "verified"
                ? () => this.finished(this.state.stripe_data.data)
                : () => this.finished(this.state.stripe_account_status.data)
            }
            // closeContract={()=>this.setState({finishedModal:false})}
          >
            <div className="p-3">
              <span className="span7" style={{ fontSize: "16px" }}>
                {" "}
                {this.state.applicationFee === 0
                  ? "No Application Fee will be charged when you submit this application"
                  : "Application Fee, " +
                    this.state.applicationFee +
                    ".00 USD will be charged when you submit this application"}
              </span>
            </div>
          </SmallModalContainer>
        )}
        <SmallModalContainer
          name="signatureModal"
          dark={this.props.dark}
          title="Please sign here"
          footer="false"
          size="lg"
        >
          <div className="row">
            <div className="col ">
              <button
                className={
                  this.state.signatureTab === "pad"
                    ? "mr-2 tabSignature"
                    : "mr-2  tabSignature2"
                }
                onClick={() =>
                  this.setState({ signatureTab: "pad", signatureObject2: null })
                }
              >
                Draw
              </button>
              <button
                className={
                  this.state.signatureTab === "upload"
                    ? "mr-2  tabSignature"
                    : "mr-2  tabSignature2"
                }
                onClick={() =>
                  this.setState({
                    signatureTab: "upload",
                    signatureObject2: null,
                  })
                }
              >
                Upload
              </button>
            </div>
          </div>

          <div className={"tab-content  borderSec"} id="myTabContent">
            <div
              className={
                this.state.signatureTab === "pad"
                  ? "tab-pane fade show active "
                  : "tab-pane fade "
              }
              id="upload"
              role="tabpanel"
              aria-labelledby="upload-tab"
            >
              <div className="d-flex justify-content-center pt-5 pb-4">
                <SignaturePad
                  name="applicationLast"
                  submitAlign="pull-right"
                  submitName="Submit"
                  type="base64"
                  value={this.state.signatureObject2}
                  submit={this.changeSignatureObject2}
                  submit2={this.changeSignatureObject3}
                  type2={true}
                  submitDisable={true}
                  maxWidth="450"
                />
              </div>
            </div>
            <div
              className={
                this.state.signatureTab === "upload"
                  ? "tab-pane fade show active "
                  : "tab-pane fade "
              }
              id="upload"
              role="tabpanel"
              aria-labelledby="upload-tab"
            >
              <div className="d-flex justify-content-start mb-5">
                <Dropzone
                  accept=".jpeg,.png,.jpg"
                  maxSize={10485760}
                  onDrop={(files) => {
                    files.map((file) => {
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        if (event.target.result === undefined) {
                          this.props.appendAlert(
                            "Image size is too Long (max size = 10mb) ",
                            "danger"
                          );
                        } else {
                          this.setState({
                            signatureObject2: event.target.result,
                          });
                        }
                      };
                      reader.readAsDataURL(file);
                      return file;
                    });
                  }}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {this.state.signatureObject2 === null ? (
                          <div className="text-left p-4">
                            <div
                              className="dragDrop text-center  pointer"
                              style={{
                                fontSize: "60px",
                                minHeight: "250px",
                                paddingTop: "70px",
                              }}
                            >
                              <div className="row">
                                <div className="col">
                                  <i
                                    className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <p className="licenseHead">
                                    Drag Or Click to Upload
                                  </p>
                                </div>
                              </div>
                            </div>
                            <span className="span5 textPri ">
                              Max file size is 10MB per image. JPG, PNG, or GIF
                              formats only.
                            </span>
                          </div>
                        ) : (
                          <div className=" text-center ">
                            <img
                              src={this.state.signatureObject2}
                              className="licenseImage dragDrop pointer"
                              alt="propsImage"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Dropzone>
              </div>
              <div className="row">
                <div className="col ">
                  <button
                    className="buttonSquareLinePri pull-left ml-3 mt-3"
                    onClick={() => this.setState({ signatureObject2: null })}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                  {this.state.signatureObject2 !== null ? (
                    <button
                      className=" mr-3 pull-right mb-3"
                      onClick={() =>
                        this.changeSignatureObject2(this.state.signatureObject2)
                      }
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="secDisable pull-right mb-3 mr-3"
                      style={{ cursor: "not-allowed" }}
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Please provide your signature image"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SmallModalContainer>
        <SmallModalContainer
          name="mailCheck"
          dark={this.props.dark}
          title="Hi, have you been here before?"
          // footer="false"
          rejectCheck="true"
          confirmCommand="Continue"
          rejectCommand="Try another e-mail"
          reject={() => this.emailReject()}
          size="lg"
          confirm={() => this.emailAccept()}
          // closeContract={()=>this.setState({finishedModal:false})}
        >
          <div className="p-3">
            <span className="span7" style={{ fontSize: "16px" }}>
              {" "}
              A user with this e-mail already exists. How do you want to
              continue?
            </span>
            <br></br>
            <br></br>
            <i className="fa fa-info-circle " aria-hidden="true"></i>
            &nbsp;&nbsp;
            <span className="searchListContentApp">
              If you choose to continue with existing details(Name,Mobile),
              Press continue.
            </span>
            <br></br>
            <span className="searchListContentApp">
              &nbsp;&nbsp;&nbsp;&nbsp;Else try with another e-mail.
            </span>
          </div>
        </SmallModalContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setCustomerPaymentStatus, setUser })(
    ListingApplicationBritishColumbia
  )
);
export class AddTenantPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: CookieService.getCookie("axTok"),
      data: this.props.data,
      accountStatus: "",
      type: "",
      activePaymentMethod: false,
      isLoading: true,
      isButtonDisable: true,
      account_holder_name: "",
      account_holder_type: "individual",
      routing_number: "",
      account_number: "",
      verifiedAcc: "",
      verifiedId: "",
      token_id: "",
      bank_account_id: "",
      deposit1: "",
      deposit2: "",
      nextPayment: false,

      nextPayment1: false,
      accountNumbers: [],
      accountNum: "",
      accountId: [],
      customerData: [],

      addLoading: false,
      verifiedLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
  }
  async componentDidMount() {
    this.setState({
      accountStatus: this.props.status2 ? this.props.status2 : "",
    });

    checkPaymentStatus().then((response) => {
      if (response.status === 200) {
        this.setState({
          accountStatus: response.data.data,
        });
        this.props.setCustomerPaymentStatus(response.data.data);
        userDetail(this.props.data.username).then((response) => {
          if (response.status === 200) {
            CookieService.setArrayCookie("axUserDetails", response.data, 7);
            this.props.setUser(response.data);
          } else {
            // this.props.appendAlert("Something went wrong!, Please try again later.", "danger");
          }
        });
      } else {
        this.props.appendAlert(
          "Something went wrong!, Please try again later.",
          "danger"
        );
      }
    });

    if (this.props.data.stripeCustomerId !== null) {
      try {
        getCustomerPayment().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              let x = [];
              let y = [];
              response.data.sources.data.map((data, index) =>
                response.data.defaultSource !== data.id
                  ? (x.push("**** **** ****" + data.last4), y.push(data.id))
                  : this.setState({
                      status: data.status,
                      verifiedAcc: data.last4,
                      verifiedId: data.id,
                    })
              );
              this.setState({
                accountId: y,
                accountNumbers: x,
                customerData: response.data,
                isLoading: false,
              });
            } else {
              console.log("No data!");
              this.setState({ noData: true, isLoading: false });
            }
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  checkValidate(value) {
    return value === "" && this.state.nextPayment;
  }
  checkValidate1(value) {
    return value === "" && this.state.nextPayment1;
  }
  handleSubmit = async () => {
    if (
      this.state.account_holder_name === "" ||
      this.state.routing_number === "" ||
      this.state.account_number === ""
    ) {
      this.setState({ nextPayment: true });
    } else {
      this.setState({ nextPayment: false, addLoading: true });

      try {
        this.setState({ isButtonDisable: false });
        let formBody = [];
        let details = {
          "bank_account[country]": "US",
          "bank_account[currency]": "usd",
          "bank_account[account_holder_name]": this.state.account_holder_name,
          "bank_account[account_holder_type]": this.state.account_holder_type,
          "bank_account[routing_number]": this.state.routing_number,
          "bank_account[account_number]": this.state.account_number,
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        stripeToken(formBody).then(async (response) => {
          if (response.status) {
            this.setState({
              token_id: response.data.id,
              bank_account_id: response.data.bank_account.id,
            });
            let item = {
              email: this.props.data.username,
              token: this.state.token_id,
              bankAccountId: this.state.bank_account_id,
            };
            let form_data = [];
            for (let key in item) {
              let encodedKey = encodeURIComponent(key);
              let encodedValue = encodeURIComponent(item[key]);
              form_data.push(encodedKey + "=" + encodedValue);
            }
            form_data = form_data.join("&");
            await addPaymentMethod(this.state.type, form_data)
              .then((response2) => {
                this.setState({ addLoading: false });
                if (response2.data) {
                  this.setState({ nextPayment: false });
                  this.setState({ accountStatus: "new" });
                  this.props.appendAlert("Please Verify the amounts", "info");
                  checkPaymentStatus().then((response2) => {
                    if (response2.status === 200) {
                      if (response2.status) {
                        this.props.setCustomerPaymentStatus(
                          response2.data.data
                        );
                        this.props.next(response2.data, "bank");
                        userDetail(this.props.data.username).then(
                          (response) => {
                            if (response.status === 200) {
                              CookieService.setArrayCookie(
                                "axUserDetails",
                                response.data,
                                7
                              );
                              this.props.setUser(response.data);
                            } else {
                              // this.props.appendAlert("Something went wrong!, Please try again later.", "danger");
                            }
                          }
                        );
                      }
                    }
                  });
                } else {
                  if (response2.data === undefined) {
                    if (response2.response.data !== undefined) {
                      this.props.appendAlert(
                        response2.response.data.message,
                        "danger"
                      );
                    } else {
                      this.props.appendAlert("Try again later", "warning");
                    }
                  }
                }
                this.setState({ isButtonDisable: true });
              })
              .catch((res3) => {
                this.props.appendAlert(
                  "Error in creating customer.Try again later!",
                  "danger"
                );
              });
            if (this.state.customerData.length === 0) {
              try {
                getCustomerPayment().then((response) => {
                  if (response.status === 200) {
                    if (response.data.length !== 0) {
                      let x = [];
                      let y = [];
                      response.data.sources.data.map((data, index) =>
                        response.data.defaultSource !== data.id
                          ? (x.push("**** **** ****" + data.last4),
                            y.push(data.id))
                          : this.setState({
                              status: data.status,
                              verifiedAcc: data.last4,
                              verifiedId: data.id,
                            })
                      );
                      this.setState({
                        accountId: y,
                        accountNumbers: x,
                        customerData: response.data,
                        isLoading: false,
                        addLoading: false,
                      });
                    } else {
                      console.log("No data!");
                      this.setState({
                        noData: true,
                        isLoading: false,
                        addLoading: false,
                      });
                    }
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                    this.props.appendAlert(
                      "Something went wrong!, Please try again later.",
                      "danger"
                    );
                  }
                });
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            this.setState({
              noData: true,
              isLoading: false,
              addLoading: false,
            });
            if (
              response.response.data.error.code === "account_number_invalid"
            ) {
              this.props.appendAlert(
                "Account number Invalid. Please type correct account number",
                "danger"
              );
            } else if (response.response.data.error.code) {
              this.props.appendAlert(
                response.response.data.error.message,
                "warning"
              );
            } else {
              this.setState({
                isLoading: false,
              });
              this.props.appendAlert(
                "Something went wrong!, Please try again later.",
                "danger"
              );
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  verifyAccount = async () => {
    this.setState({ isButtonDisable: false, verifiedLoading: true });
    if (this.state.deposit1 === "" || this.state.deposit2 === "") {
      this.setState({ nextPayment: true });
    } else {
      this.setState({ nextPayment: false });
      try {
        let formBody = [];
        let details = {
          bankAccoutnId:
            this.state.bank_account_id !== ""
              ? this.state.bank_account_id
              : this.state.customerData.defaultSource,
          customerId: this.state.customerData.id,
          deposit1: parseInt(parseFloat(this.state.deposit1) * 100),
          deposit2: parseInt(parseFloat(this.state.deposit2) * 100),
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        verifyPaymentMethod(formBody).then((response) => {
          this.setState({ verifiedLoading: false });
          if (
            response.data.data === "Your account verified successfully verified"
          ) {
            if (this.props.data.stripeCustomerId === null) {
              userDetail(this.props.data.username).then((response1) => {
                if (response1.status === 200) {
                  CookieService.setArrayCookie(
                    "axUserDetails",
                    response1.data,
                    7
                  );
                  this.props.setUser(response1.data);
                  checkPaymentStatus().then((response2) => {
                    if (response2.status === 200) {
                      if (response2.status) {
                        this.props.setCustomerPaymentStatus(
                          response2.data.data
                        );
                        this.props.next(response2.data, "bank");
                      }
                    }
                  });
                  this.setState({
                    isButtonDisable: true,
                    verifiedLoading: false,
                  });
                }
              });
            } else {
              checkPaymentStatus().then((response2) => {
                if (response2.status === 200) {
                  if (response2.status) {
                    this.props.setCustomerPaymentStatus(response2.data.data);
                    this.props.next(response2.data, "bank");
                  }
                }
              });
              this.setState({ isButtonDisable: true, verifiedLoading: false });
            }
            this.props.appendAlert("Verified successfully.", "success");
          } else {
            this.props.appendAlert(
              "Invalidate verification amount !!!",
              "danger"
            );
            this.setState({ isButtonDisable: true, verifiedLoading: false });
          }
        });
      } catch (error) {
        this.setState({ isButtonDisable: true, verifiedLoading: false });
        throw error;
      }
    }
  };

  render() {
    return (
      <div className="medium-container" style={{ marginTop: "10px" }}>
        <div className="container-fluid">
          {this.props.stripe_type !== "bank" && (
            <>
              {this.state.accountStatus !== "new" && (
                <>
                  <div className="row mb-3 pr-3 pl-3 cardApplication ">
                    <div className="col">
                      {this.props.status2 === "verified" ? (
                        <div className="mt-3 mb-5 pb-3">
                          <p className="stepTitlePayment">
                            Payment Information
                          </p>
                          <br></br>
                          <div className=" cardApplication2 p-3 pt-2 pb-2">
                            <i
                              class="fa fa-credit-card-alt pr-4 pl-2"
                              aria-hidden="true"
                            ></i>{" "}
                            <span className="stepTitlePayment text-dark">
                              Card Added
                            </span>{" "}
                            <i
                              class="fa fa-check-square pull-right pt-1 "
                              style={{ color: "#40bb6d" }}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <StripeProvider apiKey={stripeKey}>
                          <Elements>
                            <CardPayment
                              access_token={this.state.access_token}
                              email={
                                this.state.data
                                  ? this.state.data.username
                                  : "email"
                              }
                              user={this.props.data}
                              mode="temp"
                              history={this.props.history}
                              type={this.state.type}
                              pushAlert={this.props.appendAlert}
                              next={this.props.next}
                              role={this.props.role}
                              paymentToken={this.props.paymentToken}
                              setPaymentToken={this.props.setPaymentToken}
                              changeTokenNull={this.props.changeTokenNull}
                              setCustomerPaymentStatus={
                                this.props.setCustomerPaymentStatus
                              }
                              setUser={this.props.setUser}
                            />
                          </Elements>
                        </StripeProvider>
                      )}
                    </div>
                    <div className="col ">
                      <div className="informationListAppNormal">
                        <i
                          className="fa fa-info-circle "
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;
                        <span className="searchListContentApp">
                          Application Fee = &nbsp;&nbsp;
                          <CurrencyFormat value={this.state.applicationFee} />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {this.props.stripe_type === "" && (
            <>
              {this.state.accountStatus !== "new" &&
                this.state.accountStatus !== "verification_failed" && (
                  <div
                    className="divider"
                    style={{ marginTop: "30px", marginBottom: "30px" }}
                  >
                    <span></span>
                    <span>OR</span>
                    <span></span>
                  </div>
                )}
            </>
          )}
          {this.props.stripe_type !== "card" && (
            <>
              <div className="row   pt-3 cardApplication pr-3 pl-3 pb-4 pm-2 ">
                {this.props.status2 === "verified" ? (
                  <div className="col">
                    <div className="mt-3 mb-5 pb-3">
                      <p className="stepTitlePayment">Payment Information</p>
                      <br></br>
                      <div className=" cardApplication2 p-3 pt-2 pb-2">
                        <i
                          class="fa fa-university pr-4 pl-2"
                          aria-hidden="true"
                        ></i>{" "}
                        <span className="stepTitlePayment text-dark">
                          Bank Information Added
                        </span>{" "}
                        <i
                          class="fa fa-check-square pull-right pt-1 "
                          style={{ color: "#40bb6d" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.accountStatus === "new" ||
                    this.state.accountStatus === "verification_failed" ? (
                      <>
                        <div className="col ">
                          <h1 className="one pl-0 pb-3 pt-2">Verify ACH</h1>
                          <ApplicationInput
                            name="Deposit Amount 1"
                            type="text"
                            target="deposit1"
                            place="Deposit Amount 1."
                            onChange={this.handleChange}
                            important="true"
                            errorMessage="Deposit Amount 1."
                            validate={this.checkValidate(this.state.deposit1)}
                            value={this.state.deposit1}
                          />
                          <ApplicationInput
                            name="Deposit Amount 2"
                            type="text"
                            target="deposit2"
                            onChange={this.handleChange}
                            important="true"
                            place="Deposit Amount 2."
                            errorMessage="Deposit Amount 2."
                            validate={this.checkValidate(this.state.deposit2)}
                            value={this.state.deposit2}
                          />
                          <br></br>
                          <div className="pull-right ">
                            <div
                              className=" "
                              style={{
                                marginRight: "10px",
                                marginBottom: "-20px",
                              }}
                            >
                              {this.state.verifiedLoading && <Loader3 />}
                            </div>
                            <button
                              type="submit"
                              onClick={() => this.verifyAccount()}
                              className={
                                this.state.verifiedLoading
                                  ? "secDisable "
                                  : "buttonPri "
                              }
                              disabled={
                                this.state.verifiedLoading && "disabled"
                              }
                            >
                              Verify Amounts
                            </button>
                          </div>
                          {(this.state.status === "new" ||
                            this.state.accountStatus ===
                              "verification_failed") &&
                            this.state.accountNumbers.length > 0 &&
                            (this.state.account_number === "" ||
                              this.state.account_number ===
                                this.state.verifiedAcc.substr(
                                  this.state.verifiedAcc.length - 4
                                )) && (
                              <>
                                <h1 className="one pt-5 pb-3 pl-0 mt-5">
                                  Do you want to add another account as default.
                                </h1>
                                <ApplicationInput
                                  name="Account Number"
                                  target="accountNum"
                                  type="optional"
                                  options={this.state.accountNumbers}
                                  optionValue={this.state.accountId}
                                  onChange={this.handleChange}
                                  important="true"
                                  errorMessage="account number"
                                  validate={this.checkValidate1(
                                    this.state.accountNum
                                  )}
                                  value={this.state.accountNum}
                                />
                                <div className="text-right pt-3">
                                  <button
                                    type="submit"
                                    className="pri"
                                    onClick={() => this.addCard()}
                                  >
                                    Change as default
                                  </button>
                                </div>
                              </>
                            )}
                        </div>
                      </>
                    ) : (
                      <div className="col pb-4">
                        <h1 className="one pl-0 pb-3 pt-2">
                          <i
                            className="fa fa-university pr-3"
                            aria-hidden="true"
                          ></i>
                          Bank account
                        </h1>
                        <ApplicationInput
                          name="Full Name"
                          type="text"
                          target="account_holder_name"
                          onChange={this.handleChange}
                          important="true"
                          place="Full Name"
                          errorMessage="Full Name"
                          validate={this.checkValidate(
                            this.state.account_holder_name
                          )}
                          value={this.state.account_holder_name}
                        />
                        <ApplicationInput
                          name="Routing Number"
                          type="text"
                          target="routing_number"
                          onChange={this.handleChange}
                          important="true"
                          place="110000000"
                          errorMessage="Routing Number"
                          validate={this.checkValidate(
                            this.state.routing_number
                          )}
                          value={this.state.routing_number}
                        />
                        <ApplicationInput
                          name="Account Number"
                          type="text"
                          target="account_number"
                          onChange={this.handleChange}
                          important="true"
                          place="000123456789"
                          errorMessage="Account Number"
                          validate={this.checkValidate(
                            this.state.account_number
                          )}
                          value={this.state.account_number}
                        />
                        <br></br>
                        <br></br>

                        <div className="pull-right">
                          <div
                            className=" "
                            style={{
                              marginRight: "10px",
                              marginBottom: "-20px",
                            }}
                          >
                            {this.state.addLoading && <Loader3 />}
                          </div>
                          <button
                            type="submit"
                            className={
                              this.state.addLoading
                                ? "secDisable "
                                : "buttonPri "
                            }
                            disabled={this.state.addLoading && "disabled"}
                            onClick={() => this.handleSubmit()}
                          >
                            Add Account
                          </button>
                        </div>
                      </div>
                    )}
                    <br></br>
                    <div className="col ">
                      <h1 className="one pl-0 pt-2">
                        Steps to verify your ACH payment method.
                      </h1>
                      <br></br>
                      <div className="informationListAppNormal mt-1">
                        <span className="spanRound">1</span>&nbsp;&nbsp;
                        <span className="searchListContentApp">
                          System automatically sends two small deposits for
                          verification purpose. These deposits take 1-2 business
                          days to appear on your online statement.
                        </span>
                      </div>
                      <div className="informationListAppNormal mt-1">
                        <span
                          className={
                            this.state.accountStatus === "new" ||
                            this.state.accountStatus === "verified"
                              ? "spanRound"
                              : "spanRoundDark"
                          }
                        >
                          2
                        </span>
                        &nbsp;&nbsp;
                        <span className="searchListContentApp">
                          The statement has a description that includes AMTS
                          followed by the two micro deposit amounts.
                        </span>
                      </div>
                      <div className="informationListAppNormal mt-1">
                        <span
                          className={
                            this.state.accountStatus === "verified"
                              ? "spanRound"
                              : "spanRoundDark"
                          }
                        >
                          3
                        </span>
                        &nbsp;&nbsp;
                        <span className="searchListContentApp">
                          Once you receive the two micro deposit amounts, update
                          it in your payment portal.
                        </span>
                      </div>
                      {/* {this.state.status === "new" &&
                (this.state.account_number === "" ||
                  this.state.account_number ===
                    this.state.verifiedAcc.substr(
                      this.state.verifiedAcc.length - 4
                    )) && (
                  <>
                    <button
                      type="submit"
                      className="pri pull-right mt-4"
                      onClick={() =>
                        this.setState({ accountStatus: "add", type: "addNew" })
                      }
                    >
                      Add New Account
                    </button>
                  </>
                )} */}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
