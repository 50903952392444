// sudo kill -9 $(sudo lsof -t -i:3000)

import React, { useState, useEffect, useCallback } from "react";

import "./profile.css";
import Dropzone from "react-dropzone";
import image from "../../../Images/profile.png";
import {
  checkPaymentAccountDetail,
  stripeConnect,
  // stripeKey,
  updateUserDetails,
  uploadProfileImage,
  url,
  userDetail,
} from "../../../Services/ApiServices";
import Loader3 from "../../Loader/loader3";
import { useDispatch, useSelector } from "react-redux";
import {
  appendAlert,
  setUser,
  appendTempProfileImage,
  setOpenProfileAlert,
  setAccountPaymentStatus,
  // changePercentageProfileAlert,
} from "../../../actions";
import CookieService from "../../../Services/CookieService";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import SearchInput from "../Application/SearchInput";
import { CountryJson } from "../Property/CountryJson";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// import AddressForm from "./AddressForm";
import PhoneInput from "../../UIServices/PhoneInput/PhoneInput";
import ApplicationInput from "../../UIServices/ApplicationInput";
import { stripeToken } from "../../../Services/ApiServices";
// import {
//   CardElement,
//   useStripe,
//   useElements,
// } from "@stripe/react-stripe-js";

const LandlordProfile = (props) => {
  // const options = {
  //   // Fully customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };

  const countryFilter = (country) => {
    if (country === "US") {
      return "United States";
    } else if (country === "CA") {
      return "Canada";
    } else {
      return country;
    }
  };
  const onChangeDate = (name, value) => {
    setState({ ...state, [name]: value });
  };
  const onchange = (e) => {
    let { name, value } = e.target;
    let num = value.replace(/[^\d]/g, "");

    if (name === "ssn") {
      if (num.length > 4) {
        setState({ ...state, [name]: state[name] });
      } else {
        setState({ ...state, [name]: value });
      }
    } else {
      setState({ ...state, [name]: value });
    }
  };
  const checkValidate = (value) => {
    return value === "" && nextPayment;
  };
  const user = useSelector((state) => state.user);
  // const customerPaymentStatus = useSelector((state) => state.customerPayment);
  const [state, setState] = React.useState({
    fName: user.firstName ? user.firstName : "",
    lName: user.lastName ? user.lastName : "",
    email: user.username ? user.username : "",
    mobile: user.mobileNumber ? user.mobileNumber : "",
    mobileCode: user.mobileCode ? user.mobileCode : "+1",
    occupation: user.occupation ? user.occupation : "",
    street: user.address
      ? user.address.address
        ? user.address.address
        : ""
      : "",
    street2: user.address
      ? user.address.address2
        ? user.address.address2
        : ""
      : "",
    country: user.address
      ? user.address.country
        ? countryFilter(user.address.country)
        : "United States"
      : "United States",
    zip: user.address ? (user.address.zip ? user.address.zip : "") : "",
    state: user.address ? (user.address.state ? user.address.state : "") : "",
    city: user.address ? (user.address.city ? user.address.city : "") : "",
    dob: "",
    ssn: "",
    routing_number: "",
    account_number: "",
    transit_number: "",
    institution_number: "",

    nPass: "",
    oPass: "",
    countryList: [],
    stateList: [],
    cityList: [],
    countryJson: [],
    stateJson: [],
    cityJson: [],
  });
  const [next, setNext] = useState({
    fName: false,
    lName: false,
    email: false,
    mobile: false,
    mobileCode: false,
    occupation: false,
    street: false,
    street2: false,
    country: false,
    zip: false,
    state: false,
    city: false,
  });
  const [profilePercentage, setProfilePercentage] = useState(360);
  const [coverImage, changeCoverImage] = useState([]);
  const tempProfileImages = useSelector((state) => state.tempProfileImages);
  const accountPayment = useSelector((state) => state.accountPayment);
  const [profileImage, changeProfileImage] = useState(image);
  const [profileImageLoader, changeProfileImageLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rememberPass, setRememberPass] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [loading, loadingChange] = useState(false);
  const [nextPayment, nextPaymentChange] = useState(false);
  // const [paymentType, changePaymentType] = useState("bank");
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user.username === "" ||
      user.username === null ||
      user.firstName === "" ||
      user.firstName === null ||
      user.lastName === "" ||
      user.lastName === null ||
      user.address.state === "" ||
      user.address.state === null ||
      user.address.country === "" ||
      user.address.country === null ||
      user.address.zip === "" ||
      user.address.zip === null ||
      user.address.city === "" ||
      user.address.city === null ||
      user.address.address === "" ||
      user.address.address === null ||
      user.mobileNumber === "" ||
      user.mobileNumber === null
    ) {
      dispatch(appendAlert("Please enter your profile information", "warning"));
    } else {
      if (props.location.state) {
        if (props.location.state.rentalEntityId) {
          if (props.location.state.rentalEntityId !== null) {
            props.history.push({
              pathname: "/rental-units/" + props.location.state.rentalEntityId,
            });
          } else {
            props.history.push({
              pathname: "/rental-units",
            });
          }
        } else {
          props.history.push({
            pathname: "/rental-units",
          });
        }
      }
    }
    checkPaymentAccountDetail().then((response) => {
      if (response.status === 200) {
        if (response.data.capabilities.card_payments === "active") {
          dispatch(
            setAccountPaymentStatus(response.data.capabilities.card_payments)
          );
          if (response.data.external_accounts.data.length > 0) {
            setState({
              ...state,
              ssn: " # # # # ",
              routing_number:
                response.data.external_accounts.data[0].routing_number,
              account_number:
                "*********" + response.data.external_accounts.data[0].last4,
              dob:response.data.individual?
                (response.data.individual.dob.year < 10
                  ? "0" + response.data.individual.dob.year.toString()
                  : response.data.individual.dob.year.toString()) +
                "-" +
                (response.data.individual.dob.month < 10
                  ? "0" + response.data.individual.dob.month.toString()
                  : response.data.individual.dob.month.toString()) +
                "-" +
                (response.data.individual.dob.day < 10
                  ? "0" + response.data.individual.dob.day.toString()
                  : response.data.individual.dob.day.toString()):"",
            });
          } else {
            setState({
              ...state,
              ssn: " # # # # ",

              dob:response.data.individual?
                (response.data.individual.dob.year < 10
                  ? "0" + response.data.individual.dob.year.toString()
                  : response.data.individual.dob.year.toString()) +
                "-" +
                (response.data.individual.dob.month < 10
                  ? "0" + response.data.individual.dob.month.toString()
                  : response.data.individual.dob.month.toString()) +
                "-" +
                (response.data.individual.dob.day < 10
                  ? "0" + response.data.individual.dob.day.toString()
                  : response.data.individual.dob.day.toString()):"",
            });
          }
        } else if (response.data.capabilities.card_payments === "pending") {
          dispatch(
            setAccountPaymentStatus(response.data.capabilities.card_payments)
          );
          // dispatch(
          //   appendAlert(
          //     "Please wait while your request is being processed , else add another account",
          //     "warning"
          //   )
          // );
        } else if (response.data.capabilities.card_payments === "inactive") {
          dispatch(
            setAccountPaymentStatus(response.data.capabilities.card_payments)
          );
        } else {
          dispatch(
            setAccountPaymentStatus(response.data.capabilities.card_payments)
          );
        }
      } else {
        // dispatch(
        //   appendAlert(
        //     "Something went wrong!, Please try again later.",
        //     "danger"
        //   )
        // );
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let count = 0;
    if (state.email !== "" && state.email !== null) {
      count++;
    }
    if (state.fName !== "" && state.fName !== null) {
      count++;
    }
    if (state.lName !== "" && state.lName !== null) {
      count++;
    }
    if (state.mobile !== "" && state.mobile !== null) {
      count++;
    }
    if (state.street !== "" && state.street !== null) {
      count++;
    }
    if (state.city !== "" && state.city !== null) {
      count++;
    }
    if (state.state !== "" && state.state !== null) {
      count++;
    }
    if (state.zip !== "" && state.zip !== null) {
      count++;
    }
    if (state.country !== "" && state.country !== null) {
      count++;
    }
    setProfilePercentage((count / 9) * 360);
  }, [state]);
  const setFun = useCallback(async () => {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";

    const fetchImage = (username, url) => {
      const toDataURL = (url1) =>
        fetch(url1)
          .then((response) => {
            if (response.status === 200) {
              return response.blob();
            } else {
              throw new Error(response);
            }
          })
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          )
          .catch((res) => {
            throw new Error(res);
          });

      toDataURL(url)
        .then((dataUrl) => {
          changeProfileImage(dataUrl);

          dispatch(
            appendTempProfileImage({
              username: username,
              image: dataUrl,
            })
          );
        })
        .catch((res) => {
          changeProfileImage(image);
          dispatch(
            appendTempProfileImage({
              username: username,
              image: image,
            })
          );
        });
    };
    const setImage = (data) => {
      if (data.provider === "" || data.provider === null) {
        changeProfileImage(image);
        dispatch(
          appendTempProfileImage({
            username: data.username,
            image: image,
          })
        );
      } else if (data.provider === "local") {
        if (data.coverImage === null) {
          changeProfileImage(image);
        } else {
          fetchImage(
            data.username,
            url + `api/user/${data.username}/image/download`
          );
        }
      } else {
        fetchImage(data.username, data.coverImage);
      }
    };
    const filterImage = (data) => {
      let usersImage = tempProfileImages.filter(
        (todo) => todo.username === data.username
      );
      if (usersImage.length === 0) {
        setImage(data);
      } else {
        changeProfileImage(usersImage[0].image);
      }
    };
    filterImage(user);
  }, [user, dispatch, tempProfileImages]);

  const setFun1 = useCallback(async () => {
    const json = await CountryJson();
    let countryList = [];
    json.map((data, index) => countryList.push(data.name));

    let selectedCountry = json.filter(
      (country) =>
        country.name.replace(/\s/g, "").toLowerCase() ===
        state.country.replace(/\s/g, "").toLowerCase()
    );
    let countryData = [];
    let countryData2 = [];
    let stateList = [];
    let selectedStates = [];
    let cityList = [];
    if (selectedCountry.length !== 0) {
      // selectedCountry[0].states.map((data, index) => stateList.push(data.name));
      selectedCountry[0].states.map((data) => {
        stateList.push(data.name);
        countryData = countryData.concat(data.cities);
        return data;
      });
      countryData.map((data) => {
        countryData2.push(data.name);
        return data;
      });
      selectedStates = selectedCountry[0].states.filter(
        (state1) =>
          state1.name.replace(/\s/g, "").toLowerCase() ===
          state.state.replace(/\s/g, "").toLowerCase()
      );
      cityList = countryData2;

      if (selectedStates.length !== 0) {
        selectedStates[0].cities.map((data, index) => cityList.push(data.name));
      }
    }

    setState({
      ...state,
      countryList: countryList,
      countryJson: json,
      stateList: stateList,
      stateJson: selectedCountry[0] ? selectedCountry[0].states : [],
      cityList: cityList,
      cityJson: selectedStates[0] ? selectedStates[0].cities : [],
    });
    // eslint-disable-next-line
  }, [user, dispatch, state]);

  useEffect(() => {
    setFun1();
    setFun();
    if (CookieService.checkCookie("rememberPassword")) {
      if (CookieService.getCookie("rememberPassword") === "true") {
        setRememberPass(true);
      }
    }

    // eslint-disable-next-line
  }, [setFun]);

  function handleSubmit() {
    if (
      state.dob === "" ||
      state.ssn === "" ||
      state.routing_number === "" ||
      state.account_number === ""
    ) {
      nextPaymentChange(true);
    } else {
      try {
        loadingChange(true);
        let formBody = [];
        let details = {
          "bank_account[country]": "US",
          "bank_account[currency]": "usd",
          "bank_account[account_holder_name]": state.fName + state.lName,
          "bank_account[account_holder_type]": "individual",
          "bank_account[routing_number]": state.routing_number,
          "bank_account[account_number]": state.account_number,
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        stripeConnectFun(formBody);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function handleSubmitCanada() {
    if (
      state.dob === "" ||
      state.ssn === "" ||
      state.transit_number === "" ||
      state.institution_number === "" ||
      state.account_number === ""
    ) {
      nextPaymentChange(true);
    } else {
      try {
        loadingChange(true);
        let formBody = [];
        let details = {
          "bank_account[country]": "CA",
          "bank_account[currency]": "cad",
          "bank_account[account_holder_name]": state.fName + state.lName,
          "bank_account[account_holder_type]": "individual",
          "bank_account[routing_number]":
            state.transit_number + state.institution_number,
          "bank_account[account_number]": state.account_number,
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        stripeConnectFun(formBody);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function stripeConnectFun(formBody) {
    stripeToken(formBody).then(async (response) => {
      if (response.status) {
        stripeConnectAccount(response.data.id);
      } else {
        loadingChange(false);
        if (response.response.data.error.code === "account_number_invalid") {
          dispatch(
            appendAlert(
              "Account number Invalid. Please type correct account number",
              "danger"
            )
          );
        } else if (
          response.response.data.error.code === "routing_number_invalid"
        ) {
          dispatch(
            appendAlert(
              "Routing number Invalid. Please type correct routing number",
              "danger"
            )
          );
        } else if (
          response.response.data.error.code ===
          "bank_account_bad_routing_numbers"
        ) {
          dispatch(
            appendAlert(
              "The bank account is known to not support the currency in question.",
              "danger"
            )
          );
        } else if (response.response.data.error.code) {
          dispatch(
            appendAlert(response.response.data.error.message, "warning")
          );
        } else {
          dispatch(
            appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            )
          );
        }
      }
    });
  }

  async function stripeConnectAccount(id) {
    var parts = state.dob.split("-");
    const body = {
      businessProfile: {
        extraParams: null,
        mcc: "6513",
        name: null,
        productDescription: null,
        supportAddress: null,
        supportEmail: null,
        supportPhone: null,
        supportUrl: null,
        url: "www.axallant.com",
      },
      businessType: "INDIVIDUAL",
      company: null,
      country: "US",
      defaultCurrency: null,
      email: state.email,
      expand: null,
      externalAccount: id,
      extraParams: null,
      individual: {
        address: {
          city: state.city,
          country: "US",
          extraParams: null,
          line1: state.street,
          line2: state.street2,
          postalCode: state.zip,
          state: state.state,
        },
        addressKana: null,
        addressKanji: null,
        dob: {
          day: parseInt(parts[2]),
          extraParams: null,
          month: parseInt(parts[1]),
          year: parseInt(parts[0]),
        },
        email: state.email,
        extraParams: null,
        firstName: state.fName,
        gender: null,
        idNumber: null,
        lastName: state.lName,
        maidenName: null,
        metadata: null,
        phone: state.mobile,
        ssnLast4: state.ssn,
        verification: null,
      },
      requestedCapabilities: ["CARD_PAYMENTS", "TRANSFERS"],
      type: "CUSTOM",
    };

    await stripeConnect(body)
      .then((response2) => {
        if (response2.status === 200) {
          if (response2.data) {
           
            checkPaymentAccountDetail().then((response) => {
             
              if (response.status === 200) {
                if (response.data.capabilities.card_payments === "active") {
                  dispatch(
                    setAccountPaymentStatus(
                      response.data.capabilities.card_payments
                    )
                  );
                } else if (
                  response.data.capabilities.card_payments === "pending"
                ) {
                  loadingChange(false);
                  dispatch(
                    setAccountPaymentStatus(
                      response.data.capabilities.card_payments
                    )
                  );
                  // dispatch(
                  //   appendAlert(
                  //     "Please wait while your request is being processed",
                  //     "warning"
                  //   )
                  // );
                } else if (
                  response.data.capabilities.card_payments === "inactive"
                ) {
                  dispatch(
                    setAccountPaymentStatus(
                      response.data.capabilities.card_payments
                    )
                  );
                } else {
                  dispatch(
                    setAccountPaymentStatus(
                      response.data.capabilities.card_payments
                    )
                  );
                  console.log(response.data);
                }
                userDetail(state.email).then((response3) => {
                  if (response3.status === 200) {
                    nextPaymentChange(false);
                    dispatch(appendAlert("Payment method added successfully", "info"));
                    props.history.push({
                      pathname: "/properties",
                    });
                    CookieService.setArrayCookie(
                      "axUserDetails",
                      response3.data,
                      7
                    );
                    dispatch(setUser(response3.data));
                  }
                });
              } else {

                nextPaymentChange(false);
                dispatch(
                  appendAlert(
                    "Something went wrong!, Please try again later.",
                    "danger"
                  )
                );
              }
            });
          } else {
            nextPaymentChange(false);
            if (response2.data === undefined) {
              if (response2.response.data !== undefined) {
                dispatch(
                  appendAlert(response2.response.data.message, "danger")
                );
              } else {
                dispatch(appendAlert("Try again later", "warning"));
              }
            }
          }
        } else {
          if (response2.response.data.message !== undefined) {
            var message = response2.response.data.message;
            if (
              message.includes("Must be at least 13 years of age to use Stripe")
            ) {
              dispatch(
                appendAlert(
                  "Must be at least 13 years of age to use our system",
                  "danger"
                )
              );
            } else if (
              message.includes(
                "Invalid SSN last 4. SSN last 4 must be exactly four digits"
              )
            ) {
              dispatch(
                appendAlert(
                  "Invalid SSN last 4. SSN last 4 must be exactly four digits",
                  "danger"
                )
              );
            } else if (
              response2.response.data.error.code === "account_number_invalid"
            ) {
              dispatch(
                appendAlert(
                  "Account number Invalid. Please type correct account number",
                  "danger"
                )
              );
            } else if (
              response2.response.data.error.code === "routing_number_invalid"
            ) {
              dispatch(
                appendAlert(
                  "Routing number Invalid. Please type correct routing number",
                  "danger"
                )
              );
            } else if (
              response2.response.data.error.code ===
              "bank_account_bad_routing_numbers"
            ) {
              dispatch(
                appendAlert(
                  "The bank account is known to not support the currency in question.",
                  "danger"
                )
              );
            } else if (response2.response.data.error.code) {
              dispatch(
                appendAlert(response2.response.data.error.message, "warning")
              );
            } else {
              dispatch(appendAlert(response2.response.data.message, "danger"));
            }
          }
        }
        loadingChange(false);
      })
      .catch((res3) => {
        console.log(res3);
        dispatch(
          appendAlert("Error in creating customer.Try again later!", "danger")
        );
      });
  }

  function updateProfileImage(Image) {
    if (Image === undefined) {
      dispatch(
        appendAlert("Image size is too Long (max size = 10mb) ", "danger")
      );
    } else {
      let tempImage = coverImage;
      changeCoverImage(Image);
      changeProfileImageLoader(true);
      try {
        const formData = new FormData();
        formData.append("file", Image);
        uploadProfileImage(formData)
          .then((response) => {
            if (response.status === 200) {
              changeProfileImageLoader(false);
              dispatch(appendAlert("Profile Image Changed", "success"));

              userDetail(state.email).then((response) => {
                if (response.status === 200) {
                  dispatch(setUser(response.data));
                  CookieService.setArrayCookie(
                    "axUserDetails",
                    response.data,
                    7
                  );
                }
              });
            } else {
              changeCoverImage(tempImage);
              changeProfileImageLoader(false);
              dispatch(
                appendAlert(
                  "Something went wrong!, Please try again later.",
                  "danger"
                )
              );
            }
          })
          .catch((err) => {
            dispatch(appendAlert("Image upload failed", "danger"));
            console.log(err);
          });
      } catch (error) {}
    }
  }

  function update(save) {
    if (
      state.fName === "" ||
      state.lName === "" ||
      state.email === "" ||
      state.state === "" ||
      state.zip === "" ||
      state.city === "" ||
      state.street === "" ||
      state.mobile === ""
    ) {
      setState({
        ...state,
        fName: state.fName === "" ? "" : state.fName,
        lName: state.lName === "" ? "" : state.lName,
        email: state.email === "" ? "" : state.email,
        state: state.state === "" ? "" : state.state,
        city: state.city === "" ? "" : state.city,
        zip: state.zip === "" ? "" : state.zip,
        street: state.street === "" ? "" : state.street,
        mobile: state.mobile === "" ? "" : state.mobile,
      });
      setNext({
        ...next,
        fName: state.fName === "",
        lName: state.lName === "",
        email: state.email === "",
        mobile: state.mobile === "",
        state: state.state === "",
        city: state.city === "",
        zip: state.zip === "",
        street: state.street === "",
      });
    } else {
      setLoader(true);
      let body = user;
      body.username = state.email;
      body.firstName = state.fName;
      body.lastName = state.lName;
      body.mobileNumber = state.mobile;
      body.address.address = state.street;
      body.address.address2 = state.street2;
      body.address.city = state.city;
      if (state.country === "United States") {
        body.address.country = "US";
      } else if (state.country === "Canada") {
        body.address.country = "CA";
      } else {
        body.address.country = state.country;
      }
      body.address.state = state.state;
      body.address.zip = state.zip;

      updateUserDetails(body)
        .then(async (response) => {
          setLoader(false);
          setDisableUpdate(true);
          if (response.status === 200) {
            dispatch(
              appendAlert("Account details successfully Updated", "success")
            );

            dispatch(setOpenProfileAlert(false));
            // props.history.push({
            //   pathname: "/properties",
            // });
            if (save) {
              props.history.push({ pathname: "/profile", hash: "#payment" });
            }
          } else if (response.status === 400) {
            dispatch(appendAlert("Now this service not available", "warning"));
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                const text = response.response.data.message;

                if (text.search("phone") !== -1) {
                  dispatch(
                    appendAlert("Please enter a valid mobile no.", "warning")
                  );
                }
                if (text.search("state") !== -1) {
                  dispatch(
                    appendAlert("Please enter a valid state.", "warning")
                  );
                }
                if (text.search("city") !== -1) {
                  dispatch(
                    appendAlert("Please enter a valid city.", "warning")
                  );
                }
                if (text.search("postal_code") !== -1) {
                  dispatch(
                    appendAlert("Please enter a valid state.", "warning")
                  );
                }
                // if (text.search("address")) {
                //   dispatch(appendAlert("Please enter a valid address.", "warning"));
                // }
                setState({
                  ...state,
                  state: text.search("state") !== -1 ? "" : state.state,
                  city: text.search("city") !== -1 ? "" : state.city,
                  zip: text.search("postal_code") !== -1 ? "" : state.zip,
                  // street: text.search("address") ? "" : state.street,
                  mobile: text.search("phone") !== -1 ? "" : state.mobile,
                });
                setNext({
                  ...next,
                  mobile: text.search("phone") !== -1,
                  state: text.search("state") !== -1,
                  city: text.search("city") !== -1,
                  zip: text.search("postal_code") !== -1,
                  // street: text.search("address")!==-1,
                });
              } else {
                dispatch(
                  appendAlert(
                    "Something went wrong!, Please try again later.",
                    "danger"
                  )
                );
              }
            }
          }
          userDetail(state.email).then((response) => {
            if (response.status === 200) {
              CookieService.setArrayCookie("axUserDetails", response.data, 7);
              dispatch(setUser(response.data));
            }
          });
        })
        .catch((error) => {
          dispatch(appendAlert(error.message, "danger"));
        });
    }
  }
  async function changeState(name, value) {
    if (
      user.username !== (name === "email" ? value : state.email) ||
      user.firstName !== (name === "fName" ? value : state.fName) ||
      user.lastName !== (name === "lName" ? value : state.lName) ||
      user.mobileNumber !== state.mobile ||
      user.address.address !== (name === "street" ? value : state.street) ||
      user.address.address2 !== (name === "street2" ? value : state.street2) ||
      user.address.city !== (name === "city" ? value : state.city) ||
      user.address.state !== (name === "state" ? value : state.state) ||
      user.address.zip !== state.zip
    ) {
      setDisableUpdate(false);
    } else {
      setDisableUpdate(true);
    }
    if (name === "state") {
      let selectedCities = state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        selectedCities[0].cities.map((data, index) => cityList.push(data.name));
        setState({
          ...state,
          cityList: cityList,
          city: "",
          [name]: value,
          cityJson: selectedCities[0].cities,
        });
      } else {
        setState({ ...state, city: "" });
        setState({ ...state, cityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        selectedCountry[0].states.map((data, index) =>
          stateList.push(data.name)
        );
        setState({
          ...state,
          [name]: value,
          state: "",
          city: "",
          stateList: stateList,
          stateJson: selectedCountry[0].states,
        });
      } else {
        setState({
          ...state,
          [name]: value,
          state: "",
          city: "",
          stateList: [],
          cityList: [],
        });
      }
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
    setNext({ ...next, [name]: false });
  }
  function check() {
    if (CookieService.checkCookie("rememberPassword")) {
      if (CookieService.getCookie("rememberPassword") === "true") {
        setRememberPass(false);
        CookieService.setCookie("rememberPassword", "false", 7);
      } else {
        setRememberPass(true);
        CookieService.setCookie("rememberPassword", "true", 7);
      }
    }
  }
  async function onChange(e) {
    let {
      target: { name, value },
    } = e;
    if (name === "zip") {
      setNext({ ...next, zip: false });
      let num = value.replace(/[^\d]/g, "");
      value = num;

      if (
        user.username !== state.email ||
        user.firstName !== state.fName ||
        user.lastName !== state.lName ||
        user.mobileNumber !== state.mobile ||
        user.address.address !== state.street ||
        user.address.address2 !== state.street2 ||
        user.address.city !== state.city ||
        user.address.state !== state.state ||
        user.address.zip !== value
      ) {
        setDisableUpdate(false);
      } else {
        setDisableUpdate(true);
      }
      await setState({ ...state, [name]: value });
    } else if (name === "mobile") {
      setNext({ ...next, mobile: false });
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await setState({ ...state, [name]: state[name] });
      } else {
        await setState({ ...state, [name]: value });
      }
      if (
        user.username !== state.email ||
        user.firstName !== state.fName ||
        user.lastName !== state.lName ||
        user.mobileNumber !== value ||
        user.address.address !== state.street ||
        user.address.address2 !== state.street2 ||
        user.address.city !== state.city ||
        user.address.state !== state.state ||
        user.address.zip !== state.zip
      ) {
        setDisableUpdate(false);
      } else {
        setDisableUpdate(true);
      }
    } else {
      setNext({ ...next, [name]: false });
      await setState({ ...state, [name]: value });
      if (
        user.username !== (name === "email" ? value : state.email) ||
        user.firstName !== (name === "fName" ? value : state.fName) ||
        user.lastName !== (name === "lName" ? value : state.lName) ||
        user.mobileNumber !== state.mobile ||
        user.address.address !== (name === "street" ? value : state.street) ||
        user.address.address2 !==
          (name === "street2" ? value : state.street2) ||
        user.address.city !== (name === "city" ? value : state.city) ||
        user.address.state !== (name === "state" ? value : state.state) ||
        user.address.zip !== state.zip
      ) {
        setDisableUpdate(false);
      } else {
        setDisableUpdate(true);
      }
    }
  }

  return (
    <>
      <style>
        {`
          .progressProfileApp .progressProfileApp-right .progressProfileApp-bar {
            animation: loading-3 1.8s linear forwards;
          }
          .progressProfileApp.blue .progressProfileApp-left .progressProfileApp-bar {
            animation: loading-4 1.5s linear forwards 1.8s;
          }
          .progressProfileApp.red .progressProfileApp-left .progressProfileApp-bar {
            animation: loading-4 1.5s linear forwards 1.8s;
          }
          @keyframes loading-3 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }

            100% {
              -webkit-transform: rotate(` +
          (profilePercentage > 180 ? 180 : profilePercentage) +
          `deg);
              transform: rotate(` +
          (profilePercentage > 180 ? 180 : profilePercentage) +
          `deg);
            }
          }

          @keyframes loading-4 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }

            100% {
              -webkit-transform: rotate(` +
          (profilePercentage > 180 ? profilePercentage - 180 : 0) +
          `deg);
              transform: rotate(` +
          (profilePercentage > 180 ? profilePercentage - 180 : 0) +
          `deg);
            }
          }

          `}
      </style>
      <div
        style={{
          marginTop: "30px",
          minHeight: window.screen.height,
        }}
      >
        <div className="container-fluid pt-4">
          <div className="row gutters">
            <div
              className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
              style={{ marginTop: "50px" }}
            >
              <div className="card h-100 profileCard pt-3">
                <div className="">
                  <div className="account-settings">
                    <div className="user-profile">
                      <div className="user-avatar ">
                        <p className="p-0 m-0">
                          {" "}
                          {profilePercentage === 360
                            ? ""
                            : "Remaining: " +
                              Math.round(
                                100 - (profilePercentage / 360) * 100
                              ) +
                              "%"}
                        </p>

                        {coverImage.length === 0 ? (
                          // <img src={profileImage} alt="profileImage" />

                          <div className="row ">
                            <div className="col d-flex justify-content-center">
                              <div className="progressProfileApp red">
                                {" "}
                                <span className="progressProfileApp-left">
                                  {" "}
                                  <span className="progressProfileApp-bar"></span>{" "}
                                </span>{" "}
                                <span className="progressProfileApp-right">
                                  {" "}
                                  <span className="progressProfileApp-bar"></span>{" "}
                                </span>
                                <div
                                  className="progressProfileApp-value "
                                  style={{
                                    backgroundImage:
                                      "url(" + profileImage + ")",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          // <img
                          //   src={URL.createObjectURL(coverImage)}
                          //   alt="profileImage"
                          // />
                          <div className="row ">
                            <div className="col d-flex justify-content-center">
                              <div className="progressProfileApp red">
                                {" "}
                                <span className="progressProfileApp-left">
                                  {" "}
                                  <span className="progressProfileApp-bar"></span>{" "}
                                </span>{" "}
                                <span className="progressProfileApp-right">
                                  {" "}
                                  <span className="progressProfileApp-bar"></span>{" "}
                                </span>
                                <div
                                  className="progressProfileApp-value"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      URL.createObjectURL(coverImage) +
                                      ")",
                                  }}
                                >
                                  {(profilePercentage / 360) * 100}%
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <br></br>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            updateProfileImage(acceptedFiles[0]);
                          }}
                          multiple={false}
                          accept=".jpeg,.png,.jpg"
                          maxSize={10485760}
                          disabled={profileImageLoader}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <button
                                className={
                                  profileImageLoader
                                    ? "secDisable notAllow  mt-0 mr-5 ml-5 "
                                    : " mt-0 mr-5 ml-5 priButton"
                                }
                              >
                                Change
                              </button>
                            </div>
                          )}
                        </Dropzone>
                        {profileImageLoader && (
                          <Loader3 transY="40px" transX="0px" />
                        )}
                      </div>
                      <h5 className="user-name text-dark profileHead mt-5 pt-3">
                        {state.fName}&nbsp;
                        {state.lName}
                      </h5>
                      <h6 className="user-email contentProfile">
                        {state.username}
                      </h6>
                    </div>
                    <div className="about">
                      <h5 className="mb-2 text-primary text-dark profileHead">
                        <b>About</b>
                      </h5>
                      <p className="contentProfile">I'm a landlord.</p>
                      <button
                        name="cPass"
                        className="pri  mt-5"
                        onClick={() =>
                          props.history.push({ pathname: "/change-password" })
                        }
                      >
                        Change Password
                      </button>
                      <div
                        className="custom-control custom-checkbox  pointer pt-3"
                        onClick={() => check()}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input listCheck pointer"
                          id="rememberPass"
                          name="rememberPass"
                          checked={rememberPass && "checked"}
                          style={{ background: "#000" }}
                        />
                        <label
                          className="custom-control-label span2 pt-1 pointer"
                          htmlFor="customCheck1"
                        >
                          Remember password
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12"
              style={{ marginTop: "50px" }}
            >
              <div className="card h-100 profileCard ">
                <div class="top_nav_profile mb-3">
                  <a
                    class={
                      props.location.hash !== "#payment"
                        ? "active mb-0"
                        : "mb-0"
                    }
                    href="#account"
                  >
                    Account Settings
                  </a>
                  <a
                    class={
                      props.location.hash === "#payment"
                        ? "active mb-0"
                        : "mb-0"
                    }
                    onClick={
                      user.username === "" ||
                      user.username === null ||
                      user.firstName === "" ||
                      user.firstName === null ||
                      user.lastName === "" ||
                      user.lastName === null ||
                      user.address.state === "" ||
                      user.address.state === null ||
                      user.address.country === "" ||
                      user.address.country === null ||
                      user.address.zip === "" ||
                      user.address.zip === null ||
                      user.address.city === "" ||
                      user.address.city === null ||
                      user.address.address === "" ||
                      user.address.address === null ||
                      user.mobileNumber === "" ||
                      user.mobileNumber === null
                        ? () =>
                            dispatch(
                              appendAlert(
                                "Please enter your profile information",
                                "warning"
                              )
                            )
                        : () => {}
                    }
                    href={
                      user.username === "" ||
                      user.username === null ||
                      user.firstName === "" ||
                      user.firstName === null ||
                      user.lastName === "" ||
                      user.lastName === null ||
                      user.address.state === "" ||
                      user.address.state === null ||
                      user.address.country === "" ||
                      user.address.country === null ||
                      user.address.zip === "" ||
                      user.address.zip === null ||
                      user.address.city === "" ||
                      user.address.city === null ||
                      user.address.address === "" ||
                      user.address.address === null ||
                      user.mobileNumber === "" ||
                      user.mobileNumber === null
                        ? "#account"
                        : "#payment"
                    }
                  >
                    Payment Settings{" "}
                  </a>
                </div>
                {props.location.hash !== "#payment" ? (
                  <div className="card-body">
                    <div className="row gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3  profileHead">
                          <b>Profile</b>
                        </h6>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="firstName" className="profileLabel">
                            First Name
                          </label>
                          <input
                            type="text"
                            className={
                              next.fName
                                ? "form-control tag alert1"
                                : "form-control tag"
                            }
                            name="fName"
                            placeholder=""
                            onChange={onChange}
                            value={state.fName}
                          />
                          {next.fName && (
                            <p className="spanValidateApp textPri">
                              Please Enter the first name
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="lastName" className="profileLabel">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className={
                              next.lName
                                ? "form-control tag alert1"
                                : "form-control tag"
                            }
                            name="lName"
                            onChange={onChange}
                            placeholder=""
                            value={state.lName}
                          />
                          {next.lName && (
                            <p className="spanValidateApp textPri">
                              Please Enter the Last name
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="eMail" className="profileLabel">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control tag"
                            name="email"
                            onChange={onChange}
                            placeholder=""
                            disabled="disabled"
                            value={state.email}
                          />
                          {next.email && (
                            <p className="spanValidateApp textPri">
                              Please Enter the Email
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <PhoneInput
                          alert={next.mobile}
                          onChange={onChange}
                          changeState={changeState}
                          mobile={state.mobile}
                          mobileCode={state.mobileCode}
                          title="Phone"
                        />
                        {next.mobile && (
                          <p className="spanValidateApp textPri">
                            Please Enter the phone
                          </p>
                        )}
                      </div>
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="occupation" className="profileLabel">
                        Occupation
                      </label>
                      <input
                        type="url"
                        className="form-control tag"
                        name="occupation"
                        onChange={onChange}
                        placeholder="Enter Occupation"
                      />
                    </div>
                  </div> */}
                    </div>
                    <div className="row gutters ">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3 mt-3 profileHead">
                          <b>Address</b>
                        </h6>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="ciTy" className="profileLabel">
                            Country
                          </label>

                          <SearchInput
                            name="country"
                            id="null"
                            type="3"
                            target="country"
                            onChange={onChange}
                            list={state.countryList}
                            validate={next.country}
                            important={true}
                            errorMessage="the country"
                            // inputDisable={true}
                            changeState={changeState}
                            value={state.country}
                            // searchDisable={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="sTate" className="profileLabel">
                            State or Province
                          </label>
                          <SearchInput
                            name="state"
                            id="null"
                            type="3"
                            target="state"
                            onChange={onChange}
                            list={state.stateList}
                            // inputDisable={true}
                            errorMessage="the state"
                            important={true}
                            validate={next.state}
                            changeState={changeState}
                            value={state.state}
                            // searchDisable={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="ciTy" className="profileLabel">
                            City
                          </label>

                          <SearchInput
                            name="city"
                            id="null"
                            type="3"
                            target="city"
                            onChange={onChange}
                            list={state.cityList}
                            // inputDisable={true}
                            errorMessage="the city"
                            validate={next.city}
                            changeState={changeState}
                            value={state.city}
                            important={true}
                            // searchDisable={true}
                          />
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="zIp" className="profileLabel">
                            Zip Code
                          </label>
                          <input
                            type="text"
                            className={
                              next.zip
                                ? "form-control tag alert1"
                                : "form-control tag"
                            }
                            name="zip"
                            onChange={onChange}
                            placeholder=""
                            value={state.zip}
                          />
                          {next.zip && (
                            <p className="spanValidateApp textPri">
                              Please Enter the zip
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="Street" className="profileLabel">
                            Address line 1
                          </label>
                          <input
                            type="name"
                            className={
                              next.street
                                ? "form-control tag alert1"
                                : "form-control tag"
                            }
                            name="street"
                            onChange={onChange}
                            placeholder=""
                            value={state.street}
                          />
                          {next.street && (
                            <p className="spanValidateApp textPri">
                              Please Enter the address
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="Street" className="profileLabel">
                            Address line 2
                          </label>
                          <input
                            type="name"
                            className={
                              next.street2
                                ? "form-control tag alert1"
                                : "form-control tag"
                            }
                            name="street2"
                            onChange={onChange}
                            placeholder=""
                            value={state.street2}
                          />
                          {next.street2 && (
                            <p className="spanValidateApp textPri">
                              Please Enter the address
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <Elements stripe={stripe} options={options}>
                    <AddressForm />
                  </Elements> */}
                    <div className="row gutters mt-3">
                      <div className="col-12 ">
                        <div className="">
                          <button
                            name="submit"
                            className={
                              disableUpdate
                                ? "secDisable click pull-right"
                                : "priButton click pull-right"
                            }
                            disabled={
                              disableUpdate ? "disabled" : loader && "disabled"
                            }
                            onClick={() =>
                              update(
                                user.username === "" ||
                                  user.username === null ||
                                  user.firstName === "" ||
                                  user.firstName === null ||
                                  user.lastName === "" ||
                                  user.lastName === null ||
                                  user.address.state === "" ||
                                  user.address.state === null ||
                                  user.address.country === "" ||
                                  user.address.country === null ||
                                  user.address.zip === "" ||
                                  user.address.zip === null ||
                                  user.address.city === "" ||
                                  user.address.city === null ||
                                  user.address.address === "" ||
                                  user.address.address === null ||
                                  user.mobileNumber === "" ||
                                  user.mobileNumber === null
                              )
                            }
                          >
                            {user.username === "" ||
                            user.username === null ||
                            user.firstName === "" ||
                            user.firstName === null ||
                            user.lastName === "" ||
                            user.lastName === null ||
                            user.address.state === "" ||
                            user.address.state === null ||
                            user.address.country === "" ||
                            user.address.country === null ||
                            user.address.zip === "" ||
                            user.address.zip === null ||
                            user.address.city === "" ||
                            user.address.city === null ||
                            user.address.address === "" ||
                            user.address.address === null ||
                            user.mobileNumber === "" ||
                            user.mobileNumber === null
                              ? "Save"
                              : "Update"}
                            {loader && <Loader3 transY="50px" transX="0px" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="row ">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3  profileHead">
                          <b>Payment Info</b>
                        </h6>
                        {process.env.REACT_APP_STATUS !== "Production" && (
                  <div
                    className="col-12  d-flex flex-row-reverse"
                    style={{ marginTop: "-40px", marginBottom: "10px" }}
                  >
                    <span
                      class="badge p-2 text-white pointer"
                      onClick={() => setState({
                        ...state,
                        ssn: "0000",
                        routing_number:"110000000",
                        account_number:"000123456789",
                        dob:"1901-01-01",
                        transit_number: "12345",
                        institution_number: "678",
                      
                      })}
                      style={{ backgroundColor: "#42dc13" }}
                    >
                      Test Data
                    </span>
                  </div>
                )}
                      </div>
                      {/* {paymentType === "bank" ? (
                        <div class="col-3 p-0 m-0 pl-2 ">
                          <div className=" cardApplication3 p-3 pt-2 pb-2 borderBlack2">
                            <i
                              class="fa fa-university pr-4 pl-2"
                              aria-hidden="true"
                            ></i>{" "}
                            <span className="stepTitlePayment text-dark">
                              Bank Account
                            </span>{" "}
                            <i
                              class="fa fa-check-square pull-right pt-1 "
                              style={{ color: "#000" }}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="col-3 p-0 m-0 pl-2 pointer"
                          onClick={() => changePaymentType("bank")}
                        >
                          <div className=" cardApplication3 p-3 pt-2 pb-2">
                            <i
                              class="fa fa-university pr-4 pl-2"
                              aria-hidden="true"
                            ></i>{" "}
                            <span className="stepTitlePayment text-dark">
                              Bank Account
                            </span>{" "}
                          </div>
                        </div>
                      )} */}
                      {/* {paymentType === "card" ? (
                        <div class="col-3 p-0 m-0">
                          <div className=" cardApplication3 p-3 pt-2 pb-2 borderBlack2">
                            <i
                              class="fa fa-credit-card-alt pr-4 pl-2"
                              aria-hidden="true"
                            ></i>{" "}
                            <span className="stepTitlePayment text-dark">
                              Debit Card
                            </span>{" "}
                            <i
                              class="fa fa-check-square pull-right pt-1 "
                              style={{ color: "#000" }}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="col-3 p-0 m-0 pointer"
                          onClick={() => changePaymentType("card")}
                        >
                          <div className=" cardApplication3 p-3 pt-2 pb-2 ">
                            <i
                              class="fa fa-credit-card-alt pr-4 pl-2"
                              aria-hidden="true"
                            ></i>{" "}
                            <span className="stepTitlePayment text-dark">
                              Debit Card
                            </span>{" "}
                          </div>
                        </div>
                      )} */}
                    </div>
                    <div className="row gutters ">
                      {/* {paymentType === "card" ? (
                        <div className="col">
                          <StripeProvider apiKey={stripeKey}>
                            <Elements stripe={stripe}>
                              <StripeUI
                                state={state}
                                loading={loading}
                                loadingChange={loadingChange}
                                nextPaymentChange={nextPaymentChange}
                                stripeConnectAccount={stripeConnectAccount}
                              />
                            </Elements>
                          </StripeProvider>
                        </div>
                      ) : ( */}

                      {user.country === "Canada" ? (
                        <div className="col-6">
                          <ApplicationInput
                            name="Transit Number"
                            type="text"
                            target="transit_number"
                            onChange={onchange}
                            important="true"
                            place="12345"
                            designType="2"
                            errorMessage="Transit Number"
                            validate={checkValidate(state.transit_number)}
                            value={state.transit_number}
                            backColor="rgb(241, 241, 241)"
                          />
                          <ApplicationInput
                            name="Institution Number"
                            type="text"
                            target="institution_number"
                            onChange={onchange}
                            important="true"
                            designType="2"
                            place="678"
                            errorMessage="Institution Number"
                            validate={checkValidate(state.institution_number)}
                            value={state.institution_number}
                            backColor="rgb(241, 241, 241)"
                          />
                          <ApplicationInput
                            name="Account Number"
                            type="text"
                            target="account_number"
                            onChange={onchange}
                            designType="2"
                            important="true"
                            place="000123456789"
                            errorMessage="Account Number"
                            validate={checkValidate(state.account_number)}
                            value={state.account_number}
                            backColor="rgb(241, 241, 241)"
                          />
                        </div>
                      ) : (
                        <div className="col-6">
                          <ApplicationInput
                            name="Routing Number"
                            type="text"
                            target="routing_number"
                            onChange={onchange}
                            important="true"
                            designType="2"
                            place="110000000"
                            errorMessage="Routing Number"
                            validate={checkValidate(state.routing_number)}
                            value={state.routing_number}
                            backColor="rgb(241, 241, 241)"
                          />
                          <ApplicationInput
                            name="Account Number"
                            type="text"
                            target="account_number"
                            onChange={onchange}
                            designType="2"
                            important="true"
                            place="000123456789"
                            errorMessage="Account Number"
                            validate={checkValidate(state.account_number)}
                            value={state.account_number}
                            backColor="rgb(241, 241, 241)"
                          />
                        </div>
                      )}

                      {/* )} */}
                    </div>
                    <div className="row gutters mt-4">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-3  profileHead">
                          <b>Additional Info</b>
                        </h6>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <ApplicationInput
                            name="Date of Birth"
                            type="date"
                            target="dob"
                            designType="2"
                            min={"1900-01-01"}
                            onChange={onChangeDate}
                            important="true"
                            errorMessage="contract end date"
                            validate={checkValidate(state.dob)}
                            value={state.dob}
                            backColor="rgb(241, 241, 241)"
                          />
                        </div>
                        <div className="form-group">
                          <ApplicationInput
                            name="Last 4 digits of Social Security number"
                            type={state.ssn === " # # # # " ? "text" : "number"}
                            target="ssn"
                            designType="2"
                            backColor="rgb(241, 241, 241)"
                            onChange={onchange}
                            iconL={"# # #  -   #  #   -  "}
                            iconLPadding="90px"
                            important="true"
                            errorMessage="contract end date"
                            validate={checkValidate(state.ssn)}
                            value={state.ssn}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="pull-right">
                          <button
                            type="submit"
                            className={loading ? "secDisable " : "buttonPri "}
                            disabled={loading && "disabled"}
                            onClick={
                              user.country === "Canada"
                                ? () => handleSubmitCanada()
                                : () => handleSubmit()
                            }
                          >
                            {accountPayment === "active"
                              ? "Change Account"
                              : "Add Account"}
                          </button>
                          <div
                            className=" "
                            style={{
                              marginRight: "10px",
                              marginTop: "45px",
                            }}
                          >
                            {loading && <Loader3 />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(LandlordProfile);

// const StripeUI = (props) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const dispatch = useDispatch();

//   async function handleSubmit(event) {
//     event.preventDefault();
//     if (props.state.dob === "" || props.state.ssn === "") {
//       props.nextPaymentChange(true);
//     } else {
//       try {
//         props.loadingChange(true);
//         const { error, token } = await stripe.createToken(
//           elements.getElement(CardElement)
//         );
//         if (error) {
//           props.loadingChange(false);
//           dispatch(appendAlert(error.message, "warning"));
//         } else {
//           console.log("[Token]", token.ID);
//           props.stripeConnectAccount(token.ID);
//         }
//       } catch (error) {
//         console.log(error);
//         throw error;
//       }
//     }
//   }
//   const cardElementOptions = {
//     style: {
//       base: {
//         fontSize: "16px",
//         color: "#32325d",
//         "::placeholder": {
//           color: "#aab7c4",
//         },
//       },
//     },
//     value: {
//       "cardholder-name": "John Doe",
//       number: "4242424242424242",
//       exp_month: "12",
//       exp_year: "2023",
//       cvc: "123",
//       zip: "2222",
//     },
//   };
//   return (
//     <div>
//       <form
//         onSubmit={handleSubmit}
//         className="form-group  rounded pt-4"
//         id="cardElement"
//       >
//         <div className="col-6">
//           <CardElement
//             hidePostalCode={true}
//             className="p-2 border border-light shadow-sm mt-2"
//             options={cardElementOptions}
//           />
//         </div>
//         <br></br>
//         <br></br>
//         <div className="pull-right">
//           <button
//             type="submit"
//             className={props.loading ? "secDisable " : "buttonPri "}
//             disabled={props.loading && "disabled"}
//           >
//             Add Account
//           </button>
//           <div
//             className=" "
//             style={{
//               marginRight: "10px",
//               marginTop: "45px",
//             }}
//           >
//             {props.loading && <Loader3 />}
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };
