export const demoDataBCContract = (userDetail) => {
  let date = new Date();
  let date2 = new Date();
  date.setDate(date.getDate() + 15);
  date2.setDate(date2.getDate() + 10);
  let formattedMoveInDate = date.toISOString().slice(0, 10);
  let securityDepositPayBy = date2.toISOString().slice(0, 10);
  return {
    nextEnable: true,
    landlordUnit: "Zora",
    landlordStreet: userDetail.address.address,
      landlordCity:userDetail.address.city,
      landlordProvince: userDetail.address.state,
      landlordPostalCode: userDetail.address.zip,
      landlordDayTimePhoneNumber: userDetail.mobileNumber,
    landlordPhoneNumber: "",
    landlordFaxNumber: "",
    landlordAgentUnit: "Zora",
    landlordAgentStreet: "4119 Wyecroft Road",
    landlordAgentCity: "Burlington",
    landlordAgentProvince: "Ontario",
    landlordAgentPostalCode: "L7P 2S9",
    landlordAgentDayTimePhoneNumber: "(250) 617-7144",
    landlordAgentPhoneNumber: "",
    landlordAgentFaxNumber: "",
    moveInDate: formattedMoveInDate,
    rent:1250,
    paymentDay:5,
    securityDeposit: 4500,
    securityDepositPayBy: securityDepositPayBy,
    petDeposit: "",
    petDepositPayBy: "",
    numberAddendumPage:"25",
    numberAdditionalAddendumPage:"5",
  };
};
