import ApplicationInput from "../../../../../UIServices/ApplicationInput";
import React, { useEffect } from "react";
import SearchInput from "../../../../Application/SearchInput";
import { today } from "../../../../../../Defined/Function";
import { checkMobile } from "../CreateContractBritishColumbiaFunctions";

export default function Stepper01Elements(props) {
  var {
    values,
    onchangeCheck,
    onchange,
    changeState,
    checkValidate,
    onChangeDate,
    setDemoData
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="cardContract">
      {process.env.REACT_APP_STATUS !== "Production" && (
                <div className="row inputContainer pt-0 pb-0 mt-0 demoDataBcContract" >
                  <div className="col  d-flex flex-row-reverse">
                    <span
                      class="badge p-2 text-white pointer"
                      onClick={() =>setDemoData()}
                      style={{ backgroundColor: "#42dc13" }}
                    >
                      Test Data
                    </span>
                  </div>
                </div>
              )}
        <div className="row inputContainer pt-2 pb-0 ">
          <div className="col-lg-3 col-md-12 col-sm-12 pr-3 pl-3">
            <span className="stepTitle2 textDark1">
              Address for Service of the{" "}
            </span>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-3  addressForServiceBcApp">
            <ApplicationInput
              name={<span className="stepTitle2 textDark">Landlord</span>}
              type="checkedBox"
              target="landlord"
              onChange={onchangeCheck}
              value={values.landlord}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6  addressForServiceBcApp">
            <ApplicationInput
              name={
                <span className="stepTitle2 textDark">Landlord's agent</span>
              }
              type="checkedBox"
              target="landlordAgent"
              onChange={onchangeCheck}
              value={values.landlordAgent}
            />
          </div>
        </div>
        <div className="row inputContainer pt-2 mt-0 mb-0 pb-0">
          {values.landlord ? (
            <>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Unit/Site #"
                  type="text"
                  target="landlordUnit"
                  onChange={onchange}
                  // important="true"
                  errorMessage="unit name"
                  validate={checkValidate("landlordUnit")}
                  value={values.landlordUnit}
                />
              </div>
              <div className="col-3 alignInput">
                <SearchInput
                  name="landlordProvince"
                  id="Province"
                  target="landlordProvince"
                  list={values.landlordProvinceList}
                  changeState={changeState}
                  important="true"
                  errorMessage="landlord province"
                  validate={checkValidate("landlordProvince")}
                  value={values.landlordProvince}
                />
              </div>
              <div className="col-3 alignInput">
                <SearchInput
                  name="landlordCity"
                  id="City"
                  target="landlordCity"
                  list={values.landlordCityList}
                  changeState={changeState}
                  important="true"
                  errorMessage="landlord city"
                  validate={checkValidate("landlordCity")}
                  value={values.landlordCity}
                />
              </div>
              <div className="col-3 alignInput">
              <ApplicationInput
                  name="Street Number & Street Name"
                  type="text"
                  target="landlordStreet"
                  onChange={onchange}
                  important="true"
                  errorMessage="landlord street name"
                  validate={checkValidate("landlordStreet")}
                  value={values.landlordStreet}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Postal Code"
                  type="text"
                  target="landlordPostalCode"
                  onChange={onchange}
                  important="true"
                  errorMessage="landlord postal code"
                  validate={checkValidate("landlordPostalCode")}
                  value={values.landlordPostalCode}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Daytime Phone Number"
                  type="text"
                  target="landlordDayTimePhoneNumber"
                  onChange={onchange}
                  important="true"
                  errorMessage={
                    checkValidate("landlordDayTimePhoneNumber")
                      ? "the day time phone number of landlord."
                      : "the 10 digit number "
                  }
                  validate={checkMobile(values.landlordDayTimePhoneNumber,values.validation)}
                  value={values.landlordDayTimePhoneNumber}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Other Phone Number"
                  type="text"
                  target="landlordPhoneNumber"
                  onChange={onchange}
                  errorMessage={
                    checkValidate("landlordPhoneNumber")
                      ? "the phone number of landlord."
                      : "the 10 digit number "
                  }
                  validate={checkMobile(values.landlordPhoneNumber,values.validation)}
                  value={values.landlordPhoneNumber}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Fax Number"
                  type="text"
                  target="landlordFaxNumber"
                  onChange={onchange}
                  errorMessage="landlord fax number"
                  validate={checkValidate("landlordFaxNumber")}
                  value={values.landlordFaxNumber}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Unit/Site #"
                  type="text"
                  target="landlordAgentUnit"
                  onChange={onchange}
                  important="true"
                  errorMessage="unit name"
                  validate={checkValidate("landlordAgentUnit")}
                  value={values.landlordAgentUnit}
                />
              </div>
              <div className="col-3 alignInput">
                <SearchInput
                  name="landlordAgentProvince"
                  id="Province"
                  target="landlordAgentProvince"
                  list={values.landlordAgentProvinceList}
                  changeState={changeState}
                  important="true"
                  errorMessage="landlord agent province"
                  validate={checkValidate("landlordAgentProvince")}
                  value={values.landlordAgentProvince}
                />
              </div>
              <div className="col-3 alignInput">
                <SearchInput
                  name="landlordAgentCity"
                  id="City"
                  target="landlordAgentCity"
                  list={values.landlordAgentCityList}
                  changeState={changeState}
                  important="true"
                  errorMessage="landlord agent city"
                  validate={checkValidate("landlordAgentCity")}
                  value={values.landlordAgentCity}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Street Number & Street Name"
                  type="text"
                  target="landlordAgentStreet"
                  onChange={onchange}
                  important="true"
                  errorMessage="landlord agent street name"
                  validate={checkValidate("landlordAgentStreet")}
                  value={values.landlordAgentStreet}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Postal Code"
                  type="text"
                  target="landlordAgentPostalCode"
                  onChange={onchange}
                  important="true"
                  errorMessage="landlord agent postal code"
                  validate={checkValidate("landlordAgentPostalCode")}
                  value={values.landlordAgentPostalCode}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Daytime Phone Number"
                  type="text"
                  target="landlordAgentDayTimePhoneNumber"
                  onChange={onchange}
                  important="true"
                  errorMessage={
                    checkValidate("landlordAgentDayTimePhoneNumber")
                      ? "the day time phone number of landlord agent."
                      : "the 10 digit number "
                  }
                  validate={checkMobile(values.landlordAgentDayTimePhoneNumber,values.validation)}
                  value={values.landlordAgentDayTimePhoneNumber}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Other Phone Number"
                  type="text"
                  target="landlordAgentPhoneNumber"
                  onChange={onchange}
                  errorMessage={
                    checkValidate("landlordAgentPhoneNumber")
                      ? "the phone number of landlord agent."
                      : "the 10 digit number "
                  }
                  validate={checkMobile(values.landlordAgentPhoneNumber,values.validation)}
                  value={values.landlordAgentPhoneNumber}
                />
              </div>
              <div className="col-3 alignInput">
                <ApplicationInput
                  name="Fax Number"
                  type="text"
                  target="landlordAgentFaxNumber"
                  onChange={onchange}
                  errorMessage="landlord agent fax number"
                  validate={checkValidate("landlordAgentFaxNumber")}
                  value={values.landlordAgentFaxNumber}
                />
              </div>
            </>
          )}
          <div class="col-12 pt-5 pb-0 mb-0">
            <p className=" stepTitle2 textDark1  pb-0 mb-0">
              Beginning and Term of the Agreement
            </p>
          </div>
          <div class="pl-3">
            <p className="span2 contractTextStyle">
            This tenancy created by agreement starts on
              <br></br>
              <span className=" span2 textSec1 beingTermDateLabelBcContract">day/month/year</span>
            </p>
          </div>
          <div className="col alignInput beingTermInputBcContract">
            <ApplicationInput
              name=""
              type="date"
              min={today()}
              target="moveInDate"
              format="yearFirst"
              important="true"
              onChange={onChangeDate}
              errorMessage="your Move In Date"
              validate={checkValidate("moveInDate")}
              value={values.moveInDate}
            />
          </div>
          <div class="col-12 pl-3 pt-5">
            <p className="stepTitle2  textDark1 ">Check A,B or C</p>
          </div>
          <div className="col-12 ">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  A) and continues on a month-to-month basis until ended in
                  accordance with the Act.
                </span>
              }
              type="checkedBox"
              target="agreementPaymentMonthBasicType"
              onChange={onchangeCheck}
              value={values.agreementPaymentMonthBasicType}
            />
          </div>
          <div className="col-12 ">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  B) and continues on another periodic basis, as specified
                  below, until ended in accordance with the Act.
                </span>
              }
              type="checkedBox"
              target="agreementPaymentPeriodicType"
              onChange={onchangeCheck}
              value={values.agreementPaymentPeriodicType}
            />
          </div>
          <div className="row  bcPeriodCheckBoxes">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle mr-4 ">Weekly</span>
              }
              type="checkedBox"
              target="agreementPaymentWeekly"
              onChange={onchangeCheck}
              value={values.agreementPaymentWeekly}
            />

            <ApplicationInput
              name={
                <span
                  className="span2 mr-4"
                  style={{
                    whiteSpace: "pre-line",
                    lineHeight: "25px",
                  }}
                >
                  Bi-weekly
                </span>
              }
              type="checkedBox"
              target="agreementPaymentBiWeekly"
              onChange={onchangeCheck}
              value={values.agreementPaymentBiWeekly}
            />
            <ApplicationInput
              name={
                <span
                  className="span2 mr-4"
                  style={{
                    whiteSpace: "pre-line",
                    lineHeight: "25px",
                  }}
                >
                  Other
                </span>
              }
              type="checkedBox"
              target="agreementPaymentOther"
              onChange={onchangeCheck}
              
              value={values.agreementPaymentOther}
            />
            <div style={{ marginTop: "-10px" }}>
              <ApplicationInput
                name=""
                type="text"
                target="agreementPaymentOtherType"
                onChange={onchange}
                errorMessage="other type"
                important="true"
                disabled
                validate={checkValidate("agreementPaymentOtherType")}
                value={values.agreementPaymentOtherType}
              />
            </div>
          </div>
          <div className="col-12 mt-2 ">
            <div className="row pl-3">
              <div class="pt-0 mt-0">
                <ApplicationInput
                  name={
                    <span className="span2 contractTextStyle">
                      C) and is for a fixed term ending on
                      <br></br>
                      <span className="beingTermDateLabelBcContract span2 textSec1 ">
                        day/month/year
                      </span>
                    </span>
                  }
                  type="checkedBox"
                  target="agreementPaymentFixedType"
                  onChange={onchangeCheck}
                  value={values.agreementPaymentFixedType}
                />
              </div>
              <div
                className="col alignInput  fixedTermInputBc"
             
              >
                <ApplicationInput
                  name=""
                  type="date"
                  min={today()}
                  disabled={!values.agreementPaymentFixedType&&"true"}
                  target="agreementPaymentFixedTermEndVacate"
                  format="vacate date"
                  onChange={onChangeDate}
                  important="true"
                  errorMessage="your Move In Date"
                  validate={checkValidate("agreementPaymentFixedTermEndVacate")}
                  value={values.agreementPaymentFixedTermEndVacate}
                />
              </div>
            </div>
          </div>
          <div class="col-12 pl-3 pt-5">
            <p className="stepTitle2  textDark1 ">
              If you choose C, check and complete D or E{" "}
            </p>
          </div>
          <div className="col-12 ">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  D) At the end of this time, the tenancy will continue on a
                  month-to-month basis, or another fixed length of time, unless
                  the tenant gives notice to end tenancy at least one clear
                  month before the end of the term.
                </span>
              }
              type="checkedBox"
              target="agreementPaymentFixedTermEndNotContinue"
              onChange={onchangeCheck}
              value={values.agreementPaymentFixedTermEndNotContinue}
            />
          </div>
          <div className="col-12 ">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  E) At the end of this time,{" "}
                  <b>
                    the tenancy is ended and the tenant must vacate the rental
                    unit.
                  </b>
                </span>
              }
              type="checkedBox"
              target="agreementPaymentFixedTermEndContinue"
              onChange={onchangeCheck}
              value={values.agreementPaymentFixedTermEndContinue}
            />
          </div>
          <div className="col-12 ">
            <div className="row tenancyEndReasonDiv ">
              <div>
                <span className="span2 contractTextStyle ">
                <i class="fa fa-circle pr-2" aria-hidden="true"></i>   Reason tenant must vacate 
                </span>
              </div>
              <div class="col  tenancyEndReasonDivInput">
                <ApplicationInput
                  name=""
                  type="text"
                  target="reasonTenancyVacate"
                  onChange={onchange}
                  errorMessage="reason"
                  important="true"
                  validate={checkValidate("reasonTenancyVacate")}
                  value={values.reasonTenancyVacate}
                />
              </div>
            </div>
          </div>
          <div className="col-12 ">
            <div className="row  tenancyEndReasonDiv">
              <div>
                <span className="span2 contractTextStyle ">
                <i class="fa fa-circle pr-2" aria-hidden="true"></i> Residential Tenancy Regulation section number (if applicable) : 
                </span>
              </div>
              <div class="col tenancyEndReasonDivInput">
                <ApplicationInput
                  name=""
                  type="text"
                  target="regulationTenancyNumber"
                  onChange={onchange}
                  // important="true"
                  errorMessage="section number"
                  validate={checkValidate("regulationTenancyNumber")}
                  value={values.regulationTenancyNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
