import React, { Component } from "react";
import Loader3 from "../../Loader/loader3";

class SmallModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div
          className="modal fade "
          id={this.props.name}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            zIndex: "5500",
          }}
        >
          <div
            className={
              this.props.size === "lg"
                ? "modal-dialog modal-lg "
                : "modal-dialog "
            }
            role="document"
          >
            <div className="modal-content">
              {this.props.header!=="false"&&
              <div
                className="modal-header "
                style={{ height: "60px", borderBottom: "none" }}
              >
                <h1
                  className="modal-title axText1 textPri"
                  id="exampleModalLabel"
                  style={{ marginTop: "-10px", fontWeight: "900" }}
                >
                  {this.props.title}
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>}
              <div
                className="modal-body bodyAvailable "
                style={this.props.max&&{
                  maxHeight: this.props.max,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {this.props.children}
              </div>
              {this.props.footer !== "false" && (
                <div
                  className={this.props.max?" pt-3 shadow ":" pt-3"}
                  style={{ borderTop: "none" }}
                >
                  <div className="row pr-4 pl-4 pb-3">
                  {this.props.footerMessage && (
                      <div className=" col-md">{this.props.footerMessage()}</div>
                    )}
                    <div className="col">
                    
                      
                    <span
                      className="badge appStatus  mb-0 mr-3 "
                      style={{ backgroundColor:"#2ECC71" }}
                      >{this.props.beforeButton}</span>
                      <button
                        data-placement="top"
                        title={this.props.confirmToggle}
                        className={
                          this.props.confirmDisable ? "secDisable pull-right" : " pull-right"
                        }
                        data-dismiss={!this.props.stopConfirmDismiss&&"modal"}
                        disabled={this.props.confirmDisable && "disabled"}
                        onClick={() => this.props.confirm()}
                      >
                        {this.props.confirmCommand}
                            {this.props.confirmLoader && (
                              <Loader3 transY="47px" transX="0px" />
                            )}
                      </button>
                      &nbsp; &nbsp;
                      {this.props.rejectCheck === "true" && (
                        <button
                          className="pri text-left mr-2 pull-right"
                          data-dismiss="modal"
                          onClick={() => this.props.reject()}
                        >
                          {this.props.rejectCommand}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SmallModalContainer;
