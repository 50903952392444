import React, { Component } from "react";

class CurrencyFormat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  format() {
    let value = this.props.value;
    if (value) {
      if (value === null || value === "" || value === undefined)
        return "$ 0.00";
      return (
        <>
          <i className="fa fa-usd " aria-hidden="true"></i>{" "}
          {value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </>
      );
    } else {
      return "$ 0.00";
    }
  }
  render() {
    return this.format();
  }
}

export default CurrencyFormat;
