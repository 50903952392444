import React, { Component } from "react";
import "../application.css";
import "../dropdownForm.css";
import { getPropertyLists } from "../../../../Services/ApiServices";
import { setDateFormat } from "../../../../Defined/Function";
import ApplicationAgreementPDF from "../ApplicationAgreementPDF";

export class ApplicationModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.modaldata;
  }
  componentDidMount() {
    this.getApp();
  }
  async getApp() {
    if (this.state.apply[0]) {
      if (this.state.apply[0].applicant) {
        if (this.state.apply[0].applicant.username) {
          try {
            await getPropertyLists(this.state.rentalEntityId).then(
              (response) => {
                if (response.status === 200) {
                  if (response.data.length !== 0) {
                    this.setState({
                      ...this.state,
                      propertyData: response.data,
                    });
                  }
                } else {
                  this.props.pushAlert(
                    "Something went wrong!, Please try again later.",
                    "danger"
                  );
                }
              }
            );
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  }
  alignData(props) {
    return (
      <div className="row">
        <div className="col-5">
          <span className="span6">{props.label}</span>
        </div>
        <div className="col-1">
          <span className="span">:</span>
        </div>
        <div className="col-6 item-left">
          <span className="span6">
            {props.value === "checked" ? (
              <i className="fa fa-check-square-o" aria-hidden="true"></i>
            ) : props.value === "unChecked" ? (
              <i className="fa fa-square-o" aria-hidden="true"></i>
            ) : (
              props.value
            )}
          </span>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div
        className="modal fade  bd-example-modal-xl"
        id={"applicationPdfModal" + this.props.application.id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="applicationPdfModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div
              className="modal-header shadow"
              style={{ height: "80px", borderBottom: "1px solid #e09b81" }}
            >
              <h1
                className="modal-title  textPri axText"
                id="applicationPdfModalLabel"
                style={{ fontSize: "25px", marginTop: "0px" }}
              >
                Application
              </h1>
              <button
                type="button"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.onHide()}
              >
                <span aria-hidden="true" className="closeModal textPri">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body bodyAvailable pt-2  pb-0 bodyApplication">
              <div style={{ height: "12px" }} />
              <div>
                {this.props.property.type === "OFFICE" ? (
                  <div className="row m-2 border shadow  mt-1 pt-4 pb-4 d-flex justify-content-center">
                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 ">Basic</p>
                      <div className="pt-4">
                        <this.alignData
                          label="Business Name"
                          value={this.state.businessApplication.businessName}
                        />
                        <this.alignData
                          label="Established"
                          value={this.state.businessApplication.establish}
                        />
                        <this.alignData
                          label="Contact Phone"
                          value={this.state.businessApplication.contactPhone}
                        />
                        <this.alignData
                          label="Contact Fax"
                          value={this.state.businessApplication.contactFax}
                        />
                        <this.alignData
                          label="Email"
                          value={this.state.apply[0].applicant.username}
                        />
                      </div>
                      <p className="pt-2   stepTitle  ">Primary Bank</p>
                      <br />
                      <div className="pt-2">
                        <this.alignData
                          label="Bank name"
                          value={this.state.businessApplication.primaryBank}
                        />
                        <this.alignData
                          label="Bank Phone"
                          value={
                            this.state.businessApplication.primaryBankPhone
                          }
                        />
                        <this.alignData
                          label="Bank Address"
                          value={
                            this.state.businessApplication.primaryBankAddress
                              .address +
                            ", " +
                            this.state.businessApplication.primaryBankAddress
                              .city +
                            ", " +
                            this.state.businessApplication.primaryBankAddress
                              .state +
                            ", " +
                            this.state.businessApplication.primaryBankAddress
                              .zip
                          }
                        />
                      </div>
                      <p className="pt-2   stepTitle  ">Secondary Bank</p>
                      <br />
                      <div className="pt-2">
                        <this.alignData
                          label="Bank name"
                          value={this.state.businessApplication.secondaryBank}
                        />
                        <this.alignData
                          label="Bank Phone"
                          value={
                            this.state.businessApplication.secondaryBankPhone
                          }
                        />
                        <this.alignData
                          label="Bank Address"
                          value={
                            this.state.businessApplication.secondaryBankAddress
                              .address +
                            ", " +
                            this.state.businessApplication.secondaryBankAddress
                              .city +
                            ", " +
                            this.state.businessApplication.secondaryBankAddress
                              .state +
                            ", " +
                            this.state.businessApplication.secondaryBankAddress
                              .zip
                          }
                        />
                      </div>
                    </div>
                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 ">
                        Business Info
                      </p>
                      <div className="pt-4">
                        <this.alignData
                          label="Number of Employee"
                          value={this.state.businessApplication.employessCount}
                        />
                        <this.alignData
                          label="Business Type"
                          value={this.state.businessApplication.businessType}
                        />
                        <this.alignData
                          label="Ownership Type"
                          value={this.state.businessApplication.ownershipType}
                        />
                        <this.alignData
                          label="Intent"
                          value={this.state.businessApplication.intent}
                        />
                        <this.alignData
                          label="Emergency Contact Name"
                          value={
                            this.state.apply[0].applicant.emergencyContactName
                          }
                        />
                        <this.alignData
                          label="Emergency Contact Phone"
                          value={
                            this.state.apply[0].applicant.emergencyContactPhone
                          }
                        />
                        {this.state.businessApplication.parentCompany && (
                          <>
                            <this.alignData
                              label="Parent Company"
                              value={
                                this.state.businessApplication.parentCompanyName
                              }
                            />
                            <this.alignData
                              label="Parent Company Address"
                              value={
                                this.state.businessApplication
                                  .parentCompanyAddress.address +
                                ", " +
                                this.state.businessApplication
                                  .parentCompanyAddress.state +
                                ", " +
                                this.state.businessApplication
                                  .parentCompanyAddress.city +
                                ", " +
                                this.state.businessApplication
                                  .parentCompanyAddress.zip
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 ">Rental Info</p>
                      <p className="pt-4   stepTitle  ">Present</p>
                      <br />
                      <div className="pt-4 mb-2">
                        <this.alignData
                          label="Landlord Name"
                          value={
                            this.state.apply[0].applicant.resPresentLandlord
                          }
                        />
                        <this.alignData
                          label="Phone"
                          value={
                            this.state.apply[0].applicant.resPresentLandlordTp
                          }
                        />
                        <this.alignData
                          label="Address"
                          value={
                            this.state.apply[0].applicant.resPresentAddress +
                            ", " +
                            this.state.apply[0].applicant.resPresentState +
                            ", " +
                            this.state.apply[0].applicant.resPresentZip
                          }
                        />
                        <this.alignData
                          label="Occupancy From"
                          value={setDateFormat(
                            this.state.apply[0].applicant.resPresentFrom
                          )}
                        />
                        <this.alignData
                          label="Occupancy To"
                          value={setDateFormat(
                            this.state.apply[0].applicant.resPresentTo
                          )}
                        />
                        <this.alignData
                          label="Monthly Rent"
                          value={this.state.apply[0].applicant.resPresentRent}
                        />
                      </div>
                      <p className="pt-1   stepTitle  ">Previous</p>
                      {/* <br/> */}
                      <div className="pt-4">
                        <this.alignData
                          label="Landlord Name"
                          value={
                            this.state.apply[0].applicant.resPreviousLandlord
                          }
                        />
                        <this.alignData
                          label="Phone"
                          value={
                            this.state.apply[0].applicant.resPreviousLandlordTp
                          }
                        />
                        <this.alignData
                          label="Address"
                          value={
                            this.state.apply[0].applicant.resPreviousAddress +
                            ", " +
                            this.state.apply[0].applicant.resPreviousState +
                            ", " +
                            this.state.apply[0].applicant.resPreviousZip
                          }
                        />
                        <this.alignData
                          label="Occupancy From"
                          value={setDateFormat(
                            this.state.apply[0].applicant.resPreviousFrom
                          )}
                        />
                        <this.alignData
                          label="Occupancy To"
                          value={setDateFormat(
                            this.state.apply[0].applicant.resPreviousTo
                          )}
                        />
                        <this.alignData
                          label="Monthly Rent"
                          value={this.state.apply[0].applicant.resPreviousRent}
                        />
                      </div>
                    </div>
                    {this.state.businessApplication.creditReferences.length >
                      0 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0   stepTitle textPri2 ">
                          Credit Reference
                        </p>
                        <p className="pt-4 stepTitle">First Reference</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Company Name"
                            value={
                              this.state.businessApplication.creditReferences[0]
                                .companyName
                            }
                          />
                          <this.alignData
                            label="Contact Name"
                            value={
                              this.state.businessApplication.creditReferences[0]
                                .contactName
                            }
                          />
                          <this.alignData
                            label="Contact Phone"
                            value={
                              this.state.businessApplication.creditReferences[0]
                                .phone
                            }
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.businessApplication.creditReferences[0]
                                .companyAddress.address +
                              ", " +
                              this.state.businessApplication.creditReferences[0]
                                .companyAddress.city +
                              ", " +
                              this.state.businessApplication.creditReferences[0]
                                .companyAddress.state +
                              ", " +
                              this.state.businessApplication.creditReferences[0]
                                .companyAddress.zip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.businessApplication.creditReferences.length >
                      1 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0   stepTitle textPri2 "></p>
                        <p className="pt-4 stepTitle">Second Reference</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Company Name"
                            value={
                              this.state.businessApplication.creditReferences[1]
                                .companyName
                            }
                          />
                          <this.alignData
                            label="Contact Name"
                            value={
                              this.state.businessApplication.creditReferences[1]
                                .contactName
                            }
                          />
                          <this.alignData
                            label="Contact Phone"
                            value={
                              this.state.businessApplication.creditReferences[1]
                                .phone
                            }
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.businessApplication.creditReferences[1]
                                .companyAddress.address +
                              ", " +
                              this.state.businessApplication.creditReferences[1]
                                .companyAddress.city +
                              ", " +
                              this.state.businessApplication.creditReferences[1]
                                .companyAddress.state +
                              ", " +
                              this.state.businessApplication.creditReferences[1]
                                .companyAddress.zip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.businessApplication.creditReferences.length >
                      2 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0   stepTitle textPri2 "></p>
                        <p className="pt-4 stepTitle">Second Reference</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Company Name"
                            value={
                              this.state.businessApplication.creditReferences[2]
                                .companyName
                            }
                          />
                          <this.alignData
                            label="Contact Name"
                            value={
                              this.state.businessApplication.creditReferences[2]
                                .contactName
                            }
                          />
                          <this.alignData
                            label="Contact Phone"
                            value={
                              this.state.businessApplication.creditReferences[2]
                                .phone
                            }
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.businessApplication.creditReferences[2]
                                .companyAddress.address +
                              ", " +
                              this.state.businessApplication.creditReferences[2]
                                .companyAddress.city +
                              ", " +
                              this.state.businessApplication.creditReferences[2]
                                .companyAddress.state +
                              ", " +
                              this.state.businessApplication.creditReferences[2]
                                .companyAddress.zip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.businessApplication.owners.length > 0 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0   stepTitle textPri2 ">
                          Owners Info
                        </p>
                        <p className="pt-4   stepTitle  ">First owner</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Name"
                            value={
                              this.state.businessApplication.owners[0].name
                            }
                          />

                          <this.alignData
                            label="Email"
                            value={
                              this.state.businessApplication.owners[0].email
                            }
                          />
                          <this.alignData
                            label="Phone"
                            value={
                              this.state.businessApplication.owners[0].phone
                            }
                          />
                          <this.alignData
                            label="Alternative Phone"
                            value={
                              this.state.businessApplication.owners[0]
                                .phoneAlteranative
                            }
                          />
                          <this.alignData
                            label="Date of Birth"
                            value={this.state.businessApplication.owners[0].dob}
                          />
                          <this.alignData
                            label="Driving License No"
                            value={
                              this.state.businessApplication.owners[0]
                                .drivingLicenceNo
                            }
                          />
                          <this.alignData
                            label="SSN"
                            value={this.state.businessApplication.owners[0].ssn}
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.businessApplication.owners[0]
                                .ownerAddress.address +
                              ", " +
                              this.state.businessApplication.owners[0]
                                .ownerAddress.city +
                              ", " +
                              this.state.businessApplication.owners[0]
                                .ownerAddress.state +
                              ", " +
                              this.state.businessApplication.owners[0]
                                .ownerAddress.zip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.businessApplication.owners.length > 1 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0   stepTitle textPri2 "></p>
                        <p className="pt-4   stepTitle  ">First owner</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Name"
                            value={
                              this.state.businessApplication.owners[1].name
                            }
                          />
                          <this.alignData
                            label="Email"
                            value={
                              this.state.businessApplication.owners[1].email
                            }
                          />
                          <this.alignData
                            label="Phone"
                            value={
                              this.state.businessApplication.owners[1].phone
                            }
                          />
                          <this.alignData
                            label="Alternative Phone"
                            value={
                              this.state.businessApplication.owners[1]
                                .phoneAlteranative
                            }
                          />
                          <this.alignData
                            label="Date of Birth"
                            value={this.state.businessApplication.owners[1].dob}
                          />
                          <this.alignData
                            label="Driving License No"
                            value={
                              this.state.businessApplication.owners[1]
                                .drivingLicenceNo
                            }
                          />
                          <this.alignData
                            label="SSN"
                            value={this.state.businessApplication.owners[1].ssn}
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.businessApplication.owners[1]
                                .ownerAddress.address +
                              ", " +
                              this.state.businessApplication.owners[1]
                                .ownerAddress.city +
                              ", " +
                              this.state.businessApplication.owners[1]
                                .ownerAddress.state +
                              ", " +
                              this.state.businessApplication.owners[1]
                                .ownerAddress.zip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.businessApplication.owners.length > 2 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0 stepTitle textPri2"></p>
                        <p className="pt-4 stepTitle">First owner</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Name"
                            value={
                              this.state.businessApplication.owners[2].name
                            }
                          />
                          <this.alignData
                            label="Email"
                            value={
                              this.state.businessApplication.owners[2].email
                            }
                          />
                          <this.alignData
                            label="Phone"
                            value={
                              this.state.businessApplication.owners[2].phone
                            }
                          />
                          <this.alignData
                            label="Alternative Phone"
                            value={
                              this.state.businessApplication.owners[2]
                                .phoneAlteranative
                            }
                          />
                          <this.alignData
                            label="Date of Birth"
                            value={this.state.businessApplication.owners[2].dob}
                          />
                          <this.alignData
                            label="Driving License No"
                            value={
                              this.state.businessApplication.owners[2]
                                .drivingLicenceNo
                            }
                          />
                          <this.alignData
                            label="SSN"
                            value={this.state.businessApplication.owners[2].ssn}
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.businessApplication.owners[2]
                                .ownerAddress.address +
                              ", " +
                              this.state.businessApplication.owners[2]
                                .ownerAddress.city +
                              ", " +
                              this.state.businessApplication.owners[2]
                                .ownerAddress.state +
                              ", " +
                              this.state.businessApplication.owners[2]
                                .ownerAddress.zip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : this.props.property.address.country === "Canada" ? (
                  <ApplicationAgreementPDF id={this.state.id} />
                ) : (
                  <ApplicationAgreementPDF id={this.state.id} />
                )}
              </div>
              <br />
            </div>

            {(this.state.status === "PENDING" ||
              this.state.status === "APPROVED") && (
              <div
                className="modal-footer pt-3 shadow1"
                style={{ borderTop: "1px solid #d15428" }}
              >
                {this.state.status === "PENDING" && (
                  <>
                    <button
                      className="pri "
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.onReject(this.props.modaldata);
                      }}
                    >
                      Reject
                    </button>
                    <button
                      className="buttonPri"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.onAccept();
                      }}
                    >
                      Approve
                    </button>
                  </>
                )}
                {this.state.status === "APPROVED" && (
                  <button
                    className="buttonPri"
                    data-dismiss="modal"
                    onClick={
                      this.props.property.type === "OFFICE"
                        ? () =>
                            this.props.history.push({
                              pathname: "/create-contract/washington",
                              state: {
                                applicationData: this.props.modaldata,
                                propertyData: this.state.propertyData,
                              },
                            })
                        : this.props.property.address.state ===
                          "British Columbia"
                        ? () =>
                            this.props.history.push({
                              pathname: "/create-contract/british_columbia",
                              state: {
                                applicationData: this.props.modaldata,
                                propertyData: this.state.propertyData,
                              },
                            })
                        : this.props.property.address.state === "Ontario"
                        ? () =>
                            this.props.history.push({
                              pathname: "/create-contract/ontario",
                              state: {
                                applicationData: this.props.modaldata,
                                propertyData: this.state.propertyData,
                              },
                            })
                        : () =>
                            this.props.history.push({
                              pathname: "/create-contract/washington",
                              state: {
                                applicationData: this.props.modaldata,
                                propertyData: this.state.propertyData,
                              },
                            })
                    }
                  >
                    Create Contract
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
