import React, { useState, useEffect } from "react";
import NoCov from "../../../Images/no-cover-img.jpg";
import propertyLoader from "../../../Images/propertyLoader.gif";
import "./RentalUnits.css";
import { url } from "../../../Services/ApiServices";
import { convertLocalDate, sortText } from "../../../Defined/Function";
import { useSelector, useDispatch } from "react-redux";
import { appendTempPropertyImage } from "../../../actions";

const RentalUnit = (props) => {
  const [heart, setHeart] = useState(false);
  const [rentalImage, setRentalImage] = useState(propertyLoader);
  const tempPropertyImages = useSelector((state) => state.tempPropertyImages);
  const { coverImage } = props.rentalEntity.property;
  const { rent, sqFeet, bedRooms, bathroomsAttach } = props.rentalEntity;
  const { address, city, country, state } = props.rentalEntity.property.address;
  const dispatch = useDispatch();
  function changeHeart(event) {
    event.stopPropagation();
    setHeart(!heart);
  }
  useEffect(() => {
    const fetchImage = (id, url) => {
      try {
        const toDataURL = (url1) =>
          fetch(url1)
            .then((response) => {
              if (response.status === 200) {
                return response.blob();
              } else {
                throw new Error(response);
              }
            })
            .then(
              (blob) =>
                new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(blob);
                })
            )
            .catch((res) => {
              throw new Error(res);
            });

        toDataURL(url)
          .then((dataUrl) => {
            setRentalImage(dataUrl);
            dispatch(
              appendTempPropertyImage({
                id: id,
                image: dataUrl,
              })
            );
          })
          .catch((res) => {
            setRentalImage(NoCov);
            dispatch(
              appendTempPropertyImage({
                id: id,
                image: NoCov,
              })
            );
          });
      } catch (error) {
        setRentalImage(NoCov);
      }
    };
    const setImage = (id) => {
      if (id === "" || id === null) {
        setRentalImage(NoCov);
        dispatch(
          appendTempPropertyImage({
            id: id,
            image: NoCov,
          })
        );
      } else {
        fetchImage(id, url + `api/property/${id}/image/download`);
      }
    };
    const filterImage = (id) => {
      let propertyImage = tempPropertyImages.filter((todo) => todo.id === id);
      if (propertyImage.length === 0) {
        setImage(id);
      } else {
        setRentalImage(propertyImage[0].image);
      }
    };
    filterImage(props.rentalEntity.property.id);
    // eslint-disable-next-line
  }, [props.rentalEntity.property.id]);

  return (
    <>
      <div className="propertyCard pb-1 bg-whiteImage">
        <div className="propsImageCover d-flex justify-content-center pointer">
          <span className="heart absolute">
            <i
              onClick={(e) => changeHeart(e)}
              className={heart ? "fa fa-heart" : "fa fa-heart-o"}
              aria-hidden="true"
            ></i>
          </span>
          {coverImage ? (
            <>
              {" "}
              <img
                className="propsImage  pointer"
                alt="img"
                src={rentalImage}
              />{" "}
              <button className="viewListingButton2">
                <i className="fa fa-usd " aria-hidden="true"></i>
                {rent.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </button>
            </>
          ) : (
            <>
              <img className="propsImage pointer" alt="img" src={NoCov} />{" "}
              <button className="viewListingButton2">
                <i className="fa fa-usd " aria-hidden="true"></i>
                {rent.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </button>
            </>
          )}
        </div>
        <div className=" propCoverContent ">
          <div className="row propCoverContent1 pl-3 ">
            <div className="col-12"></div>
            <div className="col-8 propsContent">
              <div className="row">
                <div className="col-5 text-left">
                  <span className="propsContent2">Available</span>
                </div>
                <div className="col-7">
                  <span className="propsContent2">
                    : &nbsp;&nbsp;
                    <span className="">
                      {convertLocalDate(props.rentalEntity.availableFrom)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-5 text-left">
                  <span className="propsContent2 "> Area</span>
                </div>
                <div className="col-7">
                  {/* <span className="propsContent2">: {props.property.rentalEntity.length}</span> */}
                  <span className="propsContent2">
                    : &nbsp;&nbsp;{sqFeet} sqft
                  </span>
                </div>
              </div>
            </div>
            <div className="col-4 propsContent ">
              <div className="row">
                <i className="fa fa-bed" aria-hidden="true"></i>
                &nbsp;&nbsp;:&nbsp;{bedRooms}
              </div>
              <div className="row">
                <span className="propsContent">
                  <i className="fa fa-bath" aria-hidden="true"></i>
                  &nbsp;&nbsp; : &nbsp;{bathroomsAttach}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="propertyHead  pr-3 pl-3 pt-2 ">
          <div className="  text-left">
            <h4 className="propsAddressHead text-left">
              {sortText(address + "," + city, 0, 33)}
            </h4>
            <h4 className="propsAddress text-left pb-1">
              {sortText(state + ", " + country, 0, 33)}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalUnit;
