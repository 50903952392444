const checkEmailNext = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !(!re.test(String(value).toLowerCase()) || value === "");
};

const checkMobileNext = (value) => {
  return value.length === 14;
};
const checkAccountNoNext = (value) => {
  return !isNaN(value);
};
const checkSINNext = (value) => {
  return value.length === 11;
};
export const canadaContractStep1Verification = (state) => {
  let x = false;
  if (state.startDate !== "" && state.agreementType !== "") {
    if (state.smoking) {
      if (state.smokingDescription !== "") {
        if (state.parkingAcce) {
          if (state.parkingDescription !== "") {
            if (state.emailOption) {
              if (
                state.documentEmails !== "" &&
                checkEmailNext(state.documentEmails)&&
                state.documentEmails2 !== "" &&
                checkEmailNext(state.documentEmails2)
              ) {
                if (state.landlordEmergencyContactOption) {
                  if (state.landlordEmergencyContact !== "") {
                    if (state.agreementType === "other") {
                      if (state.otherAgreementType !== "") {
                        x = true;
                      }
                    } else if (state.agreementType === "fixed") {
                      if (state.endDate !== "") {
                        x = true;
                      }
                    } else {
                      x = true;
                    }
                  }
                } else {
                  if (state.agreementType === "other") {
                    if (state.otherAgreementType !== "") {
                      x = true;
                    }
                  } else if (state.agreementType === "fixed") {
                    if (state.endDate !== "") {
                      x = true;
                    }
                  } else {
                    x = true;
                  }
                }
              }
            } else {
              if (state.landlordEmergencyContactOption) {
                if (state.landlordEmergencyContact !== "") {
                  if (state.agreementType === "other") {
                    if (state.otherAgreementType !== "") {
                      x = true;
                    }
                  } else if (state.agreementType === "fixed") {
                    if (state.endDate !== "") {
                      x = true;
                    }
                  } else {
                    x = true;
                  }
                }
              } else {
                if (state.agreementType === "other") {
                  if (state.otherAgreementType !== "") {
                    x = true;
                  }
                } else if (state.agreementType === "fixed") {
                  if (state.endDate !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            }
          }
        } else {
          if (state.emailOption) {
            if (
              state.documentEmails !== "" &&
              checkEmailNext(state.documentEmails) &&
              state.documentEmails2 !== "" &&
              checkEmailNext(state.documentEmails2)
            ) {
              if (state.landlordEmergencyContactOption) {
                if (state.landlordEmergencyContact !== "") {
                  if (state.agreementType === "other") {
                    if (state.otherAgreementType !== "") {
                      x = true;
                    }
                  } else if (state.agreementType === "fixed") {
                    if (state.endDate !== "") {
                      x = true;
                    }
                  } else {
                    x = true;
                  }
                }
              } else {
                if (state.agreementType === "other") {
                  if (state.otherAgreementType !== "") {
                    x = true;
                  }
                } else if (state.agreementType === "fixed") {
                  if (state.endDate !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            }
          } else {
            if (state.landlordEmergencyContactOption) {
              if (state.landlordEmergencyContact !== "") {
                if (state.agreementType === "other") {
                  if (state.otherAgreementType !== "") {
                    x = true;
                  }
                } else if (state.agreementType === "fixed") {
                  if (state.endDate !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            } else {
              if (state.agreementType === "other") {
                if (state.otherAgreementType !== "") {
                  x = true;
                }
              } else if (state.agreementType === "fixed") {
                if (state.endDate !== "") {
                  x = true;
                }
              } else {
                x = true;
              }
            }
          }
        }
      }
    } else {
      if (state.parkingAcce) {
        if (state.parkingDescription !== "") {
          if (state.emailOption) {
            if (
              state.documentEmails !== "" &&
              checkEmailNext(state.documentEmails) &&
              state.documentEmails2 !== "" &&
              checkEmailNext(state.documentEmails2)
            ) {
              if (state.landlordEmergencyContactOption) {
                if (state.landlordEmergencyContact !== "") {
                  if (state.agreementType === "other") {
                    if (state.otherAgreementType !== "") {
                      x = true;
                    }
                  } else if (state.agreementType === "fixed") {
                    if (state.endDate !== "") {
                      x = true;
                    }
                  } else {
                    x = true;
                  }
                }
              } else {
                if (state.agreementType === "other") {
                  if (state.otherAgreementType !== "") {
                    x = true;
                  }
                } else if (state.agreementType === "fixed") {
                  if (state.endDate !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            }
          } else {
            if (state.landlordEmergencyContactOption) {
              if (state.landlordEmergencyContact !== "") {
                if (state.agreementType === "other") {
                  if (state.otherAgreementType !== "") {
                    x = true;
                  }
                } else if (state.agreementType === "fixed") {
                  if (state.endDate !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            } else {
              if (state.agreementType === "other") {
                if (state.otherAgreementType !== "") {
                  x = true;
                }
              } else if (state.agreementType === "fixed") {
                if (state.endDate !== "") {
                  x = true;
                }
              } else {
                x = true;
              }
            }
          }
        }
      } else {
        if (state.emailOption) {
          if (
            state.documentEmails !== "" &&
            checkEmailNext(state.documentEmails) &&
            state.documentEmails2 !== "" &&
            checkEmailNext(state.documentEmails2)
          ) {
            if (state.landlordEmergencyContactOption) {
              if (state.landlordEmergencyContact !== "") {
                if (state.agreementType === "other") {
                  if (state.otherAgreementType !== "") {
                    x = true;
                  }
                } else if (state.agreementType === "fixed") {
                  if (state.endDate !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            } else {
              if (state.agreementType === "other") {
                if (state.otherAgreementType !== "") {
                  x = true;
                }
              } else if (state.agreementType === "fixed") {
                if (state.endDate !== "") {
                  x = true;
                }
              } else {
                x = true;
              }
            }
          }
        } else {
          if (state.landlordEmergencyContactOption) {
            if (state.landlordEmergencyContact !== "") {
              if (state.agreementType === "other") {
                if (state.otherAgreementType !== "") {
                  x = true;
                }
              } else if (state.agreementType === "fixed") {
                if (state.endDate !== "") {
                  x = true;
                }
              } else {
                x = true;
              }
            }
          } else {
            if (state.agreementType === "other") {
              if (state.otherAgreementType !== "") {
                x = true;
              }
            } else if (state.agreementType === "fixed") {
              if (state.endDate !== "") {
                x = true;
              }
            } else {
              x = true;
            }
          }
        }
      }
    }
  }
  return x;
};

export const canadaContractStep2Verification = (state) => {
  let x = false;
  if (state.rent !== "" && state.rentCollectionOnEach !== "") {
    if (
      state.rentCollectionOnEach === "month" ||
      state.rentCollectionOnEach === "week"
    ) {
      if (state.rentCollectionDay !== "") {
        if (state.rentPayableTo !== "" && state.rentPayableMethod !== "") {
          if (state.isLateFee) {
            if (state.lateFeeAmount !== "") {
              if (state.isPartialRent) {
                if (
                  state.partialRent !== "" &&
                  state.partialRentDate !== "" &&
                  state.partialRentFrom !== "" &&
                  state.partialRentTo !== ""
                ) {
                  if (state.keyDeposit) {
                    if (
                      state.keyDepositAmount !== "" &&
                      state.keyDepositDescription !== ""
                    ) {
                      if (state.securityDeposit) {
                        if (state.securityDepositAmount !== "") {
                          if (state.isRentDiscount) {
                            if (
                              state.rentDiscountType !== "" &&
                              state.rentDiscountDescription !== ""
                            ) {
                              if (state.rentDiscountType === "fixed") {
                                if (state.rentDiscountAmount !== "") {
                                  if (state.rentAmount.length > 0) {
                                    if (
                                      state.rentType[0] !== "" &&
                                      state.rentAmount[0] !== ""
                                    ) {
                                      if (state.rentAmount.length > 1) {
                                        if (
                                          state.rentType[1] !== "" &&
                                          state.rentAmount[1] !== ""
                                        ) {
                                          if (state.rentAmount.length > 2) {
                                            if (
                                              state.rentType[2] !== "" &&
                                              state.rentAmount[2] !== ""
                                            ) {
                                              if (state.isAdditionalTerms) {
                                                if (
                                                  state.additionalTermsDoc !==
                                                  ""
                                                ) {
                                                  x = true;
                                                }
                                              } else {
                                                x = true;
                                              }
                                            }
                                          } else {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else if (
                                state.rentDiscountType === "percentage"
                              ) {
                                if (state.rentDiscountPercentage !== "") {
                                  if (state.rentAmount.length > 0) {
                                    if (
                                      state.rentType[0] !== "" &&
                                      state.rentAmount[0] !== ""
                                    ) {
                                      if (state.rentAmount.length > 1) {
                                        if (
                                          state.rentType[1] !== "" &&
                                          state.rentAmount[1] !== ""
                                        ) {
                                          if (state.rentAmount.length > 2) {
                                            if (
                                              state.rentType[2] !== "" &&
                                              state.rentAmount[2] !== ""
                                            ) {
                                              if (state.isAdditionalTerms) {
                                                if (
                                                  state.additionalTermsDoc !==
                                                  ""
                                                ) {
                                                  x = true;
                                                }
                                              } else {
                                                x = true;
                                              }
                                            }
                                          } else {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              }
                            }
                          } else {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    }
                  } else {
                    if (state.securityDeposit) {
                      if (state.securityDepositAmount !== "") {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  }
                }
              } else {
                if (state.keyDeposit) {
                  if (
                    state.keyDepositAmount !== "" &&
                    state.keyDepositDescription !== ""
                  ) {
                    if (state.securityDeposit) {
                      if (state.securityDepositAmount !== "") {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                            x = true;
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  }
                } else {
                  if (state.securityDeposit) {
                    if (state.securityDepositAmount !== "") {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  } else {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      } else {
                        if (state.isAdditionalTerms) {
                          if (state.additionalTermsDoc !== "") {
                            x = true;
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (state.isPartialRent) {
              if (
                state.partialRent !== "" &&
                state.partialRentDate !== "" &&
                state.partialRentFrom !== "" &&
                state.partialRentTo !== ""
              ) {
                if (state.keyDeposit) {
                  if (
                    state.keyDepositAmount !== "" &&
                    state.keyDepositDescription !== ""
                  ) {
                    if (state.securityDeposit) {
                      if (state.securityDepositAmount !== "") {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            if (state.isAdditionalTerms) {
                                              if (
                                                state.additionalTermsDoc !== ""
                                              ) {
                                                x = true;
                                              }
                                            } else {
                                              x = true;
                                            }
                                          }
                                        } else {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  }
                } else {
                  if (state.securityDeposit) {
                    if (state.securityDepositAmount !== "") {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  } else {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      } else {
                        if (state.isAdditionalTerms) {
                          if (state.additionalTermsDoc !== "") {
                            x = true;
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (state.keyDeposit) {
                if (
                  state.keyDepositAmount !== "" &&
                  state.keyDepositDescription !== ""
                ) {
                  if (state.securityDeposit) {
                    if (state.securityDepositAmount !== "") {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          if (state.isAdditionalTerms) {
                                            if (
                                              state.additionalTermsDoc !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  } else {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      } else {
                        if (state.isAdditionalTerms) {
                          if (state.additionalTermsDoc !== "") {
                            x = true;
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                }
              } else {
                if (state.securityDeposit) {
                  if (state.securityDepositAmount !== "") {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        if (state.isAdditionalTerms) {
                                          if (state.additionalTermsDoc !== "") {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  }
                                } else {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  if (state.isAdditionalTerms) {
                                    if (state.additionalTermsDoc !== "") {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      } else {
                        if (state.isAdditionalTerms) {
                          if (state.additionalTermsDoc !== "") {
                            x = true;
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                } else {
                  if (state.isRentDiscount) {
                    if (
                      state.rentDiscountType !== "" &&
                      state.rentDiscountDescription !== ""
                    ) {
                      if (state.rentDiscountType === "fixed") {
                        if (state.rentDiscountAmount !== "") {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      } else if (state.rentDiscountType === "percentage") {
                        if (state.rentDiscountPercentage !== "") {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      if (state.isAdditionalTerms) {
                                        if (state.additionalTermsDoc !== "") {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    if (state.isAdditionalTerms) {
                                      if (state.additionalTermsDoc !== "") {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                }
                              } else {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          } else {
                            if (state.isAdditionalTerms) {
                              if (state.additionalTermsDoc !== "") {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    }
                  } else {
                    if (state.rentAmount.length > 0) {
                      if (
                        state.rentType[0] !== "" &&
                        state.rentAmount[0] !== ""
                      ) {
                        if (state.rentAmount.length > 1) {
                          if (
                            state.rentType[1] !== "" &&
                            state.rentAmount[1] !== ""
                          ) {
                            if (state.rentAmount.length > 2) {
                              if (
                                state.rentType[2] !== "" &&
                                state.rentAmount[2] !== ""
                              ) {
                                if (state.isAdditionalTerms) {
                                  if (state.additionalTermsDoc !== "") {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              if (state.isAdditionalTerms) {
                                if (state.additionalTermsDoc !== "") {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        } else {
                          if (state.isAdditionalTerms) {
                            if (state.additionalTermsDoc !== "") {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    } else {
                      if (state.isAdditionalTerms) {
                        if (state.additionalTermsDoc !== "") {
                          x = true;
                        }
                      } else {
                        x = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if (state.rentCollectionOnEach === "other") {
      if (
        state.rentCollectionDay !== "" &&
        state.rentCollectionOnEachOther !== ""
      ) {
        if (state.rentPayableTo !== "" && state.rentPayableMethod !== "") {
          if (state.isLateFee) {
            if (state.lateFeeAmount !== "") {
              if (state.isPartialRent) {
                if (
                  state.partialRent !== "" &&
                  state.partialRentDate !== "" &&
                  state.partialRentFrom !== "" &&
                  state.partialRentTo !== ""
                ) {
                  if (state.keyDeposit) {
                    if (
                      state.keyDepositAmount !== "" &&
                      state.keyDepositDescription !== ""
                    ) {
                      if (state.securityDeposit) {
                        if (state.securityDepositAmount !== "") {
                          if (state.isRentDiscount) {
                            if (
                              state.rentDiscountType !== "" &&
                              state.rentDiscountDescription !== ""
                            ) {
                              if (state.rentDiscountType === "fixed") {
                                if (state.rentDiscountAmount !== "") {
                                  if (state.rentAmount.length > 0) {
                                    if (
                                      state.rentType[0] !== "" &&
                                      state.rentAmount[0] !== ""
                                    ) {
                                      if (state.rentAmount.length > 1) {
                                        if (
                                          state.rentType[1] !== "" &&
                                          state.rentAmount[1] !== ""
                                        ) {
                                          if (state.rentAmount.length > 2) {
                                            if (
                                              state.rentType[2] !== "" &&
                                              state.rentAmount[2] !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else if (
                                state.rentDiscountType === "percentage"
                              ) {
                                if (state.rentDiscountPercentage !== "") {
                                  if (state.rentAmount.length > 0) {
                                    if (
                                      state.rentType[0] !== "" &&
                                      state.rentAmount[0] !== ""
                                    ) {
                                      if (state.rentAmount.length > 1) {
                                        if (
                                          state.rentType[1] !== "" &&
                                          state.rentAmount[1] !== ""
                                        ) {
                                          if (state.rentAmount.length > 2) {
                                            if (
                                              state.rentType[2] !== "" &&
                                              state.rentAmount[2] !== ""
                                            ) {
                                              x = true;
                                            }
                                          } else {
                                            x = true;
                                          }
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              }
                            }
                          } else {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      } else {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  } else {
                    if (state.securityDeposit) {
                      if (state.securityDepositAmount !== "") {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    } else {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                }
              } else {
                if (state.keyDeposit) {
                  if (
                    state.keyDepositAmount !== "" &&
                    state.keyDepositDescription !== ""
                  ) {
                    if (state.securityDeposit) {
                      if (state.securityDepositAmount !== "") {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    } else {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                } else {
                  if (state.securityDeposit) {
                    if (state.securityDepositAmount !== "") {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  } else {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (state.isPartialRent) {
              if (
                state.partialRent !== "" &&
                state.partialRentDate !== "" &&
                state.partialRentFrom !== "" &&
                state.partialRentTo !== ""
              ) {
                if (state.keyDeposit) {
                  if (
                    state.keyDepositAmount !== "" &&
                    state.keyDepositDescription !== ""
                  ) {
                    if (state.securityDeposit) {
                      if (state.securityDepositAmount !== "") {
                        if (state.isRentDiscount) {
                          if (
                            state.rentDiscountType !== "" &&
                            state.rentDiscountDescription !== ""
                          ) {
                            if (state.rentDiscountType === "fixed") {
                              if (state.rentDiscountAmount !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else if (
                              state.rentDiscountType === "percentage"
                            ) {
                              if (state.rentDiscountPercentage !== "") {
                                if (state.rentAmount.length > 0) {
                                  if (
                                    state.rentType[0] !== "" &&
                                    state.rentAmount[0] !== ""
                                  ) {
                                    if (state.rentAmount.length > 1) {
                                      if (
                                        state.rentType[1] !== "" &&
                                        state.rentAmount[1] !== ""
                                      ) {
                                        if (state.rentAmount.length > 2) {
                                          if (
                                            state.rentType[2] !== "" &&
                                            state.rentAmount[2] !== ""
                                          ) {
                                            x = true;
                                          }
                                        } else {
                                          x = true;
                                        }
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            }
                          }
                        } else {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    } else {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  }
                } else {
                  if (state.securityDeposit) {
                    if (state.securityDepositAmount !== "") {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  } else {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  }
                }
              }
            } else {
              if (state.keyDeposit) {
                if (
                  state.keyDepositAmount !== "" &&
                  state.keyDepositDescription !== ""
                ) {
                  if (state.securityDeposit) {
                    if (state.securityDepositAmount !== "") {
                      if (state.isRentDiscount) {
                        if (
                          state.rentDiscountType !== "" &&
                          state.rentDiscountDescription !== ""
                        ) {
                          if (state.rentDiscountType === "fixed") {
                            if (state.rentDiscountAmount !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else if (state.rentDiscountType === "percentage") {
                            if (state.rentDiscountPercentage !== "") {
                              if (state.rentAmount.length > 0) {
                                if (
                                  state.rentType[0] !== "" &&
                                  state.rentAmount[0] !== ""
                                ) {
                                  if (state.rentAmount.length > 1) {
                                    if (
                                      state.rentType[1] !== "" &&
                                      state.rentAmount[1] !== ""
                                    ) {
                                      if (state.rentAmount.length > 2) {
                                        if (
                                          state.rentType[2] !== "" &&
                                          state.rentAmount[2] !== ""
                                        ) {
                                          x = true;
                                        }
                                      } else {
                                        x = true;
                                      }
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          }
                        }
                      } else {
                        if (state.rentAmount.length > 0) {
                          if (
                            state.rentType[0] !== "" &&
                            state.rentAmount[0] !== ""
                          ) {
                            if (state.rentAmount.length > 1) {
                              if (
                                state.rentType[1] !== "" &&
                                state.rentAmount[1] !== ""
                              ) {
                                if (state.rentAmount.length > 2) {
                                  if (
                                    state.rentType[2] !== "" &&
                                    state.rentAmount[2] !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    }
                  } else {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  }
                }
              } else {
                if (state.securityDeposit) {
                  if (state.securityDepositAmount !== "") {
                    if (state.isRentDiscount) {
                      if (
                        state.rentDiscountType !== "" &&
                        state.rentDiscountDescription !== ""
                      ) {
                        if (state.rentDiscountType === "fixed") {
                          if (state.rentDiscountAmount !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else if (state.rentDiscountType === "percentage") {
                          if (state.rentDiscountPercentage !== "") {
                            if (state.rentAmount.length > 0) {
                              if (
                                state.rentType[0] !== "" &&
                                state.rentAmount[0] !== ""
                              ) {
                                if (state.rentAmount.length > 1) {
                                  if (
                                    state.rentType[1] !== "" &&
                                    state.rentAmount[1] !== ""
                                  ) {
                                    if (state.rentAmount.length > 2) {
                                      if (
                                        state.rentType[2] !== "" &&
                                        state.rentAmount[2] !== ""
                                      ) {
                                        x = true;
                                      }
                                    } else {
                                      x = true;
                                    }
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        }
                      }
                    } else {
                      if (state.rentAmount.length > 0) {
                        if (
                          state.rentType[0] !== "" &&
                          state.rentAmount[0] !== ""
                        ) {
                          if (state.rentAmount.length > 1) {
                            if (
                              state.rentType[1] !== "" &&
                              state.rentAmount[1] !== ""
                            ) {
                              if (state.rentAmount.length > 2) {
                                if (
                                  state.rentType[2] !== "" &&
                                  state.rentAmount[2] !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  }
                } else {
                  if (state.isRentDiscount) {
                    if (
                      state.rentDiscountType !== "" &&
                      state.rentDiscountDescription !== ""
                    ) {
                      if (state.rentDiscountType === "fixed") {
                        if (state.rentDiscountAmount !== "") {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else if (state.rentDiscountType === "percentage") {
                        if (state.rentDiscountPercentage !== "") {
                          if (state.rentAmount.length > 0) {
                            if (
                              state.rentType[0] !== "" &&
                              state.rentAmount[0] !== ""
                            ) {
                              if (state.rentAmount.length > 1) {
                                if (
                                  state.rentType[1] !== "" &&
                                  state.rentAmount[1] !== ""
                                ) {
                                  if (state.rentAmount.length > 2) {
                                    if (
                                      state.rentType[2] !== "" &&
                                      state.rentAmount[2] !== ""
                                    ) {
                                      x = true;
                                    }
                                  } else {
                                    x = true;
                                  }
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      }
                    }
                  } else {
                    if (state.rentAmount.length > 0) {
                      if (
                        state.rentType[0] !== "" &&
                        state.rentAmount[0] !== ""
                      ) {
                        if (state.rentAmount.length > 1) {
                          if (
                            state.rentType[1] !== "" &&
                            state.rentAmount[1] !== ""
                          ) {
                            if (state.rentAmount.length > 2) {
                              if (
                                state.rentType[2] !== "" &&
                                state.rentAmount[2] !== ""
                              ) {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      x = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return x;
};
export const canadaStep1Verification = (state) => {
  let x = false;


  if (
    state.applicantFirstName !== "" &&
    state.applicantLastName !== "" &&
    state.applicantEmail !== "" &&
    checkEmailNext(state.applicantEmail) &&
    state.applicantDob !== "" &&
    state.applicantGender !== "" &&
    state.applicantSIN !== "" &&
    checkSINNext(state.applicantSIN) &&
    state.applicantMobile !== "" &&
    checkMobileNext(state.applicantMobile) &&
    state.applicantFirstReferencesName !== "" &&
    state.applicantFirstReferencesAddress !== "" &&
    state.applicantFirstReferencesTelephone !== "" &&
    checkMobileNext(state.applicantFirstReferencesTelephone) &&
    state.applicantSecondReferencesName !== "" &&
    state.applicantSecondReferencesAddress !== "" &&
    state.applicantSecondReferencesTelephone !== "" &&
    checkMobileNext(state.applicantSecondReferencesTelephone) &&
    state.moveInDate !== "" &&
    state.emergencyContactName !== "" &&
    state.emergencyContactPhone !== "" &&
    checkMobileNext(state.emergencyContactPhone) &&
    state.emergencyContactRelation !== ""
  ) {
    if (state.applicantVehicle) {
      if (
        state.applicantDriversLicense !== "-" &&
        state.applicantVehicleMadeBy !== "" &&
        state.applicantVehicleModel !== "" &&
        state.applicantVehicleYear !== "" &&
        state.applicantVehicleLicenseNumber !== ""
      ) {
        if (state.applicant2) {
          if (
            state.applicant2FirstName !== "" &&
            state.applicant2LastName !== "" &&
            state.applicant2Email !== "" &&
            checkEmailNext(state.applicant2Email) &&
            state.applicant2Dob !== "" &&
            state.applicant2Gender !== "" &&
            state.applicant2SIN !== "" &&
            checkSINNext(state.applicant2SIN) !== "" &&
            state.applicant2Mobile !== "" &&
            checkMobileNext(state.applicant2Mobile) &&
            state.applicant2FirstReferencesName !== "" &&
            state.applicant2FirstReferencesAddress !== "" &&
            state.applicant2FirstReferencesTelephone !== "" &&
            checkMobileNext(state.applicant2FirstReferencesTelephone) &&
            state.applicant2SecondReferencesName !== "" &&
            state.applicant2SecondReferencesAddress !== "" &&
            state.applicant2SecondReferencesTelephone !== "" &&
            checkMobileNext(state.applicant2SecondReferencesTelephone)
          ) {
            if (state.applicant2Vehicle) {
              if (
                state.applicant2DriversLicense !== "" &&
                state.applicant2VehicleMadeBy !== "" &&
                state.applicant2VehicleModel !== "" &&
                state.applicant2VehicleYear !== "" &&
                state.applicant2VehicleLicenseNumber !== ""
              ) {
                if (state.applicant3) {
                  if (
                    state.applicant3FirstName !== "" &&
                    state.applicant3LastName !== "" &&
                    state.applicant3Email !== "" &&
                    checkEmailNext(state.applicant3Email) &&
                    state.applicant3Dob !== "" &&
                    state.applicant3Gender !== "" &&
                    state.applicant3SIN !== "" &&
                    checkSINNext(state.applicant3SIN) !== "" &&
                    state.applicant3Mobile !== "" &&
                    checkMobileNext(state.applicant3Mobile) &&
                    state.applicant3FirstReferencesName !== "" &&
                    state.applicant3FirstReferencesAddress !== "" &&
                    state.applicant3FirstReferencesTelephone !== "" &&
                    checkMobileNext(state.applicant3FirstReferencesTelephone) &&
                    state.applicant3SecondReferencesName !== "" &&
                    state.applicant3SecondReferencesAddress !== "" &&
                    checkMobileNext(
                      state.applicant3SecondReferencesTelephone
                    ) &&
                    state.applicant3SecondReferencesTelephone !== ""
                  ) {
                    if (state.applicant3Vehicle) {
                      if (
                        state.applicant3DriversLicense !== "" &&
                        state.applicant3VehicleMadeBy !== "" &&
                        state.applicant3VehicleModel !== "" &&
                        state.applicant3VehicleYear !== "" &&
                        state.applicant3VehicleLicenseNumber !== ""
                      ) {
                        if (state.additionalApplicantInfo) {
                          if (
                            state.additionalApplicantFirstName !== "" &&
                            state.additionalApplicantLastName !== "" &&
                            state.additionalApplicantDob !== "" &&
                            state.additionalApplicantGender !== "" &&
                            checkSINNext(state.additionalApplicantSIN) !== "" &&
                            state.additionalApplicantSIN !== ""
                          ) {
                            if (state.additionalApplicant2) {
                              if (
                                state.additionalApplicant2FirstName !== "" &&
                                state.additionalApplicant2LastName !== "" &&
                                state.additionalApplicant2Dob !== "" &&
                                state.additionalApplicant2Gender !== "" &&
                                checkSINNext(state.additionalApplicant2SIN) !==
                                  "" &&
                                state.additionalApplicant2SIN !== ""
                              ) {
                                if (state.additionalApplicant3) {
                                  if (
                                    state.additionalApplicant3FirstName !==
                                      "" &&
                                    state.additionalApplicant3MiddleName !==
                                      "" &&
                                    state.additionalApplicant3LastName !== "" &&
                                    state.additionalApplicant3Dob !== "" &&
                                    state.additionalApplicant3Gender !== "" &&
                                    checkSINNext(
                                      state.additionalApplicant3SIN
                                    ) !== "" &&
                                    state.additionalApplicant3SIN !== ""
                                  ) {
                                    x = true;
                                  }
                                } else {
                                  x = true;
                                }
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      if (state.additionalApplicantInfo) {
                        if (
                          state.additionalApplicantFirstName !== "" &&
                          state.additionalApplicantLastName !== "" &&
                          state.additionalApplicantDob !== "" &&
                          state.additionalApplicantGender !== "" &&
                          checkSINNext(state.additionalApplicantSIN) !== "" &&
                          state.additionalApplicantSIN !== ""
                        ) {
                          if (state.additionalApplicant2) {
                            if (
                              state.additionalApplicant2FirstName !== "" &&
                              state.additionalApplicant2LastName !== "" &&
                              state.additionalApplicant2Dob !== "" &&
                              state.additionalApplicant2Gender !== "" &&
                              checkSINNext(state.additionalApplicant2SIN) !==
                                "" &&
                              state.additionalApplicant2SIN !== ""
                            ) {
                              if (state.additionalApplicant3) {
                                if (
                                  state.additionalApplicant3FirstName !== "" &&
                                  state.additionalApplicant3LastName !== "" &&
                                  state.additionalApplicant3Dob !== "" &&
                                  state.additionalApplicant3Gender !== "" &&
                                  checkSINNext(
                                    state.additionalApplicant3SIN
                                  ) !== "" &&
                                  state.additionalApplicant3SIN !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  }
                } else {
                  if (state.additionalApplicantInfo) {
                    if (
                      state.additionalApplicantFirstName !== "" &&
                      state.additionalApplicantLastName !== "" &&
                      state.additionalApplicantDob !== "" &&
                      state.additionalApplicantGender !== "" &&
                      checkSINNext(state.additionalApplicantSIN) !== "" &&
                      state.additionalApplicantSIN !== ""
                    ) {
                      if (state.additionalApplicant2) {
                        if (
                          state.additionalApplicant2FirstName !== "" &&
                          state.additionalApplicant2LastName !== "" &&
                          state.additionalApplicant2Dob !== "" &&
                          state.additionalApplicant2Gender !== "" &&
                          checkSINNext(state.additionalApplicant2SIN) !== "" &&
                          state.additionalApplicant2SIN !== ""
                        ) {
                          if (state.additionalApplicant3) {
                            if (
                              state.additionalApplicant3FirstName !== "" &&
                              state.additionalApplicant3LastName !== "" &&
                              state.additionalApplicant3Dob !== "" &&
                              state.additionalApplicant3Gender !== "" &&
                              checkSINNext(state.additionalApplicant3SIN) !==
                                "" &&
                              state.additionalApplicant3SIN !== ""
                            ) {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  } else {
                    x = true;
                  }
                }
              }
            } else {
              if (state.applicant3) {
                if (
                  state.applicant3FirstName !== "" &&
                  state.applicant3LastName !== "" &&
                  state.applicant3Email !== "" &&
                  checkEmailNext(state.applicant3Email) &&
                  state.applicant3Dob !== "" &&
                  state.applicant3Gender !== "" &&
                  state.applicant3SIN !== "" &&
                  checkSINNext(state.applicant3SIN) !== "" &&
                  state.applicant3Mobile !== "" &&
                  checkMobileNext(state.applicant3Mobile) &&
                  state.applicant3FirstReferencesName !== "" &&
                  state.applicant3FirstReferencesAddress !== "" &&
                  state.applicant3FirstReferencesTelephone !== "" &&
                  checkMobileNext(state.applicant3FirstReferencesTelephone) &&
                  state.applicant3SecondReferencesName !== "" &&
                  state.applicant3SecondReferencesAddress !== "" &&
                  checkMobileNext(state.applicant3SecondReferencesTelephone) &&
                  state.applicant3SecondReferencesTelephone !== ""
                ) {
                  if (state.applicant3Vehicle) {
                    if (
                      state.applicant3DriversLicense !== "" &&
                      state.applicant3VehicleMadeBy !== "" &&
                      state.applicant3VehicleModel !== "" &&
                      state.applicant3VehicleYear !== "" &&
                      state.applicant3VehicleLicenseNumber !== ""
                    ) {
                      if (state.additionalApplicantInfo) {
                        if (
                          state.additionalApplicantFirstName !== "" &&
                          state.additionalApplicantLastName !== "" &&
                          state.additionalApplicantDob !== "" &&
                          state.additionalApplicantGender !== "" &&
                          checkSINNext(state.additionalApplicantSIN) !== "" &&
                          state.additionalApplicantSIN !== ""
                        ) {
                          if (state.additionalApplicant2) {
                            if (
                              state.additionalApplicant2FirstName !== "" &&
                              state.additionalApplicant2LastName !== "" &&
                              state.additionalApplicant2Dob !== "" &&
                              state.additionalApplicant2Gender !== "" &&
                              checkSINNext(state.additionalApplicant2SIN) !==
                                "" &&
                              state.additionalApplicant2SIN !== ""
                            ) {
                              if (state.additionalApplicant3) {
                                if (
                                  state.additionalApplicant3FirstName !== "" &&
                                  state.additionalApplicant3LastName !== "" &&
                                  state.additionalApplicant3Dob !== "" &&
                                  state.additionalApplicant3Gender !== "" &&
                                  checkSINNext(
                                    state.additionalApplicant3SIN
                                  ) !== "" &&
                                  state.additionalApplicant3SIN !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  } else {
                    if (state.additionalApplicantInfo) {
                      if (
                        state.additionalApplicantFirstName !== "" &&
                        state.additionalApplicantLastName !== "" &&
                        state.additionalApplicantDob !== "" &&
                        state.additionalApplicantGender !== "" &&
                        checkSINNext(state.additionalApplicantSIN) !== "" &&
                        state.additionalApplicantSIN !== ""
                      ) {
                        if (state.additionalApplicant2) {
                          if (
                            state.additionalApplicant2FirstName !== "" &&
                            state.additionalApplicant2LastName !== "" &&
                            state.additionalApplicant2Dob !== "" &&
                            state.additionalApplicant2Gender !== "" &&
                            checkSINNext(state.additionalApplicant2SIN) !==
                              "" &&
                            state.additionalApplicant2SIN !== ""
                          ) {
                            if (state.additionalApplicant3) {
                              if (
                                state.additionalApplicant3FirstName !== "" &&
                                state.additionalApplicant3LastName !== "" &&
                                state.additionalApplicant3Dob !== "" &&
                                state.additionalApplicant3Gender !== "" &&
                                checkSINNext(state.additionalApplicant3SIN) !==
                                  "" &&
                                state.additionalApplicant3SIN !== ""
                              ) {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      x = true;
                    }
                  }
                }
              } else {
                if (state.additionalApplicantInfo) {
                  if (
                    state.additionalApplicantFirstName !== "" &&
                    state.additionalApplicantLastName !== "" &&
                    state.additionalApplicantDob !== "" &&
                    state.additionalApplicantGender !== "" &&
                    checkSINNext(state.additionalApplicantSIN) !== "" &&
                    state.additionalApplicantSIN !== ""
                  ) {
                    if (state.additionalApplicant2) {
                      if (
                        state.additionalApplicant2FirstName !== "" &&
                        state.additionalApplicant2LastName !== "" &&
                        state.additionalApplicant2Dob !== "" &&
                        state.additionalApplicant2Gender !== "" &&
                        checkSINNext(state.additionalApplicant2SIN) !== "" &&
                        state.additionalApplicant2SIN !== ""
                      ) {
                        if (state.additionalApplicant3) {
                          if (
                            state.additionalApplicant3FirstName !== "" &&
                            state.additionalApplicant3LastName !== "" &&
                            state.additionalApplicant3Dob !== "" &&
                            state.additionalApplicant3Gender !== "" &&
                            checkSINNext(state.additionalApplicant3SIN) !==
                              "" &&
                            state.additionalApplicant3SIN !== ""
                          ) {
                            x = true;
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      x = true;
                    }
                  }
                } else {
                  x = true;
                }
              }
            }
          }
        } else {
          if (state.additionalApplicantInfo) {
            if (
              state.additionalApplicantFirstName !== "" &&
              state.additionalApplicantLastName !== "" &&
              state.additionalApplicantDob !== "" &&
              state.additionalApplicantGender !== "" &&
              checkSINNext(state.additionalApplicantSIN) !== "" &&
              state.additionalApplicantSIN !== ""
            ) {
              if (state.additionalApplicant2) {
                if (
                  state.additionalApplicant2FirstName !== "" &&
                  state.additionalApplicant2LastName !== "" &&
                  state.additionalApplicant2Dob !== "" &&
                  state.additionalApplicant2Gender !== "" &&
                  checkSINNext(state.additionalApplicant2SIN) !== "" &&
                  state.additionalApplicant2SIN !== ""
                ) {
                  if (state.additionalApplicant3) {
                    if (
                      state.additionalApplicant3FirstName !== "" &&
                      state.additionalApplicant3LastName !== "" &&
                      state.additionalApplicant3Dob !== "" &&
                      state.additionalApplicant3Gender !== "" &&
                      checkSINNext(state.additionalApplicant3SIN) !== "" &&
                      state.additionalApplicant3SIN !== ""
                    ) {
                      x = true;
                    }
                  } else {
                    x = true;
                  }
                }
              } else {
                x = true;
              }
            }
          } else {
            x = true;
          }
        }
      }
    } else {
      if (state.applicant2) {
        if (
          state.applicant2FirstName !== "" &&
          state.applicant2LastName !== "" &&
          state.applicant2Email !== "" &&
          checkEmailNext(state.applicant2Email) &&
          state.applicant2Dob !== "" &&
          state.applicant2Gender !== "" &&
          state.applicant2SIN !== "" &&
          checkSINNext(state.applicant2SIN) !== "" &&
          state.applicant2Mobile !== "" &&
          checkMobileNext(state.applicant2Mobile) &&
          state.applicant2FirstReferencesName !== "" &&
          state.applicant2FirstReferencesAddress !== "" &&
          state.applicant2FirstReferencesTelephone !== "" &&
          checkMobileNext(state.applicant2FirstReferencesTelephone) &&
          state.applicant2SecondReferencesName !== "" &&
          state.applicant2SecondReferencesAddress !== "" &&
          checkMobileNext(state.applicant2SecondReferencesTelephone) &&
          state.applicant2SecondReferencesTelephone !== ""
        ) {
          if (state.applicant2Vehicle) {
            if (
              state.applicant2DriversLicense !== "" &&
              state.applicant2VehicleMadeBy !== "" &&
              state.applicant2VehicleModel !== "" &&
              state.applicant2VehicleYear !== "" &&
              state.applicant2VehicleLicenseNumber !== ""
            ) {
              if (state.applicant3) {
                if (
                  state.applicant3FirstName !== "" &&
                  state.applicant3LastName !== "" &&
                  state.applicant3Email !== "" &&
                  checkEmailNext(state.applicant3Email) &&
                  state.applicant3Dob !== "" &&
                  state.applicant3Gender !== "" &&
                  state.applicant3SIN !== "" &&
                  checkSINNext(state.applicant3SIN) !== "" &&
                  state.applicant3Mobile !== "" &&
                  checkMobileNext(state.applicant3Mobile) &&
                  state.applicant3FirstReferencesName !== "" &&
                  state.applicant3FirstReferencesAddress !== "" &&
                  state.applicant3FirstReferencesTelephone !== "" &&
                  checkMobileNext(state.applicant3FirstReferencesTelephone) &&
                  state.applicant3SecondReferencesName !== "" &&
                  state.applicant3SecondReferencesAddress !== "" &&
                  checkMobileNext(state.applicant3SecondReferencesTelephone) &&
                  state.applicant3SecondReferencesTelephone !== ""
                ) {
                  if (state.applicant3Vehicle) {
                    if (
                      state.applicant3DriversLicense !== "" &&
                      state.applicant3VehicleMadeBy !== "" &&
                      state.applicant3VehicleModel !== "" &&
                      state.applicant3VehicleYear !== "" &&
                      state.applicant3VehicleLicenseNumber !== ""
                    ) {
                      if (state.additionalApplicantInfo) {
                        if (
                          state.additionalApplicantFirstName !== "" &&
                          state.additionalApplicantLastName !== "" &&
                          state.additionalApplicantDob !== "" &&
                          state.additionalApplicantGender !== "" &&
                          checkSINNext(state.additionalApplicantSIN) !== "" &&
                          state.additionalApplicantSIN !== ""
                        ) {
                          if (state.additionalApplicant2) {
                            if (
                              state.additionalApplicant2FirstName !== "" &&
                              state.additionalApplicant2LastName !== "" &&
                              state.additionalApplicant2Dob !== "" &&
                              state.additionalApplicant2Gender !== "" &&
                              checkSINNext(state.additionalApplicant2SIN) !==
                                "" &&
                              state.additionalApplicant2SIN !== ""
                            ) {
                              if (state.additionalApplicant3) {
                                if (
                                  state.additionalApplicant3FirstName !== "" &&
                                  state.additionalApplicant3LastName !== "" &&
                                  state.additionalApplicant3Dob !== "" &&
                                  state.additionalApplicant3Gender !== "" &&
                                  checkSINNext(
                                    state.additionalApplicant3SIN
                                  ) !== "" &&
                                  state.additionalApplicant3SIN !== ""
                                ) {
                                  x = true;
                                }
                              } else {
                                x = true;
                              }
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  } else {
                    if (state.additionalApplicantInfo) {
                      if (
                        state.additionalApplicantFirstName !== "" &&
                        state.additionalApplicantLastName !== "" &&
                        state.additionalApplicantDob !== "" &&
                        state.additionalApplicantGender !== "" &&
                        checkSINNext(state.additionalApplicantSIN) !== "" &&
                        state.additionalApplicantSIN !== ""
                      ) {
                        if (state.additionalApplicant2) {
                          if (
                            state.additionalApplicant2FirstName !== "" &&
                            state.additionalApplicant2LastName !== "" &&
                            state.additionalApplicant2Dob !== "" &&
                            state.additionalApplicant2Gender !== "" &&
                            checkSINNext(state.additionalApplicant2SIN) !==
                              "" &&
                            state.additionalApplicant2SIN !== ""
                          ) {
                            if (state.additionalApplicant3) {
                              if (
                                state.additionalApplicant3FirstName !== "" &&
                                state.additionalApplicant3LastName !== "" &&
                                state.additionalApplicant3Dob !== "" &&
                                state.additionalApplicant3Gender !== "" &&
                                checkSINNext(state.additionalApplicant3SIN) !==
                                  "" &&
                                state.additionalApplicant3SIN !== ""
                              ) {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      x = true;
                    }
                  }
                }
              } else {
                if (state.additionalApplicantInfo) {
                  if (
                    state.additionalApplicantFirstName !== "" &&
                    state.additionalApplicantLastName !== "" &&
                    state.additionalApplicantDob !== "" &&
                    state.additionalApplicantGender !== "" &&
                    checkSINNext(state.additionalApplicantSIN) !== "" &&
                    state.additionalApplicantSIN !== ""
                  ) {
                    if (state.additionalApplicant2) {
                      if (
                        state.additionalApplicant2FirstName !== "" &&
                        state.additionalApplicant2LastName !== "" &&
                        state.additionalApplicant2Dob !== "" &&
                        state.additionalApplicant2Gender !== "" &&
                        checkSINNext(state.additionalApplicant2SIN) !== "" &&
                        state.additionalApplicant2SIN !== ""
                      ) {
                        if (state.additionalApplicant3) {
                          if (
                            state.additionalApplicant3FirstName !== "" &&
                            state.additionalApplicant3LastName !== "" &&
                            state.additionalApplicant3Dob !== "" &&
                            state.additionalApplicant3Gender !== "" &&
                            checkSINNext(state.additionalApplicant3SIN) !==
                              "" &&
                            state.additionalApplicant3SIN !== ""
                          ) {
                            x = true;
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      x = true;
                    }
                  }
                } else {
                  x = true;
                }
              }
            }
          } else {
            if (state.applicant3) {
              if (
                state.applicant3FirstName !== "" &&
                state.applicant3LastName !== "" &&
                state.applicant3Email !== "" &&
                checkEmailNext(state.applicant3Email) &&
                state.applicant3Dob !== "" &&
                state.applicant3Gender !== "" &&
                state.applicant3SIN !== "" &&
                checkSINNext(state.applicant3SIN) !== "" &&
                state.applicant3Mobile !== "" &&
                checkMobileNext(state.applicant3Mobile) &&
                state.applicant3FirstReferencesName !== "" &&
                state.applicant3FirstReferencesAddress !== "" &&
                state.applicant3FirstReferencesTelephone !== "" &&
                checkMobileNext(state.applicant3FirstReferencesTelephone) &&
                state.applicant3SecondReferencesName !== "" &&
                state.applicant3SecondReferencesAddress !== "" &&
                checkMobileNext(state.applicant3SecondReferencesTelephone) &&
                state.applicant3SecondReferencesTelephone !== ""
              ) {
                if (state.applicant3Vehicle) {
                  if (
                    state.applicant3DriversLicense !== "" &&
                    state.applicant3VehicleMadeBy !== "" &&
                    state.applicant3VehicleModel !== "" &&
                    state.applicant3VehicleYear !== "" &&
                    state.applicant3VehicleLicenseNumber !== ""
                  ) {
                    if (state.additionalApplicantInfo) {
                      if (
                        state.additionalApplicantFirstName !== "" &&
                        state.additionalApplicantLastName !== "" &&
                        state.additionalApplicantDob !== "" &&
                        state.additionalApplicantGender !== "" &&
                        state.additionalApplicantSIN !== "" &&
                        checkSINNext(state.additionalApplicantSIN) !== ""
                      ) {
                        if (state.additionalApplicant2) {
                          if (
                            state.additionalApplicant2FirstName !== "" &&
                            state.additionalApplicant2LastName !== "" &&
                            state.additionalApplicant2Dob !== "" &&
                            state.additionalApplicant2Gender !== "" &&
                            checkSINNext(state.additionalApplicant2SIN) !==
                              "" &&
                            state.additionalApplicant2SIN !== ""
                          ) {
                            if (state.additionalApplicant3) {
                              if (
                                state.additionalApplicant3FirstName !== "" &&
                                state.additionalApplicant3LastName !== "" &&
                                state.additionalApplicant3Dob !== "" &&
                                state.additionalApplicant3Gender !== "" &&
                                checkSINNext(state.additionalApplicant3SIN) !==
                                  "" &&
                                state.additionalApplicant3SIN !== ""
                              ) {
                                x = true;
                              }
                            } else {
                              x = true;
                            }
                          }
                        } else {
                          x = true;
                        }
                      }
                    } else {
                      x = true;
                    }
                  }
                } else {
                  if (state.additionalApplicantInfo) {
                    if (
                      state.additionalApplicantFirstName !== "" &&
                      state.additionalApplicantLastName !== "" &&
                      state.additionalApplicantDob !== "" &&
                      state.additionalApplicantGender !== "" &&
                      checkSINNext(state.additionalApplicantSIN) !== "" &&
                      state.additionalApplicantSIN !== ""
                    ) {
                      if (state.additionalApplicant2) {
                        if (
                          state.additionalApplicant2FirstName !== "" &&
                          state.additionalApplicant2LastName !== "" &&
                          state.additionalApplicant2Dob !== "" &&
                          state.additionalApplicant2Gender !== "" &&
                          checkSINNext(state.additionalApplicant2SIN) !== "" &&
                          state.additionalApplicant2SIN !== ""
                        ) {
                          if (state.additionalApplicant3) {
                            if (
                              state.additionalApplicant3FirstName !== "" &&
                              state.additionalApplicant3LastName !== "" &&
                              state.additionalApplicant3Dob !== "" &&
                              state.additionalApplicant3Gender !== "" &&
                              checkSINNext(state.additionalApplicant3SIN) !==
                                "" &&
                              state.additionalApplicant3SIN !== ""
                            ) {
                              x = true;
                            }
                          } else {
                            x = true;
                          }
                        }
                      } else {
                        x = true;
                      }
                    }
                  } else {
                    x = true;
                  }
                }
              }
            } else {
              if (state.additionalApplicantInfo) {
                if (
                  state.additionalApplicantFirstName !== "" &&
                  state.additionalApplicantLastName !== "" &&
                  state.additionalApplicantDob !== "" &&
                  state.additionalApplicantGender !== "" &&
                  checkSINNext(state.additionalApplicantSIN) !== "" &&
                  state.additionalApplicantSIN !== ""
                ) {
                  if (state.additionalApplicant2) {
                    if (
                      state.additionalApplicant2FirstName !== "" &&
                      state.additionalApplicant2LastName !== "" &&
                      state.additionalApplicant2Dob !== "" &&
                      state.additionalApplicant2Gender !== "" &&
                      checkSINNext(state.additionalApplicant2SIN) !== "" &&
                      state.additionalApplicant2SIN !== ""
                    ) {
                      if (state.additionalApplicant3) {
                        if (
                          state.additionalApplicant3FirstName !== "" &&
                          state.additionalApplicant3LastName !== "" &&
                          state.additionalApplicant3Dob !== "" &&
                          state.additionalApplicant3Gender !== "" &&
                          checkSINNext(state.additionalApplicant3SIN) !== "" &&
                          state.additionalApplicant3SIN !== ""
                        ) {
                          x = true;
                        }
                      } else {
                        x = true;
                      }
                    }
                  } else {
                    x = true;
                  }
                }
              } else {
                x = true;
              }
            }
          }
        }
      } else {
        if (state.additionalApplicantInfo) {
          if (
            state.additionalApplicantFirstName !== "" &&
            state.additionalApplicantLastName !== "" &&
            state.additionalApplicantDob !== "" &&
            state.additionalApplicantGender !== "" &&
            checkSINNext(state.additionalApplicantSIN) !== "" &&
            state.additionalApplicantSIN !== ""
          ) {
            if (state.additionalApplicant2) {
              if (
                state.additionalApplicant2FirstName !== "" &&
                state.additionalApplicant2LastName !== "" &&
                state.additionalApplicant2Dob !== "" &&
                state.additionalApplicant2Gender !== "" &&
                checkSINNext(state.additionalApplicant2SIN) !== "" &&
                state.additionalApplicant2SIN !== ""
              ) {
                if (state.additionalApplicant3) {
                  if (
                    state.additionalApplicant3FirstName !== "" &&
                    state.additionalApplicant3LastName !== "" &&
                    state.additionalApplicant3Dob !== "" &&
                    state.additionalApplicant3Gender !== "" &&
                    checkSINNext(state.additionalApplicant3SIN) !== "" &&
                    state.additionalApplicant3SIN !== ""
                  ) {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            } else {
              x = true;
            }
          }
        } else {
          x = true;
        }
      }
    }
  }

  return x;
};

export const canadaStep2Verification = (state) => {
  let x2 = false;
  if (
    state.presentAddress !== "" &&
    state.presentState !== "" &&
    state.presentCity !== "" &&
    state.presentZip !== "" &&
    state.presentFrom !== "" &&
    state.presentTo !== "" &&
    state.presentHomePhone !== "" &&
    checkMobileNext(state.presentHomePhone) &&
    state.presentReasonLeaving !== "" &&
    state.presentPropertyType !== ""
  ) {
    if (state.presentPropertyType === "Rent") {
      if (
        state.presentLandlord !== "" &&
        state.presentLandlordPhone !== "" &&
        checkMobileNext(state.presentLandlordPhone) &&
        state.presentLandlordIDProof !== "" &&
        state.presentMonthlyRent !== ""
      ) {
        if (state.applicantPreviousInfo) {
          if (
            state.previousAddress !== "" &&
            state.previousState !== "" &&
            state.previousCity !== "" &&
            state.previousZip !== "" &&
            state.previousFrom !== "" &&
            state.previousTo !== "" &&
            state.previousHomePhone !== "" &&
            state.previousReasonLeaving !== "" &&
            state.previousPropertyType !== ""
          ) {
            if (state.previousPropertyType === "Rent") {
              if (
                state.previousLandlord !== "" &&
                state.previousLandlordPhone !== "" &&
                state.previousMonthlyRent !== ""
              ) {
                if (state.applicant2) {
                  if (
                    state.applicant2PresentAddress !== "" &&
                    state.applicant2PresentState !== "" &&
                    state.applicant2PresentCity !== "" &&
                    state.applicant2PresentZip !== "" &&
                    state.applicant2PresentFrom !== "" &&
                    state.applicant2PresentTo !== "" &&
                    state.applicant2PresentHomePhone !== "" &&
                    checkMobileNext(state.applicant2PresentHomePhone) &&
                    state.applicant2PresentReasonLeaving !== "" &&
                    state.applicant2PresentPropertyType !== ""
                  ) {
                    if (state.applicant2PresentPropertyType === "Rent") {
                      if (
                        state.applicant2PresentLandlord !== "" &&
                        state.applicant2PresentLandlordPhone !== "" &&
                        checkMobileNext(state.applicant2PresentLandlordPhone) &&
                        state.applicant2PresentLandlordIDProof !== "" &&
                        state.applicant2PresentMonthlyRent !== ""
                      ) {
                        if (state.applicant2PreviousInfo) {
                          if (
                            state.applicant2PreviousAddress !== "" &&
                            state.applicant2PreviousState !== "" &&
                            state.applicant2PreviousCity !== "" &&
                            state.applicant2PreviousZip !== "" &&
                            state.applicant2PreviousFrom !== "" &&
                            state.applicant2PreviousTo !== "" &&
                            state.applicant2PreviousHomePhone !== "" &&
                            state.applicant2PreviousReasonLeaving !== "" &&
                            state.applicant2PreviousPropertyType !== ""
                          ) {
                            if (
                              state.applicant2PreviousPropertyType === "Rent"
                            ) {
                              if (
                                state.applicant2PreviousLandlord !== "" &&
                                state.applicant2PreviousLandlordPhone !== "" &&
                                state.applicant2PreviousMonthlyRent !== ""
                              ) {
                                if (state.applicant3) {
                                  if (
                                    state.applicant3PresentAddress !== "" &&
                                    state.applicant3PresentState !== "" &&
                                    state.applicant3PresentCity !== "" &&
                                    state.applicant3PresentZip !== "" &&
                                    state.applicant3PresentFrom !== "" &&
                                    state.applicant3PresentTo !== "" &&
                                    state.applicant3PresentHomePhone !== "" &&
                                    checkMobileNext(
                                      state.applicant3PresentHomePhone
                                    ) &&
                                    checkMobileNext(
                                      state.applicant3PresentHomePhone
                                    ) &&
                                    state.applicant3PresentReasonLeaving !==
                                      "" &&
                                    state.applicant3PresentPropertyType !== ""
                                  ) {
                                    if (
                                      state.applicant3PresentPropertyType ===
                                      "Rent"
                                    ) {
                                      if (
                                        state.applicant3PresentLandlord !==
                                          "" &&
                                        state.applicant3PresentLandlordPhone !==
                                          "" &&
                                        checkMobileNext(
                                          state.applicant3PresentLandlordPhone
                                        ) &&
                                        state.applicant3PresentLandlordIDProof !==
                                          "" &&
                                        state.applicant3PresentMonthlyRent !==
                                          ""
                                      ) {
                                        if (state.applicant3PreviousInfo) {
                                          if (
                                            state.applicant3PreviousAddress !==
                                              "" &&
                                            state.applicant3PreviousState !==
                                              "" &&
                                            state.applicant3PreviousCity !==
                                              "" &&
                                            state.applicant3PreviousZip !==
                                              "" &&
                                            state.applicant3PreviousFrom !==
                                              "" &&
                                            state.applicant3PreviousTo !== "" &&
                                            state.applicant3PreviousHomePhone !==
                                              "" &&
                                            state.applicant3PreviousReasonLeaving !==
                                              "" &&
                                            state.applicant3PreviousPropertyType !==
                                              ""
                                          ) {
                                            if (
                                              state.applicant3PreviousPropertyType ===
                                              "Rent"
                                            ) {
                                              if (
                                                state.applicant3PreviousLandlord !==
                                                  "" &&
                                                state.applicant3PreviousLandlordPhone !==
                                                  "" &&
                                                state.applicant3PreviousMonthlyRent !==
                                                  ""
                                              ) {
                                                x2 = true;
                                              }
                                            } else {
                                              x2 = true;
                                            }
                                          }
                                        } else {
                                          x2 = true;
                                        }
                                      }
                                    } else {
                                      x2 = true;
                                    }
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              if (state.applicant3) {
                                if (
                                  state.applicant3PresentAddress !== "" &&
                                  state.applicant3PresentState !== "" &&
                                  state.applicant3PresentCity !== "" &&
                                  state.applicant3PresentZip !== "" &&
                                  state.applicant3PresentFrom !== "" &&
                                  state.applicant3PresentTo !== "" &&
                                  state.applicant3PresentHomePhone !== "" &&
                                  checkMobileNext(
                                    state.applicant3PresentHomePhone
                                  ) &&
                                  state.applicant3PresentReasonLeaving !== "" &&
                                  state.applicant3PresentPropertyType !== ""
                                ) {
                                  if (
                                    state.applicant3PresentPropertyType ===
                                    "Rent"
                                  ) {
                                    if (
                                      state.applicant3PresentLandlord !== "" &&
                                      state.applicant3PresentLandlordPhone !==
                                        "" &&
                                      checkMobileNext(
                                        state.applicant3PresentLandlordPhone
                                      ) &&
                                      state.applicant3PresentLandlordIDProof !==
                                        "" &&
                                      state.applicant3PresentMonthlyRent !== ""
                                    ) {
                                      if (state.applicant3PreviousInfo) {
                                        if (
                                          state.applicant3PreviousAddress !==
                                            "" &&
                                          state.applicant3PreviousState !==
                                            "" &&
                                          state.applicant3PreviousCity !== "" &&
                                          state.applicant3PreviousZip !== "" &&
                                          state.applicant3PreviousFrom !== "" &&
                                          state.applicant3PreviousTo !== "" &&
                                          state.applicant3PreviousHomePhone !==
                                            "" &&
                                          state.applicant3PreviousReasonLeaving !==
                                            "" &&
                                          state.applicant3PreviousPropertyType !==
                                            ""
                                        ) {
                                          if (
                                            state.applicant3PreviousPropertyType ===
                                            "Rent"
                                          ) {
                                            if (
                                              state.applicant3PreviousLandlord !==
                                                "" &&
                                              state.applicant3PreviousLandlordPhone !==
                                                "" &&
                                              state.applicant3PreviousMonthlyRent !==
                                                ""
                                            ) {
                                              x2 = true;
                                            }
                                          } else {
                                            x2 = true;
                                          }
                                        }
                                      } else {
                                        x2 = true;
                                      }
                                    }
                                  } else {
                                    x2 = true;
                                  }
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          }
                        } else {
                          if (state.applicant3) {
                            if (
                              state.applicant3PresentAddress !== "" &&
                              state.applicant3PresentState !== "" &&
                              state.applicant3PresentCity !== "" &&
                              state.applicant3PresentZip !== "" &&
                              state.applicant3PresentFrom !== "" &&
                              state.applicant3PresentTo !== "" &&
                              state.applicant3PresentHomePhone !== "" &&
                              checkMobileNext(
                                state.applicant3PresentHomePhone
                              ) &&
                              state.applicant3PresentReasonLeaving !== "" &&
                              state.applicant3PresentPropertyType !== ""
                            ) {
                              if (
                                state.applicant3PresentPropertyType === "Rent"
                              ) {
                                if (
                                  state.applicant3PresentLandlord !== "" &&
                                  state.applicant3PresentLandlordPhone !== "" &&
                                  checkMobileNext(
                                    state.applicant3PresentLandlordPhone
                                  ) &&
                                  state.applicant3PresentLandlordIDProof !==
                                    "" &&
                                  state.applicant3PresentMonthlyRent !== ""
                                ) {
                                  if (state.applicant3PreviousInfo) {
                                    if (
                                      state.applicant3PreviousAddress !== "" &&
                                      state.applicant3PreviousState !== "" &&
                                      state.applicant3PreviousCity !== "" &&
                                      state.applicant3PreviousZip !== "" &&
                                      state.applicant3PreviousFrom !== "" &&
                                      state.applicant3PreviousTo !== "" &&
                                      state.applicant3PreviousHomePhone !==
                                        "" &&
                                      state.applicant3PreviousReasonLeaving !==
                                        "" &&
                                      state.applicant3PreviousPropertyType !==
                                        ""
                                    ) {
                                      if (
                                        state.applicant3PreviousPropertyType ===
                                        "Rent"
                                      ) {
                                        if (
                                          state.applicant3PreviousLandlord !==
                                            "" &&
                                          state.applicant3PreviousLandlordPhone !==
                                            "" &&
                                          state.applicant3PreviousMonthlyRent !==
                                            ""
                                        ) {
                                          x2 = true;
                                        }
                                      } else {
                                        x2 = true;
                                      }
                                    }
                                  } else {
                                    x2 = true;
                                  }
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          } else {
                            x2 = true;
                          }
                        }
                      }
                    } else {
                      if (state.applicant3) {
                        if (
                          state.applicant3PresentAddress !== "" &&
                          state.applicant3PresentState !== "" &&
                          state.applicant3PresentCity !== "" &&
                          state.applicant3PresentZip !== "" &&
                          state.applicant3PresentFrom !== "" &&
                          state.applicant3PresentTo !== "" &&
                          state.applicant3PresentHomePhone !== "" &&
                          checkMobileNext(state.applicant3PresentHomePhone) &&
                          state.applicant3PresentReasonLeaving !== "" &&
                          state.applicant3PresentPropertyType !== ""
                        ) {
                          if (state.applicant3PresentPropertyType === "Rent") {
                            if (
                              state.applicant3PresentLandlord !== "" &&
                              state.applicant3PresentLandlordPhone !== "" &&
                              checkMobileNext(
                                state.applicant3PresentLandlordPhone
                              ) &&
                              state.applicant3PresentLandlordIDProof !== "" &&
                              state.applicant3PresentMonthlyRent !== ""
                            ) {
                              if (state.applicant3PreviousInfo) {
                                if (
                                  state.applicant3PreviousAddress !== "" &&
                                  state.applicant3PreviousState !== "" &&
                                  state.applicant3PreviousCity !== "" &&
                                  state.applicant3PreviousZip !== "" &&
                                  state.applicant3PreviousFrom !== "" &&
                                  state.applicant3PreviousTo !== "" &&
                                  state.applicant3PreviousHomePhone !== "" &&
                                  state.applicant3PreviousReasonLeaving !==
                                    "" &&
                                  state.applicant3PreviousPropertyType !== ""
                                ) {
                                  if (
                                    state.applicant3PreviousPropertyType ===
                                    "Rent"
                                  ) {
                                    if (
                                      state.applicant3PreviousLandlord !== "" &&
                                      state.applicant3PreviousLandlordPhone !==
                                        "" &&
                                      state.applicant3PreviousMonthlyRent !== ""
                                    ) {
                                      x2 = true;
                                    }
                                  } else {
                                    x2 = true;
                                  }
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          } else {
                            x2 = true;
                          }
                        }
                      } else {
                        x2 = true;
                      }
                    }
                  }
                } else {
                  if (state.applicant3) {
                    if (
                      state.applicant3PresentAddress !== "" &&
                      state.applicant3PresentState !== "" &&
                      state.applicant3PresentCity !== "" &&
                      state.applicant3PresentZip !== "" &&
                      state.applicant3PresentFrom !== "" &&
                      state.applicant3PresentTo !== "" &&
                      state.applicant3PresentHomePhone !== "" &&
                      checkMobileNext(state.applicant3PresentHomePhone) &&
                      state.applicant3PresentReasonLeaving !== "" &&
                      state.applicant3PresentPropertyType !== ""
                    ) {
                      if (state.applicant3PresentPropertyType === "Rent") {
                        if (
                          state.applicant3PresentLandlord !== "" &&
                          state.applicant3PresentLandlordPhone !== "" &&
                          checkMobileNext(
                            state.applicant3PresentLandlordPhone
                          ) &&
                          state.applicant3PresentLandlordIDProof !== "" &&
                          state.applicant3PresentMonthlyRent !== ""
                        ) {
                          if (state.applicant3PreviousInfo) {
                            if (
                              state.applicant3PreviousAddress !== "" &&
                              state.applicant3PreviousState !== "" &&
                              state.applicant3PreviousCity !== "" &&
                              state.applicant3PreviousZip !== "" &&
                              state.applicant3PreviousFrom !== "" &&
                              state.applicant3PreviousTo !== "" &&
                              state.applicant3PreviousHomePhone !== "" &&
                              state.applicant3PreviousReasonLeaving !== "" &&
                              state.applicant3PreviousPropertyType !== ""
                            ) {
                              if (
                                state.applicant3PreviousPropertyType === "Rent"
                              ) {
                                if (
                                  state.applicant3PreviousLandlord !== "" &&
                                  state.applicant3PreviousLandlordPhone !==
                                    "" &&
                                  state.applicant3PreviousMonthlyRent !== ""
                                ) {
                                  x2 = true;
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          } else {
                            x2 = true;
                          }
                        }
                      } else {
                        x2 = true;
                      }
                    }
                  } else {
                    x2 = true;
                  }
                }
              }
            } else {
              if (state.applicant2) {
                if (
                  state.applicant2PresentAddress !== "" &&
                  state.applicant2PresentState !== "" &&
                  state.applicant2PresentCity !== "" &&
                  state.applicant2PresentZip !== "" &&
                  state.applicant2PresentFrom !== "" &&
                  state.applicant2PresentTo !== "" &&
                  state.applicant2PresentHomePhone !== "" &&
                  checkMobileNext(state.applicant2PresentHomePhone) &&
                  state.applicant2PresentReasonLeaving !== "" &&
                  state.applicant2PresentPropertyType !== ""
                ) {
                  if (state.applicant2PresentPropertyType === "Rent") {
                    if (
                      state.applicant2PresentLandlord !== "" &&
                      state.applicant2PresentLandlordPhone !== "" &&
                      checkMobileNext(state.applicant2PresentLandlordPhone) &&
                      state.applicant2PresentLandlordIDProof !== "" &&
                      state.applicant2PresentMonthlyRent !== ""
                    ) {
                      if (state.applicant2PreviousInfo) {
                        if (
                          state.applicant2PreviousAddress !== "" &&
                          state.applicant2PreviousState !== "" &&
                          state.applicant2PreviousCity !== "" &&
                          state.applicant2PreviousZip !== "" &&
                          state.applicant2PreviousFrom !== "" &&
                          state.applicant2PreviousTo !== "" &&
                          state.applicant2PreviousHomePhone !== "" &&
                          state.applicant2PreviousReasonLeaving !== "" &&
                          state.applicant2PreviousPropertyType !== ""
                        ) {
                          if (state.applicant2PreviousPropertyType === "Rent") {
                            if (
                              state.applicant2PreviousLandlord !== "" &&
                              state.applicant2PreviousLandlordPhone !== "" &&
                              state.applicant2PreviousMonthlyRent !== ""
                            ) {
                              if (state.applicant3) {
                                if (
                                  state.applicant3PresentAddress !== "" &&
                                  state.applicant3PresentState !== "" &&
                                  state.applicant3PresentCity !== "" &&
                                  state.applicant3PresentZip !== "" &&
                                  state.applicant3PresentFrom !== "" &&
                                  state.applicant3PresentTo !== "" &&
                                  state.applicant3PresentHomePhone !== "" &&
                                  checkMobileNext(
                                    state.applicant3PresentHomePhone
                                  ) &&
                                  state.applicant3PresentReasonLeaving !== "" &&
                                  state.applicant3PresentPropertyType !== ""
                                ) {
                                  if (
                                    state.applicant3PresentPropertyType ===
                                    "Rent"
                                  ) {
                                    if (
                                      state.applicant3PresentLandlord !== "" &&
                                      state.applicant3PresentLandlordPhone !==
                                        "" &&
                                      checkMobileNext(
                                        state.applicant3PresentLandlordPhone
                                      ) &&
                                      state.applicant3PresentLandlordIDProof !==
                                        "" &&
                                      state.applicant3PresentMonthlyRent !== ""
                                    ) {
                                      if (state.applicant3PreviousInfo) {
                                        if (
                                          state.applicant3PreviousAddress !==
                                            "" &&
                                          state.applicant3PreviousState !==
                                            "" &&
                                          state.applicant3PreviousCity !== "" &&
                                          state.applicant3PreviousZip !== "" &&
                                          state.applicant3PreviousFrom !== "" &&
                                          state.applicant3PreviousTo !== "" &&
                                          state.applicant3PreviousHomePhone !==
                                            "" &&
                                          state.applicant3PreviousReasonLeaving !==
                                            "" &&
                                          state.applicant3PreviousPropertyType !==
                                            ""
                                        ) {
                                          if (
                                            state.applicant3PreviousPropertyType ===
                                            "Rent"
                                          ) {
                                            if (
                                              state.applicant3PreviousLandlord !==
                                                "" &&
                                              state.applicant3PreviousLandlordPhone !==
                                                "" &&
                                              state.applicant3PreviousMonthlyRent !==
                                                ""
                                            ) {
                                              x2 = true;
                                            }
                                          } else {
                                            x2 = true;
                                          }
                                        }
                                      } else {
                                        x2 = true;
                                      }
                                    }
                                  } else {
                                    x2 = true;
                                  }
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          } else {
                            if (state.applicant3) {
                              if (
                                state.applicant3PresentAddress !== "" &&
                                state.applicant3PresentState !== "" &&
                                state.applicant3PresentCity !== "" &&
                                state.applicant3PresentZip !== "" &&
                                state.applicant3PresentFrom !== "" &&
                                state.applicant3PresentTo !== "" &&
                                state.applicant3PresentHomePhone !== "" &&
                                checkMobileNext(
                                  state.applicant3PresentHomePhone
                                ) &&
                                state.applicant3PresentReasonLeaving !== "" &&
                                state.applicant3PresentPropertyType !== ""
                              ) {
                                if (
                                  state.applicant3PresentPropertyType === "Rent"
                                ) {
                                  if (
                                    state.applicant3PresentLandlord !== "" &&
                                    state.applicant3PresentLandlordPhone !==
                                      "" &&
                                    checkMobileNext(
                                      state.applicant3PresentLandlordPhone
                                    ) &&
                                    state.applicant3PresentLandlordIDProof !==
                                      "" &&
                                    state.applicant3PresentMonthlyRent !== ""
                                  ) {
                                    if (state.applicant3PreviousInfo) {
                                      if (
                                        state.applicant3PreviousAddress !==
                                          "" &&
                                        state.applicant3PreviousState !== "" &&
                                        state.applicant3PreviousCity !== "" &&
                                        state.applicant3PreviousZip !== "" &&
                                        state.applicant3PreviousFrom !== "" &&
                                        state.applicant3PreviousTo !== "" &&
                                        state.applicant3PreviousHomePhone !==
                                          "" &&
                                        state.applicant3PreviousReasonLeaving !==
                                          "" &&
                                        state.applicant3PreviousPropertyType !==
                                          ""
                                      ) {
                                        if (
                                          state.applicant3PreviousPropertyType ===
                                          "Rent"
                                        ) {
                                          if (
                                            state.applicant3PreviousLandlord !==
                                              "" &&
                                            state.applicant3PreviousLandlordPhone !==
                                              "" &&
                                            state.applicant3PreviousMonthlyRent !==
                                              ""
                                          ) {
                                            x2 = true;
                                          }
                                        } else {
                                          x2 = true;
                                        }
                                      }
                                    } else {
                                      x2 = true;
                                    }
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        }
                      } else {
                        if (state.applicant3) {
                          if (
                            state.applicant3PresentAddress !== "" &&
                            state.applicant3PresentState !== "" &&
                            state.applicant3PresentCity !== "" &&
                            state.applicant3PresentZip !== "" &&
                            state.applicant3PresentFrom !== "" &&
                            state.applicant3PresentTo !== "" &&
                            state.applicant3PresentHomePhone !== "" &&
                            checkMobileNext(state.applicant3PresentHomePhone) &&
                            state.applicant3PresentReasonLeaving !== "" &&
                            state.applicant3PresentPropertyType !== ""
                          ) {
                            if (
                              state.applicant3PresentPropertyType === "Rent"
                            ) {
                              if (
                                state.applicant3PresentLandlord !== "" &&
                                state.applicant3PresentLandlordPhone !== "" &&
                                checkMobileNext(
                                  state.applicant3PresentLandlordPhone
                                ) &&
                                state.applicant3PresentLandlordIDProof !== "" &&
                                state.applicant3PresentMonthlyRent !== ""
                              ) {
                                if (state.applicant3PreviousInfo) {
                                  if (
                                    state.applicant3PreviousAddress !== "" &&
                                    state.applicant3PreviousState !== "" &&
                                    state.applicant3PreviousCity !== "" &&
                                    state.applicant3PreviousZip !== "" &&
                                    state.applicant3PreviousFrom !== "" &&
                                    state.applicant3PreviousTo !== "" &&
                                    state.applicant3PreviousHomePhone !== "" &&
                                    state.applicant3PreviousReasonLeaving !==
                                      "" &&
                                    state.applicant3PreviousPropertyType !== ""
                                  ) {
                                    if (
                                      state.applicant3PreviousPropertyType ===
                                      "Rent"
                                    ) {
                                      if (
                                        state.applicant3PreviousLandlord !==
                                          "" &&
                                        state.applicant3PreviousLandlordPhone !==
                                          "" &&
                                        state.applicant3PreviousMonthlyRent !==
                                          ""
                                      ) {
                                        x2 = true;
                                      }
                                    } else {
                                      x2 = true;
                                    }
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    }
                  } else {
                    if (state.applicant3) {
                      if (
                        state.applicant3PresentAddress !== "" &&
                        state.applicant3PresentState !== "" &&
                        state.applicant3PresentCity !== "" &&
                        state.applicant3PresentZip !== "" &&
                        state.applicant3PresentFrom !== "" &&
                        state.applicant3PresentTo !== "" &&
                        state.applicant3PresentHomePhone !== "" &&
                        checkMobileNext(state.applicant3PresentHomePhone) &&
                        state.applicant3PresentReasonLeaving !== "" &&
                        state.applicant3PresentPropertyType !== ""
                      ) {
                        if (state.applicant3PresentPropertyType === "Rent") {
                          if (
                            state.applicant3PresentLandlord !== "" &&
                            state.applicant3PresentLandlordPhone !== "" &&
                            checkMobileNext(
                              state.applicant3PresentLandlordPhone
                            ) &&
                            state.applicant3PresentLandlordIDProof !== "" &&
                            state.applicant3PresentMonthlyRent !== ""
                          ) {
                            if (state.applicant3PreviousInfo) {
                              if (
                                state.applicant3PreviousAddress !== "" &&
                                state.applicant3PreviousState !== "" &&
                                state.applicant3PreviousCity !== "" &&
                                state.applicant3PreviousZip !== "" &&
                                state.applicant3PreviousFrom !== "" &&
                                state.applicant3PreviousTo !== "" &&
                                state.applicant3PreviousHomePhone !== "" &&
                                state.applicant3PreviousReasonLeaving !== "" &&
                                state.applicant3PreviousPropertyType !== ""
                              ) {
                                if (
                                  state.applicant3PreviousPropertyType ===
                                  "Rent"
                                ) {
                                  if (
                                    state.applicant3PreviousLandlord !== "" &&
                                    state.applicant3PreviousLandlordPhone !==
                                      "" &&
                                    state.applicant3PreviousMonthlyRent !== ""
                                  ) {
                                    x2 = true;
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                }
              } else {
                if (state.applicant3) {
                  if (
                    state.applicant3PresentAddress !== "" &&
                    state.applicant3PresentState !== "" &&
                    state.applicant3PresentCity !== "" &&
                    state.applicant3PresentZip !== "" &&
                    state.applicant3PresentFrom !== "" &&
                    state.applicant3PresentTo !== "" &&
                    state.applicant3PresentHomePhone !== "" &&
                    checkMobileNext(state.applicant3PresentHomePhone) &&
                    state.applicant3PresentReasonLeaving !== "" &&
                    state.applicant3PresentPropertyType !== ""
                  ) {
                    if (state.applicant3PresentPropertyType === "Rent") {
                      if (
                        state.applicant3PresentLandlord !== "" &&
                        state.applicant3PresentLandlordPhone !== "" &&
                        checkMobileNext(state.applicant3PresentLandlordPhone) &&
                        state.applicant3PresentLandlordIDProof !== "" &&
                        state.applicant3PresentMonthlyRent !== ""
                      ) {
                        if (state.applicant3PreviousInfo) {
                          if (
                            state.applicant3PreviousAddress !== "" &&
                            state.applicant3PreviousState !== "" &&
                            state.applicant3PreviousCity !== "" &&
                            state.applicant3PreviousZip !== "" &&
                            state.applicant3PreviousFrom !== "" &&
                            state.applicant3PreviousTo !== "" &&
                            state.applicant3PreviousHomePhone !== "" &&
                            state.applicant3PreviousReasonLeaving !== "" &&
                            state.applicant3PreviousPropertyType !== ""
                          ) {
                            if (
                              state.applicant3PreviousPropertyType === "Rent"
                            ) {
                              if (
                                state.applicant3PreviousLandlord !== "" &&
                                state.applicant3PreviousLandlordPhone !== "" &&
                                state.applicant3PreviousMonthlyRent !== ""
                              ) {
                                x2 = true;
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                } else {
                  x2 = true;
                }
              }
            }
          }
        } else {
          if (state.applicant2) {
            if (
              state.applicant2PresentAddress !== "" &&
              state.applicant2PresentState !== "" &&
              state.applicant2PresentCity !== "" &&
              state.applicant2PresentZip !== "" &&
              state.applicant2PresentFrom !== "" &&
              state.applicant2PresentTo !== "" &&
              state.applicant2PresentHomePhone !== "" &&
              checkMobileNext(state.applicant2PresentHomePhone) &&
              state.applicant2PresentReasonLeaving !== "" &&
              state.applicant2PresentPropertyType !== ""
            ) {
              if (state.applicant2PresentPropertyType === "Rent") {
                if (
                  state.applicant2PresentLandlord !== "" &&
                  state.applicant2PresentLandlordPhone !== "" &&
                  checkMobileNext(state.applicant2PresentLandlordPhone) &&
                  state.applicant2PresentLandlordIDProof !== "" &&
                  state.applicant2PresentMonthlyRent !== ""
                ) {
                  if (state.applicant2PreviousInfo) {
                    if (
                      state.applicant2PreviousAddress !== "" &&
                      state.applicant2PreviousState !== "" &&
                      state.applicant2PreviousCity !== "" &&
                      state.applicant2PreviousZip !== "" &&
                      state.applicant2PreviousFrom !== "" &&
                      state.applicant2PreviousTo !== "" &&
                      state.applicant2PreviousHomePhone !== "" &&
                      state.applicant2PreviousReasonLeaving !== "" &&
                      state.applicant2PreviousPropertyType !== ""
                    ) {
                      if (state.applicant2PreviousPropertyType === "Rent") {
                        if (
                          state.applicant2PreviousLandlord !== "" &&
                          state.applicant2PreviousLandlordPhone !== "" &&
                          state.applicant2PreviousMonthlyRent !== ""
                        ) {
                          if (state.applicant3) {
                            if (
                              state.applicant3PresentAddress !== "" &&
                              state.applicant3PresentState !== "" &&
                              state.applicant3PresentCity !== "" &&
                              state.applicant3PresentZip !== "" &&
                              state.applicant3PresentFrom !== "" &&
                              state.applicant3PresentTo !== "" &&
                              state.applicant3PresentHomePhone !== "" &&
                              checkMobileNext(
                                state.applicant3PresentHomePhone
                              ) &&
                              state.applicant3PresentReasonLeaving !== "" &&
                              state.applicant3PresentPropertyType !== ""
                            ) {
                              if (
                                state.applicant3PresentPropertyType === "Rent"
                              ) {
                                if (
                                  state.applicant3PresentLandlord !== "" &&
                                  state.applicant3PresentLandlordPhone !== "" &&
                                  checkMobileNext(
                                    state.applicant3PresentLandlordPhone
                                  ) &&
                                  state.applicant3PresentLandlordIDProof !==
                                    "" &&
                                  state.applicant3PresentMonthlyRent !== ""
                                ) {
                                  if (state.applicant3PreviousInfo) {
                                    if (
                                      state.applicant3PreviousAddress !== "" &&
                                      state.applicant3PreviousState !== "" &&
                                      state.applicant3PreviousCity !== "" &&
                                      state.applicant3PreviousZip !== "" &&
                                      state.applicant3PreviousFrom !== "" &&
                                      state.applicant3PreviousTo !== "" &&
                                      state.applicant3PreviousHomePhone !==
                                        "" &&
                                      state.applicant3PreviousReasonLeaving !==
                                        "" &&
                                      state.applicant3PreviousPropertyType !==
                                        ""
                                    ) {
                                      if (
                                        state.applicant3PreviousPropertyType ===
                                        "Rent"
                                      ) {
                                        if (
                                          state.applicant3PreviousLandlord !==
                                            "" &&
                                          state.applicant3PreviousLandlordPhone !==
                                            "" &&
                                          state.applicant3PreviousMonthlyRent !==
                                            ""
                                        ) {
                                          x2 = true;
                                        }
                                      } else {
                                        x2 = true;
                                      }
                                    }
                                  } else {
                                    x2 = true;
                                  }
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          } else {
                            x2 = true;
                          }
                        }
                      } else {
                        if (state.applicant3) {
                          if (
                            state.applicant3PresentAddress !== "" &&
                            state.applicant3PresentState !== "" &&
                            state.applicant3PresentCity !== "" &&
                            state.applicant3PresentZip !== "" &&
                            state.applicant3PresentFrom !== "" &&
                            state.applicant3PresentTo !== "" &&
                            state.applicant3PresentHomePhone !== "" &&
                            checkMobileNext(state.applicant3PresentHomePhone) &&
                            state.applicant3PresentReasonLeaving !== "" &&
                            state.applicant3PresentPropertyType !== ""
                          ) {
                            if (
                              state.applicant3PresentPropertyType === "Rent"
                            ) {
                              if (
                                state.applicant3PresentLandlord !== "" &&
                                state.applicant3PresentLandlordPhone !== "" &&
                                checkMobileNext(
                                  state.applicant3PresentLandlordPhone
                                ) &&
                                state.applicant3PresentLandlordIDProof !== "" &&
                                state.applicant3PresentMonthlyRent !== ""
                              ) {
                                if (state.applicant3PreviousInfo) {
                                  if (
                                    state.applicant3PreviousAddress !== "" &&
                                    state.applicant3PreviousState !== "" &&
                                    state.applicant3PreviousCity !== "" &&
                                    state.applicant3PreviousZip !== "" &&
                                    state.applicant3PreviousFrom !== "" &&
                                    state.applicant3PreviousTo !== "" &&
                                    state.applicant3PreviousHomePhone !== "" &&
                                    state.applicant3PreviousReasonLeaving !==
                                      "" &&
                                    state.applicant3PreviousPropertyType !== ""
                                  ) {
                                    if (
                                      state.applicant3PreviousPropertyType ===
                                      "Rent"
                                    ) {
                                      if (
                                        state.applicant3PreviousLandlord !==
                                          "" &&
                                        state.applicant3PreviousLandlordPhone !==
                                          "" &&
                                        state.applicant3PreviousMonthlyRent !==
                                          ""
                                      ) {
                                        x2 = true;
                                      }
                                    } else {
                                      x2 = true;
                                    }
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    }
                  } else {
                    if (state.applicant3) {
                      if (
                        state.applicant3PresentAddress !== "" &&
                        state.applicant3PresentState !== "" &&
                        state.applicant3PresentCity !== "" &&
                        state.applicant3PresentZip !== "" &&
                        state.applicant3PresentFrom !== "" &&
                        state.applicant3PresentTo !== "" &&
                        state.applicant3PresentHomePhone !== "" &&
                        checkMobileNext(state.applicant3PresentHomePhone) &&
                        state.applicant3PresentReasonLeaving !== "" &&
                        state.applicant3PresentPropertyType !== ""
                      ) {
                        if (state.applicant3PresentPropertyType === "Rent") {
                          if (
                            state.applicant3PresentLandlord !== "" &&
                            state.applicant3PresentLandlordPhone !== "" &&
                            checkMobileNext(
                              state.applicant3PresentLandlordPhone
                            ) &&
                            state.applicant3PresentLandlordIDProof !== "" &&
                            state.applicant3PresentMonthlyRent !== ""
                          ) {
                            if (state.applicant3PreviousInfo) {
                              if (
                                state.applicant3PreviousAddress !== "" &&
                                state.applicant3PreviousState !== "" &&
                                state.applicant3PreviousCity !== "" &&
                                state.applicant3PreviousZip !== "" &&
                                state.applicant3PreviousFrom !== "" &&
                                state.applicant3PreviousTo !== "" &&
                                state.applicant3PreviousHomePhone !== "" &&
                                state.applicant3PreviousReasonLeaving !== "" &&
                                state.applicant3PreviousPropertyType !== ""
                              ) {
                                if (
                                  state.applicant3PreviousPropertyType ===
                                  "Rent"
                                ) {
                                  if (
                                    state.applicant3PreviousLandlord !== "" &&
                                    state.applicant3PreviousLandlordPhone !==
                                      "" &&
                                    state.applicant3PreviousMonthlyRent !== ""
                                  ) {
                                    x2 = true;
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                }
              } else {
                if (state.applicant3) {
                  if (
                    state.applicant3PresentAddress !== "" &&
                    state.applicant3PresentState !== "" &&
                    state.applicant3PresentCity !== "" &&
                    state.applicant3PresentZip !== "" &&
                    state.applicant3PresentFrom !== "" &&
                    state.applicant3PresentTo !== "" &&
                    state.applicant3PresentHomePhone !== "" &&
                    checkMobileNext(state.applicant3PresentHomePhone) &&
                    state.applicant3PresentReasonLeaving !== "" &&
                    state.applicant3PresentPropertyType !== ""
                  ) {
                    if (state.applicant3PresentPropertyType === "Rent") {
                      if (
                        state.applicant3PresentLandlord !== "" &&
                        state.applicant3PresentLandlordPhone !== "" &&
                        checkMobileNext(state.applicant3PresentLandlordPhone) &&
                        state.applicant3PresentLandlordIDProof !== "" &&
                        state.applicant3PresentMonthlyRent !== ""
                      ) {
                        if (state.applicant3PreviousInfo) {
                          if (
                            state.applicant3PreviousAddress !== "" &&
                            state.applicant3PreviousState !== "" &&
                            state.applicant3PreviousCity !== "" &&
                            state.applicant3PreviousZip !== "" &&
                            state.applicant3PreviousFrom !== "" &&
                            state.applicant3PreviousTo !== "" &&
                            state.applicant3PreviousHomePhone !== "" &&
                            state.applicant3PreviousReasonLeaving !== "" &&
                            state.applicant3PreviousPropertyType !== ""
                          ) {
                            if (
                              state.applicant3PreviousPropertyType === "Rent"
                            ) {
                              if (
                                state.applicant3PreviousLandlord !== "" &&
                                state.applicant3PreviousLandlordPhone !== "" &&
                                state.applicant3PreviousMonthlyRent !== ""
                              ) {
                                x2 = true;
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                } else {
                  x2 = true;
                }
              }
            }
          } else {
            if (state.applicant3) {
              if (
                state.applicant3PresentAddress !== "" &&
                state.applicant3PresentState !== "" &&
                state.applicant3PresentCity !== "" &&
                state.applicant3PresentZip !== "" &&
                state.applicant3PresentFrom !== "" &&
                state.applicant3PresentTo !== "" &&
                state.applicant3PresentHomePhone !== "" &&
                checkMobileNext(state.applicant3PresentHomePhone) &&
                state.applicant3PresentReasonLeaving !== "" &&
                state.applicant3PresentPropertyType !== ""
              ) {
                if (state.applicant3PresentPropertyType === "Rent") {
                  if (
                    state.applicant3PresentLandlord !== "" &&
                    state.applicant3PresentLandlordPhone !== "" &&
                    checkMobileNext(state.applicant3PresentLandlordPhone) &&
                    state.applicant3PresentLandlordIDProof !== "" &&
                    state.applicant3PresentMonthlyRent !== ""
                  ) {
                    if (state.applicant3PreviousInfo) {
                      if (
                        state.applicant3PreviousAddress !== "" &&
                        state.applicant3PreviousState !== "" &&
                        state.applicant3PreviousCity !== "" &&
                        state.applicant3PreviousZip !== "" &&
                        state.applicant3PreviousFrom !== "" &&
                        state.applicant3PreviousTo !== "" &&
                        state.applicant3PreviousHomePhone !== "" &&
                        state.applicant3PreviousReasonLeaving !== "" &&
                        state.applicant3PreviousPropertyType !== ""
                      ) {
                        if (state.applicant3PreviousPropertyType === "Rent") {
                          if (
                            state.applicant3PreviousLandlord !== "" &&
                            state.applicant3PreviousLandlordPhone !== "" &&
                            state.applicant3PreviousMonthlyRent !== ""
                          ) {
                            x2 = true;
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                } else {
                  x2 = true;
                }
              }
            } else {
              x2 = true;
            }
          }
        }
      }
    } else {
      if (state.applicant2) {
        if (
          state.applicant2PresentAddress !== "" &&
          state.applicant2PresentState !== "" &&
          state.applicant2PresentCity !== "" &&
          state.applicant2PresentZip !== "" &&
          state.applicant2PresentFrom !== "" &&
          state.applicant2PresentTo !== "" &&
          state.applicant2PresentHomePhone !== "" &&
          checkMobileNext(state.applicant2PresentHomePhone) &&
          state.applicant2PresentReasonLeaving !== "" &&
          state.applicant2PresentPropertyType !== ""
        ) {
          if (state.applicant2PresentPropertyType === "Rent") {
            if (
              state.applicant2PresentLandlord !== "" &&
              state.applicant2PresentLandlordPhone !== "" &&
              checkMobileNext(state.applicant2PresentLandlordPhone) &&
              state.applicant2PresentLandlordIDProof !== "" &&
              state.applicant2PresentMonthlyRent !== ""
            ) {
              if (state.applicant2PreviousInfo) {
                if (
                  state.applicant2PreviousAddress !== "" &&
                  state.applicant2PreviousState !== "" &&
                  state.applicant2PreviousCity !== "" &&
                  state.applicant2PreviousZip !== "" &&
                  state.applicant2PreviousFrom !== "" &&
                  state.applicant2PreviousTo !== "" &&
                  state.applicant2PreviousHomePhone !== "" &&
                  state.applicant2PreviousReasonLeaving !== "" &&
                  state.applicant2PreviousPropertyType !== ""
                ) {
                  if (state.applicant2PreviousPropertyType === "Rent") {
                    if (
                      state.applicant2PreviousLandlord !== "" &&
                      state.applicant2PreviousLandlordPhone !== "" &&
                      state.applicant2PreviousMonthlyRent !== ""
                    ) {
                      if (state.applicant3) {
                        if (
                          state.applicant3PresentAddress !== "" &&
                          state.applicant3PresentState !== "" &&
                          state.applicant3PresentCity !== "" &&
                          state.applicant3PresentZip !== "" &&
                          state.applicant3PresentFrom !== "" &&
                          state.applicant3PresentTo !== "" &&
                          state.applicant3PresentHomePhone !== "" &&
                          checkMobileNext(state.applicant3PresentHomePhone) &&
                          state.applicant3PresentReasonLeaving !== "" &&
                          state.applicant3PresentPropertyType !== ""
                        ) {
                          if (state.applicant3PresentPropertyType === "Rent") {
                            if (
                              state.applicant3PresentLandlord !== "" &&
                              state.applicant3PresentLandlordPhone !== "" &&
                              checkMobileNext(
                                state.applicant3PresentLandlordPhone
                              ) &&
                              state.applicant3PresentLandlordIDProof !== "" &&
                              state.applicant3PresentMonthlyRent !== ""
                            ) {
                              if (state.applicant3PreviousInfo) {
                                if (
                                  state.applicant3PreviousAddress !== "" &&
                                  state.applicant3PreviousState !== "" &&
                                  state.applicant3PreviousCity !== "" &&
                                  state.applicant3PreviousZip !== "" &&
                                  state.applicant3PreviousFrom !== "" &&
                                  state.applicant3PreviousTo !== "" &&
                                  state.applicant3PreviousHomePhone !== "" &&
                                  state.applicant3PreviousReasonLeaving !==
                                    "" &&
                                  state.applicant3PreviousPropertyType !== ""
                                ) {
                                  if (
                                    state.applicant3PreviousPropertyType ===
                                    "Rent"
                                  ) {
                                    if (
                                      state.applicant3PreviousLandlord !== "" &&
                                      state.applicant3PreviousLandlordPhone !==
                                        "" &&
                                      state.applicant3PreviousMonthlyRent !== ""
                                    ) {
                                      x2 = true;
                                    }
                                  } else {
                                    x2 = true;
                                  }
                                }
                              } else {
                                x2 = true;
                              }
                            }
                          } else {
                            x2 = true;
                          }
                        }
                      } else {
                        x2 = true;
                      }
                    }
                  } else {
                    if (state.applicant3) {
                      if (
                        state.applicant3PresentAddress !== "" &&
                        state.applicant3PresentState !== "" &&
                        state.applicant3PresentCity !== "" &&
                        state.applicant3PresentZip !== "" &&
                        state.applicant3PresentFrom !== "" &&
                        state.applicant3PresentTo !== "" &&
                        state.applicant3PresentHomePhone !== "" &&
                        checkMobileNext(state.applicant3PresentHomePhone) &&
                        state.applicant3PresentReasonLeaving !== "" &&
                        state.applicant3PresentPropertyType !== ""
                      ) {
                        if (state.applicant3PresentPropertyType === "Rent") {
                          if (
                            state.applicant3PresentLandlord !== "" &&
                            state.applicant3PresentLandlordPhone !== "" &&
                            checkMobileNext(
                              state.applicant3PresentLandlordPhone
                            ) &&
                            state.applicant3PresentLandlordIDProof !== "" &&
                            state.applicant3PresentMonthlyRent !== ""
                          ) {
                            if (state.applicant3PreviousInfo) {
                              if (
                                state.applicant3PreviousAddress !== "" &&
                                state.applicant3PreviousState !== "" &&
                                state.applicant3PreviousCity !== "" &&
                                state.applicant3PreviousZip !== "" &&
                                state.applicant3PreviousFrom !== "" &&
                                state.applicant3PreviousTo !== "" &&
                                state.applicant3PreviousHomePhone !== "" &&
                                state.applicant3PreviousReasonLeaving !== "" &&
                                state.applicant3PreviousPropertyType !== ""
                              ) {
                                if (
                                  state.applicant3PreviousPropertyType ===
                                  "Rent"
                                ) {
                                  if (
                                    state.applicant3PreviousLandlord !== "" &&
                                    state.applicant3PreviousLandlordPhone !==
                                      "" &&
                                    state.applicant3PreviousMonthlyRent !== ""
                                  ) {
                                    x2 = true;
                                  }
                                } else {
                                  x2 = true;
                                }
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                }
              } else {
                if (state.applicant3) {
                  if (
                    state.applicant3PresentAddress !== "" &&
                    state.applicant3PresentState !== "" &&
                    state.applicant3PresentCity !== "" &&
                    state.applicant3PresentZip !== "" &&
                    state.applicant3PresentFrom !== "" &&
                    state.applicant3PresentTo !== "" &&
                    state.applicant3PresentHomePhone !== "" &&
                    checkMobileNext(state.applicant3PresentHomePhone) &&
                    state.applicant3PresentReasonLeaving !== "" &&
                    state.applicant3PresentPropertyType !== ""
                  ) {
                    if (state.applicant3PresentPropertyType === "Rent") {
                      if (
                        state.applicant3PresentLandlord !== "" &&
                        state.applicant3PresentLandlordPhone !== "" &&
                        checkMobileNext(state.applicant3PresentLandlordPhone) &&
                        state.applicant3PresentLandlordIDProof !== "" &&
                        state.applicant3PresentMonthlyRent !== ""
                      ) {
                        if (state.applicant3PreviousInfo) {
                          if (
                            state.applicant3PreviousAddress !== "" &&
                            state.applicant3PreviousState !== "" &&
                            state.applicant3PreviousCity !== "" &&
                            state.applicant3PreviousZip !== "" &&
                            state.applicant3PreviousFrom !== "" &&
                            state.applicant3PreviousTo !== "" &&
                            state.applicant3PreviousHomePhone !== "" &&
                            state.applicant3PreviousReasonLeaving !== "" &&
                            state.applicant3PreviousPropertyType !== ""
                          ) {
                            if (
                              state.applicant3PreviousPropertyType === "Rent"
                            ) {
                              if (
                                state.applicant3PreviousLandlord !== "" &&
                                state.applicant3PreviousLandlordPhone !== "" &&
                                state.applicant3PreviousMonthlyRent !== ""
                              ) {
                                x2 = true;
                              }
                            } else {
                              x2 = true;
                            }
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                } else {
                  x2 = true;
                }
              }
            }
          } else {
            if (state.applicant3) {
              if (
                state.applicant3PresentAddress !== "" &&
                state.applicant3PresentState !== "" &&
                state.applicant3PresentCity !== "" &&
                state.applicant3PresentZip !== "" &&
                state.applicant3PresentFrom !== "" &&
                state.applicant3PresentTo !== "" &&
                state.applicant3PresentHomePhone !== "" &&
                checkMobileNext(state.applicant3PresentHomePhone) &&
                state.applicant3PresentReasonLeaving !== "" &&
                state.applicant3PresentPropertyType !== ""
              ) {
                if (state.applicant3PresentPropertyType === "Rent") {
                  if (
                    state.applicant3PresentLandlord !== "" &&
                    state.applicant3PresentLandlordPhone !== "" &&
                    checkMobileNext(state.applicant3PresentLandlordPhone) &&
                    state.applicant3PresentLandlordIDProof !== "" &&
                    state.applicant3PresentMonthlyRent !== ""
                  ) {
                    if (state.applicant3PreviousInfo) {
                      if (
                        state.applicant3PreviousAddress !== "" &&
                        state.applicant3PreviousState !== "" &&
                        state.applicant3PreviousCity !== "" &&
                        state.applicant3PreviousZip !== "" &&
                        state.applicant3PreviousFrom !== "" &&
                        state.applicant3PreviousTo !== "" &&
                        state.applicant3PreviousHomePhone !== "" &&
                        state.applicant3PreviousReasonLeaving !== "" &&
                        state.applicant3PreviousPropertyType !== ""
                      ) {
                        if (state.applicant3PreviousPropertyType === "Rent") {
                          if (
                            state.applicant3PreviousLandlord !== "" &&
                            state.applicant3PreviousLandlordPhone !== "" &&
                            state.applicant3PreviousMonthlyRent !== ""
                          ) {
                            x2 = true;
                          }
                        } else {
                          x2 = true;
                        }
                      }
                    } else {
                      x2 = true;
                    }
                  }
                } else {
                  x2 = true;
                }
              }
            } else {
              x2 = true;
            }
          }
        }
      } else {
        if (state.applicant3) {
          if (
            state.applicant3PresentAddress !== "" &&
            state.applicant3PresentState !== "" &&
            state.applicant3PresentCity !== "" &&
            state.applicant3PresentZip !== "" &&
            state.applicant3PresentFrom !== "" &&
            state.applicant3PresentTo !== "" &&
            state.applicant3PresentHomePhone !== "" &&
            checkMobileNext(state.applicant3PresentHomePhone) &&
            state.applicant3PresentReasonLeaving !== "" &&
            state.applicant3PresentPropertyType !== ""
          ) {
            if (state.applicant3PresentPropertyType === "Rent") {
              if (
                state.applicant3PresentLandlord !== "" &&
                state.applicant3PresentLandlordPhone !== "" &&
                checkMobileNext(state.applicant3PresentLandlordPhone) &&
                state.applicant3PresentLandlordIDProof !== "" &&
                state.applicant3PresentMonthlyRent !== ""
              ) {
                if (state.applicant3PreviousInfo) {
                  if (
                    state.applicant3PreviousAddress !== "" &&
                    state.applicant3PreviousState !== "" &&
                    state.applicant3PreviousCity !== "" &&
                    state.applicant3PreviousZip !== "" &&
                    state.applicant3PreviousFrom !== "" &&
                    state.applicant3PreviousTo !== "" &&
                    state.applicant3PreviousHomePhone !== "" &&
                    state.applicant3PreviousReasonLeaving !== "" &&
                    state.applicant3PreviousPropertyType !== ""
                  ) {
                    if (state.applicant3PreviousPropertyType === "Rent") {
                      if (
                        state.applicant3PreviousLandlord !== "" &&
                        state.applicant3PreviousLandlordPhone !== "" &&
                        state.applicant3PreviousMonthlyRent !== ""
                      ) {
                        x2 = true;
                      }
                    } else {
                      x2 = true;
                    }
                  }
                } else {
                  x2 = true;
                }
              }
            } else {
              x2 = true;
            }
          }
        } else {
          x2 = true;
        }
      }
    }
  }
  return x2;
};

export const canadaStep3Verification = (state) => {
  let x3 = false;
  if (
    state.employmentCompany !== "" &&
    state.employmentPosition !== "" &&
    state.employmentFrom !== "" &&
    state.employmentUntil !== "" &&
    state.employmentAnnualIncome !== "" &&
    state.employmentZip !== "" &&
    state.employmentAddress !== "" &&
    state.employmentCity !== "" &&
    state.employmentState !== "" &&
    state.employmentPhone !== "" &&
    checkMobileNext(state.employmentPhone)
    // state.spousesEmployer !== "" &&
    // state.spousesEmployerAddress !== "" &&
    // state.spousesIncome !== ""
  ) {
    if (state.employment1) {
      if (
        state.preEmploymentCompany !== "" &&
        state.preEmploymentZip !== "" &&
        state.preEmploymentAddress !== "" &&
        state.preEmploymentCity !== "" &&
        state.preEmploymentState !== "" &&
        state.preEmploymentPosition !== "" &&
        state.preEmploymentFrom !== "" &&
        state.preEmploymentUntil !== ""
      ) {
        if (state.employment2) {
          if (
            state.preEmployment2Company !== "" &&
            state.preEmployment2Zip !== "" &&
            state.preEmployment2Address !== "" &&
            state.preEmployment2City !== "" &&
            state.preEmployment2State !== "" &&
            state.preEmployment2Position !== "" &&
            state.preEmployment2From !== "" &&
            state.preEmployment2Until !== ""
          ) {
            if (state.applicant2) {
              if (
                state.applicant2EmploymentCompany !== "" &&
                state.applicant2EmploymentPosition !== "" &&
                state.applicant2EmploymentFrom !== "" &&
                state.applicant2EmploymentUntil !== "" &&
                state.applicant2EmploymentAnnualIncome !== "" &&
                state.applicant2EmploymentZip !== "" &&
                state.applicant2EmploymentAddress !== "" &&
                state.applicant2EmploymentCity !== "" &&
                state.applicant2EmploymentState !== "" &&
                state.applicant2EmploymentPhone !== "" &&
                checkMobileNext(state.applicant2EmploymentPhone)
                // state.applicant2SpousesEmployer !== "" &&
                // state.applicant2SpousesEmployerAddress !== "" &&
                // state.applicant2SpousesIncome !== ""
              ) {
                if (state.applicant2Employment1) {
                  if (
                    state.applicant2PreEmploymentCompany !== "" &&
                    state.applicant2PreEmploymentZip !== "" &&
                    state.applicant2PreEmploymentAddress !== "" &&
                    state.applicant2PreEmploymentCity !== "" &&
                    state.applicant2PreEmploymentState !== "" &&
                    state.applicant2PreEmploymentPosition !== "" &&
                    state.applicant2PreEmploymentFrom !== "" &&
                    state.applicant2PreEmploymentUntil !== ""
                  ) {
                    if (state.applicant2Employment2) {
                      if (
                        state.applicant2PreEmployment2Company !== "" &&
                        state.applicant2PreEmployment2Zip !== "" &&
                        state.applicant2PreEmployment2Address !== "" &&
                        state.applicant2PreEmployment2City !== "" &&
                        state.applicant2PreEmployment2State !== "" &&
                        state.applicant2PreEmployment2Position !== "" &&
                        state.applicant2PreEmployment2From !== "" &&
                        state.applicant2PreEmployment2Until !== ""
                      ) {
                        if (state.applicant3) {
                          if (
                            state.applicant3EmploymentCompany !== "" &&
                            state.applicant3EmploymentPosition !== "" &&
                            state.applicant3EmploymentFrom !== "" &&
                            state.applicant3EmploymentUntil !== "" &&
                            state.applicant3EmploymentAnnualIncome !== "" &&
                            state.applicant3EmploymentZip !== "" &&
                            state.applicant3EmploymentAddress !== "" &&
                            state.applicant3EmploymentCity !== "" &&
                            state.applicant3EmploymentState !== "" &&
                            state.applicant3EmploymentPhone !== "" &&
                            checkMobileNext(state.applicant3EmploymentPhone)
                            // state.applicant3SpousesEmployer !== "" &&
                            // state.applicant3SpousesEmployerAddress !== "" &&
                            // state.applicant3SpousesIncome !== ""
                          ) {
                            if (state.applicant3Employment1) {
                              if (
                                state.applicant3PreEmploymentCompany !== "" &&
                                state.applicant3PreEmploymentZip !== "" &&
                                state.applicant3PreEmploymentAddress !== "" &&
                                state.applicant3PreEmploymentCity !== "" &&
                                state.applicant3PreEmploymentState !== "" &&
                                state.applicant3PreEmploymentPosition !== "" &&
                                state.applicant3PreEmploymentFrom !== "" &&
                                state.applicant3PreEmploymentUntil !== ""
                              ) {
                                if (state.applicant3Employment2) {
                                  if (
                                    state.applicant3PreEmployment2Company !==
                                      "" &&
                                    state.applicant3PreEmployment2Zip !== "" &&
                                    state.applicant3PreEmployment2Address !==
                                      "" &&
                                    state.applicant3PreEmployment2City !== "" &&
                                    state.applicant3PreEmployment2State !==
                                      "" &&
                                    state.applicant3PreEmployment2Position !==
                                      "" &&
                                    state.applicant3PreEmployment2From !== "" &&
                                    state.applicant3PreEmployment2Until !== ""
                                  ) {
                                    x3 = true;
                                  }
                                } else {
                                  x3 = true;
                                }
                              }
                            } else {
                              x3 = true;
                            }
                          }
                        } else {
                          x3 = true;
                        }
                      }
                    } else {
                      if (state.applicant3) {
                        if (
                          state.applicant3EmploymentCompany !== "" &&
                          state.applicant3EmploymentPosition !== "" &&
                          state.applicant3EmploymentFrom !== "" &&
                          state.applicant3EmploymentUntil !== "" &&
                          state.applicant3EmploymentAnnualIncome !== "" &&
                          state.applicant3EmploymentZip !== "" &&
                          state.applicant3EmploymentAddress !== "" &&
                          state.applicant3EmploymentCity !== "" &&
                          state.applicant3EmploymentState !== "" &&
                          state.applicant3EmploymentPhone !== "" &&
                          checkMobileNext(state.applicant3EmploymentPhone)
                          // state.applicant3SpousesEmployer !== "" &&
                          // state.applicant3SpousesEmployerAddress !== "" &&
                          // state.applicant3SpousesIncome !== ""
                        ) {
                          if (state.applicant3Employment1) {
                            if (
                              state.applicant3PreEmploymentCompany !== "" &&
                              state.applicant3PreEmploymentZip !== "" &&
                              state.applicant3PreEmploymentAddress !== "" &&
                              state.applicant3PreEmploymentCity !== "" &&
                              state.applicant3PreEmploymentState !== "" &&
                              state.applicant3PreEmploymentPosition !== "" &&
                              state.applicant3PreEmploymentFrom !== "" &&
                              state.applicant3PreEmploymentUntil !== ""
                            ) {
                              if (state.applicant3Employment2) {
                                if (
                                  state.applicant3PreEmployment2Company !==
                                    "" &&
                                  state.applicant3PreEmployment2Zip !== "" &&
                                  state.applicant3PreEmployment2Address !==
                                    "" &&
                                  state.applicant3PreEmployment2City !== "" &&
                                  state.applicant3PreEmployment2State !== "" &&
                                  state.applicant3PreEmployment2Position !==
                                    "" &&
                                  state.applicant3PreEmployment2From !== "" &&
                                  state.applicant3PreEmployment2Until !== ""
                                ) {
                                  x3 = true;
                                }
                              } else {
                                x3 = true;
                              }
                            }
                          } else {
                            x3 = true;
                          }
                        }
                      } else {
                        x3 = true;
                      }
                    }
                  }
                } else {
                  if (state.applicant3) {
                    if (
                      state.applicant3EmploymentCompany !== "" &&
                      state.applicant3EmploymentPosition !== "" &&
                      state.applicant3EmploymentFrom !== "" &&
                      state.applicant3EmploymentUntil !== "" &&
                      state.applicant3EmploymentAnnualIncome !== "" &&
                      state.applicant3EmploymentZip !== "" &&
                      state.applicant3EmploymentAddress !== "" &&
                      state.applicant3EmploymentCity !== "" &&
                      state.applicant3EmploymentState !== "" &&
                      state.applicant3EmploymentPhone !== "" &&
                      checkMobileNext(state.applicant3EmploymentPhone)
                      // state.applicant3SpousesEmployer !== "" &&
                      // state.applicant3SpousesEmployerAddress !== "" &&
                      // state.applicant3SpousesIncome !== ""
                    ) {
                      if (state.applicant3Employment1) {
                        if (
                          state.applicant3PreEmploymentCompany !== "" &&
                          state.applicant3PreEmploymentZip !== "" &&
                          state.applicant3PreEmploymentAddress !== "" &&
                          state.applicant3PreEmploymentCity !== "" &&
                          state.applicant3PreEmploymentState !== "" &&
                          state.applicant3PreEmploymentPosition !== "" &&
                          state.applicant3PreEmploymentFrom !== "" &&
                          state.applicant3PreEmploymentUntil !== ""
                        ) {
                          if (state.applicant3Employment2) {
                            if (
                              state.applicant3PreEmployment2Company !== "" &&
                              state.applicant3PreEmployment2Zip !== "" &&
                              state.applicant3PreEmployment2Address !== "" &&
                              state.applicant3PreEmployment2City !== "" &&
                              state.applicant3PreEmployment2State !== "" &&
                              state.applicant3PreEmployment2Position !== "" &&
                              state.applicant3PreEmployment2From !== "" &&
                              state.applicant3PreEmployment2Until !== ""
                            ) {
                              x3 = true;
                            }
                          } else {
                            x3 = true;
                          }
                        }
                      } else {
                        x3 = true;
                      }
                    }
                  } else {
                    x3 = true;
                  }
                }
              }
            } else {
              x3 = true;
            }
          }
        } else {
          if (state.applicant2) {
            if (
              state.applicant2EmploymentCompany !== "" &&
              state.applicant2EmploymentPosition !== "" &&
              state.applicant2EmploymentFrom !== "" &&
              state.applicant2EmploymentUntil !== "" &&
              state.applicant2EmploymentAnnualIncome !== "" &&
              state.applicant2EmploymentZip !== "" &&
              state.applicant2EmploymentAddress !== "" &&
              state.applicant2EmploymentCity !== "" &&
              state.applicant2EmploymentState !== "" &&
              state.applicant2EmploymentPhone !== "" &&
              checkMobileNext(state.applicant2EmploymentPhone)
              // state.applicant2SpousesEmployer !== "" &&
              // state.applicant2SpousesEmployerAddress !== "" &&
              // state.applicant2SpousesIncome !== ""
            ) {
              if (state.applicant2Employment1) {
                if (
                  state.applicant2PreEmploymentCompany !== "" &&
                  state.applicant2PreEmploymentZip !== "" &&
                  state.applicant2PreEmploymentAddress !== "" &&
                  state.applicant2PreEmploymentCity !== "" &&
                  state.applicant2PreEmploymentState !== "" &&
                  state.applicant2PreEmploymentPosition !== "" &&
                  state.applicant2PreEmploymentFrom !== "" &&
                  state.applicant2PreEmploymentUntil !== ""
                ) {
                  if (state.applicant2Employment2) {
                    if (
                      state.applicant2PreEmployment2Company !== "" &&
                      state.applicant2PreEmployment2Zip !== "" &&
                      state.applicant2PreEmployment2Address !== "" &&
                      state.applicant2PreEmployment2City !== "" &&
                      state.applicant2PreEmployment2State !== "" &&
                      state.applicant2PreEmployment2Position !== "" &&
                      state.applicant2PreEmployment2From !== "" &&
                      state.applicant2PreEmployment2Until !== ""
                    ) {
                      if (state.applicant3) {
                        if (
                          state.applicant3EmploymentCompany !== "" &&
                          state.applicant3EmploymentPosition !== "" &&
                          state.applicant3EmploymentFrom !== "" &&
                          state.applicant3EmploymentUntil !== "" &&
                          state.applicant3EmploymentAnnualIncome !== "" &&
                          state.applicant3EmploymentZip !== "" &&
                          state.applicant3EmploymentAddress !== "" &&
                          state.applicant3EmploymentCity !== "" &&
                          state.applicant3EmploymentState !== "" &&
                          state.applicant3EmploymentPhone !== "" &&
                          checkMobileNext(state.applicant3EmploymentPhone)
                          // state.applicant3SpousesEmployer !== "" &&
                          // state.applicant3SpousesEmployerAddress !== "" &&
                          // state.applicant3SpousesIncome !== ""
                        ) {
                          if (state.applicant3Employment1) {
                            if (
                              state.applicant3PreEmploymentCompany !== "" &&
                              state.applicant3PreEmploymentZip !== "" &&
                              state.applicant3PreEmploymentAddress !== "" &&
                              state.applicant3PreEmploymentCity !== "" &&
                              state.applicant3PreEmploymentState !== "" &&
                              state.applicant3PreEmploymentPosition !== "" &&
                              state.applicant3PreEmploymentFrom !== "" &&
                              state.applicant3PreEmploymentUntil !== ""
                            ) {
                              if (state.applicant3Employment2) {
                                if (
                                  state.applicant3PreEmployment2Company !==
                                    "" &&
                                  state.applicant3PreEmployment2Zip !== "" &&
                                  state.applicant3PreEmployment2Address !==
                                    "" &&
                                  state.applicant3PreEmployment2City !== "" &&
                                  state.applicant3PreEmployment2State !== "" &&
                                  state.applicant3PreEmployment2Position !==
                                    "" &&
                                  state.applicant3PreEmployment2From !== "" &&
                                  state.applicant3PreEmployment2Until !== ""
                                ) {
                                  x3 = true;
                                }
                              } else {
                                x3 = true;
                              }
                            }
                          } else {
                            x3 = true;
                          }
                        }
                      } else {
                        x3 = true;
                      }
                    }
                  } else {
                    if (state.applicant3) {
                      if (
                        state.applicant3EmploymentCompany !== "" &&
                        state.applicant3EmploymentPosition !== "" &&
                        state.applicant3EmploymentFrom !== "" &&
                        state.applicant3EmploymentUntil !== "" &&
                        state.applicant3EmploymentAnnualIncome !== "" &&
                        state.applicant3EmploymentZip !== "" &&
                        state.applicant3EmploymentAddress !== "" &&
                        state.applicant3EmploymentCity !== "" &&
                        state.applicant3EmploymentState !== "" &&
                        state.applicant3EmploymentPhone !== "" &&
                        checkMobileNext(state.applicant3EmploymentPhone)
                        // state.applicant3SpousesEmployer !== "" &&
                        // state.applicant3SpousesEmployerAddress !== "" &&
                        // state.applicant3SpousesIncome !== ""
                      ) {
                        if (state.applicant3Employment1) {
                          if (
                            state.applicant3PreEmploymentCompany !== "" &&
                            state.applicant3PreEmploymentZip !== "" &&
                            state.applicant3PreEmploymentAddress !== "" &&
                            state.applicant3PreEmploymentCity !== "" &&
                            state.applicant3PreEmploymentState !== "" &&
                            state.applicant3PreEmploymentPosition !== "" &&
                            state.applicant3PreEmploymentFrom !== "" &&
                            state.applicant3PreEmploymentUntil !== ""
                          ) {
                            if (state.applicant3Employment2) {
                              if (
                                state.applicant3PreEmployment2Company !== "" &&
                                state.applicant3PreEmployment2Zip !== "" &&
                                state.applicant3PreEmployment2Address !== "" &&
                                state.applicant3PreEmployment2City !== "" &&
                                state.applicant3PreEmployment2State !== "" &&
                                state.applicant3PreEmployment2Position !== "" &&
                                state.applicant3PreEmployment2From !== "" &&
                                state.applicant3PreEmployment2Until !== ""
                              ) {
                                x3 = true;
                              }
                            } else {
                              x3 = true;
                            }
                          }
                        } else {
                          x3 = true;
                        }
                      }
                    } else {
                      x3 = true;
                    }
                  }
                }
              } else {
                if (state.applicant3) {
                  if (
                    state.applicant3EmploymentCompany !== "" &&
                    state.applicant3EmploymentPosition !== "" &&
                    state.applicant3EmploymentFrom !== "" &&
                    state.applicant3EmploymentUntil !== "" &&
                    state.applicant3EmploymentAnnualIncome !== "" &&
                    state.applicant3EmploymentZip !== "" &&
                    state.applicant3EmploymentAddress !== "" &&
                    state.applicant3EmploymentCity !== "" &&
                    state.applicant3EmploymentState !== "" &&
                    state.applicant3EmploymentPhone !== "" &&
                    checkMobileNext(state.applicant3EmploymentPhone)
                    // state.applicant3SpousesEmployer !== "" &&
                    // state.applicant3SpousesEmployerAddress !== "" &&
                    // state.applicant3SpousesIncome !== ""
                  ) {
                    if (state.applicant3Employment1) {
                      if (
                        state.applicant3PreEmploymentCompany !== "" &&
                        state.applicant3PreEmploymentZip !== "" &&
                        state.applicant3PreEmploymentAddress !== "" &&
                        state.applicant3PreEmploymentCity !== "" &&
                        state.applicant3PreEmploymentState !== "" &&
                        state.applicant3PreEmploymentPosition !== "" &&
                        state.applicant3PreEmploymentFrom !== "" &&
                        state.applicant3PreEmploymentUntil !== ""
                      ) {
                        if (state.applicant3Employment2) {
                          if (
                            state.applicant3PreEmployment2Company !== "" &&
                            state.applicant3PreEmployment2Zip !== "" &&
                            state.applicant3PreEmployment2Address !== "" &&
                            state.applicant3PreEmployment2City !== "" &&
                            state.applicant3PreEmployment2State !== "" &&
                            state.applicant3PreEmployment2Position !== "" &&
                            state.applicant3PreEmployment2From !== "" &&
                            state.applicant3PreEmployment2Until !== ""
                          ) {
                            x3 = true;
                          }
                        } else {
                          x3 = true;
                        }
                      }
                    } else {
                      x3 = true;
                    }
                  }
                } else {
                  x3 = true;
                }
              }
            }
          } else {
            x3 = true;
          }
        }
      }
    } else {
      if (state.applicant2) {
        if (
          state.applicant2EmploymentCompany !== "" &&
          state.applicant2EmploymentPosition !== "" &&
          state.applicant2EmploymentFrom !== "" &&
          state.applicant2EmploymentUntil !== "" &&
          state.applicant2EmploymentAnnualIncome !== "" &&
          state.applicant2EmploymentZip !== "" &&
          state.applicant2EmploymentAddress !== "" &&
          state.applicant2EmploymentCity !== "" &&
          state.applicant2EmploymentState !== "" &&
          state.applicant2EmploymentPhone !== "" &&
          checkMobileNext(state.applicant2EmploymentPhone)
          // state.applicant2SpousesEmployer !== "" &&
          // state.applicant2SpousesEmployerAddress !== "" &&
          // state.applicant2SpousesIncome !== ""
        ) {
          if (state.applicant2Employment1) {
            if (
              state.applicant2PreEmploymentCompany !== "" &&
              state.applicant2PreEmploymentZip !== "" &&
              state.applicant2PreEmploymentAddress !== "" &&
              state.applicant2PreEmploymentCity !== "" &&
              state.applicant2PreEmploymentState !== "" &&
              state.applicant2PreEmploymentPosition !== "" &&
              state.applicant2PreEmploymentFrom !== "" &&
              state.applicant2PreEmploymentUntil !== ""
            ) {
              if (state.applicant2Employment2) {
                if (
                  state.applicant2PreEmployment2Company !== "" &&
                  state.applicant2PreEmployment2Zip !== "" &&
                  state.applicant2PreEmployment2Address !== "" &&
                  state.applicant2PreEmployment2City !== "" &&
                  state.applicant2PreEmployment2State !== "" &&
                  state.applicant2PreEmployment2Position !== "" &&
                  state.applicant2PreEmployment2From !== "" &&
                  state.applicant2PreEmployment2Until !== ""
                ) {
                  if (state.applicant3) {
                    if (
                      state.applicant3EmploymentCompany !== "" &&
                      state.applicant3EmploymentPosition !== "" &&
                      state.applicant3EmploymentFrom !== "" &&
                      state.applicant3EmploymentUntil !== "" &&
                      state.applicant3EmploymentAnnualIncome !== "" &&
                      state.applicant3EmploymentZip !== "" &&
                      state.applicant3EmploymentAddress !== "" &&
                      state.applicant3EmploymentCity !== "" &&
                      state.applicant3EmploymentState !== "" &&
                      state.applicant3EmploymentPhone !== "" &&
                      checkMobileNext(state.applicant3EmploymentPhone)
                      // state.applicant3SpousesEmployer !== "" &&
                      // state.applicant3SpousesEmployerAddress !== "" &&
                      // state.applicant3SpousesIncome !== ""
                    ) {
                      if (state.applicant3Employment1) {
                        if (
                          state.applicant3PreEmploymentCompany !== "" &&
                          state.applicant3PreEmploymentZip !== "" &&
                          state.applicant3PreEmploymentAddress !== "" &&
                          state.applicant3PreEmploymentCity !== "" &&
                          state.applicant3PreEmploymentState !== "" &&
                          state.applicant3PreEmploymentPosition !== "" &&
                          state.applicant3PreEmploymentFrom !== "" &&
                          state.applicant3PreEmploymentUntil !== ""
                        ) {
                          if (state.applicant3Employment2) {
                            if (
                              state.applicant3PreEmployment2Company !== "" &&
                              state.applicant3PreEmployment2Zip !== "" &&
                              state.applicant3PreEmployment2Address !== "" &&
                              state.applicant3PreEmployment2City !== "" &&
                              state.applicant3PreEmployment2State !== "" &&
                              state.applicant3PreEmployment2Position !== "" &&
                              state.applicant3PreEmployment2From !== "" &&
                              state.applicant3PreEmployment2Until !== ""
                            ) {
                              x3 = true;
                            }
                          } else {
                            x3 = true;
                          }
                        }
                      } else {
                        x3 = true;
                      }
                    }
                  } else {
                    x3 = true;
                  }
                }
              } else {
                if (state.applicant3) {
                  if (
                    state.applicant3EmploymentCompany !== "" &&
                    state.applicant3EmploymentPosition !== "" &&
                    state.applicant3EmploymentFrom !== "" &&
                    state.applicant3EmploymentUntil !== "" &&
                    state.applicant3EmploymentAnnualIncome !== "" &&
                    state.applicant3EmploymentZip !== "" &&
                    state.applicant3EmploymentAddress !== "" &&
                    state.applicant3EmploymentCity !== "" &&
                    state.applicant3EmploymentState !== "" &&
                    state.applicant3EmploymentPhone !== "" &&
                    checkMobileNext(state.applicant3EmploymentPhone)
                    // state.applicant3SpousesEmployer !== "" &&
                    // state.applicant3SpousesEmployerAddress !== "" &&
                    // state.applicant3SpousesIncome !== ""
                  ) {
                    if (state.applicant3Employment1) {
                      if (
                        state.applicant3PreEmploymentCompany !== "" &&
                        state.applicant3PreEmploymentZip !== "" &&
                        state.applicant3PreEmploymentAddress !== "" &&
                        state.applicant3PreEmploymentCity !== "" &&
                        state.applicant3PreEmploymentState !== "" &&
                        state.applicant3PreEmploymentPosition !== "" &&
                        state.applicant3PreEmploymentFrom !== "" &&
                        state.applicant3PreEmploymentUntil !== ""
                      ) {
                        if (state.applicant3Employment2) {
                          if (
                            state.applicant3PreEmployment2Company !== "" &&
                            state.applicant3PreEmployment2Zip !== "" &&
                            state.applicant3PreEmployment2Address !== "" &&
                            state.applicant3PreEmployment2City !== "" &&
                            state.applicant3PreEmployment2State !== "" &&
                            state.applicant3PreEmployment2Position !== "" &&
                            state.applicant3PreEmployment2From !== "" &&
                            state.applicant3PreEmployment2Until !== ""
                          ) {
                            x3 = true;
                          }
                        } else {
                          x3 = true;
                        }
                      }
                    } else {
                      x3 = true;
                    }
                  }
                } else {
                  x3 = true;
                }
              }
            }
          } else {
            if (state.applicant3) {
              if (
                state.applicant3EmploymentCompany !== "" &&
                state.applicant3EmploymentPosition !== "" &&
                state.applicant3EmploymentFrom !== "" &&
                state.applicant3EmploymentUntil !== "" &&
                state.applicant3EmploymentAnnualIncome !== "" &&
                state.applicant3EmploymentZip !== "" &&
                state.applicant3EmploymentAddress !== "" &&
                state.applicant3EmploymentCity !== "" &&
                state.applicant3EmploymentState !== "" &&
                state.applicant3EmploymentPhone !== "" &&
                checkMobileNext(state.applicant3EmploymentPhone)
                // state.applicant3SpousesEmployer !== "" &&
                // state.applicant3SpousesEmployerAddress !== "" &&
                // state.applicant3SpousesIncome !== ""
              ) {
                if (state.applicant3Employment1) {
                  if (
                    state.applicant3PreEmploymentCompany !== "" &&
                    state.applicant3PreEmploymentZip !== "" &&
                    state.applicant3PreEmploymentAddress !== "" &&
                    state.applicant3PreEmploymentCity !== "" &&
                    state.applicant3PreEmploymentState !== "" &&
                    state.applicant3PreEmploymentPosition !== "" &&
                    state.applicant3PreEmploymentFrom !== "" &&
                    state.applicant3PreEmploymentUntil !== ""
                  ) {
                    if (state.applicant3Employment2) {
                      if (
                        state.applicant3PreEmployment2Company !== "" &&
                        state.applicant3PreEmployment2Zip !== "" &&
                        state.applicant3PreEmployment2Address !== "" &&
                        state.applicant3PreEmployment2City !== "" &&
                        state.applicant3PreEmployment2State !== "" &&
                        state.applicant3PreEmployment2Position !== "" &&
                        state.applicant3PreEmployment2From !== "" &&
                        state.applicant3PreEmployment2Until !== ""
                      ) {
                        x3 = true;
                      }
                    } else {
                      x3 = true;
                    }
                  }
                } else {
                  x3 = true;
                }
              }
            } else {
              x3 = true;
            }
          }
        }
      } else {
        x3 = true;
      }
    }
  }
  return x3;
};

export const canadaStep4Verification = (state) => {
  let x4 = false;
  if (
    state.applicantPrimaryBankName !== "" &&
    state.applicantPrimaryBankBranch !== "" &&
    state.applicantPrimaryBankAccType !== "" &&
    state.applicantPrimaryBankAccNo !== "" &&
    checkAccountNoNext(state.applicantPrimaryBankAccNo)
    // state.applicantSecondaryBankName !== "" &&
    // state.applicantSecondaryBankBranch !== "" &&
    // state.applicantSecondaryBankAccType !== "" &&
    // state.applicantSecondaryBankAccNo !== "" &&
    // checkAccountNoNext(state.applicantSecondaryBankAccNo)
  ) {
    if (state.applicant2) {
      if (
        state.applicant2PrimaryBankName !== "" &&
        state.applicant2PrimaryBankBranch !== "" &&
        state.applicant2PrimaryBankAccType !== "" &&
        state.applicant2PrimaryBankAccNo !== "" &&
        checkAccountNoNext(state.applicant2PrimaryBankAccNo)
        // state.applicant2SecondaryBankName !== "" &&
        // state.applicant2SecondaryBankBranch !== "" &&
        // state.applicant2SecondaryBankAccType !== "" &&
        // state.applicant2SecondaryBankAccNo !== "" &&
        // checkAccountNoNext(state.applicant2SecondaryBankAccNo)
      ) {
        if (state.applicant3) {
          if (
            state.applicant3PrimaryBankName !== "" &&
            state.applicant3PrimaryBankBranch !== "" &&
            state.applicant3PrimaryBankAccType !== "" &&
            state.applicant3PrimaryBankAccNo !== "" &&
            checkAccountNoNext(state.applicant3PrimaryBankAccNo)
            // state.applicant3SecondaryBankName !== "" &&
            // state.applicant3SecondaryBankBranch !== "" &&
            // state.applicant3SecondaryBankAccType !== "" &&
            // state.applicant3SecondaryBankAccNo !== "" &&
            // checkAccountNoNext(state.applicant3SecondaryBankAccNo)
          ) {
            x4 = true;
          }
        } else {
          x4 = true;
        }
      }
    } else {
      if (state.applicant3) {
        if (
          state.applicant3PrimaryBankName !== "" &&
          state.applicant3PrimaryBankBranch !== "" &&
          state.applicant3PrimaryBankAccType !== "" &&
          state.applicant3PrimaryBankAccNo !== "" &&
          checkAccountNoNext(state.applicant3PrimaryBankAccNo)
          // state.applicant3SecondaryBankName !== "" &&
          // state.applicant3SecondaryBankBranch !== "" &&
          // state.applicant3SecondaryBankAccType !== "" &&
          // state.applicant3SecondaryBankAccNo !== "" &&
          // checkAccountNoNext(state.applicant3SecondaryBankAccNo)
        ) {
          x4 = true;
        }
      } else {
        x4 = true;
      }
    }
  }
  return x4;
};
