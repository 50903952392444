import React, { Component } from "react";
import "../../contracts.css";
import "../style.css";
import "../../../Application/application.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { appendAlert, setContract } from "../../../../../actions";
import { CountryJson } from "../../../Property/CountryJson";
import StepNavigator from "../../../Components/StepNavigator";
import Stepper01Elements from "./CreateContractBritishColumbiaChild/Stepper01Elements";
import {
  ModifyString,
  nextFirstTabCheck,
  nextForthTabCheck,
  nextSecondTabCheck,
  nextThirdTabCheck,
  submitContract,
} from "./CreateContractBritishColumbiaFunctions";
import Stepper02Elements from "./CreateContractBritishColumbiaChild/Stepper02Elements";
import Stepper03Elements from "./CreateContractBritishColumbiaChild/Stepper03Elements";
import Stepper04Elements from "./CreateContractBritishColumbiaChild/Stepper04Elements";
import { demoDataBCContract } from "./demoData";
import Stepper05Elements from "./CreateContractBritishColumbiaChild/Stepper05Elements";
import SignaturePad from "../../../../UIServices/SignaturePad/SignaturePad";
import SmallModalContainer from "../../../Documents/SmallModalContainer";
import Dropzone from "react-dropzone";
import {
  getPropertyLists,
  url,
  userListDetail,
} from "../../../../../Services/ApiServices";
import CookieService from "../../../../../Services/CookieService";
import Loader from "../../../../Loader/Loader";

class CreateContractBritishColumbia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: true,
      nextEnable: false,
      landlordCountryJson: [],
      landlordProvinceJson: [],
      landlordCityJson: [],
      landlordCountryList: [],
      landlordProvinceList: [],
      landlordCityList: [],
      landlordAgentCountryJson: [],
      landlordAgentProvinceJson: [],
      landlordAgentCityJson: [],
      landlordAgentCountryList: [],
      landlordAgentProvinceList: [],
      landlordAgentCityList: [],

      nowTab: 1,
      landlord: true,
      landlordAgent: false,
      landlordUnit: "",
      landlordStreet: this.props.userDetail.address.address,
      landlordCity: this.props.userDetail.address.city,
      landlordProvince: this.props.userDetail.address.state,
      landlordPostalCode: this.props.userDetail.address.zip,
      landlordDayTimePhoneNumber: this.props.userDetail.mobileNumber,
      landlordPhoneNumber: "",
      landlordFaxNumber: "",
      landlordAgentUnit: "",
      landlordAgentStreet: "",
      landlordAgentCity: "",
      landlordAgentProvince: "",
      landlordAgentPostalCode: "",
      landlordAgentDayTimePhoneNumber: "",
      landlordAgentPhoneNumber: "",
      landlordAgentFaxNumber: "",
      moveInDate: "",
      agreementPaymentMonthBasicType: true,
      agreementPaymentPeriodicType: false,
      agreementPaymentFixedType: false,
      agreementPaymentWeekly: false,
      agreementPaymentBiWeekly: false,
      agreementPaymentOther: false,
      agreementPaymentOtherType: "",
      agreementPaymentFixedTermEndVacate: "",
      agreementPaymentFixedTermEndNotContinue: false,
      agreementPaymentFixedTermEndContinue: false,
      reasonTenancyVacate: "",
      regulationTenancyNumber: "",

      rent: this.props.location.state
        ? this.props.location.state.applicationData
          ? this.props.location.state.applicationData.rent
          : ""
        : "",
      rentDay: true,
      rentWeek: false,
      rentMonth: false,
      paymentDay: "",
      rentPayDay: true,
      rentPayWeek: false,
      rentPayMonth: false,

      securityDeposit: "",
      securityDepositPayBy: "",
      isPetDeposit: false,
      petDeposit: "",
      petDepositPayBy: "",

      isUtilityWater: false,
      isUtilityNaturalGas: false,
      isUtilityGarbageCollection: false,
      isUtilityRefrigerator: false,
      isUtilityCarpets: false, //
      isUtilityCablevision: false,
      isUtilitySewageDisposal: false,
      isUtilityRecyclingServices: false,
      isUtilityDishwasher: false, //
      isUtilityElectricity: false,

      isUtilitySnowRemoval: false,
      isUtilityKitchenScrapCollection: false,
      isUtilityStoveAndOven: false,
      isUtilityInternet: false,
      isUtilityStorage: false,
      isUtilityLaundry: false,
      isUtilityWindowCoverings: false,
      isUtilityHeat: false,
      isUtilityRecreationFacilities: false,
      isUtilityFreeLaundry: false,

      isUtilityFurniture: false,
      isUtilityParkingFor: false,
      utilityParkingVehicles: "",
      isUtilityOther1: false,
      utilityOther1: "",
      isUtilityOther2: false,
      utilityOther2: "",
      isUtilityOther3: false,
      utilityOther3: "",
      isUtilityAdditionalInfo: false,

      utilityAdditionalInfo: "",
      isAttachedTenancyAgreement: true,
      isNotAttachedTenancyAgreement: false,
      numberAddendumPage: "",
      numberAdditionalAddendumPage: "",

      pdfDocObject: "",
      pdfLink: null,
      changeBlob: false,
      isButtonDisableFinish: false,

      signatureObject: null,
      signatureObject2: null,
      signatureObject3: null,
      signatureTab: "pad",
      signModal: false,
      signModal2: false,
      applicationData: this.props.location.state
        ? this.props.location.state.applicationData
        : null,
      propertyData: this.props.location.state
        ? this.props.location.state.propertyData
        : null,

      occupants: "",
      tenantData: [],
      listingData: [],

      formPdfBytes: null,

      tabData: [
        {
          head: "Basic Info",
          icon: "fa-address-card-o",
        },
        {
          head: "Rental Info",
          icon: "fa-home",
        },
        {
          head: "Security Info",
          icon: "fa-shield",
        },
        {
          head: "Additional Info",
          icon: "fa-puzzle-piece",
        },
        {
          head: "Review",
          icon: "fa-retweet",
        },
      ],
    };
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.changeState = this.changeState.bind(this);
    this.onchange = this.onchange.bind(this);
    this.checkValidate = this.checkValidate.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.nextButtonFunction = this.nextButtonFunction.bind(this);
    this.backButtonFunction = this.backButtonFunction.bind(this);
    this.changeState2 = this.changeState2.bind(this);
    this.nextButtonCheck = this.nextButtonCheck.bind(this);
    this.setDemoData = this.setDemoData.bind(this);
    this.changeSignatureObject = this.changeSignatureObject.bind(this);
    this.changeSignatureObject2 = this.changeSignatureObject2.bind(this);
    this.changeSignatureObject3 = this.changeSignatureObject3.bind(this);
    this.Submit = this.Submit.bind(this);
  }
  async componentDidMount() {
    if (this.props.location.state) {
      if (
        this.props.location.state.applicationData === null ||
        this.props.location.state.applicationData === undefined
      ) {
        this.props.history.push("/applications");
      }
      if (!this.props.location.state.applicationData) {
        this.props.history.push("/applications");
      }

      const json = await CountryJson();
      this.setState({
        landlordCountryJson: json,
        landlordProvinceJson: json[0].states,
        landlordAgentCountryJson: json,
        landlordAgentProvinceJson: json[0].states,
      });
      let landlordCountryList = [],
        landlordAgentCountryList = [],
        landlordAgentProvinceList = [],
        landlordProvinceList = [];
      this.state.landlordCountryJson.map((data) =>
        landlordCountryList.push(data.name)
      );
      this.state.landlordProvinceJson.map((data) =>
        landlordProvinceList.push(data.name)
      );
      this.state.landlordAgentCountryJson.map((data) =>
        landlordAgentCountryList.push(data.name)
      );
      this.state.landlordAgentProvinceJson.map((data) =>
        landlordAgentProvinceList.push(data.name)
      );
      this.setState({
        landlordCountryList: landlordCountryList,
        landlordProvinceList: landlordProvinceList,
        landlordAgentCountryList: landlordAgentCountryList,
        landlordAgentProvinceList: landlordAgentProvinceList,
      });
      if (this.props.location.state.applicationData) {
        try {
          let userList = [];
          if (this.props.location.state.applicationData.apply[0]) {
            userList.push(
              this.props.location.state.applicationData.apply[0].applicant
                .username
            );
          }
          if (this.props.location.state.applicationData.apply[1]) {
            userList.push(
              this.props.location.state.applicationData.apply[1].applicant
                .username
            );
          }
          if (this.props.location.state.applicationData.apply[2]) {
            userList.push(
              this.props.location.state.applicationData.apply[2].applicant
                .username
            );
          }
          if (userList.length > 0) {
            userListDetail(userList).then((response) => {
              if (response.status === 200) {
                if (response.data.length !== 0) {
                  this.setState({ tenantData: response.data });
                  let occ1 = "";

                  occ1 =
                    ModifyString(response.data[0].firstName) +
                    " " +
                    ModifyString(response.data[0].lastName) +
                    ", " +
                    ModifyString(
                      this.props.location.state.applicationData.occupant
                    );
                  this.setState({
                    occupants: occ1,
                  });
                  getPropertyLists(
                    this.props.location.state.applicationData.rentalEntityId
                  ).then((response1) => {
                    if (response1.status === 200) {
                      if (response1.data.length !== 0) {
                        this.setState({
                          listingData: response1.data,
                        });
                      }
                    }
                  });
                }
              }
            });
          } else {
            this.props.history.push("/applications");
          }
        } catch (error) {
          console.error(error);
        }
      }
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        "Bearer " + CookieService.getCookie("axTok")
      );
      const requestOptions = {
        method: "GET", // Replace with the appropriate HTTP method (GET, POST, PUT, DELETE, etc.)
        headers: headers,
      };
      const formUrl = url + "api/contract/britishColumbia/template/download";
      const formPdfBytes = await fetch(formUrl, requestOptions).then((res) =>
        res.arrayBuffer()
      );

      this.setState({
        formPdfBytes: formPdfBytes,
      });
    } else {
      this.props.history.push("/applications");
    }
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    if (name === "landlord") {
      await this.setState({ [name]: checked, landlordAgent: !checked });
    } else if (name === "landlordAgent") {
      await this.setState({ [name]: checked, landlord: !checked });
    } else if (name === "agreementPaymentMonthBasicType") {
      if (checked) {
        await this.setState({
          [name]: checked,
          agreementPaymentPeriodicType: false,
          agreementPaymentFixedType: false,
          agreementPaymentWeekly: false,
          agreementPaymentBiWeekly: false,
          agreementPaymentOther: false,
          agreementPaymentOtherType: "",
          agreementPaymentFixedTermEndContinue: false,
          agreementPaymentFixedTermEndNotContinue: false,
          agreementPaymentFixedTermEndVacate: "",
        });
      }
    } else if (name === "agreementPaymentPeriodicType") {
      if (checked) {
        await this.setState({
          [name]: checked,
          agreementPaymentMonthBasicType: false,
          agreementPaymentFixedType: false,
          agreementPaymentWeekly: true,
          agreementPaymentBiWeekly: false,
          agreementPaymentOther: false,
          agreementPaymentOtherType: "",
          agreementPaymentFixedTermEndContinue: false,
          agreementPaymentFixedTermEndNotContinue: false,
          agreementPaymentFixedTermEndVacate: "",
        });
      }
    } else if (name === "agreementPaymentFixedType") {
      if (checked) {
        await this.setState({
          [name]: checked,
          agreementPaymentMonthBasicType: false,
          agreementPaymentPeriodicType: false,
          agreementPaymentWeekly: false,
          agreementPaymentBiWeekly: false,
          agreementPaymentOther: false,
          agreementPaymentOtherType: "",
          agreementPaymentFixedTermEndNotContinue: true,
        });
      }
    } else if (name === "agreementPaymentWeekly") {
      if (checked) {
        if (this.state.agreementPaymentPeriodicType) {
          await this.setState({
            [name]: checked,
            agreementPaymentBiWeekly: false,
            agreementPaymentOther: false,
            agreementPaymentOtherType: "",
          });
        }
      }
    } else if (name === "agreementPaymentBiWeekly") {
      if (checked) {
        if (this.state.agreementPaymentPeriodicType) {
          await this.setState({
            [name]: checked,
            agreementPaymentWeekly: false,
            agreementPaymentOther: false,
            agreementPaymentOtherType: "",
          });
        }
      }
    } else if (name === "agreementPaymentOther") {
      if (checked) {
        if (this.state.agreementPaymentPeriodicType) {
          await this.setState({
            [name]: checked,
            agreementPaymentWeekly: false,
            agreementPaymentBiWeekly: false,
            agreementPaymentOtherType: "",
          });
        }
      }
    } else if (name === "agreementPaymentFixedTermEndNotContinue") {
      if (this.state.agreementPaymentFixedType) {
        await this.setState({
          [name]: checked,
          agreementPaymentFixedTermEndContinue: !checked,
          reasonTenancyVacate: "",
          regulationTenancyNumber: "",
        });
      }
    } else if (name === "agreementPaymentFixedTermEndContinue") {
      if (this.state.agreementPaymentFixedType) {
        await this.setState({
          [name]: checked,
          agreementPaymentFixedTermEndNotContinue: !checked,
          reasonTenancyVacate: "",
          regulationTenancyNumber: "",
        });
      }
    } else if (name === "isPetDeposit") {
      await this.setState({
        [name]: checked,
        petDeposit: "",
        petDepositPayBy: "",
      });
    } else if (name === "isAttachedTenancyAgreement") {
      await this.setState({
        [name]: checked,
        isNotAttachedTenancyAgreement: !checked,
        numberAddendumPage: "",
        numberAdditionalAddendumPage: "",
      });
    } else if (name === "isNotAttachedTenancyAgreement") {
      await this.setState({
        [name]: checked,
        isAttachedTenancyAgreement: !checked,
        numberAddendumPage: "",
        numberAdditionalAddendumPage: "",
      });
    } else if (name === "isUtilityParkingFor") {
      await this.setState({
        [name]: checked,
        utilityParkingVehicles: "",
      });
    } else if (name === "isUtilityOther1") {
      await this.setState({
        [name]: checked,
        utilityOther1: "",
      });
    } else if (name === "isUtilityOther2") {
      await this.setState({
        [name]: checked,
        utilityOther2: "",
      });
    } else if (name === "isUtilityOther3") {
      await this.setState({
        [name]: checked,
        utilityOther3: "",
      });
    } else if (name === "isUtilityAdditionalInfo") {
      await this.setState({
        [name]: checked,
        utilityAdditionalInfo: "",
      });
    } else if (name === "rentDay") {
      await this.setState({
        [name]: checked,
        rentWeek: false,
        rentMonth: false,
      });
    } else if (name === "rentWeek") {
      await this.setState({
        [name]: checked,
        rentDay: false,
        rentMonth: false,
      });
    } else if (name === "rentMonth") {
      await this.setState({
        [name]: checked,
        rentWeek: false,
        rentDay: false,
      });
    } else if (name === "rentPayDay") {
      await this.setState({
        [name]: checked,
        rentPayWeek: false,
        rentPayMonth: false,
      });
    } else if (name === "rentPayWeek") {
      await this.setState({
        [name]: checked,
        rentPayDay: false,
        rentPayMonth: false,
      });
    } else if (name === "rentPayMonth") {
      await this.setState({
        [name]: checked,
        rentPayWeek: false,
        rentPayDay: false,
      });
    } else {
      await this.setState({
        [name]: checked,
      });
    }
    this.nextButtonCheck();
  }

  async changeState(name, value) {
    await this.setState({ [name]: value });
    if (name === "landlordProvince") {
      let selectedCities = this.state.landlordProvinceJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let landlordCityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ landlordCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) =>
          landlordCityList.push(data.name)
        );
        this.setState({ landlordCityList: landlordCityList });
        this.setState({ landlordCity: "" });
      } else {
        this.setState({ landlordCity: "" });
        this.setState({ landlordCityList: [] });
      }
    } else if (name === "landlordAgentProvince") {
      let selectedCities = this.state.landlordAgentProvinceJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let landlordAgentCityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ landlordAgentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) =>
          landlordAgentCityList.push(data.name)
        );
        this.setState({ landlordAgentCityList: landlordAgentCityList });
        this.setState({ landlordAgentCity: "" });
      } else {
        this.setState({ landlordAgentCity: "" });
        this.setState({ landlordAgentCityList: [] });
      }
    }
    this.nextButtonCheck();
  }
  async onchange(e) {
    let { name, value } = e.target;
    if (
      name === "landlordDayTimePhoneNumber" ||
      name === "landlordAgentPhoneNumber" ||
      name === "landlordPhoneNumber" ||
      name === "landlordAgentDayTimePhoneNumber" ||
      name === "landlordAgentPhoneNumber"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (name === "agreementPaymentOtherType") {
      if (this.state.agreementPaymentOther) {
        await this.setState({ [name]: value });
      }
    } else if (
      name === "reasonTenancyVacate" ||
      name === "regulationTenancyNumber"
    ) {
      if (this.state.agreementPaymentFixedTermEndContinue) {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
    this.nextButtonCheck();
  }
  checkValidate(name) {
    if (name === "regulationTenancyNumber" || name === "reasonTenancyVacate") {
      if (this.state.agreementPaymentFixedTermEndContinue) {
        return this.state[name] === "" && !this.state.validation;
      } else {
        return false;
      }
    } else if (name === "agreementPaymentFixedTermEndVacate") {
      if (this.state.agreementPaymentFixedType) {
        return this.state[name] === "" && !this.state.validation;
      } else {
        return false;
      }
    } else if (name === "agreementPaymentOtherType") {
      if (this.state.agreementPaymentOther) {
        return this.state[name] === "" && !this.state.validation;
      } else {
        return false;
      }
    } else {
      return this.state[name] === "" && !this.state.validation;
    }
  }

  async onChangeDate(name, value) {
    await this.setState({ [name]: value });
    this.nextButtonCheck();
  }
  changeState2(value) {
    this.setState(value);
  }
  nextButtonCheck() {
    if (this.state.nowTab === 1) {
      this.setState({
        nextEnable: nextFirstTabCheck(false, this.changeState2, this.state),
      });
    } else if (this.state.nowTab === 2) {
      this.setState({
        nextEnable: nextSecondTabCheck(false, this.changeState2, this.state),
      });
    } else if (this.state.nowTab === 3) {
      this.setState({
        nextEnable: nextThirdTabCheck(false, this.changeState2, this.state),
      });
    } else if (this.state.nowTab === 4) {
      this.setState({
        nextEnable: nextForthTabCheck(false, this.changeState2, this.state),
      });
    }
  }
  backButtonFunction() {
    if (this.state.nowTab === 2) {
      this.setState({ nowTab: 1 });
    } else if (this.state.nowTab === 3) {
      this.setState({ nowTab: 2 });
    } else if (this.state.nowTab === 4) {
      this.setState({ nowTab: 3 });
    } else if (this.state.nowTab === 5) {
      this.setState({ nowTab: 4 });
    }
  }
  nextButtonFunction() {
    if (this.state.nowTab === 1) {
      if (nextFirstTabCheck(true, this.changeState2, this.state)) {
        this.setState({ nowTab: 2 });
      }
    } else if (this.state.nowTab === 2) {
      if (nextSecondTabCheck(true, this.changeState2, this.state)) {
        this.setState({ nowTab: 3 });
      }
    } else if (this.state.nowTab === 3) {
      if (nextThirdTabCheck(true, this.changeState2, this.state)) {
        this.setState({ nowTab: 4 });
      }
    } else if (this.state.nowTab === 4) {
      if (nextForthTabCheck(true, this.changeState2, this.state)) {
        this.setState({ nowTab: 5 });
        if (this.state.signatureObject2 === null) {
          this.openSignModal2();
        }
      }
    } else if (this.state.nowTab === 5) {
      if (this.state.signatureObject === null) {
        this.openSignModal();
      }
      if (
        this.state.signatureObject2 === null &&
        this.state.agreementPaymentFixedTermEndContinue
      ) {
        this.openSignModal2();
      }
    }
  }
  async openSignModal() {
    await this.setState({ signModal: true });
    window.$("#signatureModal").modal("show");
  }
  async openSignModal2() {
    await this.setState({ signModal2: true });
    window.$("#signatureModal2").modal("show");
  }
  async changeSignatureObject(object) {
    window.$("#signatureModal").modal("hide");
    await this.setState({ signatureObject: object, signModal: false });
  }
  async changeSignatureObject2(object) {
    window.$("#signatureModal2").modal("hide");
    await this.setState({ signatureObject2: object, signModal2: false });
  }
  changeSignatureObject3(object) {
    this.setState({ signatureObject3: object });
  }
  setDemoData() {
    this.setState(demoDataBCContract(this.props.userDetail));
  }

  Submit() {
    submitContract(this.changeState2, this.state, this.props);
  }

  render() {
    return (
      <>
        <div
          className="container-fluid"
          style={{ minHeight: window.screen.height }}
        >
          <StepNavigator
            activeTab={this.state.nowTab}
            tabData={this.state.tabData}
            next={
              this.state.nowTab === 5
                ? this.state.agreementPaymentFixedTermEndContinue
                  ? this.state.signatureObject === null ||
                    this.state.signatureObject2 === null
                    ? this.nextButtonFunction
                    : this.Submit
                  : this.state.signatureObject === null
                  ? this.nextButtonFunction
                  : this.Submit
                : this.nextButtonFunction
            }
            nextEnable={this.state.nextEnable}
            back={this.backButtonFunction}
            history={this.props.history}
            nextText={
              this.state.nowTab === 5
                ? this.state.agreementPaymentFixedTermEndContinue
                  ? this.state.signatureObject === null ||
                    this.state.signatureObject2 === null
                    ? "Sign here"
                    : "Submit"
                  : this.state.signatureObject === null
                  ? "Sign here"
                  : "Submit"
                : "Next"
            }
            nextLoading={this.state.isButtonDisableFinish}
            nextTarget={this.state.nowTab === 5 ? "#signatureModal" : ""}
          >
            {this.state.nowTab === 1 && (
              <Stepper01Elements
                values={this.state}
                onchangeCheck={this.onchangeCheck}
                checkValidate={this.checkValidate}
                onchange={this.onchange}
                changeState={this.changeState}
                onChangeDate={this.onChangeDate}
                setDemoData={this.setDemoData}
              />
            )}
            {this.state.nowTab === 2 && (
              <Stepper02Elements
                values={this.state}
                onchangeCheck={this.onchangeCheck}
                checkValidate={this.checkValidate}
                onchange={this.onchange}
                changeState={this.changeState}
                onChangeDate={this.onChangeDate}
                nextButtonCheck={this.nextButtonCheck}
              />
            )}
            {this.state.nowTab === 3 && (
              <Stepper03Elements
                values={this.state}
                onchangeCheck={this.onchangeCheck}
                checkValidate={this.checkValidate}
                onchange={this.onchange}
                changeState={this.changeState}
                onChangeDate={this.onChangeDate}
                nextButtonCheck={this.nextButtonCheck}
              />
            )}
            {this.state.nowTab === 4 && (
              <Stepper04Elements
                values={this.state}
                onchangeCheck={this.onchangeCheck}
                checkValidate={this.checkValidate}
                onchange={this.onchange}
                changeState={this.changeState}
                onChangeDate={this.onChangeDate}
                nextButtonCheck={this.nextButtonCheck}
              />
            )}
            {this.state.nowTab === 5 &&
              (this.state.tenantData.length > 0 ? (
                <Stepper05Elements
                  values={this.state}
                  onchangeCheck={this.onchangeCheck}
                  checkValidate={this.checkValidate}
                  onchange={this.onchange}
                  changeState={this.changeState}
                  onChangeDate={this.onChangeDate}
                  nextButtonCheck={this.nextButtonCheck}
                  UserData={this.props.UserData}
                  applicationData={this.state.applicationData}
                  propertyData={this.state.propertyData}
                  occupants={this.state.occupants}
                  tenantData={this.state.tenantData}
                  listingData={this.state.listingData}
                />
              ) : (
                <Loader />
              ))}
          </StepNavigator>
        </div>
        {this.state.signModal && (
          <SmallModalContainer
            name="signatureModal"
            dark={this.props.dark}
            title="Final signature please"
            footer="false"
            size="lg"
          >
            <div className="row">
              <div className="col ">
                <button
                  className={
                    this.state.signatureTab === "pad"
                      ? "mr-2 tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() =>
                    this.setState({
                      signatureTab: "pad",
                      signatureObject: null,
                    })
                  }
                >
                  Draw
                </button>
                <button
                  className={
                    this.state.signatureTab === "upload"
                      ? "mr-2  tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() =>
                    this.setState({
                      signatureTab: "upload",
                      signatureObject: null,
                    })
                  }
                >
                  Upload
                </button>
              </div>
            </div>

            <div className={"tab-content  borderSec"} id="myTabContent">
              <div
                className={
                  this.state.signatureTab === "pad"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-center pt-5 pb-4">
                  <SignaturePad
                    name="applicationLast"
                    submitAlign="pull-right"
                    submitName="Submit"
                    type="base64"
                    value={this.state.signatureObject}
                    submit={this.changeSignatureObject}
                    submit2={this.changeSignatureObject3}
                    type2={true}
                    submitDisable={true}
                    maxWidth="450"
                  />
                </div>
              </div>
              <div
                className={
                  this.state.signatureTab === "upload"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-start mb-5">
                  <Dropzone
                    accept=".jpeg,.png,.jpg"
                    maxSize={10485760}
                    onDrop={(files) => {
                      files.map((file) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          if (event.target.result === undefined) {
                            this.props.appendAlert(
                              "Image size is too Long (max size = 10mb) ",
                              "danger"
                            );
                          } else {
                            this.setState({
                              signatureObject: event.target.result,
                            });
                          }
                        };
                        reader.readAsDataURL(file);
                        return file;
                      });
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.state.signatureObject === null ? (
                            <div className="text-left p-4">
                              <div
                                className="dragDrop text-center  pointer"
                                style={{
                                  fontSize: "60px",
                                  minHeight: "250px",
                                  paddingTop: "70px",
                                }}
                              >
                                <div className="row">
                                  <div className="col">
                                    <i
                                      className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="licenseHead">
                                      Drag Or Click to Upload
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <span className="span5 textPri ">
                                Max file size is 10MB per image. JPG, PNG, or
                                GIF formats only.
                              </span>
                            </div>
                          ) : (
                            <div className=" text-center ">
                              <img
                                src={this.state.signatureObject}
                                className="licenseImage dragDrop pointer"
                                alt="propsImage"
                                style={{ height: "100%", width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <div className="col ">
                    <button
                      className="buttonSquareLinePri pull-left ml-3 mt-3"
                      onClick={() => this.setState({ signatureObject: null })}
                    >
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    {this.state.signatureObject !== null ? (
                      <button
                        className=" mr-3 pull-right mb-3"
                        onClick={() =>
                          this.changeSignatureObject(this.state.signatureObject)
                        }
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="secDisable pull-right mb-3 mr-3"
                        style={{ cursor: "not-allowed" }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Please provide your signature image"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SmallModalContainer>
        )}
        {this.state.signModal2 &&
          this.state.agreementPaymentFixedTermEndContinue && (
            <SmallModalContainer
              name="signatureModal2"
              dark={this.props.dark}
              title="Add Initials"
              footer="false"
              size="lg"
            >
              <div className="row">
                <div className="col ">
                  <button
                    className={
                      this.state.signatureTab === "pad"
                        ? "mr-2 tabSignature"
                        : "mr-2  tabSignature2"
                    }
                    onClick={() =>
                      this.setState({
                        signatureTab: "pad",
                        signatureObject2: null,
                      })
                    }
                  >
                    Draw
                  </button>
                  <button
                    className={
                      this.state.signatureTab === "upload"
                        ? "mr-2  tabSignature"
                        : "mr-2  tabSignature2"
                    }
                    onClick={() =>
                      this.setState({
                        signatureTab: "upload",
                        signatureObject2: null,
                      })
                    }
                  >
                    Upload
                  </button>
                </div>
              </div>

              <div className={"tab-content  borderSec"} id="myTabContent">
                <div
                  className={
                    this.state.signatureTab === "pad"
                      ? "tab-pane fade show active "
                      : "tab-pane fade "
                  }
                  id="upload"
                  role="tabpanel"
                  aria-labelledby="upload-tab"
                >
                  <div className="d-flex justify-content-center pt-5 pb-4">
                    <SignaturePad
                      name="applicationLast"
                      submitAlign="pull-right"
                      submitName="Submit"
                      type="base64"
                      value={this.state.signatureObject2}
                      submit={this.changeSignatureObject2}
                      submit2={this.changeSignatureObject3}
                      type2={true}
                      submitDisable={true}
                      maxWidth="450"
                    />
                  </div>
                </div>
                <div
                  className={
                    this.state.signatureTab === "upload"
                      ? "tab-pane fade show active "
                      : "tab-pane fade "
                  }
                  id="upload"
                  role="tabpanel"
                  aria-labelledby="upload-tab"
                >
                  <div className="d-flex justify-content-start mb-5">
                    <Dropzone
                      accept=".jpeg,.png,.jpg"
                      maxSize={10485760}
                      onDrop={(files) => {
                        files.map((file) => {
                          const reader = new FileReader();
                          reader.onload = (event) => {
                            if (event.target.result === undefined) {
                              this.props.appendAlert(
                                "Image size is too Long (max size = 10mb) ",
                                "danger"
                              );
                            } else {
                              this.setState({
                                signatureObject2: event.target.result,
                              });
                            }
                          };
                          reader.readAsDataURL(file);
                          return file;
                        });
                      }}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.state.signatureObject2 === null ? (
                              <div className="text-left p-4">
                                <div
                                  className="dragDrop text-center  pointer"
                                  style={{
                                    fontSize: "60px",
                                    minHeight: "250px",
                                    paddingTop: "70px",
                                  }}
                                >
                                  <div className="row">
                                    <div className="col">
                                      <i
                                        className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <p className="licenseHead">
                                        Drag Or Click to Upload
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <span className="span5 textPri ">
                                  Max file size is 10MB per image. JPG, PNG, or
                                  GIF formats only.
                                </span>
                              </div>
                            ) : (
                              <div className=" text-center ">
                                <img
                                  src={this.state.signatureObject2}
                                  className="licenseImage dragDrop pointer"
                                  alt="propsImage"
                                  style={{ height: "100%", width: "100%" }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Dropzone>
                  </div>
                  <div className="row">
                    <div className="col ">
                      <button
                        className="buttonSquareLinePri pull-left ml-3 mt-3"
                        onClick={() =>
                          this.setState({ signatureObject2: null })
                        }
                      >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </button>
                      {this.state.signatureObject2 !== null ? (
                        <button
                          className=" mr-3 pull-right mb-3"
                          onClick={() =>
                            this.changeSignatureObject2(
                              this.state.signatureObject2
                            )
                          }
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          className="secDisable pull-right mb-3 mr-3"
                          style={{ cursor: "not-allowed" }}
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Please provide your signature image"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SmallModalContainer>
          )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setContract })(
    CreateContractBritishColumbia
  )
);
