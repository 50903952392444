import React, { Component } from "react";
import "../contracts.css";
import coverImage from "../../../../Images/no-cover-img.jpg";
import {
  getPropertyLists,
  deleteContracts,
  joinTenantsStatus,
  getRentalListsImages,
  url,
} from "../../../../Services/ApiServices";
import Loader3 from "../../../Loader/loader3";
import {
  addressFormatter,
  setDateFormat,
  timeFormatHumanInteraction,
} from "../../../../Defined/Function";
import ApplicationInput from "../../../UIServices/ApplicationInput";

import { connect } from "react-redux";
import {
  appendAlert,
  appendTempListingData,
  appendTempTenantByContract,
} from "../../../../actions";
import { withRouter } from "react-router-dom";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

class ContractListLandlord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingData: [],
      contractData: this.props.data,
      viewingData: [],
      tenantStatus: [],
      subscriptionDate: "",
      next: false,
      listImage: "",
      failFetching: false,
      paymentPlanToggle: false,
    };
    this.rejectContract = this.rejectContract.bind(this);
    this.onchange = this.onchange.bind(this);
    this.checkValidate = this.checkValidate.bind(this);
    this.changeMethod = this.changeMethod.bind(this);
  }
  changePaymentPlanToggle() {
    this.setState({ paymentPlanToggle: !this.state.paymentPlanToggle });
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  changeMethod() {
    this.setState({ next: true });
    if (this.state.contractData.subscription) {
      this.props.appendAlert("changed as one time pay", "success");
      this.changePaymentPlanToggle();
      let sub = this.state.contractData;
      sub.subscription = false;
      this.setState({ contractData: sub });
    } else {
      if (this.state.subscriptionDate !== "") {
        this.setState({ next: false });
        this.props.appendAlert("changed as auto pay", "success");
        let sub1 = this.state.contractData;
        sub1.subscription = true;
        this.setState({ contractData: sub1 });
      }
    }
  }
  getProps() {
    getPropertyLists(this.props.data.rentalEntityId).then((response) => {
      if (response.status === 200) {
        if (response.data.length !== 0) {
          this.setState({
            listingData: response.data,
            failFetching: false,
          });
          this.props.appendTempListingData(response.data);
        } else {
          console.log("No data there");
        }
      } else {
        this.setState({ failFetching: true });
      }
    });
  }

  getTenant() {
    joinTenantsStatus(this.state.contractData.contractId).then((response) => {
      if (response.status === 200) {
        if (response.data.length !== 0) {
          this.setState({ tenantStatus: response.data });
          this.props.appendTempTenantByContract(
            response.data,
            this.state.contractData.contractId
          );
        }
      } else {
        this.setState({ tenantStatus: this.state.contractData.tenants });
      }
    });
  }
  async componentDidMount() {
    let listing = this.props.tempListingArray.filter(
      (todo) => todo.rentalEntity.id === this.props.data.rentalEntityId
    );
    try {
      if (listing.length === 0) {
        await this.getProps();
      } else {
        this.setState({ listingData: listing[0] });
        this.setState({
          failFetching: false,
        });
        await this.getProps();
      }
      let tenantByContract = this.props.tempTenantByContractArray.filter(
        (todo) => todo.id === this.state.contractData.contractId
      );
      if (tenantByContract.length === 0) {
        this.getTenant();
      } else {
        this.setState({ tenantStatus: tenantByContract[0].contract });
      }
      getRentalListsImages(this.props.data.rentalEntityId).then(
        (responseImage) => {
          if (responseImage.status === 200) {
            if (responseImage.data.length !== 0) {
              this.setState({
                listImage:
                  url +
                  "api/property/" +
                  this.props.data.rentalEntityId +
                  "/" +
                  responseImage.data[0] +
                  "/image/downloadByName",
              });
            }
          } else {
            console.log("Error in processing.Try again later!");
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
    setTimeout(
      function () {
        if (this.props.match.params.id) {
          if (
            parseInt(this.props.match.params.id) ===
            parseInt(this.props.data.contractId)
          ) {
            var elementTarget = document.getElementById(
              "contract_" + this.props.match.params.id
            );
            if (elementTarget !== null) {
              window.scrollTo(0, elementTarget.offsetTop - 100);
            }
          }
        }
      }.bind(this),
      1000
    );
  }

  async rejectContract(modalData) {
    let updated = modalData;
    modalData.status = "CLOSE";
    try {
      await deleteContracts(modalData.contractId).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.setState((prevState) => {
              let newData = prevState.contractData;
              Object.assign(newData, updated);
              return { contractData: newData };
            });
            this.props.appendAlert("Contract Rejected", "success");
          } else {
            this.props.appendAlert("Error in rejection", "danger");
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return this.state.failFetching ? (
      <></>
    ) : this.state.listingData.length === 0 ? (
      <div className="p-5" style={{ marginTop: "10px" }}>
        <Loader3 />
      </div>
    ) : (
      this.state.contractData && (
        <>
          <div
            className="contractContainer "
            id={"contract_" + this.state.contractData.contractId}
          >
            <div
              className={
                this.props.isDark
                  ? "row contractCardDark shadow2"
                  : "row contractCard shadow2"
              }
            >
              <div
                className="col-2 contractListImage1"
                style={{
                  backgroundImage:
                    "url(" +
                    (this.state.listImage === ""
                      ? coverImage
                      : this.state.listImage) +
                    ")",
                  borderBottomLeftRadius: "15px",
                  borderTopLeftRadius: "15px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="col-12 contractListImage2"
                style={{
                  backgroundImage:
                    "url(" +
                    (this.state.listImage === ""
                      ? coverImage
                      : this.state.listImage) +
                    ")",
                  height: "200px",
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>

              <div className="col">
                <div className="row mt-2 pb-2 contractDivider">
                  <div className="col-md-12 contractListImage2">
                    <span
                      className="badge appStatus pull-right"
                      style={{
                        backgroundColor:
                          this.state.contractData.status === "ACTIVE"
                            ? "#2ECC71"
                            : this.state.contractData.status ===
                              "TENANT_INVITED"
                            ? "#2ECC71"
                            : this.state.contractData.status === "PENDING"
                            ? "#F4D03F"
                            : "#E74C3C",
                      }}
                    >
                      {this.state.contractData.status === "CLOSE"
                        ? "REJECTED"
                        : this.state.contractData.status === "CANCEL"
                        ? "CANCELED"
                        : this.state.contractData.status}
                    </span>
                  </div>
                  <div className="col-12 pl-3 ">
                    <span className="badge badge02 ">Rental</span>
                  </div>

                  <div className="col-md-2 col-lg-2 contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Unit
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.listingData.rentalEntity &&
                        (this.state.listingData.rentalEntity.unitName
                          ? this.state.listingData.rentalEntity.unitName
                          : " - ")}
                    </p>
                  </div>
                  <div className="col-md-2 contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Apartment
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.listingData.rentalEntity &&
                        (this.state.listingData.rentalEntity.property.name
                          ? this.state.listingData.rentalEntity.property.name
                          : " - ")}
                    </p>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Lease Validity Period
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      <div className="dropdown">
                        <div
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-hidden="true"
                          className="pointer"
                        >
                          {/* <i className="fa fa-calendar pr-2 "></i> */}
                          <span
                            className={
                              this.props.isDark
                                ? "contractTextDark"
                                : "contractText "
                            }
                          >
                            {this.state.contractData.startDate
                              ? timeFormatHumanInteraction(
                                  this.state.contractData.startDate,
                                  this.state.contractData.endDate
                                )
                              : "-"}
                          </span>
                        </div>
                        <div
                          className="dropdown-menu pr-2 pl-2"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <i
                            className="fa fa-calendar pr-2 pl-2"
                            aria-hidden="true"
                          ></i>
                          <span>
                            {setDateFormat(this.state.contractData.startDate)}
                          </span>
                          <i
                            className="fa fa-long-arrow-right pr-2 pl-2"
                            aria-hidden="true"
                          ></i>
                          <i
                            className="fa fa-calendar pr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          <span>
                            {setDateFormat(this.state.contractData.endDate)}
                          </span>
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Address
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      {addressFormatter(this.state.listingData.address)}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 pb-2 contractDivider">
                  <div className="col-12 pl-3 ">
                    <span className="badge badge02 ">Tenant</span>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Primary
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.tenantStatus[0] ? (
                        !this.state.tenantStatus[0].userDetails ? (
                          this.state.tenantStatus[0].username
                        ) : this.state.tenantStatus[0].userDetails.firstName ===
                            "" &&
                          this.state.tenantStatus[0].userDetails.lastName ===
                            "" ? (
                          this.state.tenantStatus[0].username
                        ) : (
                          this.state.tenantStatus[0].userDetails.firstName +
                          " " +
                          this.state.tenantStatus[0].userDetails.lastName
                        )
                      ) : (
                        <Loader3 transY="40px" transX="-100px" />
                      )}
                    </p>
                  </div>
                  {this.state.tenantStatus.length > 1 && (
                    <div className="col-md-4 contractListAlign">
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Others
                      </p>
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText"
                        }
                      >
                        <div className="row">
                          <div className="col">
                            {this.state.tenantStatus[1] ? (
                              !this.state.tenantStatus[1].userDetails ? (
                                this.state.tenantStatus[1].username
                              ) : this.state.tenantStatus[1].userDetails
                                  .firstName === "" &&
                                this.state.tenantStatus[1].userDetails
                                  .lastName === "" ? (
                                this.state.tenantStatus[1].username
                              ) : (
                                this.state.tenantStatus[1].userDetails
                                  .firstName +
                                " " +
                                this.state.tenantStatus[1].userDetails.lastName
                              )
                            ) : (
                              <Loader3 transY="40px" transX="-100px" />
                            )}
                            {this.state.tenantStatus[1].agreed ? (
                              <i
                                className="fa fa-check-circle text-success pl-2"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <>
                                <i
                                  className="fa fa-exclamation-circle text-danger ml-2"
                                  aria-hidden="true"
                                ></i>
                              </>
                            )}
                          </div>
                          <div className="col-2">
                            <div className="dropdown show pull-right">
                              <span
                                className="badge bg-gray dropdown-toggle pointer p-2 pb-1"
                                id="dropdownChangeEmail2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-pencil-square-o "
                                  aria-hidden="true"
                                ></i>{" "}
                              </span>
                              <div
                                className="dropdown-menu   profileMenu navProfile pr-3 pl-3 shadow"
                                aria-labelledby="dropdownChangeEmail2"
                              >
                                <ApplicationInput
                                  name="Email"
                                  target="tenant2Email"
                                  type="email"
                                  onChange={this.onchange}
                                  important="true"
                                  errorMessage="the email "
                                  validate={this.checkValidate(
                                    this.state.tenant2Email
                                  )}
                                  value={this.state.tenant2Email}
                                />

                                <button
                                  className=" mt-3 mb-3 buttonPri pull-right"
                                  onClick={this.changeMethod}
                                >
                                  Change Email
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p>
                      {this.state.tenantStatus.length > 2 && (
                        <p
                          className={
                            this.props.isDark
                              ? "contractTextDark"
                              : "contractText"
                          }
                        >
                          <div className="row">
                            <div className="col">
                              {this.state.tenantStatus[2] ? (
                                !this.state.tenantStatus[2].userDetails ? (
                                  this.state.tenantStatus[2].username
                                ) : this.state.tenantStatus[2].userDetails
                                    .firstName === "" &&
                                  this.state.tenantStatus[2].userDetails
                                    .lastName === "" ? (
                                  this.state.tenantStatus[2].username
                                ) : (
                                  this.state.tenantStatus[2].userDetails
                                    .firstName +
                                  " " +
                                  this.state.tenantStatus[2].userDetails
                                    .lastName
                                )
                              ) : (
                                <Loader3 transY="40px" transX="-100px" />
                              )}
                              {this.state.tenantStatus[2].agreed ? (
                                <i
                                  className="fa fa-check-circle text-success pl-2"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <>
                                  <i
                                    className="fa fa-exclamation-circle text-danger ml-2"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              )}
                            </div>
                            <div className="col-2">
                              <div className="dropdown show pull-right">
                                <span
                                  className="badge bg-gray dropdown-toggle pointer p-2 pb-1"
                                  id="dropdownChangeEmail3"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fa fa-pencil-square-o "
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                                <div
                                  className="dropdown-menu   profileMenu navProfile pr-3 pl-3 shadow"
                                  aria-labelledby="dropdownChangeEmail3"
                                >
                                  <ApplicationInput
                                    name="Email"
                                    target="tenant3Email"
                                    type="email"
                                    onChange={this.onchange}
                                    important="true"
                                    errorMessage="the email "
                                    validate={this.checkValidate(
                                      this.state.tenant3Email
                                    )}
                                    value={this.state.tenant3Email}
                                  />

                                  <button
                                    className=" mt-3 mb-3 buttonPri pull-right"
                                    onClick={this.changeMethod}
                                  >
                                    Change Email
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                      )}
                    </div>
                  )}
                  <div className="col-md-2 contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Payment Plan
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      <div className="row">
                        <div className="col-7">
                          {" "}
                          {this.state.contractData.subscription
                            ? "Auto Pay"
                            : "One Time Pay"}
                        </div>
                      </div>
                    </p>
                  </div>
                  {this.state.contractData.isKeyDeposit && (
                    <div className="col-md-2 contractListAlign">
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Key Deposit
                      </p>
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                      >
                        <CurrencyFormat
                          value={this.state.contractData.keyDeposit}
                        />
                      </p>
                    </div>
                  )}

                  {this.state.contractData.rentDiscount !== 0 && (
                    <div className="col-md-3 contractListAlign">
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Rent Discount
                      </p>
                      <p
                        className={
                          this.props.isDark
                            ? "contractTextDark"
                            : "contractText "
                        }
                      >
                        {this.state.contractData.rentDiscount}
                        <i
                          className="fa fa-percent pl-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  )}
                  <div className="col-md contractListAlign">
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Total Rent
                    </p>
                    <p
                      className={
                        this.props.isDark ? "contractTextDark" : "contractText "
                      }
                    >
                      <CurrencyFormat value={this.state.contractData.rent} />
                      {/* {(
                        this.state.contractData.otherRentFee1 +
                        this.state.contractData.otherRentFee2 +
                        this.state.contractData.otherRentFee3 +
                        this.state.contractData.parkingFee +
                        this.state.contractData.petAmount +
                        (this.state.contractData.rentDiscount !== 0
                          ? (this.state.contractData.rent *
                              (100 - this.state.contractData.rentDiscount)) /
                            100
                          : this.state.contractData.rent)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })} */}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-3">
                  <div className="col-md-2 contractListImage1">
                    <span
                      className="badge appStatus  mt-3 "
                      style={{
                        backgroundColor:
                          this.state.contractData.status === "ACTIVE"
                            ? "#2ECC71"
                            : this.state.contractData.status ===
                              "TENANT_INVITED"
                            ? "#2ECC71"
                            : this.state.contractData.status === "PENDING"
                            ? "#F4D03F"
                            : "#E74C3C",
                      }}
                    >
                      {this.state.contractData.status === "CLOSE"
                        ? "REJECTED"
                        : this.state.contractData.status === "CANCEL"
                        ? "CANCELED"
                        : this.state.contractData.status}
                    </span>
                  </div>
                  <div className="col-md">
                    <button
                      className={
                        (this.props.isDark ? "agreementDark" : "agreement") +
                        " pull-right mr-3 mt-3"
                      }
                      data-placement="top"
                      title="Files"
                      onClick={() => {
                        this.props.viewFiles(this.props.data);
                      }}
                      data-target="#filesView"
                      data-toggle="modal"
                    >
                      <i
                        className="fa fa-folder  mr-2 ml-2"
                        aria-hidden="true"
                      ></i>
                      Files
                    </button>
                    {this.state.contractData.agreementPDF &&
                      (this.state.contractData.agreementPDF ===
                      "OWN_AGREEMENT_PENDING" ? (
                        <button
                          className={
                            this.props.pdfButtonDisable
                              ? "secDisable notAllow pull-right mr-3 mt-3"
                              : (this.props.dark
                                  ? "agreementDark"
                                  : "agreement") + "  pull-right mr-3 mt-3 "
                          }
                          data-placement="top"
                          title="Contract Agreement"
                          onClick={() => {
                            this.props.viewUploadAgreement(this.props.data);
                          }}
                          data-target="#uploadAgreement"
                          data-toggle="modal"
                          disabled={this.props.pdfButtonDisable}
                        >
                          <i
                            className="fa fa-upload  mr-2 ml-2"
                            aria-hidden="true"
                          ></i>
                          Agreement
                          {this.props.pdfButtonDisable && (
                            <Loader3 transY="47px" transX="0px" />
                          )}
                        </button>
                      ) : (
                        <button
                          className={
                            (this.props.isDark
                              ? "agreementDark"
                              : "agreement") + " pull-right mr-3 mt-3"
                          }
                          data-placement="top"
                          title="Contract Agreement"
                          onClick={() => {
                            this.props.viewContract(
                              this.props.data,
                              "contractAgreement"
                            );
                          }}
                          data-target="#exampleModal"
                          data-toggle="modal"
                        >
                          <i
                            className="fa fa-list-alt  mr-2 ml-2"
                            aria-hidden="true"
                          ></i>
                          Agreement
                        </button>
                      ))}
                    {process.env.REACT_APP_STATUS !== "Production" &&
                      new Date(this.state.contractData.endDate) - new Date() >
                        0 && (
                        <button
                          className={
                            (this.props.dark ? "agreementDark" : "agreement") +
                            "  pull-right  mr-3 mt-3"
                          }
                          data-placement="top"
                          title="Manage Maintenance"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/maintenance",
                              state: {
                                contractId: this.state.contractData.contractId,
                              },
                            })
                          }
                        >
                          <i
                            className="fa fa-fire-extinguisher pl-2 pr-2 "
                            aria-hidden="true"
                          ></i>{" "}
                          Maintenance
                          {this.props.maintenanceArray !== null &&
                            this.props.maintenanceArray.filter(
                              (data) =>
                                parseInt(this.state.contractData.contractId) ===
                                  parseInt(data.contractId) &&
                                data.landlordApprovel === "pending"
                            ).length > 0 && (
                              <span className="badge badge-light round  pointer ml-3">
                                {
                                  this.props.maintenanceArray.filter(
                                    (data) =>
                                      parseInt(
                                        this.state.contractData.contractId
                                      ) === parseInt(data.contractId) &&
                                      data.landlordApprovel === "pending"
                                  ).length
                                }
                              </span>
                            )}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    isDark: state.isDark,
    dark: state.darkReducer,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    tempTenantByContract: state.tempTenantByContractReducer,
    tempTenantByContractArray: state.tempTenantByContract,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempListingData,
    appendTempTenantByContract,
  })(ContractListLandlord)
);
