import React, { Component } from "react";
import ApplicationInput from "../../../../UIServices/ApplicationInput";
import "../../contracts.css";
import "../../../Application/application.css";
import { withRouter } from "react-router-dom";
import {
  createContract,
  getLandlordContracts,
  userListDetail,
} from "../../../../../Services/ApiServices";
import Loader from "../../../../Loader/Loader";
import {
  hundredNumberArray,
  monthNumberArray,
  weekNumberArray,
} from "../../../../../Services/JsonData";
import TorontoReview from "../../Review/TrontoReview";
import InfoReview from "../../Review/InfoReview";
import {
  canadaContractStep1Verification,
  canadaContractStep2Verification,
} from "../../../../../Defined/AppDivideFunction";
import { today } from "../../../../../Defined/Function";
import CookieService from "../../../../../Services/CookieService";
import { connect } from "react-redux";
import { appendAlert, setContract } from "../../../../../actions";
class CreateContractOntario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      nowTab: 1,
      next: false,
      tabCheck: [false, false, false, false],
      footer: true,
      pushFinished: false,
      applicationData: this.props.location.state
        ? this.props.location.state.applicationData
        : null,
      applicantData: [],
      propertyData: this.props.location.state
        ? this.props.location.state.propertyData
        : null,
      userDetails: CookieService.getArrayCookie("axUserDetails"),
      type: "",
      isLoading: true,
      activeStep: 0,

      startDate: "",
      endDate: "",
      agreementType: "",
      otherAgreementType: "",
      smoking: this.props.location.state
        ? this.props.location.state.propertyData.rentalEntity.indoorSmoking
        : false,
      smokingDescription: "",
      parkingAcce: "",
      parkingDescription: "",

      condominium: false,
      // condominiumRules: "",
      emailOption: "",
      documentEmails: "",
      documentEmails2: "",
      landlordEmergencyContactOption: "",
      landlordEmergencyContact: "",

      rentCollectionDay: "1",
      rentCollectionOnEach: "month",
      rentCollectionOnEachOther: "",
      rent: this.props.location.state
        ? this.props.location.state.propertyData.rentalEntity.rent
        : null,
      parkingRent: this.props.location.state
        ? this.props.location.state.propertyData.rentalEntity.parkingFee
        : null,

      isRentDiscount: false,
      rentDiscountType: "percentage",
      rentDiscountDescription: "",
      rentDiscountPercentage: "",
      rentDiscountAmount: "",

      securityDeposit: false,
      securityDepositAmount: "",
      keyDeposit: false,
      keyDepositAmount: "",
      keyDepositDescription: "",
      isPartialRent: false,
      partialRent: "",
      partialRentDate: "",
      partialRentFrom: "",
      partialRentTo: "",
      isLateFee: false,
      lateFeeAmount: "",
      isAdditionalTerms: false,
      additionalTermsDoc: "",

      rentType: [],
      rentAmount: [],
      rentPayableTo:
        CookieService.getArrayCookie("axUserDetails").firstName +
        " " +
        CookieService.getArrayCookie("axUserDetails").lastName,
      rentPayableMethod: "",

      electricityResponsible: "landlord",
      heatResponsible: "landlord",
      waterResponsible: "landlord",
      tenantInsurance: false,
      otherResponsible: "",
      // utilityType: [],
      // utilityResponsibleFor: [],

      airConditioner: this.props.location.state
        ? this.props.location.state.propertyData.rentalEntity.airConditioner
        : false,
      gas: false,
      additionalStorage: false,
      laundry: false,
      laundryChargeType: "nocharge",
      guestParking: false,
      guestParkingChargeType: "nocharge",
      serviceType: [],
      otherServiceDescription: "",
    };
    this.onchange = this.onchange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.showButton = this.showButton.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  showButton() {
    this.setState({ footer: true });
  }
  async handleFinish() {
    if (this.checkTab()) {
      try {
        this.setState({ pushFinished: true });
        this.setState({ footer: false });
        let tenant = [];
        if (this.props.location.state.applicationData.apply[0]) {
          tenant.push({
            username: this.props.location.state
              ? this.props.location.state.applicationData.apply[0].applicant
                  .username
              : null,
            tenantOrder: 1,
          });
        }
        if (this.props.location.state.applicationData.apply[1]) {
          tenant.push({
            username: this.props.location.state
              ? this.props.location.state.applicationData.apply[1].applicant
                  .username
              : null,
            tenantOrder: 2,
          });
        }
        if (this.props.location.state.applicationData.apply[2]) {
          tenant.push({
            username: this.props.location.state
              ? this.props.location.state.applicationData.apply[2].applicant
                  .username
              : null,
            tenantOrder: 3,
          });
        }
        let body = {
          tenants: tenant,
          rentalEntityId: this.props.location.state
            ? this.props.location.state.applicationData.rentalEntityId
            : null,
          applicationId: this.props.location.state
            ? this.props.location.state.applicationData.id
            : null,

          agreementType:
            this.state.agreementType !== "" ? this.state.agreementType : null,
          otherAgreementType:
            this.state.otherAgreementType !== ""
              ? this.state.otherAgreementType
              : null,
          startDate: this.state.startDate !== "" ? this.state.startDate : null,
          endDate: this.state.endDate !== "" ? this.state.endDate : null,
          rent: this.state.rent !== "" ? this.state.rent : null,
          rentCollectionDay:
            this.state.rentCollectionDay !== ""
              ? this.state.rentCollectionDay
              : null,
          rentPaidOnPeriod:
            this.state.rentCollectionOnEach !== "other"
              ? this.state.rentCollectionOnEach
              : this.state.rentCollectionOnEachOther,
          isSecurityDeposit: this.state.securityDeposit,
          securityDeposit:
            this.state.securityDeposit &&
            this.state.securityDepositAmount !== ""
              ? this.state.securityDepositAmount
              : null,
          isKeyDeposit: this.state.keyDeposit,
          keyDeposit:
            this.state.keyDeposit && this.state.keyDepositAmount !== ""
              ? this.state.keyDepositAmount
              : null,
          keyDepositDescription: this.state.keyDepositDescription,
          moveInCheckList: null,
          moveOutCheckList: null,
          status: this.props.location.state
            ? this.props.location.state.applicationData.status
            : null,
          parkingAcce: this.state.parkingAcce,
          parkingDescription: this.state.parkingDescription,
          condominium: this.state.condominium,
          condominiumLink: null,
          indoorSmoking: this.state.smoking,
          indoorVaping: false,
          smokingDescription: this.state.smokingDescription,
          emailOption: this.state.emailOption,
          documentEmails:
            this.state.documentEmails + "," + this.state.documentEmails2,
          landlordEmergencyContactOption:
            this.state.landlordEmergencyContactOption,
          landlordEmergencyContact: this.state.landlordEmergencyContact,
          parkingFee: this.state.parkingRent ? this.state.parkingRent : 0.0,
          otherRentFee1Name: this.state.rentType[0]
            ? this.state.rentType[0]
            : null,
          otherRentFee1: this.state.rentAmount[0]
            ? this.state.rentAmount[0]
            : 0.0,
          otherRentFee2Name: this.state.rentType[1]
            ? this.state.rentType[1]
            : null,
          otherRentFee2: this.state.rentAmount[1]
            ? this.state.rentAmount[1]
            : 0.0,
          otherRentFee3Name: this.state.rentType[2]
            ? this.state.rentType[2]
            : null,
          otherRentFee3: this.state.rentAmount[2]
            ? this.state.rentAmount[2]
            : 0.0,
          isRentDiscount: this.state.isRentDiscount,
          rentDiscount: this.state.isRentDiscount
            ? this.state.rentDiscountType === "percentage"
              ? this.state.rentDiscountPercentage
              : this.state.rentDiscountAmount
            : 0.0,
          rentDiscountType: this.state.rentDiscountType,
          rentDiscountDescription: this.state.rentDiscountDescription,
          rentPayableTo: this.state.rentPayableTo,
          rentPayableMethod: this.state.rentPayableMethod,
          electricityResponsible: this.state.electricityResponsible,
          heatResponsible: this.state.heatResponsible,
          waterResponsible: this.state.waterResponsible,
          otherResponsible: this.state.otherResponsible,
          isTenantInsurance: this.state.tenantInsurance,
          airConditioner: this.state.airConditioner,
          gas: this.state.gas,
          storage: this.state.additionalStorage,
          laundry: this.state.laundry,
          laundryChargeType: this.state.laundryChargeType,
          guestParking: this.state.guestParking,
          guestParkingType: this.state.guestParkingChargeType,
          serviceType1: this.state.serviceType[0]
            ? this.state.serviceType[0]
            : "",
          serviceType2: this.state.serviceType[1]
            ? this.state.serviceType[1]
            : "",
          serviceType3: this.state.serviceType[2]
            ? this.state.serviceType[2]
            : "",
          serviceDescription: this.state.otherServiceDescription,
          subscription: false,

          isPartialRent: this.state.isPartialRent,
          partialRent: this.state.partialRent,
          partialRentDate: this.state.partialRentDate,
          partialRentFrom: this.state.partialRentFrom,
          partialRentTo: this.state.partialRentTo,
          islateFee: this.state.isLateFee,
          lateFeeAmount:
            this.state.lateFeeAmount === "" ? this.state.lateFeeAmount : 0.0,
          isAdditionalTerms: this.state.isAdditionalTerms,
          additionalTermsDoc: this.state.additionalTermsDoc,
          landLordId: "",
        };

        createContract(body)
          .then((response) => {
            if (response.status === 200) {
              this.props.history.push("/contracts");
              this.setState({ pushFinished: false });
              this.setState({ footer: true });
              if (response.data.status) {
                this.props.appendAlert(
                  "Lease created successfully!",
                  "success"
                );
              } else {
                this.props.appendAlert("Something went wrong!", "danger");
              }
              try {
                getLandlordContracts().then((response1) => {
                  if (response1.status === 200) {
                    if (response1.data.length !== 0) {
                      this.props.setContract(
                        response1.data.sort((a, b) =>
                          b.updatedAt.localeCompare(a.updatedAt)
                        )
                      );
                    } else {
                      this.props.setContract([]);
                    }
                  }
                });
              } catch (error) {
                console.error(error);
              }
            } else {
              this.props.appendAlert(
                "Something went wrong!, Please try again later.",
                "danger"
              );
              this.setState({ pushFinished: false });
              this.setState({ footer: true });
            }
          })
          .catch(() => {
            this.props.appendAlert("Something went wrong!", "danger");
            this.setState({ pushFinished: false });
            this.setState({ footer: true });
          });
      } catch (error) {
        this.setState({ pushFinished: false });
        this.setState({ footer: true });
      }
    }
  }
  checkTab() {
    if (this.state.tabCheck[this.state.nowTab - 1] || this.state.nowTab === 1) {
      return true;
    } else {
      for (let i = this.state.nowTab - 1; i > 0; i--) {
        if (this.state.tabCheck[i]) {
          this.props.appendAlert("Please fill correct order", "warning");
          this.setState({ nowTab: i + 1 });
          break;
        }
        if (i === 1) {
          this.props.appendAlert("Please fill correct order", "warning");
          this.setState({ nowTab: 1 });
          this.setState({ next: false });
          break;
        }
      }
    }
  }
  clickStep(step) {
    if (this.state.nowTab !== step) {
      if (this.state.tabCheck[step]) {
        this.setState({ nowTab: step });
      } else {
        if (this.state.tabCheck[this.state.nowTab]) {
          if (this.state.nowTab + 1 === step) {
            this.next();
          } else {
            this.setState({ nowTab: this.state.nowTab + 1 });
            this.props.appendAlert(
              "Please fill correct order, Your next step is " +
                (this.state.nowTab + 1),
              "warning"
            );
          }
        } else {
          if (this.next()) {
            if (this.state.nowTab + 1 !== step) {
              this.props.appendAlert(
                "Please fill correct order, Your next step is " +
                  (this.state.nowTab + 1),
                "warning"
              );
            }
          } else {
            this.props.appendAlert("Please complete current step", "danger");
          }
        }
      }
    }
  }
  next() {
    this.setState({ next: true });
    if (this.state.nowTab < 5) {
      if (this.state.nowTab === 1) {
        window.scrollTo(0, 0);
        if (canadaContractStep1Verification(this.state)) {
          if (this.checkTab()) {
            let newArray = this.state.tabCheck;
            newArray[this.state.nowTab] = true;
            this.setState({
              next: false,
              nowTab: this.state.nowTab + 1,
              tabCheck: newArray,
            });
            return true;
          }
        }
      } else if (this.state.nowTab === 2) {
        window.scrollTo(0, 0);
        if (canadaContractStep2Verification(this.state)) {
          if (this.checkTab()) {
            let newArray = this.state.tabCheck;
            newArray[this.state.nowTab] = true;
            this.setState({
              next: false,
              nowTab: this.state.nowTab + 1,
              tabCheck: newArray,
            });
            return true;
          }
        }
      } else if (this.state.nowTab === 3) {
        window.scrollTo(0, 0);
        let x = false;
        if (this.state.serviceType.length > 0) {
          if (this.state.serviceType[0] !== "") {
            if (this.state.serviceType.length > 1) {
              if (this.state.serviceType[1] !== "") {
                if (this.state.serviceType.length > 2) {
                  if (this.state.serviceType[2] !== "") {
                    x = true;
                  }
                } else {
                  x = true;
                }
              }
            } else {
              x = true;
            }
          }
        } else {
          x = true;
        }
        if (x) {
          if (this.checkTab()) {
            let newArray = this.state.tabCheck;
            newArray[this.state.nowTab] = true;
            this.setState({
              next: false,
              nowTab: this.state.nowTab + 1,
              tabCheck: newArray,
            });
            return true;
          }
        }
      }
    }
    return false;
  }
  checkEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      (!re.test(String(value).toLowerCase()) || value === "") && this.state.next
    );
  }

  checkMobile(value) {
    return (value.length !== 14 || value === "") && this.state.next;
  }
  checkSIN(value) {
    return (value.length !== 11 || value === "") && this.state.next;
  }
  checkAccountNo(value) {
    return (isNaN(value) || value === "") && this.state.next;
  }
  back() {
    if (this.state.nowTab > 1) {
      window.scrollTo(0, 0);
      this.setState({ nowTab: this.state.nowTab - 1 });
    }
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  onChangeDate(name, value) {
    this.setState({ [name]: value });
  }
  async onchange(e) {
    let { name, value, id } = e.target;
    if (name === "rentType") {
      let x = this.state.rentType;
      x[id] = value;
      await this.setState({ [name]: x });
    } else if (name === "rentAmount") {
      let x = this.state.rentAmount;
      x[id] = value;
      await this.setState({ [name]: x });
    } else if (name === "serviceType") {
      let x = this.state.serviceType;
      x[id] = value;
      await this.setState({ [name]: x });
    } else if (name === "utilityType") {
      let x = this.state.utilityType;
      x[id] = value;
      await this.setState({ [name]: x });
    } else if (
      name === "rent" ||
      name === "parkingRent" ||
      name === "rentDiscountAmount" ||
      name === "securityDepositAmount" ||
      name === "keyDepositAmount" ||
      name === "partialRent" ||
      name === "lateFeeAmount"
    ) {
      let num = value.replace(/[^\d]/g, "");
      value = num;
      await this.setState({ [name]: value });
    } else {
      await this.setState({ [name]: value });
    }
  }
  async onchangeCheck(e) {
    let { name, checked, id } = e.target;
    if (name === "utilityResponsibleFor") {
      let x = this.state.utilityResponsibleFor;
      x[id] = checked;
      await this.setState({ [name]: x });
    } else if (name === "smoking") {
      if (!checked) {
        await this.setState({ [name]: checked, smokingDescription: "" });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "parkingAcce") {
      if (!checked) {
        await this.setState({ [name]: checked, parkingDescription: "" });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "emailOption") {
      if (!checked) {
        await this.setState({
          [name]: checked,
          documentEmails: "",
          documentEmails2: "",
        });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "landlordEmergencyContactOption") {
      if (!checked) {
        await this.setState({ [name]: checked, landlordEmergencyContact: "" });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "isRentDiscount") {
      if (!checked) {
        await this.setState({
          [name]: checked,
          rentDiscountAmount: "",
          rentDiscountType: "",
          rentDiscountDescription: "",
          rentDiscountPercentage: "",
        });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "securityDeposit") {
      if (!checked) {
        await this.setState({ [name]: checked, securityDepositAmount: "" });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "keyDeposit") {
      if (!checked) {
        await this.setState({
          [name]: checked,
          keyDepositAmount: "",
          keyDepositDescription: "",
        });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "isPartialRent") {
      if (!checked) {
        await this.setState({
          [name]: checked,
          partialRent: "",
          partialRentDate: "",
          partialRentTo: "",
          partialRentFrom: "",
        });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "isLateFee") {
      if (!checked) {
        await this.setState({ [name]: checked, lateFeeAmount: "" });
      } else {
        await this.setState({ [name]: checked });
      }
    } else if (name === "isAdditionalTerms") {
      if (!checked) {
        await this.setState({ [name]: checked, additionalTermsDoc: "" });
      } else {
        await this.setState({ [name]: checked });
      }
    } else {
      await this.setState({ [name]: checked });
    }
  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };
  componentDidMount() {
    window.onbeforeunload = this.beforeUnloadListener;
    if (!this.props.location.state) {
      this.props.history.push({
        pathname: "/applications",
      });
    } else {
      try {
        let userList = [];
        if (this.props.location.state.applicationData.apply[0]) {
          userList.push(
            this.props.location.state.applicationData.apply[0].applicant
              .username
          );
        }
        if (this.props.location.state.applicationData.apply[1]) {
          userList.push(
            this.props.location.state.applicationData.apply[1].applicant
              .username
          );
        }
        if (this.props.location.state.applicationData.apply[2]) {
          userList.push(
            this.props.location.state.applicationData.apply[2].applicant
              .username
          );
        }
        userListDetail(userList).then((response) => {
          if (response.status === 200) {
            this.setState({ applicantData: response.data });
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      } catch (error) {}
    }
  }
  addRent() {
    let addAmount = this.state.rentAmount;
    let addRent = this.state.rentType;
    addRent.push("");
    addAmount.push("");
    this.setState({ rentType: addRent, rentAmount: addAmount });
  }
  removeRent(index) {
    let removedTypeArray = this.state.rentType;
    let removedAmountArray = this.state.rentAmount;
    removedTypeArray.splice(index, 1);
    removedAmountArray.splice(index, 1);
    this.setState({
      rentType: removedTypeArray,
      rentAmount: removedAmountArray,
    });
  }
  addService() {
    let addService = this.state.serviceType;
    addService.push("");
    this.setState({ serviceType: addService });
  }
  removeService(index) {
    let removedTypeArray = this.state.serviceType;

    removedTypeArray.splice(index, 1);
    this.setState({
      serviceType: removedTypeArray,
    });
  }
  addUtility() {
    let addUtility = this.state.utilityType;
    let addUtilityOption = this.state.utilityResponsibleFor;
    addUtility.push("");
    addUtilityOption.push(false);
    this.setState({
      utilityType: addUtility,
      utilityResponsibleFor: addUtilityOption,
    });
  }
  removeUtility(index) {
    let removedTypeArray = this.state.utilityType;
    let removedOptionArray = this.state.utilityResponsibleFor;
    removedTypeArray.splice(index, 1);
    removedOptionArray.splice(index, 1);
    this.setState({
      utilityType: removedTypeArray,
      utilityResponsibleFor: removedOptionArray,
    });
  }
  render() {
    return (
      <>
        <div
          className="container-fluid"
          style={{ minHeight: window.screen.height }}
        >
          <div className="row stepFormNav">
            <div className="col text-center ">
              <div className="row">
                <div className="col SideLineHide"></div>
                <div className="col pointer" onClick={() => this.clickStep(1)}>
                  <div className=" social-container-step activeStep">
                    <div className="social ">
                      <i
                        className="fa fa-address-card-o "
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 1</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 1
                      ? "col  SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 1
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col pointer" onClick={() => this.clickStep(2)}>
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-home " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 2</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 2
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>

            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 2
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col pointer" onClick={() => this.clickStep(4)}>
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social">
                      <i className="fa fa-wrench " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 3</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 3
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 3
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col pointer" onClick={() => this.clickStep(5)}>
                  <div
                    className={
                      this.state.nowTab > 3
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social">
                      <i className="fa fa-retweet " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 4</span>
                  <br></br>
                </div>
                <div className="col SideLineHide"></div>
              </div>
            </div>
          </div>

          <div className="row stepFormNavTitle ">
            <div
              className="col d-flex justify-content-center pointer"
              onClick={() => this.clickStep(1)}
            >
              {" "}
              <p className="stepTitle">Basic</p>
            </div>
            <div
              className="col d-flex justify-content-center pointer"
              onClick={() => this.clickStep(2)}
            >
              {" "}
              <p className="stepTitle ">Rental</p>
            </div>

            <div
              className="col d-flex justify-content-center pointer"
              onClick={() => this.clickStep(3)}
            >
              {" "}
              <p className="stepTitle">Service</p>
            </div>
            <div
              className="col d-flex justify-content-center pointer"
              onClick={() => this.clickStep(4)}
            >
              {" "}
              <p className="stepTitle">Review</p>
            </div>
          </div>
          {this.state.nowTab === 1 && (
            <>
              <div className="cardApplication">
                <div className="row inputContainer1  pt-4">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Lease Start Date"
                      type="date"
                      target="startDate"
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage="the lease start date"
                      validate={this.checkValidate(this.state.startDate)}
                      value={this.state.startDate}
                      min={today()}
                      max={this.state.endDate}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Agreement Type"
                      target="agreementType"
                      type="optional"
                      options={["Fixed", "Monthly", "Other"]}
                      optionValue={["fixed", "monthly", "other"]}
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the agreement type"
                      validate={this.checkValidate(this.state.agreementType)}
                      value={this.state.agreementType}
                    />
                  </div>
                  {this.state.agreementType === "other" && (
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Specify Other Type"
                        type="textarea"
                        target="otherAgreementType"
                        rows={3}
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the other agreement type"
                        validate={this.checkValidate(
                          this.state.otherAgreementType
                        )}
                        value={this.state.otherAgreementType}
                      />
                    </div>
                  )}
                  {this.state.agreementType === "fixed" && (
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Lease End Date"
                        type="date"
                        target="endDate"
                        onChange={this.onChangeDate}
                        important="true"
                        errorMessage="the lease end date"
                        validate={this.checkValidate(this.state.endDate)}
                        value={this.state.endDate}
                        min={
                          this.state.startDate === ""
                            ? today()
                            : this.state.startDate
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="row inputContainer1 mb-3">
                  <div className="col ">
                    <ApplicationInput
                      name="Note:"
                      type="info"
                      value={
                        <>
                          <div className=" torontoagreementTableBody pb-3 ">
                            <p className="m-1">
                              The tenant does not have to move out at the end of
                              the term.
                            </p>
                            <div className="row torontoagreementTitleBar p-0 m-0">
                              <div className="col p-0 m-0">
                                <p className="pl-2 m-1">
                                  <b>C. Renewing a Tenancy Agreement </b> (Part
                                  V of the Act)
                                </p>
                              </div>
                            </div>

                            <div className="col pt-2 p-0 m-0">
                              <span className="span7 ">
                                If the landlord and tenant agree that the
                                tenancy will last for a specific period of time,
                                this is called a fixed term tenancy. This is
                                because both the start and end date are set out
                                in the tenancy agreement. <br></br>
                                The end of an agreement does not mean the tenant
                                has to move out or sign a renewal or new
                                agreement in order to stay. The rules of the
                                agreement will still apply and the tenant still
                                has the right to stay:
                              </span>
                              <div className="col pt-2">
                                <span className="span7 ul p-0 m-0">
                                  <li className="p-2">
                                    as a monthly tenant, if the agreement was
                                    for a fixed term or monthly tenancy,{" "}
                                    <br></br>
                                  </li>
                                  <li className="p-2">
                                    as a weekly tenant, if the agreement was for
                                    a weekly tenancy, or <br></br>
                                  </li>

                                  <li className="p-2">
                                    as a daily tenant, if the agreement was for
                                    a daily tenancy.
                                  </li>
                                </span>
                              </div>
                              <span className="span7 ">
                                The landlord and tenant can also agree to renew
                                the agreement for another fixed term or enter
                                into a new agreement. In any case, changes to
                                the rent must follow the rules under the Act
                                (see Part I below for further information).
                              </span>
                            </div>
                          </div>
                          <div className=" torontoagreementTableBody pb-3 ">
                            <div className="row torontoagreementTitleBar p-0 m-0">
                              <div className="col p-0 m-0">
                                <p className="pl-2 m-1">
                                  <b>D. Ending the Tenancy </b>(Part V of the
                                  Act){" "}
                                </p>
                              </div>
                            </div>

                            <div className="col pt-2 p-0 m-0">
                              <span className="span7 ">
                                The landlord or tenant must follow the rules of
                                the Act when ending a tenancy. <br></br>
                                <b>When the tenant can end the tenancy </b>{" "}
                                <br></br>
                                he tenant can end a tenancy by giving the
                                landlord proper notice using the appropriate
                                Landlord and Tenant Board form. They must give:
                              </span>
                              <div className="col pt-2">
                                <span className="span7 ul p-0 m-0">
                                  <li className="p-2">
                                    at least 60 days’ notice if they have a
                                    monthly or fixed term tenancy, or <br></br>
                                  </li>
                                  <li className="p-2">
                                    at least 28 days’ notice if they have a
                                    daily or weekly tenancy.
                                  </li>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      target="rentalInfo"
                      titleBreck="true"
                    />
                  </div>
                </div>
                <div className="row inputContainer1">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Smoking"
                      type="optionYN"
                      target="smoking"
                      onChange={this.onchangeCheck}
                      important="true"
                      info={
                        <>
                          <b>Note:</b>
                          <br />
                          <span>
                            In making and enforcing smoking rules, the landlord
                            must follow the Ontario Human Rights Code.
                          </span>
                          <br />
                          <div className=" torontoagreementTableBody pb-3 ">
                            <div className="row torontoagreementTitleBar p-0 m-0">
                              <div className="col p-0 m-0">
                                <p className="pl-2 m-1">
                                  {" "}
                                  <b>M. Discrimination </b>
                                </p>
                              </div>
                            </div>

                            <div className="col pt-2 p-0 m-0">
                              <span className="span7 ">
                                f the landlord (or anyone acting for the
                                landlord) discriminates against the tenant based
                                on prohibited grounds of discrimination under
                                the Ontario Human Rights Code (the Code), they
                                may be violating the tenant’s rights under the
                                Code. The Landlord and Tenant Board may be able
                                to consider discrimination if it relates to an
                                application under the Residential Tenancies Act,
                                2006. In other situations, the tenant may have
                                to take their case to the Human Rights Tribunal
                                of Ontario.
                              </span>
                            </div>
                          </div>
                          <div className=" torontoagreementTableBody pb-3 ">
                            <div className="row torontoagreementTitleBar p-0 m-0">
                              <div className="col p-0 m-0">
                                <p className="pl-2 m-1">
                                  {" "}
                                  <b>S. Smoking </b> (Part V of the Act)
                                </p>
                              </div>
                            </div>

                            <div className="col pt-2 p-0 m-0">
                              <span className="span7  ">
                                The Act does not discuss smoking in a rental
                                unit. The landlord and tenant can use Section 10
                                of this lease to agree to either allow or
                                prohibit smoking in the unit, and/or on the
                                landlord’s property. Even if the lease doesn’t
                                prohibit smoking, the landlord may apply to the
                                Landlord and Tenant Board to end the tenancy if
                                the smoking:
                              </span>

                              <div></div>

                              <span className="span7  ">
                                If the tenant believes that other people smoking
                                in their building affects their health or
                                safety, contravenes maintenance standards, or
                                substantially interferes with their reasonable
                                enjoyment of the rental unit, they should
                                discuss it with their landlord before contacting
                                the Landlord and Tenant Board.
                              </span>
                            </div>
                          </div>
                        </>
                      }
                      errorMessage="the smoking or not"
                      validate={this.checkValidate(this.state.smoking)}
                      value={this.state.smoking}
                    />
                  </div>
                  {this.state.smoking && (
                    <div className="col ">
                      <span className="searchListHeadApp ">
                        Smoking Rules *
                      </span>
                      <textarea
                        className={
                          this.checkValidate(this.state.smokingDescription)
                            ? "ApplicationInputAgg alert"
                            : "ApplicationInputAgg"
                        }
                        onChange={this.onchange}
                        name={"smokingDescription"}
                        rows={3}
                        value={this.state.smokingDescription}
                      ></textarea>
                      {this.checkValidate(this.state.smokingDescription) && (
                        <p className="spanValidateApp textPri">
                          Please choose the rules of smoking
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="row inputContainer1">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Parking"
                      type="optionYN"
                      target="parkingAcce"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the parking have or not"
                      validate={this.checkValidate(this.state.parkingAcce)}
                      value={this.state.parkingAcce}
                    />
                  </div>
                  {this.state.parkingAcce && (
                    <>
                      <div className="col ">
                        <span className="searchListHeadApp ">
                          Parking Description *
                        </span>
                        <textarea
                          className={
                            this.checkValidate(this.state.parkingDescription)
                              ? "ApplicationInputAgg alert"
                              : "ApplicationInputAgg"
                          }
                          onChange={this.onchange}
                          name={"parkingDescription"}
                          rows={3}
                          value={this.state.parkingDescription}
                        ></textarea>
                        {this.checkValidate(this.state.parkingDescription) && (
                          <p className="spanValidateApp textPri">
                            Please choose the parking description
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="row    inputContainer1">
                  <div className="col-12">
                    <ApplicationInput
                      name="The rental unit is a unit in a condominium."
                      type="optionYN"
                      target="condominium"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the condominium option"
                      validate={this.checkValidate(this.state.condominium)}
                      value={this.state.condominium}
                    />
                  </div>

                  {/* {this.state.condominium && (
                    <>
                      <span className="searchListHeadApp ml-3">
                        If yes, the tenant agrees to comply with the condominium
                        declaration, by-laws and rules, as provided by the
                        landlord.
                      </span>

                      <div className="col-12  ">
                        <textarea
                          className={
                            this.checkValidate(this.state.condominiumRules)
                              ? "ApplicationInputAgg alert"
                              : "ApplicationInputAgg"
                          }
                          onChange={this.onchange}
                          name={"condominiumRules"}
                          value={this.state.condominiumRules}
                        ></textarea>
                      </div>
                    </>
                  )} */}
                </div>
                <div className="row  inputContainer1">
                  <div className="col-lg-10 col-xl-12">
                    <ApplicationInput
                      name="Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant
                      Board’s Rules of Procedure."
                      type="optionYN"
                      target="emailOption"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the landlord email option"
                      validate={this.checkValidate(this.state.emailOption)}
                      info={
                        <div className=" torontoagreementTableBody pb-2   ">
                          <div className="row torontoagreementTitleBar p-0 m-0">
                            <div className="col p-0 m-0">
                              <p className="pl-2 m-1">B. Change of Landlord</p>
                            </div>
                          </div>

                          <div className="col p-0 m-0 pt-2">
                            <span className="span7 ">
                              A new landlord has the same rights and duties as
                              the previous landlord. A new landlord must follow
                              all the terms of this agreement unless the tenant
                              and new landlord agree to other terms. A new
                              landlord should provide the tenant with their
                              legal name and address
                            </span>
                          </div>

                          <div className="row torontoagreementTitleBar p-0 m-0">
                            <div className="col p-0 m-0">
                              <p className="pl-2 m-1">
                                <b>E. Giving Notices and Documents </b> (Part
                                XII of the Act)
                              </p>
                            </div>
                          </div>

                          <div className="col pt-2 p-0 m-0">
                            <span className="span7 ">
                              The landlord and tenant have to deliver some
                              official notices and other documents in writing.
                              These notices and documents can be:
                            </span>
                            <div className="col pt-2">
                              <span className="span7 ul p-0 m-0">
                                <li className="p-2">
                                  hand delivered, <br></br>
                                </li>
                                <li className="p-2">
                                  left in a mail box or a place where mail is
                                  ordinarily delivered, or <br></br>
                                </li>
                                <li className="p-2">
                                  mailed (this will count as delivered five days
                                  after mailing). <br></br>
                                </li>
                              </span>
                            </div>
                            <span className="span7 ">
                              There are also other ways to serve notices and
                              documents. For more information, contact the
                              Landlord and Tenant Board or see the Rules of
                              Practice on its website
                            </span>
                          </div>
                        </div>
                      }
                      value={this.state.emailOption}
                    />
                  </div>

                  {this.state.emailOption && (
                    <>
                      <div className="col-12">
                        <span className="searchListHeadApp ">
                          If yes, provide Landlord, Tenant email addresses:
                        </span>
                      </div>
                      <div className="col-3  ">
                        <ApplicationInput
                          name="Landlord email:"
                          type="email"
                          target="documentEmails"
                          important="true"
                          onChange={this.onchange}
                          errorMessage={
                            this.checkValidate(this.state.documentEmails)
                              ? "your email"
                              : "your email correct format"
                          }
                          validate={this.checkEmail(this.state.documentEmails)}
                          value={this.state.documentEmails}
                        />
                      </div>
                      <div className="col  ">
                        <ApplicationInput
                          name="Tenant email:"
                          type="email"
                          target="documentEmails2"
                          important="true"
                          onChange={this.onchange}
                          errorMessage={
                            this.checkValidate(this.state.documentEmails2)
                              ? "your email"
                              : "your email correct format"
                          }
                          validate={this.checkEmail(this.state.documentEmails2)}
                          value={this.state.documentEmails2}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="row  inputContainer1 pb-4">
                  <div className="col-12">
                    <ApplicationInput
                      name="The landlord is providing phone and/or email contact information for emergencies or day-to-day communications:"
                      type="optionYN"
                      target="landlordEmergencyContactOption"
                      onChange={this.onchangeCheck}
                      info={
                        <div className=" torontoagreementTableBody pb-2   ">
                          <div className="row torontoagreementTitleBar p-0 m-0">
                            <div className="col p-0 m-0">
                              <p className="pl-2 m-1">B. Change of Landlord</p>
                            </div>
                          </div>

                          <div className="col p-0 m-0 pt-2">
                            <span className="span7 ">
                              A new landlord has the same rights and duties as
                              the previous landlord. A new landlord must follow
                              all the terms of this agreement unless the tenant
                              and new landlord agree to other terms. A new
                              landlord should provide the tenant with their
                              legal name and address
                            </span>
                          </div>

                          <div className="row torontoagreementTitleBar p-0 m-0">
                            <div className="col p-0 m-0">
                              <p className="pl-2 m-1">
                                <b>E. Giving Notices and Documents </b> (Part
                                XII of the Act)
                              </p>
                            </div>
                          </div>

                          <div className="col pt-2 p-0 m-0">
                            <span className="span7 ">
                              The landlord and tenant have to deliver some
                              official notices and other documents in writing.
                              These notices and documents can be:
                            </span>
                            <div className="col pt-2">
                              <span className="span7 ul p-0 m-0">
                                <li className="p-2">
                                  hand delivered, <br></br>
                                </li>
                                <li className="p-2">
                                  left in a mail box or a place where mail is
                                  ordinarily delivered, or <br></br>
                                </li>
                                <li className="p-2">
                                  mailed (this will count as delivered five days
                                  after mailing). <br></br>
                                </li>
                              </span>
                            </div>
                            <span className="span7 ">
                              There are also other ways to serve notices and
                              documents. For more information, contact the
                              Landlord and Tenant Board or see the Rules of
                              Practice on its website
                            </span>
                          </div>
                        </div>
                      }
                      important="true"
                      errorMessage="the landlord emergency information option"
                      validate={this.checkValidate(
                        this.state.landlordEmergencyContactOption
                      )}
                      value={this.state.landlordEmergencyContactOption}
                    />
                  </div>

                  {this.state.landlordEmergencyContactOption && (
                    <>
                      <div className="col-12  ">
                        <ApplicationInput
                          name="If yes, provide information:"
                          type="text"
                          target="landlordEmergencyContact"
                          important="true"
                          onChange={this.onchange}
                          errorMessage={"your information"}
                          validate={this.checkValidate(
                            this.state.landlordEmergencyContact
                          )}
                          value={this.state.landlordEmergencyContact}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {this.state.nowTab === 2 && (
            <>
              <div className="cardApplication">
                <div className="row pt-4 inputContainer1  ">
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Rent On Each"
                      target="rentCollectionOnEach"
                      type="optional"
                      options={["Month", "Week", "Other"]}
                      optionValue={["month", "week", "other"]}
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the on each"
                      validate={this.checkValidate(
                        this.state.rentCollectionOnEach
                      )}
                      value={this.state.rentCollectionOnEach}
                    />
                  </div>
                  {this.state.rentCollectionOnEach === "other" && (
                    <>
                      <div className="col-4 alignInput">
                        <ApplicationInput
                          name="Specify Other Type"
                          type="textarea"
                          target="rentCollectionOnEachOther"
                          rows={3}
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the other on each"
                          validate={this.checkValidate(
                            this.state.rentCollectionOnEachOther
                          )}
                          value={this.state.rentCollectionOnEachOther}
                        />
                      </div>
                      <div className="col-4 alignInput">
                        <ApplicationInput
                          name="Rent Due Date"
                          target="rentCollectionDay"
                          type="optional"
                          options={
                            this.state.rentCollectionOnEach === "month"
                              ? monthNumberArray
                              : weekNumberArray
                          }
                          optionValue={
                            this.state.rentCollectionOnEach === "month"
                              ? monthNumberArray
                              : weekNumberArray
                          }
                          infoStyle="infoContent2"
                          info={
                            <>
                              <b>Note:</b>
                              <br></br>
                              <span>
                                {this.state.rentCollectionDay}{" "}
                                {this.state.rentCollectionDay > 10 &&
                                this.state.rentCollectionDay < 20
                                  ? "th"
                                  : this.state.rentCollectionDay % 10 === 1
                                  ? "st"
                                  : this.state.rentCollectionDay % 10 === 2
                                  ? "nd"
                                  : this.state.rentCollectionDay % 10 === 3
                                  ? "rd"
                                  : "th"}{" "}
                                day of{" "}
                                {this.state.rentCollectionOnEachOther === ""
                                  ? "(other)"
                                  : this.state.rentCollectionOnEachOther}
                              </span>
                            </>
                          }
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the tenancy type"
                          validate={this.checkValidate(
                            this.state.rentCollectionDay
                          )}
                          value={this.state.rentCollectionDay}
                        />
                      </div>
                    </>
                  )}
                  {(this.state.rentCollectionOnEach === "month" ||
                    this.state.rentCollectionOnEach === "week") && (
                    <div className="col-4 alignInput">
                      <ApplicationInput
                        name="Rent Due Date"
                        target="rentCollectionDay"
                        type="optional"
                        options={
                          this.state.rentCollectionOnEach === "month"
                            ? monthNumberArray
                            : weekNumberArray
                        }
                        optionValue={
                          this.state.rentCollectionOnEach === "month"
                            ? monthNumberArray
                            : weekNumberArray
                        }
                        infoStyle="infoContent2"
                        info={
                          <>
                            <b>Note:</b>
                            <br></br>
                            <span>
                              {this.state.rentCollectionDay}{" "}
                              {this.state.rentCollectionDay > 10 &&
                              this.state.rentCollectionDay < 20
                                ? "th"
                                : this.state.rentCollectionDay % 10 === 1
                                ? "st"
                                : this.state.rentCollectionDay % 10 === 2
                                ? "nd"
                                : this.state.rentCollectionDay % 10 === 3
                                ? "rd"
                                : "th"}{" "}
                              day of {this.state.rentCollectionOnEach}
                            </span>
                          </>
                        }
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the tenancy type"
                        validate={this.checkValidate(
                          this.state.rentCollectionDay
                        )}
                        value={this.state.rentCollectionDay}
                      />
                    </div>
                  )}
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Rent Payable To"
                      type="text"
                      target="rentPayableTo"
                      onChange={this.onchange}
                      important="true"
                      infoStyle="infoContent2"
                      info={
                        <>
                          <b>Note:</b>
                          <br />
                          <span>
                            The tenant cannot be required to pay rent by
                            post-dated cheques or automatic payments, but can
                            choose to do so."
                          </span>
                        </>
                      }
                      validate={this.checkValidate(this.state.rentPayableTo)}
                      value={this.state.rentPayableTo}
                    />
                  </div>
                  <div className="col-md alignInput">
                    <ApplicationInput
                      name="Rent Payable Method"
                      target="rentPayableMethod"
                      type="optional"
                      options={["Card", "ACH", "Cheque"]}
                      optionValue={["CARD", "ACH", "CHEQUE"]}
                      onChange={this.onchange}
                      important="true"
                      infoStyle="infoContent2"
                      info={
                        <>
                          <b>Note:</b>
                          <br />
                          <span>
                            The tenant cannot be required to pay rent by
                            post-dated cheques or automatic payments, but can
                            choose to do so.
                          </span>
                        </>
                      }
                      errorMessage="the rent payable method"
                      validate={this.checkValidate(
                        this.state.rentPayableMethod
                      )}
                      value={this.state.rentPayableMethod}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Rent"
                      type="number"
                      place="00.00"
                      target="rent"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="rent"
                      validate={this.checkValidate(this.state.rent)}
                      iconL={"fa-usd"}
                      value={this.state.rent}
                    />
                  </div>
                  {this.state.parkingAcce && (
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Parking Rent"
                        type="number"
                        place="00.00"
                        target="parkingRent"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="parking Rent"
                        validate={this.checkValidate(this.state.parkingRent)}
                        iconL={"fa-usd"}
                        value={this.state.parkingRent}
                      />
                    </div>
                  )}
                </div>

                <div
                  className={
                    this.state.rentAmount.length > 0
                      ? "informationListAppNormal ashBack inputContainer1 mt-4"
                      : " inputContainer1"
                  }
                >
                  {this.state.rentAmount.map((_data, index) => (
                    <div className="row   ">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Rent Type"
                          target="rentType"
                          type="text"
                          onChange={this.onchange}
                          id={index}
                          important="true"
                          errorMessage="the rent type"
                          validate={this.checkValidate(
                            this.state.rentType[index]
                          )}
                          value={this.state.rentType[index]}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Amount"
                          target="rentAmount"
                          type="number"
                          onChange={this.onchange}
                          id={index}
                          iconL={"fa-usd"}
                          value={this.state.rentAmount[index]}
                          important="true"
                          errorMessage="the rent amount"
                          validate={this.checkValidate(
                            this.state.rentAmount[index]
                          )}
                        />
                      </div>
                      <div className="col-1">
                        <>
                          <button
                            key="deleteImage"
                            onClick={() => this.removeRent(index)}
                            className="agreement   pri"
                            style={{
                              marginTop: "35px",
                              height: "35px",
                              zIndex: "4500",
                            }}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </button>
                        </>
                      </div>
                    </div>
                  ))}
                  {this.state.rentAmount.length < 3 && (
                    <div className="row  p-0 m-0">
                      <div className="col text-right pull-right">
                        <button
                          className="mr-3 mt-2 pri "
                          onClick={() => this.addRent()}
                        >
                          Add additional Rent
                        </button>
                        <span className="infoHandel mb-5  ">
                          <i
                            className="fa fa-question-circle infoIcon"
                            aria-hidden="true"
                          ></i>
                          <br></br>
                          <div className=" informationListApp mt-2 infoContent2 pull-right">
                            <span className="searchListContentApp">
                              This rents will be add with total rent
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <br />
                <br />

                <div className="row inputContainer1 mt-0">
                  <div className="col-md-4 ">
                    <ApplicationInput
                      name="Rent Discounts"
                      type="optionYN"
                      target="isRentDiscount"
                      onChange={this.onchangeCheck}
                      important="true"
                      info={
                        <div className=" torontoagreementTableBody pb-3 ">
                          <div className="row torontoagreementTitleBar p-0 m-0">
                            <div className="col p-0 m-0">
                              <p className="pl-2 m-1">
                                <b>G. Rent Discounts </b> (Part XII of the Act)
                              </p>
                            </div>
                          </div>

                          <div className="col pt-2 p-0 m-0">
                            <span className="span7 ">
                              The landlord can offer the tenant a discount for
                              paying rent on or before the date it is due. This
                              discount can be up to two per cent of the lawful
                              rent. <br></br>
                              The landlord can also offer rent-free periods or
                              discounts in one of three ways:
                            </span>
                            <div className="col pt-2">
                              <span className="span7 ul p-0 m-0">
                                <li className="p-2">
                                  Rent-free periods of up to three months within
                                  any 12-month period, <br></br>
                                </li>
                                <li className="p-2">
                                  A discount of up to one month’s rent spread
                                  evenly over eight months, or <br></br>
                                </li>
                                <li className="p-2">
                                  A discount of up to two months’ rent, with up
                                  to one month’s rent spread evenly over the
                                  first seven months, and up to one month’s rent
                                  discounted in one of the last five months.
                                </li>
                              </span>
                            </div>
                            <span className="span7 ">
                              These types of discounts must be agreed to in
                              writing.
                            </span>
                          </div>
                        </div>
                      }
                      errorMessage="the discount negotiation"
                      validate={this.checkValidate(this.state.isRentDiscount)}
                      value={this.state.isRentDiscount}
                    />
                  </div>
                  {this.state.isRentDiscount && (
                    <>
                      <div className="col-md alignInput ">
                        <ApplicationInput
                          name="Rent Discount Type"
                          target="rentDiscountType"
                          type="optional"
                          options={["Percentage", "Fixed"]}
                          optionValue={["percentage", "fixed"]}
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the tenancy type"
                          validate={this.checkValidate(
                            this.state.rentDiscountType
                          )}
                          value={this.state.rentDiscountType}
                        />
                      </div>
                      {this.state.rentDiscountType === "fixed" && (
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Rent Discount Amount"
                            target="rentDiscountAmount"
                            type="number"
                            onChange={this.onchange}
                            important="true"
                            iconL={"fa-usd"}
                            errorMessage="the rent discount amount"
                            validate={this.checkValidate(
                              this.state.rentDiscountAmount
                            )}
                            value={this.state.rentDiscountAmount}
                          />
                        </div>
                      )}
                      {this.state.rentDiscountType === "percentage" && (
                        <div className="col-md alignInput">
                          <ApplicationInput
                            name="Rent Discount Percentage"
                            target="rentDiscountPercentage"
                            type="optional"
                            options={hundredNumberArray}
                            optionValue={hundredNumberArray}
                            onChange={this.onchange}
                            important="true"
                            iconL={"fa-usd"}
                            errorMessage="the rent discount"
                            validate={this.checkValidate(
                              this.state.rentDiscountPercentage
                            )}
                            value={this.state.rentDiscountPercentage}
                          />
                        </div>
                      )}
                      <div className="col-md ">
                        <span className="searchListHeadApp ">
                          Rent Discount Description *
                        </span>
                        <textarea
                          className={
                            this.checkValidate(
                              this.state.rentDiscountDescription
                            )
                              ? "ApplicationInputAgg alert"
                              : "ApplicationInputAgg"
                          }
                          onChange={this.onchange}
                          name={"rentDiscountDescription"}
                          rows={3}
                          value={this.state.rentDiscountDescription}
                        ></textarea>
                        {this.checkValidate(
                          this.state.rentDiscountDescription
                        ) && (
                          <p className="spanValidateApp textPri">
                            Please choose the rent discount description
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="row inputContainer1 ">
                  <div className="col-md-4 ">
                    <ApplicationInput
                      name="Rent Deposit"
                      type="optionYN"
                      target="securityDeposit"
                      info={
                        <>
                          <b>Note:</b>
                          <br />
                          <span>
                            This amount cannot be more than one month’s rent or
                            the rent for one rental period (e.g., one week in a
                            weekly tenancy), whichever is less. This cannot be
                            used as a damage deposit. The landlord must pay the
                            tenant interest on the rent deposit every year. See
                            Part H in General Information.
                          </span>
                        </>
                      }
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the rent deposit have or not"
                      validate={this.checkValidate(this.state.securityDeposit)}
                      value={this.state.securityDeposit}
                    />
                  </div>
                  {this.state.securityDeposit && (
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Rent Deposit"
                        type="number"
                        place="00.00"
                        target="securityDepositAmount"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="rent amount"
                        validate={this.checkValidate(
                          this.state.securityDepositAmount
                        )}
                        iconL={"fa-usd"}
                        value={this.state.securityDepositAmount}
                      />
                    </div>
                  )}
                </div>
                <div className="row inputContainer1">
                  <div className="col-md-4 ">
                    <ApplicationInput
                      name="Key Deposit"
                      type="optionYN"
                      target="keyDeposit"
                      onChange={this.onchangeCheck}
                      important="true"
                      info={
                        <>
                          <b>Note:</b>
                          <br />
                          <span>
                            The key deposit cannot be more than the expected
                            replacement cost.
                          </span>
                          <div className=" torontoagreementTableBody pb-3 ">
                            <div className="row torontoagreementTitleBar p-0 m-0">
                              <div className="col p-0 m-0">
                                <p className="pl-2 m-1">
                                  {" "}
                                  <b> H. Deposits </b> (Part VII of the Act)
                                </p>
                              </div>
                            </div>

                            <div className="col pt-2 p-0 m-0">
                              <span className="span7 ">
                                The landlord can only collect a deposit for the
                                last month’s rent and a refundable key deposit.
                                The tenant does not have to provide any other
                                form of deposit, such as pet or damage deposits.
                                If the tenant pays anything more, the tenant can
                                apply to the Landlord and Tenant Board to get
                                the money back. <br></br>
                                <b>
                                  {" "}
                                  Rent deposit (i.e. last month’s rent):
                                </b>{" "}
                                The landlord can require a rent deposit on or
                                before the tenant enters into the tenancy
                                agreement. The landlord must apply this money to
                                the rent for the last period of the tenancy. The
                                rent deposit must not be more than one month’s
                                rent or the rent for one rental period (e.g.,
                                one week in a weekly tenancy), whichever is
                                less. <br></br>
                                The landlord must pay the tenant interest on the
                                rent deposit every year. If the rent increases
                                after the tenant has paid a rent deposit, the
                                landlord can require the tenant to top-up the
                                rent deposit so that it is the same as the new
                                rent. The landlord can use the interest on the
                                rent deposit to top-up the rent deposit.{" "}
                                <br></br>
                                If the landlord is unable to let the tenant move
                                into the rental unit, the landlord must return
                                the deposit, unless the tenant agrees to rent a
                                different unit. <br></br>
                                <b> Key deposit: </b> If the landlord collects a
                                deposit for key(s), remote entry devices or
                                cards, the landlord must return the deposit when
                                the tenant gives back their key(s) at the end of
                                the tenancy. <br></br>
                                The landlord can charge the tenant for
                                additional keys that the tenant requests (for
                                example, if the tenant wants an extra key or if
                                the tenant has lost their key), but the charge
                                cannot be more than actual cost of the keys.
                                This is not a key deposit.
                              </span>
                            </div>
                          </div>
                        </>
                      }
                      errorMessage="the key deposit have or not"
                      validate={this.checkValidate(this.state.keyDeposit)}
                      value={this.state.keyDeposit}
                    />
                  </div>
                  {this.state.keyDeposit && (
                    <>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Key Deposit"
                          type="number"
                          place="00.00"
                          target="keyDepositAmount"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="key amount"
                          validate={this.checkValidate(
                            this.state.keyDepositAmount
                          )}
                          iconL={"fa-usd"}
                          value={this.state.keyDepositAmount}
                        />
                      </div>
                      <div className="col-md alignInput">
                        <span className="searchListHeadApp ">
                          Key Deposit Description *
                        </span>
                        <textarea
                          className={
                            this.checkValidate(this.state.keyDepositDescription)
                              ? "ApplicationInputAgg alert"
                              : "ApplicationInputAgg"
                          }
                          onChange={this.onchange}
                          name={"keyDepositDescription"}
                          rows={3}
                          value={this.state.keyDepositDescription}
                        ></textarea>
                        {this.checkValidate(
                          this.state.keyDepositDescription
                        ) && (
                          <p className="spanValidateApp textPri">
                            Please choose the key deposit description
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="row inputContainer1 ">
                  <div className="col-md-12 ">
                    <ApplicationInput
                      name="Partial Rent Payments"
                      type="optionYN"
                      target="isPartialRent"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the partial rent have or not"
                      validate={this.checkValidate(this.state.isPartialRent)}
                      value={this.state.isPartialRent}
                    />

                    {this.state.isPartialRent && (
                      <div className="row   informationListAppNormal  mt-3 mb-3 pb-4">
                        <span className="span7 pt-3 pr-3">
                          If the first rental period (e.g., month) is a partial
                          period, the tenant will pay a partial rent of ${" "}
                        </span>
                        <input
                          className={
                            this.checkValidate(this.state.partialRent)
                              ? "col-3 p-0 ApplicationInput1 m-0  alert"
                              : "col-3 p-0 ApplicationInput1 m-0"
                          }
                          onChange={this.onchange}
                          name={"partialRent"}
                          type="number"
                          style={{ height: "32px" }}
                          value={this.state.partialRent}
                        ></input>
                        <span className="span7 pt-3 pr-3 pl-3"> on</span>
                        <input
                          className={
                            this.checkValidate(this.state.partialRentDate)
                              ? "col-3 p-0 ApplicationInput1 m-0 alert"
                              : "col-3 p-0 ApplicationInput1 m-0"
                          }
                          onChange={this.onChangeDate}
                          name={"partialRentDate"}
                          type="date"
                          style={{ height: "32px" }}
                          value={this.state.partialRentDate}
                        ></input>
                        <span className="span7 pt-4 pr-3">
                          {" "}
                          . This partial rent covers the rental of the unit from
                        </span>
                        <input
                          className={
                            this.checkValidate(this.state.partialRentFrom)
                              ? "col-3 p-0 ApplicationInput1 m-0 mt-2 alert"
                              : "col-3 p-0 ApplicationInput1 m-0 mt-2"
                          }
                          onChange={this.onChangeDate}
                          name={"partialRentFrom"}
                          type="date"
                          style={{ height: "32px" }}
                          value={this.state.partialRentFrom}
                        ></input>
                        <span className="span7 pt-4 pr-3 pl-3"> to</span>
                        <input
                          className={
                            this.checkValidate(this.state.partialRentTo)
                              ? "col-3 p-0 ApplicationInput1 m-0 mt-2 alert"
                              : "col-3 p-0 ApplicationInput1 m-0 mt-2"
                          }
                          onChange={this.onChangeDate}
                          name={"partialRentTo"}
                          type="date"
                          style={{ height: "32px" }}
                          value={this.state.partialRentTo}
                        ></input>
                        <span className="span7 pl-2 pt-4">.</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row inputContainer1 ">
                  <div className="col-md-12 ">
                    <ApplicationInput
                      name="Administration Charge"
                      type="optionYN"
                      target="isLateFee"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the partial rent have or not"
                      validate={this.checkValidate(this.state.isLateFee)}
                      value={this.state.isLateFee}
                      info={
                        <>
                          <span>
                            The landlord’s administration charge for an NSF
                            cheque cannot be more than $20.00
                          </span>
                        </>
                      }
                    />

                    {this.state.isLateFee && (
                      <div className="row informationListAppNormal  mt-3 mb-3 pb-4">
                        <>
                          <span className=" span7 pt-3  ">
                            If the tenant’s cheque is returned because of
                            non-sufficient funds (NSF), the tenant will have to
                            pay the landlord’s administration charge of $
                          </span>
                          <input
                            className={
                              this.checkValidate(this.state.lateFeeAmount)
                                ? "col-3 p-0 ApplicationInput1 m-0 alert"
                                : "col-3 p-0 ApplicationInput1 m-0"
                            }
                            onChange={this.onchange}
                            name={"lateFeeAmount"}
                            type="number"
                            value={this.state.lateFeeAmount}
                            style={{ height: "38px" }}
                          ></input>
                          <span className="span7 pt-3">
                            {" "}
                            plus any NSF charges made by the landlord's bank.
                          </span>
                          <br />
                          <br />
                        </>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row inputContainer1">
                  <div className="col  pt-0 ">
                    <ApplicationInput
                      name="Tenant Insurance"
                      type="optionYN"
                      target="tenantInsurance"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the partial rent have or not"
                      validate={this.checkValidate(this.state.tenantInsurance)}
                      value={this.state.tenantInsurance}
                    />
                    {this.state.tenantInsurance && (
                      <div className=" informationListAppNormal col-12  mb-2 mt-3">
                        <div className="searchListContentApp">
                          <span>
                            The tenant must have liability insurance at all
                            times. If the landlord asks for proof of coverage,
                            the tenant must provide it. It is up to the tenant
                            to get contents insurance if they want it.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row inputContainer1 pb-5">
                  <div className="col-md-12 ">
                    <ApplicationInput
                      name="Additional terms"
                      type="optionYN"
                      target="isAdditionalTerms"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the additional terms have or not"
                      validate={this.checkValidate(
                        this.state.isAdditionalTerms
                      )}
                      value={this.state.isAdditionalTerms}
                    />
                  </div>

                  {this.state.isAdditionalTerms && (
                    <>
                      <textarea
                        className={
                          this.checkValidate(this.state.additionalTermsDoc)
                            ? "col-12 ApplicationInputAgg alert"
                            : "col-12 ApplicationInputAgg m-0"
                        }
                        onChange={this.onchange}
                        name={"additionalTermsDoc"}
                        value={this.state.additionalTermsDoc}
                      ></textarea>
                      {this.checkValidate(this.state.additionalTermsDoc) ? (
                        <p className="spanValidateApp textPri">
                          Please enter the additional terms
                        </p>
                      ) : (
                        <span></span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {this.state.nowTab === 3 && (
            <>
              <div className="cardApplication">
                <div className="row inputContainer1  pt-3">
                  <p className="optionTitle mb-0 pb-2 ">
                    The following services are included in the lawful rent for
                    the rental unit, as specified:
                  </p>
                </div>
                <div className="row inputContainer1  pt-4">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Gas"
                      type="optionYN"
                      target="gas"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the gas"
                      validate={this.checkValidate(this.state.gas)}
                      value={this.state.gas}
                    />
                  </div>
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Air Condition"
                      type="optionYN"
                      target="airConditioner"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the air condition"
                      validate={this.checkValidate(this.state.airConditioner)}
                      value={this.state.airConditioner}
                    />
                  </div>
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Additional Storage"
                      type="optionYN"
                      target="additionalStorage"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the additionalStorage"
                      validate={this.checkValidate(
                        this.state.additionalStorage
                      )}
                      value={this.state.additionalStorage}
                    />
                  </div>
                </div>
                <div className="row inputContainer1  pb-4">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="On Site Laundry"
                      type="optionYN"
                      target="laundry"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the on site laundry"
                      validate={this.checkValidate(this.state.laundry)}
                      value={this.state.laundry}
                    />
                    {this.state.laundry && (
                      <ApplicationInput
                        name="On Site Laundry Charge Type"
                        type="optionBox"
                        target="laundryChargeType"
                        onChange={this.onchange}
                        options={["No charge", "Pay per use"]}
                        optionValue={["nocharge", "payperuse"]}
                        value={this.state.laundryChargeType}
                      />
                    )}
                  </div>
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Guest Parking"
                      type="optionYN"
                      target="guestParking"
                      onChange={this.onchangeCheck}
                      important="true"
                      errorMessage="the guest parking"
                      validate={this.checkValidate(this.state.guestParking)}
                      value={this.state.guestParking}
                    />
                    {this.state.guestParking && (
                      <ApplicationInput
                        name="Guest Parking Charge Type"
                        type="optionBox"
                        target="guestParkingChargeType"
                        onChange={this.onchange}
                        options={["No charge", "Pay per use"]}
                        optionValue={["nocharge", "payperuse"]}
                        value={this.state.guestParkingChargeType}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={
                    this.state.serviceType.length > 0
                      ? "informationListAppNormal ashBack inputContainer1 mt-3"
                      : " inputContainer1"
                  }
                >
                  {this.state.serviceType.map((_data, index) => (
                    <>
                      <div className="row">
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Service Type"
                            target="serviceType"
                            type="text"
                            onChange={this.onchange}
                            id={index}
                            value={this.state.serviceType[index]}
                            important="true"
                            errorMessage="the service type"
                            validate={this.checkValidate(
                              this.state.serviceType[index]
                            )}
                          />
                        </div>

                        <div className="col-1">
                          <>
                            <button
                              key="deleteImage"
                              onClick={() => this.removeService(index)}
                              className="agreement   pri"
                              style={{
                                marginTop: "35px",
                                height: "35px",
                                zIndex: "4500",
                              }}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </>
                        </div>
                      </div>
                    </>
                  ))}
                  {this.state.serviceType.length < 3 && (
                    <div className="row  ">
                      <div className="col text-right ">
                        <button
                          className="mr-3 mb-4 pri "
                          onClick={() => this.addService()}
                        >
                          Add additional Services
                        </button>
                      </div>
                    </div>
                  )}
                  {this.state.serviceType.length > 0 && (
                    <>
                      <br />{" "}
                      <span className="searchListHeadApp ml-3">
                        Provide details about services or list any additional
                        services if needed (if necessary add additional pages):
                      </span>
                      <div className="col-12  ">
                        <textarea
                          className={
                            // this.checkValidate(
                            //   this.state.otherServiceDescription
                            // )
                            //   ? "ApplicationInputAgg alert"
                            "ApplicationInputAgg"
                          }
                          onChange={this.onchange}
                          name={"otherServiceDescription"}
                          value={this.state.otherServiceDescription}
                        ></textarea>
                      </div>
                    </>
                  )}
                </div>
                <div className="row  inputContainer1  ">
                  <div className="col alignInput">
                    <p className="optionTitle pt-2 pb-0 mb-0 ">
                      The following utilities are the responsibility of:
                    </p>

                    <ApplicationInput
                      name="Electricity"
                      type="optionBox"
                      target="electricityResponsible"
                      onChange={this.onchange}
                      options={["Landlord", "Tenant"]}
                      optionValue={["landlord", "tenant"]}
                      // important="true"
                      // errorMessage="you have the pet or not"
                      // validate={this.checkValidate(this.state.washerDrier)}
                      value={this.state.electricityResponsible}
                    />
                  </div>
                  <div className="col alignInput">
                    <br />
                    <br />
                    <ApplicationInput
                      name="Heat"
                      type="optionBox"
                      target="heatResponsible"
                      onChange={this.onchange}
                      options={["Landlord", "Tenant"]}
                      optionValue={["landlord", "tenant"]}
                      // important="true"
                      // errorMessage="you have the pet or not"
                      // validate={this.checkValidate(this.state.washerDrier)}
                      value={this.state.heatResponsible}
                    />
                  </div>
                  <div className="col alignInput">
                    <br />
                    <br />
                    <ApplicationInput
                      name="Water"
                      type="optionBox"
                      target="waterResponsible"
                      onChange={this.onchange}
                      options={["Landlord", "Tenant"]}
                      optionValue={["landlord", "tenant"]}
                      // important="true"
                      // errorMessage="you have the pet or not"
                      // validate={this.checkValidate(this.state.washerDrier)}
                      value={this.state.waterResponsible}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="row inputContainer1 ">
                  <div className="col  pt-0 ">
                    <span className="searchListHeadApp ml-0">
                      If the tenant is responsible for any utilities, provide
                      details of the arrangement, e.g. tenant sets up account
                      with and pays the utility provider, tenant pays a portion
                      of the utility costs (if necessary add additional pages):
                    </span>

                    <div className="col-12  ">
                      <textarea
                        className={
                          // this.checkValidate(this.state.otherResponsible)
                          //   ? "ApplicationInputAgg alert"
                          "ApplicationInputAgg"
                        }
                        onChange={this.onchange}
                        name={"otherResponsible"}
                        value={this.state.otherResponsible}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {this.state.nowTab === 4 && (
            <>
              {this.state.pushFinished ? (
                <Loader />
              ) : (
                <>
                  <TorontoReview
                    agreementData={this.state}
                    applicationData={this.state.applicationData}
                    applicantData={this.state.applicantData}
                    propertyData={this.state.propertyData}
                    landlordData={this.state.userDetails}
                  />
                  <InfoReview
                    agreementData={this.state}
                    applicationData={this.state.applicationData}
                    propertyData={this.state.propertyData}
                    landlordData={this.state.userDetails}
                  />
                </>
              )}
            </>
          )}
          {this.state.footer && (
            <div className="row appFootButton text-right">
              <div className="col ">
                {this.state.nowTab !== 1 && (
                  <button className="mr-3 pri" onClick={() => this.back()}>
                    Back
                  </button>
                )}
                {this.state.nowTab !== 4 && (
                  <button className="buttonPri " onClick={() => this.next()}>
                    Next
                  </button>
                )}
                {this.state.nowTab === 4 && (
                  <button
                    className=" buttonPri"
                    onClick={() => this.handleFinish()}
                  >
                    Finished
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setContract })(CreateContractOntario)
);
