import React, { Component } from "react";
import { checkPaymentStatus } from "../../../Services/ApiServices";
import Loader from "../../Loader/Loader";
import "./payment.css";
import { withRouter } from "react-router-dom";
import CookieService from "../../../Services/CookieService";
import { connect } from "react-redux";
import { setCustomerPaymentStatus, appendAlert } from "../../../actions";
import PaymentTenant from "./PaymentTenant/PaymentTenant";
import PaymentLandlordV2 from "./PaymentLandlord/PaymentLandlordV2";
class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractData: [],
      stripeCustomerId: null,
      stripe_account_status: null,
      paymentAdded: true,
      userDetails: CookieService.getArrayCookie("axUserDetails"),
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    if (this.props.role !== "Guest") {
      try {
        if (this.props.role !== null) {
          if (this.props.role === "Tenant") {
            if (this.props.userDetail.stripeCustomerId) {
              checkPaymentStatus().then((response) => {
                if (response.status === 200) {
                  if (response.data.status) {
                    this.setState({
                      stripe_account_status: response.data.data,
                    });
                    this.props.setCustomerPaymentStatus(response.data.data);
                    if (response.data.data !== "verified") {
                      this.props.history.push({
                        pathname: "/payments/add",
                        state: {
                          data: this.props.userDetail,
                          status: response.data.data,
                          type: "initial",
                        },
                      });
                    }
                  } else {
                    this.props.history.push({
                      pathname: "/payments/add",
                      state: {
                        data: this.props.userDetail,
                        status: this.props.customerPaymentStatus,
                        type: "initial",
                      },
                    });
                    this.setState({ stripe_account_status: "new" });
                  }
                } else {
                  this.props.appendAlert(
                    "Something went wrong!, Please try again later.",
                    "danger"
                  );
                }
              });
            } else {
              this.props.history.push({
                pathname: "/payments/add",
                state: {
                  data: this.props.userDetail,
                  status: this.props.customerPaymentStatus,
                  type: "initial",
                },
              });
            }
          } else if (
            this.props.role === "Landlord" ||
            this.props.role === "Admin"
          ) {
            //Console.log("Admin,Landlord")
          }
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      window.location.reload();
    }
  }

  render() {
    if (this.state.role !== null) {
      if (this.props.role === "Landlord" || this.props.role === "Admin") {
        return <PaymentLandlordV2 />;
      } else if (this.props.role === "Tenant") {
        return this.props.customerPaymentStatus === null ? (
          <Loader />
        ) : (
          <PaymentTenant />
        );
      } else {
        return <h1 style={{ paddingTop: "70px" }}>Something went wrong</h1>;
      }
    } else {
      window.location.reload();
    }
  }
}
const mapStateToProps = (state) => {
  return {
    customerPayment: state.customerPaymentReducer,
    customerPaymentStatus: state.customerPayment,
    alert: state.alertReducer,
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setCustomerPaymentStatus })(Payments)
);
