import React, { Component } from "react";
import "../contracts.css";
import {
  changeVisibleContractDocument,
  deleteContractDocument,
  getLandlordContracts,
  uploadContractFile,
  viewContractDocument,
} from "../../../../Services/ApiServices";
import Loader from "../../../Loader/Loader";
import NoData from "../../../../Messages/NoData";
import ModalContainer from "../../Documents/ModalContainer";
import ViewDocuments from "../../Documents/ViewDocuments";
import ContractListLandlord from "./ContractListLandlord";
import { connect } from "react-redux";
import { appendAlert, setContract } from "../../../../actions";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import SearchInput from "../../Application/SearchInput";
import {
  capitalizeFirstLetter,
  convertLocalDate,
  sortText,
  sortText2,
} from "../../../../Defined/Function";
import Loader3 from "../../../Loader/loader3";

class ContractLandlord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewId: 0,
      viewFile: "contractAgreement",
      viewTitle: "",
      viewStatus: "",
      viewContract: false,
      uploadAgreement: false,
      files: false,
      pdfDoc: [],
      fileDoc: [],
      pdfButtonDisable: false,
      fileButtonDisable: false,
      next: false,
      fileType: "",
      fileName: "",
      uploadWindow: false,
      viewFilesArray: [],
      isButtonDisableUploadAgreement: false,
    };
    this.closeContract = this.closeContract.bind(this);
    this.viewContract = this.viewContract.bind(this);
    this.viewUploadAgreement = this.viewUploadAgreement.bind(this);
    this.viewFiles = this.viewFiles.bind(this);
    this.pdfUpload = this.pdfUpload.bind(this);
    this.onchange = this.onchange.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  viewContract(data, file) {
    this.setState({
      viewId: data.contractId,
      viewFilesArray: data.contractFiles ? data.contractFiles : [],
      status: data.status,
    });
    this.setState({ viewFile: file });
    if (file === "contractAgreement") {
      this.setState({ viewTitle: "Contract Agreement" });
    } else {
      this.setState({ viewTitle: "Move In List" });
    }
    this.setState({ viewContract: true });
  }
  viewUploadAgreement(data) {
    this.setState({
      viewFilesArray: data.contractFiles ? data.contractFiles : [],
      viewId: data.contractId,
      status: data.status,
      uploadAgreement: true,
    });
  }
  viewFiles(data) {
    this.setState({
      viewFilesArray: data.contractFiles ? data.contractFiles : [],
      viewId: data.contractId,
      status: data.status,
      files: true,
      uploadWindow: false,
    });
  }
  closeContract() {
    this.setState({
      viewContract: false,
      uploadAgreement: false,
      files: false,
    });
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.refresh) {
      this.props.setContract(null);
    }
    try {
      await getLandlordContracts().then(async (response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            await this.props.setContract(null);
            this.props.setContract(
              response.data.sort((a, b) =>
                b.updatedAt.localeCompare(a.updatedAt)
              )
            );
          } else {
            this.props.setContract([]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  checkValidate(value) {
    return (
      (value === "" && this.state.next) ||
      (value === undefined && this.state.next)
    );
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  async changeState(name, value) {
    await this.setState({ [name]: value });
  }

  pdfUpload() {
    const formData = new FormData();
    formData.append("file", this.state.pdfDoc);
    formData.append("version", 1);
    formData.append("tenantAccess", true);
    formData.append("landlordAccess", true);
    this.setState({ pdfButtonDisable: true });
    uploadContractFile(formData, this.state.viewId, "contractAgreement")
      .then((response) => {
        window.$("#uploadAgreement").modal("hide");
        this.setState({ pdfButtonDisable: false });
        if (response.status === 200) {
          this.props.appendAlert("Pdf Uploaded successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              element.agreementPDF = "OWN_AGREEMENT_UPLOADED";
            }
          }
          this.props.setContract([]);
          this.props.setContract(arrayData);
        }
      })
      .catch(() => {
        this.closeContract();
        let arrayData = this.props.contractArray;
        for (const element of arrayData) {
          if (element.contractId === this.state.viewId) {
            element.agreementPDF = "OWN_AGREEMENT_UPLOADED";
          }
        }
        this.props.setContract([]);
        this.props.setContract(arrayData);
        this.setState({ pdfButtonDisable: false });
        this.props.appendAlert("Pdf details couldn't be gathered", "warning");
      });
  }

  downloadFile(data) {
    viewContractDocument(data.id, data.category).then((response) => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.download =
          data.category +
          " - " +
          data.version +
          "." +
          response.data.type.split("/")[1];
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        this.props.appendAlert("Download Failed", "danger");
      }
    });
  }
  saveUpload() {
    if (this.state.fileType !== "" && this.state.fileDoc.length !== 0) {
      let cat =
        this.state.fileType === "Final Agreement"
          ? "contractAgreement"
          : this.state.fileType === "Draft Agreement"
          ? "draftAgreement"
          : this.state.fileType === "Credit Check"
          ? "creditCheck"
          : this.state.fileType === "Background Check"
          ? "backgroundCheck"
          : this.state.fileType;
      let array = this.state.viewFilesArray
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((todo) => todo.category === cat);
      let length = 1;
      if (array.length !== 0) {
        length = parseInt(array[0].version) + 1;
      }
      this.fileUpload(length, cat);
    } else {
      this.setState({ next: true });
    }
  }

  updateFileAccess(data, access) {
    data.tenantAccess = access;
    changeVisibleContractDocument(data, this.state.viewId)
      .then((response) => {
        if (response.status === 200) {
          window.$("#filesView").modal("hide");
          this.props.appendAlert("File Access changed successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              for (let i = 0; i < element.contractFiles.length; i++) {
                if (element.contractFiles[i].id === data.id) {
                  element.contractFiles[i].tenantAccess = access;
                }
              }
            }
          }

          this.props.setContract([]);
          this.props.setContract(arrayData);
        } else {
          this.props.appendAlert("File Access changed Failed", "danger");
        }
      })
      .catch(() => {
        this.props.appendAlert("File Access changed Failed", "danger");
      });
  }
  deleteFile(id) {
    deleteContractDocument(id)
      .then((response) => {
        if (response.status === 200) {
          window.$("#filesView").modal("hide");
          this.props.appendAlert("File deleted successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              for (let i = 0; i < element.contractFiles.length; i++) {
                if (element.contractFiles[i].id === id) {
                  element.contractFiles[i].deleted = true;
                }
              }
            }
          }

          this.props.setContract([]);
          this.props.setContract(arrayData);
        } else {
          this.props.appendAlert("File deleted Failed", "danger");
        }
      })
      .catch(() => {
        this.props.appendAlert("File deleted Failed", "danger");
      });
  }

  fileUpload(version, cat) {
    const formData = new FormData();
    formData.append("file", this.state.fileDoc);
    formData.append("version", version);
    formData.append("tenantAccess", true);
    formData.append("landlordAccess", true);
    this.setState({ fileButtonDisable: true });
    uploadContractFile(formData, this.state.viewId, cat)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            fileButtonDisable: false,
            fileType: "",
            fileDoc: [],
          });
          window.$("#filesView").modal("hide");
          this.props.appendAlert("File Uploaded successfully", "success");
          let arrayData = this.props.contractArray;
          for (const element of arrayData) {
            if (element.contractId === this.state.viewId) {
              element.contractFiles.push(response.data);
              if (cat === "contractAgreement") {
                element.agreementPDF = "OWN_AGREEMENT_UPLOADED";
              }
            }
          }
          this.props.setContract([]);
          this.props.setContract(arrayData);
        } else {
          this.setState({ fileButtonDisable: false });
          window.$("#filesView").modal("hide");
        }
      })
      .catch(() => {
        this.setState({ fileButtonDisable: false });
        window.$("#filesView").modal("hide");
        this.props.appendAlert("File details couldn't be gathered", "warning");
      });
  }
  convertPdf(pdf) {
    const url = window.URL.createObjectURL(new Blob([pdf], { type: pdf.type }));
    const link = document.createElement("a");
    link.href = url;
    return link;
  }

  render() {
    return this.props.contractArray === null ? (
      <Loader />
    ) : this.props.contractArray.length === 0 ? (
      <div style={{ paddingTop: "132px", minHeight: window.screen.height }}>
        {" "}
        <NoData
          message="pending contracts to process"
          sub=""
        ></NoData>
      </div>
    ) : (
      <div
        style={{
          paddingTop: "115px",
          paddingBottom: "25px",
          minHeight: window.screen.height,
        }}
      >
        {this.props.contractArray.map((data, index) => {
          return (
            <ContractListLandlord
              data={data}
              eventKey={index}
              viewContract={this.viewContract}
              viewUploadAgreement={this.viewUploadAgreement}
              viewFiles={this.viewFiles}
              pdfButtonDisable={this.state.pdfButtonDisable}
            />
          );
        })}
        {this.state.viewContract && (
          <ModalContainer
            min="500px"
            name="exampleModal"
            title={this.state.viewTitle}
            footer="false"
            closeContract={this.closeContract}
            dark={this.props.dark}
          >
            <ViewDocuments
              viewFilesArray={this.state.viewFilesArray}
              id={this.state.viewId}
              file={this.state.viewFile}
              dark={this.props.dark}
              pushAlert={this.props.appendAlert}
            />
          </ModalContainer>
        )}
        {this.state.uploadAgreement && (
          <SmallModalContainer
            name="uploadAgreement"
            title="Upload Agreement Form"
            size="lg"
            confirm={this.pdfUpload}
            confirmCommand="Upload"
            confirmDisable={this.state.pdfButtonDisable}
            stopConfirmDismiss={true}
            max="530px"
          >
            <Dropzone
              accept=".pdf"
              maxSize={10485760}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles[0] === undefined) {
                  this.props.appendAlert(
                    "Pdf size is too Long (max size = 10mb) ",
                    "danger"
                  );
                } else {
                  this.setState({ pdfDoc: acceptedFiles[0] });
                }
              }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  <div className={"mt-4"} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {this.state.pdfDoc.length === 0 ? (
                      <div
                        className="dragDrop text-center "
                        style={{
                          fontSize: "60px",
                          minHeight: "350px",
                          paddingTop: "100px",
                        }}
                      >
                        <div className="row">
                          <div className="col">
                            <i
                              className={
                                this.props.dark
                                  ? "fa fa-cloud-upload  fa-10x dragIcon text-light vert-move "
                                  : "fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                              }
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p
                              className={
                                this.props.dark
                                  ? "licenseHeadDark"
                                  : "licenseHead"
                              }
                            >
                              Drag Or Click to Upload Pdf
                            </p>
                            <span className="span2 textPri text-center  ">
                              Max file size is 10MB. Pdf format only.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" text-center ">
                        {/* <embed
                          src={this.convertPdf(this.state.pdfDoc)}
                          width="100%"
                          height="450px"
                        /> */}
                        <center>
                          <div className="PDFWeb">
                            <object
                              data={this.convertPdf(this.state.pdfDoc)}
                              type="application/pdf"
                              width="100%"
                              height="450px"
                            >
                              <p>
                                Your web browser doesn't have a PDF plugin.
                                Instead you can{" "}
                                <a href={this.convertPdf(this.state.pdfDoc)}>
                                  click here to download the PDF file.
                                </a>
                              </p>
                            </object>
                          </div>
                          <div className="PDFMobile">
                            <object
                              data={this.convertPdf(this.state.pdfDoc)}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                            >
                              <p>
                                Your web browser doesn't have a PDF plugin.
                                Instead you can{" "}
                                <a href={this.convertPdf(this.state.pdfDoc)}>
                                  click here to download the PDF file.
                                </a>
                              </p>
                            </object>
                          </div>
                          <a
                            className="pt-0 mt-0 PDFMobile "
                            href={this.convertPdf(this.state.pdfDoc)}
                          >
                            click here to view the full PDF file.
                          </a>
                        </center>
                        <button className="pri pull-left">Change</button>
                        <br></br>
                        <br></br>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Dropzone>
          </SmallModalContainer>
        )}
        {this.state.files && (
          <SmallModalContainer
            name="filesView"
            title="Files"
            size="lg"
            footer="false"
            max="530px"
          >
            <div style={{ paddingRight: "2%", paddingLeft: "2%" }}>
              <span className="badge badge02 ">My Files</span>
            </div>
            {this.state.viewFilesArray.filter(
              (todo) => todo.uploadBy === this.props.userDetail.username
            ).length === 0 && (
              <span className="span2 d-flex justify-content-center textSec1">
                No file Found
              </span>
            )}
            <div className="row mb-0 pb-0">
              {this.state.viewFilesArray
                .filter(
                  (todo) => todo.uploadBy === this.props.userDetail.username
                )
                .map((data) => {
                  return (
                    !data.deleted && (
                      <div
                        className=" mt-4 mr-4 ml-4 text-center"
                        style={{ width: "150px" }}
                      >
                        <div className="fileType">
                          {data.tenantAccess ? (
                            <span
                              className="badge badgeFileType absolute"
                              title="Tenant can view"
                            >
                              open
                            </span>
                          ) : (
                            <span
                              className="badge badgeFileType1 absolute"
                              title="Tenant can't view"
                            >
                              private
                            </span>
                          )}
                          {data.fileType === "" ||
                          data.fileType === null ||
                          data.fileType === undefined
                            ? "file"
                            : sortText2(data.fileType.split("/")[1], 0, 4)}{" "}
                        </div>

                        <span className="span2  pt-2 ">
                          {capitalizeFirstLetter(
                            sortText2(data.category, 0, 17)
                          )}{" "}
                          - {data.version}{" "}
                        </span>
                        <div className="row">
                          <div
                            className=" pt-1 "
                            style={{ width: "30px" }}
                          ></div>
                          <div className=" pt-1 " style={{ width: "20px" }}>
                            <div className="btn-group pointer text-right ">
                              <div
                                className="fileTypeMenuPro  "
                                aria-hidden="true"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <svg
                                  version="1.0"
                                  className="fileTypeMenuPro1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10px"
                                  viewBox="0 0 512.000000 512.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#000000"
                                    stroke="none"
                                  >
                                    <path
                                      d="M1604 4869 c-201 -23 -407 -137 -539 -296 -62 -76 -135 -210 -160
                                          -295 l-16 -58 -372 0 c-357 0 -372 -1 -415 -21 -106 -52 -132 -188 -54 -281
                                          48 -57 56 -58 466 -58 l374 0 22 -72 c43 -142 115 -254 233 -364 151 -140 312
                                          -209 517 -221 346 -20 656 165 806 480 20 42 41 98 48 124 l12 48 1240 5 c913
                                          4 1244 8 1259 17 52 30 95 104 95 165 0 50 -37 116 -83 146 l-41 27 -1234 5
                                          -1234 5 -23 72 c-82 259 -301 471 -565 546 -56 16 -207 38 -245 36 -11 -1 -52
                                          -5 -91 -10z m219 -374 c87 -23 155 -61 218 -124 185 -185 186 -477 2 -664
                                          -141 -144 -353 -181 -539 -93 -165 78 -265 239 -265 426 0 99 21 170 74 252
                                          109 170 318 253 510 203z"
                                    />
                                    <path
                                      d="M3237 3410 c-108 -23 -267 -98 -352 -167 -123 -100 -223 -247 -271
                                        -398 l-22 -70 -1234 -5 c-1221 -5 -1233 -5 -1268 -26 -117 -68 -117 -240 0
                                        -308 35 -21 47 -21 1268 -26 l1233 -5 27 -80 c90 -265 292 -458 567 -541 77
                                        -23 109 -27 220 -27 152 -1 225 15 353 74 227 105 390 291 457 523 l16 56 377
                                        0 c372 0 377 1 417 23 125 71 125 243 0 314 -40 22 -45 23 -416 23 l-377 0
                                        -26 83 c-68 218 -253 419 -469 510 -155 65 -336 82 -500 47z m318 -370 c203
                                        -70 326 -240 326 -450 0 -133 -44 -240 -138 -333 -91 -91 -202 -137 -331 -137
                                        -365 0 -591 395 -406 710 57 97 157 177 264 211 78 25 210 25 285 -1z"
                                    />
                                    <path
                                      d="M1565 1909 c-157 -26 -308 -103 -434 -223 -108 -104 -180 -218 -221
                                        -354 l-22 -72 -374 0 c-410 0 -418 -1 -466 -58 -78 -93 -52 -229 54 -281 43
                                        -20 58 -21 415 -21 l372 0 16 -57 c46 -158 156 -319 287 -419 414 -319 1003
                                        -197 1253 259 21 38 48 101 60 140 l23 72 1234 5 1234 5 41 27 c46 30 83 96
                                        83 146 0 61 -43 135 -95 165 -15 9 -346 13 -1259 17 l-1240 5 -12 48 c-17 68
                                        -77 190 -128 262 -181 252 -509 386 -821 334z m291 -380 c333 -106 433 -531
                                        185 -780 -249 -248 -666 -148 -782 189 -14 39 -20 84 -20 142 0 187 100 348
                                        265 426 115 55 229 62 352 23z"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="dropdown-menu pr-2 pl-2 dropdown-menu-left text-left">
                                <span
                                  className="dropdown-item text-info click span2"
                                  onClick={() => this.downloadFile(data)}
                                  title="Download File"
                                >
                                  Download
                                </span>
                                <span
                                  className="dropdown-item textPri click span2"
                                  onClick={() => this.deleteFile(data.id)}
                                  title="Delete File"
                                >
                                  Delete
                                </span>
                                <span
                                  className="dropdown-item textSec1 click span2"
                                  onClick={() =>
                                    this.updateFileAccess(
                                      data,
                                      !data.tenantAccess
                                    )
                                  }
                                  title={
                                    !data.tenantAccess
                                      ? "Change open access for tenant"
                                      : "Change file as private"
                                  }
                                >
                                  {!data.tenantAccess
                                    ? "Change Open Access"
                                    : "Change Private Access"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <span className="span2 textSec1">
                              {convertLocalDate(data.createdAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            {this.state.status !== "CLOSE" &&
              this.state.viewFilesArray.filter(
                (todo) => todo.uploadBy !== this.props.userDetail.username
              ).length !== 0 && (
                <>
                  <div style={{ paddingRight: "2%", paddingLeft: "2%" }}>
                    <hr className="line "></hr>
                    <span className="badge badge02 ">Tenant's Files</span>
                  </div>

                  <div className="row ">
                    {this.state.viewFilesArray
                      .filter(
                        (todo) =>
                          todo.uploadBy !== this.props.userDetail.username
                      )
                      .map((data) => {
                        return (
                          !data.deleted &&
                          data.landlordAccess && (
                            <div
                              className=" mt-4 mr-4 ml-4 text-center"
                              style={{ width: "150px" }}
                            >
                              <div className="fileType">
                                {data.fileType === "" ||
                                data.fileType === null ||
                                data.fileType === undefined
                                  ? "file"
                                  : sortText2(
                                      data.fileType.split("/")[1],
                                      0,
                                      4
                                    )}{" "}
                              </div>

                              <span className="span2  pt-2 ">
                                {capitalizeFirstLetter(
                                  sortText2(data.category, 0, 17)
                                )}{" "}
                                - {data.version}{" "}
                              </span>
                              <div className="row">
                                <div
                                  className=" pt-1 "
                                  style={{ width: "30px" }}
                                ></div>
                                <div
                                  className=" pt-1 "
                                  style={{ width: "20px" }}
                                >
                                  <span
                                    className=" text-info click span2 fileTypeMenuPro pt-2 "
                                    onClick={() => this.downloadFile(data)}
                                    title="Download File"
                                  >
                                    <i
                                      className={
                                        this.props.dark
                                          ? "fa fa-download  fa-10x  text-light fileTypeMenuPro1 "
                                          : "fa fa-download  fa-10x  text-dark  fileTypeMenuPro1"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                                <div className="col">
                                  <span className="span2 textSec1">
                                    {convertLocalDate(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                  </div>
                </>
              )}
            {this.state.status !== "CLOSE" &&
              (!this.state.uploadWindow ? (
                <button
                  className="priButton mb-5  pull-right"
                  onClick={() => this.setState({ uploadWindow: true })}
                >
                  Upload Files
                </button>
              ) : (
                <>
                  <div style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                    <hr className="line "></hr>
                  </div>
                  <br></br>
                  <div
                    style={{
                      paddingRight: "2%",
                      paddingLeft: "2%",
                      border: "1px solid #000",
                      borderRadius: "10px ",
                    }}
                  >
                    <span
                      className="closeModal pull-right pointer textPri"
                      onClick={() => this.setState({ uploadWindow: false })}
                    >
                      &times;
                    </span>
                    <p
                      className={
                        this.props.isDark
                          ? "contractTextDark mb-2 mt-3"
                          : "contractText mb-2 mt-3"
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Upload{" "}
                    </p>
                    <SearchInput
                      name="fileType"
                      id="File Type"
                      type="2"
                      target="fileType"
                      onChange={this.onchange}
                      list={[
                        "Draft Agreement",
                        "Final Agreement",
                        "Credit Check",
                        "Background Check",
                      ]}
                      changeState={this.changeState}
                      important="true"
                      errorMessage=" the file type"
                      validate={this.checkValidate(this.state.fileType)}
                      value={this.state.fileType}
                    />

                    <br></br>
                    <div className="row">
                      {this.state.fileDoc.length !== 0 && (
                        <div className="col-4">
                          <div className=" m-4" style={{ width: "120px" }}>
                            <div className="fileType">
                              {sortText2(
                                this.state.fileDoc.type.split("/")[1],
                                0,
                                4
                              )}{" "}
                            </div>

                            <span className="span2  d-flex justify-content-center mt-3">
                              {capitalizeFirstLetter(
                                sortText(this.state.fileDoc.name, 0, 20)
                              )}{" "}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="col">
                        <Dropzone
                          // accept=".pdf,.jpeg,.png,.jpg,.doc,.docx"
                          maxSize={10485760}
                          onDrop={(acceptedFiles) => {
                            if (acceptedFiles[0] === undefined) {
                              this.props.appendAlert(
                                "file size is too Long (max size = 10mb) ",
                                "danger"
                              );
                            } else {
                              this.setState({ fileDoc: acceptedFiles[0] });
                            }
                          }}
                          inputWithFilesContent="Upload more"
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <>
                              <div
                                className={
                                  this.state.next &&
                                  this.state.fileDoc.length === 0
                                    ? "alert"
                                    : "mt-4"
                                }
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />

                                <div
                                  className="dragDrop text-center pointer"
                                  style={{
                                    fontSize: "60px",
                                    minHeight: "130px",
                                    paddingTop: "40px",
                                  }}
                                >
                                  <div className="row">
                                    <div className="col">
                                      <i
                                        className={
                                          this.state.fileDoc.length === 0
                                            ? this.props.dark
                                              ? this.state.next
                                                ? "fa fa-cloud-upload  fa-10x dragIcon textPri vert-move "
                                                : "fa fa-cloud-upload  fa-10x dragIcon text-light vert-move "
                                              : this.state.next
                                              ? "fa fa-cloud-upload  fa-10x dragIcon textPri vert-move "
                                              : "fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                            : this.props.dark
                                            ? "fa fa-check-circle  fa-10x dragIcon text-light vert-move "
                                            : "fa fa-check-circle  fa-10x dragIcon text-dark vert-move "
                                        }
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col">
                                      <p
                                        className={
                                          this.props.dark
                                            ? "licenseHeadDark"
                                            : "licenseHead"
                                        }
                                      >
                                        {this.state.fileDoc.length === 0
                                          ? "Drag Or Click to upload file"
                                          : "Drag Or Click to change file"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </Dropzone>
                        <span className="span2 textPri text-center pull-right ">
                          Max file size is 10MB.
                        </span>
                      </div>
                    </div>

                    <br></br>
                    <button
                      className="priButton mb-5  pull-right"
                      onClick={() => this.saveUpload()}
                    >
                      Save
                    </button>
                    <br></br>
                    {this.state.fileButtonDisable && (
                      <>
                        <div className="pull-right">
                          <Loader3 transY="60px" transX="60px" />
                        </div>
                        <br></br>
                      </>
                    )}
                    <br></br>
                  </div>
                </>
              ))}
          </SmallModalContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    contract: state.contractReducer,
    contractArray: state.contract,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setContract })(ContractLandlord)
);
