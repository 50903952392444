import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "./RentalUnits.css";
import { appendAlert } from "../../../actions";
import CurrencyFormat from "../../UIServices/CurrencyFormat";
import { addressFormatter, exceptNull } from "../../../Defined/Function";
import Loader2 from "../../Loader/Loader2";
import Loader3 from "../../Loader/loader3";

const RentalUnitModal = (props) => {
  const role = useSelector((state) => state.role);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.isAuth);
  const dispatch = useDispatch();
  const customerPaymentStatus = useSelector((state) => state.customerPayment);
  const [viewingDay, setViewingDay] = useState([]);
  const [viewingTime, setViewingTime] = useState([]);
  const timeConvert = (time) => {
    // Check correct time format and split into components
    if (time === null || time === undefined || time === "") return "-";
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    time[3] = "";
    return time.join(""); // return adjusted time or original string
  };
  useEffect(() => {
    if (props.modalData.viewingTimes) {
      if (props.modalData.viewingTimes.length > 0) {
        var x = [];
        var y = [];
        props.modalData.viewingTimes.forEach((item) => {
          x.push(item.dayOfWeek);
          y.push(item.time);
        });
        if (x.length > 0 || y.length > 0) {
          setViewingDay(x);
          setViewingTime(y);
        } else {
          setViewingDay([""]);
          setViewingTime([""]);
        }
      }
    }
  }, [props.modalData]);

  async function apply(id, country,state, applicationFee, modal) {
    if (auth) {
      if (
        user.username === "" ||
        user.username === null ||
        user.firstName === "" ||
        user.firstName === null ||
        user.lastName === "" ||
        user.lastName === null ||
        user.address.state === "" ||
        user.address.state === null ||
        user.address.country === "" ||
        user.address.country === null ||
        user.address.zip === "" ||
        user.address.zip === null ||
        user.address.city === "" ||
        user.address.city === null ||
        user.address.address === "" ||
        user.address.address === "" ||
        user.mobileNumber === "" ||
        user.mobileNumber === null
      ) {
        props.history.push({
          pathname: "/profile",
          state: {
            rentalEntityId: props.match.params.id,
          },
        });
        // } else if (customerPaymentStatus !== "verified" && role !== "Guest") {
        //   props.history.push({
        //     pathname: "/payments/add",
        //     state: {
        //       data: user,
        //       status: customerPaymentStatus,
        //       type: "initial",
        //       rentalEntityId: null,
        //     },
        //   });
        //   dispatch(appendAlert("Please add payment method.", "warning"));
      } else {
        if (role) {
          if (role !== "Landlord") {
            if (country === "Canada") {
              if(state === "British Columbia"){
                props.history.push({
                  pathname: "/application/british_columbia/" + id,
                  state: { applicationFee: applicationFee, rent: modal.rent },
                });
              }else{
                props.history.push({
                  pathname: "/application/ontario/" + id,
                  state: { applicationFee: applicationFee, rent: modal.rent },
                });
              }
             
            } else {
              if (props.match.params.appId) {
                props.history.push({
                  pathname:
                    "/application/secondary/" +
                    id +
                    "/" +
                    props.match.params.appId +
                    "/" +
                    props.match.params.applicant +
                    "/" +
                    props.match.params.order,
                  state: {
                    applicationFee: applicationFee,
                    rent: modal.rent,
                    email: atob(props.match.params.applicant),
                    type: "directEmailTenantLogin",
                  },
                });
              } else {
                props.history.push({
                  pathname: "/application/" + id,
                  state: { applicationFee: applicationFee, rent: modal.rent },
                });
              }
            }
          } else {
            dispatch(
              appendAlert("Sorry!!! Landlord can't Apply Units", "warning")
            );
          }
        } else {
          if (country === "Canada") {
            props.history.push({
              pathname: "/application/ontario/" + id,
              state: { applicationFee: applicationFee, rent: modal.rent },
            });
          } else {
            if (props.match.params.appId) {
              props.history.push({
                pathname:
                  "/application/secondary/" +
                  id +
                  "/" +
                  props.match.params.appId +
                  "/" +
                  props.match.params.applicant +
                  "/" +
                  props.match.params.order,
                state: {
                  applicationFee: applicationFee,
                  rent: modal.rent,
                  email: atob(props.match.params.applicant),
                  type: "directEmailTenantLogin",
                },
              });
            } else {
              props.history.push({
                pathname: "/application/" + id,
                state: { applicationFee: applicationFee, rent: modal.rent },
              });
            }
          }
        }
      }
    } else {
      props.history.push({
        pathname: "/profile",
        state: {
          rentalEntityId: props.match.params.id,
        },
      });
    }
  }
  async function applyOffice(id) {
    if (role) {
      if (role !== "Landlord") {
        props.history.push({
          pathname: "/application/office/" + id,
          state: props.modalData,
        });
      } else {
        dispatch(appendAlert("Sorry!!! Landlord can't Apply Units", "warning"));
      }
    } else {
      props.history.push({
        pathname: "/application/office/" + id,
        state: props.modalData,
      });
    }
  }
  return (
    <>
      <div
        className="modal fade bd-example-modal-xl"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div
              className="modal-header shadow"
              style={{ height: "80px", borderBottom: "1px solid #e09b81" }}
            >
              <h1
                className="modal-title textAvailableUnit textPri"
                id="exampleModalLabel"
                style={{ fontSize: "50px", marginTop: "-10px" }}
              >
                Unit Detail
              </h1>
            </div>
            <div className="modal-body bodyAvailable pt-0  pb-0">
              <div className="row ">
                <div className="detailAvailable ">
                  <div className="row">
                    {props.spinner ? (
                      <>
                        <div
                          className="col-md mt-5 listingModalLoader"
                          style={{ minHeight: "300px" }}
                        >
                          <Loader2 />
                        </div>
                        <div
                          className="col text-center align-self-center listingModalLoader1"
                          style={{ marginTop: "15%" }}
                        >
                          <i
                            className="fa fa-picture-o fa-3x  textSec"
                            aria-hidden="true"
                          ></i>
                          <p className=" two">Image Loading . . .</p>
                          <Loader3 transY="20px" transX="0px" />
                        </div>
                      </>
                    ) : props.images && props.images.length === 0 ? (
                      <div
                        className="col text-center align-self-center"
                        style={{ marginTop: "15%" }}
                      >
                        <i
                          className="fa fa-picture-o fa-3x  textSec"
                          aria-hidden="true"
                        ></i>
                        <p className=" two">No image Available</p>
                      </div>
                    ) : (
                      props.images.map((img, index) => (
                        // index===0?<></>:
                        <div className="col-md-6 img-wrap p-0">
                          <img
                            src={props.images[index]}
                            alt=""
                            className="imageAvailable"
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div className="col detailAvailable pb-5">
                  <h3 className="detailsHead textPri pb-2">Basic Details</h3>
                  {props.modalData.unitName ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Unit </h5>
                      </div>
                      <div className="col-6">
                        <span
                          className="span2 "
                          style={{
                            display: "inline-block",
                            textJustify: "inter-word",
                            textAlign: "justify",
                            whiteSpace: "normal",
                          }}
                        >
                          :&nbsp;
                          {props.modalData.unitName}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.property ? (
                    <>
                      <div className="row">
                        <div className="col-6 unitDetailListTop">
                          <h5 className="detailContentAvailable">Address</h5>
                        </div>
                        <div className="col-6">
                          <span className="span2">:</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1 unitDetailListTop"></div>
                        <div className="col-11">
                          <span
                            className="span5"
                            style={{
                              display: "inline-block",
                              textJustify: "inter-word",
                              textAlign: "justify",
                            }}
                          >
                            {addressFormatter(props.modalData.property.address)}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {props.modalData.rent ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Rent </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;
                          <CurrencyFormat value={props.modalData.rent} />
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.sqFeet ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Square feet </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.sqFeet} sqft
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.securityDeposit ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          Security Deposit{" "}
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;
                          <CurrencyFormat
                            value={props.modalData.securityDeposit}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.bedRooms ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Bedrooms </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.bedRooms}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.quarterBathrooms ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          1/4 Bathrooms{" "}
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.quarterBathrooms}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.halfBathrooms ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          1/2 Bathrooms{" "}
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.halfBathrooms}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {props.modalData.threeQuarterBathrooms ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          3/4 Bathrooms{" "}
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.threeQuarterBathrooms}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.fullBathrooms ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          Full Bathrooms{" "}
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.fullBathrooms}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {props.modalData.bathroomsAttach ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          Attached bathrooms
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.bathroomsAttach}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.bathroomsSeparate ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          Separate bathrooms
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.bathroomsSeparate}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.listingHeading ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Unit Status</h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.listingHeading}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.leasePeriod ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">
                          Lease Duration
                        </h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;{props.modalData.leasePeriod}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-6 unitDetailListTop">
                      <h5 className="detailContentAvailable">Description</h5>
                    </div>
                    <div className="col-6">
                      <span className="span2">:</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 unitDetailListTop"></div>
                    <div className="col-11">
                      <span
                        className="span5"
                        style={{
                          display: "inline-block",
                          textJustify: "inter-word",
                          textAlign: "justify",
                        }}
                      >
                        {props.modalData.description === ""
                          ? "-"
                          : props.modalData.description}
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <h3 className="detailsHead textPri pb-2">
                    Additional Details
                  </h3>
                  {props.modalData.petPolicy !== "don'tSpecify" && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">Pet policy</h5>
                        </div>

                        <div className="col-6">
                          <li className="span2">
                            {props.modalData.petPolicy === "petsNegotiable"
                              ? "Negotiable"
                              : props.modalData.petPolicy === "noPets"
                              ? "No Pets"
                              : props.modalData.petPolicy === "smallDogsCats"
                              ? "Small Dogs,Cats"
                              : props.modalData.petPolicy === "petsOk"
                              ? "Pets OK"
                              : "Don't Specify"}
                          </li>
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {props.modalData.basement !== "none" && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">Basement</h5>
                        </div>
                        <div className="col-6">
                          <li className="span2">
                            {props.modalData.basement === "finished"
                              ? "Finished"
                              : props.modalData.basement === "unFinished"
                              ? "Unfinished"
                              : "None"}
                          </li>
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {props.modalData.parkingCovered ||
                  props.modalData.parkingDedicatedSpot ||
                  props.modalData.parkingDriveway ||
                  props.modalData.parkingGarage ||
                  props.modalData.parkingOnStreet ||
                  props.modalData.parkingPrivateLot ? (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">Parking</h5>
                        </div>
                        <div className="col-6">
                          {props.modalData.parkingCovered ? (
                            <li className="span2">Covered</li>
                          ) : null}{" "}
                          {props.modalData.parkingDedicatedSpot ? (
                            <li className="span2">Dedicated Spot</li>
                          ) : null}
                          {props.modalData.parkingDriveway ? (
                            <li className="span2">Driveway</li>
                          ) : null}
                          {props.modalData.parkingGarage ? (
                            <li className="span2">Garage</li>
                          ) : null}
                          {props.modalData.parkingOnStreet ? (
                            <li className="span2">On Street</li>
                          ) : null}
                          {props.modalData.parkingPrivateLot ? (
                            <li className="span2">Private Lot</li>
                          ) : null}
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  ) : (
                    ""
                  )}

                  {props.modalData.outDoorBalcony ||
                  props.modalData.outDoorGarden ||
                  props.modalData.outDoorPatio ||
                  props.modalData.outDoorPrivateYard ||
                  props.modalData.outDoorSharedYard ? (
                    <>
                      <div className="row ">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">Outdoor </h5>
                        </div>
                        <div className="col-6">
                          {props.modalData.outDoorBalcony ? (
                            <li className="span2">Balcony</li>
                          ) : null}
                          {props.modalData.outDoorGarden ? (
                            <li className="span2">Garden</li>
                          ) : null}
                          {props.modalData.outDoorPatio ? (
                            <li className="span2">Patio</li>
                          ) : null}
                          {props.modalData.outDoorPrivateYard ? (
                            <li className="span2">Private Yard</li>
                          ) : null}
                          {props.modalData.outDoorSharedYard ? (
                            <li className="span2">Shared Yard</li>
                          ) : null}
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  ) : (
                    ""
                  )}

                  {props.modalData.washerDrier !== "none" && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">
                            Washer / Dryer{" "}
                          </h5>
                        </div>
                        <div className="col-6">
                          <li className="span2">
                            {props.modalData.washerDrier === "inUnit"
                              ? "In Unit"
                              : props.modalData.washerDrier === "onSite"
                              ? "On Site"
                              : "None"}
                          </li>
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {props.modalData.laundryType !== "none" && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">
                            Laundry type
                          </h5>
                        </div>
                        <div className="col-6">
                          <li className="span2">
                            {props.modalData.laundryType === "inUnit"
                              ? "In Unit"
                              : props.modalData.laundryType === "onSite"
                              ? "On Site"
                              : "None"}
                          </li>
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {(props.modalData.wheelChair ||
                    props.modalData.dishwasher ||
                    props.modalData.microwave ||
                    props.modalData.oven ||
                    props.modalData.refrigerator ||
                    props.modalData.freezer ||
                    props.modalData.trashCompactor ||
                    props.modalData.garbageDisposal) && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">
                            Appliances{" "}
                          </h5>
                        </div>
                        <div className="col-6">
                          {props.modalData.dishwasher ? (
                            <li className="span2">Dish Washer</li>
                          ) : null}
                          {props.modalData.microwave ? (
                            <li className="span2">Microwave</li>
                          ) : null}
                          {props.modalData.oven ? (
                            <li className="span2">Range / Oven</li>
                          ) : null}
                          {props.modalData.refrigerator ? (
                            <li className="span2">Refrigerator</li>
                          ) : null}
                          {props.modalData.freezer ? (
                            <li className="span2">Freezer</li>
                          ) : null}
                          {props.modalData.trashCompactor ? (
                            <li className="span2">Trash Compactor</li>
                          ) : null}
                          {props.modalData.garbageDisposal ? (
                            <li className="span2">Garbage Disposal</li>
                          ) : null}
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {(props.modalData.heatingBaseboard ||
                    props.modalData.heatingForcedAir ||
                    props.modalData.heatingGeothermal ||
                    props.modalData.heatingHeatPump ||
                    props.modalData.heatingRadiant ||
                    props.modalData.heatingStove ||
                    props.modalData.heatingWall ||
                    props.modalData.heatingOther) && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">
                            Heating type{" "}
                          </h5>
                        </div>
                        <div className="col-6">
                          {props.modalData.heatingBaseboard ? (
                            <li className="span2">Baseboard</li>
                          ) : null}
                          {props.modalData.heatingForcedAir ? (
                            <li className="span2">Forced Air</li>
                          ) : null}
                          {props.modalData.heatingGeothermal ? (
                            <li className="span2">Geothermal</li>
                          ) : null}
                          {props.modalData.heatingHeatPump ? (
                            <li className="span2">Heat Pump</li>
                          ) : null}
                          {props.modalData.heatingRadiant ? (
                            <li className="span2">Radiant</li>
                          ) : null}
                          {props.modalData.heatingStove ? (
                            <li className="span2">Stove</li>
                          ) : null}
                          {props.modalData.heatingWall ? (
                            <li className="span2">Wall</li>
                          ) : null}
                          {props.modalData.heatingOther ? (
                            <li className="span2">Other</li>
                          ) : null}
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {(props.modalData.coolingCentral ||
                    props.modalData.coolingGeothermal ||
                    props.modalData.coolingEvaporative ||
                    props.modalData.coolingRefrigeration ||
                    props.modalData.coolingSolar ||
                    props.modalData.coolingWall ||
                    props.modalData.coolingOther) && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">
                            Cooling type{" "}
                          </h5>
                        </div>
                        <div className="col-6">
                          {props.modalData.coolingCentral ? (
                            <li className="span2">Central</li>
                          ) : null}
                          {props.modalData.coolingGeothermal ? (
                            <li className="span2">Geothermal</li>
                          ) : null}
                          {props.modalData.coolingEvaporative ? (
                            <li className="span2">Evaporative</li>
                          ) : null}
                          {props.modalData.coolingRefrigeration ? (
                            <li className="span2">Refrigeration</li>
                          ) : null}
                          {props.modalData.coolingSolar ? (
                            <li className="span2">Solar</li>
                          ) : null}
                          {props.modalData.coolingWall ? (
                            <li className="span2">Wall</li>
                          ) : null}
                          {props.modalData.coolingOther ? (
                            <li className="span2">Other</li>
                          ) : null}
                        </div>
                      </div>
                      <hr className="lightLine "></hr>
                    </>
                  )}

                  {(props.modalData.wheelChair ||
                    props.modalData.dishwasher ||
                    props.modalData.microwave ||
                    props.modalData.oven ||
                    props.modalData.refrigerator ||
                    props.modalData.freezer ||
                    props.modalData.trashCompactor ||
                    props.modalData.garbageDisposal) && (
                    <>
                      <div className="row">
                        <div className="col-6 ">
                          <h5 className="detailContentAvailable">Others </h5>
                        </div>
                        <div className="col-6">
                          {props.modalData.gym ? (
                            <li className="span2">Gym/Fitness</li>
                          ) : null}
                          {props.modalData.airConditioner ? (
                            <li className="span2">Air Conditioner</li>
                          ) : null}
                          {props.modalData.hardwoodFloors ? (
                            <li className="span2">Hardwood Floors</li>
                          ) : null}
                          {props.modalData.fireplace ? (
                            <li className="span2">Fireplace</li>
                          ) : null}
                          {props.modalData.walkInCloset ? (
                            <li className="span2">Walk-In Closet</li>
                          ) : null}
                          {props.modalData.furnisher ? (
                            <li className="span2">Furnished</li>
                          ) : null}
                          {props.modalData.pool ? (
                            <li className="span2">Pool</li>
                          ) : null}
                          {props.modalData.hotTub ? (
                            <li className="span2">Hot Tub</li>
                          ) : null}
                          {props.modalData.wheelChair ? (
                            <li className="span2">Wheel Chair</li>
                          ) : null}
                          {props.modalData.storage ? (
                            <li className="span2">Storage</li>
                          ) : null}
                          {props.modalData.amenities ? (
                            <li className="span2">Amenities</li>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}

                  {props.modalData.walkInTime ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Walking Time</h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;
                          {props.modalData.walkInTime && (
                            <i className="fa fa-check" aria-hidden="true"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.modalData.walkInDate ? (
                    <div className="row">
                      <div className="col-6 unitDetailListTop">
                        <h5 className="detailContentAvailable">Walking Time</h5>
                      </div>
                      <div className="col-6">
                        <span className="span2">
                          :&nbsp;
                          {props.modalData.walkInDate && (
                            <i className="fa fa-check" aria-hidden="true"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {viewingDay.length !== 0 && (
                    <>
                      <h3 className="detailsHead textPri">Tour Availability</h3>

                      <div className="row">
                        {viewingDay.map((day, index) => (
                          <>
                            <div className="col-3 ">
                              <span className="span2">
                                {exceptNull(viewingDay[index])}
                              </span>
                            </div>
                            <div className="col-9">
                              <span className="span2">
                                :&nbsp;
                                {timeConvert(viewingTime[index])}
                              </span>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  )}

                  {(props.modalData.securityChk ||
                    props.modalData.backgroundChk) && (
                    <>
                      <h3 className="detailsHead textPri pb-2">
                        Screening Details
                      </h3>

                      <div className="row">
                        <div className="col-6 unitDetailListTop">
                          <h5 className="detailContentAvailable">
                            Security Check{" "}
                          </h5>
                        </div>
                        <div className="col-6">
                          <span className="span2">
                            :&nbsp;
                            {props.modalData.securityChk ? (
                              <i className="fa fa-check" aria-hidden="true"></i>
                            ) : (
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 unitDetailListTop">
                          <h5 className="detailContentAvailable">
                            Background Check{" "}
                          </h5>
                        </div>
                        <div className="col-6">
                          <span className="span2">
                            :&nbsp;
                            {props.modalData.backgroundChk ? (
                              <i className="fa fa-check" aria-hidden="true"></i>
                            ) : (
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="modal-footer pt-3 shadow1"
              style={{ borderTop: "1px solid #d15428" }}
            >
              {!props.match.params.appId && (
                <button className="pri" data-dismiss="modal">
                  {" "}
                  Close
                </button>
              )}
              {props.type === "apply" ? (
                <button
                  className={
                    auth
                      ? customerPaymentStatus === null
                        ? "secDisable notAllow"
                        : " buttonPri"
                      : "buttonPri"
                  }
                  disabled={auth ? customerPaymentStatus === null : false}
                  onClick={
                    props.modalData.property &&
                    (props.modalData.property.type === "OFFICE"
                      ? () => applyOffice(props.modalData.id)
                      : () =>
                          apply(
                            props.modalData.id,
                            props.modalData.property.address.country,
                            props.modalData.property.address.state,
                            props.modalData.property.applicationFee,
                            props.modalData
                          ))
                  }
                  data-dismiss="modal"
                >
                  Apply Now
                </button>
              ) : (
                <button
                  className=" buttonPri"
                  onClick={() =>
                    props.history.push({
                      pathname:
                        "/properties/" +
                        props.match.params.propsId +
                        "/listings/" +
                        props.modalData.id +
                        "/edit-listing",
                      state: {
                        data: props.modalData1,
                        type: "edit",
                        propsId: props.match.params.propsId,
                      },
                    })
                  }
                  data-dismiss="modal"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(RentalUnitModal);
