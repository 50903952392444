import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import noData from "./Images/nodata2.png";
import "./NoData.css";
import "./style.css";

class NoData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid page404">
        <div className="row">
          <div className="col-sm text-center">
            <img src={noData} className="noData1" alt="noData" />
          </div>
          <div className="col ">
            {/* <h1 className="head"></h1> */}
            <h1 className="head2">No {this.props.message}!</h1>
            <p className="span1  pr-5">
              {this.props.sub}
            </p>
            <button
              className="  pri"
              id="signUp"
              onClick={() => this.props.history.push("/")}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NoData);
