import React, { Component } from "react";
import "../application.css";
import "../dropdownForm.css";
import Loader from "../../../Loader/Loader";
import NoData from "../../../../Messages/NoData";
import { getAllApplication } from "../../../../Services/ApiServices";
import { connect } from "react-redux";
import { appendAlert, setApplication } from "../../../../actions";
import { withRouter } from "react-router-dom";
import ApplicationLandlordList from "./ApplicationLandlordList";

class ApplicationLandlord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllApp();
  }
  async getAllApp() {
    try {
      await getAllApplication().then(async(response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            await this.props.setApplication(null);
            this.props.setApplication(response.data.reverse());
          } else {
            this.props.setApplication([]);
          }
        } else {
          
          this.props.setApplication(null);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleActiveKeyChange = (activeKey) => {
    if (this.state.activeKey === activeKey) {
      activeKey = -1;
    }
    this.setState({ activeKey });
  };

  render() {
    return this.props.applicationArray === null ? (
      <Loader />
    ) : this.props.applicationArray.length === 0 ? (
      <div style={{ paddingTop: "108px", minHeight: window.screen.height }}>
        {" "}
        <NoData
          message="pending applications to process"
          sub=""
        ></NoData>
      </div>
    ) : (
      <div
        className="row"
        style={{ paddingTop: "83px"}}
      >
        {this.props.applicationArray.map((data, index) => {
          return (
            <div className="col-md-12 col-lg-6 col-xl-6 mb-4" key={index}>
              <ApplicationLandlordList
                dark={this.props.dark}
                data={data}
                eventKey={index}
                history={this.props.history}
                handleActiveKeyChange={this.handleActiveKeyChange}
                appendAlert={this.props.appendAlert}
              />
            </div>
          );
        })}
        <br /> <br /> <br />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    application: state.applicationReducer,
    applicationArray: state.application,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setApplication })(ApplicationLandlord)
);
