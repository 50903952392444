import { PDFDocument } from "pdf-lib";
import React, { useEffect } from "react";
import Loader from "../../../../../Loader/Loader";
import { url } from "../../../../../../Services/ApiServices";
import CookieService from "../../../../../../Services/CookieService";

export default function Stepper05Elements(props) {
  var { values, changeState, UserData, tenantData, propertyData } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function ModifyString(value) {
    if (value) {
      return value.toString();
    }
    return "";
  }
  useEffect(() => {
    changeState("changeBlob", false);
    async function checkPdfDownload() {
      if (values.formPdfBytes === null) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append(
          "Authorization",
          "Bearer " + CookieService.getCookie("axTok")
        );
        const requestOptions = {
          method: "GET", // Replace with the appropriate HTTP method (GET, POST, PUT, DELETE, etc.)
          headers: headers,
        };
        const formUrl = url + "api/contract/britishColumbia/template/download";
        const formPdfBytes = await fetch(formUrl, requestOptions).then((res) =>
          res.arrayBuffer()
        );
        CreatePdf(formPdfBytes);
      } else {
        CreatePdf(values.formPdfBytes);
      }
    }
    async function CreatePdf(formPdfBytes) {
      let today = new Date().toISOString().split("T")[0];
      const pdfDoc = await PDFDocument.load(formPdfBytes);
      const form = pdfDoc.getForm();

      if (values.agreementPaymentFixedTermEndContinue && values.signatureObject2 !== null) {
        const base64Image2 = values.signatureObject2.split(",")[1];
        const imageBytes2 = Buffer.from(base64Image2, "base64");
        const image2 = await pdfDoc.embedPng(imageBytes2);
        const pageIndex2 = 1;
        const page2 = pdfDoc.getPage(pageIndex2);
        const x = 454;
        const y = 304;

        page2.drawImage(image2, {
          x: x,
          y: y,
          width: 50,
          height: 50,
        });
      }
      if (values.signatureObject !== null) {
        const base64Image = values.signatureObject.split(",")[1];
        const imageBytes = Buffer.from(base64Image, "base64");
        const image = await pdfDoc.embedPng(imageBytes);
        const pageIndex = 5;
        const page = pdfDoc.getPage(pageIndex);
        const x = 89;
        const y = 536;

        page.drawImage(image, {
          x: x,
          y: y,
          width: 50,
          height: 30,
        });
      }

      form
        .getTextField("Landlord1 last name")
        .setText(ModifyString(UserData.lastName));
      form.getTextField("Landlord1 last name").enableReadOnly();
      form
        .getTextField("Landlord1 first and middle names")
        .setText(
          ModifyString(UserData.firstName) +
            " " +
            ModifyString(UserData.middleName)
        );
      form.getTextField("Landlord1 first and middle names").enableReadOnly();
      form.getTextField("Landlord2 last name").setText("");
      form.getTextField("Landlord2 last name").enableReadOnly();
      form.getTextField("Landlord2 first and middle names").setText("");
      form.getTextField("Landlord2 first and middle names").enableReadOnly();
      form
        .getTextField("Tenant1 last name")
        .setText(ModifyString(tenantData && tenantData[0].lastName));
      form.getTextField("Tenant1 last name").enableReadOnly();
      form
        .getTextField("Tenant1 first and middle names")
        .setText(
          ModifyString(tenantData && tenantData[0].firstName) +
            " " +
            ModifyString(tenantData && tenantData[0].middleName)
        );
      form.getTextField("Tenant1 first and middle names").enableReadOnly();

      if (tenantData.length > 1) {
        form
          .getTextField("Tenant2 last name")
          .setText(ModifyString(tenantData && tenantData[1].lastName));

        form
          .getTextField("Tenant2 first and middle names")
          .setText(
            ModifyString(tenantData && tenantData[1].firstName) +
              " " +
              ModifyString(tenantData && tenantData[1].middleName)
          );
      }
      form.getTextField("Tenant2 last name").enableReadOnly();
      form.getTextField("Tenant2 first and middle names").enableReadOnly();
      form.getTextField("Tenant1 phone number code").setText("");
      form.getTextField("Tenant1 phone number code").enableReadOnly();
      form
        .getTextField("Tenant1 phone number")
        .setText(ModifyString(tenantData && tenantData[0].mobileNumber));
      form.getTextField("Tenant1 phone number").enableReadOnly();
      form.getTextField("Tenant1 other phone number code").setText("");
      form.getTextField("Tenant1 other phone number code").enableReadOnly();
      form.getTextField("Tenant1 other phone number").setText("");
      form.getTextField("Tenant1 other phone number").enableReadOnly();

      form
        .getTextField("Tenant's unit #")
        .setText(
          ModifyString(propertyData && propertyData.rentalEntity.unitName)
        );
      form.getTextField("Tenant's unit #").enableReadOnly();
      form
        .getTextField("Tenant's street number and street name")
        .setText(
          ModifyString(
            propertyData && propertyData.rentalEntity.property.address.address
          )
        );
      form
        .getTextField("Tenant's street number and street name")
        .enableReadOnly();
      form
        .getTextField("Tenant's City")
        .setText(
          ModifyString(
            propertyData && propertyData.rentalEntity.property.address.city
          )
        );
      form.getTextField("Tenant's City").enableReadOnly();
      form
        .getTextField("Tenant's Province")
        .setText(
          ModifyString(
            propertyData && propertyData.rentalEntity.property.address.state
          )
        );
      form.getTextField("Tenant's Province").enableReadOnly();
      form
        .getTextField("Tenant's Postalcode")
        .setText(
          ModifyString(
            propertyData && propertyData.rentalEntity.property.address.zip
          )
        );
      form.getTextField("Tenant's Postalcode").enableReadOnly();
      // form.getCheckBox("landlord").check();
      if (values.landlord) {
        form
          .getTextField("Landlord's unitsite")
          .setText(ModifyString(values.landlordUnit));
        form.getTextField("Landlord's unitsite").enableReadOnly();
        form
          .getTextField("Landlord's street number and street name")
          .setText(ModifyString(values.landlordStreet));
        form
          .getTextField("Landlord's street number and street name")
          .enableReadOnly();
        form
          .getTextField("Landlord's city")
          .setText(ModifyString(values.landlordCity));
        form.getTextField("Landlord's city").enableReadOnly();
        form
          .getTextField("Landlord's province")
          .setText(ModifyString(values.landlordProvince));
        form.getTextField("Landlord's province").enableReadOnly();
        form
          .getTextField("Landlord's postal code")
          .setText(ModifyString(values.landlordPostalCode));
        form.getTextField("Landlord's postal code").enableReadOnly();
        form.getTextField("Landlord's daytime phone number code").setText("");
        form
          .getTextField("Landlord's daytime phone number code")
          .enableReadOnly();
        form
          .getTextField("Landlord's daytime phone number")
          .setText(ModifyString(values.landlordDayTimePhoneNumber));
        form.getTextField("Landlord's daytime phone number").enableReadOnly();
        form.getTextField("Landlord's other phone number code").setText("");
        form
          .getTextField("Landlord's other phone number code")
          .enableReadOnly();
        form
          .getTextField("Landlord's other phone number")
          .setText(ModifyString(values.landlordPhoneNumber));
        form.getTextField("Landlord's other phone number").enableReadOnly();
        form
          .getTextField("Landlord's fax number code")
          .setText(ModifyString(""));
        form.getTextField("Landlord's fax number code").enableReadOnly();
        form
          .getTextField("Landlord's fax number")
          .setText(ModifyString(values.landlordFaxNumber));
        form.getTextField("Landlord's fax number").enableReadOnly();
      } else {
        form
          .getTextField("Landlord's unitsite")
          .setText(ModifyString(values.landlordAgentUnit));
        form.getTextField("Landlord's unitsite").enableReadOnly();
        form
          .getTextField("Landlord's street number and street name")
          .setText(ModifyString(values.landlordAgentStreet));
        form
          .getTextField("Landlord's street number and street name")
          .enableReadOnly();
        form
          .getTextField("Landlord's city")
          .setText(ModifyString(values.landlordAgentCity));
        form.getTextField("Landlord's city").enableReadOnly();
        form
          .getTextField("Landlord's province")
          .setText(ModifyString(values.landlordAgentProvince));
        form.getTextField("Landlord's province").enableReadOnly();
        form
          .getTextField("Landlord's postal code")
          .setText(ModifyString(values.landlordAgentPostalCode));
        form.getTextField("Landlord's postal code").enableReadOnly();
        form.getTextField("Landlord's daytime phone number code").setText("");
        form
          .getTextField("Landlord's daytime phone number code")
          .enableReadOnly();
        form
          .getTextField("Landlord's daytime phone number")
          .setText(ModifyString(values.landlordAgentDayTimePhoneNumber));
        form.getTextField("Landlord's daytime phone number").enableReadOnly();
        form.getTextField("Landlord's other phone number code").setText("");
        form
          .getTextField("Landlord's other phone number code")
          .enableReadOnly();
        form
          .getTextField("Landlord's other phone number")
          .setText(ModifyString(values.landlordAgentPhoneNumber));
        form.getTextField("Landlord's other phone number").enableReadOnly();
        form
          .getTextField("Landlord's fax number code")
          .setText(ModifyString(""));
        form.getTextField("Landlord's fax number code").enableReadOnly();
        form
          .getTextField("Landlord's fax number")
          .setText(ModifyString(values.landlordAgentFaxNumber));
        form.getTextField("Landlord's fax number").enableReadOnly();
      }
      let moveInDate = new Date(values.moveInDate);
      form
        .getTextField("Tenancy agreement starts on day")
        .setText(ModifyString(moveInDate.getDate()).padStart(2, "0"));
      form.getTextField("Tenancy agreement starts on day").enableReadOnly();
      form
        .getTextField("Tenancy agreement starts on month")
        .setText(ModifyString(moveInDate.getMonth() + 1).padStart(2, "0"));
      form.getTextField("Tenancy agreement starts on month").enableReadOnly();
      form
        .getTextField("Tenancy agreement starts on year")
        .setText(ModifyString(moveInDate.getFullYear()));
      form.getTextField("Tenancy agreement starts on year").enableReadOnly();

      if (values.agreementPaymentMonthBasicType) {
        form.getCheckBox("Is 2A").check();
      } else if (values.agreementPaymentPeriodicType) {
        form.getCheckBox("Is 2B").check();
        if (values.agreementPaymentWeekly) {
          form.getCheckBox("Is 2B weekly").check();
        } else if (values.agreementPaymentBiWeekly) {
          form.getCheckBox("Is 2B biweekly").check();
        } else if (values.agreementPaymentOther) {
          form.getCheckBox("Is 2B  other").check();
          form
            .getTextField("2B other")
            .setText(ModifyString(values.agreementPaymentOtherType));
        }
      } else if (values.agreementPaymentFixedType) {
        form.getCheckBox("Is 2C").check();
        form
          .getTextField("Tenancy agreement ends on day")
          .setText(
            ModifyString(
              new Date(values.agreementPaymentFixedTermEndVacate).getDate()
            ).padStart(2, "0")
          );
        form
          .getTextField("Tenancy agreement ends on month")
          .setText(
            ModifyString(
              new Date(values.agreementPaymentFixedTermEndVacate).getMonth() + 1
            ).padStart(2, "0")
          );
        form
          .getTextField("Tenancy agreement ends on year")
          .setText(
            ModifyString(
              new Date(values.agreementPaymentFixedTermEndVacate).getFullYear()
            ).padStart(2, "0")
          );
        if (values.agreementPaymentFixedTermEndNotContinue) {
          form.getCheckBox("Is 2D").check();
        } else if (values.agreementPaymentFixedTermEndContinue){
          form.getCheckBox("Is 2E").check();
          form
            .getTextField("Reason tenant vacate")
            .setText(ModifyString(values.reasonTenancyVacate));
          form
            .getTextField("Tenancy Regulation section number")
            .setText(ModifyString(values.regulationTenancyNumber));
          // form
          //   .getTextField("Landlord's Initials")
          //   .setText(ModifyString(UserData.firstName[0].toUpperCase()));
          // form
          //   .getTextField("Tenant's initials")
          //   .setText(ModifyString(tenantData[0].firstName[0].toUpperCase()));
        }
      }
      form.getCheckBox("Is 2A").enableReadOnly();
      form.getCheckBox("Is 2B").enableReadOnly();
      form.getCheckBox("Is 2B weekly").enableReadOnly();
      form.getCheckBox("Is 2B biweekly").enableReadOnly();
      form.getCheckBox("Is 2B  other").enableReadOnly();
      form.getTextField("2B other").enableReadOnly();
      form.getCheckBox("Is 2C").enableReadOnly();
      form.getTextField("Tenancy agreement ends on day").enableReadOnly();
      form.getTextField("Tenancy agreement ends on month").enableReadOnly();
      form.getTextField("Tenancy agreement ends on year").enableReadOnly();

      form.getCheckBox("Is 2D").enableReadOnly();
      form.getCheckBox("Is 2E").enableReadOnly();
      form.getTextField("Reason tenant vacate").enableReadOnly();
      form.getTextField("Tenancy Regulation section number").enableReadOnly();
      form.getTextField("Landlord's Initials").enableReadOnly();
      form.getTextField("Tenant's initials").enableReadOnly();
      form.getTextField("Rent").setText(ModifyString(values.rent));
      form.getTextField("Rent").enableReadOnly();

      if (values.rentDay) {
        form.getCheckBox("Is rent payment day").check();
      } else if (values.rentWeek) {
        form.getCheckBox("Is rent payment week").check();
      } else if (values.rentMonth) {
        form.getCheckBox("Is rent payment month").check();
      }
      form.getCheckBox("Is rent payment day").enableReadOnly();
      form.getCheckBox("Is rent payment week").enableReadOnly();
      form.getCheckBox("Is rent payment month").enableReadOnly();

      form
        .getTextField("Rent due day")
        .setText(ModifyString(values.paymentDay));
      form.getTextField("Rent due day").enableReadOnly();

      if (values.rentPayDay) {
        form.getCheckBox("Is rent due day").check();
      } else if (values.rentPayWeek) {
        form.getCheckBox("Is rent due week").check();
      } else if (values.rentPayMonth) {
        form.getCheckBox("Is rent due month").check();
      }
      form.getCheckBox("Is rent due day").enableReadOnly();
      form.getCheckBox("Is rent due week").enableReadOnly();
      form.getCheckBox("Is rent due month").enableReadOnly();

      if (values.isUtilityWater) form.getCheckBox("water").check();

      if (values.isUtilityNaturalGas) form.getCheckBox("natural gas").check();

      if (values.isUtilityGarbageCollection)
        form.getCheckBox("Garbage collection").check();

      if (values.isUtilityRefrigerator)
        form.getCheckBox("Refrigerator").check();

      if (values.isUtilityCarpets) form.getCheckBox("Carpets").check();
      if (values.isUtilityCablevision) form.getCheckBox("Cabblevision").check();
      if (values.isUtilitySewageDisposal)
        form.getCheckBox("Sewage disposal").check();

      if (values.isUtilityRecyclingServices)
        form.getCheckBox("Recycling Services").check();

      if (values.isUtilityDishwasher) form.getCheckBox("Dishwasher").check();

      if (values.isUtilityParkingFor) {
        form.getCheckBox("Parking").check();
        form
          .getTextField("Parking for")
          .setText(ModifyString(values.utilityParkingVehicles));
      }

      if (values.isUtilityElectricity) form.getCheckBox("Electricity").check();

      if (values.isUtilitySnowRemoval) form.getCheckBox("Snow removal").check();

      if (values.isUtilityKitchenScrapCollection)
        form.getCheckBox("Kitchen Scrap collection").check();

      if (values.isUtilityStoveAndOven)
        form.getCheckBox("Stove and oven").check();

      if (values.isUtilityInternet) form.getCheckBox("Internet").check();

      if (values.isUtilityStorage) form.getCheckBox("Storage").check();

      if (values.isUtilityLaundry) form.getCheckBox("Laundry").check();

      if (values.isUtilityWindowCoverings)
        form.getCheckBox("Window coverings").check();

      if (values.isUtilityOther1) {
        form.getCheckBox("Is rent included other1").check();
        form
          .getTextField("Rent included other1")
          .setText(ModifyString(values.utilityOther1));
      }

      if (values.isUtilityOther2) {
        form.getCheckBox("Is rent included other2").check();
        form
          .getTextField("Rent included other2")
          .setText(ModifyString(values.utilityOther2));
      }

      if (values.isUtilityOther3) {
        form.getCheckBox("Is rent included other3").check();
        form
          .getTextField("Rent included other3")
          .setText(ModifyString(values.utilityOther3));
      }

      if (values.isUtilityHeat) form.getCheckBox("Heat").check();

      if (values.isUtilityRecreationFacilities)
        form.getCheckBox("Recreational facilities").check();

      if (values.isUtilityFreeLaundry) form.getCheckBox("Free laundry").check();

      if (values.isUtilityFurniture) form.getCheckBox("Furniture").check();

      if (values.isUtilityAdditionalInfo) {
        form.getCheckBox("Is Additional information").check();
        form
          .getTextField("Additional information")
          .setText(ModifyString(values.utilityAdditionalInfo));
      }

      form.getCheckBox("water").enableReadOnly();
      form.getCheckBox("natural gas").enableReadOnly();
      form.getCheckBox("Garbage collection").enableReadOnly();
      form.getCheckBox("Refrigerator").enableReadOnly();
      form.getCheckBox("Carpets").enableReadOnly();
      form.getCheckBox("Cabblevision").enableReadOnly();
      form.getCheckBox("Sewage disposal").enableReadOnly();
      form.getCheckBox("Recycling Services").enableReadOnly();
      form.getCheckBox("Dishwasher").enableReadOnly();
      form.getCheckBox("Parking").enableReadOnly();
      form.getTextField("Parking for").enableReadOnly();
      form.getCheckBox("Electricity").enableReadOnly();
      form.getCheckBox("Snow removal").enableReadOnly();
      form.getCheckBox("Kitchen Scrap collection").enableReadOnly();
      form.getCheckBox("Stove and oven").enableReadOnly();
      form.getCheckBox("Internet").enableReadOnly();
      form.getCheckBox("Storage").enableReadOnly();
      form.getCheckBox("Laundry").enableReadOnly();
      form.getCheckBox("Window coverings").enableReadOnly();
      form.getCheckBox("Is rent included other1").enableReadOnly();
      form.getTextField("Rent included other1").enableReadOnly();
      form.getCheckBox("Is rent included other2").enableReadOnly();
      form.getTextField("Rent included other2").enableReadOnly();
      form.getCheckBox("Is rent included other3").enableReadOnly();
      form.getTextField("Rent included other3").enableReadOnly();
      form.getCheckBox("Heat").enableReadOnly();
      form.getCheckBox("Recreational facilities").enableReadOnly();
      form.getCheckBox("Free laundry").enableReadOnly();
      form.getCheckBox("Furniture").enableReadOnly();
      form.getCheckBox("Is Additional information").enableReadOnly();
      form.getTextField("Additional information").enableReadOnly();

 
        form
          .getTextField("Security deposit amount")
          .setText(ModifyString(values.securityDeposit));
        form
          .getTextField("Security deposit day")
          .setText(
            ModifyString(
              new Date(values.securityDepositPayBy).getDate()
            ).padStart(2, "0")
          );
        form
          .getTextField("Security deposit month")
          .setText(
            ModifyString(
              new Date(values.securityDepositPayBy).getMonth() + 1
            ).padStart(2, "0")
          );
        form
          .getTextField("Security deposit year")
          .setText(
            ModifyString(
              new Date(values.securityDepositPayBy).getFullYear()
            ).padStart(2, "0")
          );
      
      form.getTextField("Security deposit amount").enableReadOnly();
      form.getTextField("Security deposit day").enableReadOnly();
      form.getTextField("Security deposit month").enableReadOnly();
      form.getTextField("Security deposit year").enableReadOnly();

      if (values.isPetDeposit) {
        
        form
          .getTextField("Ped damage deposit amount")
          .setText(ModifyString(values.petDeposit));
        form
          .getTextField("Ped damage deposit day")
          .setText(
            ModifyString(new Date(values.petDepositPayBy).getDate()).padStart(
              2,
              "0"
            )
          );
        form
          .getTextField("Ped damage deposit month")
          .setText(
            ModifyString(
              new Date(values.petDepositPayBy).getMonth() + 1
            ).padStart(2, "0")
          );
        form
          .getTextField("Ped damage deposit year")
          .setText(
            ModifyString(
              new Date(values.petDepositPayBy).getFullYear()
            ).padStart(2, "0")
          );
      }else{
        form.getCheckBox("Is pet damage deposit").check();
      }
      form.getCheckBox("Is pet damage deposit").enableReadOnly();
      form.getTextField("Ped damage deposit amount").enableReadOnly();
      form.getTextField("Ped damage deposit day").enableReadOnly();
      form.getTextField("Ped damage deposit month").enableReadOnly();
      form.getTextField("Ped damage deposit year").enableReadOnly();

      if (values.isAttachedTenancyAgreement) {
        form.getCheckBox("Is an Addendum attached").check();
        form
          .getTextField("No of pages of addendum")
          .setText(ModifyString(values.numberAddendumPage));
        form
          .getTextField("No of additional terms in Addendum")
          .setText(ModifyString(values.numberAdditionalAddendumPage));
      } else {
        form.getCheckBox("is not Addendum attached").check();
      }
      form.getCheckBox("Is an Addendum attached").enableReadOnly();
      form.getCheckBox("is not Addendum attached").enableReadOnly();
      form.getTextField("No of pages of addendum").enableReadOnly();
      form.getTextField("No of additional terms in Addendum").enableReadOnly();
      form
        .getTextField("Landlord1 signature date")
        .setText(ModifyString(today));

      const pdfBytes = await pdfDoc.save();
      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      const urlView = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlView;
      changeState("pdfLink", link);
      changeState("pdfDocObject", blob);
      changeState("changeBlob", true);
      try {
      } catch (error) {
        changeState({ pdfDocObject: "" });
        console.log("PDF Fetching Error");
      }
    }
    checkPdfDownload();
    // eslint-disable-next-line
  }, [values.signatureObject2, values.signatureObject]);
  return (
    <div className="">
      <div className="cardContract">
        <div className="text-center mt-4">
          {values.pdfLink ? (
            <center>
              <div className="PDFWeb">
                <object
                  data={values.pdfLink}
                  type="application/pdf"
                  width="800px"
                  height="2100px"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{" "}
                    <a href={values.pdfLink}>
                      click here to download the PDF file.
                    </a>
                  </p>
                </object>
              </div>
              <div className="PDFMobile">
                <object
                  data={values.pdfLink}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{" "}
                    <a href={values.pdfLink}>
                      click here to download the PDF file.
                    </a>
                  </p>
                </object>
              </div>
              <a className="pt-0 mt-0 PDFMobile " href={values.pdfLink}>
                click here to view the full PDF file.
              </a>
            </center>
          ) : (
            <div style={{ marginTop: "15%" }}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
