import React, { Component } from "react";
import "../contracts.css";
import coverImage from "../../../../Images/no-cover-img.jpg";
import {
  getPropertyLists,
  deleteContracts,
  userDetail,
  joinTenantsStatus,
  getRentalListsImages,
  url,
  getTenantContracts,
} from "../../../../Services/ApiServices";
import Loader3 from "../../../Loader/loader3";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import {
  addressFormatter,
  ExpireCheck,
  setDateFormat,
  timeFormatHumanInteraction,
} from "../../../../Defined/Function";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import { connect } from "react-redux";
import {
  appendAlert,
  appendTempListingData,
  appendTempUsers,
  appendTempTenantByContract,
  setContract,
} from "../../../../actions";
import { withRouter } from "react-router-dom";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import Dropzone from "react-dropzone";
import SignaturePad from "../../../UIServices/SignaturePad/SignaturePad";
class ContractListTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failFetching: false,
      listingData: [],
      contractData: this.props.data,
      viewingData: [],
      payRentDisabled: true,
      isOpen: false,
      listImage: "",
      landlordData: {
        accountNonExpired: true,
        accountNonLocked: true,
        address: {
          address: "No.123 1st cross street",
          city: "",
          state: "",
          country: "",
        },
        coverImage: null,
        credentialsNonExpired: true,
        enabled: true,
        firstName: "",
        lastName: "",
        middleName: "",
        mobileNumber: "",
        password: "",
        provider: "",
        stripeAccountId: "",
        rejectModal: false,
        stripeCustomerId: null,
        username: "",
      },
      tenantStatus: [],
      subscriptionDate: "",
      next: false,
      paymentPlanToggle: false,
      signatureTab: "pad",
    };
    this.rejectContract = this.rejectContract.bind(this);
    this.onchange = this.onchange.bind(this);
    this.checkValidate = this.checkValidate.bind(this);
    this.changeMethod = this.changeMethod.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeSignatureObject = this.changeSignatureObject.bind(this);
    this.changeShortSignatureObject =
      this.changeShortSignatureObject.bind(this);
  }
  changeTab(name, sign) {
    this.setState({ signatureTab: name });
    this.props.onChangeState(sign, null);
  }
  changeSignatureObject(object) {
    this.props.onChangeState("signObject", object);
    window.$("#signatureModal" + this.props.data.rentalEntityId).modal("hide");
    window.$("#exampleModal2").modal("show");
    this.props.onChangeState("sign", true);
  }
  changeShortSignatureObject(object) {
    window
      .$("#shortSignatureModal" + this.props.data.rentalEntityId)
      .modal("hide");
    this.props.onChangeState("shortSignObject", object);
    this.props.onChangeState("shortSign", true);
    this.props.onChangeState("sign", false);
    window.$("#signatureModal" + this.props.data.rentalEntityId).modal("show");
    this.props.onChangeState("signObject", null);
  }
  changePaymentPlanToggle() {
    this.setState({ paymentPlanToggle: !this.state.paymentPlanToggle });
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  changeMethod() {
    this.setState({ next: true });
    if (this.state.contractData.subscription) {
      this.props.appendAlert("changed as one time pay", "success");
      this.changePaymentPlanToggle();
    } else {
      if (this.state.subscriptionDate !== "") {
        this.setState({ next: false });
        this.props.appendAlert("changed as auto pay", "success");
      }
    }
  }
  getProps() {
    getPropertyLists(this.props.data.rentalEntityId).then((response) => {
      if (response.status === 200) {
        if (response.data.length !== 0) {
          this.setState({
            listingData: response.data,
            failFetching: false,
          });
          this.props.appendTempListingData(response.data);
        } else {
          console.log("No data there");
        }
      } else {
        this.setState({ failFetching: true });
      }
    });
  }
  getUser() {
    try {
      userDetail(this.props.data.landLordId).then((response) => {
        if (response.status) {
          this.setState({ landlordData: response.data });
          this.props.appendTempUsers(response.data);
        }
      });
    } catch (error) {}
  }
  getTenant() {
    joinTenantsStatus(this.state.contractData.contractId).then((response) => {
      if (response.status === 200) {
        if (response.data.length !== 0) {
          this.setState({ tenantStatus: response.data });
          this.props.appendTempTenantByContract(
            response.data,
            this.state.contractData.contractId
          );
        } else {
          this.setState({ tenantStatus: this.state.contractData.tenants });
        }
      }
    });
  }
  async componentDidMount() {
    let listing = this.props.tempListingArray.filter(
      (todo) => todo.rentalEntity.id === this.props.data.rentalEntityId
    );
    try {
      if (listing.length === 0) {
        await this.getProps();
      } else {
        this.setState({ listingData: listing[0] });
        this.setState({
          failFetching: false,
        });
        await this.getProps();
      }
      if (this.props.data.landLordId) {
        let users = this.props.tempUsersArray.filter(
          (todo) => todo.username === this.props.data.landLordId
        );
        if (users.length === 0) {
          this.getUser();
        } else {
          this.setState({ landlordData: users[0] });
          this.getUser();
        }
      }

      let tenantByContract = this.props.tempTenantByContractArray.filter(
        (todo) => todo.id === this.state.contractData.contractId
      );
      if (tenantByContract.length === 0) {
        this.getTenant();
      } else {
        this.setState({ tenantStatus: tenantByContract[0].contract });
      }

      getRentalListsImages(this.props.data.rentalEntityId).then(
        (responseImage) => {
          if (responseImage.status === 200) {
            if (responseImage.data.length !== 0) {
              this.setState({
                listImage:
                  url +
                  "api/property/" +
                  this.props.data.rentalEntityId +
                  "/" +
                  responseImage.data[0] +
                  "/image/downloadByName",
                spinner: false,
              });
              this.props.setModalImage(
                url +
                  "api/property/" +
                  this.props.data.rentalEntityId +
                  "/" +
                  responseImage.data[0] +
                  "/image/downloadByName",
                this.props.data.rentalEntityId
              );
            }
          } else {
            console.log("Error in processing.Try again later!");
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
    setTimeout(
      function () {
        if (this.props.match.params.id) {
          if (
            parseInt(this.props.match.params.id) ===
            parseInt(this.props.data.contractId)
          ) {
            var elementTarget = document.getElementById(
              "contract_" + this.props.match.params.id
            );
            if (elementTarget !== null) {
              window.scrollTo(0, elementTarget.offsetTop - 100);
            }
          }
        }
      }.bind(this),
      1000
    );
  }

  async rejectContract(modalData) {
    let updated = modalData;
    modalData.status = "CLOSE";

    try {
      await deleteContracts(modalData.contractId).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.setState((prevState) => {
              let newData = prevState.contractData;
              Object.assign(newData, updated);
              return { contractData: newData };
            });

            this.props.appendAlert("Contract Rejected", "success");
            try {
              getTenantContracts().then((response1) => {
                if (response1.status === 200) {
                  if (response1.data.length !== 0) {
                    this.props.setContract(
                      response1.data.sort((a, b) =>
                        b.updatedAt.localeCompare(a.updatedAt)
                      )
                    );
                  } else {
                    this.props.setContract([]);
                  }
                }
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            this.props.appendAlert("Error in rejection", "danger");
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return this.state.failFetching ? (
      <></>
    ) : this.state.listingData.length === 0 ? (
      <div className="p-5" style={{ marginTop: "10px" }}>
        <Loader3 />
      </div>
    ) : (
      this.state.contractData && (
        <>
          {this.state.rejectModal && (
            <SmallModalContainer
              name="rejectContract"
              dark={this.props.dark}
              title="Confirm Reject?"
              confirmCommand="Reject"
              confirm={() => {
                this.rejectContract(this.state.contractData);
              }}
              reject={() => this.setState({ rejectModal: false })}
              rejectCommand="Cancel"
              rejectCheck="true"
            >
              <div className="p-2 pull-left text-left">
                <span className="span7" style={{ fontSize: "16px" }}>
                  Are you sure you want to reject this contract?
                </span>
              </div>
            </SmallModalContainer>
          )}
          <div
            className="contractContainer"
            id={"contract_" + this.state.contractData.contractId}
          >
            <div
              className={
                this.props.dark ? "row contractCardDark " : "row contractCard "
              }
            >
              <div
                className="col-2 contractListImage1"
                style={{
                  backgroundImage:
                    "url(" +
                    (this.state.listImage === ""
                      ? coverImage
                      : this.state.listImage) +
                    ")",
                  borderBottomLeftRadius: "15px",
                  borderTopLeftRadius: "15px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="col-12 contractListImage2"
                style={{
                  backgroundImage:
                    "url(" +
                    (this.state.listImage === ""
                      ? coverImage
                      : this.state.listImage) +
                    ")",
                  height: "200px",
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="col">
                <div className="row mt-2 pb-2 contractDivider">
                  <div className="col-md-12 contractListImage2">
                    <span
                      className="badge appStatus pull-right"
                      style={{
                        backgroundColor:
                          this.state.contractData.status === "ACTIVE"
                            ? "#2ECC71"
                            : this.state.contractData.status ===
                              "TENANT_INVITED"
                            ? "#2ECC71"
                            : this.state.contractData.status === "PENDING"
                            ? "#F4D03F"
                            : "#E74C3C",
                      }}
                    >
                      {this.state.contractData.status === "CLOSE"
                        ? "REJECTED"
                        : this.state.contractData.status === "CANCEL"
                        ? "CANCELED"
                        : this.state.contractData.status}
                    </span>
                  </div>
                  <div className="col-12 pl-3 ">
                    <span className="badge badge02 ">Rental</span>
                  </div>

                  <div className="col-md-2 col-lg-2 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Unit
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.listingData.rentalEntity &&
                        (this.state.listingData.rentalEntity.unitName
                          ? this.state.listingData.rentalEntity.unitName
                          : " - ")}
                    </p>
                  </div>
                  <div className="col-md-2 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Apartment
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.listingData.rentalEntity &&
                        (this.state.listingData.rentalEntity.property.name
                          ? this.state.listingData.rentalEntity.property.name
                          : " - ")}
                    </p>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Lease Validity Period
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      <div className="dropdown">
                        <div
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-hidden="true"
                          className="pointer"
                        >
                          {/* <i className="fa fa-calendar pr-2 "></i> */}
                          <span
                            className={
                              this.props.dark
                                ? "contractTextDark"
                                : "contractText "
                            }
                          >
                            {timeFormatHumanInteraction(
                              this.state.contractData.startDate,
                              this.state.contractData.endDate
                            )}
                          </span>
                        </div>
                        <div
                          className="dropdown-menu pr-2 pl-2"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <i
                            className="fa fa-calendar pr-2 pl-2"
                            aria-hidden="true"
                          ></i>
                          <span>
                            {setDateFormat(this.state.contractData.startDate)}
                          </span>
                          <i
                            className="fa fa-long-arrow-right pr-2 pl-2"
                            aria-hidden="true"
                          ></i>
                          <i
                            className="fa fa-calendar pr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          <span>
                            {setDateFormat(this.state.contractData.endDate)}
                          </span>
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Address
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {addressFormatter(this.state.listingData.address)}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 pb-2 contractDivider">
                  <div className="col-12 pl-3">
                    <span className="badge badge02 ">Landlord</span>
                  </div>
                  <div className="col-md-2 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Name
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.landlordData.firstName === "" &&
                      this.state.landlordData.lastName === ""
                        ? "-"
                        : this.state.landlordData.firstName +
                          " " +
                          this.state.landlordData.lastName}
                    </p>
                  </div>
                  <div className="col-md-2 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Mobile
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.landlordData.mobileNumber === ""
                        ? "-"
                        : this.state.landlordData.mobileNumber}
                    </p>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Email
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.landlordData.username === ""
                        ? "-"
                        : this.state.landlordData.username}
                    </p>
                  </div>

                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Address
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {addressFormatter(this.state.landlordData.address)}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 pb-2 contractDivider">
                  <div className="col-12 pl-3">
                    <span className="badge badge02 ">Tenant</span>
                  </div>
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Primary
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      {this.state.tenantStatus[0] ? (
                        !this.state.tenantStatus[0].userDetails ? (
                          this.state.tenantStatus[0].username
                        ) : this.state.tenantStatus[0].userDetails.firstName ===
                            "" &&
                          this.state.tenantStatus[0].userDetails.lastName ===
                            "" ? (
                          this.state.tenantStatus[0].username
                        ) : (
                          this.state.tenantStatus[0].userDetails.firstName +
                          " " +
                          this.state.tenantStatus[0].userDetails.lastName
                        )
                      ) : (
                        <Loader3 transY="40px" transX="-100px" />
                      )}
                    </p>
                  </div>
                  {this.state.tenantStatus.length > 1 && (
                    <div className="col-md-4 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Others
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText"
                        }
                      >
                        <div className="row">
                          <div className="col">
                            {this.state.tenantStatus[1] ? (
                              !this.state.tenantStatus[1].userDetails ? (
                                this.state.tenantStatus[1].username
                              ) : this.state.tenantStatus[1].userDetails
                                  .firstName === "" &&
                                this.state.tenantStatus[1].userDetails
                                  .lastName === "" ? (
                                this.state.tenantStatus[1].username
                              ) : (
                                this.state.tenantStatus[1].userDetails
                                  .firstName +
                                " " +
                                this.state.tenantStatus[1].userDetails.lastName
                              )
                            ) : (
                              <Loader3 transY="40px" transX="-100px" />
                            )}
                            {this.state.tenantStatus[1].agreed ? (
                              <i
                                className="fa fa-check-circle text-success pl-2"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <>
                                <i
                                  className="fa fa-exclamation-circle text-danger ml-2"
                                  aria-hidden="true"
                                ></i>
                              </>
                            )}
                          </div>
                          <div className="col-2">
                            <div className="dropdown show pull-right">
                              <span
                                className="badge bg-gray dropdown-toggle pointer p-2 pb-1"
                                id="dropdownChangeEmail2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-pencil-square-o "
                                  aria-hidden="true"
                                ></i>{" "}
                              </span>
                              <div
                                className="dropdown-menu   profileMenu navProfile pr-3 pl-3 shadow"
                                aria-labelledby="dropdownChangeEmail2"
                              >
                                <ApplicationInput
                                  name="Email"
                                  target="tenant2Email"
                                  type="email"
                                  onChange={this.onchange}
                                  important="true"
                                  errorMessage="the email "
                                  validate={this.checkValidate(
                                    this.state.tenant2Email
                                  )}
                                  value={this.state.tenant2Email}
                                />

                                <button
                                  className=" mt-3 mb-3 buttonPri pull-right"
                                  onClick={this.changeMethod}
                                >
                                  Change Email
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p>
                      {this.state.tenantStatus.length > 2 && (
                        <p
                          className={
                            this.props.dark
                              ? "contractTextDark"
                              : "contractText"
                          }
                        >
                          <div className="row">
                            <div className="col">
                              {this.state.tenantStatus[2] ? (
                                !this.state.tenantStatus[2].userDetails ? (
                                  this.state.tenantStatus[2].username
                                ) : this.state.tenantStatus[2].userDetails
                                    .firstName === "" &&
                                  this.state.tenantStatus[2].userDetails
                                    .lastName === "" ? (
                                  this.state.tenantStatus[2].username
                                ) : (
                                  this.state.tenantStatus[2].userDetails
                                    .firstName +
                                  " " +
                                  this.state.tenantStatus[2].userDetails
                                    .lastName
                                )
                              ) : (
                                <Loader3 transY="40px" transX="-100px" />
                              )}
                              {this.state.tenantStatus[2].agreed ? (
                                <i
                                  className="fa fa-check-circle text-success pl-2"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <>
                                  <i
                                    className="fa fa-exclamation-circle text-danger ml-2"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              )}
                            </div>
                            <div className="col-2">
                              <div className="dropdown show pull-right">
                                <span
                                  className="badge bg-gray dropdown-toggle pointer p-2 pb-1"
                                  id="dropdownChangeEmail2"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fa fa-pencil-square-o "
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                                <div
                                  className="dropdown-menu   profileMenu navProfile pr-3 pl-3 shadow"
                                  aria-labelledby="dropdownChangeEmail2"
                                >
                                  <ApplicationInput
                                    name="Email"
                                    target="tenant2Email"
                                    type="email"
                                    onChange={this.onchange}
                                    important="true"
                                    errorMessage="the email "
                                    validate={this.checkValidate(
                                      this.state.tenant2Email
                                    )}
                                    value={this.state.tenant2Email}
                                  />

                                  <button
                                    className=" mt-3 mb-3 buttonPri pull-right"
                                    onClick={this.changeMethod}
                                  >
                                    Change Email
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                      )}
                    </div>
                  )}
                  <div className="col-md-3 contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Payment Plan
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      <div className="row">
                        <div className="col-7">
                          {" "}
                          {this.state.contractData.subscription
                            ? "Auto Pay"
                            : "One Time Pay"}
                        </div>
                        {/* {this.state.contractData.status === "ACTIVE" && (
                          <div className="col-5 ">
                            <div className=" ">
                              <span
                                className="badge bg-gray  pointer p-2 pb-1"
                                onClick={() => this.changePaymentPlanToggle()}
                              >
                                <i
                                  className="fa fa-pencil-square-o "
                                  aria-hidden="true"
                                ></i>{" "}
                              </span>
                              {this.state.paymentPlanToggle && (
                                <div className="absolute paymentPlanToggleAlign  profileMenu navProfile pr-3 pl-3 shadow">
                                  <i
                                    className="fa fa-close pull-right pointer"
                                    aria-hidden="true"
                                    onClick={() =>
                                      this.changePaymentPlanToggle()
                                    }
                                  ></i>
                                  {!this.state.contractData.subscription ? (
                                    <>
                                      <ApplicationInput
                                        name="Subscription Date"
                                        target="subscriptionDate"
                                        type="optional"
                                        options={monthNumberArray}
                                        optionValue={monthNumberArray}
                                        infoStyle="infoContent2"
                                        info={
                                          <>
                                            <b>Note:</b>
                                            <br></br>
                                            <span>
                                              {this.state.subscriptionDate}{" "}
                                              {this.state.subscriptionDate >
                                                10 &&
                                              this.state.subscriptionDate < 20
                                                ? "th"
                                                : this.state.subscriptionDate %
                                                    10 ===
                                                  1
                                                ? "st"
                                                : this.state.subscriptionDate %
                                                    10 ===
                                                  2
                                                ? "nd"
                                                : this.state.subscriptionDate %
                                                    10 ===
                                                  3
                                                ? "rd"
                                                : "th"}{" "}
                                              day of month
                                            </span>
                                          </>
                                        }
                                        onChange={this.onchange}
                                        important="true"
                                        errorMessage="the subscription date"
                                        validate={this.checkValidate(
                                          this.state.subscriptionDate
                                        )}
                                        value={this.state.subscriptionDate}
                                      />
                                      <button
                                        className=" mt-3 mb-3 buttonPri pull-right"
                                        onClick={this.changeMethod}
                                      >
                                        Change to Auto Pay
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className="  buttonPri "
                                      style={{ width: "300px" }}
                                      onClick={this.changeMethod}
                                    >
                                      Change to One Time Pay
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )} */}
                      </div>
                    </p>
                  </div>
                  {this.state.contractData.isKeyDeposit && (
                    <div className="col-md-2 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Key Deposit
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        <CurrencyFormat
                          value={this.state.contractData.keyDeposit}
                        />
                      </p>
                    </div>
                  )}

                  {this.state.contractData.rentDiscount !== 0 && (
                    <div className="col-md-3 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Rent Discount
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        {this.state.contractData.rentDiscount}
                        <i
                          className="fa fa-percent pl-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  )}
                  <div className="col-md contractListAlign">
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                      style={{ fontWeight: "bold" }}
                    >
                      Total Rent
                    </p>
                    <p
                      className={
                        this.props.dark ? "contractTextDark" : "contractText "
                      }
                    >
                      <CurrencyFormat value={this.state.contractData.rent} />
                      {/* {(
                        this.state.contractData.otherRentFee1 +
                        this.state.contractData.otherRentFee2 +
                        this.state.contractData.otherRentFee3 +
                        this.state.contractData.parkingFee +
                        this.state.contractData.petAmount +
                        (this.state.contractData.rentDiscount !== 0
                          ? (this.state.contractData.rent *
                              (100 - this.state.contractData.rentDiscount)) /
                            100
                          : this.state.contractData.rent)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })} */}
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-3">
                  <div className="col-md-2 contractListImage1">
                    <span
                      className="badge appStatus  mt-3 "
                      style={{
                        backgroundColor:
                          this.state.contractData.status === "ACTIVE"
                            ? "#2ECC71"
                            : this.state.contractData.status ===
                              "TENANT_INVITED"
                            ? "#2ECC71"
                            : this.state.contractData.status === "PENDING"
                            ? "#F4D03F"
                            : "#E74C3C",
                      }}
                    >
                      {this.state.contractData.status === "CLOSE"
                        ? "REJECTED"
                        : this.state.contractData.status === "CANCEL"
                        ? "CANCELED"
                        : this.state.contractData.status}
                    </span>
                  </div>
                  <div className="col-md">
                    {this.state.contractData.status === "ACTIVE" && (
                      <>
                        {!this.state.contractData.subscription && (
                          <button
                            disabled={
                              !this.props.isButtonDisableFinishPay ||
                              this.props.customerPaymentStatus !== "verified"
                            }
                            className={
                              !this.props.isButtonDisableFinishPay ||
                              this.props.customerPaymentStatus !== "verified"
                                ? "secDisable notAllow pull-right mr-3 mt-3 mb-2"
                                : (this.props.dark
                                    ? "agreementDark"
                                    : "agreement") +
                                  "  pull-right mr-3 mt-3  mb-2"
                            }
                            data-placement="top"
                            title="Pay Rent"
                            onClick={() => {
                              this.props.setModalData(
                                this.state.contractData,
                                this.state.listImage,
                                "pay"
                              );
                            }}
                            // data-target="#exampleModal1"
                            // data-toggle="modal"
                          >
                            <i
                              className="fa fa-credit-card-alt  mr-2 ml-2 "
                              aria-hidden="true"
                            ></i>
                            Pay Rent
                            {!this.props.isButtonDisableFinishPay && (
                              <Loader3 transY="47px" transX="0px" />
                            )}
                          </button>
                        )}
                        <button
                          className={
                            (this.props.isDark
                              ? "agreementDark"
                              : "agreement") + " pull-right mr-3 mt-3"
                          }
                          data-placement="top"
                          title="Files"
                          onClick={() => {
                            this.props.viewFiles(
                              this.props.data.contractFiles,
                              this.props.data.contractId
                            );
                          }}
                          data-target="#filesView"
                          data-toggle="modal"
                        >
                          <i
                            className="fa fa-folder  mr-2 ml-2"
                            aria-hidden="true"
                          ></i>
                          Files
                        </button>
                        {/* <button
                          className={
                            (this.props.dark ? "agreementDark" : "agreement") +
                            "  pull-right mr-3 mt-3 "
                          }
                          data-placement="top"
                          title="Move-in List Details"
                          onClick={() => {
                            this.props.viewContract(
                              this.props.data.contractFiles,
                              this.props.data.contractId,
                              "moveInOutCheckList"
                            );
                          }}
                          data-target="#exampleModal"
                          data-toggle="modal"
                        >
                          <i
                            className="fa fa-cubes pl-2 pr-2"
                            aria-hidden="true"
                          ></i>
                          Move in list
                        </button> */}
                        {this.state.contractData.agreementPDF &&
                          this.state.contractData.agreementPDF !==
                            "OWN_AGREEMENT_PENDING" && (
                            <button
                              className={
                                (this.props.dark
                                  ? "agreementDark "
                                  : "agreement ") + "mr-3 pull-right mt-3"
                              }
                              data-placement="top"
                              title="View Agreement"
                              onClick={() => {
                                this.props.viewContract(
                                  this.props.data.contractFiles,
                                  this.props.data.contractId,
                                  "contractAgreement"
                                );
                              }}
                              data-target="#exampleModal"
                              data-toggle="modal"
                            >
                              <i
                                className="fa fa-list-alt  mr-2 ml-2"
                                aria-hidden="true"
                              ></i>
                              Agreement
                            </button>
                          )}

                        {process.env.REACT_APP_STATUS !== "Production" &&
                          new Date(this.state.contractData.endDate) -
                            new Date() >
                            0 && (
                            <button
                              className={
                                (this.props.dark
                                  ? "agreementDark"
                                  : "agreement") + "  pull-right  mr-3 mt-3"
                              }
                              data-placement="top"
                              title="Manage Maintenance"
                              onClick={() =>
                                this.props.history.push({
                                  pathname: "/maintenance",
                                  state: {
                                    contractId:
                                      this.state.contractData.contractId,
                                  },
                                })
                              }
                            >
                              <i
                                className="fa fa-fire-extinguisher pl-2 pr-2 "
                                aria-hidden="true"
                              ></i>{" "}
                              Maintenance
                              {this.props.maintenanceArray !== null &&
                                this.props.maintenanceArray.filter(
                                  (data) =>
                                    parseInt(
                                      this.state.contractData.contractId
                                    ) === parseInt(data.contractId) &&
                                    data.landlordApprovel === "pending"
                                ).length > 0 && (
                                  <span className="badge badge-light round  pointer ml-3">
                                    {
                                      this.props.maintenanceArray.filter(
                                        (data) =>
                                          parseInt(
                                            this.state.contractData.contractId
                                          ) === parseInt(data.contractId) &&
                                          data.landlordApprovel === "pending"
                                      ).length
                                    }
                                  </span>
                                )}
                            </button>
                          )}
                      </>
                    )}
                    {this.state.contractData.status === "PENDING" && (
                      <>
                        {this.state.contractData.agreementPDF &&
                          this.state.contractData.agreementPDF !==
                            "OWN_AGREEMENT_PENDING" && (
                            <button
                              className={
                                (this.props.dark
                                  ? "agreementDark"
                                  : "agreement") + "  pull-right mr-3 mt-3"
                              }
                              data-placement="top"
                              title="View Agreement"
                              onClick={() => {
                                this.props.viewContract(
                                  this.props.data.contractFiles,
                                  this.props.data.contractId,
                                  "contractAgreement"
                                );
                              }}
                              data-target="#exampleModal"
                              data-toggle="modal"
                            >
                              <i
                                className="fa fa-list-alt  mr-2 ml-2"
                                aria-hidden="true"
                              ></i>
                              Agreement
                            </button>
                          )}

                        {/* <button
                          className={
                            (this.props.dark ? "agreementDark" : "agreement") +
                            "  pull-right mr-3 mt-3 "
                          }
                          data-placement="top"
                          title="Move-in List Details"
                          onClick={() => {
                            this.props.viewContract(
                              this.props.data.contractFiles,
                              this.props.data.contractId,
                              "moveInOutCheckList"
                            );
                          }}
                          data-target="#exampleModal"
                          data-toggle="modal"
                        >
                          <i
                            className="fa fa-cubes pl-2 pr-2"
                            aria-hidden="true"
                          ></i>
                          Move in list
                        </button> */}
                        {ExpireCheck(
                          this.state.contractData.startDate,
                          this.state.contractData.endDate
                        ) && (
                          <>
                            <button
                              className={
                                (this.props.dark
                                  ? "agreementDark"
                                  : "agreement") + " pull-right  mr-3 mt-3"
                              }
                              data-placement="top"
                              title="Reject"
                              onClick={() =>
                                this.setState({ rejectModal: true })
                              }
                              data-target="#rejectContract"
                              data-toggle="modal"
                            >
                              <i
                                className="fa fa-times pl-1 pr-2"
                                aria-hidden="true"
                              ></i>
                              Reject
                            </button>
                            {this.state.tenantStatus.length > 2 ? (
                              this.state.tenantStatus[0].agreed &&
                              this.state.tenantStatus[1].agreed &&
                              this.state.tenantStatus[2].agreed ? (
                                <button
                                  className={
                                    (this.props.dark
                                      ? "agreementDark"
                                      : "agreement") + " pull-right mr-3 mt-3"
                                  }
                                  data-placement="top"
                                  title="Accept"
                                  onClick={() => {
                                    this.props.setModalData(
                                      this.state.contractData,
                                      this.state.listImage,
                                      "accept"
                                    );
                                  }}
                                  // data-target="#exampleModal2"
                                  // data-toggle="modal"
                                >
                                  <i
                                    className="fa fa-check pl-2 pr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Accept
                                </button>
                              ) : (
                                <button
                                  className={
                                    "agreement agreementDisable pull-right mr-3 mt-3"
                                  }
                                  data-placement="top"
                                  title="Waiting for other tenant respond"
                                  onClick={() =>
                                    this.props.appendAlert(
                                      "Please wait until other tenants respond to the contract",
                                      "warning"
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-check pl-2 pr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Accept
                                </button>
                              )
                            ) : this.state.tenantStatus.length > 1 ? (
                              this.state.tenantStatus[0].agreed &&
                              this.state.tenantStatus[1].agreed ? (
                                <button
                                  className={
                                    (this.props.dark
                                      ? "agreementDark"
                                      : "agreement") + " pull-right mr-3 mt-3"
                                  }
                                  data-placement="top"
                                  title="Accept"
                                  onClick={() => {
                                    this.props.setModalData(
                                      this.state.contractData,
                                      this.state.listImage,
                                      "accept"
                                    );
                                  }}
                                  // data-target="#exampleModal2"
                                  // data-toggle="modal"
                                >
                                  <i
                                    className="fa fa-check pl-2 pr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Accept
                                </button>
                              ) : (
                                <button
                                  className={
                                    "agreement agreementDisable pull-right mr-3 mt-3"
                                  }
                                  data-placement="top"
                                  title="Waiting for other tenant respond"
                                  onClick={() =>
                                    this.props.appendAlert(
                                      "Please wait until other tenants respond to the contract",
                                      "warning"
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-check pl-2 pr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Accept
                                </button>
                              )
                            ) : this.state.tenantStatus.length > 0 ? (
                              <button
                                className={
                                  (this.props.dark
                                    ? "agreementDark"
                                    : "agreement") + " pull-right mr-3 mt-3"
                                }
                                data-placement="top"
                                title="Accept"
                                onClick={() => {
                                  this.props.setModalData(
                                    this.state.contractData,
                                    this.state.listImage,
                                    "accept"
                                  );
                                }}
                                // data-target="#exampleModal2"
                                // data-toggle="modal"
                              >
                                <i
                                  className="fa fa-check pl-2 pr-2"
                                  aria-hidden="true"
                                ></i>
                                Accept
                              </button>
                            ) : (
                              <button
                                className={
                                  "agreement agreementDisable pull-right mr-3 mt-3"
                                }
                                data-placement="top"
                                title="Waiting for other tenant respond"
                                onClick={() =>
                                  this.props.appendAlert(
                                    "Please wait until other tenants respond to the contract",
                                    "warning"
                                  )
                                }
                              >
                                <i
                                  className="fa fa-check pl-2 pr-2"
                                  aria-hidden="true"
                                ></i>
                                Accept
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!this.props.sign && (
              <SmallModalContainer
                name={"signatureModal" + this.props.data.rentalEntityId}
                dark={this.props.dark}
                title="Final Sign for Contract Agreement"
                footer="false"
                size="lg"
              >
                <div className="row">
                  <div className="col ">
                    <button
                      className={
                        this.state.signatureTab === "pad"
                          ? "mr-2 tabSignature"
                          : "mr-2  tabSignature2"
                      }
                      onClick={() => this.changeTab("pad", "signObject")}
                    >
                      Draw
                    </button>
                    <button
                      className={
                        this.state.signatureTab === "upload"
                          ? "mr-2  tabSignature"
                          : "mr-2  tabSignature2"
                      }
                      onClick={() => this.changeTab("upload", "signObject")}
                    >
                      Upload
                    </button>
                  </div>
                </div>

                <div className={"tab-content  borderSec"} id="myTabContent">
                  <div
                    className={
                      this.state.signatureTab === "pad"
                        ? "tab-pane fade show active "
                        : "tab-pane fade "
                    }
                    id="upload"
                    role="tabpanel"
                    aria-labelledby="upload-tab"
                  >
                    <div className="d-flex justify-content-center pt-5 pb-4">
                      <SignaturePad
                        name={"signPad" + this.props.data.rentalEntityId}
                        submitAlign="pull-right"
                        submitName="Submit"
                        type="base64"
                        value={this.props.signObject}
                        submit={this.changeSignatureObject}
                        submitDisable={true}
                        maxWidth="450"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.state.signatureTab === "upload"
                        ? "tab-pane fade show active "
                        : "tab-pane fade "
                    }
                    id="upload"
                    role="tabpanel"
                    aria-labelledby="upload-tab"
                  >
                    <div className="d-flex justify-content-start mb-5">
                      <Dropzone
                        accept=".jpeg,.png,.jpg"
                        maxSize={10485760}
                        onDrop={(files) => {
                          files.map((file) => {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                              if (event.target.result === undefined) {
                                this.props.appendAlert(
                                  "Image size is too Long (max size = 10mb) ",
                                  "danger"
                                );
                              } else {
                                this.changeSignatureObject(event.target.result);
                              }
                            };
                            reader.readAsDataURL(file);
                            return file;
                          });
                        }}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {this.props.signObject === null ? (
                                <div className="text-left p-4">
                                  <div
                                    className="dragDrop text-center  pointer"
                                    style={{
                                      fontSize: "60px",
                                      minHeight: "250px",
                                      paddingTop: "70px",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <i
                                          className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="licenseHead">
                                          Drag Or Click to Upload
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="span5 textPri ">
                                    Max file size is 10MB per image. JPG, PNG,
                                    or GIF formats only.
                                  </span>
                                </div>
                              ) : (
                                <div className=" text-center ">
                                  <img
                                    src={this.props.signObject}
                                    className="licenseImage dragDrop pointer"
                                    alt="propsImage"
                                    style={{ height: "100%", width: "100%" }}
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </Dropzone>
                    </div>
                    <div className="row">
                      <div className="col ">
                        <button
                          className="buttonSquareLinePri pull-left ml-3 mt-3"
                          onClick={() => this.changeSignatureObject(null)}
                        >
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </button>
                        {this.props.signObject !== null ? (
                          <button
                            className=" mr-3 pull-right mb-3"
                            onClick={() =>
                              this.changeSignatureObject(this.props.signObject)
                            }
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            className="secDisable pull-right mb-3 mr-3"
                            style={{ cursor: "not-allowed" }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Please provide your signature image"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SmallModalContainer>
            )}
            {!this.props.shortSign && (
              <SmallModalContainer
                name={"shortSignatureModal" + this.props.data.rentalEntityId}
                dark={this.props.dark}
                title="Short sign for contract agreement"
                footer="false"
                size="lg"
              >
                <div className="row">
                  <div className="col ">
                    <button
                      className={
                        this.state.signatureTab === "pad"
                          ? "mr-2 tabSignature"
                          : "mr-2  tabSignature2"
                      }
                      onClick={() => this.changeTab("pad", "shortSignObject")}
                    >
                      Draw
                    </button>
                    <button
                      className={
                        this.state.signatureTab === "upload"
                          ? "mr-2  tabSignature"
                          : "mr-2  tabSignature2"
                      }
                      onClick={() =>
                        this.changeTab("upload", "shortSignObject")
                      }
                    >
                      Upload
                    </button>
                  </div>
                </div>

                <div className={"tab-content  borderSec"} id="myTabContent">
                  <div
                    className={
                      this.state.signatureTab === "pad"
                        ? "tab-pane fade show active "
                        : "tab-pane fade "
                    }
                    id="upload"
                    role="tabpanel"
                    aria-labelledby="upload-tab"
                  >
                    <div className="d-flex justify-content-center pt-5 pb-4">
                      <SignaturePad
                        name={"shortSignPad" + this.props.data.rentalEntityId}
                        submitAlign="pull-right"
                        submitName="Submit"
                        type="base64"
                        value={this.props.shortSignObject}
                        submit={this.changeShortSignatureObject}
                        submitDisable={true}
                        maxWidth="450"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.state.signatureTab === "upload"
                        ? "tab-pane fade show active "
                        : "tab-pane fade "
                    }
                    id="upload"
                    role="tabpanel"
                    aria-labelledby="upload-tab"
                  >
                    <div className="d-flex justify-content-start mb-5">
                      <Dropzone
                        accept=".jpeg,.png,.jpg"
                        maxSize={10485760}
                        onDrop={(files) => {
                          files.map((file) => {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                              if (event.target.result === undefined) {
                                this.props.appendAlert(
                                  "Image size is too Long (max size = 10mb) ",
                                  "danger"
                                );
                              } else {
                                this.changeShortSignatureObject(
                                  event.target.result
                                );
                              }
                            };
                            reader.readAsDataURL(file);
                            return file;
                          });
                        }}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {this.props.shortSignObject === null ? (
                                <div className="text-left p-4">
                                  <div
                                    className="dragDrop text-center  pointer"
                                    style={{
                                      fontSize: "60px",
                                      minHeight: "250px",
                                      paddingTop: "70px",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <i
                                          className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="licenseHead">
                                          Drag Or Click to Upload
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="span5 textPri ">
                                    Max file size is 10MB per image. JPG, PNG,
                                    or GIF formats only.
                                  </span>
                                </div>
                              ) : (
                                <div className=" text-center ">
                                  <img
                                    src={this.props.shortSignObject}
                                    className="licenseImage dragDrop pointer"
                                    alt="propsImage"
                                    style={{ height: "100%", width: "100%" }}
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </Dropzone>
                    </div>
                    <div className="row">
                      <div className="col ">
                        <button
                          className="buttonSquareLinePri pull-left ml-3 mt-3"
                          onClick={() => this.changeShortSignatureObject(null)}
                        >
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </button>
                        {this.props.signObject !== null ? (
                          <button
                            className=" mr-3 pull-right mb-3"
                            onClick={() =>
                              this.changeShortSignatureObject(
                                this.props.signObject
                              )
                            }
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            className="secDisable pull-right mb-3 mr-3"
                            style={{ cursor: "not-allowed" }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Please provide your signature image"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SmallModalContainer>
            )}
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    isDark: state.isDark,
    dark: state.darkReducer,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    customerPayment: state.customerPaymentReducer,
    customerPaymentStatus: state.customerPayment,
    tempTenantByContract: state.tempTenantByContractReducer,
    tempTenantByContractArray: state.tempTenantByContract,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempListingData,
    appendTempUsers,
    appendTempTenantByContract,
    setContract,
  })(ContractListTenant)
);
