import React, { Component } from "react";
import Loader3 from "../Loader/loader3";
import "./TimeInput.css";
import "./DateInput.css";
import Calender from "./Calender/Calender";

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      focus: true,
      value: "",
      displayValue: "mm-dd-yyyy",
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocus0 = this.handleFocus0.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleBlur0 = this.handleBlur0.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.clear = this.clear.bind(this);
    this.today = this.today.bind(this);
  }
  handleFocus(e) {
    if (this.props.disabled !== "true") {
      this.setState({ listOpen: true });
    }
  }
  handleFocus0(e) {
    if (this.props.disabled !== "true") {
      this.setState({ listOpen: true, focus: false });
    }
  }
  handleBlur(e) {
    setTimeout(
      function () {
        this.setState({ listOpen: false, focus: true });
      }.bind(this),
      300
    );
  }
  handleBlur0(e) {
    setTimeout(
      function () {
        if (this.state.focus) {
          this.setState({ listOpen: false });
        }
      }.bind(this),
      300
    );
  }
  handleClick() {
    if (this.props.disabled !== "true") {
      this.setState({ listOpen: true, focus: true });
    }
  }
  selectDate(date, month, year) {
    this.props.onChange(
      this.props.target,
      year +
        "-" +
        (month + 1).toString().padStart(2, 0) +
        "-" +
        date.toString().padStart(2, 0)
    );
    this.setState({
      listOpen: false,
    });
  }
  componentDidMount() {
    var d = "mm-dd-yyyy";
    if (this.props.value !== "" && this.props.value !== null) {
      d = new Date(this.props.value);
      var dd = String(d.getDate()).padStart(2, "0");
      var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = d.getFullYear();
      d = mm + "-" + dd + "-" + yyyy;
    }
    this.setState({ value: d });
  }
  dateChange() {
    var d = "mm-dd-yyyy";
    if (this.props.value !== "" && this.props.value !== null) {
      d = new Date(this.props.value);
      var dd = String(d.getDate()).padStart(2, "0");
      var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = d.getFullYear();
      d = mm + "-" + dd + "-" + yyyy;
    }
    return d;
  }
  dateChange2() {
    var d = "yyyy-mm-dd";
    if (this.props.value !== "" && this.props.value !== null) {
      d = new Date(this.props.value);
      var dd = String(d.getDate()).padStart(2, "0");
      var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = d.getFullYear();
      d = yyyy + "-" + mm + "-" + dd;
    }
    return d;
  }
  today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    this.selectDate(
      parseInt(dd),
      parseInt(String(today.getMonth()).padStart(2, "0")),
      yyyy
    );
    today = mm + "-" + dd + "-" + yyyy;
    this.props.onChange(this.props.target, today);
    this.setState({ value: today, listOpen: false });
  }

  clear() {
    this.props.onChange(this.props.target, "");
    this.setState({ value: "", listOpen: false });
  }
  render() {
    const style = {
      paddingRight: this.props.icon && "60px",
      paddingLeft: this.props.iconL && "40px",
      backgroundColor:this.props.backColor&&this.props.backColor
    };
    return (
      <div className="outSpan">
        {this.props.name === "null" || this.props.name === "" ? (
          <>&nbsp;</>
        ) : (
          <span
            className={
              this.props.titleBreak
                ? "searchListHeadApp"
                : "searchListHeadApp noBreak"
            }
            style={{ width: "100%" }}
          >
            {this.props.name}

            {this.props.important && (
              <span className="textPri" style={{ fontSize: "15px" }}>
                {" "}
                &nbsp;*
              </span>
            )}
            {this.props.info && (
              <span className="infoHandel mb-5">
                <i
                  className="fa fa-question-circle infoIcon"
                  aria-hidden="true"
                ></i>
                <br></br>
                <div
                  className={
                    this.props.infoStyle === "infoContent2"
                      ? "informationListApp mt-2 infoContent2"
                      : " informationListApp mt-2 infoContent optionInfo"
                  }
                >
                  <span className="searchListContentApp">
                    {this.props.info}
                  </span>
                </div>
              </span>
            )}
          </span>
        )}
        <input
          className={
            this.props.validate && this.props.important
              ? this.props.designType === "2"
                ? this.props.align === "right"
                  ? "ApplicationInputAgg alert text-right"
                  : "ApplicationInputAgg alert"
                : this.props.align === "right"
                ? "ApplicationInput alert text-right"
                : "ApplicationInput alert"
              : this.props.designType === "2"
              ? this.props.align === "right"
                ? "ApplicationInputAgg text-right"
                : "ApplicationInputAgg "
              : this.props.align === "right"
              ? "ApplicationInput text-right"
              : "ApplicationInput "
          }
          onFocus={this.handleFocus}
          onBlur={this.handleBlur0}
          onClick={this.handleClick}
          // onChange={this.onChange}
          autocomplete="nope"
          type={"text"}
          placeholder={this.props.place}
          name={this.props.target}
          value={
            this.props.format === "yearFirst"
              ? this.dateChange2()
              : this.dateChange()
          }
          disabled={this.props.value === "please wait..." && "disabled"}
          max={this.props.max}
          min={this.props.min}
          id={this.props.id}
          style={style}
        ></input>{" "}
        <i
          onClick={this.handleClick}
          className={" fa fa-calendar-o fa-10x absolute pointer"}
          style={{
            marginLeft: "-40px",
            marginTop: "20px",
            top: "25px",
            right: "30px",
          }}
          aria-hidden="true"
        ></i>
        {this.state.listOpen && (
          <div
            className="timeInputDropDown p-0"
            tabindex="0"
            // onBlur={this.handleBlur}
            onFocus={this.handleFocus0}
          >
            <span
              aria-hidden="true"
              className="badge  inputClose1  absolute"
              style={{ marginLeft: "325px" }}
              onClick={() => this.setState({ listOpen: false })}
            >
              &times;
            </span>
            <Calender
              name={this.props.target}
              value={this.props.value}
              selectDate={this.selectDate}
              min={this.props.min}
              max={this.props.max}
              clear={this.clear}
              today={this.today}
            />
          </div>
        )}
        {this.props.value === "please wait..." && <Loader3 />}
        {this.props.validate && this.props.important && (
          <p className="spanValidateApp textPri">
            {this.props.errorStart !== "false" && "Please enter"}{" "}
            {this.props.errorMessage}
          </p>
        )}
      </div>
    );
  }
}

export default DateInput;
