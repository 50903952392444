import React from "react";
import "./DashCardApplication.css";

const DashCardApplication = () => {
  return (
    <div className="BackStatus dashCover  p-4 fullHeight squareStyle">
      <h3 className="text-dark cardHead">Applications</h3>
      <br></br>
      <div className="row   ">
        <div className="col square1 text-center textGray ">
          <div className=" round1  pb-3 ">
            <div className="cardIconDash text-center ">
              <i className="fa fa-binoculars fa-10x" aria-hidden="true"></i>
            </div>
            <h5 className="cardHeader">Leads</h5>
            <h1 className=" cardTextDash ">124</h1>
            {/* <button type="button" className="btn btn-outline btn-sm textGray bgGray footerButton">
            Details
          </button> */}
          </div>
        </div>
        <div className="col square1 text-center textBlue ">
          <div className=" round1  pb-3">
            <div className="cardIconDash text-center ">
              <i className="fa fa-paper-plane fa-10x" aria-hidden="true"></i>
            </div>
            <h5 className=" cardHeader">New</h5>
            <h1 className=" cardTextDash">5</h1>
          </div>

          {/* <button type="button" className="btn btn-outline btn-sm textBlue bgBl footerButton">
            Details
          </button> */}
        </div>

        <div className="col square1  text-center textGreen ">
          <div className=" round1  pb-3">
            <div className="cardIconDash text-center ">
              <i className="fa fa-tasks fa-10x" aria-hidden="true"></i>
            </div>
            <h5 className=" cardHeader">Pending </h5>
            <h1 className=" cardTextDash ">12</h1>
            {/* <button
            type="button"
            className="btn btn-outline btn-sm textGreen bgGreen footerButton"
          >
            Details
          </button> */}
          </div>
        </div>
        <div className="col  square1 text-center textRed ">
          <div className=" round1  pb-3">
            <div className="cardIconDash text-center">
              <i className="fa fa-trash fa-10x" aria-hidden="true"></i>
            </div>
            <h5 className="cardHeader">Rejected </h5>
            <h1 className=" cardTextDash ">7</h1>
            {/* <button type="button" className="btn btn-outline btn-sm textRed bgRed footerButton">
            Details
          </button> */}
          </div>
        </div>
        <div className="col square1 text-center textPurple">
          <div className=" round1  pb-3">
            <div className="cardIconDash text-center">
              <i className="fa fa-handshake-o fa-10x" aria-hidden="true"></i>
            </div>
            <h5 className="cardHeader">In Contract</h5>
            <h1 className=" cardTextDash ">84</h1>
            {/* <button
            type="button"
            className="btn btn-outline btn-sm textPurple bgPurple footerButton"
          >
            Details
          </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashCardApplication;
