import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { CardElement, injectStripe } from "react-stripe-elements";
import {
  addPaymentMethod,
  checkPaymentStatus,
  userDetail,
} from "../../../Services/ApiServices";
import CookieService from "../../../Services/CookieService";
import Loader3 from "../../Loader/loader3";


class CardPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: this.props.access_token,
      user: this.props.user,
      redirect: false,
      isButtonDisable: true,
      loading: false,
      number: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isButtonDisable: false });
    this.setState({ loading: true });
    try {
      let { token } = await this.props.stripe.createToken();
      if (token) {
        var formBody = [];
        var details = {
          email: this.props.email,
          token: token.id,
        };

        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        if (this.props.role === "Guest") {
          this.props.setPaymentToken(token);
          this.props.pushAlert("Your Card Successfully Added !", "success");
          this.props.next(null,"");
        } else {
          addPaymentMethod(this.props.type, formBody)
            .then((response) => {
              if (response.data !== undefined) {
                if (response.data.status) {
                  if (this.props.user.stripeCustomerId === null) {
                    userDetail(this.props.user.username).then((response2) => {
                      if (response2.status === 200) {
                        checkPaymentStatus().then((response3) => {
                          if (response3.status === 200) {
                            if (response3.status) {
                              if (this.props.mode === "temp") {
                                this.props.next(response3.data,"card");
                              }else{
                                this.props.history.goBack();
                              }
                              this.props.setCustomerPaymentStatus(
                                response3.data.data
                              );
                              this.setState({ isButtonDisable: true });
                              this.setState({ loading: false });
                              this.props.pushAlert(
                                "Your Card Successfully Added !",
                                "success"
                              );
                              
                              CookieService.setArrayCookie(
                                "axUserDetails",
                                response2.data,
                                7
                              );
                              this.props.setUser(response2.data);
                            }
                          }
                        });
                      } else {
                        this.setState({ isButtonDisable: true });
                        this.setState({ loading: false });
                      }
                    });
                  } else {
                    this.setState({ isButtonDisable: true });
                    this.setState({ loading: false });
                    this.props.pushAlert(
                      "Your Card Successfully Added !",
                      "success"
                    );
                    this.props.history.goBack();
                  }
                } else {
                  this.setState({ isButtonDisable: true });
                  this.setState({ loading: false });
                  this.props.pushAlert(
                    "Something went wrong!, Please try again later.",
                    "danger"
                  );
                }
              } else {
                this.setState({ isButtonDisable: true });
                this.setState({ loading: false });
                this.props.pushAlert(response.response.data.message, "danger");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.props.pushAlert(
          "Error in Card details.Try again later!",
          "danger"
        );
        this.setState({ isButtonDisable: true });
        this.setState({ loading: false });
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div className="">
        {this.props.paymentToken === "" ? (
          <>
            <form
              onSubmit={this.handleSubmit}
              className="form-group  rounded pt-4"
              id="cardElement"
            >
              <p className="stepTitlePayment   ">Payment Information</p>
              <br></br>
              <CardElement
                hidePostalCode
                className="p-2 border border-light shadow-sm mt-2"
              />
              <div style={{ marginTop: "10px" }}>
                {this.state.loading && <Loader3 />}
              </div>
              <br></br>
              <div style={{ textAlign: "right" }}>
                {!this.state.isButtonDisable ? (
                  <button
                    className="secDisable"
                    style={{ cursor: "not-allowed" }}
                  >
                    Add Card
                  </button>
                ) : (
                  <button
                    className="priButton"
                    disabled={!this.state.isButtonDisable}
                  >
                    Add Card
                  </button>
                )}
              </div>
            </form>
          </>
        ) : (
          <>
            <h1
              className="stepTitle  text-secondary"
              style={{ marginTop: "30px" }}
            >
              Payment Information
            </h1>
            <br></br>
            <br></br>
            <p className="span7 " style={{ marginLeft: "30px" }}>
              Card added successfully
            </p>
            <div style={{ textAlign: "right" }}>
              <button
                className="pri"
                onClick={() => this.props.changeTokenNull()}
              >
                Change Card
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(injectStripe(CardPayment));
