import ApplicationInput from "../../../../../UIServices/ApplicationInput";
import React, { useEffect } from "react";

export default function Stepper04Elements(props) {
  var {
    values,
    onchangeCheck,
    onchange,
    checkValidate,
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="cardContract">
        <div className="row inputContainer  pt-0 pb-0 ">
          <div className="">
            <p className="bcAgreementSubTitle   textDark ">
              Utilities included in the rent
            </p>
          </div>
        </div>

        <div className="row inputContainer  pb-0 pt-0">
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Water</span>}
              type="checkedBox"
              target="isUtilityWater"
              onChange={onchangeCheck}
              value={values.isUtilityWater}
            />
          </div>

          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Natural gas</span>
              }
              type="checkedBox"
              target="isUtilityNaturalGas"
              onChange={onchangeCheck}
              value={values.isUtilityNaturalGas}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Garbage collection
                </span>
              }
              type="checkedBox"
              target="isUtilityGarbageCollection"
              onChange={onchangeCheck}
              value={values.isUtilityGarbageCollection}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Refrigerator</span>
              }
              type="checkedBox"
              target="isUtilityRefrigerator"
              onChange={onchangeCheck}
              value={values.isUtilityRefrigerator}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Carpets</span>}
              type="checkedBox"
              target="isUtilityCarpets"
              onChange={onchangeCheck}
              value={values.isUtilityCarpets}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Cablevision</span>
              }
              type="checkedBox"
              target="isUtilityCablevision"
              onChange={onchangeCheck}
              value={values.isUtilityCablevision}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Sewage disposal</span>
              }
              type="checkedBox"
              target="isUtilitySewageDisposal"
              onChange={onchangeCheck}
              value={values.isUtilitySewageDisposal}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Recycling services
                </span>
              }
              type="checkedBox"
              target="isUtilityRecyclingServices"
              onChange={onchangeCheck}
              value={values.isUtilityRecyclingServices}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Dishwasher</span>}
              type="checkedBox"
              target="isUtilityDishwasher"
              onChange={onchangeCheck}
              value={values.isUtilityDishwasher}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Electricity</span>
              }
              type="checkedBox"
              target="isUtilityElectricity"
              onChange={onchangeCheck}
              value={values.isUtilityElectricity}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Snow removal</span>
              }
              type="checkedBox"
              target="isUtilitySnowRemoval"
              onChange={onchangeCheck}
              value={values.isUtilitySnowRemoval}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Kitchen scrap collection
                </span>
              }
              type="checkedBox"
              target="isUtilityKitchenScrapCollection"
              onChange={onchangeCheck}
              value={values.isUtilityKitchenScrapCollection}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Stove and oven</span>
              }
              type="checkedBox"
              target="isUtilityStoveAndOven"
              onChange={onchangeCheck}
              value={values.isUtilityStoveAndOven}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Internet</span>}
              type="checkedBox"
              target="isUtilityInternet"
              onChange={onchangeCheck}
              value={values.isUtilityInternet}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Storage</span>}
              type="checkedBox"
              target="isUtilityStorage"
              onChange={onchangeCheck}
              value={values.isUtilityStorage}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Laundry (coin-up)
                </span>
              }
              type="checkedBox"
              target="isUtilityLaundry"
              onChange={onchangeCheck}
              value={values.isUtilityLaundry}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Window coverings
                </span>
              }
              type="checkedBox"
              target="isUtilityWindowCoverings"
              onChange={onchangeCheck}
              value={values.isUtilityWindowCoverings}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Heat</span>}
              type="checkedBox"
              target="isUtilityHeat"
              onChange={onchangeCheck}
              value={values.isUtilityHeat}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Recreation facilities
                </span>
              }
              type="checkedBox"
              target="isUtilityRecreationFacilities"
              onChange={onchangeCheck}
              value={values.isUtilityRecreationFacilities}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Free laundry</span>
              }
              type="checkedBox"
              target="isUtilityFreeLaundry"
              onChange={onchangeCheck}
              value={values.isUtilityFreeLaundry}
            />
          </div>

          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Furniture</span>}
              type="checkedBox"
              target="isUtilityFurniture"
              onChange={onchangeCheck}
              value={values.isUtilityFurniture}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">Parking For</span>
              }
              type="checkedBox"
              target="isUtilityParkingFor"
              onChange={onchangeCheck}
              value={values.isUtilityParkingFor}
            />
            {values.isUtilityParkingFor && (
              <ApplicationInput
                name=""
                type="text"
                target="utilityParkingVehicles"
                onChange={onchange}
                important="true"
                place="vehicles"
                errorMessage="parking vehicles"
                validate={checkValidate("utilityParkingVehicles")}
                value={values.utilityParkingVehicles}
              />
            )}
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Other</span>}
              type="checkedBox"
              target="isUtilityOther1"
              onChange={onchangeCheck}
              value={values.isUtilityOther1}
            />
            {values.isUtilityOther1 && (
              <ApplicationInput
                name=""
                type="text"
                target="utilityOther1"
                onChange={onchange}
                important="true"
                errorMessage="utility other type"
                validate={checkValidate("utilityOther1")}
                value={values.utilityOther1}
              />
            )}
          </div>
          <div
            className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2"
            style={{ display: "inline-block" }}
          >
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Other</span>}
              type="checkedBox"
              target="isUtilityOther2"
              onChange={onchangeCheck}
              value={values.isUtilityOther2}
            />
            {values.isUtilityOther2 && (
              <ApplicationInput
                name=""
                type="text"
                target="utilityOther2"
                onChange={onchange}
                important="true"
                errorMessage="utility other type"
                validate={checkValidate("utilityOther2")}
                value={values.utilityOther2}
              />
            )}
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={<span className="span2 contractTextStyle">Other</span>}
              type="checkedBox"
              target="isUtilityOther3"
              onChange={onchangeCheck}
              value={values.isUtilityOther3}
            />
            {values.isUtilityOther3 && (
              <ApplicationInput
                name=""
                type="text"
                target="utilityOther3"
                onChange={onchange}
                important="true"
                errorMessage="utility other type"
                validate={checkValidate("utilityOther3")}
                value={values.utilityOther3}
              />
            )}
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 pt-2">
            <ApplicationInput
              name={
                <span className="span2 contractTextStyle">
                  Additional Information
                </span>
              }
              type="checkedBox"
              target="isUtilityAdditionalInfo"
              onChange={onchangeCheck}
              value={values.isUtilityAdditionalInfo}
            />
            {values.isUtilityAdditionalInfo && (
              <ApplicationInput
                name=""
                type="text"
                target="utilityAdditionalInfo"
                onChange={onchange}
                important="true"
                errorMessage="utility additional information"
                validate={checkValidate("utilityAdditionalInfo")}
                value={values.utilityAdditionalInfo}
              />
            )}
          </div>
        </div>
        <div className="row inputContainer bcAgreementAttachment pt-5 pb-0 ">
          <div className="mr-3 ">
            <span className="bcAgreementSubTitle   textDark ">
              Attached to this tenancy agreement, there
            </span>
          </div>
          <div className="mr-3">
            <ApplicationInput
              name={
                <span className=" bcAgreementSubTitle2   textDark">is</span>
              }
              type="checkedBox"
              target="isAttachedTenancyAgreement"
              onChange={onchangeCheck}
              value={values.isAttachedTenancyAgreement}
            />
          </div>
          <div className="">
            <ApplicationInput
              name={
                <span className=" bcAgreementSubTitle2   textDark">
                  is not Addendum
                </span>
              }
              type="checkedBox"
              target="isNotAttachedTenancyAgreement"
              onChange={onchangeCheck}
              value={values.isNotAttachedTenancyAgreement}
            />
          </div>
        </div>
        {values.isAttachedTenancyAgreement&&
        <div className="row inputContainer bcAgreementAttachment pt-0 pb-0 ">
          <span className="span2 contractTextStyle pl-3">
            If there is an Addendum attached , provide the following information
            on the Addendum that forms part of this tenancy agreement:
          </span>
          <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
            <ApplicationInput
              name="Number of pages of the Addendum:"
              type="text"
              target="numberAddendumPage"
              onChange={onchange}
              important="true"
              errorMessage="number of pages of the Addendum"
              validate={checkValidate("numberAddendumPage")}
              value={values.numberAddendumPage}
            />
          </div>
          <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12">
            <ApplicationInput
              name="Number of additional terms in the Addendum:"
              type="text"
              target="numberAdditionalAddendumPage"
              onChange={onchange}
              important="true"
              errorMessage="number of additional terms in the Addendum"
              validate={checkValidate("numberAdditionalAddendumPage")}
              value={values.numberAdditionalAddendumPage}
            />
          </div>
        </div>}
      </div>
    </div>
  );
}
