import React from "react";
import SearchRental from "./SearchRental";
import "./RentalUnits.css";
import { useSelector } from "react-redux";

const RentalListSearch = (props) => {
  const dark = useSelector((state) => state.isDark);
  return (
    <div className={dark ? "RentalSearchDark" : "RentalSearch"}>
      <div className="row pr-3 pl-3">
        <div className="col RentalSearch1">
          <SearchRental name="Rent (less than)" search={props.search} />
        </div>
        <div className="col RentalSearch1">
          <SearchRental name="Bedrooms" search={props.search} />
        </div>
        <div className="col RentalSearch1">
          <SearchRental name="City" search={props.search} />
        </div>
        <div className="col RentalSearch1">
          <SearchRental name="State" search={props.search} />
        </div>

        <button
          className=""
          onClick={() => props.rentalRefresh()}
          style={{ height: "40px", marginTop: "35px" }}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default RentalListSearch;
