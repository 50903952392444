import {
  createContractBritishColumbia,
  getLandlordContracts,
} from "../../../../../Services/ApiServices";

export function checkMobile(value, validation) {
  return (value.length !== 14 || value === "") && !validation;
}

export function checkMobileNext(value) {
  return value.length === 14;
}
export function submitContract(
  changeState,
  state,
  props
) {
  try {
    changeState({ isButtonDisableFinish: true });

    let tenant = [];
    if (props.location.state.applicationData.apply[0]) {
      tenant.push({
        username: props.location.state
          ? props.location.state.applicationData.apply[0].applicant.username
          : null,
        tenantOrder: 1,
      });
    }
    if (props.location.state.applicationData.apply[1]) {
      tenant.push({
        username: props.location.state
          ? props.location.state.applicationData.apply[1].applicant.username
          : null,
        tenantOrder: 2,
      });
    }
    if (props.location.state.applicationData.apply[2]) {
      tenant.push({
        username: props.location.state
          ? props.location.state.applicationData.apply[2].applicant.username
          : null,
        tenantOrder: 3,
      });
    }
    let contractBody = {
      tenants: tenant,
      rentalEntityId: props.location.state ? props.location.state.applicationData.rentalEntityId : null, //
      applicationId: props.location.state ? props.location.state.applicationData.id : null, //
      agreementType: state.agreementPaymentMonthBasicType
        ? "monthly"
        : state.agreementPaymentPeriodicType
        ? state.agreementPaymentWeekly
          ? "weekly"
          : state.agreementPaymentBiWeekly
          ? "by_weekly"
          : "other"
        : "fixed",
      otherAgreementType: null,
      agreementContinueAfter: state.agreementPaymentFixedType,
      reasonForVacate: state.agreementPaymentFixedTermEndNotContinue
        ? state.reasonTenancyVacate
        : null,
      tenancyRegulationSectionNo: state.agreementPaymentFixedTermEndNotContinue
        ? state.regulationTenancyNumber
        : null,
      moveInCheckList: null,
      moveOutCheckList: null,
      status: "ACTIVE",
      startDate: state.moveInDate !== "" ? state.moveInDate : null, //
      endDate: state.agreementPaymentFixedType
        ? state.agreementPaymentFixedTermEndVacate
        : null, //
      rent: state.rent !== "" ? state.rent : null, //
      rentCollectionDay:
        state.paymentDay !== ""
          ? parseInt(state.paymentDay) //
          : null,
      rentPaidOnPeriod: state.rentDay
        ? "day"
        : state.rentWeek
        ? "week"
        : "month",
      rentLateFeeChargeOn: state.rentPayDay
        ? "day"
        : state.rentPayWeek
        ? "week"
        : "month",
      isSecurityDeposit: true,
      securityDeposit:
        state.securityDeposit !== "" ? state.securityDeposit : null,
      securityDepositPayOn: state.securityDepositPayBy
        ? state.securityDepositPayBy
        : null,
      petChargePayOn: state.isPetDeposit ? state.petDepositPayBy : null,
      parkingAcce: state.isUtilityParkingFor,
      parkingDescription: state.utilityParkingVehicles,
      condominium: false,
      condominiumLink: null,
      indoorSmoking: false,
      smokingDescription: "",
      smokeDetectionEmeNotifi: "",
      smokeDetectionEmeNotifiFile: "",
      smokeDetectionEmeRelocation: false,
      smokeDetectionEmeRelocationFile: "",
      smokeDetectionEmeEvacuation: false,
      smokeDetectionEmeEvacuationFile: "",
      moveEarly: false,
      indoorVaping: false,
      emailOption: false,
      documentEmails: "",
      landlordEmergencyContactOption: false,
      parkingFee: 0.0,
      otherRentFee1Name: "",
      otherRentFee1: 0.0,
      otherRentFee2Name: "",
      otherRentFee2: 0.0,
      otherRentFee3Name: "",
      otherRentFee3: 0.0,
      isRentDiscount: false,
      rentDiscount: 0.0,
      rentDiscountType: "",
      rentDiscountDescription: "",
      rentPayableTo: "",
      rentPayableMethod: "",
      electricityResponsible: "",
      heatResponsible: "",
      waterResponsible: "tenant",
      sewerResponsible: "tenant",
      water: state.isUtilityWater,
      garbageCollection: state.isUtilityGarbageCollection,
      refrigerator: state.isUtilityRefrigerator,
      cablevision: state.isUtilityCablevision,
      sewageDisposal: state.isUtilitySewageDisposal,
      recyclingServices: state.isUtilityRecyclingServices,
      dishwasher: state.isUtilityDishwasher,
      electricity: state.isUtilityElectricity,
      snowRemoval: state.isUtilitySnowRemoval,
      kitchenScrapCollection: state.isUtilityKitchenScrapCollection,
      stoveAndOven: state.isUtilityStoveAndOven,
      internet: state.isUtilityInternet,
      windowCoverings: state.isUtilityWindowCoverings,
      heat: state.isUtilityHeat,
      recreationFacilities: state.isUtilityRecreationFacilities,
      furniture: state.isUtilityFurniture,
      tenantCarpetCleaning: state.isUtilityCarpets,
      garbageResponsible: "tenant",
      otherResponsible: "",
      isTenantInsurance: "",
      smokeDetectionDevice: "hard_wired",
      smokeDetectionFireSprinkler: true,
      smokeDetectionFireAlarm: true,
      airConditioner: "",
      gas: state.isUtilityNaturalGas,
      storage: state.isUtilityStorage,
      laundry: state.isUtilityLaundry,
      laundryChargeType: "",
      guestParking: false,
      guestParkingType: "",
      serviceType1: state.utilityOther1,
      serviceType2: state.utilityOther2,
      serviceType3: state.utilityOther3,
      serviceDescription: state.utilityAdditionalInfo,
      subscription: false,
      isPartialRent: false,
      partialRent: 0.0,
      partialRentDate: null,
      partialRentFrom: null,
      partialRentTo: null,

      isAdditionalTerms: false,
      additionalTermsDoc: "",
      landLordId: "",
      bankName: null,
      bankBranch: null,
      bankAddress: null,
      improvement: false,
      subLease: false,
      isKeyDeposit: null,
      keyDepositDescription: null,
      keyDeposit: null,
      petChargeMethod: null,
      petAmount:
        state.isPetDeposit && state.petDeposit !== "" ? state.petDeposit : null,
      utilityResposible: false,
      islateFee: null,
      lateFeeCharging: null,
      gracePeriod: null,
      lateFeeAmount: null,
      inspection: false,
      petCharge: state.isPetDeposit,
      tenantMaintenance: false,
      tenantInsurance: false,
      numberOfPagesAddendum: state.isAttachedTenancyAgreement
        ? state.numberAddendumPage
        : null,
      numberOfAdditionalTermsAddendum: state.isAttachedTenancyAgreement
        ? state.numberAdditionalAddendumPage
        : null,
      agreementPDF: "AXALLANT_AGREEMENT",
    };
    let body = {
      contract: contractBody,
      shortSignature: state.signatureObject2,
      signatureImage: state.signatureObject,
      signature: true,
    };
    createContractBritishColumbia(body)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            props.history.push({
              pathname: "/contracts",
              state: {
                refresh: true,
              },
            });
            changeState({ isButtonDisableFinish: false });
            props.appendAlert("Lease created successfully!", "success");

            try {
              getLandlordContracts().then((response2) => {
                if (response2.status === 200) {
                  if (response2.data.length !== 0) {
                    props.setContract(
                      response2.data.sort((a, b) =>
                        b.updatedAt.localeCompare(a.updatedAt)
                      )
                    );
                  } else {
                    props.setContract([]);
                  }
                }
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            props.appendAlert("Something went wrong!", "danger");
          }
        } else {
          props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );

          changeState({ isButtonDisableFinish: false });
        }
      })
      .catch(() => {
        props.appendAlert("Something went wrong!", "danger");
        changeState({ isButtonDisableFinish: false });
      });
  } catch (error) {
    changeState({ isButtonDisableFinish: false });
  }
}

export function nextFirstTabCheck(makeValidation, changeState, state) {
  if (makeValidation) {
    changeState({ validation: false });
  }
  if (state.landlord) {
    return nextFirstTabCheckLandlord(makeValidation, changeState, state);
  } else {
    return nextFirstTabCheckLandlordAgent(makeValidation, changeState, state);
  }
}
function changeValidateReturnTrue(makeValidation, changeState) {
  changeState({ validation: makeValidation });
  return true;
}

function nextFirstTabCheckLandlord(makeValidation, changeState, state) {
  if (
    // state.landlordUnit !== "" &&
    state.landlordStreet !== "" &&
    state.landlordCity !== "" &&
    state.landlordProvince !== "" &&
    state.landlordPostalCode !== "" &&
    state.landlordDayTimePhoneNumber !== "" &&
    state.moveInDate !== "" &&
    checkMobileNext(state.landlordDayTimePhoneNumber)
  ) {
    return nextFirstTabCheckPeriod(makeValidation, changeState, state);
  } else {
    return false;
  }
}
function nextFirstTabCheckLandlordAgent(makeValidation, changeState, state) {
  if (
    // state.landlordAgentUnit !== "" &&
    state.landlordAgentStreet !== "" &&
    state.landlordAgentCity !== "" &&
    state.landlordAgentProvince !== "" &&
    state.landlordAgentPostalCode !== "" &&
    state.landlordAgentDayTimePhoneNumber !== "" &&
    state.moveInDate !== "" &&
    checkMobileNext(state.landlordAgentDayTimePhoneNumber)
  ) {
    return nextFirstTabCheckPeriod(makeValidation, changeState, state);
  } else {
    return false;
  }
}

function nextFirstTabCheckPeriod(makeValidation, changeState, state) {
  if (state.agreementPaymentOther) {
    if (state.agreementPaymentOtherType !== "") {
      return changeValidateReturnTrue(makeValidation, changeState);
    } else {
      return false;
    }
  } else if (state.agreementPaymentFixedType) {
    if (state.agreementPaymentFixedTermEndVacate !== "") {
      return nextFirstTabFixedTermTenancy(makeValidation, changeState, state);
    } else {
      return false;
    }
  } else {
    return changeValidateReturnTrue(makeValidation, changeState);
  }
}

function nextFirstTabFixedTermTenancy(makeValidation, changeState, state) {
  if (state.agreementPaymentFixedTermEndContinue) {
    if (
      state.reasonTenancyVacate !== "" 
      // state.regulationTenancyNumber !== ""
    ) {
      return changeValidateReturnTrue(makeValidation, changeState);
    } else {
      return false;
    }
  } else {
    return changeValidateReturnTrue(makeValidation, changeState);
  }
}
export function nextSecondTabCheck(makeValidation, changeState, state) {
  if (makeValidation) {
    changeState({ validation: false });
  }
  if (state.rent !== "" && state.paymentDay !== "") {
    return changeValidateReturnTrue(makeValidation, changeState);
  } else {
    return false;
  }
}
export function nextThirdTabCheck(makeValidation, changeState, state) {
  if (makeValidation) {
    changeState({ validation: false });
  }

  if (state.securityDeposit !== "" && state.securityDepositPayBy !== "") {
    if (state.isPetDeposit) {
      if (state.petDeposit !== "" && state.petDepositPayBy !== "") {
        return changeValidateReturnTrue(makeValidation, changeState);
      } else {
        return false;
      }
    } else {
      return changeValidateReturnTrue(makeValidation, changeState);
    }
  } else {
    return false;
  }
}

export function nextForthTabCheck(makeValidation, changeState, state) {
  if (makeValidation) {
    changeState({ validation: false });
  }
  if (state.isAttachedTenancyAgreement) {
    if (
      state.numberAddendumPage !== "" &&
      state.numberAdditionalAddendumPage !== ""
    ) {
      return nextForthTabCheckParkingFor(makeValidation, changeState, state);
    } else {
      return false;
    }
  } else {
    return nextForthTabCheckParkingFor(makeValidation, changeState, state);
  }
}
function nextForthTabCheckParkingFor(makeValidation, changeState, state) {
  if (state.isUtilityParkingFor) {
    if (state.utilityParkingVehicles !== "") {
      return nextForthTabCheckOther1(makeValidation, changeState, state);
    } else {
      return false;
    }
  } else {
    return nextForthTabCheckOther1(makeValidation, changeState, state);
  }
}
function nextForthTabCheckOther1(makeValidation, changeState, state) {
  if (state.isUtilityOther1) {
    if (state.utilityOther1 !== "") {
      return nextForthTabCheckOther2(makeValidation, changeState, state);
    } else {
      return false;
    }
  } else {
    return nextForthTabCheckOther2(makeValidation, changeState, state);
  }
}
function nextForthTabCheckOther2(makeValidation, changeState, state) {
  if (state.isUtilityOther2) {
    if (state.utilityOther2 !== "") {
      return nextForthTabCheckOther3(makeValidation, changeState, state);
    } else {
      return false;
    }
  } else {
    return nextForthTabCheckOther3(makeValidation, changeState, state);
  }
}
function nextForthTabCheckOther3(makeValidation, changeState, state) {
  if (state.isUtilityOther3) {
    if (state.utilityOther3 !== "") {
      return nextForthTabCheckAdditionalInfo(
        makeValidation,
        changeState,
        state
      );
    } else {
      return false;
    }
  } else {
    return nextForthTabCheckAdditionalInfo(makeValidation, changeState, state);
  }
}
function nextForthTabCheckAdditionalInfo(makeValidation, changeState, state) {
  if (state.isUtilityAdditionalInfo) {
    if (state.utilityAdditionalInfo !== "") {
      return changeValidateReturnTrue(makeValidation, changeState);
    } else {
      return false;
    }
  } else {
    return changeValidateReturnTrue(makeValidation, changeState);
  }
}
export function ModifyString(value) {
  if (value) {
    return value.toString();
  }
  return "";
}
